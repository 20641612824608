import React from "react"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import SecondaryNavDrawer from "../SecondaryNavDrawer/SecondaryNavDrawer"
import MobileNavItem from "../MobileNavItem/MobileNavItem"

const FormularyNavDrawer = ({ history, closeAllDrawers }) => {
  const { formularyMetaData, formularyTitle } = useSelector(
    ({ application, formulary }) => {
      const { currentFormularyType } = formulary

      return {
        formularyMetaData: application[`${currentFormularyType}Metadata`] || [],
        formularyTitle: formulary.currentFormularyTitle
      }
    }
  )

  const onOptionClick = (option) => {
    history.push(option.steps[0].url)
    closeAllDrawers()
  }

  const navOptions = formularyMetaData.map((option) => (
    <MobileNavItem
      style={{ padding: "10px 40px 10px 40px" }}
      clickHandler={() => onOptionClick(option)}
      isSelected={false}
    >
      <p style={{ gridColumnStart: 2 }}>{option.title}</p>
    </MobileNavItem>
  ))

  return <SecondaryNavDrawer navOptions={navOptions} title={formularyTitle} />
}

FormularyNavDrawer.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  closeAllDrawers: PropTypes.func.isRequired
}

export default WithDrawerConsumer(withRouter(FormularyNavDrawer))
