import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import moment from "moment"
import queryString from "query-string"
import PropTypes from "prop-types"
import { setLoginRedirect } from "../../Redux/Actions/User/functions"
import { getWorkshopAttendees } from "../../Redux/Actions/Workshop/functions"
import GoogleTagManager from "../GoogleTagManager/GoogleTagManager"
import AttendeesList from "../AttendeesList/AttendeesList"
import colors from "../../Constants/colors"

const styles = {
  container: {
    paddingTop: "140px",
    paddingBottom: "80px",
    backgroundColor: "#f5f6f8",
    minHeight: "100vh"
  },
  title: {
    color: colors.mediumBlue,
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    textAlign: "center"
  },
  description: {
    color: colors.mediumBlue,
    fontFamily: "Lato-Regular, sans-serif",
    fontSize: "1.4em",
    marginTop: "22px",
    textAlign: "center",
    margin: "40px 15% 75px"
  }
}

const AttendeesScreen = ({ history, location }) => {
  const dispatch = useDispatch()

  const { errorData, user, attendees, isLoading, hasError } = useSelector(
    (state) => ({
      errorData: state.errors.errorData,
      user: state.user.user,
      attendees: state.workshop.attendees,
      isLoading: state.workshop.isLoading,
      hasError: state.workshop.hasError
    })
  )

  const fetchAttendees = () => {
    const { campaignId } = queryString.parse(location.search)

    dispatch(getWorkshopAttendees(campaignId))
  }

  useEffect(() => {
    if (errorData && errorData.redirectUri) {
      const redirectPath = `${location.pathname}${location.search}`
      dispatch(setLoginRedirect(redirectPath))
      history.push("/account/login")
    }
  }, [errorData])

  useEffect(() => {
    document.title = "Workshop Attendees"

    if (window.dataLayer) {
      window.dataLayer.push({
        page_view: {
          page_path: `/attendees`,
          page_title: document.title
        }
      })
    }
  }, [])

  useEffect(() => {
    fetchAttendees()
  }, [location])

  const setDimensions = () => {
    try {
      window.dataLayer.push({
        dimensions: {
          rank: user && user.military && user.military.lastRank,
          USSOCOM: user && user.military && user.military.sof,
          time_until_separation:
            user &&
            user.military &&
            `${moment(user.military.retirementDate).diff(
              moment(),
              "months"
            )} Months`,
          military_status: user && user.military && user.military.status,
          gender: user && user.gender
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <GoogleTagManager
      gtmId={process.env.REACT_APP_NETWORK_GTM_ID}
      onFinishLoad={setDimensions}
    >
      <div style={styles.container}>
        <p style={styles.title}>Workshop Participants</p>
        <p style={styles.description}>
          The COMMIT Foundation is dedicated to connecting those in our
          ecosystem, to include staff, coaches, mentors, and servicemembers.
          This space is for viewing and connecting via LinkedIn with other
          servicemembers, mentors and COMMIT Foundation staff who are
          participating in the workshop.
        </p>
        <AttendeesList
          isLoading={isLoading}
          attendees={attendees}
          hasError={hasError}
          fetchAttendees={fetchAttendees}
        />
      </div>
    </GoogleTagManager>
  )
}

AttendeesScreen.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired
}

export default withRouter(AttendeesScreen)
