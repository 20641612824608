import React, { useState } from "react"
import CreateIcon from "@material-ui/icons/Create"
import CommitInput from "../CommitInput/CommitInput"
import OutsideClickWrapper from "../../Hooks/outsideClickWrapper"

export default function RoadmapperConstraintEvent({
  title,
  time,
  AMorPM,
  updateConstraint,
  calender
}) {
  const [editing, toggleEditing] = useState(false)

  const options = []
  ;(() => {
    for (let i = 2; i < 12; i++) {
      options.push({
        value: `${i}:00${AMorPM}`
      })
      options.push({
        value: `${i}:30${AMorPM}`
      })
    }
    options.push({
      value: `12:00${AMorPM === "pm" ? "am" : "pm"}`
    })
  })()

  const toggleEdit = (toggler = !editing) => {
    if (!calender) toggleEditing(toggler)
  }

  const updateSingleConstraint = (e) => {
    if (
      !calender ||
      (e.target.value && e.target.value.split("-").length === 3)
    ) {
      updateConstraint(e.target.value, AMorPM, calender)
    }
  }

  return (
    <OutsideClickWrapper clickHandler={() => toggleEdit(false)}>
      {editing && false ? (
        <div style={{ ...styles.generalBox, ...styles.editingBox }}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ ...styles.rectangle, backgroundColor: "#00D061" }} // "#7551E9" }}
            />
            <span className="roadmapperEventConstraintTitle">{title}</span>
          </span>
          <span style={styles.durationTimeStyle}>
            {calender ? (
              <CommitInput
                title={title}
                inputType="date"
                style={styles.inputStyle}
                placeHolder={title}
                value={time}
                handleChange={(e) => updateSingleConstraint(e)}
              >
                {time}
              </CommitInput>
            ) : (
              <CommitInput
                style={styles.autoMargin}
                inputType="time"
                value={time.format("hh:mm")}
                options={options}
                handleChange={(e) => updateSingleConstraint(e)}
              >
                {time.format("hh:mm a")}
              </CommitInput>
            )}
          </span>
          <button
            onClick={() => toggleEdit()}
            style={{
              ...styles.editButton
            }}
          >
            <CreateIcon />
          </button>
        </div>
      ) : (
        <div style={{ ...styles.generalBox, ...styles.nonEditBox }}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{ ...styles.rectangle, backgroundColor: "#00D061" }} // "#7551E9" }}
            />
            <span className="roadmapperEventConstraintTitle">{title}</span>
          </span>
          <span
            style={
              calender || true
                ? styles.durationCalenderTime
                : styles.durationTimeStyle
            }
          >
            {calender ? time : time.format("h:mm a")}
          </span>
          {calender || true ? (
            <></>
          ) : (
            <button
              onClick={() => toggleEdit()}
              style={{
                ...styles.editButton
              }}
            >
              <CreateIcon />
            </button>
          )}
        </div>
      )}
    </OutsideClickWrapper>
  )
}

const styles = {
  editButton: {
    height: "24px",
    color: "rgba(0, 0, 0, 0.09)",
    backgroundColor: "inherit",
    border: "none",
    margin: "auto",
    marginRight: "16px",
    marginLeft: "0px",
    padding: "0px"
  },
  durationTimeStyle: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "106%",
    alignItems: "center",
    textAlign: "right",
    color: "#8792A5",
    margin: "auto",
    marginRight: "0px",
    paddingRight: "16px"
  },
  durationCalenderTime: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "106%",
    alignItems: "center",
    textAlign: "right",
    color: "#8792A5",
    margin: "auto",
    marginRight: "40px",
    paddingRight: "16px"
  },
  rectangle: {
    alignItems: "center",
    width: "4px",
    minWidth: "4px",
    height: "100%",
    borderRadius: "3px 0 0 3px",
    display: "flex"
  },
  editingBox: {
    height: "fit-content"
  },
  nonEditBox: {
    height: "fit-content"
  },
  generalBox: {
    display: "flex",
    minHeight: "44px",
    height: "fit-content",
    width: "100%",
    background: "#ffffff",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px",
    margin: "5px 0px"
  },
  autoMargin: {
    marginTop: "10px",
    marginBottom: "auto"
  }
}
