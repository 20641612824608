import React, { Component } from "react"
// import PropTypes from 'prop-types'
import "./CommitDrawer.css"
import MediaQuery from "react-responsive"
import SideBar from "../SideBar/SideBar"

class CommitDrawer extends Component {
  render() {
    return (
      <MediaQuery minWidth={769}>
        <SideBar style={{ ...this.props.style }}>
          {this.props.desktopMenu()}
        </SideBar>
      </MediaQuery>
    )
  }
}

export default CommitDrawer
