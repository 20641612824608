import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import colors from "../../Constants/colors"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"

const ApiTimeoutModal = (props) => {
  const closeModal = () => {
    window.location.reload()
    // dispatch(
    //   Actions.toggleModal({
    //     modal_mode: false,
    //     modal_usi: "apiTimeout"
    //   })
    // )
  }

  return (
    <div
      style={{
        ...props.style,
        color: colors.midnight
      }}
    >
      <ModalDialogHandler
        modal_usi="apiTimeout"
        paperProps={{
          style: { maxWidth: "800px", minWidth: "315px", width: "50%" }
        }}
        footer={
          <DialogActions>
            <CommitButtonWithoutClickState
              clickHandler={closeModal}
              id="return to module"
              type="red"
              style={{
                width: "150px"
              }}
            >
              Refresh Page{" "}
            </CommitButtonWithoutClickState>
          </DialogActions>
        }
      >
        <div
          style={{
            padding: "50px",
            backgroundColor: colors.background
          }}
        >
          <p>
            <b>
              Our servers are experiencing a higher than normal amount of
              volume. Bear with us! Try refreshing the page or try again later.
            </b>
          </p>
        </div>
      </ModalDialogHandler>
    </div>
  )
}

export default ApiTimeoutModal
