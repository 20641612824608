import React from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector } from "react-redux"
import Loader from "react-loader-spinner"
import PropTypes from "prop-types"

import colors from "../../Constants/colors"

const styles = {
  loaderContainer: {
    width: "100px",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    marginTop: "30px",
    height: "fit-content"
  },
  cardContainer: {
    display: "flex",
    flexDireciton: "row",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
    height: "fit-content",
    width: "calc(100vw - 66px)",
    maxWidth: "1295px",
    margin: "auto"
  }
}

const MembersPageInfiniteScroll = ({ loadMore, children, spouseDashboard}) => {
  const { fetchingSearchUsers, hasMore, page } = useSelector((state) => ({
    fetchingSearchUsers: state.networkUsers.fetchingSearchUsers,
    page: state.networkUsers.page,
    hasMore: state.networkUsers.page < state.networkUsers.lastPage
  }))

  const loadMoreUsers = () => {
    if (!fetchingSearchUsers && hasMore) {
      loadMore(page)
    }
  }

  if (spouseDashboard) {
    return <div style={styles.cardContainer}>{children}</div>
  }

  return (
    <InfiniteScroll
      useWindow={false}
      initialLoad={false}
      threshold={0}
      pageStart={0}
      hasMore={hasMore}
      loadMore={loadMoreUsers}
      loader={
        <div style={styles.loaderContainer} key={0}>
          <Loader
            type="Oval"
            color={colors.midnight}
            height="100"
            width="100"
          />
        </div>
      }
    >
      <div style={styles.cardContainer}>{children}</div>
    </InfiniteScroll>
  )
}

MembersPageInfiniteScroll.propTypes = {
  loadMore: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default MembersPageInfiniteScroll
