import React from "react"
import colors from "../../Constants/colors"
import "./PYPContentBorder.css"

const PYPContentBorder = (props) => {
  return <div className="underline-from-center" style={styles.border} />
}

const styles = {
  border: {
    width: "148px",
    height: "1px",
    backgroundColor: colors.fadedRed,
    marginTop: "40px",
    marginBottom: "40px",
    transition: "border-width 0.6s linear"
  }
}

export default PYPContentBorder
