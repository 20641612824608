import React, { Component } from "react"
import colors from "../../Constants/colors"

export const WithBottomBorder = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isHovered: false,
        isSelected: props.isSelected
      }
      this.hoverHandler = this.hoverHandler.bind(this)
    }

    hoverHandler() {
      this.setState({
        isHovered: !this.state.isHovered
      })
    }

    render() {
      const style =
        this.state.isHovered || this.props.isSelected
          ? {
              width: "100%",
              backgroundColor: colors.fadedRed,
              height: "2px",
              marginTop: "-2px"
            }
          : {
              width: "100%",
              height: "2px",
              marginTop: "-2px"
            }

      return (
        <div
          className="withBottomBorder"
          onMouseLeave={this.hoverHandler}
          onMouseEnter={this.hoverHandler}
          style={{ width: "fit-content" }}
        >
          <WrappedComponent {...this.props} />
          <div style={style} />
        </div>
      )
    }
  }
}
