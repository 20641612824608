import React from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import InputGroup from "../InputGroup/InputGroup"
import colors from "../../Constants/colors"

import "./ProfileProfessionalTwo.css"

const mapSelectOptions = (option, index) => {
  if (typeof option === "string") {
      return {
          id: index,
          value: option
      }
  }
  return {
      id: index,
      ...option
  }
}

const styleSheet = () => ({
  label: {
    fontFamily: "Lato-Regular",
    color: colors.midnight
  }
})

const inputs = [
  {
    id: 2,
    className: "class_preference",
    inputType: "select",
    userField: "career.class_preference",
    title: "Class Preference (typically a Thursday)",
    required: true,
    value: "",
    options: ["Thursday 12pm Eastern Time", "Wednesday 11am Pacific Time"].map(
        mapSelectOptions
    )
}
]

const ProfileProfessionalTwo = (props) => {
  const is_spouse = props.user.is_spouse
  const containerClass = !is_spouse ? "profileProfessionalContainer" : "profileTwoProfessionalContainer"
  const inputDataWithValues = inputs.map((input) => {
    const inputValue = Object.byString(
      props.application.application,
      input.userField
    )

    if (input.type === "dropdown") {
      return {
        ...input,
        value: inputValue === 0 ? "No" : "Yes"
      }
    }

    return {
      ...input,
      value: inputValue || ""
    }
  })

  return (
    <InputGroup
      {...props}
      containerClass={containerClass}
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) =>
        props.renderComponent({
          ...props,
          inputData,
          inputComponent,
          checkSubmittability
        })
      }
    />
  )
}

const mapStateToProps = (state) => ({
  application: state.application,
  user: state.user.user
})

export default connect(mapStateToProps)(
  withStyles(styleSheet)(ProfileProfessionalTwo)
)
