import types from "../../Actions/types"

/*
  We've used this file for several reducers, it's grown into more than just user-based reducers and should be broken up some
  time in the same way the rest of the Reducer is.
*/

export const analytics = (
  state = {
    fetchingAnalytics: false,
    analyticsData: {},
    startDate: null,
    endDate: null,
    buttonAnalytics: [],
    videoAnalytics: []
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_ANALYTICS_DATES:
      return {
        ...state,
        startDate: action.data.startDate,
        endDate: action.data.endDate
      }
    case types.FETCHING_ANALYTICS:
      return {
        ...state,
        fetchingAnalytics: true
      }
    case types.FETCHED_ANALYTICS:
      return {
        ...state,
        fetchingAnalytics: false,
        analyticsData: action.data.analyticsData,
        videoCategoryAnalytics: action.data.videoData.categoryBreakdown,
        videoTypeAnalytics: action.data.videoData.typeBreakdown
      }
    case types.FETCHED_BUTTON_ANALYTICS:
      return {
        ...state,
        buttonAnalytics: action.data.analyticsData
      }
    case types.FETCHED_VIDEO_ANALYTICS:
      return {
        ...state,
        videoAnalytics: action.data.analyticsData.videos,
        videoCategoryAnalytics: action.data.analyticsData.categoryBreakdown,
        videoTypeAnalytics: action.data.analyticsData.typeBreakdown
      }
    default:
      return {
        ...state
      }
  }
}

export const network = (
  state = {
    search_text: "",
    search_item: "name",
    filter_state: "",
    filter_interests: "",
    filter_branch: ""
  },
  action
) => {
  switch (action.type) {
    case types.UPDATE_SEARCH_PARAMS:
      return {
        ...state,
        search_text: action.data.search_text || "",
        search_item: action.data.search_item || "name",
        filter_state: action.data.filter_state || "",
        filter_interests: action.data.filter_interests || "",
        filter_branch: action.data.filter_branch || ""
      }
    case types.RESET_SEARCH_PARAMS:
      return {
        ...state,
        search_text: "",
        search_item: "name",
        filter_state: "",
        filter_interests: "",
        filter_branch: ""
      }
    default:
      return {
        ...state
      }
  }
}

export const networkUsers = (
  state = {
    users: [],
    page: 1,
    total: 10,
    lastPage: 10,
    fetchingSearchUsers: false
  },
  action
) => {
  switch (action.type) {
    case types.SET_SEARCH_USERS:
      return {
        fetchingSearchUsers: state.fetchingSearchUsers,
        users: action.data.users,
        total: action.data.total,
        page: action.data.page,
        lastPage: action.data.lastPage
      }
    case types.ADD_SEARCH_USERS:
      return {
        fetchingSearchUsers: state.fetchingSearchUsers,
        users:
          state.users.length <= action.data.limit * action.data.page
            ? state.users
                .slice(
                  0,
                  action.data.limit * action.data.page - 1 * action.data.limit
                )
                .concat(action.data.users)
            : state.users
                .slice(
                  0,
                  action.data.limit * action.data.page - 1 * action.data.limit
                )
                .concat(action.data.users)
                .concat(
                  state.users.slice(action.data.limit) * action.data.page
                ),
        total: action.data.total,
        page: action.data.page,
        lastPage: action.data.lastPage
      }
    case types.FETCHING_SEARCH_USERS:
      return {
        ...state,
        fetchingSearchUsers: action.data.toggle
      }
    default:
      return {
        ...state
      }
  }
}

export const api = (
  state = {
    apiToken: "",
    refreshToken: "",
    refreshingToken: false
  },
  action
) => {
  switch (action.type) {
    case types.SET_API_KEY:
      return {
        ...state,
        apiToken: `Bearer ${action.apiToken}`,
        refreshToken: action.refreshToken
      }
    case types.CLEAR_API_KEY:
      return {
        ...state,
        apiToken: "",
        refreshToken: "",
        refreshingToken: false
      }

    case types.REFRESHING_TOKEN:
      return {
        ...state,
        refreshingToken: true
      }

    case types.REFRESHED_TOKEN:
      return {
        ...state,
        apiToken: `Bearer ${action.data.token}`,
        refreshToken: action.data.refreshToken,
        refreshingToken: false
      }

    default:
      return {
        ...state
      }
  }
}

export const instructionalGuide = (
  state = {
    guide: [
      { id: 0, name: "navigation" },
      { id: 1, name: "dots" },
      { id: 2, name: "saveAndExit" }
    ],
    currentStepId: 0
  },
  action
) => {
  switch (action.type) {
    case types.LOGOUT:
      return {
        ...state,
        currentStepId: 0
      }
    // TODO: make this reset, not login
    case types.LOGIN:
      return {
        ...state,
        currentStepId: 0
      }
    case types.INCREMENT_INSTRUCTION_STEP:
      const incrementedStep = state.currentStepId + 1
      return {
        ...state,
        currentStepId:
          state.currentStepId + 1 < state.guide.length ? incrementedStep : -1
      }
    default:
      return {
        ...state
      }
  }
}

export const user = (
  state = {
    user: {},
    creatingAccount: false,
    fetchingUser: false,
    intervals: {},
    redirectUrl: "",
    loggingIn: false,
    isUpdatingProfileThumbnail: false,
    hasCheckedProfile: false
  },
  action
) => {
  switch (action.type) {
    case types.LOGGING_IN:
      return {
        ...state,
        loggingIn: true
      }
    case types.SET_LOGIN_REDIRECT:
      return {
        ...state,
        redirectUrl: action.data.url
      }
    // TODO: i think we can get rid of this
    case types.UPDATE_PATH_HISTORY:
      return {
        ...state,
        pathHistory: action.data
      }
    case types.ADD_TO_RECENTLY_WATCHED:
      return {
        ...state,
        user: {
          ...state.user,
          recentlyWatched: action.data
        }
      }
    case types.CREATING_ACCOUNT:
      return {
        ...state,
        creatingAccount: true
      }
    case types.LOGOUT:
      return {
        ...state,
        user: {}
      }
    case types.ADD_INTERVAL:
      return {
        ...state,
        intervals: {
          ...state.intervals,
          ...action.data
        }
      }
    case types.FETCHING_USER:
      return {
        ...state,
        fetchingUser: true
      }
    case types.FETCHED_USER:
      return {
        ...state,
        user: {
          ...user,
          ...action.user
        },
        fetchingUser: false
      }
    case types.LOGIN:
      return {
        ...state,
        creatingAccount: false,
        redirectUrl: "",
        loggingIn: false,
        user: {
          ...action.user
        }
      }
    case types.UPDATING_PROFILE_THUMBNAIL:
      return {
        ...state,
        isUpdatingProfileThumbnail: true
      }
    case types.UPDATED_PROFILE_THUMBNAIL:
      return {
        ...state,
        isUpdatingProfileThumbnail: false
      }
    case types.TOGGLE_HAS_CHECKED_PROFILE:
      return {
        ...state,
        hasCheckedProfile: action.data
      }
    default:
      return {
        ...state,
        creatingAccount: false
      }
  }
}

export const modal = (
  state = {
    modal: {
      cropProfile: false,
      videoUpload: false,
      coachesNotes: false,
      apiTimeout: false,
      programDisabled: false,
      simpleModal: false
    },
    image_for_cropping: null,
    text: ""
  },
  action
) => {
  switch (action.type) {
    case types.TOGGLE_MODAL_CROP:
      return {
        ...state,
        ...action.data,
        modal: {
          ...state.modal,
          ...action.data.modal
        }
      }
    case types.TOGGLE_MODAL: {
      const { modal: modalData, title, text } = action.data

      return {
        ...state,
        modal: {
          ...modalData
        },
        title,
        text
      }
    }
    default:
      return state
  }
}

export const errors = (
  state = {
    exception: null,
    message: null,
    hasError: false,
    openErrorDialog: false,
    rollbar: null
  },
  action
) => {
  switch (action.type) {
    case types.EXCEPTION:
      return {
        ...state,
        exception: action.data
      }
    case types.SETUP_ROLLBAR:
      return {
        ...state,
        rollbar: action.data.rollbar
      }
    case types.LOGIN:
      return {
        exception: null,
        message: null,
        hasError: false,
        openErrorDialog: false
      }
    case types.RESET_ERRORS:
      return {
        ...state,
        hasError: false,
        errorData: null,
        exception: null,
        message: null,
        openErrorDialog: false
      }
    case types.OPEN_ERROR_MODAL:
      return {
        ...state,
        openErrorDialog: true,
        message: action.data.message.toString()
      }
    case types.ERROR:
      if (
        (action.message && `${action.message}`.includes("Error: timeout")) ||
        (action.data && `${action.data}`.includes("Error: timeout"))
      ) {
        return {
          ...state,
          openErrorDialog: true
        }
      }
      return {
        ...state,
        hasError: true,
        errorData: action.data
      }

    // the exceptions should really only display shortly
    // after something happens. If ANYTHING else happens,
    // we should probably remove it
    default:
      return {
        ...state,
        //    exception: null,
        message: null,
        openErrorDialog: false
      }
  }
}

// TODO: should probably separate these out into their own reducers
export default {
  errors,
  user,
  api,
  instructionalGuide,
  modal,
  analytics,
  network,
  networkUsers
}
