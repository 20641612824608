import React from "react"
import colors from "../../Constants/colors"
import { WithBottomBorder } from "../WithBottomBorder/WithBottomBorder"

const TransparentButton = ({ children, onClick, style }) => {
  return (
    <div onClick={onClick} style={{ ...styles.text, ...style }}>
      {children}
    </div>
  )
}

const styles = {
  text: {
    cursor: "pointer",
    color: colors.fadedRed,
    fontSize: "16px",
    fontFamily: "Lato-Regular"
  }
}

export default WithBottomBorder(TransparentButton)
