import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core"
import MediaQuery from "react-responsive"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ShowMoreText from "react-show-more-text"
import readIcon from "../../Assets/Images/icons/CF_WebIcons_SpouseProgram_Read.svg"
import watchIcon from "../../Assets/Images/icons/CF_WebIcons_SpouseProgram_Watch.svg"
import doIcon from "../../Assets/Images/icons/CF_WebIcons_SpouseProgram_Do.svg"
import { updateUserActivityStatuses } from "../../Redux/Actions/Workshop/functions"

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  rootMobile: {
    marginLeft: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    borderRadius: "50%",
    width: 20,
    height: 20,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
      borderRadius: "50%"
    }
  },
  checkedIcon: {
    backgroundColor: "#14A14B",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
      "input:hover ~ &": {
        backgroundColor: "#14A14B",
        borderRadius: "50%"
      }
    }
  },
  label: {
    lineHeight: "20px",
    fontSize: "18px",
    fontWeight: "500px"
  },
  formGroupCheckbox: {
    marginTop: "10px"
  }
})

const ActivityItem = ({ activity }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const { user } = useSelector((state) => ({
    user: state.user.user
  }))

  const newActivityCompletedStatus = activity.userActivityStatuses?.completed

  const getLogo = (activity_type) => {
    const logos = {
      read: <img src={readIcon} alt="readIcon" />,
      watch: <img src={watchIcon} alt="watchIcon" />,
      do: <img src={doIcon} alt="doIcon" />
    }

    return logos[activity_type]
  }

  const updateActivityStatus = (activityId, userId, completedStatus) => {
    dispatch(updateUserActivityStatuses(activityId, userId, completedStatus))
  }

  const descriptionLineBreaks = (string) => string.replace(/;/g, "\n")

  return (
    <div key={activity.id} className="activity-container">
      <div className="activity-logo">{getLogo(activity.type)}</div>
      <div className="activity-info">
        <div className="activity-title">{activity.title}</div>
        <div className="activity-description">
          <ShowMoreText
            /* Default options */
            lines={2}
            more={
              <div>
                Show More <ExpandMoreIcon />
              </div>
            }
            less={
              <div>
                Show Less <ExpandLessIcon />
              </div>
            }
            className="content-css"
            anchorClass="show-more-collapse"
            expanded={false}
            keepNewLines
            truncatedEndingComponent="..."
          >
            {descriptionLineBreaks(activity.description)}
          </ShowMoreText>
        </div>
      </div>
      <a
        rel="noopener noreferrer"
        target="_blank"
        href={activity.link}
        className="activity-button"
      >
        Start Activity
      </a>
      <MediaQuery minWidth={651}>
        <div className="activity-completed-checkbox-container">
          <div>
            <Checkbox
              className={classes.root}
              checkedIcon={
                <span className={`${classes.icon} ${classes.checkedIcon}`} />
              }
              disableRipple
              color="default"
              icon={<span className={classes.icon} />}
              checked={newActivityCompletedStatus}
              onChange={() =>
                updateActivityStatus(
                  activity.id,
                  user.id,
                  !activity.userActivityStatuses?.completed
                )
              }
            />
          </div>
          <div
            className={`completed-column-title ${
              newActivityCompletedStatus && "completed"
            }`}
          >
            {newActivityCompletedStatus ? "Completed" : "Mark as completed"}
          </div>
        </div>
      </MediaQuery>
      {/* Mobile */}
      <MediaQuery maxWidth={650}>
        <FormGroup className={classes.formGroupCheckbox}>
          <FormControlLabel
            control={
              <Checkbox
                className={classes.rootMobile}
                checkedIcon={
                  <span className={`${classes.icon} ${classes.checkedIcon}`} />
                }
                disableRipple
                color="default"
                icon={<span className={classes.icon} />}
                checked={newActivityCompletedStatus}
                onChange={() =>
                  updateActivityStatus(
                    activity.id,
                    user.id,
                    !activity.userActivityStatuses?.completed
                  )
                }
              />
            }
            label={
              <span
                className={`completed-column-title ${
                  newActivityCompletedStatus && "completed"
                }`}
              >
                {newActivityCompletedStatus ? "Completed" : "Mark as completed"}
              </span>
            }
          />
        </FormGroup>
      </MediaQuery>
    </div>
  )
}

export default ActivityItem
