import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import AddToCalendar from "react-add-to-calendar-customizable-ui"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
import OutsideClickWrapper from "../../Hooks/outsideClickWrapper"
import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer"
import PYPPartReflectTitleContent from "../PYPPartReflectTitleContent/PYPPartReflectTitleContent"
import "./layoutMetaDataCalender.css"

const styles = {
  listItem: {
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    lineHeight: "17px",
    borderBottom: "1px solid #E8E8E8",
    paddingBottom: "10px",
    paddingTop: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "110px",
    textAlign: "left"
  },
  lastListItem: {
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    lineHeight: "17px",
    border: "none",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "110px",
    textAlign: "left"
  },
  innerListElement: {
    color: colors.midnight,
    textDecoration: "none",
    textAlign: "left",
    width: "110px"
  },
  date: {
    color: colors.fadedGray,
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "17px",
    height: "fit-content"
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    alignItems: "center"
  },
  dropdownList: {
    listStyleType: "none",
    transition:
      "all 0.3s ease-in-out 0.5s, visibility 0.5s linear 0.3s,z-index 0s linear 10ms, opacity 0.5s ease-in-out",
    transitionDelay: "0s, 0s, 0.3s",
    transformOrigin: "top center",
    position: "absolute",
    right: "-59px",
    top: "45px",
    backgroundColor: colors.white,
    width: "156px",
    height: "fit-content",
    padding: "5px 4px",
    boxShadow: "0px 10px 30px rgba(0,0,0,0.2)"
  },
  rectangle: {
    content: "",
    alignItems: "center",
    width: "4px",
    height: "100%",
    minHeight: "44px",
    borderRadius: "3px 0 0 3px",
    display: "flex",
    minWidth: "4px" // This is very important, will prevent css overrides
  },
  calenderIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer"
  },
  titleContainerStyles: {
    paddingLeft: "15px",
    paddingRight: "15px",
    margin: "auto",
    width: "441px"
  },
  titleContent: {
    color: colors.midnight
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    height: "fit-content",
    paddingLeft: "2vw",
    paddingRight: "1vw",
    width: "100%",
    alignItems: "center"
  },
  container: {
    backgroundColor: "inherit",
    width: "600px",
    maxHeight: "371px",
    overflow: "scroll",
    maxWidth: "100%",
    minWidth: "325px",
    margin: "auto",
    marginTop: "25px",
    marginBottom: "25px"
  },
  bar: {
    minWidth: "268px",
    minHeight: "44px",
    width: "calc(100% - 116px)",
    background: "#ffffff",
    borderRadius: "3px",
    margin: "5px auto",
    height: "fit-content",
    color: colors.midnight,
    paddingRight: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative"
  },
  secondaryContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft: "51px",
    width: "calc(100% - 55px)"
  },
  title: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    color: "#030D26"
  }
}
const dateSortingFunction = (a, b) => {
  if (a.time) {
    if (b.time) {
      return moment(b.time).isBefore(a.time) ? 1 : -1
    }
    return -1
  }
  if (b.time) {
    return 1
  }
  return 0
}

export default function LayoutMetaDataCalender(props) {
  const [selectedId, toggleSelectedId] = useState(-1)
  const [highlightedId, toggleHighlightedId] = useState(-1)

  const dispatch = useDispatch()
  const stateData = useSelector((state) => {
    const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
    const partDataId = currentPart.partData

    let metadata = { title: null, description: null }
    let dateEvents = []
    let start = {}
    let end = {}

    const partData =
      state.pyp.partData.byId[partDataId] &&
      state.pyp.partData.byId[partDataId].data

    if (partData) {
      metadata = partData.partTitle[0]
      start = partData.referencedPartData.start
      end = partData.referencedPartData.end
      dateEvents = partData.referencedPartData.dateEvents.map((event) => event)
      dateEvents = dateEvents
        .filter((event) => event.time)
        .sort((a, b) => dateSortingFunction(a, b))
    }
    return {
      pyp: state.pyp,
      dateEvents,
      metadata,
      start,
      end
    }
  })

  useEffect(() => {
    dispatch(Actions.fetchPartData())
  }, [dispatch])

  const calenderBox = (dateEvent, key, enabled = true) =>
    dateEvent.title ? (
      <div
        className="layoutCalendarbarGraph"
        key={key}
        style={{ ...styles.bar }}
      >
        <div
          className="layoutCalendarRectangle"
          style={{
            ...styles.rectangle,
            backgroundColor: key ? "#00D061" : "purple"
          }}
        />
        <div style={styles.secondaryContent}>
          <div style={styles.title}>
            {dateEvent.title.length > 40
              ? `${dateEvent.title.substr(0, 40)}...`
              : dateEvent.title}
          </div>
          <div style={styles.dateContainer}>
            <div style={{ ...styles.date, marginRight: key ? "13px" : "37px" }}>
              {moment(dateEvent.time, "YYYY-MM-DD").format("MMM DD")}
            </div>
            {enabled ? (
              <OutsideClickWrapper
                clickHandler={() => {
                  if (
                    key === selectedId &&
                    (!highlightedId || highlightedId < 1)
                  ) {
                    toggleHighlightedId(-1)
                    toggleSelectedId(-1)
                  }
                }}
                onClickHandler={() => {
                  if (!(key === selectedId)) {
                    toggleSelectedId(key)
                  }
                }}
              >
                <div
                  className="burgeParentDiv"
                  onMouseEnter={() => {
                    toggleHighlightedId(key)
                  }}
                  onMouseLeave={() => toggleHighlightedId(-1)}
                >
                  <AddToCalendar
                    dropdownListLastElementStyles={styles.lastListItem}
                    dropdownListElementStyles={styles.listItem}
                    dropdownItemStyles={styles.innerListElement}
                    dropdownListStyles={{
                      ...styles.dropdownList,
                      zIndex: key === selectedId ? 999 : 1
                    }}
                    // dropdownContainerStyles={{
                    // }}
                    customButtonLabel={
                      <CalendarTodayIcon
                        style={{
                          ...styles.calenderIcon,
                          color:
                            key === highlightedId || key === selectedId
                              ? "#737373"
                              : "#E9E9EA"
                        }}
                      />
                    }
                    useCustomButtonLabel
                    optionsOpen={key === selectedId}
                    buttonLabel="E"
                    event={{
                      title: dateEvent.title,
                      description: "none",
                      location: "",
                      startTime: moment(dateEvent.time, "YYYY-MM-DD").format(
                        "YYYYMMDD"
                      ),
                      endTime: moment(dateEvent.time, "YYYY-MM-DD").add(
                        1,
                        "day"
                      ),
                      allDay: true
                    }}
                    // The nonbreaking spaces below \xa0 are used to extend a list element link to the width of it's parent
                    listItems={[
                      {
                        apple: "Apple Calendar"
                      },
                      {
                        google:
                          "Google\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                      },
                      {
                        outlook:
                          "Outlook\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                      },
                      {
                        outlookcom: "Outlook.com\xa0\xa0\xa0\xa0\xa0"
                      },
                      {
                        yahoo:
                          "Yahoo\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                      }
                    ]}
                  />
                </div>
              </OutsideClickWrapper>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    ) : (
      <></>
    )

  const renderedData =
    stateData && stateData.start ? (
      <div style={styles.container}>
        {/* calenderBox start and end have index values 0 and false respectively to separate their styling - note they cannot have the same index value! */}
        {calenderBox(stateData.start, 0, false)}
        {stateData.dateEvents.map((event, index) =>
          calenderBox(event, index + 1)
        )}
        {calenderBox(stateData.end, false, false)}
      </div>
    ) : (
      <></>
    )
  return (
    <PYPLayoutContainer
      backgroundColor={colors.background}
      style={{
        ...styles.layoutContainer
      }}
    >
      <PYPPartReflectTitleContent
        className="layoutCalendarTitleContainer"
        title={stateData.metadata.title}
        description={stateData.metadata.description}
        style={styles.titleContainerStyles}
        textStyle={styles.titleContent}
      />
      {renderedData}
    </PYPLayoutContainer>
  )
}
