import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Actions from "../../Redux/Actions"
import VideoCategoryWrapper from "../VideoCategoryWrapper/VideoCategoryWrapper"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"

export default function SingleFilteredPlaylist({ id }) {
  const dispatch = useDispatch()

  const { playlists, fetching } = useSelector(
    (state) => state.digitalLibrary.transition
  )

  useEffect(() => {
    const fetchPlaylistById = () => {
      dispatch(Actions.fetchPlaylistById(id))
    }

    fetchPlaylistById()
  }, [])

  return (
    <VideoCategoryWrapper
      clearVideos={() => console.log("all videos")}
      category={{}} // name: playlists.title }}
      videoCount={playlists.videos && playlists.videos.length}
      notAllVideosShown={false}
    >
      <VideoThumbnailList
        from="transition"
        playlist={playlists.title}
        loaded={fetching}
        skeletonLoaderCount={8}
        style={{
          justifyContent: "flex-start"
        }}
        dynamicThumbnailCountOnResize={false}
        videos={
          (playlists && playlists.videos) || [{}, {}, {}, {}, {}, {}, {}, {}]
        }
      />
    </VideoCategoryWrapper>
  )
}
