import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import RoadmapperDraggableContainer from "../RoadmapperDraggableContainer/RoadmapperDraggableContainer"

import Actions from "../../Redux/Actions"

/*  Component Functionality
  -Pull in data for part (ideal personal/work day)
  -Aggregate data into array of time events + constraints
  -Aggregate top 10 values
*/

const styles = {
  container: {
    alignItems: "center",
    textAlign: "center"
  },
  layoutContainer: {
    justifyContent: "start"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let dateEvents = null
  let eventOrder = null
  let constraints = []

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data

  if (partData) {
    const dateSortingFunction = (a, b) => {
      if (a.moment_data) {
        if (b.moment_data) {
          return moment(b.moment_data).isBefore(a.moment_data) ? 1 : -1
        }
        return -1
      }
      if (b.moment_data) {
        return 1
      }
      return 0
    }
    metadata = partData.partTitle[0]
    constraints = {
      start:
        partData.referencedPartData.constraints[0] &&
        moment(partData.referencedPartData.constraints[0].text, "YYYY:MM:DD"),
      end:
        partData.referencedPartData.constraints[1] &&
        moment(partData.referencedPartData.constraints[1].text, "YYYY:MM:DD"),
      startId:
        partData.referencedPartData.constraints[0] &&
        partData.referencedPartData.constraints[0].id,
      endId:
        partData.referencedPartData.constraints[1] &&
        partData.referencedPartData.constraints[1].id
    }
    dateEvents = partData.userDateEvents.map((event) => event)
    const sortedEventOrder = dateEvents.sort((a, b) =>
      dateSortingFunction(a, b)
    )
    eventOrder = sortedEventOrder.map((event) => event.id)
  }
  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    initializedPart: state.pyp.initializedPart,
    fetchingPartData: state.pyp.fetchingPartData,
    initializingPYPInputs: state.pyp.initializingPYPInputs,
    dateEvents,
    eventOrder,
    constraints
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class LayoutMetaDataActionPlanner extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        dateEvents: null,
        loading: true
      }
    }

    componentDidMount() {
      if (!this.props.pyp.savingPYP) {
        this.props.fetchPartData().then(() => this.setState({ loading: false }))
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
        this.props.fetchPartData().then(() => this.setState({ loading: false }))
      }
    }

    render() {
      return (
        <PYPLayoutContainer
          backgroundColor={colors.background}
          style={{
            ...styles.layoutContainer
          }}
        >
          <PYPPartTitleContent
            title={this.props.metadata.title}
            description={this.props.metadata.description}
          />
          <div style={styles.container} id="DraggableContainer">
            <RoadmapperDraggableContainer
              calender
              momentFormat={this.props.momentFormat || ["MMM Do", "MMM Do"]}
              momentJoiner={this.props.momentJoiner || " - "}
              timeConstraints={{
                start:
                  (this.props.constraints && this.props.constraints.start) ||
                  moment("2020-10-20", "YYYY:MM:DD"),
                end:
                  (this.props.constraints && this.props.constraints.end) ||
                  moment("2020-10-21", "YYYY:MM:DD")
              }}
              loading={this.state.loading}
              durationUnit="days"
              userTimeEvents={this.props.dateEvents}
              eventOrder={this.props.eventOrder}
            />
          </div>
        </PYPLayoutContainer>
      )
    }
  }
)
