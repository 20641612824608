/*
  This is a middleware that every API call goes through. It's a useful layer for efficiently adding things shared between every API function, 
  like error handling and token refreshing.
*/

import axios from "axios"
import jwtDecode from "jwt-decode"
import moment from "moment"
import { clear } from "redux-localstorage-simple"
import constants from "../../Constants"
import types from "../Actions/types"

const host = constants.HOST

const apiCallMap = {
  GET: axios.get,
  POST: axios.post,
  DELETE: axios.delete,
  PUT: axios.put
}

const statusesToRedirectToLogin = [419, 406, 401, 403]

export const fetchMiddleware = (rollbar) => (store) => (next) => async (
  action
) => {
  if (action.type === "FETCH") {
    try {
      let response
      const token = store.getState().api.apiToken
      const apiFn = apiCallMap[action.params.type]

      // TODO: get this into it's own redux middleware
      const decoded = token && jwtDecode(token)
      const issuedAt = decoded.iat
      const expiresAt = decoded.exp
      const validSeconds = expiresAt - issuedAt
      const halfOfTokenExp = 0.5 * validSeconds
      const nowInSeconds = moment().valueOf() / 1000

      if (
        expiresAt - nowInSeconds < halfOfTokenExp &&
        !store.getState().api.refreshingToken
      ) {
        store.dispatch({
          type: types.REFRESHING_TOKEN
        })

        const { refreshToken } = store.getState().api
        const tokenRes = await axios.post("/v1/refreshLogin", {
          refreshToken
        })

        store.dispatch({
          type: types.REFRESHED_TOKEN,
          data: {
            token: tokenRes.data.token,
            refreshToken: tokenRes.data.refreshToken
          }
        })
      }

      if (action.params.type === "GET") {
        response = await apiFn(`${host}${action.url}`, {
          timeout:
            (action.requestMetaData && action.requestMetaData.timeout) ||
            constants.DEFAULT_API_TIMEOUT_MS,
          params: action.params.data && action.params.data.params,
          headers: { Authorization: token, mode: "cors" }
        })
      } else if (
        action.params.type === "POST" ||
        action.params.type === "PUT"
      ) {
        response = await apiFn(
          `${host}${action.url}`,
          action.params.data.requestPayload,
          {
            timeout:
              (action.requestMetaData && action.requestMetaData.timeout) ||
              constants.DEFAULT_API_TIMEOUT_MS,
            headers: {
              ...action.params.data.headers,
              environment: process.env.REACT_APP_ENV,
              Authorization: token || action.params.data.token,
              mode: "cors"
            }
          }
        )
      } else if (action.params.type === "DELETE") {
        response = await apiFn(`${host}${action.url}`, {
          data:
            action.params &&
            action.params.data &&
            action.params.data.requestPayload,
          timeout:
            (action.requestMetaData && action.requestMetaData.timeout) ||
            constants.DEFAULT_API_TIMEOUT_MS,
          headers: { Authorization: token, mode: "cors" }
        })
      }
      return response
    } catch (e) {
      if (
        e.toString() ===
        `Error: timeout of ${
          (action.requestMetaData && action.requestMetaData.timeout) ||
          constants.DEFAULT_API_TIMEOUT_MS
        }ms exceeded`
      ) {
        store.dispatch({
          type: types.TOGGLE_MODAL,
          data: {
            redirectUri: "/account/login",
            modal: {
              apiTimeout: true
            }
          }
        })
      }

      if (e.response && statusesToRedirectToLogin.includes(e.response.status)) {
        if (e.response.status === 406) {
          rollbar &&
            rollbar.info(
              `Invalid Refresh Token - ${
                e.response ? JSON.stringify(e.response.data) : e.toString()
              }, id: ${store.getState().user.user.id}`
            )
        }

        clear()
        store.dispatch({
          type: types.CLEAR_API_KEY
        })

        store.dispatch({
          type: types.ERROR,
          data: {
            redirectUri: "/account/login"
          }
        })
        throw e
      } else {
        rollbar &&
          rollbar.error(
            `Caught in ApiWrapper - ${
              e.response ? JSON.stringify(e.response.data) : e.toString()
            }, id: ${store.getState().user.user.id}`
          )
        throw e
      }
    }
  } else {
    return next(action)
  }
}
