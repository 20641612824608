import React from "react"
import PersonalInfoInputs from "../PersonalInfoInputs/PersonalInfoInputs"
import LocationInputs from "../LocationInputs/LocationInputs"
import TimelineInputs from "../TimelineInputs/TimelineInputs"
import PersonalInfoInputsThree from "../PersonalInfoInputsThree/PersonalInfoInputsThree"
import MilitaryOne from "../MilitaryOne/MilitaryOne"
import BiographyOne from "../BiographyOne/BiographyOne"
import BiographyTwo from "../BiographyTwo/BiographyTwo"
import BiographyThree from "../BiographyThree/BiographyThree"
import CareerOne from "../CareerOne/CareerOne"
import { TransitionOne, TransitionGoals } from "../Transition"
import SubmissionOne from "../SubmissionOne/SubmissionOne"
import ApplicationIntroduction from "../ApplicationIntroduction"

const applicationHashMap = {
  "introduction-video": () => {
    return <ApplicationIntroduction />
  },
  "personal_info-basic": (props) => {
    return (
      <PersonalInfoInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "personal_info-address": (props) => {
    return (
      <LocationInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "personal_info-timeline": (props) => {
    return (
      <TimelineInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  relocation: (props) => {
    return (
      <PersonalInfoInputsThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "military-basic": (props) => {
    return (
      <MilitaryOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  biography: (props) => {
    return (
      <BiographyOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-motivations": (props) => {
    return (
      <BiographyTwo
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-top_priorities": (props) => {
    return (
      <BiographyThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "career-interested_industries": (props) => {
    return (
      <CareerOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "transition-challenges": (props) => {
    return (
      <TransitionOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "transition-goals": () => <TransitionGoals />,
  submission: (props) => {
    return (
      <SubmissionOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  }
}

export default applicationHashMap
