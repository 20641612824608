import React from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"

import InputGroup from "../InputGroup/InputGroup"
import colors from "../../Constants/colors"

import "./ProfileProfessionalOne.css"

const styleSheet = () => ({
  label: {
    fontFamily: "Lato-Regular",
    color: colors.midnight
  }
})

const inputs = [
  {
    id: 0,
    className: "currentJob",
    inputType: "text",
    userField: "community.current_job",
    placeHolder: "Current Job (optional)",
    required: false,
    value: ""
  },
  {
    id: 2,
    className: "looking_change",
    inputType: "select",
    userField: "looking_change",
    options: [
      { id: 0, value: "Yes" },
      { id: 1, value: "No" }
    ],
    title: `Are you looking to make a career change?`
  }
]

const PersonalInfoInputs = (props) => {
  const is_spouse = props.user.is_spouse
  const containerClass = !is_spouse ? "profileProfessionalContainer" : "profileTwoProfessionalContainer"
  const inputDataWithValues = inputs.map((input) => {
    const inputValue = Object.byString(
      props.application.application,
      input.userField
    )

    if (input.type === "dropdown") {
      return {
        ...input,
        value: inputValue === 0 ? "No" : "Yes"
      }
    }

    return {
      ...input,
      value: inputValue || ""
    }
  })

  return (
    <InputGroup
      {...props}
      containerClass={containerClass}
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) =>
        props.renderComponent({
          ...props,
          inputData,
          inputComponent,
          checkSubmittability
        })
      }
    />
  )
}

const mapStateToProps = (state) => ({
  application: state.application,
  user: state.user.user
})

export default connect(mapStateToProps)(
  withStyles(styleSheet)(PersonalInfoInputs)
)
