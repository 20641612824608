import React from "react"
import { withRouter } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import MediaQuery from "react-responsive"
import colors from "../../Constants/colors.js"
import VideoSearchBarContainer from "../VideoSearchBarContainer/VideoSearchBarContainer"
import "./VideoLibraryHeader.css"
import AllVideosBackground from "../../Assets/Images/allVideos.png"
import IndustryVideosBackground from "../../Assets/Images/industry.png"
import InsightsVideosBackground from "../../Assets/Images/insights.png"
import Actions from "../../Redux/Actions"

const VideoLibraryHeader = (props) => {
  const { match } = props

  const getHeaderText = (type) => {
    switch (type) {
      case "playlist":
        return "My Playlist"
      case "recommended":
        return "Recommended"
      case "recentlyWatched":
        return "Recently Watched"
      case "categories":
        return typeMap[match.params.type]
      case "results":
        return typeMap[match.params.type]
      case "search":
        return "Search Results"
      case "filtered_playlists":
        return "Journey"
      case "playlist_with_title":
        if (props.playlist_title) {
          document.title = `Playlist | ${props.playlist_title}`
          window.dataLayer.push({
            page_view: {
              page_title: document.title
            }
          })
        }
        return props.playlist_title
      default:
        return actionMap[match.params.action]
    }
  }

  const getHeaderBanner = (type) => {
    switch (type) {
      case "Industry":
        return IndustryVideosBackground
      case "Insights":
        return InsightsVideosBackground
      default:
        return AllVideosBackground
    }
  }

  const actionMap = {
    "my playlist": "Playlist",
    recentlyWatched: "Recently Watched"
  }

  const typeMap = {
    home: "Home",
    insights: "Insights",
    industry: "Industry",
    search: "Search Results",
    full_interviews: "Full Interviews",
    my_playlist: "Playlist",
    my_videos: "My Videos",
    recommended: "Recommended",
    filtered_playlists: "Journey"
  }
  return (
    <div
      style={{
        ...styles.componentContainer,
        ...props.style,
        backgroundImage: `url(${getHeaderBanner(
          typeMap[match.params.type]
        )}), linear-gradient(to bottom, rgba(41, 46, 76, 0.9), rgba(41, 46, 76, 0.9)), linear-gradient(to left, ${
          colors.midnight
        }, rgba(3, 13, 38, 0.45) 53%, ${colors.midnight}) `
      }}
    >
      <div
        className="videoLibraryHeaderContainer"
        style={{ marginBottom: "40px" }}
      >
        <h1 className="libraryHeaderContainer" style={styles.videoTypeText}>
          {" "}
          {getHeaderText(props.for)}
        </h1>
        <div
          className="commitDropdown"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{
              marginRight: "25px"
            }}
          />
          <MediaQuery minWidth={768}>
            <VideoSearchBarContainer className="commitSearchbar" />
          </MediaQuery>
        </div>
      </div>
    </div>
  )
}

const styles = {
  componentContainer: {
    width: "100%",
    height: "245px",
    display: "flex",
    flexDirection: "center",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0,
    backgroundBlendMode: "multiply, normal",
    backgroundSize: "cover"
  },
  videoTypeText: {
    fontFamily: "Lato-Bold",
    fontSize: "50px",
    whiteSpace: "nowrap",
    lineHeight: "42px",
    color: colors.white
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    playlist_title:
      state.digitalLibrary &&
      state.digitalLibrary.transition &&
      state.digitalLibrary.transition.playlists &&
      state.digitalLibrary.transition.playlists.title
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoLibraryHeader)
)
