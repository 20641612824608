import React, { useEffect, useState } from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch, useSelector } from "react-redux"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import Actions from "../../Redux/Actions"
import { withRouter } from "react-router-dom";
import { profileMetadata } from "../../Redux/Reducers/Application/profileMetadata"
import { spouseProfileMetadata } from "../../Redux/Reducers/SpouseApplication/spouseProfileMetadata"

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
  s = s.replace(/^\./, "") // strip a leading dot
  const a = s.split(".")
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (o && k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

Object.size = function(arr) {
  var size = 0;
  for (var key in arr) {
      if (arr.hasOwnProperty(key)) size++;
  }
  return size;
};

const modalUsi = "updateProfileModal"

const styles = {
  footerButton: {
    width: "100px",
    marginLeft: "20x",
    marginRight: "26px",
    marginBottom: "20px"
  },
  modalContainer: {
    maxWidth: "800px",
    width: "85%",
    background: "#F5F6F8"
  },
  modalOverlay: {
    padding: "40px"
  },
  biographyLabel: {
    fontFamily: "Lato-Regular",
    fontSize: "14px"
  },
  updateProfileModalText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px"
  }
}


const UpdateProfileModal = (props) => {
  const dispatch = useDispatch();
  //const user = useSelector((state) => (state.user?.user));
  const { user, application } = useSelector((state) => ({
    user: state.user?.user,
    application: state.application?.application
  }));

  const [missingFieldsArray, setMissingFieldsArray] = useState([]);
  const [missingFieldsElems, setMissingFieldsElems] = useState([]);
  const [hasBeenSixty, setHasBeenSixty] = useState(false);

  const nameMappings = {
    "firstName": "First Name",
    "lastName": "Last Name",
    "email": "Email",
    "nickName": "Preferred Name/Nickname (Optional)",
    "linkedin_url": "LinkedIn URL (Optional)",
    "phoneNumber": "Phone Number",
    "interests_for_profile": 'What industries are you interested in?',
    "profession.otherInterest": 'What other industries are you interested in?',
    "address.streetAddress": "Street Address",
    "address.city": "City",
    "address.state": "State",
    "address.zipCode": "Zip Code",
    "address.country": "Country",
    "military.branch": "Branch",
    "military.specialty": 'Military Specialty',
    "military.lastRank": "Final Rank",
    "military.lastDutyPosition": "Last Duty Position",
    "biography.biography": 'Write your biography',
    "community.current_job": "Current Job (optional)",
  }
  useEffect(() => {
    if(typeof application != 'undefined' && !application.hasOwnProperty('id')){
      dispatch(Actions.fetchApplication("initialLoad", props.history))
    }
  }, [])

  useEffect(() =>{
    let isFieldMissing = false;
    let fieldValue;
    let newMissingFieldsArray = [];
    
    if(typeof application != 'undefined' && Object.keys(application).length > 0 && application.hasOwnProperty('id')){
      const userProfileMeta = application.is_spouse ? spouseProfileMetadata : profileMetadata
      for (let index = 0; index < userProfileMeta.length; index++){

        let inputs = userProfileMeta[index].inputs;

        for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++){

          fieldValue = Object.byString(
            application,
            inputs[inputIndex].userField
          );
          isFieldMissing =  inputs[inputIndex].userField == 'interests_for_profile' ? fieldValue?.length < 5 && Object.byString(application, inputs[inputIndex + 1].userField
          ): !fieldValue
          if( isFieldMissing && !inputs[inputIndex].optional){
            //&& !inputs[inputIndex].optional
          //if (true){
            //if (!newMissingFieldsArray.some(e => e.Name === userProfileMeta[index].title)) console.log('adding', userProfileMeta[index].title)

            if (!newMissingFieldsArray.hasOwnProperty(userProfileMeta[index].title)){
              newMissingFieldsArray[userProfileMeta[index].title] = []
            }
            newMissingFieldsArray[userProfileMeta[index].title].push(inputs[inputIndex].userField) 
          }
        }
      }
    }
    setMissingFieldsArray(newMissingFieldsArray)
  }, [application])

  useEffect(() => {
    if (user.last_activity != null){
      const lastActivityDate = new Date(user.last_activity);
      const sixtyDaysAgo = new Date();
      sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
      setHasBeenSixty(lastActivityDate < sixtyDaysAgo)
    }

  }, [user])

  useEffect(() => {
    let sectionElements = [];
    let inputElements;
    for (let section in missingFieldsArray) {
      inputElements = [];
      if (missingFieldsArray[section].length > 0){
        for (let index = 0; index < missingFieldsArray[section].length; index++){
          inputElements.push(<li key={index}>{nameMappings[missingFieldsArray[section][index]]}</li>)
        }
        sectionElements.push(
          <div key={section}>
            <h6>{section}</h6>
            <ul style={styles.updateProfileModalText}>
                {inputElements}
            </ul>
          </div>
        )
      } 

    }
    setMissingFieldsElems(sectionElements)
  }, [missingFieldsArray])


  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: modalUsi
      })
    );
    dispatch(Actions.toggleHasCheckedProfile(true))
  }

  return (
    <ModalDialogHandler
      modal_usi={modalUsi}
      paperProps={{ style: styles.modalContainer }}
      showCloseButton
      footer={
        <DialogActions>
          <CommitButtonWithoutClickState
            clickHandler={() => {
              closeModal();
              dispatch(Actions.toggleHasCheckedProfile(true));
              props.history.push('/profile?title=personal');
            }}
            id="updateProfileModal"
            type="white"
            style={styles.footerButton}
          >
            Go to profile
          </CommitButtonWithoutClickState>
        </DialogActions>
      }
    >
      <div style={styles.modalOverlay}>
        <h5>Update Profile</h5>
        <hr></hr>
        <p style={styles.updateProfileModalText}>
            {
              Object.size(missingFieldsArray) > 0 ?
              'We are still missing some information in your profile. Please review the following and update. Thank you.'
              : 'It seems you haven’t logged in for a while. Please review your profile and make sure the information is accurate. Thank you.'
            }
        </p>
        <div style={{marginTop: "20px"}}>
          {Object.size(missingFieldsArray) > 0 ? missingFieldsElems : null}
        </div>
      </div>
    </ModalDialogHandler>
  )
}

UpdateProfileModal.modalUsi = modalUsi

export default withRouter(UpdateProfileModal)
