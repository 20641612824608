import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import Actions from "../../Redux/Actions"
import SecondaryNavDrawer from "../SecondaryNavDrawer/SecondaryNavDrawer"
import MobileNavItem from "../MobileNavItem/MobileNavItem"
import ApplicationProcessTertiaryNavDrawer from "../ApplicationProcessTertiaryNavDrawer/ApplicationProcessTertiaryNavDrawer"
import ApplicationSubmitButton from "../ApplicationSubmitButton/ApplicationSubmitButton"

export const ApplicationProcessNavDrawer = (props) => {
  useEffect(() => {
    // want to run this conditional if a user clicked the hamburger menu which would've automatically triggered the path
    // primary-secondary-
    const regEx =
      props.location.pathname === "/profile"
        ? /profile\?title/
        : /application\?title/

    if (
      regEx.test(props.location.pathname + props.location.search) &&
      props.drawerUsePath === "primary-secondary-"
    ) {
      const { title } = queryString.parse(props.location.search)
      const selectedOption = props.applicationOptions.find((option) => {
        return option.steps.some((step) => {
          return step.stepId === title
        })
      })

      !props.preventTertiaryFromOpeningAutomatically &&
        props.openTertiaryDrawer(() => {
          return (
            <ApplicationProcessTertiaryNavDrawer
              selectedOption={selectedOption}
            />
          )
        })
    }

    !props.applicationIsInitialized &&
      props.fetchApplication("initialLoad", props.history)
  })

  const addSelectorAndClickHandler = (option) => {
    const regEx = new RegExp(`/${option.title}/`)

    return {
      ...option,
      isSelected: regEx.test(props.match.url),
      clickHandler: () => {
        props.openTertiaryDrawer(() => {
          return <ApplicationProcessTertiaryNavDrawer selectedOption={option} />
        })
      }
    }
  }

  const applicationProcessOptions = props.applicationOptions
    .map(addSelectorAndClickHandler)
    .map((option, index) => {
      return (
        <MobileNavItem
          style={{ padding: "10px 40px 10px 40px" }}
          hasSecondaryNavigation
          alert={!option.filled && props.showUnfilledApplication}
          {...option}
          key={index}
        >
          <p style={{ gridColumnStart: 2 }}>{option.title}</p>
        </MobileNavItem>
      )
    })

  const bottomButtonHandler = () => {
    if (props.applicationIsComplete) {
      props.closeAllDrawers()
    } else {
      props.toggleUnfilled()
      props.openDrawer()
    }
  }

  return (
    <>
      <SecondaryNavDrawer
        navOptions={applicationProcessOptions}
        title={props.userHasSubmittedApplication ? "Profile" : "Application"}
        bottomButtonComponent={
          <ApplicationSubmitButton
            applicationIsComplete={props.applicationIsComplete}
            onPressSubmit={bottomButtonHandler}
            submitState={props.submitState}
          />
        }
      />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    applicationOptions: state.user.user.has_submitted_application
      ? (state.user.user.is_spouse == 1 ? state.spouseApplication.spouseProfileMetadata : state.application.profileMetadata)
      : (state.user.user.is_spouse == 1 ? state.spouseApplication.spouseApplicationMetadata : state.application.applicationMetadata),
    userHasSubmittedApplication:
      state.user.user.id && state.user.user.has_submitted_application,
    showUnfilledApplication: state.application.showErrors,
    applicationIsComplete: state.user.user.has_submitted_application
      ? (state.user.user.is_spouse == 1 ? state.spouseApplication.profileIsComplete : state.application.profileIsComplete)
      : (state.user.user.is_spouse == 1 ? state.spouseApplication.applicationIsComplete : state.application.applicationIsComplete),
    applicationIsInitialized: state.application.initialized
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(ApplicationProcessNavDrawer))
)
