import React from "react"
import MediaQuery from "react-responsive"
import NavBar from "../NavBar/NavBar"

export const CommitNavBar = ({ desktopMenu, mobileMenu }) => {
  return (
    <NavBar>
      <MediaQuery maxWidth={939}>{mobileMenu && mobileMenu()}</MediaQuery>
      <MediaQuery minWidth={940}>{desktopMenu && desktopMenu()}</MediaQuery>
    </NavBar>
  )
}

export default CommitNavBar
