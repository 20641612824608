import React from "react"
import { connect } from "react-redux"
import "./PYPCarousel.css"
import { lightThemePartNames } from "../PYPPartContainer/lightThemeParts"
import colors from "../../Constants/colors"

export class PYPCarousel extends React.Component {
  constructor(props) {
    super(props)

    // NOTE: You MUST pass a cardHeight and cardWidth prop
    // (cooresponding to the height and width of the active card)

    // For now, removing 'size' as an optional prop.
    // Default to small box if no size is passed
    // const size = props.size || "small";

    this.state = {
      firstPage: true,
      lastPage: false,
      activePosition: 0,
      exampleLength: props.cards.length,
      theme: props.theme

      // inactiveBoxDimensions: {
      //   maxWidth: inactiveMaxWidth[size],
      //   minWidth: inactiveMinWidth[size],
      //   height: inactiveHeight[size]
      // },
      // activeBoxDimensions: {
      //   maxWidth: activeMaxWidth[size],
      //   minWidth: activeMinWidth[size],
      //   height: activeHeight[size]
      // }
    }

    this.handleIncrement = this.handleIncrement.bind(this)
    this.handleDecrement = this.handleDecrement.bind(this)
  }

  handleIncrement(e) {
    const oldState = this.state
    const newState = {}

    // if last page, make it first page
    if (this.state.lastPage) {
      newState.lastPage = false
      newState.firstPage = true
      newState.activePosition = 0
    } else {
      // otherwise go to next page
      newState.firstPage = false
      newState.activePosition = oldState.activePosition + 1
      if (newState.activePosition === this.props.cards.length - 1) {
        newState.lastPage = true
      }
    }
    this.setState({ ...newState })
  }

  handleDecrement(e) {
    const oldState = this.state
    const newState = {}
    // if first page, make it last page
    if (this.state.firstPage) {
      newState.firstPage = false
      newState.lastPage = true
      newState.activePosition = this.props.cards.length - 1
    } else {
      // otherwise go to previous page
      newState.lastPage = false
      newState.activePosition = oldState.activePosition - 1
      if (newState.activePosition === 0) {
        newState.firstPage = true
      }
    }
    this.setState({ ...newState })
  }

  render() {
    const activeExample = this.props.cards[this.state.activePosition]

    const activeCardDimensions = {
      height: `${this.props.cardHeight}px`,
      width: `${this.props.cardWidth}px`
    }
    const inactiveCardDimensions = {
      height: `${this.props.cardHeight * 0.87}px`,
      width: `320px` // Gets set to the minimum input box width to prevent overlapping
      // width: `${this.props.cardWidth}px`
    }

    let leftInactiveExample =
      this.props.cards[this.state.activePosition - 1] ||
      this.props.cards[this.props.cards.length - 1]

    // Inject the preventScroll=true prop
    leftInactiveExample = {
      ...leftInactiveExample,
      props: { ...leftInactiveExample.props, preventScroll: true }
    }

    let rightInactiveExample =
      this.props.cards[this.state.activePosition + 1] || this.props.cards[0]

    // Inject the preventScroll=true prop
    rightInactiveExample = {
      ...rightInactiveExample,
      props: { ...rightInactiveExample.props, preventScroll: true }
    }

    const textColor = this.state.theme === "dark" ? colors.white : colors.text

    const carouselPositionIndicator = (
      <div className="noselect" style={styles.carouselPositionIndicator}>
        {!this.props.loading ? (
          <>
            <span className="clickable" onClick={this.handleDecrement}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: "scale(-1.4, 1.4) translate(0,-1.5px)",
                  ...styles.caret
                }}
                width="15"
                height="15"
                viewBox="0 0 8 12"
              >
                <path
                  fill="none"
                  fillRule="evenodd"
                  stroke={textColor}
                  strokeWidth="2"
                  d="M1 1l5 5-5 5"
                />
              </svg>
            </span>

            <span
              style={{
                color: textColor,
                ...styles.currentCarouselPageBold
              }}
            >
              {" "}
              {this.state.activePosition + 1}/{this.props.cards.length}{" "}
            </span>

            <span className="clickable" onClick={this.handleIncrement}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  transform: "scale(1.5, 1.5) translate(0,-1.5px)",
                  ...styles.caret
                }}
                width="15"
                height="15"
                viewBox="0 0 8 12"
              >
                <path
                  fill="none"
                  fillRule="evenodd"
                  stroke={textColor}
                  strokeWidth="2"
                  d="M1 1l5 5-5 5"
                />
              </svg>
            </span>
          </>
        ) : (
          <div />
        )}
      </div>
    )

    return (
      <>
        <div style={styles.carouselContainer}>
          <div style={styles.boxContainer}>
            <div
              style={{
                ...styles.activeCard,
                ...activeCardDimensions
              }}
            >
              <div
                style={{
                  ...styles.leftBoxOffset,
                  ...styles.inactiveCard,
                  ...inactiveCardDimensions
                }}
              >
                {leftInactiveExample}
              </div>

              <div
                style={{
                  ...styles.rightBoxOffset,
                  ...styles.inactiveCard,
                  ...inactiveCardDimensions
                }}
              >
                {rightInactiveExample}
              </div>

              {activeExample}
            </div>
          </div>

          {carouselPositionIndicator}
        </div>
      </>
    )
  }
}

const styles = {
  carouselContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  boxContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center", // space-around
    alignItems: "center",
    margin: "0px 40px"
  },
  caret: {
    marginLeft: "4px",
    marginRight: "4px",
    height: "8px"
  },
  activeCard: {
    position: "relative",
    zIndex: 2,
    display: "flex",
    justifyContent: "center"
  },
  inactiveCard: {
    opacity: "0.7",
    zIndex: 0
  },
  leftBoxOffset: {
    position: "absolute",
    top: 20,
    left: -50
    // transform: "translate(82%)"
  },
  rightBoxOffset: {
    position: "absolute",
    top: 20,
    right: -50
    // transform: "translate(-82%)"
  },
  carouselPositionIndicator: {
    height: "19px",
    margin: "10px",
    // Style the text
    fontFamily: "Lato-light",
    fontSize: "14px",
    // color: colors.text,
    textAlign: "center",
    zIndex: 1,

    // Remove defaults
    resize: "none",
    border: "none",
    outline: "none",
    boxShadow: "none"
  },
  currentCarouselPageBold: {
    fontFamily: "Lato-bold",
    fontSize: "17px"
  },
  currentCarouselPageLight: {
    fontFamily: "Lato-light"
  }
}

// Removed because width and height is calculated based on the width and heigh props
// const activeMaxWidth = {
//   small: "380px",
//   medium: "527px",
//   large: "605px"
// };
//
// const inactiveMaxWidth = {
//   small: "380px",
//   medium: "527px",
//   large: "605px"
// };
//
// const activeMinWidth = {
//   small: "380px",
//   medium: "527px",
//   large: "605px"
// };
//
// const inactiveMinWidth = {
//   small: "380px",
//   medium: "527px",
//   large: "605px"
// };
//
// const activeHeight = {
//   small: "260px",
//   medium: "320px",
//   large: "391px"
// };
//
// const inactiveHeight = {
//   small: "221px",
//   medium: "272px",
//   large: "332px"
// };

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  return {
    theme: lightThemePartNames.includes(currentPart.name) ? "light" : "dark"
  }
}

export default connect(mapStateToProps)(PYPCarousel)
// export default PYPCarousel;
