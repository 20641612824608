import React from "react"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import colors from "../../Constants/colors"
import { logout } from "../../Redux/Actions/User/functions"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { FullCommitLogoDark } from "../../Icons/FullCommitLogoDark"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

import "./applicationStatus.css"

const styles = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  title: {
    whiteSpace: "nowrap",
    marginTop: "30px",
    fontFamily: "Lato-SemiBold",
    fontSize: "22px",
    color: colors.midnight,
    letterSpacing: "0.76px"
  },
  message: {
    fontSize: "15px",
    fontFamily: "Lato-Regular",
    letterSpacing: "0.52px",
    textAlign: "center",
    marginTop: "10px",
    lineHeight: "21px",
    color: colors.midnight
  },
  logoutButton: {
    width: "147px",
    marginTop: "30px"
  }
}

const ApplicationStatus = ({ history }) => {
  const dispatch = useDispatch()

  const clickHandler = () => {
    dispatch(logout())
    history.push("/account/login")
  }

  return (
    <AccountFormContainer style={styles.container}>
      <FullCommitLogoDark />
      <h1 style={styles.title}>Application Submitted</h1>
      <p className="status-text" style={styles.message}>
        A Director of Veteran Services will be reviewing your application and
        will reach out to you within the next few business days. Until then
        please feel free to explore our website and connect with us on LinkedIn.
      </p>
      <CommitButtonWithClickState
        id="application-submission-btn"
        type="red"
        style={styles.logoutButton}
        clickHandler={clickHandler}
      >
        Logout
      </CommitButtonWithClickState>
    </AccountFormContainer>
  )
}

ApplicationStatus.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
}

export default withRouter(ApplicationStatus)
