import React, { useState } from "react"
import { useSelector } from "react-redux"
import MediaQuery from "react-responsive"
import UserBiography from "../../UserBiography/UserBiography"
import UserBiographyProfileImage from "../../UserBiographyProfileImage/UserBiographyProfileImage"

import "./userBiographyContainer.css"

const UserBiographyContainer = () => {
  const { user, workshops } = useSelector((state) => ({
    user: state.user?.user,
    workshops: state.workshop?.workshops
  }))
  const militaryStatus = user?.military?.branch || "branch"
  return (
    <>
      <MediaQuery maxWidth={650}>
        <div className="userBiographyGridContainer">
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%" }}>
              <UserBiographyProfileImage />
            </div>
            <div style={{ width: "70%", paddingLeft: 16, paddingTop: 7 }}>
              <h1 className="userBiographyUsername">
                {user?.firstName} {user?.lastName}
              </h1>
              {workshops.length && (
                <h3 className="userBiographyMilitary">{militaryStatus}</h3>
              )}
            </div>
          </div>
          <UserBiography />
        </div>
      </MediaQuery>
      <MediaQuery minWidth={651}>
        <div className="userBiographyGridContainer">
          <UserBiographyProfileImage />
          <UserBiography />
        </div>
      </MediaQuery>
    </>
  )
}

export default UserBiographyContainer
