import axios from "axios"

import store from "../Redux/store"

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const getHeaders = () => {
  const token = store.getState().api.apiToken
  return { Authorization: token }
}

export const getCountries = async () => {
  const { data } = await client.get("/locations/countries", {
    headers: getHeaders()
  })

  return data
}

export const getRegions = async (countryId) => {
  if (!countryId) return []

  const { data } = await client.get("/locations/regions", {
    params: { countryId },
    headers: getHeaders()
  })

  return data
}

export const getCities = async (stateId) => {
  if (!stateId) return []

  try {
    const { data } = await client.get("/locations/cities", {
      params: { stateId },
      headers: getHeaders()
    })

    return data
  } catch (error) {
    return []
  }
}
