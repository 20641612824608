import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"
import { ThumbsDown } from "../../Icons/ThumbsDown"
import { ThumbsUp } from "../../Icons/ThumbsUp"
import { gtmClickHandler } from "../../Utils/gtm"

export class VideoTitleBar extends React.Component {
  constructor(props) {
    super(props)
    this.likeVideo = this.likeVideo.bind(this)
    this.dislikeVideo = this.dislikeVideo.bind(this)
  }

  componentDidMount() {
    this.props.getVideoLikeStatus(this.props.currentVideo.id)
  }

  likeVideo() {
    if (this.props.likeStatus === 1) {
      gtmClickHandler({
        name: "ResetLikedEvent",
        data: {
          action: "Reset Liked",
          category: "Video Engagement",
          label: this.props.currentVideo.title
        }
      })
      this.props.postVideoLikeReset(this.props.currentVideo.id)
    } else {
      gtmClickHandler({
        name: "LikedEvent",
        data: {
          action: "Liked",
          category: "Video Engagement",
          label: this.props.currentVideo.title
        }
      })
      this.props.postVideoLike(this.props.currentVideo.id)
    }
  }

  dislikeVideo() {
    if (this.props.likeStatus === -1) {
      gtmClickHandler({
        name: "ResetDislikedEvent",
        data: {
          action: "Reset Disliked",
          category: "Video Engagement",
          label: this.props.currentVideo.title
        }
      })
      this.props.postVideoLikeReset(this.props.currentVideo.id)
    } else {
      gtmClickHandler({
        name: "DislikedEvent",
        data: {
          action: "Disliked",
          category: "Video Engagement",
          label: this.props.currentVideo.title
        }
      })
      this.props.postVideoDislike(this.props.currentVideo.id)
    }
  }

  render() {
    if (this.props.fetchingLikeStatus) {
      return (
        <SkeletonWrapper
          theme="light"
          height={20}
          width={80}
          containerStyle={{ width: "80px", ...this.props.style }}
        >
          <rect x="0" y="0" rx="4" ry="4" width="20" height={20} />
          <rect x="30" y="0" rx="4" ry="4" width="20" height={20} />
        </SkeletonWrapper>
      )
    }
    return (
      <span style={{ ...this.props.style }}>
        <span>
          <button
            aria-label="dislike video"
            onClick={this.likeVideo}
            style={styles.thumbButton}
          >
            <ThumbsUp
              fill={this.props.likeStatus === 1 ? "#030d26" : "#8792A5"}
              style={styles.thumbsUp}
            />
            {this.props.likeCount}
          </button>
        </span>
        <span>
          <button
            aria-label="dislike video"
            onClick={this.dislikeVideo}
            style={styles.thumbButton}
          >
            <ThumbsDown
              fill={this.props.likeStatus === -1 ? "#030d26" : "#8792A5"}
              style={styles.thumbsDown}
            />
            {this.props.dislikeCount}
          </button>
        </span>
      </span>
    )
  }
}

const styles = {
  thumbButton: {
    backgroundColor: "transparent",
    border: "none"
  },
  thumbsUp: {
    cursor: "pointer",
    marginRight: "8px"
  },
  thumbsDown: {
    cursor: "pointer",
    marginLeft: "20px",
    marginRight: "8px"
  },
  thumbsContainer: {
    textAlign: "right",
    marginRight: "45px",
    fontFamily: "Lato-Semibold",
    fontSize: "14px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    currentVideo: state.digitalLibrary.currentVideo,
    likeStatus: state.digitalLibrary.likeStatus,
    likeCount: state.digitalLibrary.likeCount,
    dislikeCount: state.digitalLibrary.dislikeCount,
    fetchingLikeStatus: state.digitalLibrary.fetchingLikeStatus
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTitleBar)
