/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import { DefaultUserThumbnail } from "../../Icons/DefaultUserThumbnail"
import GrayOverlay from "../../Icons/GrayOverlay"
import "./UserBiographyProfileImage.css"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    user: state.user.user,
    modal: state.modal
  }
}

class UserBiographyProfileImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: "",
      darkenImage: false
    }

    this.inputFileButton = React.createRef()
    this.toggleDarkenImage = this.toggleDarkenImage.bind(this)
  }

  fileUpload = async (e) => {
    e.preventDefault()
    const { toggleModalCrop } = this.props
    if (e.target.files[0]) {
      await toggleModalCrop(true, e.target.files[0])
    }
  }

  toggleDarkenImage = () => {
    const { darkenImage } = this.state
    this.setState({ darkenImage: !darkenImage })
  }

  triggerClickFileButton = () => {
    this.inputFileButton.current.click()
  }

  render() {
    const { user } = this.props
    const { value, darkenImage } = this.state

    const renderedThumbnail = user.profile_image_url ? (
      <>
        <MediaQuery maxWidth={650}>
          <div className="userProfileImageCircle">
            <div
              htmlFor="thumbnail"
              className="userProfileImageLabelField"
              onClick={() => this.triggerClickFileButton()}
            >
              <img
                className="profileImageThumbnail"
                src={user.profile_image_url}
                width="62px"
                height="62px"
                alt="profile"
              />
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={651}>
          <div className="userProfileImageCircle">
            <div
              htmlFor="thumbnail"
              className="userProfileImageLabelField"
              onClick={() => this.triggerClickFileButton()}
            >
              <GrayOverlay width="182" height="182" borderR="100" />
            </div>
            <img
              className="profileImageThumbnail"
              src={user.profile_image_url}
              width="182px"
              height="182px"
              alt="profile"
            />
          </div>
        </MediaQuery>
      </>
    ) : (
      <div>
        <MediaQuery maxWidth={650}>
          <div
            onMouseEnter={this.toggleDarkenImage}
            onMouseLeave={this.toggleDarkenImage}
            htmlFor="thumbnail"
            className="userProfileImageDefaultLabelField"
            onClick={() => this.triggerClickFileButton()}
          >
            <DefaultUserThumbnail
              width="70px"
              height="69.1px"
              darkerGray={darkenImage}
            />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={651}>
          <div
            onMouseEnter={this.toggleDarkenImage}
            onMouseLeave={this.toggleDarkenImage}
            htmlFor="thumbnail"
            className="userProfileImageDefaultLabelField"
            onClick={() => this.triggerClickFileButton()}
          >
            <DefaultUserThumbnail
              width="182px"
              height="182px"
              darkerGray={darkenImage}
            />
          </div>
        </MediaQuery>
      </div>
    )
    return (
      <>
        <span className="userProfileThumbnailContainer">
          <input
            type="file"
            ref={this.inputFileButton}
            id="thumbnail"
            name="thumbnail"
            accept="image/png, image/jpg, image/jpeg"
            className="inputfile hiddenUserUploadField"
            onChange={(e) => this.fileUpload(e)}
            onInput={(e) => this.fileUpload(e)}
            value={value}
          />
          {renderedThumbnail}
        </span>
      </>
    )
  }
}

UserBiographyProfileImage.propTypes = {
  user: PropTypes.objectOf(PropTypes.any).isRequired,
  toggleModalCrop: PropTypes.func.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBiographyProfileImage)
