import React, { Component } from "react"
import colors from "../../Constants/colors.js"
import "./UserFieldEditorHeaderSectionTitle.css"

export default class UserFieldEditorSectionTitle extends Component {
  render() {
    return (
      <h2
        className="appSectionTitle"
        style={{ ...styles.componentContainer, ...this.props.style }}
      >
        {this.props.children}
      </h2>
    )
  }
}

const styles = {
  componentContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontFamily: "Lato-SemiBold",
    textAlign: "center",
    lineHeight: "42px",
    align: "center",
    color: colors.midnight
  }
}
