import React from "react"
import { Modal, ModalBody } from "reactstrap"
import "./InstructionalModal.css"
import { debounce } from "debounce"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"

class WithInstructionalModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      top: 0,
      left: 0,
      modalBodyWidth: 0,
      modalBodyHeight: 0,
      receivedModalCoords: false,
      receivedChildCoords: false
    }

    this.modalBody = React.createRef()
    this.childWrapper = React.createRef()

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  updateDimensions() {
    const modalBodyDimensions =
      this.modalBody.current && this.modalBody.current.getBoundingClientRect()
    const childWrapperDimensions =
      this.childWrapper.current &&
      this.childWrapper.current.getBoundingClientRect()

    if (modalBodyDimensions && childWrapperDimensions) {
      this.setState(
        this.getCoordinates(
          childWrapperDimensions || {},
          modalBodyDimensions || {},
          this.props.orientation || "right"
        )
      )
    }
  }

  componentDidUpdate(prevProps, prevState, currentState) {
    if (
      prevProps.instructionalGuide.currentStepId !==
      this.props.instructionalGuide.currentStepId
    ) {
      const modalBodyDimensions =
        this.modalBody.current && this.modalBody.current.getBoundingClientRect()
      const childWrapperDimensions =
        this.childWrapper.current &&
        this.childWrapper.current.getBoundingClientRect()

      if (modalBodyDimensions && childWrapperDimensions) {
        this.setState(
          this.getCoordinates(
            childWrapperDimensions || {},
            modalBodyDimensions || {},
            this.props.orientation || "right"
          )
        )
      }
    }
    window.addEventListener("resize", debounce(this.updateDimensions, 100))
  }

  componentDidMount() {
    const modalBodyDimensions =
      this.modalBody.current && this.modalBody.current.getBoundingClientRect()
    const childWrapperDimensions =
      this.childWrapper.current &&
      this.childWrapper.current.getBoundingClientRect()

    if (modalBodyDimensions && childWrapperDimensions) {
      this.setState(
        this.getCoordinates(
          childWrapperDimensions || {},
          modalBodyDimensions || {},
          this.props.orientation || "right"
        )
      )
    }
  }

  getCoordinates(childDimensions, modalDimensions, orientation) {
    switch (orientation) {
      case "top": {
        const isFirefox = typeof InstallTrigger !== "undefined"
        const topAdjustment = isFirefox ? 190 : 145
        return {
          top: childDimensions.top - modalDimensions.height - topAdjustment,
          left:
            (childDimensions.right + childDimensions.left) / 2 -
            modalDimensions.width / 2,
          modalBodyHeight: modalDimensions.height,
          modalBodyWidth: modalDimensions.width
        }
      }
      case "right":
        return {
          top: childDimensions.top - modalDimensions.height / 2 + 28,
          left: childDimensions.right + 23,
          modalBodyHeight: modalDimensions.height,
          modalBodyWidth: modalDimensions.width
        }
      default:
        console.warn("getCoordinates received invalid arg")
    }
  }

  refCallback(element) {
    if (element) {
      this.setState(
        this.getCoordinates(
          element.getBoundingClientRect(),
          this.props.orientation || "right"
        )
      )
    }
  }

  getTriangle(orientation) {
    switch (orientation) {
      case "top":
        return (
          <div
            className="arrow-down"
            style={{
              position: "absolute",
              left: `${this.state.modalBodyWidth / 2 - 20}px`,
              top: `${this.state.modalBodyHeight}px`
            }}
          />
        )
      case "right":
        return (
          <div
            className="arrow-left"
            style={{
              position: "absolute",
              left: "-20px",
              top: `${this.state.modalBodyHeight / 2 - 20}px`
            }}
          />
        )
      default:
        console.warn("getTriangle received invalid arg")
    }
  }

  render() {
    const { guide, currentStepId } = this.props.instructionalGuide

    return (
      <>
        <div style={this.props.style} ref={this.childWrapper}>
          {this.props.renderComponent({
            ...this.props
          })}
        </div>
        <Modal
          isOpen={
            !this.props.userHasCompletedGuide &&
            currentStepId > -1 &&
            guide[currentStepId].name === this.props.step
          }
          style={{
            left: this.state.left,
            top: this.state.top,
            margin: "0px"
          }}
        >
          <div ref={this.modalBody}>
            <ModalBody>{this.props.contentComponent()}</ModalBody>
          </div>
          {this.getTriangle(this.props.orientation || "right")}
        </Modal>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    instructionalGuide: state.instructionalGuide,
    userHasCompletedGuide:
      state.user.user.has_completed_application_guide || false
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithInstructionalModal)
