import React from "react"
import { useSelector } from "react-redux"

import InputGroup from "../InputGroup/InputGroup"

import "./AlliedForcesTimelineInputs.css"

const initialInputs = [
  {
    id: 7,
    userField: "timeline",
    inputType: "select",
    placeHolder: "",
    title: "Timeline to transition?",
    options: [
      { id: "0-6 months", value: "0-6 months" },
      { id: "6-12 months", value: "6-12 months" },
      { id: "12-24 months", value: "12-24 months" },
      { id: "24+ months", value: "24+ months" },
      { id: "Unsure", value: "Unsure" }
    ],
    required: true,
  }
]

const AlliedForcesTimelineInputs = (props) => {
  const application = useSelector((state) => {
    return state.alliedForcesApplication.application;
  });

  const inputDataWithValues = initialInputs.map((input) => {
    let value = Object.byString(application, input.userField) || ""
    return {
      ...input,
      value
    }
  })

  const requiredValidators = (input) => ({
    ...input,
    error: !!(input.required && input.value.length === 0)
  })

  const dateOfBirthValidator = (input) => {
    if (input.textField === "dateOfBirth") {
      return {
        ...input,
        error: true
      }
    }
    return input
  }

  return (
    <InputGroup
      {...props}
      containerClass="singleGridContainer"
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              checkSubmittability,
              inputComponent,
              validators: [requiredValidators, dateOfBirthValidator]
            })}
          </>
        )
      }}
    />
  )
}

export default AlliedForcesTimelineInputs
