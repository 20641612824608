import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import MediaQuery from "react-responsive"
import colors from "../../Constants/colors"
import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import Actions from "../../Redux/Actions"
import TimeEventBarGraph from "../CommitBarGraph/CommitBarGraph"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

const styles = {
  graphContainer: {
    color: colors.white,
    height: "450px",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto"
  },
  titleContent: {
    backgroundColor: "inherit",
    minHeight: "min-content"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let buttonValues = []
  let timeEvents = []
  let timeAsleep = null
  let metadata = { title: null, description: null }

  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.partTitle[0]
    buttonValues = partData.referencedPartData.buttonValues.filter(
      (buttonValue) => {
        return buttonValue.buttonSelections.length
      }
    )
    timeEvents = partData.referencedPartData.timeEvents
    timeAsleep =
      parseInt(partData.referencedPartData.constraints[0].text.split(":")[0]) *
        60 +
      24 * 60 -
      parseInt(partData.referencedPartData.constraints[1].text.split(":")[0]) *
        60 +
      parseInt(partData.referencedPartData.constraints[0].text.split(":")[1]) -
      parseInt(partData.referencedPartData.constraints[1].text.split(":")[1])
  }

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    initializedPart: state.pyp.initializedPart,
    fetchingPartData: state.pyp.fetchingPartData,
    initializingPYPInputs: state.pyp.initializingPYPInputs,
    buttonValues,
    timeEvents,
    timeAsleep
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class LayoutMetaDataGraphs extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        skeletonWidth: 0,
        skeletonWidthRatio: 0
      }
    }

    resizeSkeletonWidth = () => {
      const container = document.getElementById("skeletonContainer")
      this.setState({
        skeletonWidth: container.clientWidth * 0.8,
        skeletonWidthRatio: container.clientWidth / 400
      })
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.resizeSkeletonWidth)
    }

    componentDidMount() {
      this.resizeSkeletonWidth()
      window.addEventListener("resize", this.resizeSkeletonWidth)
      if (!this.props.pyp.savingPYP) {
        this.props
          .fetchPartData()
          .then("() => this.setState({ loading: false })")
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
        this.props
          .fetchPartData()
          .then("() => this.setState({ loading: false })")
      }
    }

    render() {
      let eventTitles = [""]
      let longestValue = 9
      const timeAwake = [24 - this.props.timeAsleep / 60]
      const tempValueData =
        this.props.buttonValues[0] &&
        this.props.buttonValues.map((value) => {
          if (value.name.length > longestValue) {
            longestValue = value.name.length
          }
          const valueObj = {
            titleSequence: [],
            title: value.name,
            Total: 0
          }
          this.props.timeEvents.forEach((event) => {
            if (event.value_id === value.id) {
              valueObj.Total += Math.round(
                event.duration / (0.6 * timeAwake),
                4
              )
              valueObj[event.title] = Math.round(
                event.duration / (0.6 * timeAwake),
                4
              )
              valueObj.titleSequence.push(event.title)
            }
          })
          const filteredTimeEvents = this.props.timeEvents.filter((event) =>
            valueObj.titleSequence.includes(event.title)
          )
          filteredTimeEvents
            .sort((a, b) => valueObj[b.title] - valueObj[a.title])
            .map((event) => event.title)
          valueObj.titleSequence = ["Total"]

          return valueObj
        })
      if (tempValueData) {
        const valueObj = {
          titleSequence: [],
          title: "Non-Value",
          "Non-Value": 0
        }
        this.props.timeEvents.forEach((event) => {
          let nonValue = true
          this.props.buttonValues.forEach((value) => {
            if (event.value_id === value.id) nonValue = false
          })
          if (nonValue) {
            valueObj["Non-Value"] += Math.round(
              event.duration / (0.6 * timeAwake),
              4
            )
            valueObj[event.title] = Math.round(
              event.duration / (0.6 * timeAwake),
              4
            )
            valueObj.titleSequence.push(event.title)
          }
        })
        eventTitles = ["Total", "Non-Value"]
        tempValueData.push(valueObj)
      }

      // eventTitles.push("Sleep") //uncomment if we want sleep to be included in breakdown
      if (tempValueData && false)
        // uncomment if we want sleep to be included in same graph
        tempValueData.push({
          titleSequence: ["Sleep"],
          title: "Sleep",
          Sleep: Math.round(this.props.timeAsleep / (0.6 * timeAwake), 4)
        })
      let valueData =
        tempValueData && tempValueData.filter((item) => item.titleSequence[0])
      valueData =
        valueData &&
        valueData.sort((a, b) =>
          a.Total > b.Total ? 1 : a.Total < b.Total ? -1 : 0
        )
      valueData =
        valueData &&
        valueData.sort((a, b) =>
          a.title === "Non-Value"
            ? b.title === "Non-Value"
              ? 0
              : -1
            : b.title === "Non-Value"
            ? 1
            : 0
        )
      return (
        <PYPLayoutContainer
          style={{ ...this.props.style }}
          footerContent={{ background: colors.midnight }}
        >
          <PYPPartTitleContent
            title={this.props.metadata.title}
            description={this.props.metadata.description}
            style={styles.titleContent}
            theme="dark"
          />
          <div
            id="skeletonContainer"
            style={{
              ...styles.graphContainer,
              height: `${(valueData && valueData.length * 37) || 350}px`
            }}
          >
            {valueData ? (
              <>
                <MediaQuery maxWidth={599}>
                  <TimeEventBarGraph
                    rightMarginModifier={-60}
                    data={valueData}
                    keys={eventTitles}
                    inColors={{
                      Total: "#00D061",
                      Sleep: "#7551E9",
                      default: "#D8F0F8",
                      "Non-Value": "#D8F0F8"
                    }}
                    tickStep={0.5}
                    yUnits="Hours"
                    xUnits="Value"
                    longestValue={longestValue}
                  />
                </MediaQuery>
                <MediaQuery minWidth={600}>
                  <TimeEventBarGraph
                    data={valueData}
                    keys={eventTitles}
                    inColors={{
                      Total: "#00D061",
                      Sleep: "#7551E9",
                      default: "#D8F0F8",
                      "Non-Value": "#D8F0F8"
                    }}
                    tickStep={0.5}
                    yUnits="Hours"
                    xUnits="Value"
                    longestValue={longestValue}
                  />
                </MediaQuery>
              </>
            ) : (
              <SkeletonWrapper
                theme="dark"
                height="400"
                width={this.state.skeletonWidth}
                containerStyle={{
                  width: "100%",
                  height: "400px"
                }}
              >
                <rect
                  x={20 * this.state.skeletonWidthRatio}
                  y="65"
                  width={280 * this.state.skeletonWidthRatio}
                  height="20"
                />
                <rect
                  x={20 * this.state.skeletonWidthRatio}
                  y="95"
                  width={250 * this.state.skeletonWidthRatio}
                  height="20"
                />
                <rect
                  x={20 * this.state.skeletonWidthRatio}
                  y="125"
                  width={200 * this.state.skeletonWidthRatio}
                  height="20"
                />
                <rect
                  x={20 * this.state.skeletonWidthRatio}
                  y="155"
                  width={200 * this.state.skeletonWidthRatio}
                  height="20"
                />
                <rect
                  x={20 * this.state.skeletonWidthRatio}
                  y="185"
                  width={120 * this.state.skeletonWidthRatio}
                  height="20"
                />
              </SkeletonWrapper>
            )}
          </div>
        </PYPLayoutContainer>
      )
    }
  }
)
