import React from "react"

/*  Use
Takes in an array of objects and maps them

style = {}
clickHandler = function

listItems = {
  text: "",
  className: "",
  functionParams: '', //Requires a default clickHandler
  function: '', //Overwrites functionParams && clickhandler
  componentType: "" //Overwrites default button, case needs to be mase in switch statement
  style: "" //Overwrites default style
}

*/

export default function CardArrayHandler({ listItems, clickHandler, style }) {
  const content = listItems.map((item, index) => {
    const paramHandler = (e) => {
      return item.function
        ? item.function()
        : clickHandler(e, item.functionParams)
    }

    switch (item.componentType) {
      case "button-with-underline":
        return (
          <div key={index}>
            <button
              className={item.className}
              style={{ ...item.style, ...style }}
              onClick={(e) => paramHandler(e)}
            >
              {" "}
              {item.text}{" "}
            </button>
            <div
              className={item.className}
              style={{ ...item.underlineStyle }}
            />
          </div>
        )
      default:
        return (
          <button
            key={index}
            style={{ ...item.style, ...style }}
            onClick={(e) => paramHandler(e)}
          >
            {" "}
            {item.text}{" "}
          </button>
        )
    }
  })
  return <>{content}</>
}
