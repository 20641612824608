import React, { Component } from "react"
import ListWithMultiSelectedComponents from "../ListWithMultiSelectedComponents/ListWithMultiSelectedComponents"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import { industriesRemoved } from "../../Utils/industriesList"
import "./ButtonSelection.css"

export class ButtonSelectionInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputData: props.inputData, // 1) Assign props.inputData to state.inputData
      updatedItems: [],
      otherInput: ""
    }

    this.updateData = this.updateData.bind(this)
  }

  // We have to do this because button selection state isn't being stored within
  // the original data source (props.inputData)

  // When custom buttons are prepended to props.inputData, this function updates
  // state.inputData so the previous state.inputData remains unchanged, but the
  // new buttons are appended to the front of the previous values. That way a user's
  // previous button selections persist
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.inputData &&
      nextProps.inputData.length !== prevState.inputData.length
    ) {
      const newData = []
      for (let i = 0; i < nextProps.inputData.length; i++) {
        const btn = nextProps.inputData[i]
        const index = prevState.inputData.findIndex(
          (e) => e.id === btn.id && e.isCustom === btn.isCustom
        )
        if (index < 0) {
          newData.push(btn)
        }
      }

      return {
        inputData: [...newData, ...prevState.inputData]
      }
    }
    return null
  }

  updateData(item, isSelected) {
    const updatedItemIndex = this.state.updatedItems.findIndex((updatedItem) =>
      item.isCustom
        ? item.id === updatedItem.id
        : updatedItem.value === item.value
    )

    const updatedItems =
      updatedItemIndex > -1
        ? this.state.updatedItems.map((updatedItem, i) => {
            return i === updatedItemIndex
              ? {
                  ...item,
                  isSelected
                }
              : updatedItem
          })
        : this.state.updatedItems.concat([
            {
              ...item,
              isSelected
            }
          ])

    this.setState({
      inputData: this.state.inputData.map((datum) => {
        return datum.id && datum.id === item.id
          ? {
              ...datum,
              isSelected
            }
          : datum
      }),
      updatedItems
    })

    const selectedItems = this.state.inputData
      .filter((elem) => {
        return (
          elem.isSelected &&
          !updatedItems.find((item) =>
            elem.isCustom
              ? elem.id === item.id
              : item.value === elem.value && !item.value.isSelected
          )
        )
      })
      .concat(updatedItems.filter((item) => item.isSelected))

    this.props.changeHandler &&
      this.props.changeHandler([
        {
          userField: this.props.userField,
          value: updatedItems,
          selectedItems
        }
      ])
  }

  renderButtons(inputData) {
    return inputData
      .map((option, i) => {
        const isAlreadySelected =
          option.isSelected === 1 || option.isSelected === true
        const isRemoved = industriesRemoved.some(
          (industryRemoved) => industryRemoved === option.value
        )

        // when inputData.length === 10 we're on stage 2 (top 3 industries on PYP module)
        if (
          (isRemoved && isAlreadySelected) ||
          !isRemoved ||
          inputData.length === 10
        ) {
          return {
            ...option,
            renderComponent: ({ isSelected, selectOption, key }) => (
              <CommitButtonWithClickState
                key={key}
                clickHandler={() => {
                  if (isSelected || !this.props.limit) {
                    selectOption()
                    this.updateData(option, !isSelected)
                  } else {
                    const currentlySelectedLength = this.state.inputData.filter(
                      (buttonSelection) => buttonSelection.isSelected
                    ).length

                    if (currentlySelectedLength + 1 <= this.props.limit) {
                      selectOption()
                      this.updateData(option, !isSelected)
                    }
                  }
                }}
                style={{
                  height: "45px",
                  width: "180px",
                  fontSize: option.value.length > 22 ? "12px" : "14px",
                  justifySelf: "center",
                  whiteSpace: "normal"
                }}
                // if 'deselected' goes to unclicked.
                // then, on mouse leave, deselected goes back to false

                type={isSelected ? "mediumBlue" : "whiteMediumBlue"}
              >
                {option.value}
              </CommitButtonWithClickState>
            )
          }
        }

        return null
      })
      .filter(Boolean)
  }

  render() {
    const buttons = this.renderButtons(this.props.inputData)

    return (
      <>
        {this.props.renderComponent({
          ...this.props,
          inputData: [
            {
              userField: this.props.userField,
              value: this.state.updatedItems
            }
          ],
          inputComponent: (
            <div
              className="buttonsContainer"
              style={{
                display: "grid",
                width: "100%",
                justifyContent: "space-evenly",
                gridRowGap: "18px",
                gridColumnGap: "18px",
                ...this.props.style
              }}
            >
              <ListWithMultiSelectedComponents components={buttons} />
            </div>
          )
        })}
      </>
    )
  }
}

export default ButtonSelectionInput
