/* eslint-disable consistent-return */
import types from "../types"
import fetch from "../../../Utils/fetch"

// the record_type_id is different for both, staging and production
// Removed to include all the workshop campaigns Chart #240155
// const RECORD_TYPE_IDS = ["0122h000000DNnqAAG", "0124N000000MfV5QAK"]

export const getUserWorkshops = () => async (dispatch) => {
  dispatch({ type: types.SET_LOADING, payload: true })

  try {
    const { data } = await dispatch(
      fetch("/userWorkshops", {
        type: "GET"
      })
    )

    // recordTypeId is the id to identify that SF workshop is Spouse Cohort type
    const spouseCourseWorkshop = data
    // Removed to include all the workshop campaigns Chart #240155
    // .filter((workshop) =>
    //   RECORD_TYPE_IDS.includes(workshop.recordTypeId)
    // )

    dispatch({ type: types.SET_WORKSHOPS, payload: spouseCourseWorkshop })
    return spouseCourseWorkshop
  } catch (error) {
    dispatch({ type: types.SET_ERROR, payload: true })
  }
}

export const getWorkshopAttendees = (workshopId) => async (dispatch) => {
  dispatch({
    type: types.FETCHING_ATTENDEES
  })

  try {
    const { data } = await dispatch(
      fetch(`/workshopAttendees/${workshopId}`, {
        type: "GET"
      })
    )

    const programCoordinator = data.filter(
      (attendee) => attendee.currentJob === "Facilitator"
    )
    dispatch({
      type: types.FETCHED_ATTENDEES,
      data,
      programCoordinator
    })
  } catch (error) {
    dispatch({
      type: types.FETCH_ATTENDEES_ERROR
    })
  }
}

export const getWorkshopActivities = (campaign_id) => async (dispatch) => {
  dispatch({
    type: types.FETCHING_ACTIVITIES
  })

  try {
    const { data: activities } = await dispatch(
      fetch(`/workshopActivities/${campaign_id}`, {
        type: "GET"
      })
    )

    const transformedActivities = activities.map((activity) => {
      // set status to true or false
      let status = activity.userActivityStatuses[0] || null

      status = status && {
        ...status,
        completed: status.completed === 1
      }

      return {
        ...activity,
        userActivityStatuses: status,
        is_pre_session: !!activity.is_pre_session
      }
    })

    return transformedActivities
  } catch (error) {
    dispatch({
      type: types.FETCH_ACTIVITIES_ERROR
    })
  }
}

export const updateUserActivityStatuses = (
  activityId,
  userId,
  completedStatus
) => async (dispatch) => {
  try {
    const { data: activityStatus } = await dispatch(
      fetch(`/updateUserActivityStatuses`, {
        type: "POST",
        data: {
          requestPayload: { activityId, userId, completedStatus }
        }
      })
    )

    dispatch({
      type: types.UPDATE_USER_ACTIVITY_STATUSES,
      payload: activityStatus
    })
  } catch ({ error }) {
    dispatch({
      type: types.FETCH_ACTIVITIES_ERROR
    })
  }
}

export const getWorkshopEvents = (campaign_id) => async (dispatch) => {
  dispatch({
    type: types.FETCHING_WORKSHOP_EVENTS
  })

  try {
    const { data: workshopEvents } = await dispatch(
      fetch(`/workshopEvents/${campaign_id}`, {
        type: "GET"
      })
    )

    await dispatch({
      type: types.FETCHED_WORKSHOP_EVENTS,
      data: workshopEvents
    })

    return workshopEvents
  } catch (error) {
    dispatch({
      type: types.FETCH_WORKSHOP_EVENTS_ERROR
    })
  }
}
