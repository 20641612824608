import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { useSelector } from "react-redux"
import colors from "../../Constants/colors"

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "50px",
    overflowX: "auto"
  },
  table: {
    height: "fit-content"
  },
  header: {
    backgroundColor: colors.midnight,
    color: colors.white
  }
})

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
}

export default function InboundTrafficTable() {
  const analyticsData = useSelector((state) => ({
    active: state.analytics.analyticsData["Monthly Active Users"].data,
    created: state.analytics.analyticsData.createdAtMonth,
    applications: state.analytics.analyticsData["application/status views"]
  }))

  const classes = useStyles()

  let tableRows =
    (analyticsData &&
      analyticsData.active &&
      Object.entries(analyticsData.active).map((entry) => ({
        month: parseInt(entry[0]),
        websiteTraffic: entry[1].value,
        applications: analyticsData.applications[parseInt(entry[0])] || "N/A",
        accountsCreated: analyticsData.created[parseInt(entry[0])] || "N/A"
      }))) ||
    []

  tableRows = tableRows.sort((a, b) => a.month - b.month)

  tableRows = tableRows.map((row, index) => (
    <TableRow key={index}>
      <TableCell component="th" scope="row" key="month">
        {monthMap[row.month]}
      </TableCell>
      <TableCell align="right" key="websiteTraffic">
        {row.websiteTraffic}
      </TableCell>
      <TableCell align="right" key="accountCreated">
        {row.accountsCreated}
      </TableCell>
      <TableCell align="right" key="account/.traffic">
        {((100 * row.accountsCreated) / row.websiteTraffic).toFixed(2)}%
      </TableCell>
      <TableCell align="right">{row.applications}</TableCell>
      <TableCell align="right">
        {((100 * row.applications) / row.websiteTraffic).toFixed(2)}%
      </TableCell>
    </TableRow>
  ))

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header}>Month</TableCell>
            <TableCell className={classes.header} align="right">
              Website Traffic
            </TableCell>
            <TableCell className={classes.header} align="right">
              Account Created
            </TableCell>
            <TableCell className={classes.header} align="right">
              % Web Traffic Creating Accounts
            </TableCell>
            <TableCell className={classes.header} align="right">
              Applications Submitted
            </TableCell>
            <TableCell className={classes.header} align="right">
              % Web Traffic Submitting Application
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </Paper>
  )
}
