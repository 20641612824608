import React from "react"
import { withRouter, Route } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Loader from "react-loader-spinner"
import queryString from "query-string"
import moment from "moment"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
// Components
import PYPModuleSplashContainer from "../PYPModuleSplashContainer/PYPModuleSplashContainer"
import PYPPartContainer from "../PYPPartContainer/PYPPartContainer"

import GoogleTagManager from "../GoogleTagManager/GoogleTagManager"
import InvisibleGuideCompletion from "../InvisibleGuideCompletion/InvisibleGuideCompletion"
import PYPModal from "../PYPModal/PYPModal"

class PursueYourPurpose extends React.Component {
  constructor() {
    super()
    this.setDimensions = this.setDimensions.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPartTitle !== this.props.currentPartTitle) {
      document.getElementById("Content-Container").scrollTop = 0
    }
    // user hit the back/forward button
    if (prevProps.match.params.module !== this.props.match.params.module) {
      this.props.navigateToModule(this.props.match.params.module)
    }
  }

  componentDidMount() {
    // this is where it needs to be
    document.title = "Pursue Your Purpose"
    if (queryString.parse(this.props.location.search).click === "true") {
      const { emailId } = queryString.parse(this.props.location.search)
      this.props.emailClicked({ emailId })
    }
    this.props.initializePYP(this.props.match.params.module)
  }

  setDimensions() {
    try {
      window.dataLayer.push({
        dimensions: {
          rank:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.lastRank,
          USSOCOM:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.sof,
          time_until_separation:
            this.props.user &&
            this.props.user.military &&
            `${moment(this.props.user.military.retirementDate).diff(
              moment(),
              "months"
            )} Months`,
          military_status:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.status,
          gender: this.props.user && this.props.user.gender
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    if (this.props.pypIsInitialized) {
      return (
        <GoogleTagManager
          gtmId={process.env.REACT_APP_PYP_GTM_ID}
          onFinishLoad={this.setDimensions}
        >
          <InvisibleGuideCompletion GUIDE_USI="LANDING_PAGE_PYP" />
          <Route
            exact
            path="/workshop/:module/content"
            render={() => <PYPPartContainer />}
          />
          <Route
            exact
            path="/workshop/:module"
            render={() => {
              return <PYPModuleSplashContainer />
            }}
          />
          <PYPModal />
        </GoogleTagManager>
      )
    }
    return (
      <div style={styles.loader}>
        <Loader type="Oval" color={colors.midnight} height="100" width="100" />
      </div>
    )
  }
}

const styles = {
  loader: {
    width: "100%",
    height: "calc(100vh)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    pypIsInitialized: state.pyp.initialized,
    user: state.user.user,
    currentPartTitle:
      state.pyp.parts &&
      state.pyp.navigation.currentPartId > -1 &&
      state.pyp.parts.byId[state.pyp.navigation.currentPartId] &&
      state.pyp.parts.byId[state.pyp.navigation.currentPartId].name
    // isAPypPart: state.pyp.navigation.currentPartId > -1,
    // currentPartId: state.pyp.navigation.currentPartId
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PursueYourPurpose)
)
