import React from 'react';
import { useDispatch } from 'react-redux';
import DialogActions from "@material-ui/core/DialogActions";
import colors from "../../Constants/colors";
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState";
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler";
import Actions from "../../Redux/Actions/AlliedForcesApplication/functions";

const AlliedForcesApplicationConfirmationModal = ({ submitHandler }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: "alliedForcesApplicationConfirmation"
      })
    );
  };

  return (
    <>
      <ModalDialogHandler
        modal_usi="alliedForcesApplicationConfirmation" // Updated modal identifier
        paperProps={{
          style: { maxWidth: "800px", minWidth: "315px", width: "50%" }
        }}
        footer={
          <DialogActions>
            <CommitButtonWithoutClickState
              type="white"
              id="return to application"
              style={{ borderRadius: "3px", width: "154px" }}
              clickHandler={closeModal}
            >
              Cancel
            </CommitButtonWithoutClickState>
            <CommitButtonWithoutClickState
              clickHandler={submitHandler}
              id="Submit Allied Forces application confirmed" // Updated ID
              type="red"
              style={{ borderRadius: "3px", width: "154px" }}
            >
              Submit
            </CommitButtonWithoutClickState>
          </DialogActions>
        }
      >
        <div
          style={{
            padding: "50px",
            backgroundColor: colors.background
          }}
        >
          <p style={{ fontSize: 'large' }}>
            Thank you for taking the time to complete your Allied Forces application. This application is the first step in us learning more about you, your background, and your goals. Please review your answers thoroughly before submitting, and be as detailed and thoughtful as possible. We look forward to working with you.
          </p>
        </div>
      </ModalDialogHandler>
    </>
  );
};

export default AlliedForcesApplicationConfirmationModal;
