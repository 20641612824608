import React from "react"
// import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"

const WithHistory = (props) => {
  return (
    <>
      {props.renderComponent({
        ...props,
        history: props.history,
        match: props.match
      })}
    </>
  )
}

export default withRouter(WithHistory)
