import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import MediaQuery from "react-responsive"
import { fetchApplication } from "../../../Redux/Actions/Application/functions"
import "./top3GoalsLanding.css"

const Top3GoalsLanding = () => {
  const dispatch = useDispatch()

  const { goals } = useSelector((state) => {
    return {
      goals: state.application.application?.transition?.goals?.split(";") || []
    }
  })
  const getGoalStyle = (index) => ({
    backgroundImage: `linear-gradient(180deg,rgba(3, 14, 37, 0) 0%, rgba(1, 3, 9, 0.767956) 42.71%, #000000 94.27%), url("https://pursue-your-purpose-assets.s3.us-east-2.amazonaws.com/images/Top_3_Goal_image_${
      index || 1
    }.jpg")`
  })

  // useEffect(() => {
  //   dispatch(fetchApplication(true))
  // }, [])

  return goals.length > 0 ? (
    <div className="top-three-goals-container">
      <MediaQuery minWidth={651}>
        <h1 className="goals-title">Your goals in working with COMMIT</h1>
      </MediaQuery>
      <MediaQuery maxWidth={650}>
        <h1 className="goals-title">Your goals</h1>
      </MediaQuery>
      <div className="goals-container">
        {goals.map((goal, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`goal-${index}`}
            className="goal-frame"
            style={getGoalStyle(index + 1)}
          >
            <span className="goal-text">{goal}</span>
          </div>
        ))}
      </div>
    </div>
  ) : null
}

export default Top3GoalsLanding
