import React, { useState } from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import { useDispatch } from "react-redux"
import { Tooltip, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { ClipboardCopyIcon } from "@heroicons/react/solid"

import defaultImg from "../../../Assets/Images/icons/defaultNetworkImage.svg"

import { BiographyModal } from "../../Modals"
import {
  scheduleDVSMeeting,
  toggleModal
} from "../../../Redux/Actions/User/functions"
import CommitButtonWithClickState from "../../CommitButtonWithClickState/CommitButtonWithClickState"

import "./userActionCard.css"

const styles = {
  commitButton: {
    fontFamily: "Lato-Bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center"
  }
}

const useStyles = makeStyles({
  root: {
    padding: "0",
    paddingRight: "12px",
    top: "5px"
  }
})

const UserActionCard = ({
  spouseDashboard,
  user,
  showScheduleButton,
  showBorder,
  customStyles,
  isShortCard,
  target,
  is_spouse
}) => {
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState(null)
  const classes = useStyles()

  const borderStyle = showBorder
    ? {
        border: "1px solid #E0E0E0"
      }
    : {}

  const imageStyles = customStyles?.imageSize || {}
  const containerStyles = customStyles?.container || {}

  const onScheduleMeetingPress = () => {
    if (!user.scheduleMeetingUrl) return

    window.open(user.scheduleMeetingUrl)
    dispatch(scheduleDVSMeeting())
  }

  const onBiographyPress = () => {
    setCurrentUser({
      fullName: !!user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.fullName,
      biography: !!user?.biography?.biography ? user?.biography?.biography : !!user?.biography ? user?.biography : ""
    })
    dispatch(
      toggleModal({
        modal_mode: true,
        modal_usi: `${BiographyModal.modalUsi}${target}`
      })
    )
  }

  const getActionButtons = () => {
    return (
      <div
        className={`action-links ${spouseDashboard && "spouse-action-links"}`}
      >
        <button
          className="biography-button"
          type="button"
          onClick={() => onBiographyPress()}
        >
          Biography
        </button>
        {user?.linkedin_url || user?.linkedIn ? (
          <a
            href={user?.linkedin_url || user?.linkedIn}
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
        ) : (
          <button type="button" className="disabled">
            LinkedIn
          </button>
        )}
      </div>
    )
  }

  const copyToClipboard = async () => {
    if (!user?.email) return
    await navigator.clipboard.writeText(user?.email)
  }

  const formatUserEmail = (email, length) => {
    if (email.length > length) {
      const shortenEmail = email.slice(0, length)
      return `${shortenEmail}...`
    }

    return email
  }

  const handleEmailClick = () => {
    window.location.href = 'mailto:' + user?.email;
  }

  return (
    <div
      className={`user-action-card-container ${
        spouseDashboard && "spouse-course-section-content"
      }`}
      style={{ ...borderStyle, ...containerStyles }}
    >
      <div
        className="profile-section"
        style={spouseDashboard ? { alignItems: "center" } : {}}
      >
        <div className="user-profile-image-container">
          <img
            className="user-profile-image"
            src={user?.profile_image_url || defaultImg}
            style={{ ...imageStyles }}
            alt=""
          />
        </div>
        <div className="user-info-section">
          <h3
            className="user-info-title"
            style={
              (isShortCard ? { fontSize: 20 } : {},
              spouseDashboard ? { marginBottom: 4 } : {})
            }
          >
            {target.includes("programCoordinator")
              ? `${user?.fullName}`
              : `${user?.firstName} ${user?.lastName}`}
          </h3>
          <p
            className="user-info-subtitle"
            style={spouseDashboard ? { marginBottom: 4 } : {}}
          >
            {target.includes("programCoordinator")
              ? `${user?.city || ""}, ${user?.state || ""}`
              : `${user?.address?.city || ""}, ${user?.address?.state || ""}`}
          </p>
          <div style={{ display: "flex", alignItems: "baseline" }}>
            <MediaQuery minWidth={769}>
              <p className="user-info-email">
                {user?.email ? formatUserEmail(user?.email, 45) : ""}
              </p>
            </MediaQuery>
            <MediaQuery minWidth={315} maxWidth={369}>
              <p className="user-info-email">
                {user?.email ? formatUserEmail(user?.email, 15) : ""}
              </p>
            </MediaQuery>
            <MediaQuery minWidth={370} maxWidth={514}>
              <p className="user-info-email">
                {user?.email ? formatUserEmail(user?.email, 24) : ""}
              </p>
            </MediaQuery>
            <MediaQuery minWidth={515} maxWidth={645}>
              <p className="user-info-email">
                {user?.email ? formatUserEmail(user?.email, 40) : ""}
              </p>
            </MediaQuery>
            <MediaQuery minWidth={646} maxWidth={768}>
              {target === "coach" && user?.email ? (
                formatUserEmail(user?.email, 20)
              ) : (
                <p className="user-info-email">{user?.email || ""}</p>
              )}
            </MediaQuery>
            <Tooltip title="Copy email address to clipboard" placement="top">
              <IconButton
                color="primary"
                className={classes.root}
                component="span"
                onClick={copyToClipboard}
              >
                <ClipboardCopyIcon
                  style={{
                    color: "#2F539D",
                    height: "21px",
                    width: "21px"
                  }}
                />
              </IconButton>
            </Tooltip>
          </div>

          <MediaQuery minWidth={651}>
            {!isShortCard ? getActionButtons() : null}
          </MediaQuery>
        </div>
      </div>
      <MediaQuery maxWidth={651}>{getActionButtons()}</MediaQuery>
      <MediaQuery minWidth={650}>
        {isShortCard ? getActionButtons() : null}
      </MediaQuery>
      <div className="action-buttons">
        {showScheduleButton && !is_spouse ? (
          <CommitButtonWithClickState
            style={styles.commitButton}
            type={user?.scheduleMeetingUrl ? "red" : "gray"}
            clickHandler={() => onScheduleMeetingPress()}
          >
            Schedule a Meeting / Calendly
          </CommitButtonWithClickState>
        ) : null}
        {is_spouse ? (
          <CommitButtonWithClickState
            style={styles.commitButton}
            type={user?.scheduleMeetingUrl ? "red" : "gray"}
            clickHandler={() => handleEmailClick()}
          >
            Send Email
          </CommitButtonWithClickState>
        ) : null}
      </div>
      {currentUser ? (
        <BiographyModal user={currentUser} target={target} />
      ) : null}
    </div>
  )
}

UserActionCard.propTypes = {
  user: PropTypes.objectOf(PropTypes.any),
  customStyles: PropTypes.objectOf(PropTypes.any),
  showBorder: PropTypes.bool,
  showScheduleButton: PropTypes.bool,
  isShortCard: PropTypes.bool,
  target: PropTypes.string
}

UserActionCard.defaultProps = {
  user: {},
  showScheduleButton: false,
  customStyles: {},
  showBorder: false,
  isShortCard: false,
  target: ""
}

export default UserActionCard
