import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch, useSelector } from "react-redux"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"

import { toggleModal } from "../../Redux/Actions/User/functions"

export const modalUsi = "simpleModal"

const styles = {
  modalContainer: {
    maxWidth: "800px",
    width: "85%",
    background: "#F5F6F8"
  },
  footerButton: {
    width: "100px",
    marginLeft: "20x",
    marginRight: "26px",
    marginBottom: "20px"
  },
  modalOverlay: {
    padding: "40px"
  },
  modalText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px"
  }
}

const SimpleModal = () => {
  const dispatch = useDispatch()

  const { text, title } = useSelector((state) => ({
    title: state.modal.title,
    text: state.modal.text
  }))

  const closeModal = () => {
    dispatch(
      toggleModal({
        modal_mode: false,
        modal_usi: modalUsi
      })
    )
  }

  return (
    <ModalDialogHandler
      modal_usi={modalUsi}
      paperProps={{ style: styles.modalContainer }}
      footer={
        <DialogActions>
          <CommitButtonWithoutClickState
            clickHandler={closeModal}
            id={modalUsi}
            type="white"
            style={styles.footerButton}
          >
            Ok
          </CommitButtonWithoutClickState>
        </DialogActions>
      }
    >
      <div style={styles.modalOverlay}>
        {Boolean(title) && (
          <>
            <h5>{title}</h5>
            <hr />
          </>
        )}
        <p style={styles.modalText}>
          {text}
          <br />
        </p>
      </div>
    </ModalDialogHandler>
  )
}

export default SimpleModal
