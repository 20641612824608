import { buildSteps } from "../../Utils/metadataUtils";

export const AlliedForcesPostSurveyMetadata = [
  {
    id: "survey",
    title: "Survey",
    display: true,
    steps: [
      {
        stepId: "survey",
        title: "Survey",
        body:
          "Please read each statement and select the circle that most closely matches how you feel about the statement in relation to your transition.",
        url: `/post-survey/allied-forces?title=survey`
      }
    ]
  },
  {
    id: "services",
    title: "Services",
    display: true,
    steps: [
      {
        stepId: "services-one",
        title: "Services",
        body: "Which services are you finding the most useful? (Pick up to 3)",
        url: `/post-survey/allied-forces?title=services-one`
      },
      {
        stepId: "services-two",
        title: "Services",
        body:
          "Are services designed and delivered according to your individual needs and timeline?",
        url: `/post-survey/allied-forces?title=services-two`
      }
    ]
  },
  {
    id: "goals",
    title: "Goals",
    display: true,
    steps: [
      {
        stepId: "goals-one",
        title: "Goals",
        body: `We would like to assess how well The COMMIT Foundation are helping you achieve your goals.`,
        url: `/post-survey/allied-forces?title=goals-one`
      }
    ]
  },
  {
    id: "submission",
    title: "Submission",
    display: true,
    steps: [
      {
        stepId: "submission-one",
        title: "Submission",
        body: "",
        url: `/post-survey/allied-forces?title=submission-one`
      },
      {
        stepId: "submission-two",
        title: "Submission",
        body: "",
        url: `/post-survey/allied-forces?title=submission-two`
      }
    ]
  }
];

export const AlliedForcesPostSurveySteps = buildSteps(AlliedForcesPostSurveyMetadata);
