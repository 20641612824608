import queryString from "query-string";
import types from "./types";
import * as utils from "../../Utils/actionUtils";
import fetch from "../../../Utils/fetch";

export const toggleAlliedForcesUnfilled = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: types.TOGGLE_ALLIED_FORCES_UNFILLED, // Updated action type
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const changeAlliedForcesWillRelocate = (value) => (dispatch) => {
  dispatch({
    type: types.WILL_ALLIED_FORCES_RELOCATE_CHANGED,  
    data: {
      value,
    },
  });
};

export const fetchAlliedForcesInterests = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.TOGGLE_FETCHING_INTERESTS,
        data: { toggle: true },
      });
      const res = await dispatch(
        fetch(`/interests`, {
          type: "GET",
        })
      );
      dispatch({
        type: types.UPDATE_INTERESTS,
        data: { interests: res.data },
      });
      dispatch({
        type: types.TOGGLE_FETCHING_INTERESTS,
        data: { toggle: false },
      });
    } catch (data) {
      console.error(data);
      dispatch({
        type: types.ERROR,
        data: data.response,
      });
    }
  };
};

export const fetchAlliedForcesApplication = (initialLoad, history) => {
  return async (dispatch) => {
    try {
      const pathname = history.location.pathname;

      const endpoint_application = pathname.includes("/spouse")
        ? "/spouse-application"
        : pathname.includes("/allied-forces")
        ? "/allied-forces-application"
        : "/application";

      const { data } = await dispatch(
        fetch(endpoint_application, {
          type: "GET",
        })
      );

      const actionType =
        endpoint_application === "/spouse-application"
          ? types.INIT_SPOUSE_APPLICATION
          : endpoint_application === "/allied-forces-application"
          ? types.INIT_ALLIED_FORCES_APPLICATION
          : types.INIT_APPLICATION;

      if (initialLoad) {
        dispatch({
          type: actionType,
          data: {
            application: utils.formatIncomingApplication(data),
            selectedApplicationInput: {
              selectedItemId: 0,
              selectedStepId: 1,
            },
          },
        });
      } else {
        dispatch({
          type: actionType,
          data: {
            application: utils.formatIncomingApplication(data),
            history,
          },
        });
      }
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response,
      });
    }
  };
};

export const saveAlliedForcesApplication = (inputData, history, next) => {
  return async (dispatch, getState) => {
    try {
      const pageTitle = queryString.parse(history.location.search).title;
      const data = inputData || getState().application.currentInputData;

      if (data) {
        const endpoint = history.location.pathname.includes("/spouse")
          ? "/spouse-application"
          : history.location.pathname.includes("/allied-forces")
          ? "/allied-forces-application"
          : "/application";

        await dispatch(
          fetch(
            endpoint,
            {
              type: "POST",
              data: {
                requestPayload: utils.processInputData(
                  utils.formatInputData(data, pageTitle)
                ),
              },
            },
            {
              timeout: 20000,
            }
          )
        );

        const res = await dispatch(
          fetch(endpoint, {
            type: "GET",
          })
        );

        const actionType =
          endpoint === "/spouse-application"
            ? types.FETCHED_SPOUSE_APPLICATION
            : endpoint === "/allied-forces-application"
            ? types.FETCHED_ALLIED_FORCES_APPLICATION
            : types.FETCHED_APPLICATION;

        dispatch({
          type: actionType,
          data: {
            application: utils.formatIncomingApplication(res.data),
          },
        });

        if (next) {
          history.push(next);
        }
      }
    } catch (data) {
      console.error(data);
      dispatch({
        type: types.ERROR,
        message: data,
        data: data.response,
      });
    }
  };
};

export const setAlliedForcesInputData = (inputData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_ALLIED_FORCES_APPLICATION_INPUT_DATA,
        data: { inputData },
      });
    } catch (response) {
      dispatch({
        type: types.ERROR,
        data: {
          message: response.data,
        },
      });
    }
  };
};

export const finalizeAndSubmitAlliedForcesApplication = (history, inputData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SUBMIT_ALLIED_FORCES_APPLICATION,
      });

      const endpoint_application = history.location.pathname.includes("/spouse")
        ? "/spouse-application"
        : history.location.pathname.includes("/allied-forces")
        ? "/allied-forces-application"
        : "/application";

      if (inputData) {
        await dispatch(
          fetch(endpoint_application, {
            type: "POST",
            data: { requestPayload: utils.processInputData(inputData) },
          })
        );
      }

      const endpoint_submit = history.location.pathname.includes("/spouse")
        ? "/spouse-submitApplication"
        : history.location.pathname.includes("/allied-forces")
        ? "/allied-forces-submitApplication"
        : "/submitApplication";

      await dispatch(
        fetch(endpoint_submit, {
          type: "POST",
          data: {
            requestPayload: null,
          },
        })
      );

      const res = await dispatch(
        fetch(endpoint_application, {
          type: "GET",
        })
      );

      dispatch({
        type: types.FETCHED_USER,
        user: res.data,
      });

      history.push("/application/status");

      dispatch({
        type: types.SUBMITTED_ALLIED_FORCES_APPLICATION,
      });
    } catch (data) {
      console.error(data);
      dispatch({
        type: types.ERROR,
        message: data,
        data: data.response,
      });
    }
  };
};

export default {
  toggleAlliedForcesUnfilled,
  changeAlliedForcesWillRelocate,
  fetchAlliedForcesApplication,
  saveAlliedForcesApplication,
  setAlliedForcesInputData,
  finalizeAndSubmitAlliedForcesApplication
};
