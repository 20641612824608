import React from "react"
import PersonalInfoInputsThree from "../PersonalInfoInputsThree/PersonalInfoInputsThree"
import BiographyOne from "../BiographyOne/BiographyOne"
import ProfilePersonalOne from "../ProfilePersonalOne/ProfilePersonalOne"
import ProfileLocationInputs from "../ProfileLocationInputs/ProfileLocationInputs"
import ProfileMilitaryOne from "../ProfileMilitaryOne/ProfileMilitaryOne"
import ProfileIndustries from "../ProfileIndustries/ProfileIndustries"
import AboutYouOne from "../AboutYouOne/AboutYouOne"
import ProfileProfessionalOne from "../ProfileProfessionalOne/ProfileProfessionalOne"
import ProfileProfessionalTwo from "../ProfileProfessionalTwo/ProfileProfessionalTwo"

const profileHashMap = {
  personal: (props) => {
    return (
      <ProfilePersonalOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  relocation: (props) => {
    return (
      <PersonalInfoInputsThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  biography: (props) => {
    return (
      <BiographyOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "about-you": (props) => {
    return (
      <AboutYouOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  location: (props) => {
    return (
      <ProfileLocationInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  military: (props) => {
    return (
      <ProfileMilitaryOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "professional-industries": (props) => {
    return (
      <ProfileIndustries
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "professional-basic": (props) => {
    return (
      <ProfileProfessionalOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "professional-preference": (props) => {
    return (
      <ProfileProfessionalTwo
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  }
}

export default profileHashMap
