/* This is used for our gtm integration */

export const gtmClickHandler = ({ name, data }) => {
  if (!name || !data) return

  window.dataLayer.push({
    event: name,
    eventMsg: data
  })
}

export const gtmNonInteraction = ({ action, category, label }) => {
  window.dataLayer.push({
    event: {
      action,
      category,
      label,
      non_interaction: true
    }
  })
}
