import React from "react"
// import PropTypes from 'prop-types'
import { Logo } from "../../Icons/Logo"
import DesktopNavItem from "../DesktopNavItem/DesktopNavItem"
import ListWithSelectedComponent from "../ListWithSelectedComponent/ListWithSelectedComponent"
import "./WebDesktopNavMenu.css"
import { withRouter, Link } from "react-router-dom"
import colors from "../../Constants/colors"
import constants from "../../Constants"

const baseUrl = "https://www.commitfoundation.org"

export const WebDesktopNavMenu = (props) => {
  const navOptions = [
    {
      isSelected: false,
      renderComponent: (props) => {
        return (
          <DesktopNavItem
            key={props.key}
            id={0}
            clickHandler={props.selectOption}
            isSelected={props.isSelected}
            popoverOptions={[
              {
                title: "1:1 Assistance",
                link: "one-on-one"
              },
              {
                title: "Digital Library",
                link: "digital-library"
              },
              {
                title: "Pursue Your Purpose",
                link: "pursueyourpurpose"
              },
              {
                title: "Workshops",
                link: "transition-mentoring"
              }
            ]}
          >
            Veteran Programs
          </DesktopNavItem>
        )
      }
    },
    {
      isSelected: false,
      renderComponent: (props) => {
        return (
          <DesktopNavItem
            key={props.key}
            id={2}
            isSelected={props.isSelected}
            clickHandler={props.selectOption}
            popoverOptions={[
              {
                title: "Mission",
                link: "mission"
              },
              {
                title: "Our Team",
                link: "team"
              }
            ]}
          >
            About
          </DesktopNavItem>
        )
      }
    },
    {
      isSelected: false,
      renderComponent: (props) => {
        return (
          <DesktopNavItem
            key={props.key}
            id={3}
            isSelected={props.isSelected}
            clickHandler={props.selectOption}
            popoverOptions={[
              {
                title: "Mentors",
                link: "our-mentors"
              },
              {
                title: "Partners",
                link: "partners"
              },
              {
                title: "Donors",
                link: "donate"
              }
            ]}
          >
            Support Us
          </DesktopNavItem>
        )
      }
    },
    {
      isSelected: false,
      renderComponent: (props) => {
        return (
          <div
            key={props.key}
            style={{
              width: "130px",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Link
              to="/account/createAccount"
              className="apply-btn"
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "9px",
                paddingBottom: "9px",
                cursor: "pointer",
                margin: "auto",
                width: "103px",
                border: "1px solid #fff"
              }}
            >
              Apply
            </Link>
          </div>
        )
      }
    },
    {
      isSelected: false,
      renderComponent: (props) => {
        return (
          <div
            key={props.key}
            style={{
              width: "130px",
              display: "flex",
              alignItems: "center",
              marginLeft: "-13px"
            }}
          >
            <a
              href={constants.DONATE_URL}
              className="donate-btn"
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "9px",
                paddingBottom: "9px",
                cursor: "pointer",
                color: colors.midnight,
                margin: "auto",
                width: "107px",
                textDecoration: "none"
              }}
            >
              Donate
            </a>
          </div>
        )
      }
    }
  ]
  return (
    <div style={styles.navContentContainer} className="navContainer">
      <a aria-label="Go to home page" href="https://www.commitfoundation.org">
        <Logo />
      </a>
      <span style={styles.navOptions}>
        <ListWithSelectedComponent components={navOptions} />
      </span>
    </div>
  )
}

const styles = {
  navOptions: {
    display: "flex",
    height: "100%"
  },
  navContentContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  }
}

export default withRouter(WebDesktopNavMenu)
