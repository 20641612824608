import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch } from "react-redux"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import Actions from "../../Redux/Actions"

const styles = {
  modalOverlay: {
    padding: "40px",
    width: "400px"
  },
  innerText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px"
  }
}

export default function ProgramDisabledModal() {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: "programDisabled"
      })
    )
  }

  return (
    <ModalDialogHandler
      modal_usi="programDisabled"
      footer={
        <DialogActions>
          <CommitButtonWithoutClickState
            clickHandler={closeModal}
            id="program disabled reminder"
            type="red"
            style={{
              width: "164px",
              marginLeft: "20x",
              marginRight: "26px",
              marginBottom: "20px"
            }}
          >
            Got It!
          </CommitButtonWithoutClickState>
        </DialogActions>
      }
    >
      <div style={styles.modalOverlay}>
        <p style={styles.innerText}>
          We are experiencing a higher than normal visitor volume to our
          website. <br />
          <br />
          Please Try again later!
        </p>
      </div>
    </ModalDialogHandler>
  )
}
