import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Loader from "react-loader-spinner"
import moment from "moment"
import colors from "../../Constants/colors.js"
import CommitDrawer from "../CommitDrawer/CommitDrawer"
import Actions from "../../Redux/Actions"
import GoogleTagManager from "../GoogleTagManager/GoogleTagManager"

export class Application extends React.Component {
  constructor() {
    super()
    this.state = {
      hasError: false
    }
    this.setDimensions = this.setDimensions.bind(this)
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    })
  }

  setDimensions() {
    if (this.props.user) {
      let pypStatus = ""
      if (this.props.user.modules) {
        const userModules = this.props.user && this.props.user.modules
        if (userModules.length === 12 && userModules[11].pivot.completed) {
          pypStatus = "Completed"
        } else if (userModules.length) {
          pypStatus = "In Progress"
        } else pypStatus = "Unenrolled"
      }

      window.dataLayer.push({
        dimensions: {
          rank:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.lastRank,
          USSOCOM:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.sof,
          military_status:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.status,
          time_until_separation:
            this.props.user &&
            this.props.user.military &&
            `${moment(this.props.user.military.retirementDate).diff(
              moment(),
              "months"
            )} Months`,
          application_submitted: this.props.user.has_submitted_application
            ? "Yes"
            : "No",
          PYP_status: pypStatus,
          gender: this.props.user && this.props.user.gender
        }
      })
    }
  }

  render() {
    return this.props.initialized ? (
      <GoogleTagManager
        gtmId={process.env.REACT_APP_APPLICATION_GTM_ID}
        onFinishLoad={this.setDimensions}
      >
        <div
          className="applicationWrapper"
          style={{ ...styles.componentContainer, ...this.props.style }}
        >
          <CommitDrawer
            style={styles.sidebarContainer}
            desktopMenu={this.props.drawerComponent}
          />
          <div
            className="applicationProcessContainer"
            id="applicationScrollContainer"
            style={styles.applicationProcessContainer}
          >
            <div style={styles.contentWrapper}>{this.props.children}</div>
          </div>
        </div>
      </GoogleTagManager>
    ) : (
      <div style={styles.loaderContainer}>
        <Loader type="Oval" color={colors.midnight} height="100" width="100" />
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.white,
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0
  },
  loaderContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  contentWrapper: {
    width: "100%",
    margin: "auto",
    minHeight: "calc(100vh - 110px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  applicationProcessContainer: {
    width: "100%",
    backgroundColor: "#f5f6f8",
    display: "flex",
    flexDirection: "column"
  },
  sidebarContainer: {
    width: "20.93%",
    minWidth: "190px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    user: state.user.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application)
