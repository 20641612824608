import React from "react"
import PYPTitle from "../PYPTitle/PYPTitle"
import PYPDescription from "../PYPDescription/PYPDescription"
import colors from "../../Constants/colors"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"

const PYPPartReflectTitleContent = ({
  title,
  description,
  style,
  textStyle
}) => {
  return (
    <>
      {title && description ? (
        <div style={{ ...styles.componentContainer, ...style }}>
          <PYPTitle
            style={{
              fontFamily: "Lato-Bold",
              fontSize: "40px",
              display: "flex",
              justifyContent: "left",
              width: "100%",
              color: colors.white,
              ...textStyle
            }}
            title={title}
          />
          <PYPDescription
            style={{
              textAlign: "left",
              fontSize: "18px",
              color: colors.white,
              paddingTop: "20px",
              fontFamily: "Lato-Light",
              ...textStyle
            }}
            description={description}
          />
        </div>
      ) : (
        <div style={{ ...styles.componentContainer, ...style }}>
          <SkeletonWrapper theme="dark" containerStyle={{ maxWidth: "600px" }}>
            <rect x="27" y="16" rx="4" ry="4" width="414" height="50" />
            <rect x="28" y="97" rx="4" ry="4" width="521" height="22" />
            <rect x="27" y="131" rx="4" ry="4" width="521" height="22" />
            <rect x="28" y="166" rx="4" ry="4" width="115" height="22" />
          </SkeletonWrapper>
        </div>
      )}
    </>
  )
}

const styles = {
  componentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "800px",
    minWidth: "280px"
  }
}

export default PYPPartReflectTitleContent
