import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import PropTypes from "prop-types"

import { useWindowResize } from "../../Hooks/useWindowResize"
import { retrieveUsers, toggleModal } from "../../Redux/Actions/User/functions"
import { gtmClickHandler } from "../../Utils/gtm"

import MembersPageInfiniteScroll from "../MembersPageInfiniteScroll/MembersPageInfiniteScroll"
import MembersCard from "../MembersCard/MembersCard"
import NoSearchResults from "../NoSearchResults/NoSearchResults"
import { BiographyModal } from "../Modals"

const styles = {
  infiniteScroll: {
    display: "flex",
    flexDirection: "row"
  },
  noResults: {
    height: "330px",
    alignItems: "auto",
    width: "calc(100% - 66px)",
    justifyContent: "left",
    maxWidth: "1285px"
  },
  stackedNoResults: {
    flexDirection: "column-reverse",
    alignItems: "center",
    marginBottom: "75px"
  }
}

const MemberCardContainer = ({ location, spouseDashboard }) => {
  const { width } = useWindowResize()
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState({})
  const { users, self, attendees } = useSelector((state) => {
    const { user } = state.user

    return {
      users: state.networkUsers.users || [],
      self: {
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        city: user?.address?.city,
        state: user?.address?.state,
        interests: ["Interests"],
        currentJob: user?.community?.current_job,
        linkedIn: user.linkedin_url,
        profile_image_url: user.profile_image_url,
        military: {
          branch: user?.military?.branch,
          specialty: user?.military?.specialty
        },
        tags: user?.tags?.map(({ name }) => name) || []
      },
      attendees: state.workshop?.attendees
    }
  })

  useEffect(() => {
    let search = queryString.parse(location.search)
    search = Object.keys(search)
      .filter((term) => term !== "campaignId" && term !== "spouse")
      .reduce((obj, term) => {
        return Object.assign(obj, {
          [term]: search[term]
        })
      }, {})

    dispatch(retrieveUsers(search, attendees))
  }, [location.search, attendees])

  const loadMore = (page) => {
    dispatch(retrieveUsers(queryString.parse(location.search), page + 1))
  }

  const onBiographyPress = (user) => {
    setCurrentUser(user)

    dispatch(
      toggleModal({
        modal_mode: true,
        modal_usi: `${BiographyModal.modalUsi}network`
      })
    )

    gtmClickHandler({
      name: "BiographyClickEvent",
      data: {
        action: "Biography Clicked",
        category: "User Interaction",
        label: user.fullName
      }
    })
  }

  const renderedData =
    (users.length &&
      users.map((user, index) => {
        if (user.fullName) {
          return (
            <MembersCard
              user={user}
              key={user.id}
              onBiographyPress={() => onBiographyPress(user)}
            />
          )
        }

        return (
          <MembersCard
            user={{ loading: true }}
            // eslint-disable-next-line react/no-array-index-key
            key={`user-${index}`}
            onBiographyPress={() => onBiographyPress(user)}
          />
        )
      })) ||
    null

  if (
    renderedData &&
    Object.keys(queryString.parse(location.search)).length <= 2 &&
    users[0].fullName
  ) {
    renderedData.unshift(
      <MembersCard
        user={self}
        key="user"
        onBiographyPress={() => onBiographyPress(self)}
      />
    )
  }

  return (
    <>
      {renderedData ? (
        <MembersPageInfiniteScroll
          loadMore={loadMore}
          style={styles.infiniteScroll}
          spouseDashboard={spouseDashboard}
        >
          {renderedData || []}
        </MembersPageInfiniteScroll>
      ) : (
        <NoSearchResults
          copy="We did not find any veterans matching your query!"
          style={{
            ...styles.noResults,
            ...(width < 600 ? styles.stackedNoResults : {})
          }}
        />
      )}
      <BiographyModal user={currentUser} target="network" />
    </>
  )
}

MemberCardContainer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
}

export default withRouter(MemberCardContainer)
