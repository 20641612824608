import React from "react"
import "./LayoutMetaDataReference.css"

import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartReflectTitleContent from "../PYPPartReflectTitleContent/PYPPartReflectTitleContent.js"
import PYPStaticInputBox from "../PYPStaticInputBox/PYPStaticInputBox.js"
import Triangle from "../../Assets/Images/Triangle.svg"
import PYPCarousel from "../PYPCarousel/PYPCarousel.js"
import SkeletonCard from "../SkeletonCard/SkeletonCard.js"

import Actions from "../../Redux/Actions"

class LayoutMetaDataReference extends React.Component {
  componentDidMount() {
    if (!this.props.pyp.savingPYP) {
      this.props.fetchPartInputs()
      // Use the referenced part ID to grab the correct data from the part - you'll probably have to actually fetch the part first
      this.props.fetchPartData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
      this.props.fetchPartInputs()
      // Use the referenced part ID to grab the correct data from the part - you'll probably have to actually fetch the part first
      this.props.fetchPartData()
    }
  }

  getReferenceComponent() {
    if (this.props.referenceBoxType === "single") {
      // render a single card
      const numInputs = this.props.referenceInputs.length

      if (numInputs) {
        // inputs have loaded - render data

        // If there's a lead input, use that:
        const input =
          (numInputs > 1 &&
            this.props.referenceInputs.find(
              (input) => input.buttonSelectionTitle.is_lead
            )) ||
          this.props.referenceInputs[0] // otherwise use the first input

        return (
          <PYPStaticInputBox
            title={
              input.input_title_id
                ? input.title.title
                : (input.buttonSelectionTitle.buttonValue &&
                    input.buttonSelectionTitle.buttonValue.name) ||
                  (input.buttonSelectionTitle.customButtonValue &&
                    input.buttonSelectionTitle.customButtonValue.name)
            }
            text={input.text}
            size={input.size}
          />
        )
      }
      // still loading - render skeleton
      return <SkeletonCard size={this.props.referenceBoxSize} />
    }
    // render a carousel
    const heightMap = {
      small: 260,
      medium: 320,
      large: 391,
      default: 260
    }

    const widthMap = {
      small: 380,
      medium: 527,
      large: 605,
      default: 380
    }

    let cards
    let loading

    if (this.props.referenceInputs.length) {
      // inputs have loaded - render data
      cards = this.props.referenceInputs.map((input) => {
        return (
          <PYPStaticInputBox
            title={
              input.input_title_id
                ? input.title.title
                : (input.buttonSelectionTitle.buttonValue &&
                    input.buttonSelectionTitle.buttonValue.name) ||
                  (input.buttonSelectionTitle.customButtonValue &&
                    input.buttonSelectionTitle.customButtonValue.name)
            }
            size={this.props.referenceBoxSize}
            text={input.text}
            style={{ height: "100%" }}
          />
        )
      })

      loading = false
    } else {
      // still loading - render skeleton
      const skeleton = (
        <SkeletonCard
          size={this.props.referenceBoxSize}
          style={{ height: "100%" }}
        />
      )
      cards = Array(3)
        .fill(1)
        .map(() => skeleton)
      loading = true
    }

    return (
      <PYPCarousel
        cards={cards}
        loading={loading}
        cardHeight={heightMap[this.props.referenceBoxSize] || heightMap.default}
        cardWidth={widthMap[this.props.referenceBoxSize] || widthMap.default}
      />
    )
  }

  render() {
    const referenceComponent = this.getReferenceComponent()
    return (
      <PYPLayoutContainer
        backgroundImage={Triangle}
        style={{
          ...this.props.style,
          backgroundPosition: "right bottom",
          backgroundRepeat: "no-repeat"
        }}
      >
        <div className="sectionContainer" style={styles.sectionContainer}>
          <div className="section1" style={styles.section}>
            <PYPPartReflectTitleContent
              title={this.props.metadata.title}
              description={this.props.metadata.description}
              style={{
                width: "500px"
              }}
            />
          </div>
          <div style={{ ...styles.section }}>{referenceComponent}</div>
        </div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  sectionContainer: {
    flex: 1,
    display: "flex",
    flexWrap: "wrap"
  },
  section: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 20px",
    alignSelf: "center",
    width: "50%",
    minWidth: "320px",
    paddingTop: "20px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let referenceInputs = []

  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data

  if (partData) {
    metadata = partData.title[0]
    referenceInputs = partData.referenceInputs
  }

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    referenceInputs
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutMetaDataReference)
)
