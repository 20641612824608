import React from "react"
import colors from "../../Constants/colors"
import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"

import ButtonSelection from "../ButtonSelection/ButtonSelection"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import SkeletonButtons from "../SkeletonButtons/SkeletonButtons.js"

import "./LayoutButtonSelectionStage2MetaData.css"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Actions from "../../Redux/Actions"

class LayoutButtonSelectionStage2MetaData extends React.Component {
  constructor(props) {
    super(props)
    this.changeHandler = this.changeHandler.bind(this)
  }

  componentDidMount() {
    if (!this.props.pyp.savingPYP) {
      this.props.fetchPartData()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
      this.props.fetchPartData()
    }
  }

  changeHandler(inputData) {
    this.props.setButtonSelection(inputData[0])
  }

  render() {
    const buttonComponent = this.props.buttonValues.length ? (
      <ButtonSelection
        limit={3}
        changeHandler={this.changeHandler}
        inputData={this.props.buttonValues}
        renderComponent={({ inputComponent }) => (
          <div
            style={{
              width: "100%",
              marginBottom: "100px"
            }}
          >
            {inputComponent}
          </div>
        )}
      />
    ) : (
      <SkeletonButtons quantity={10} />
    )

    return (
      <PYPLayoutContainer
        backgroundColor={colors.background}
        style={{ justifyContent: "flex-start" }}
      >
        <div
          className="buttonSelectionStage2Layout"
          style={{ ...styles.buttonSelectionStage2Layout, ...this.props.style }}
        >
          <div>
            <PYPPartTitleContent
              title={this.props.title.title}
              description={this.props.title.description}
            />
          </div>
          {buttonComponent}
        </div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  buttonSelectionStage2Layout: {
    width: "100%", // 80%?
    // height: '100vh', //80%?
    overflow: "visible",
    backgroundColor: colors.background,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  try {
    const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
    const partDataId = currentPart.partData

    let buttonValues = []
    let metadata = { title: null, description: null }

    const partData =
      state.pyp.partData.byId[partDataId] &&
      state.pyp.partData.byId[partDataId].data
    if (partData) {
      metadata = partData.title[0]
      buttonValues = partData.buttonValues
        .filter((buttonValue) => {
          return buttonValue.buttonSelections.length
        })
        .map((selection) => {
          return {
            value: selection.name,
            id: selection.id,
            isCustom: selection.isCustom,
            buttonSelection: selection.buttonSelections[0],
            isSelected:
              selection.buttonSelections[0].pivot.stage &&
              selection.buttonSelections[0].pivot.stage === 2
          }
        })

      // alphabetize only the non-custom buttons
      const customButtons = buttonValues.filter((value) => value.isCustom)
      const defaultButtons = buttonValues
        .filter((value) => !value.isCustom)
        .sort((a, b) =>
          a.value.toUpperCase() > b.value.toUpperCase()
            ? 1
            : b.value.toUpperCase() > a.value.toUpperCase()
            ? -1
            : 0
        )

      buttonValues = [...customButtons, ...defaultButtons]
    }

    return {
      ...props,
      pyp: state.pyp,
      currentPart,
      partDataId,
      buttonValues,
      title: metadata
    }
  } catch (e) {
    console.log(e)
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LayoutButtonSelectionStage2MetaData)
)
