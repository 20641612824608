const colors = {
  background: "#f5f6f8",
  text: "#4a4a4a",
  border: "#d6d6d6",
  white: "#ffffff",
  midnight: "#030d26",
  gray: "#787f8c",
  borderLight: "#e1e0e0",
  darkGray: "#4a4a4a",
  lightGray: "#a5a5a5",
  iceBlue: "#d8f0f8",
  // warmGrey: '#797979',
  fadedRed: "#e1373a",
  mediumBlue: "#292e4c",
  charcoalGrey: "#343642",
  blueyGrey: "#9fa9ba",
  warmGrey: "#9b9b9b",
  // just added
  softGray: "#f5f6f7",
  fadedGray: "#8792A5"
}

export default colors
