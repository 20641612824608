import { useEffect } from "react"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { applicationSteps } from "../../Redux/Reducers/Application/applicationMetadata"
import { preSurveySteps } from "../../Redux/Reducers/Application/preSurveyMetadata"
import { postSurveySteps } from "../../Redux/Reducers/Application/postSurveyMetadata"

const IncompleteFormularyRedirect = ({ children, history }) => {
  const { user } = useSelector((state) => ({
    user: state?.user?.user
  }))

  const getRedirectUrl = () => {
    if (!user.has_submitted_application) {
      return applicationSteps[0].url
    }

    if (user.tier_confirmed) {
      if (!user.has_completed_pre_survey) {
        return preSurveySteps[0].url
      }

      if (!user.has_completed_post_survey) {
        return postSurveySteps[0].url
      }
    }

    return null
  }

  useEffect(() => {
    if (!user.id) return

    const redirectUrl = getRedirectUrl()

    if (redirectUrl) {
      history.push(redirectUrl)
    }
  }, [user])

  return children
}

export default withRouter(IncompleteFormularyRedirect)
