import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"
import colors from "../../Constants/colors"

export class Playlist extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
    this.props.setMyPlaylist(this.props.user.recentlyWatched)
    document.title = `The Digital Library | Recently Watched`

    window.dataLayer.push({
      page_view: {
        page_path: `${
          this.props.location.pathname + this.props.location.search
        }`,
        page_title: document.title
      }
    })
  }

  render() {
    return (
      <>
        {this.props.user.recentlyWatched.length ? (
          <VideoThumbnailList
            style={{
              listStyleType: "none",
              padding: "calc(3.5% + 5px)",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              width: "100%",
              display: "flex"
            }}
            from="recentlyWatched"
            videos={this.props.videos}
          />
        ) : (
          <span
            style={{
              display: "flex",
              width: "100%",
              color: colors.midnight,
              height: "500px",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            No Videos Watched Yet!
          </span>
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    user: state.user.user,
    videos: state.digitalLibrary.videos.allIds.map((videoId) => {
      return {
        ...state.digitalLibrary.videos.byId[videoId],
        // Currently, if a video is in a user's recently watched, they would've had to have seen it.
        // This doesn't feel like a stable solution to this - but it's quick and performant.
        // To rewrite in a proper way, you'll need to update what gets passed through the API
        userWatches: [{}]
      }
    })
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Playlist)
)
