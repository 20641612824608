import React from "react"
import { Line } from "rc-progress"
import colors from "../../Constants/colors.js"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { RedUpload } from "../../Icons/RedUpload"

export class VideoUploadProgress extends React.Component {
  render() {
    return (
      <AccountFormContainer
        style={{
          paddingRight: "0px",
          paddingLeft: "0px",
          paddingBottom: "0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <RedUpload />
        <p
          style={{
            marginTop: "30px",
            fontFamily: "Lato-Regular",
            fontSize: "25px",
            lineHeight: "31px",
            textAlign: "center",
            color: colors.gray
          }}
        >
          Uploading...
        </p>
        <p
          style={{
            fontSize: "16px",
            color: colors.gray,
            fontFamily: "Lato-Regular"
          }}
        >
          {`${this.props.percentageComplete}%`}
        </p>
        <Line
          style={{
            marginTop: "30px"
          }}
          strokeLinecap="square"
          trailWidth="4"
          trailColor="#fff"
          percent={this.props.percentageComplete}
          strokeWidth="4"
          strokeColor={colors.fadedRed}
        />
      </AccountFormContainer>
    )
  }
}

export default VideoUploadProgress
