import types from "../../Actions/types"

const defaultFormularyInitialState = {
  inputs: {},
  sections: {}
}

const initialState = {
  currentFormularyType: null,
  currentFormularyTitle: "",
  preSurvey: defaultFormularyInitialState,
  postSurvey: defaultFormularyInitialState,
  application: defaultFormularyInitialState // Currently only being used for top 3 goals and the rest is legacy implementation
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_FORMULARY: {
      const { currentFormularyType } = state
      const { inputs, sections } = action.payload

      return {
        ...state,
        [currentFormularyType]: {
          sections,
          inputs: {
            /* Normalize inputs by id */
            ...inputs.reduce(
              (acum, input) => ({
                ...acum,
                [input.id]: { ...input }
              }),
              {}
            )
          }
        }
      }
    }
    case types.CHANGE_FORMULARY_INPUT: {
      const { currentFormularyType } = state
      const { inputId, value } = action.payload
      const currentFormulary = state[currentFormularyType]
      const currentInput = currentFormulary.inputs[inputId]

      return {
        ...state,
        [currentFormularyType]: {
          ...currentFormulary,
          inputs: {
            ...currentFormulary.inputs,
            [inputId]: {
              ...currentInput,
              value
            }
          }
        }
      }
    }
    case types.SET_CURRENT_FORMULARY: {
      const { formularyType, formularyTitle } = action.payload

      return {
        ...state,
        currentFormularyType: formularyType,
        currentFormularyTitle: formularyTitle
      }
    }
    case types.VALIDATE_CURRENT_STEP: {
      const { sectionId, isValid } = action.payload
      const { currentFormularyType } = state
      const currentFormulary = state[currentFormularyType]

      return {
        ...state,
        [currentFormularyType]: {
          ...currentFormulary,
          sections: {
            ...currentFormulary.sections,
            [sectionId]: {
              ...currentFormulary.sections[sectionId],
              isValid
            }
          }
        }
      }
    }
    case types.UPDATE_VISITED_STEP_FLAG: {
      const { stepId, sectionId } = action.payload
      const { currentFormularyType } = state
      const currentFormulary = state[currentFormularyType]

      /* This spread hell can be solved with immer */
      return {
        ...state,
        [currentFormularyType]: {
          ...currentFormulary,
          sections: {
            ...currentFormulary.sections,
            [sectionId]: {
              ...currentFormulary.sections[sectionId],
              visitedSteps: {
                ...currentFormulary.sections[sectionId].visitedSteps,
                [stepId]: true
              }
            }
          }
        }
      }
    }
    default:
      return state
  }
}
