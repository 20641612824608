import React from "react"
// import PropTypes from 'prop-types'
import { Modal, ModalBody } from "reactstrap"
import "./Tooltip.css"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { debounce } from "debounce"
import Actions from "../../Redux/Actions"

class Tooltip extends React.Component {
  /* Useful props:
      props.show - show/hide the Tooltip
      props.width - manually set the width of the Tooltip. If you don't set the width, the tooltip defaults to a standard width from the reacstrap modal
      props.modalHorizontalOffset - a positive or negative integer for shifting the Tooltip horizontally
      props.arrowOffset - a positive or negative integer for shifting the arrow vertically or horizontally
  */

  constructor(props) {
    super(props)

    this.state = {
      top: 0,
      left: 0,
      modalBodyWidth: 0,
      modalBodyHeight: 0
    }

    // refs to the modal and the child component it renders
    this.modalWrapper = React.createRef()
    this.childWrapper = React.createRef()

    this.updateDimensions = this.updateDimensions.bind(this)
  }

  updateDimensions() {
    this.setModalDimensions()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show !== this.props.show) {
      this.setModalDimensions()
    }

    window.addEventListener("resize", debounce(this.updateDimensions, 100))
  }

  componentDidMount() {
    this.setModalDimensions()
  }

  setModalDimensions() {
    // Get the dimensions of the modal. Note: When this.props.show == false,
    // modalBodyDimensions will be null. For that reason this function gets
    // re-called in componentDidUpdate()
    const modalBodyDimensions =
      this.modalWrapper.current &&
      this.modalWrapper.current.getBoundingClientRect()

    // Get the dimensions of the component this Tooltip is wrapping around
    const childWrapperDimensions =
      this.childWrapper.current &&
      this.childWrapper.current.getBoundingClientRect()

    if (modalBodyDimensions && childWrapperDimensions) {
      this.setState(
        this.getCoordinates(
          childWrapperDimensions || {},
          modalBodyDimensions || {},
          this.props.orientation || "right"
        )
      )
    }
  }

  // Calculates the position of the Tooltip based on the dimensions and position of the component it wraps and the modal content
  getCoordinates(childDimensions, modalDimensions, orientation) {
    switch (orientation) {
      case "top":
        let calculatedLeft =
          (childDimensions.right + childDimensions.left) / 2 -
          this.props.width / 2 +
          this.props.modalHorizontalOffset
        if (calculatedLeft < 20) {
          calculatedLeft = 20
        }
        return {
          top: childDimensions.top - modalDimensions.height - 30,
          left: calculatedLeft,
          modalBodyHeight: modalDimensions.height,
          modalBodyWidth: this.props.width
        }
      case "bottom":
        let calculatedLeftPx =
          (childDimensions.right + childDimensions.left) / 2 -
          this.props.width / 2 +
          this.props.modalHorizontalOffset
        if (calculatedLeftPx < 20) {
          calculatedLeftPx = 20
        }
        return {
          top: childDimensions.bottom - modalDimensions.height + 180,
          left: calculatedLeftPx - 50,
          modalBodyHeight: modalDimensions.height,
          modalBodyWidth: this.props.width
        }

      case "right":
        return {
          top: childDimensions.top - modalDimensions.height / 2 + 28,
          left: childDimensions.right + 23,
          modalBodyHeight: modalDimensions.height,
          modalBodyWidth: this.props.width
        }

      case "left":
        return {
          top: childDimensions.top,
          left: childDimensions.left - modalDimensions.width - 40,
          modalBodyHeight: modalDimensions.height,
          modalBodyWidth: this.props.width
        }
      case "custom":
        return this.props.customDimensions
      default:
        console.warn("getCoordinates received invalid arg")
    }
  }

  refCallback(element) {
    if (element) {
      this.setState(
        this.getCoordinates(
          element.getBoundingClientRect(),
          this.props.orientation || "right"
        )
      )
    }
  }

  getTriangle(orientation) {
    switch (orientation) {
      case "top":
        return (
          <div
            className="arrow-down"
            style={{
              position: "absolute",
              left: `${
                this.state.modalBodyWidth / 2 - 20 + this.props.arrowOffset
              }px`,
              top: `${this.state.modalBodyHeight}px`
            }}
          />
        )
      case "bottom":
        return (
          <div
            className="arrow-up"
            style={{
              position: "absolute",
              left: `${
                this.state.modalBodyWidth / 2 - 20 + this.props.arrowOffset
              }px`,
              top: "-20px"
            }}
          />
        )
      case "right":
        return (
          <div
            className="arrow-left"
            style={{
              position: "absolute",
              left: "-20px",
              top: `${
                this.state.modalBodyHeight / 2 - 20 + this.props.arrowOffset
              }px`
            }}
          />
        )
      case "left":
        return (
          <div
            className="arrow-right"
            style={{
              position: "absolute",
              left: `${this.state.modalBodyWidth - 2}px`,
              top: `${
                this.state.modalBodyHeight / 2 - 20 + this.props.arrowOffset
              }px`
            }}
          />
        )
      case "custom":
        return <div style={this.props.customBurgeStyles} />
      default:
        console.warn("getTriangle received invalid arg")
    }
  }

  render() {
    return (
      <>
        <div style={this.props.style} ref={this.childWrapper}>
          {this.props.renderComponent({
            ...this.props
          })}
        </div>

        <Modal
          isOpen={this.props.show}
          toggle={this.props.toggleModal}
          style={{
            left: this.state.left,
            top: this.state.top,
            margin: "0px",
            width: `${this.props.width}px`,
            height: "400px"
          }}
        >
          <div ref={this.modalWrapper}>
            <ModalBody style={this.props.modalBodyStyles}>
              {this.props.contentComponent()}
            </ModalBody>
          </div>

          {this.getTriangle(this.props.orientation || "right")}
        </Modal>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip)
