export const lightThemePartNames = [
  "Workshop Expectations",
  "Wildest Thing Examples",
  "Wildest Thing Input",
  "My Story Input",
  "People Peak Experience Input",
  "Emotion Peak Experience Input",
  "Environment Peak Experience Input",
  "Absolute Peak Input",
  "All Values",
  "Core Values",
  "Core Constraints Input",
  "Values Descriptions",
  "Questions for Culture Input",
  "Core Industries",
  "All Industries",
  "Interest Industries Input",
  "Carry Forward Skills All",
  "Carry Forward Skills Core",
  "Carry Forward Description",
  "Three Fears Input",
  "Fears Cost Input",
  "Prevent Fears Input",
  "Rewards Input",
  "Personal Morning Input",
  "Personal Afternoon Input",
  "Personal Evening Input",
  "Work Morning Input",
  "Work Afternoon Input",
  "Work Evening Input",
  "Accomplishments Input",
  "What do you want to do Input",
  "Fulfilling Input",
  "Succeed Input",
  "Help Input",
  "Personal Narrative Input",
  "Remember Input",
  "Remember Input v.2",
  "Setting Ideal Work Day Constraints",
  "Ideal Work Day Roadmapper",
  // "Ideal Work Day Roadmapper Reflection",
  "Setting Ideal Personal Day Constraints",
  "Ideal Personal Day Roadmapper",
  "Action Plan Constraints",
  "Action Plan Roadmapper",
  "Action Plan Calender v.1",
  // "Ideal Personal Day Roadmapper Reflection",
  "Courage vs. Confidence 2.0 Inputs 1",
  "Courage vs. Confidence 2.0 Action 1",
  "Courage vs. Confidence 2.0 Action 2",
  "Courage vs. Confidence 2.0 Action 3",
  "Courage vs. Confidence 2.0 Inputs 2",
  "Courage vs. Confidence 2.0 Inputs 3",
  "Help Input v.2"
]
