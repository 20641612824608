import React, { useRef, useEffect } from "react"

function useClickWrapper(
  ref,
  clickHandler,
  onClickHandler = () => {
    ""
  }
) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      clickHandler()
    } else if (ref.current && ref.current.contains(event.target)) {
      onClickHandler()
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })
}

export default function OutsideClickWrapper(props) {
  const wrapperRef = useRef(null)
  useClickWrapper(wrapperRef, props.clickHandler, props.onClickHandler)

  return <div ref={wrapperRef}>{props.children}</div>
}
