import React from "react"

export const Magnifier = (props) => {
  return (
    <div style={{ ...props.style }} onClick={props.onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "21"}
        height={props.height || "21"}
        viewBox="0 0 21 21"
      >
        <g fill={props.color || "#fff"} fillRule="evenodd">
          <path d="M8.489 1.758c-3.705.008-6.73 3.038-6.73 6.74 0 3.696 3.027 6.73 6.731 6.744 3.7.015 6.765-3.057 6.752-6.767-.014-3.702-3.053-6.725-6.753-6.717M8.482 0c4.646-.019 8.5 3.78 8.518 8.395.017 4.76-3.752 8.584-8.482 8.605C3.828 17.02.01 13.198 0 8.473-.01 3.823 3.8.019 8.482 0M17.355 15c1.048 1.033 2.141 2.069 3.183 3.154.649.675.594 1.69-.038 2.318-.666.661-1.66.723-2.35.062-1.085-1.04-2.123-2.13-3.15-3.168L17.355 15" />
        </g>
      </svg>
    </div>
  )
}
