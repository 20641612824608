import React from "react"
import MediaQuery from "react-responsive"
import PYPFooterContainer from "../PYPFooterContainer/PYPFooterContainer"

const PYPFooterDesktopContent = ({
  desktopComponent,
  mobileComponent,
  style
}) => {
  return (
    <PYPFooterContainer className="pypFooterContent" style={style}>
      <MediaQuery maxWidth={599}>{mobileComponent}</MediaQuery>
      <MediaQuery minWidth={600}>{desktopComponent}</MediaQuery>
    </PYPFooterContainer>
  )
}

export default PYPFooterDesktopContent
