import React from 'react';
import { useDispatch } from 'react-redux';
import DialogActions from "@material-ui/core/DialogActions"
import colors from "../../Constants/colors"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import Actions from "../../Redux/Actions"

const ApplicationConfirmationModal = ({submitHandler}) => {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: "applicationConfirmation"
      })
    )
  }

   return (
    <>
      <ModalDialogHandler
        modal_usi="applicationConfirmation"
        paperProps={{
          style: { maxWidth: "800px", minWidth: "315px", width: "50%" }
        }}
        footer={
          <DialogActions>
            <CommitButtonWithoutClickState
              type="white"
              id="return to application"
              style={{ borderRadius: "3px", width: "154px" }}
              clickHandler={closeModal}
            >
              Cancel
            </CommitButtonWithoutClickState>
            <CommitButtonWithoutClickState
              clickHandler={submitHandler}
              id="Submit application confirmed"
              type="red"
              style={{ borderRadius: "3px", width: "154px" }}
            >
              Submit
            </CommitButtonWithoutClickState>
          </DialogActions>
        }
      >
        <div
          style={{
            padding: "50px",
            backgroundColor: colors.background
          }}
        >
          <p style={{fontSize: 'large'}}>
          Thank you for taking the time to complete your application to The COMMIT Foundation.  This application is the first step in us learning more about you, where you are in your transition journey, and whether we are a good match for your transition goals.  Success in our programs requires you to lean into the process and prior to selecting the submit button we ask that you review your answers provided on the application and be as thorough and thoughtful as possible.   We look forward to having the opportunity to work with you.
          </p>
        </div>
      </ModalDialogHandler>
    </>
  );
}

export default ApplicationConfirmationModal;