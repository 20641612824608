import React, { useState } from "react"
import { useSelector } from "react-redux"
import CommitInput from "../CommitInput/CommitInput"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import AnalyticButtonCard from "../AnalyticButtonCard/AnalyticButtonCard"
import colors from "../../Constants/colors"

export default function AnalyticFetchingTable({
  dispatchFunction,
  defaultLimit = 10,
  options = ["OPTIONS ARE REQUIRED"],
  analyticsPointer = "buttonAnalytics",
  cardFormat = "two-input",
  cardFormatStyles,
  title = "Top PYP Buttons",
  dropdownTitle = "Pick a category",
  fetchTextStart = "Get Top Buttons",
  fetchTextSecond = fetchTextStart,
  placeHolder = "No buttons loaded",
  style,
  innerStyle
}) {
  const [data, updateData] = useState(options[0])
  const [fetching, toggleFetching] = useState(false)

  const borderLogic = (index, total) => {
    if (index % 2 === 0 && index < 2) {
      return `groove groove groove groove`
    }
    if (index % 2 === 0) {
      return "none groove groove groove"
    }
    if (index % 2 === 1 && index < 2) {
      return "groove groove groove none"
    }
    if (index % 2 === 1) {
      return "none groove groove none"
    }
  }

  const cardFormatMap = {
    "two-input": (data) => (
      <AnalyticButtonCard
        {...data}
        key={data.index}
        title={data.key}
        style={{
          ...styles["two-input"],
          ...cardFormatStyles,
          borderStyle: borderLogic(data.index, data.total)
        }}
      />
    ),
    "four-input": (data) => (
      <AnalyticButtonCard
        {...data}
        key={data.index}
        title={data.key}
        style={{
          ...styles["two-input"],
          ...cardFormatStyles
        }}
      />
    )
  }

  const analyticsData = useSelector(
    (state) => state.analytics && state.analytics[analyticsPointer]
  )

  const renderedAnalyticsData =
    analyticsData &&
    analyticsData[0] &&
    analyticsData.map((dataSet, index) => {
      return cardFormatMap[cardFormat]({
        ...dataSet,
        index,
        total: analyticsData.length
      })
    })

  const analyticsCall = async () => {
    if (fetching === false) {
      toggleFetching(true)
      await dispatchFunction(data, defaultLimit).then(() =>
        toggleFetching(false)
      )
    }
  }

  return (
    <div
      style={{
        ...styles.container,
        ...style
      }}
    >
      <div
        style={{
          ...styles.header
        }}
      >
        <h1 style={{ marginRight: "auto" }}> {title} </h1>
        <CommitInput
          inputType="select"
          options={options.map((option) => ({ value: option }))}
          value={data}
          handleChange={(e) => updateData(e.target.value)}
          title={dropdownTitle}
          style={{
            ...styles.dropdown
          }}
        />
        <CommitButtonWithClickState
          clickHandler={() => {
            analyticsCall()
          }}
          type="white"
          style={styles.button}
        >
          {renderedAnalyticsData ? fetchTextSecond : fetchTextStart}
        </CommitButtonWithClickState>
      </div>
      <div style={{ ...styles.tableContainer, ...innerStyle }}>
        {renderedAnalyticsData
          ? renderedAnalyticsData.length
            ? renderedAnalyticsData
            : "No Videos"
          : placeHolder}
      </div>
    </div>
  )
}

const styles = {
  "two-input": {
    textAlign: "left",
    width: "50%",
    margin: 0
  },
  container: {
    width: "800px",
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.05)",
    backgroundColor: colors.white,
    padding: "20px",
    margin: "10px",
    // justifyContent: "space-evenly",
    height: "fit-content"
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  },
  dropdown: {
    width: "160px",
    marginRight: "10px",
    height: "70px"
  },
  tableContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    margin: "30px",
    justifyContent: "space-evenly",
    backgroundColor: colors.white,
    maxWidth: "800px"
  },
  button: {
    width: "140px",
    height: "44px",
    marginRight: "10px",
    borderRadius: "3px"
  }
}
