import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import VideoSearchBarContainer from "../VideoSearchBarContainer/VideoSearchBarContainer"
import { BackArrow } from "../../Icons/BackArrow"
import Actions from "../../Redux/Actions"

class MobileDigitalLibrarySearch extends Component {
  constructor(props) {
    super(props)
    this.toggleSearch = this.toggleSearch.bind(this)
  }

  // need to put this into the component
  componentDidMount() {
    if (this.props.match.path === "/digitalLibrary/search/:searchTerm") {
      this.setState({
        searching: true
      })
    }
  }

  // need to put this into the component
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      if (this.props.match.path === "/digitalLibrary/search/:searchTerm") {
        this.setState({
          searching: true
        })
      }
    }
  }

  toggleSearch() {
    this.setState({
      searching: !this.state.searching
    })
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div style={{ marginRight: "3px" }} onClick={this.props.hideSearchBar}>
          <BackArrow
            fill="#D8D8D8"
            height="18px"
            width="18px"
            strokeWidth="4"
          />
        </div>
        {/digitalLibrary/.test(this.props.match.path) && (
          <VideoSearchBarContainer />
        )}
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MobileDigitalLibrarySearch)
)
