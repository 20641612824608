import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CircularProgressbar from "../CircularProgressbar/CircularProgressbar"
import colors from "../../Constants/colors"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

import Actions from "../../Redux/Actions"

export class UserFieldEditorNavMenu extends React.Component {
  constructor(props) {
    super(props)
    this.toIncludeRelocation = this.toIncludeRelocation.bind(this)
  }

  toIncludeRelocation(item) {
    if (item.stepId === "relocation") {
      if (
        this.props.application.application.address &&
        this.props.application.application.address.willLocate === "Yes"
      ) {
        return true
      }
      return false
    }
    return true
  }

  render() {
    const applicationMetaObj = this.props.application.applicationMetadata
    const completedObj = applicationMetaObj.reduce(
      (data, item) => {
        let completed = 0
        const filteredSteps = item.steps.filter(this.toIncludeRelocation)
        filteredSteps.forEach((item) => {
          item.filled && completed++
        })
        return {
          total: data.total + filteredSteps.length,
          completed: data.completed + completed
        }
      },
      { total: 0, completed: 0 }
    )

    const completedPercent = Math.round(
      (completedObj.completed / completedObj.total) * 100
    )

    return (
      <CircularProgressbar
        refs={this.refCallback}
        styles={{
          text: {
            fill: colors.white,
            fontFamily: "Lato-SemiBold"
          },
          path: {
            stroke: colors.fadedRed
          },
          trail: {
            stroke: colors.white
          }
        }}
        percentage={completedPercent}
        upperText={completedPercent.toString()}
        lowerText={`${completedObj.completed}/${completedObj.total}`}
        strokeWidthPath={0.8}
        strokeWidthTrail={3.5}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state) => {
  return {
    application: state.application
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(UserFieldEditorNavMenu))
)
