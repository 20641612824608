import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import colors from "../../Constants/colors"
import WithModuleSplashPageFunctionality from "../WithModuleSplashPageFunctionality/WithModuleSplashPageFunctionality"

const PYPPartDesktopFooterContent = () => {
  return (
    <WithModuleSplashPageFunctionality>
      {({ clickHandler, buttonText, foundUnlockedModule }) => (
        <div style={styles.componentContainer}>
          <button
            id="pyp-module-btn"
            className="moduleBtn"
            style={{
              ...styles.mobileButton,
              backgroundColor: foundUnlockedModule
                ? colors.fadedRed
                : colors.blueyGrey
            }}
            onClick={clickHandler}
          >
            <p>{buttonText}</p>
          </button>
        </div>
      )}
    </WithModuleSplashPageFunctionality>
  )
}

const styles = {
  componentContainer: {
    width: "100%",
    display: "flex",
    marginTop: "50px",
    position: "sticky",
    bottom: "0px"
  },
  mobileButton: {
    width: "100%",
    border: "none",
    fontFamily: "Lato-Regular",
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "8.5vh",
    color: colors.white
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    parts: state.pyp.parts,
    navigation: state.pyp.navigation
  }
}

export default withRouter(connect(mapStateToProps)(PYPPartDesktopFooterContent))
