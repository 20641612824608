import React from "react"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"

const SkeletonButtons = (props) => {
  const buttonSkeleton = (i) => (
    <div
      key={i}
      style={{
        width: "180px",
        height: "45px",
        margin: "0px",
        justifySelf: "center"
      }}
    >
      <SkeletonWrapper height={45} width={180} theme="light">
        {
          // Passing no children will create a skeleton loader with the full width and height of the Skeleton Wrapper
        }
      </SkeletonWrapper>
    </div>
  )

  const numButtons = props.quantity || 80
  const skeletonButtons = Array(numButtons)
    .fill(1)
    .map((e, i) => buttonSkeleton(i))

  return (
    <div
      style={{
        display: "grid",
        width: "100%",
        justifyContent: "space-evenly",
        gridRowGap: "18px",
        gridColumnGap: "18px",
        gridTemplateColumns: "repeat(auto-fill, minmax(173px, 1fr) )"
      }}
    >
      {skeletonButtons}
    </div>
  )
}

export default SkeletonButtons
