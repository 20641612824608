// eslint-disable-next-line
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Actions from "../../Redux/Actions"
import { gtmClickHandler } from "../../Utils/gtm"

const WithModuleSplashPageFunctionality = ({
  children,
  unlockedUserModules,
  navigation,
  history,
  beginModule,
  match
}) => {
  const foundUnlockedModule = unlockedUserModules.find((unlockedModule) => {
    return unlockedModule.module_id === navigation.currentModuleId
  })

  const clickHandler = () => {
    foundUnlockedModule && beginModule(history)
    gtmClickHandler({
      name: `${getButtonText(foundUnlockedModule)} ClickedEvent`,
      data: {
        action: `${getButtonText(foundUnlockedModule)} Clicked`,
        category: "PYP Engagement",
        label: match.params.module
      }
    })
  }

  const getButtonText = (userModule) => {
    if (!userModule) {
      return "Locked"
    }
    const { started, completed } = userModule
    if (started && completed) {
      return "Edit"
    }
    if (started && !completed) {
      return "Resume"
    }
    return "Begin"
  }

  return (
    <>
      {children({
        buttonText: getButtonText(foundUnlockedModule),
        clickHandler,
        foundUnlockedModule
      })}
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    unlockedUserModules: state.pyp.unlockedUserModules,
    navigation: state.pyp.navigation,
    module: state.pyp.modules
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithModuleSplashPageFunctionality)
)
