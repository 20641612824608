import React from "react"

export const Mail = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="48"
      viewBox="0 0 76 48"
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FADDDE"
          d="M5.564 2h65.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v38.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H5.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756c-.174-.326-.267-.65-.267-1.54V4.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757.326-.174.65-.267 1.54-.267z"
        />
        <path
          fill="#E1373A"
          d="M70 0H6C2.692 0 0 2.692 0 6v36c0 3.308 2.692 6 6 6h64c3.308 0 6-2.692 6-6V6c0-3.308-2.692-6-6-6zm0 4c.12 0 .232.012.348.032L40.22 24.116c-1.164.776-3.276.776-4.44 0L5.652 4.032A2.01 2.01 0 0 1 6 4h64zm0 40H6a2 2 0 0 1-2-2V7.736l29.564 19.708c1.244.832 2.84 1.244 4.436 1.244s3.192-.412 4.436-1.244L72 7.736V42a2 2 0 0 1-2 2z"
        />
      </g>
    </svg>
  )
}
