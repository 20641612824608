import { buildSteps } from "../../Utils/metadataUtils"

export const spouseProfileMetadata = [
  {
    display: true,
    id: "personal-info",
    title: "Personal Info",
    filled: false,
    steps: [
      {
        stepId: "personal",
        name: "Personal Information",
        url: "/profile?title=personal",
        title: "Personal Information",
        body: "Edit your personal information.",
        id: 0,
        inputIds: [0, 1, 2, 3, 4, 5]
      },
      {
        stepId: "location",
        name: "My Address",
        url: "/profile?title=location",
        title: "My Location",
        body:
          "Where do you live?",
        id: 1,
        inputIds: [6, 7, 8, 9, 10]
      },
      {
        stepId: "biography",
        name: "Biography",
        url: "/profile?title=biography",
        title: "Biography",
        body:
          "A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)",
        id: 2,
        inputIds: [11]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "firstName",
      },
      {
        id: 1,
        userField: "lastName",
      },
      {
        id: 2,
        userField: "email",
      },
      {
        id: 3,
        userField: "nickName",
        optional: true
      },
      {
        id: 4,
        userField: "linkedin_url",
        optional: true
      },
      {
        id: 5,
        userField: "phoneNumber"
      },
      {
        id: 6,
        userField: "address.streetAddress"
      },
      {
        id: 7,
        userField: "address.city"
      },
      {
        id: 8,
        userField: "address.state"
      },
      {
        id: 9,
        userField: "address.zipCode"
      },
      {
        id: 10,
        userField: "address.country"
      },
      {
        id: 11,
        userField: "biography.biography"
      }
    ]
  },
  {
    display: true,
    id: "about-you",
    title: "About You",
    filled: false,
    steps: [
      {
        stepId: "about-you",
        name: "About You Information",
        url: "/profile?title=about-you",
        title: "About You",
        body:
          "We use this information to learn more about the family members we serve.",
        id: 0,
        inputIds: [0, 1, 2]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "active_duty_status",
        optional: false
      },
      {
        id: 1,
        userField: "last_duty_station",
        optional: false
      },
      {
        id: 2,
        userField: "how_long_connected",
        optional: false
      }
    ]
  },
  {
    display: true,
    id: "professional",
    title: "Career",
    filled: false,
    steps: [
      {
        stepId: "professional-basic",
        name: "Professional Information",
        url: "/profile?title=professional-basic",
        title: "Professional Information",
        body:
          "Provide some information about your professional life that other veterans can see.",
        id: 0,
        inputIds: [0]
      },
      {
        stepId: "professional-preference",
        name: "Preference",
        url: "/profile?title=professional-preference",
        title: "Preference",
        body: "",
        id: 0,
        inputIds: [2]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "community.current_job",
        optional: true
      },
      {
        id: 1,
        userField: "looking_change",
        optional: false
      },
      {
        id: 2,
        userField: "career.class_preference"
      }
    ]
  }
]

export const spouseProfileSteps = buildSteps(spouseProfileMetadata)
