import moment from "moment"

function nestStringProperties(obj) {
  if (!obj) {
    return {}
  }

  const isPlainObject = (obj) => !!obj && obj.constructor === {}.constructor

  const getNestedObject = (obj) =>
    Object.entries(obj).reduce((result, [prop, val]) => {
      prop.split(".").reduce((nestedResult, prop, propIndex, propArray) => {
        const lastProp = propIndex === propArray.length - 1
        if (lastProp) {
          nestedResult[prop] = isPlainObject(val) ? getNestedObject(val) : val
        } else {
          nestedResult[prop] = nestedResult[prop] || {}
        }
        return nestedResult[prop]
      }, result)
      return result
    }, {})

  return getNestedObject(obj)
}

export const processInputData = (inputData) => {
  const processedData = inputData.reduce((obj, input) => {
    if (input.value !== null) {
      switch (input.inputType) {
        case "number":
          obj[input.userField] = input.value.length
            ? input.value
            : // this is what mysql wants
              null
          break
        case "numberInput":
          obj[input.userField] = input.value
            ? input.value
            : // this is what mysql wants
              null
          break
        case "questionRank":
          obj[input.userField] = input.value
            ? input.value
            : // this is what mysql wants
              null
          break
        case "date":
          obj[input.userField] =
            input.value.length && moment(input.value).isValid()
              ? moment(input.value).format("YYYY/MM/DD")
              : // this is what mysql wants
                null
          break
        default:
          obj[input.userField] = input.value
      }
    }
    return obj
  }, {})

  return nestStringProperties(processedData)
}

const getRecolocationInputValue = (inputData, field) => {
  const value = inputData.find(
    ({ userField }) => userField === `relocations.${field}`
  )?.value

  return value || ""
}

const processPrograms = (page) => (inputData) => {
  if (page !== "relocation") return inputData

  return [
    {
      userField: "relocations",
      value: [
        {
          city: getRecolocationInputValue(inputData, "city1"),
          state: getRecolocationInputValue(inputData, "state1"),
          country: getRecolocationInputValue(inputData, "country1")
        },
        {
          city: getRecolocationInputValue(inputData, "city2"),
          state: getRecolocationInputValue(inputData, "state2"),
          country: getRecolocationInputValue(inputData, "country2")
        },
        {
          city: getRecolocationInputValue(inputData, "city3"),
          state: getRecolocationInputValue(inputData, "state3"),
          country: getRecolocationInputValue(inputData, "country3")
        }
      ]
    }
  ]
}

const runFilledMiddleware = (...funcs) => {
  return (value) => {
    return funcs.reduceRight((acc, func) => {
      return func(acc)
    }, value)
  }
}

export const formatInputData = (inputData, page) =>
  runFilledMiddleware(processPrograms(page))(inputData)

export const processRelocations = () => (inputData) => {
  const [
    relocationOne = {},
    relocationTwo = {},
    relocationThree = {}
  ] = inputData.relocations

  return {
    ...inputData,
    relocations: {
      city1: relocationOne.city,
      state1: relocationOne.state,
      country1: relocationOne.country,
      city2: relocationTwo.city,
      state2: relocationTwo.state,
      country2: relocationTwo.country,
      city3: relocationThree.city,
      state3: relocationThree.state,
      country3: relocationThree.country
    }
  }
}

export const formatIncomingApplication = (inputData) => {
  return runFilledMiddleware(processRelocations())(inputData)
}
