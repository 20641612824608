import React from "react"
import MediaQuery from "react-responsive"
import NavBar from "../NavBar/NavBar"
import UpperMobileNavMenu from "../UpperMobileNavMenu/UpperMobileNavMenu"
import GlobalDesktopNavBar from "../GlobalDesktopNavBar/GlobalDesktopNavBar"
import NetworkDisabledModal from "../NetworkDisabledModal/NetworkDisabledModal"

const ApplicationNavBar = () => {
  return (
    <>
      <MediaQuery maxWidth={768}>
        <UpperMobileNavMenu />
      </MediaQuery>
      <MediaQuery minWidth={769}>
        <NavBar>
          <GlobalDesktopNavBar />
        </NavBar>
      </MediaQuery>
      <NetworkDisabledModal />
    </>
  )
}

export default ApplicationNavBar
