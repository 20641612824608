import React from "react"
import Drawer from "@material-ui/core/Drawer"
import MediaQuery from "react-responsive"

export const WithDrawer = (BaseComponent) => (props) => {
  return (
    <MediaQuery maxWidth={768}>
      <Drawer
        variant="persistent"
        classes={{
          paper: props.classes && props.classes.drawer
        }}
        // workaround for the drawer sliding into frame on window resize
        // I hope his will have a permanent fix on a future release of
        // Material UI
        SlideProps={{ unmountOnExit: true }}
        open={props.drawerOpen}
      >
        <BaseComponent {...props} />
      </Drawer>
    </MediaQuery>
  )
}
