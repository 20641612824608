const types = {
  SET_LOADING: "SET_LOADING",
  SET_ERROR: "SET_ERROR",
  SET_WORKSHOPS: "SET_WORKSHOPS",
  FETCHING_ATTENDEES: "FETCHING_ATTENDEES",
  FETCH_ATTENDEES_ERROR: "FETCH_ATTENDEES_ERROR",
  FETCHED_ATTENDEES: "FETCHED_ATTENDEES",
  FETCHING_ACTIVITIES: "FETCHING_ACTIVITIES",
  FETCHED_ACTIVITIES: "FETCHED_ACTIVITIES",
  FETCH_ACTIVITIES_ERROR: "FETCH_ACTIVITIES_ERROR",
  FETCHING_WORKSHOP_EVENTS: "FETCHING_WORKSHOP_EVENTS",
  FETCHED_WORKSHOP_EVENTS: "FETCHED_WORKSHOP_EVENTS",
  FETCH_WORKSHOP_EVENTS_ERROR: "FETCH_WORKSHOP_EVENTS_ERROR",
  UPDATE_USER_ACTIVITY_STATUSES: "UPDATE_USER_ACTIVITY_STATUSES"
}

export default types
