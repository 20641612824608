import React from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { applicationSteps } from "../../Redux/Reducers/Application/applicationMetadata"
import UserFieldEditorHeader from "../UserFieldEditorHeader/UserFieldEditorHeader"

const ApplicationHeader = (props) => {
  return (
    <UserFieldEditorHeader
      sectionTitle={props.sectionTitle}
      title={props.title}
      currentStepIndex={props.currentStepIndex}
      stepsLength={props.stepsLength}
      description={props.description}
    />
  )
}

const mapStateToProps = (state, props) => {
  const willLocate = state.application.userWillReLocate
  const pageTitle = queryString.parse(props.history.location.search).title

  const { stepId } = applicationSteps.find((elem) => elem.stepId === pageTitle)

  const item = state.application.applicationMetadata.find((application) =>
    application.steps.some((step) => step.stepId === stepId)
  )
  const step = item.steps.find((step) => step.stepId === stepId)

  return {
    title: step.title,
    sectionTitle: item.title,
    stepsLength: item.steps.filter((step) =>
      step.stepId === "relocation" ? Boolean(willLocate) : true
    ).length,
    currentStepIndex: item.steps.findIndex((step) => step.stepId === stepId),
    description: step.body
  }
}

export default withRouter(connect(mapStateToProps)(ApplicationHeader))
