import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
// Icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
// Components
import Accordion from "@material-ui/core/ExpansionPanel"
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary"
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails"
import ActivityItem from "../ActivityItem/ActivityItem"
// Actions
import { getWorkshopActivities } from "../../Redux/Actions/Workshop/functions"
// Styles
import types from "../../Redux/Actions/types"

import "./ActivitiesList.css"
import queryString from "query-string";

const moment = require("moment")

const useStyles = makeStyles({
  expandIcon: {
    width: 40,
    height: 40,
    color: "#2F539D"
  }
})

const useStylesPanelSummary = makeStyles(
  {
    root: {
      padding: 24
    },
    content: {
      margin: 0,
      "&$expanded": {
        margin: 0
      }
    },
    expanded: {}
  },
  { name: "MuiExpansionPanelSummary" }
)

const useStylesPanelDetails = makeStyles(
  {
    root: {
      padding: "0 24px"
    }
  },
  { name: "MuiExpansionPanelDetails" }
)

const ActivitiesList = ({ sessionNumber }) => {
  const [preSessionActivitiesOnly, setPreSessionActivitiesOnly] = useState(
    false
  )
  const [currentEvent, setCurrentEvent] = useState({})
  const [shortMonthName, setShortMonthName] = useState({})
  const [accordionExpanded, setAccordionExpanded] = useState(false)
  const [
    preActivitiesAccordionExpanded,
    setPreActivitiesAccordionExpanded
  ] = useState(false)

  const dispatch = useDispatch()
  const classes = useStyles()
  const classesPanelSummary = useStylesPanelSummary()
  const classesPanelDetails = useStylesPanelDetails()
  const { workshop: workshopId } = queryString.parse(window.location.search)
  const {
    activities,
    workshops: [sf_campaign],
    workshopEvents
  } = useSelector((state) => ({
    activities: state.workshop?.activities,
    workshops: state.workshop?.workshops,
    workshopEvents: state.workshop?.workshopEvents
  }))

  useEffect(() => {
    console.log(workshopId);
  }, []);
  
  const fetchActivities = async () => {
    if (sf_campaign && Object.keys(sf_campaign).length) {
      const workshopActivities = await dispatch(
        getWorkshopActivities(workshopId)
      )

      const sessionActivities = {
        preSessionActivities: [],
        activities: []
      }

      workshopActivities.forEach((activity) => {
        console.log(activity);
        if (activity.session_number === sessionNumber) {

          const isAvailable = !!activity.available_at && (moment.utc(new Date()).isAfter(moment.utc(activity.available_at)))

          if (activity.is_pre_session && isAvailable) {
            sessionActivities.preSessionActivities.push(activity)
          } else {
            sessionActivities.activities.push(activity)
          }
        }
      })

      dispatch({
        type: types.FETCHED_ACTIVITIES,
        data: sessionActivities
      })
    }
  }

  const onAccordionChange = () => {
    setAccordionExpanded(!accordionExpanded)
  }

  const onPreActivitiesAccordionChange = () => {
    setPreActivitiesAccordionExpanded(!preActivitiesAccordionExpanded)
  }

  useEffect(() => {
    fetchActivities()

    if (workshopEvents.length) {
      const [filteredWorkshopEvent] = workshopEvents.filter(
        (workshopEvent) => workshopEvent.session_number === sessionNumber
      )
      const workshopDate = new Date(filteredWorkshopEvent?.date)
      if (!workshopDate) return

      setCurrentEvent(filteredWorkshopEvent)

      if (moment.utc(new Date()).isBefore(moment.utc(workshopDate))) {
        const monthName = new Intl.DateTimeFormat("en-US", {
          month: "short"
        }).format(new Date(filteredWorkshopEvent?.date))
        setShortMonthName(monthName)
        setPreSessionActivitiesOnly(true)
      } else {
        setPreSessionActivitiesOnly(false)
      }

      setAccordionExpanded(false)
      setPreActivitiesAccordionExpanded(false)
    }
  }, [workshopEvents, sessionNumber])

  if (!currentEvent || !Object.keys(currentEvent).length) return null
  const { date } = currentEvent
  const workshopDate = new Date(date)

  /*const weekDayBeforeEventDate = new Date(
    workshopDate.getFullYear(),
    workshopDate.getMonth(),
    workshopDate.getDate() - 7
  )*/
  //if (moment.utc(new Date()).isBefore(moment.utc(weekDayBeforeEventDate))) {
  /*if(moment.utc(new Date()).isBefore(moment.utc(workshopDate)) && !activities.preSessionActivities?.length) {
    return (
      <div className="no-webinar-message">
        Pre-Activities are not available at this time.
      </div>
    )
  }*/

  return (
    <>
      <div className="pre-session activities-container">
        <Accordion
          expanded={preActivitiesAccordionExpanded}
          onChange={onPreActivitiesAccordionChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classesPanelSummary.root}
          >
            <div>
              <h2 className="spouse-course-section-title">
                Pre-Session Activities
              </h2>
              <p className="activities-section-description">
                Please review these activities prior to your meeting with your
                Executive Coach
              </p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classesPanelDetails.root}>
            <div className="activities-list-container">
              <div className="activities-list">
                {!activities.preSessionActivities?.length && (
                  <div className="no-webinar-message">
                    There are no pre-activities available for this session.
                  </div>
                )}
                {activities.preSessionActivities?.map((activity) => (
                  <ActivityItem key={activity.id} activity={activity} />
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      {/* // Only shown once the session is open */}
      <div className="activities-container">
        <Accordion expanded={accordionExpanded} onChange={onAccordionChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classesPanelSummary.root}
          >
            <div>
              <h2 className="spouse-course-section-title">
                Session Activities
              </h2>
              <p className="activities-section-description">
                Please review these activities prior to your meeting with your
                Executive Coach
              </p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="activities-list-container">
              <div className="activities-list">
                {!activities.activities?.length &&
                  !preSessionActivitiesOnly && (
                    <div className="no-webinar-message">
                      There are no activities available for this session
                    </div>
                  )}
                {preSessionActivitiesOnly && (
                  <div className="no-webinar-message">
                    Your session activities will be available here on{" "}
                    {`${shortMonthName} ${new Date(
                      currentEvent?.date
                    ).getUTCDate()}`}
                  </div>
                )}
                {!preSessionActivitiesOnly && activities.activities?.map((activity) => (
                  <ActivityItem key={activity.id} activity={activity} />
                ))}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  )
}

export default ActivitiesList
