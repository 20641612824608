import React from "react"
import colors from "../../Constants/colors"
import { Map } from "../../Icons/Map"

const NoSearchResults = (props) => {
  const text =
    props.copy ||
    "We are always adding more content to the Digital Library. Check back later for your search!"
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        title="No results found"
      >
        <div style={{ ...styles.componentContainer, ...props.style }}>
          <Map />
          <div style={{ marginLeft: "50px" }}>
            <h2 style={styles.header}>No results found</h2>
            <p style={{ ...styles.header, ...styles.text }}>{text}</p>
          </div>
        </div>
      </div>
    </>
  )
}

const styles = {
  header: {
    fontFamily: "Lato-Bold",
    fontSize: "30px",
    color: colors.midnight,
    marginBottom: "20px"
  },
  text: {
    fontSize: "16px",
    fontFamily: "Lato-Light"
  },
  componentContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "500px",
    width: "95%"
  }
}

export default NoSearchResults
