import React, { memo } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { changeFormularyInput } from "../../../../Redux/Actions/Formulary/functions"

/* Legacy button selection group imports */
import ButtonSelection from "../../../ButtonSelection/ButtonSelection"
import {
  industriesList,
  industriesRemoved
} from "../../../../Utils/industriesList"

const MultiSelect = ({ inputId, selectedOptions }) => {
  const dispatch = useDispatch()

  const onSelect = ([{ selectedItems }]) => {
    const values = selectedItems.map((item) => item.value)

    dispatch(changeFormularyInput(inputId, values))
  }

  return (
    <div>
      {/* Using the legacy ButtonSelection while this component is only used for Careers */}
      <ButtonSelection
        limit={5}
        inputData={industriesList
          .map((option, index) => {
            const isSelected = selectedOptions.some(
              (interest) => interest.name === option
            )
            const isRemoved = industriesRemoved.some(
              (industryRemoved) => industryRemoved === option
            )

            if ((isRemoved && isSelected) || !isRemoved) {
              return {
                id: index,
                value: option,
                isSelected
              }
            }

            return null
          })
          .filter(Boolean)}
        changeHandler={onSelect}
        renderComponent={({ inputComponent }) => inputComponent}
      />
    </div>
  )
}

MultiSelect.propTypes = {
  inputId: PropTypes.number.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default memo(MultiSelect, (prevProps, nextProps) => {
  return prevProps.selectedOptions.length === nextProps.selectedOptions.length
})
