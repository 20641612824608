import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { changeFormularyInput } from "../../../../Redux/Actions/Formulary/functions"

import plusIcon from "../../../../Assets/Images/icons/multiselect-plus-icon.png"
import checkIcon from "../../../../Assets/Images/icons/multiselect-check-icon.png"

import "./MultiSelectGroup.css"

const MultiSelectGroup = ({ inputId, options, selectedOptions }) => {
  const dispatch = useDispatch()

  const onSelect = (optionValue) => {
    const isSelected = selectedOptions.includes(optionValue)

    if (isSelected) {
      dispatch(
        changeFormularyInput(
          inputId,
          selectedOptions.filter((option) => option !== optionValue)
        )
      )
      return
    }

    if (selectedOptions.length >= 3) return

    dispatch(changeFormularyInput(inputId, [...selectedOptions, optionValue]))
  }

  return (
    <div className="multi-select">
      {options.map((option) => {
        const isSelected = selectedOptions.includes(option.text)

        return (
          <button
            key={option.id}
            className={`option ${isSelected && "selected"}`}
            onClick={() => onSelect(option.text)}
            type="button"
          >
            <span>{option.text}</span>
            <img src={isSelected ? checkIcon : plusIcon} alt="Plus icon" />
          </button>
        )
      })}
    </div>
  )
}

MultiSelectGroup.propTypes = {
  inputId: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default MultiSelectGroup
