import React from "react"
import colors from "../../Constants/colors"

import ButtonSelection from "../ButtonSelection/ButtonSelection"
import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import "./LayoutButtonSelectionMetaData.css"
import SkeletonButtons from "../SkeletonButtons/SkeletonButtons.js"

import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Actions from "../../Redux/Actions"

class LayoutButtonSelectionMetaData extends React.Component {
  constructor(props) {
    super(props)

    this.changeHandler = this.changeHandler.bind(this)
  }

  componentDidMount() {
    this.props.fetchPartData()
  }

  componentDidUpdate(prevProps) {}

  changeHandler(inputData) {
    this.props.setButtonSelection(inputData[0])
  }

  render() {
    const buttonComponent = this.props.buttonValues.length ? (
      <ButtonSelection
        limit={10}
        // didAddButton and customButtonPlaceHolder are probably deprecated - it's from the customButtonValues
        didAddButton={(name) => {
          this.props.createButton(
            name,
            this.props.currentPart.id,
            this.props.user.user.id
          )
        }}
        customButtonPlaceholder="Add a value"
        changeHandler={this.changeHandler}
        inputData={this.props.buttonValues}
        renderComponent={({ inputComponent }) => (
          <div
            style={{
              width: "100%",
              marginBottom: "100px"
            }}
          >
            {inputComponent}
          </div>
        )}
      />
    ) : (
      <SkeletonButtons />
    )

    return (
      <PYPLayoutContainer
        backgroundColor={colors.background}
        style={{ ...this.props.style }}
      >
        <div
          className="buttonSelectionLayout"
          style={{ ...styles.componentContainer, ...this.props.style }}
        >
          <div>
            <PYPPartTitleContent
              title={this.props.title.title}
              description={this.props.title.description}
            />
          </div>
          {buttonComponent}
        </div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%", // 80%?
    height: "100%", // 80%?
    backgroundColor: colors.background,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  try {
    const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
    const partDataId = currentPart.partData

    let buttonValues = []
    let metadata = { title: null, description: null }

    const partData =
      state.pyp.partData.byId[partDataId] &&
      state.pyp.partData.byId[partDataId].data
    if (partData) {
      metadata = partData.title[0]
      buttonValues = partData.buttonValues.map((buttonValue) => {
        return {
          value: buttonValue.name,
          id: buttonValue.id,
          isCustom: buttonValue.isCustom,
          isSelected: buttonValue.buttonSelections.length
        }
      })
      // alphabetize only the non-custom buttons
      // const customButtons = buttonValues.filter(value => value.isCustom).reverse()
      const defaultButtons = buttonValues
        .filter((value) => !value.isCustom)
        .sort((a, b) =>
          a.value.toUpperCase() > b.value.toUpperCase()
            ? 1
            : b.value.toUpperCase() > a.value.toUpperCase()
            ? -1
            : 0
        )

      buttonValues = [...defaultButtons]
    }

    return {
      ...props,
      pyp: state.pyp,
      currentPart,
      partDataId,
      buttonValues,
      title: metadata,
      user: state.user
    }
  } catch (e) {
    console.log(e)
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutButtonSelectionMetaData)
)
