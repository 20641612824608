import React, { Component } from "react"
import Croppie from "croppie"
import Loader from "react-loader-spinner"
import "./croppie.css"
import "./imageCropper.css"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors"

let c
const reader = new FileReader()

export default class ImageCropper extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reading: false
    }
    this.handleUpload = this.handleUpload.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    if (!c) {
      c = new Croppie(document.getElementById("main-cropper"), {
        viewport: {
          width: this.props.width,
          height: this.props.height,
          type: "circle"
        },
        showZoomer: true,
        enableExif: true,
        enableOrientation: true
      })
      if (c && this.props.image && !this.state.reading) {
        this.setState({ reading: true })
        reader.readAsDataURL(this.props.image)
      }
    }
    reader.addEventListener(
      "load",
      function (e) {
        c.bind({
          url: e.target.result
        })
      },
      false
    )
  }

  componentWillUnmount() {
    c = null
    this.setState({ reading: false })
    reader.removeEventListener(
      "load",
      function (e) {
        c.bind({
          url: e.target.result
        })
      },
      false
    )
  }

  componentDidUpdate() {
    if (!c) {
      c = new Croppie(document.getElementById("main-cropper"), {
        viewport: { width: 250, height: 254, type: "circle" },
        showZoomer: true,
        exif: false,
        enableOrientation: true
      })
    }
    if (c && this.props.image && !this.state.reading) {
      this.setState({ reading: true })
      reader.readAsDataURL(this.props.image)
    }
  }

  handleUpload = async () => {
    const file = await c.result({
      type: "blob",
      size: { width: "250", height: "254" },
      format: "png",
      circle: false
    })
    const imageData = new FormData()
    await imageData.append("file", file)

    await this.props.handleUpload(imageData)
    this.props.handleClose()
  }

  handleClose = () => {
    this.props.handleClose()
  }

  render() {
    const { isUpdatingThumbnail } = this.props
    return (
      <div className="cropperContainer">
        <div className="cropperImageContainer" id="main-cropper" />
        <div className="cropperButtonContainer">
          <CommitButtonWithClickState
            type="white"
            style={{ borderRadius: "3px" }}
            clickHandler={this.handleClose}
            id="discardUserThumbnail"
            disabled={isUpdatingThumbnail}
          >
            Discard
          </CommitButtonWithClickState>
          <CommitButtonWithClickState
            type="red"
            clickHandler={this.handleUpload}
            id="uploadUserThumbnail"
            disabled={isUpdatingThumbnail}
          >
            {isUpdatingThumbnail ? (
              <Loader
                type="ThreeDots"
                color={colors.fadedRed}
                height={24}
                width={24}
              />
            ) : (
              "Save changes"
            )}
          </CommitButtonWithClickState>
        </div>
      </div>
    )
  }
}
