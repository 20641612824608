import React from "react"

import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent"
import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPCarousel from "../PYPCarousel/PYPCarousel.js"
import PYPExampleInputBox from "../PYPExampleInputBox/PYPExampleInputBox.js"
import SkeletonCard from "../SkeletonCard/SkeletonCard.js"

import Actions from "../../Redux/Actions"

class LayoutMetaDataUserInputExample extends React.Component {
  componentDidMount() {
    // Use the referenced part ID to grab the correct data from the part - you'll probably have to actually fetch the part first
    this.props.fetchPartData()
  }

  renderCarousel() {
    let cards
    let loading
    if (this.props.userInputExamples.length) {
      cards = this.props.userInputExamples.map((input) => (
        <PYPExampleInputBox
          title={input.title.title}
          size="medium"
          text={input.text}
          img_url={input.img_url}
          style={{ height: "100%" }}
        />
      ))
      loading = false
    } else {
      const skeleton = (
        <SkeletonCard
          size="medium"
          style={{ height: "100%" }}
          textAlign="center"
        />
      )
      cards = Array(3)
        .fill(1)
        .map(() => skeleton)
      loading = true
    }
    return (
      <PYPCarousel
        cards={cards}
        loading={loading}
        cardHeight={320}
        cardWidth={527}
      />
    )
  }

  render() {
    const carousel = this.renderCarousel()
    return (
      <PYPLayoutContainer>
        <PYPPartTitleContent
          style={styles.metadata}
          title={this.props.metadata.title}
          description={this.props.metadata.description}
        />
        <div style={styles.inputBoxContainer}>{carousel}</div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap"
  },
  carouselContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  metadata: {
    textAlign: "center"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData
  let metadata = { title: null, description: null }
  let userInputExamples = []

  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.partTitle[0]
    userInputExamples = partData.userInputExamples
  }

  return {
    userInputExamples,
    metadata
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutMetaDataUserInputExample)
)
