import types from "../../Actions/types"

const pypReducer = (
  state = {
    initialized: false,
    initializedPart: false,
    fetchingModules: false,
    fetchingParts: false,
    fetchingElements: false,
    fetchingPartData: false,
    partIsLoading: false,
    savingPYP: false,
    incrementingPYP: false,
    currentPartComplete: true,
    courses: {},
    modules: {},
    parts: {},
    partData: {},
    elements: {},
    navigation: {
      moduleNavigationMetaData: null,
      currentCourseId: -1,
      currentModuleId: -1,
      currentPartId: -1,
      showIncompletionError: false
    },
    unlockedUserModules: null
  },
  action
) => {
  switch (action.type) {
    case types.PART_IS_LOADING:
      return {
        ...state,
        partIsLoading: action.data.partIsLoading
      }
    case types.INCREMENTING_PYP:
      return {
        ...state,
        incrementingPYP: true
      }
    case types.SAVING_PYP:
      return {
        ...state,
        savingPYP: true
      }
    case types.SAVED_PYP:
      return {
        ...state,
        savingPYP: false
      }
    case types.EXIT_MODULE:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentPartId: -1
        },
        initializedPart: false
      }
    case types.DECREMENT_PART:
      const moduleNavigationObj = {}
      moduleNavigationObj[action.data.moduleId] = {
        id: action.data.moduleId,
        lastVisitedPart: action.data.partId
      }

      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentPartId: action.data.partId || -1,
          currentModuleId: action.data.moduleId,
          moduleNavigationMetaData: Object.assign(
            state.navigation.moduleNavigationMetaData,
            moduleNavigationObj
          )
        },
        parts: {
          allIds: state.parts.allIds,
          byId: Object.assign(state.parts.byId, action.data.updatedPart)
        },
        unlockedUserModules: action.data.unlockedUserModules
          ? action.data.unlockedUserModules
          : state.unlockedUserModules,
        initializedPart: !!action.data.partId
      }

    case types.INCREMENT_PART:
      const moduleNavigation = {}
      moduleNavigation[action.data.moduleId] = {
        id: action.data.moduleId,
        lastVisitedPart: action.data.partId
      }

      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentPartId: action.data.partId || -1,
          currentModuleId: action.data.moduleId,
          moduleNavigationMetaData: Object.assign(
            state.navigation.moduleNavigationMetaData,
            moduleNavigation
          )
        },
        unlockedUserModules: action.data.unlockedUserModules,
        initializedPart: !!action.data.partId,
        currentPartComplete: false,
        incrementingPYP: false
      }

    case types.FETCHED_PART_DATA:
      return {
        ...state,
        partData: action.data.partData,
        parts: {
          allIds: state.parts.allIds,
          byId: Object.assign(state.parts.byId, action.data.updatedPart)
        },
        currentPartComplete: action.data.currentPartComplete,
        fetchingPartData: false
      }

    case types.FETCHING_PART_DATA:
      return {
        ...state,
        fetchingPartData: true
      }

    case types.INITIALIZED_PART:
      return {
        ...state,
        parts: action.data.parts,
        elements: action.data.elements,
        partData: action.data.partData,
        initializedPart: true,
        navigation: {
          ...state.navigation,
          currentPartId: action.data.navigation.currentPartId
        },
        currentPartComplete: action.data.currentPartComplete
      }
    case types.FETCHED_PART_INPUTS:
      return {
        ...state,
        partData: action.data.partData,
        currentPartComplete: action.data.currentPartComplete,
        parts: {
          allIds: state.parts.allIds,
          byId: Object.assign(state.parts.byId, action.data.updatedPart)
        }
      }
    case types.INITIALIZING_PYP:
      return {
        ...state,
        initialized: false
      }

    case types.INITIALIZING_PYP_INPUTS:
      return {
        ...state,
        initializingPYPInputs: true
      }

    case types.CREATE_PYP_INPUTS:
      return {
        ...state,
        initializingPYPInputs: false,
        currentPartComplete: action.data.currentPartComplete,
        // partData: {
        //   ...state.partData,
        //   byId: {
        //     ...state.partData.byId,
        //     [action.data.partDataId]: {
        //       ...state.partData.byId[action.data.partDataId],
        //       data: {
        //         ...state.partData.byId[action.data.partDataId].data,
        //         inputs: newInputArray
        //       }
        //     }
        //   }
        // }
        partData: action.data.partData
      }

    case types.SET_PYP_REFERENCE_INPUTS:
      return {
        ...state,
        currentPartComplete: action.data.currentPartComplete,
        partData: action.data.partData
      }

    case types.SET_PYP_BUTTON_SELECTION:
      const currentPartDataId =
        state.parts.byId[state.navigation.currentPartId].partData

      return {
        ...state,
        currentPartComplete: action.data.currentPartComplete,
        partData: {
          ...state.partData,
          byId: {
            ...state.partData.byId,
            [currentPartDataId]: {
              ...state.partData.byId[currentPartDataId],
              data: {
                ...state.partData.byId[currentPartDataId].data,
                valuesToUpdate: action.data.buttonSelections
              }
            }
          }
        }
      }

    case types.INSERT_CUSTOM_BUTTON:
      const partDataId =
        state.parts.byId[state.navigation.currentPartId].partData

      const currentButtonValues =
        state.partData.byId[partDataId].data.buttonValues
      return {
        ...state,
        partData: {
          ...state.partData,
          byId: {
            ...state.partData.byId,
            [partDataId]: {
              ...state.partData.byId[partDataId],
              data: {
                ...state.partData.byId[partDataId].data,
                buttonValues: [action.data.newButton, ...currentButtonValues]
              }
            }
          }
        }
      }

    case types.SET_PYP_INPUT_DATA:
      return {
        ...state,
        currentPartComplete: action.data.currentPartComplete,
        partData: action.data.partData
      }

    case types.UPDATE_PART_INCOMPLETION_ERROR:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          showIncompletionError: action.data.showIncompletionError
        }
      }

    case types.FETCHING_PARTS:
      return {
        ...state,
        fetchingParts: true
      }

    case types.NAVIGATE_TO_MODULE:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          currentModuleId: action.data.moduleId,
          currentPartId: -1
        },
        initializedPart: false
      }

    case types.BEGIN_MODULE:
      return {
        ...state,
        unlockedUserModules: action.data.unlockedUserModules
      }

    case types.INITIALIZED_PYP:
      return {
        ...state,
        modules: action.data.modules,
        courses: action.data.course,
        navigation: {
          ...state.navigation,
          currentModuleId: action.data.currentModuleId,
          moduleNavigationMetaData: action.data.moduleNavigationMetaData
        },
        unlockedUserModules: action.data.unlockedUserModules,
        initialized: true,
        fetchingModules: false
      }
    case types.FETCHING_MODULES:
      return {
        ...state,
        fetchingModules: true
      }
    case types.FETCHED_MODULES:
      return {
        ...state,
        modules: action.data
      }
    case types.LOGOUT:
      return {
        ...state,
        initialized: false,
        fetchingModules: false,
        fetchingSteps: false,
        fetchingElements: false,
        courses: {},
        modules: {},
        parts: {},
        elements: {},
        navigation: {
          currentCourseId: -1,
          currentModuleId: -1,
          currentPartId: -1
        },
        unlockedUserModules: null
      }
    default:
      return {
        ...state
      }
  }
}

export default pypReducer
