import React from "react"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { withStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import colors from "../../Constants/colors"

const styleSheet = {
  root: {
    fontFamily: "Lato-Regular",
    color: colors.midnight,
    width: "100%"
  },
  formControl: {
    minWidth: 120
  },
  rootTwo: {
    display: "flex",
    flexWrap: "wrap"
  }
}
export default withStyles(styleSheet)(function StatelessFormDropdown({
  onChange,
  value,
  title,
  style,
  classes,
  options,
  helperText,
  innerStyle
}) {
  return (
    <form className={classes.rootTwo} style={{ ...style }}>
      <FormControl
        classes={{
          root: classes.root
        }}
        style={innerStyle}
      >
        <InputLabel
          classes={{
            root: `${classes.labelClass} ${classes.root}`
          }}
          htmlFor={`title_${title}`}
        >
          {title}
        </InputLabel>
        <Select
          value={value}
          onChange={(e) => onChange(e)}
          classes={{
            root: classes.root
          }}
          inputProps={{
            id: `title_${title}`
          }}
          style={style}
        >
          {options.map((option, i) => (
            <MenuItem
              key={i}
              value={option.value}
              style={{
                padding: ".25em",
                fontSize: "14px",
                cursor: "pointer",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start"
              }}
              className="dropdownItem"
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </form>
  )
})
