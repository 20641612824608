import React from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors.js"

const GenerateThumbnailButton = ({ clickHandler }) => {
  return (
    <InputAdornment position="end">
      <CommitButtonWithClickState
        style={styles.generateThumbnailButton}
        clickHandler={clickHandler}
      >
        Change Thumbnail
      </CommitButtonWithClickState>
    </InputAdornment>
  )
}

const styles = {
  generateThumbnailButton: {
    color: colors.fadedRed,
    backgroundColor: colors.white,
    width: "124px"
  }
}

export default GenerateThumbnailButton
