import React from "react"
import { useSelector } from "react-redux"
import CommitBarGraph from "../CommitBarGraph/CommitBarGraph"
import colors from "../../Constants/colors"

const styles = {
  analyticCardContainer: {
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 20px 0px",
    backgroundColor: colors.white,
    width: "800px",
    maxWidth: "calc(100vw - 50px)",
    height: "500px",
    margin: "auto",
    marginTop: "0px",
    marginBottom: "10px",
    padding: "40px 20px 20px 20px"
  },
  title: {
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 20px 0px",
    backgroundColor: colors.white,
    width: "800px",
    margin: "auto",
    marginBottom: "0px",
    fontFamily: "Lato-Regular",
    fontWeight: "1.6"
  }
}

export default function AnalyticsBarGraphContainer(params) {
  const { analytics } = useSelector((state) => state)

  const graphData = [
    {
      Total:
        (analytics.analyticsData.createdUsers &&
          analytics.analyticsData.createdUsers[0]["count(*)"]) ||
        0,
      title: "Total Users",
      titleSeqeuence: ["Total"]
    },
    {
      Total:
        (analytics.analyticsData.createdUsers &&
          analytics.analyticsData.applicationsSubmitted[0]["count(*)"]) ||
        0,
      title: "Total Applications",
      titleSeqeuence: ["Total"]
    },
    {
      Total: analytics.analyticsData.hasDL || 0,
      title: "Users With DL",
      titleSeqeuence: ["Total"]
    },
    {
      Total: analytics.analyticsData.hasPYP || 0,
      title: "Users with PYP",
      titleSeqeuence: ["Total"]
    }
  ]

  let longestValue = 5

  graphData.forEach((item) => {
    if (longestValue < item.Total) {
      longestValue = item.total
    }
  })

  return (
    <>
      <h3 style={styles.title}> All Users </h3>
      <div style={styles.analyticCardContainer}>
        <CommitBarGraph
          bottomMarginModifier={30}
          data={graphData}
          keys={["Total"]} // eventTitles}
          percentage={false}
          inColors={{
            Total: "#00D061",
            Sleep: "#7551E9",
            default: "#D8F0F8",
            "Non-Value": "#D8F0F8"
          }}
          fontColor={colors.midnight}
          vertical
          tickStep={0.5}
          yUnits="Users"
          xUnits="Product"
          longestValue={longestValue}
          leftAxisLegendTitle="Users"
          axisBottom={{
            legend: "Product", // xUnits,
            legendPosition: "middle",
            legendOffset: 60
          }}
          fontSize={20}
          withLabel
        />
      </div>
    </>
  )
}
