import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Actions from "../../Redux/Actions"
import VideoLibraryHeader from "../VideoLibraryHeader/VideoLibraryHeader"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"
import NoSearchResults from "../NoSearchResults/NoSearchResults"
import DigitalLibraryInfiniteScroll from "../DigitalLibraryInfiniteScroll/DigitalLibraryInfiniteScroll"

export class SearchResults extends React.Component {
  constructor() {
    super()
    this.state = {
      searchOccurred: false,
      scrollReady: false
    }
    this.finishLoading = this.finishLoading.bind(this)
  }

  componentDidMount() {
    this.props.searchVideosBySearchTerm({
      searchTerm: this.props.match.params.searchTerm
    })

    document.title = `The Digital Library | Search | ${this.props.match.params.searchTerm}`
    window.dataLayer.push({
      page_view: {
        page_path: `${
          this.props.location.pathname + this.props.location.search
        }`,
        page_title: document.title
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.setState({
        searchOccurred: false
      })
      this.props.searchVideosBySearchTerm({
        searchTerm: this.props.match.params.searchTerm
      })

      document.title = `The Digital Library | Search | ${this.props.match.params.searchTerm}`
      window.dataLayer.push({
        page_view: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
    }

    // Have to differentiate between an empty array of videos due to
    // not having retrieved them yet, and having a null search result
    if (prevProps.fetchingVideos && !this.props.fetchingVideos) {
      this.setState({
        searchOccurred: true
      })
    }
  }

  finishLoading() {
    this.setState({
      scrollReady: true
    })
  }

  render() {
    return (
      <>
        <VideoLibraryHeader for="search" />
        {!this.props.videos.length &&
        this.state.searchOccurred &&
        !this.props.fetchingVideos ? (
          <NoSearchResults />
        ) : (
          <DigitalLibraryInfiniteScroll
            loadMore={this.props.fetchMoreSearchResults}
            hasMore={this.props.page < this.props.lastPage}
            scrollReady={this.state.scrollReady}
          >
            <VideoThumbnailList
              style={{
                listStyleType: "none",
                padding: "calc(3.5% + 5px)",
                justifyContent: "flex-start",
                flexWrap: "wrap",
                width: "100%",
                display: "flex"
              }}
              from="searchResults"
              videos={this.props.videos}
              finishLoading={this.finishLoading}
            />
          </DigitalLibraryInfiniteScroll>
        )}
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  const { searchVideosBySearchTerm, fetchMoreSearchResults } = Actions

  return bindActionCreators(
    {
      searchVideosBySearchTerm,
      fetchMoreSearchResults
    },
    dispatch
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    videos: state.digitalLibrary.videos.allIds.map(
      (videoId) => state.digitalLibrary.videos.byId[videoId]
    ),
    fetchingVideos: state.digitalLibrary.fetchingVideos,
    page: state.digitalLibrary.videos.page,
    lastPage: state.digitalLibrary.videos.lastPage,
    historyLength: props.history.length
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResults)
)
