import React from "react"
// import PropTypes from 'prop-types'
import { RedX } from "../../Icons/RedX"

const WithCheck = (props) => {
  return (
    <>
      {props.renderComponent({
        ...props
      })}
      {props.renderCheckMark && <RedX style={{ ...props.style }} />}
    </>
  )
}

export default WithCheck
