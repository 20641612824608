import React from "react";
import { connect } from "react-redux";
import InputGroup from "../InputGroup/InputGroup";

const AlliedForcesBiographyOne = (props) => {
  const application = window.location.href.includes('allied-forces') ? props.alliedForcesApplication : props.application;
  const data = [
    {
      id: 'biography',
      inputType: "longText",
      defaultValue: `A good biography should be approximately 150 words in length and may include your career history, key accomplishments, future career interests, and any personal details you'd like to highlight. Your bio will be shared with relevant stakeholders. Please ensure it's well-written, concise, and professional. (minimum 300 characters and maximum 1000 characters)`,
      placeholder: `Write your biography`,
      charLimit: 1000,
      userField: "biography.biography",
      title: `Biography`,
      required: true,
    }
  ];

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    const a = s.split(".");
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i];
      if (o && k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  const inputDataWithValues = data.map((input) => {
    return {
      ...input,
      value: Object.byString(application, input.userField) || ""
    };
  });

  return (
    <InputGroup
      {...props}
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              inputComponent,
              checkSubmittability
            })}
          </>
        );
      }}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application.application,
    alliedForcesApplication: state.alliedForcesApplication.application
  };
};

export default connect(mapStateToProps)(AlliedForcesBiographyOne);
