import React, { useState } from "react"
import { useSelector } from "react-redux"

import { useWindowResize } from "../../Hooks/useWindowResize"
import "../MembersCard/MembersCard.css"

const styles = {
	profileImage: {
    borderRadius: "50px",
    width: "48px",
    height: "48px",
    objectFit: "cover"
  },
  MembersCard: {
    width: "50px",
    height: "50px",
    margin: "8px"
  },
  userInitials: {
    borderRadius: "90px",
    width: "48px",
    height: "48px",
    objectFit: "cover",
    background: "linear-gradient(180deg, #BCBCBC 0%, #A1A9B8 100%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
	memberCardActive: {
		position: "absolute",
    width: "100%",
    paddingRight: "32px",
		height: "90%"
	}
}


const CohortMembersCard = ({
  user,
	expandCard
}) => {
  const { width } = useWindowResize()
  const loggedUser = useSelector((state) => state.user.user)
  const isSelf = loggedUser.id === user.id
  const userName = user?.fullName.split(" ")
  const userInitials = `${userName[0].charAt(0)}${userName[1].charAt(0)}`

  

	// if (isSelf) {
	// 	return <></>
	// }

  return (
    <div className="member-card" style={styles.MembersCard}>
				<div onClick={expandCard}>
					{
						user.profile_image_url ?
						<img
							src={user.profile_image_url}
							style={styles.profileImage}
							alt="user profile"
						/> :
						<div
							style={styles.userInitials}
						>
							{userInitials}
						</div>
					}
				</div>
    </div>
  )
}


export default CohortMembersCard
