/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getWorkshopEvents } from "../../Redux/Actions/Workshop/functions"
import SpouseEvent from "../SpouseEvent/SpouseEvent"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

import "../BiographyPYP/BiographyPYP.css"

const moment = require("moment")

const styles = {
  commitButton: {
    width: "153px",
    fontFamily: "Lato-Regular",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    borderRadius: 5,
    color: "#ffffff"
  }
}

const BiographySpouseCourse = ({ history, location }) => {
  const dispatch = useDispatch()
  const [noEventsError, setNoEventsError] = useState(false)
  const [workshopInfo, setWorkShopInfo] = useState(null)

  const { workshops, workshopEvents } = useSelector((state) => ({
    workshops: state.workshop?.workshops || [],
    workshopEvents: state.workshop?.workshopEvents
  }))

  useEffect(() => {
    const filterEvents = async (workshopId) => {
      const events = await dispatch(getWorkshopEvents(workshopId))

      const filteredEventsDate = events.filter((event) =>
        moment(event.date).isAfter(moment(new Date()))
      )

      return filteredEventsDate
    }

    if (workshops.length) {
      filterEvents(workshops[0].id).then((filteredEvents) => {
        if (!filteredEvents.length) {
          setNoEventsError(true)
        } else {
          setNoEventsError(false)
        }
      })
      setWorkShopInfo(workshops[0])
    } else {
      setNoEventsError(true)
    }
  }, [])

  const redirectToSpouseDashboard = () => {
    history.push(`/virtualcohort`)
  }

  return (
    <div id="biographyPYPContainer" className="biographyPYPContainer">
      <div className="spouse-course-info" style={{ flex: "1 1 85%" }}>
        <div className="spouse-course-info-title PYP-title">
          {workshopInfo && workshopInfo.title
            ? workshopInfo.title
            : "No program title was provided."}
        </div>
        <div className="spouse-course-info-description">
          {workshopInfo && workshopInfo.description
            ? workshopInfo.description
            : "No program description was provided."}
        </div>
        <div className="spouse-course-info-button">
          <CommitButtonWithClickState
            type="red"
            clickHandler={() => redirectToSpouseDashboard()}
            style={styles.commitButton}
          >
            Go To Program
          </CommitButtonWithClickState>
        </div>
      </div>
      <div
        className="card-action-container"
        style={{ flex: "1 1 45%", paddingRight: 23 }}
      >
        <h1 className="coach-title">{!noEventsError && "Next Event"}</h1>
        {workshopEvents.length ? (
          <SpouseEvent dashboardView workshopEvents={workshopEvents} />
        ) : null}
      </div>
    </div>
  )
}

export default withRouter(BiographySpouseCourse)
