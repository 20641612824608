import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import ApiTimeoutModal from "../ApiTimeoutModal/ApiTimeoutModal"

class AccountContent extends React.Component {
  componentDidMount() {
    document.getElementById("Content-Container").scrollTop = 0
  }

  render() {
    return (
      <div
        style={{
          ...styles.componentContainer,
          ...this.props.style,
          overflow: "hidden"
        }}
      >
        <ApiTimeoutModal />
        {this.props.children}
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    marginTop: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 60px)"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    user: state.user.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountContent)
