import React from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"
import App from "./App"
// this is for IE11 to work
import "@babel/polyfill"

import "video-react/dist/video-react.css"

// display error message for IE11
if (
  navigator.userAgent.indexOf("MSIE") !== -1 ||
  navigator.appVersion.indexOf("Trident/") > -1
) {
  alert(
    `We're detecting you're using a browser that this application is not optimized for. We recommend you use this application in the most updated version of: Microsoft Edge, Apple Safari, Firefox, or Google Chrome.`
  )
}

// This logic effectively determines if app is being used on a touch device or not
// The ' hasHover' class is added to the body element of the
// app whenever the mouse moves (meaning user is not on a touch device)
function watchForHover() {
  let hasHoverClass = false
  const container = document.body
  let lastTouchTime = 0

  function enableHover() {
    // filter emulated events coming from touch events
    if (new Date() - lastTouchTime < 500) return
    if (hasHoverClass) return

    container.className += " hasHover"
    hasHoverClass = true
  }

  function disableHover() {
    if (!hasHoverClass) return

    container.className = container.className.replace(" hasHover", "")
    hasHoverClass = false
  }

  function updateLastTouchTime() {
    lastTouchTime = new Date()
  }

  document.addEventListener("touchstart", updateLastTouchTime, true)
  document.addEventListener("touchstart", disableHover, true)
  document.addEventListener("mousemove", enableHover, true)

  enableHover()
}

watchForHover()

// polyfill for IE11
if (typeof Object.assign !== "function") {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target, varArgs) {
      // .length of function is 2
      // "use strict" arbitrary
      if (target === null || target === undefined) {
        throw new TypeError("Cannot convert undefined or null to object")
      }

      const to = Object(target)

      for (let index = 1; index < arguments.length; index++) {
        const nextSource = arguments[index]

        if (nextSource !== null && nextSource !== undefined) {
          for (const nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey]
            }
          }
        }
      }
      return to
    },
    writable: true,
    configurable: true
  })
}

ReactDOM.render(<App />, document.getElementById("root"))
