import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import colors from "../../Constants/colors"
import lockIcon from "../../Assets/Images/icons/lock.png"
import Actions from "../../Redux/Actions"
import ApplicationConfirmationModal from "../ApplicationFooter/ApplicationConfirmationModal"


const ApplicationSubmitButton = (props) => {
  const finalizeAndSubmitApplication = () => {
    props.finalizeAndSubmitNonDodIcApplication(
      props.history,
      props.currentInputData
    )
    // props.closeAllDrawers()
  }

  const onPressSubmit = async () => {
    if (props.applicationIsComplete) {
      if (props.userHasSubmittedApplication) {
        // this await might violate some redux pattern, but it's not pretty
        // to do it differently (connected router? barf!) conditionally pass in an arg save Application? barf!
        // TODO: the right way is probably to create a new function, saveApplicationAndGoHome()
        await props.saveApplication(null, props.history)
        props.history.push("/home")
      } else {
        props.toggleModal({
          modal_mode: true,
          modal_usi: "applicationConfirmation"
        })
      }
    } else {
      props.toggleUnfilled()
    }
    props.onPressSubmit && props.onPressSubmit()
  }

  // Render a different button depedning on value of props.submitState
  // Options are 'unfinished', 'finished', or 'submitted'
  switch (props.submitState) {
    case "unfinished":
      return (
        <div style={styles.buttonContainerGrey} onClick={onPressSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <p style={styles.buttonText}>Submit</p>
            <img
              alt="commitImg"
              style={{ height: "19px", width: "14px", marginLeft: "8px" }}
              src={lockIcon}
            />
          </div>
        </div>
      )
    case "editing and unfinished":
      return (
        <div style={styles.buttonContainerGrey} onClick={onPressSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <p style={styles.buttonText}>Finish Editing</p>
            <img
              alt="commitImg"
              style={{ height: "19px", width: "14px", marginLeft: "8px" }}
              src={lockIcon}
            />
          </div>
        </div>
      )
    case "finished":
      return (
        <>
        <div style={styles.buttonContainer} onClick={onPressSubmit}>
          <p style={styles.buttonText}>Submit</p>
        </div>
        <ApplicationConfirmationModal submitHandler={finalizeAndSubmitApplication}/>
        </>
      )
    case "submitted":
      return (
        <div style={styles.buttonContainer} onClick={onPressSubmit}>
          <p style={styles.buttonText}>Finish Editing</p>
        </div>
      )
    default:
      return (
        <div style={styles.buttonContainerGrey} onClick={onPressSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <p style={styles.buttonText}>Finish Editing</p>
            <img
              alt="commitImg"
              style={{ height: "19px", width: "14px", marginLeft: "8px" }}
              src={lockIcon}
            />
          </div>
        </div>
      )
  }
}

const styles = {
  buttonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "55px",
    backgroundColor: colors.fadedRed,
    cursor: "pointer",
    height: "100%"
  },
  buttonContainerGrey: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "55px",
    backgroundColor: colors.blueyGrey,
    height: "100%"
  },
  buttonText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    color: colors.white
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const userHasSubmittedApplication =
    state.user.user.id && state.user.user.has_submitted_application

  const getProfileState = () => {
    return state.application.profileIsComplete
      ? "submitted"
      : "editing and unfinished"
  }

  const getApplicationState = () => {
    return state.application.applicationIsComplete || state.spouseApplication.applicationIsComplete ? "finished" : "unfinished"
  }

  const getSubmitState = () => {
    return userHasSubmittedApplication
      ? getProfileState()
      : getApplicationState()
  }

  return {
    ...props,
    userHasSubmittedApplication,
    submitState: props.submitState || getSubmitState()
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationSubmitButton)
)
