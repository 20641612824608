import React from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import PYPInputBox from "../PYPInputBox/PYPInputBox.js"
import SkeletonCard from "../SkeletonCard/SkeletonCard.js"

import Actions from "../../Redux/Actions"

class LayoutMetaDataReferenceUserInputUserInput extends React.Component {
  constructor(props) {
    super()

    this.state = {
      finishedFetching: false
    }
  }

  componentDidMount() {
    this.props.fetchPartData().then(() => this.checkForUninitializedInputs())
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPart.id !== this.props.currentPart.id) {
      this.props.fetchPartData().then(() => this.checkForUninitializedInputs())
    }
  }

  checkForUninitializedInputs() {
    this.props
      .updatePYPInputsFromButton(
        this.props.partDataId,
        this.props.currentPart.id,
        this.props.selectedButtonsValues,
        this.props.inputBoxes
      )
      .then(() => {
        this.setState({
          finishedFetching: true
        })
      })
  }

  renderInputBoxes() {
    if (!this.state.finishedFetching || !this.props.inputBoxes.length) {
      const skeleton = (i) => (
        <SkeletonCard
          key={i}
          size={this.props.inputSize}
          style={{ margin: "30px 30px 0px 30px" }}
        />
      )
      if (this.props.numInputs) {
        return Array(this.props.numInputs)
          .fill(1)
          .map((e, i) => skeleton(i))
      }
      return skeleton
    }
    return Object.values(this.props.inputBoxes).map((box, key) => {
      // Look up the value of the title from within props.inputBoxes
      let title
      if (box.buttonSelectionTitle) {
        title =
          (box.buttonSelectionTitle.buttonValue &&
            box.buttonSelectionTitle.buttonValue.name) ||
          (box.buttonSelectionTitle.customButtonValue &&
            box.buttonSelectionTitle.customButtonValue.name)
      } else {
        title = ""
      }
      return (
        <PYPInputBox
          key={key}
          text={box.text}
          placeholder=""
          style={{ margin: "30px 30px 0px 30px" }}
          title={title}
          size="small"
          valueDidChange={(value) =>
            this.props.setPYPInputText(this.props.partDataId, box.id, value)
          }
          showIncomplete={this.props.pyp.navigation.showIncompletionError}
        />
      )
    })
  }

  render() {
    const inputBoxes = this.renderInputBoxes()
    return (
      <PYPLayoutContainer
        backgroundColor={colors.background}
        style={{ ...this.props.style }}
      >
        <PYPPartTitleContent
          title={this.props.metadata.title}
          description={this.props.metadata.description}
        />
        <div style={styles.inputBoxContainer}>{inputBoxes}</div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%", // 80%?
    padding: "46px 100px",
    minHeight: "100%", // 80%?
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    marginBottom: "30px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let inputBoxes = []
  // let inputTitles = []
  let buttonValues = []
  let stage2Buttons = []

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.title[0]
    inputBoxes = partData.inputs
    // inputTitles = partData.inputTitle
    buttonValues = partData.buttonValues

    const selectedButtons = buttonValues.filter((btn) => {
      return btn.buttonSelections && btn.buttonSelections.length
    })

    // Filter down to buttons that are in second stage
    stage2Buttons = selectedButtons.filter((btn) => {
      return btn.buttonSelections[0].pivot.stage === 2
    })

    // TODO: Change the way buttons are pulled from past parts. Use a pivot table to related button selections to parts?
    // This is a temporary hack for the Interest Industries Input part - it's the
    // / only part that references buttons that DON'T have a stage 2, so in this case,
    // grab all buttons that were selected in the previoius button selection part, regardless of the stage
  }

  if (
    !inputBoxes.some((box) => {
      return !box.buttonSelectionTitle
    })
  ) {
    inputBoxes.length &&
      inputBoxes.sort((a, b) =>
        a.buttonSelectionTitle.buttonValue.name >
        b.buttonSelectionTitle.buttonValue.name
          ? 1
          : -1
      )
  }

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    inputBoxes,
    buttonValues, // the list of ALL buttons
    selectedButtonsValues: stage2Buttons // only the selected buttons
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LayoutMetaDataReferenceUserInputUserInput)
)
