import React, { memo } from "react"
import ReactVideoThumbnail from "../ReactVideoThumbnail/ReactVideoThumbnail"
import "./VideoThumbnail.css"
import VideoThumbnailTitle from "../VideoThumbnailTitle/VideoThumbnailTitle"
import VideoThumbnailDescription from "../VideoThumbnailDescription/VideoThumbnailDescription"

const VideoTitleDescriptionThumbnail = (props) => {
  return (
    <>
      <ReactVideoThumbnail
        from={props.from}
        renderSkeletonLoaders={props.renderSkeletonLoaders}
        onLoad={props.onLoad}
        title={props.video.title}
        durationInSeconds={props.video.duration_in_seconds}
        img={props.video.s3_thumbnail_url}
        userHasSeenVideo={
          props.video.userWatches && props.video.userWatches.length
        }
      />
      <VideoThumbnailTitle title={props.video.title} />
      <VideoThumbnailDescription description={props.video.description} />
    </>
  )
}

export default memo(VideoTitleDescriptionThumbnail, (prevProps, newProps) => {
  return prevProps.video.id === newProps.video.id
})
