/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react"
import MediaQuery from "react-responsive"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"

import ProgramApprovalContainer from "../ProgramApprovalContainer/ProgramApprovalContainer"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import ProgressBar from "../ProgressBar/ProgressBar"
import pypPageLarge from "../../Assets/Images/PYP-page.png"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

import "./BiographyPYP.css"
import UserActionCard from "../LandingPage/UserActionCard/UserActionCard"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    guides: state.user.user.guides,
    // Boolean
    allModulesCompleted: !(
      state.user.user.modules &&
      state.user.user.modules.find(
        (PYPmodule) => PYPmodule.pivot.completed === 0
      )
    ),
    // Will return either current navigated modules in PYP or default if no local storage
    module:
      state.user.user.modules &&
      (state.user.user.modules.find(
        (PYPmodule) => PYPmodule.id === state.pyp.navigation.currentModuleId
      ) ||
        state.user.user.modules.find(
          (PYPmodule) =>
            PYPmodule.id ===
            state.user.user.modules[state.user.user.modules.length - 1].id
        )),
    LANDING_PAGE_PYP_GUIDE: state.user.user.guides // If the guide exists in pivot table, return it
      ? state.user.user.guides.find(
          (guide) => guide.unique_string_identifier === "LANDING_PAGE_PYP"
        )
      : null,
    // Boolean - first check if PYP exists in pivot table, then check approval status
    approvedForPYP: !!(
      state.user.user.programs &&
      state.user.user.programs.find(
        (program) => program.name === "Pursue Your Purpose"
      ) &&
      state.user.user.programs.find(
        (program) => program.name === "Pursue Your Purpose"
      ).pivot.status === "approved"
    ),
    approvalProps: {
      approvalHeader: "You’ve Unlocked Pursue Your Purpose",
      approvalMessage:
        "This program will assist you in increasing self-awareness, defining your unique transition priorities, and articulating those in a message that is authentic and comfortable to you.",
      route: "/workshop/Welcome%20to%20COMMIT",
      redirectButtonText: "Get Started",
      GUIDE_USI: "LANDING_PAGE_PYP"
    },
    user: state.user.user,
    coach: state.user?.user?.dvsPerson?.coachUser || null,
    allModules: state.pyp?.modules,
    userCompletedModules:
      state.user.user.modules &&
      state.user.user.modules.filter(
        (PYPmodule) => PYPmodule.pivot.completed === 1
      )
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class BiographyPYP extends Component {
      constructor(props) {
        super(props)
        this.state = {
          skeletonWidth: 100
        }
        this.redirectToPYP = this.redirectToPYP.bind(this)
        this.getProgress = this.getProgress.bind(this)
      }

      componentDidMount() {
        this.resizeSkeletonWidth()
        window.addEventListener("resize", this.resizeSkeletonWidth)
      }

      resizeSkeletonWidth = () => {
        setTimeout(() => {
          const container = document.getElementById("biographyPYPContainer")
          if (container) {
            this.setState({ skeletonWidth: container.clientWidth })
          }
        }, 500)
      }

      redirectToPYP = (e) => {
        e.preventDefault()
        this.props.history.push(
          `/workshop/${this.props.module.title.replace(" ", "%20")}`
        )
      }

      getProgress = () => {
        const { allModules, userCompletedModules } = this.props
        if (!allModules || !userCompletedModules) return 0

        const allModulesCant = allModules.allIds?.length || 0
        const userCompletedCant = userCompletedModules.length

        if (allModulesCant === 0) return 0

        return Math.ceil((userCompletedCant * 100) / allModulesCant)
      }

      modulesIncompletes = () => (
        <div className="biographyPYPText" style={{ flex: "1 1 70%" }}>
          <h2 className="biographyPYPTitle">
            {" "}
            Pursuing Your Purpose | {this.props.module.title}{" "}
          </h2>
          <p className="biographyPYPDescription">
            {this.props.module.title === "Welcome to COMMIT"
              ? "You’ve unlocked Pursuing Your Purpose! This program will assist you in increasing self-awareness, defining your unique transition priorities, and articulating those in a message that is authentic and comfortable to you."
              : this.props.module.description}
          </p>
          <div className="bottom-section-container">
            <span className="biographyPYPResumeButton">
              <CommitButtonWithClickState
                type="red"
                clickHandler={this.redirectToPYP}
                id="biographyPYPRedirect"
                style={{ width: "153px", height: "40px" }}
              >
                {this.props.module?.pivot?.started
                  ? "Resume Module"
                  : "Get Started"}
              </CommitButtonWithClickState>
            </span>
            <ProgressBar progress={this.getProgress()} />
          </div>
        </div>
      )

      modulesCompletes = () => (
        <div className="biographyPYPText" style={{ flex: "1 1 70%" }}>
          <h2 className="biographyPYPTitle">
            {" "}
            Pursuing Your Purpose | Completed{" "}
          </h2>
          <p
            className="biographyPYPDescription"
            style={{ WebkitBoxOrient: "vertical" }}
          >
            Many ideas, goals, and important factors of your transition are
            highlighted within Pursue Your Purpose. We encourage you to continue
            to revisit and edit your Pursue Your Purpose programming modules as
            you progress in your transition!
          </p>
          <div className="bottom-section-container">
            <span className="biographyPYPResumeButton">
              <CommitButtonWithClickState
                type="red"
                clickHandler={(e) => this.redirectToPYP(e)}
                id="biographyPYPRedirect"
                style={{ width: "153px", height: "40px" }}
              >
                Make Edits
              </CommitButtonWithClickState>
            </span>
            <ProgressBar progress={this.getProgress()} />
          </div>
        </div>
      )

      getSkeleton = () => {
        return (
          <>
            <MediaQuery maxWidth={650}>
              <div className="biographyPYPContainer">
                <SkeletonWrapper
                  height="290"
                  width={this.state.skeletonWidth}
                  containerStyle={{
                    width: "100%",
                    height: "290px"
                  }}
                />
              </div>
            </MediaQuery>
            <MediaQuery minWidth={651}>
              <div className="biographyPYPContainer">
                <SkeletonWrapper
                  height="290"
                  width={this.state.skeletonWidth}
                  containerStyle={{
                    width: "100%",
                    height: "290px"
                  }}
                >
                  <rect
                    x={this.state.skeletonWidth * 0.05}
                    y="33.5"
                    width={this.state.skeletonWidth * 0.4}
                    height="223"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="70"
                    width={this.state.skeletonWidth * 0.22}
                    height="40"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.73}
                    y="70"
                    width={this.state.skeletonWidth * 0.22}
                    height="40"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="120"
                    width={this.state.skeletonWidth * 0.45}
                    height="20"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="150"
                    width={this.state.skeletonWidth * 0.45}
                    height="20"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="180"
                    width={this.state.skeletonWidth * 0.4}
                    height="20"
                  />
                </SkeletonWrapper>
              </div>
            </MediaQuery>
          </>
        )
      }

      getBiographyHunk = () => {
        if (
          this.props.guides &&
          this.props.LANDING_PAGE_PYP_GUIDE &&
          this.props.approvedForPYP &&
          this.props.module
        ) {
          if (!this.props.allModulesCompleted) {
            return this.modulesIncompletes()
          }
          return this.modulesCompletes()
        }
        return this.getSkeleton()
      }

      getRenderedPYP = () => {
        if (this.props.LANDING_PAGE_PYP_GUIDE && this.props.approvedForPYP) {
          return (
            <>
              <MediaQuery maxWidth={650}>
                <div
                  id="biographyPYPContainer"
                  className="biographyPYPContainer"
                >
                  {this.getBiographyHunk()}
                  {this.props.coach ? (
                    <div
                      className="card-action-container"
                      style={{ flex: "1 1 100%" }}
                    >
                      <UserActionCard
                        showBorder
                        target="coach"
                        user={this.props.coach}
                        customStyles={{
                          imageSize: { width: "50px", height: "50px" }
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </MediaQuery>
              <MediaQuery minWidth={651}>
                <div
                  id="biographyPYPContainer"
                  className="biographyPYPContainer"
                >
                  {this.getBiographyHunk()}
                  {this.props.coach ? (
                    <div
                      className="card-action-container"
                      style={{ flex: "1 1 25%", paddingRight: 23 }}
                    >
                      <h1 className="coach-title">Your PYP Coach</h1>
                      <UserActionCard
                        showBorder
                        target="coach"
                        isShortCard
                        user={this.props.coach}
                        customStyles={{
                          imageSize: { width: "50px", height: "50px" },
                          container: { padding: "13px 19px" }
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </MediaQuery>
            </>
          )
        }

        return this.props.approvedForPYP ? (
          <ProgramApprovalContainer
            name="PYP"
            data={{ ...this.props.approvalProps }}
            pageImage={pypPageLarge}
          />
        ) : (
          ""
        )
      }

      render() {
        return this.getRenderedPYP()
      }
    }
  )
)
