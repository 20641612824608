import React from "react"
// import PropTypes from 'prop-types'

import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PYPSidebarItem from "../PYPSidebarItem/PYPSidebarItem"
import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"
import { gtmClickHandler } from "../../Utils/gtm"
import PYPDownloadContainer from "../PYPDownloadContainer/PYPDownloadContainer"

export class PYPSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.navItemClickHandler = this.navItemClickHandler.bind(this)
  }

  navItemClickHandler(module) {
    gtmClickHandler({
      name: "SidebarModuleClickEvent",
      data: {
        action: "Sidebar Module Click",
        category: "PYP Engagement",
        label: module.title
      }
    })
    this.props.history.push(`/workshop/${module.title}`)
  }

  getStyle(item) {
    const { unlockedUserModules } = this.props.pyp
    const unlockedModuleIndex = unlockedUserModules.findIndex(
      (unlockedModule) => item.id === unlockedModule.module_id
    )

    if (unlockedModuleIndex === -1) {
      return {
        opacity: 0.5
      }
    }
    if (unlockedUserModules[unlockedModuleIndex].completed) {
      return {
        opacity: 0.75,
        fontWeight: "bold"
      }
    }

    // return nothing, stock styling gets applied
  }

  render() {
    return (
      <div style={styles.sidebarContainer} className="sidebarContainer">
        <div style={styles.sidebarContent} className="sidebarContent">
          <div style={styles.sidebarHeader}>
            <div style={styles.headerTitle}>Pursue Your Purpose</div>
            <div style={{ height: "20px" }} />
            <div style={styles.horizontalBar} />
            <div style={{ height: "20px" }} />
          </div>

          <div style={styles.sidebarItemsContainer}>
            {this.props.pyp.modules.allIds.map((moduleId, i) => {
              const item = this.props.pyp.modules.byId[moduleId]

              return (
                <PYPSidebarItem
                  key={i}
                  uniqueKey={i}
                  item={item}
                  textStyle={this.getStyle(item)}
                  isSelected={
                    item.id === this.props.pyp.navigation.currentModuleId
                  }
                  clickHandler={() => this.navItemClickHandler(item)}
                />
              )
            })}
          </div>
        </div>

        <div
          style={styles.sideBarSectionFixedBottom}
          className="sidebarLowerSection"
        >
          <PYPDownloadContainer />
        </div>
      </div>
    )
  }
}

const styles = {
  sidebarContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 60px)",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingRight: "15px",
    zIndex: 2
  },
  sidebarContent: {
    // Container for the logo, progress dial, and nav items
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-start",
    // padding: '10px 0px 20px 30px',
    minHeight: "65px"
  },
  sidebarHeader: {
    minHeight: "100px",
    paddingTop: "50px",
    paddingLeft: "30px"
  },
  headerTitle: {
    fontFamily: "Lato-Bold",
    fontSize: "20px",
    whiteSpace: "nowrap",
    lineHeight: "42px",
    color: colors.white
  },
  horizontalBar: {
    width: "100%",
    borderBottom: `solid 1px ${colors.mediumBlue}`
  },
  sidebarItemsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    // So that scrolling is enabled when height of side bar is small
    flexGrow: 1,
    overflow: "auto",
    padding: "0px",
    // justifyContent: "space-between",
    minHeight: 0 /* for Firefox */
  },
  sideBarSectionFixedBottom: {
    // fixed to bottom - this only contains the submit button
    // the extra 15px accounts for the padding on the right of the sidebar to ensure the download button extends the component
    width: "calc(100% + 15px)"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    pyp: state.pyp
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPSidebar)
)
