import React from "react"
import NonDodIcPersonalInfoInputs from "../NonDodIcPersonalInfoInputs/NonDodIcPersonalInfoInputs"
import NonDodIcLocationInputs from "../NonDodIcLocationInputs/NonDodIcLocationInputs"
import NonDodIcTimelineInputs from "../NonDodIcTimelineInputs/NonDodIcTimelineInputs"
import NonDodIcPersonalInfoInputsThree from "../NonDodIcPersonalInfoInputsThree/NonDodIcPersonalInfoInputsThree"
import NonDodIcMilitaryOne from "../NonDodIcMilitaryOne/NonDodIcMilitaryOne"
import NonDodIcBiographyOne from "../NonDodIcBiographyOne/NonDodIcBiographyOne"
import NonDodIcBiographyTwo from "../NonDodIcBiographyTwo/NonDodIcBiographyTwo"
import NonDodIcBiographyThree from "../NonDodIcBiographyThree/NonDodIcBiographyThree" 
import NonDodIcCareerOne from "../NonDodIcCareerOne/NonDodIcCareerOne"
import { NonDodIcTransitionOne, NonDodIcTransitionGoals } from "../NonDodIcTransition"
import NonDodIcSubmissionOne from "../NonDodIcSubmissionOne/NonDodIcSubmissionOne"
import NonDodIcApplicationIntroduction from "../NonDodIcApplicationIntroduction/NonDodIcApplicationIntroduction"

const NonDodIcApplicationHashMap = {
  "introduction-video": () => {
    return <NonDodIcApplicationIntroduction />
  },
  "personal_info-basic": (props) => {
    return (
      <NonDodIcPersonalInfoInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "personal_info-address": (props) => {
    return (
      <NonDodIcLocationInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "personal_info-timeline": (props) => {
    return (
      <NonDodIcTimelineInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  relocation: (props) => {
    return (
      <NonDodIcPersonalInfoInputsThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "military-basic": (props) => {
    return (
      <NonDodIcMilitaryOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  biography: (props) => {
    return (
      <NonDodIcBiographyOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-motivations": (props) => {
    return (
      <NonDodIcBiographyTwo
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-top_priorities": (props) => {
    return (
      <NonDodIcBiographyThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "career-interested_industries": (props) => {
    return (
      <NonDodIcCareerOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "transition-challenges": (props) => {
    return (
      <NonDodIcTransitionOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "transition-goals": () => <NonDodIcTransitionGoals />,
  submission: (props) => {
    return (
      <NonDodIcSubmissionOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  }
}

export default NonDodIcApplicationHashMap
  