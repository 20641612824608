import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MediaQuery from "react-responsive"
import Actions from "../../Redux/Actions"
import WithAdminAccount from "../WithAdminAccount/WithAdminAccount"
import "./VideoTitleBar.css"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"
import VideoTitle from "../VideoTitle/VideoTitle"
import VideoLikeControls from "../VideoLikeControls/VideoLikeControls"
import PlaylistControls from "../PlaylistControls/PlaylistControls"
import VideoEditControls from "../VideoEditControls/VideoEditControls"

const VideoTitleBar = (props) => {
  return (
    <div className="videoTitleBarContainer" style={styles.componentContainer}>
      {props.currentVideo.title ? (
        <>
          <MediaQuery minWidth={650}>
            <VideoTitle title={props.currentVideo.title} />
            <span
              className="videoControlContainer"
              style={styles.controlContainer}
            >
              <VideoLikeControls style={styles.thumbsContainer} />
              <WithAdminAccount
                user={props.user}
                renderNonAdminComponent={() => <PlaylistControls />}
                renderAdminComponent={() => <VideoEditControls />}
              />
            </span>
          </MediaQuery>
          <MediaQuery maxWidth={649}>
            <VideoTitle title={props.currentVideo.title} />
            <WithAdminAccount
              user={props.user}
              renderNonAdminComponent={() => <PlaylistControls />}
              renderAdminComponent={() => <VideoEditControls />}
            />
            <VideoLikeControls
              className="videoControlThumbs"
              style={{
                ...styles.thumbsContainer,
                ...styles.thumbsContainerSmall
              }}
            />
          </MediaQuery>
        </>
      ) : (
        <SkeletonWrapper
          theme="light"
          height={30}
          width={800}
          containerStyle={{ width: "100%", height: "30px" }}
        >
          <rect x="0" y="0" rx="4" ry="4" width="400" height={30} />
          <rect x="580" y="0" rx="4" ry="4" width="30" height={30} />
          <rect x="630" y="0" rx="4" ry="4" width="30" height={30} />
          <rect x="700" y="0" rx="4" ry="4" width="100" height={30} />
        </SkeletonWrapper>
      )}
    </div>
  )
}

const styles = {
  componentContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "36px",
    paddingRight: "20px",
    paddingBottom: "9px"
  },
  controlContainer: {
    minWidth: "260px",
    display: "flex"
  },
  thumbsContainer: {
    textAlign: "right",
    marginRight: "45px",
    fontFamily: "Lato-Semibold",
    fontSize: "14px",
    minWidth: "93px",
    marginLeft: "20px"
  },
  thumbsContainerSmall: {
    marginLeft: "0px",
    textAlign: "left"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    currentVideo: state.digitalLibrary.currentVideo,
    user: state.user.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoTitleBar)
