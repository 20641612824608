import bgPattern1 from "../../Assets/Images/pattern1.svg"
import bgPattern2 from "../../Assets/Images/pattern2.svg"
import bgPattern3 from "../../Assets/Images/pattern3.svg"
import bgPattern4 from "../../Assets/Images/pattern4.svg"
import bgPattern5 from "../../Assets/Images/pattern5.svg"
import bgPattern6 from "../../Assets/Images/pattern6.svg"
import bgPattern7 from "../../Assets/Images/pattern7.svg"
import bgPattern8 from "../../Assets/Images/pattern8.svg"
import bgPattern9 from "../../Assets/Images/pattern9.svg"
import bgPattern10 from "../../Assets/Images/pattern10.svg"
import bgPattern11 from "../../Assets/Images/pattern11.svg"

const backgroundSVGMap = {
  "Nail the Pitch": bgPattern1,
  "Setting the Stage": bgPattern2,
  "Wildest Thing": bgPattern3,
  "Get Focused": bgPattern4,
  Perspective: bgPattern5,
  Excel: bgPattern6,
  "Defining Your Fears": bgPattern7,
  "Small Blocks": bgPattern8,
  Culture: bgPattern9,
  "Make it Yours": bgPattern10,
  "Your Legacy": bgPattern11,
  "Design Your Ideal Day": bgPattern1,
  "You cannot reach your goals until you set them.": bgPattern2,
  "Move Forward": bgPattern3,
  "Strategic Steps": bgPattern3
}

export default backgroundSVGMap
