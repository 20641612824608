import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { withStyles } from "@material-ui/core/styles"
import colors from "../../Constants/colors"
import VideoTitleBar from "../VideoTitleBar/VideoTitleBar"
import { LongDescription } from "../LongDescriptionContainer/LongDescriptionContainer"
import Actions from "../../Redux/Actions"
import { DownArrow } from "../../Icons/DownArrow"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import TransparentButton from "../TransparentButton/TransparentButton"
import { gtmClickHandler } from "../../Utils/gtm"

const sizingElements = {
  expansionPanelBorderWidth: "1px"
}

const styleSheet = {
  expansionRoot: {
    marginBottom: "0px !important"
  },
  root: {
    borderTop: `solid ${sizingElements.expansionPanelBorderWidth} ${colors.border} !important`,
    borderBottom: `solid ${sizingElements.expansionPanelBorderWidth} ${colors.border} !important`
  },
  expansionExpanded: {
    // borderBottom: `solid ${sizingElements.expansionPanelBorderWidth} ${colors.border} !important`,
  },
  mentorBioExpanded: {
    // borderBottom: `solid ${sizingElements.expansionPanelBorderWidth} ${colors.border} !important`,
    borderTop: `solid ${sizingElements.expansionPanelBorderWidth} ${colors.border} !important`
  }
}

class VideoMetaContent extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      mentorBioExpanded: true
    }
  }

  render() {
    // console.log(this.props, this.props.transcript)
    return (
      <div className="videoMetaContent">
        <div
          style={{
            borderBottom: `solid ${sizingElements.expansionPanelBorderWidth} ${colors.border}`,
            paddingBottom: "20px"
          }}
        >
          {
            <VideoTitleBar title={this.props.title}>
              {this.props.title}
            </VideoTitleBar>
          }
          {this.props.subject ? (
            <div
              style={styles.subject}
              onClick={() => {
                gtmClickHandler({
                  name: "MentorLinkClickedEvent",
                  data: {
                    action: "Mentor Link Clicked",
                    category: "Video Engagement",
                    label: this.props.subject
                  }
                })
              }}
            >
              <TransparentButton style={{ width: "fit-content" }}>
                <Link
                  style={{
                    textDecoration: "none",
                    color: colors.fadedRed,
                    fontFamily: "Lato-Semibold"
                  }}
                  to={`/digitalLibrary/search/${this.props.subject}`}
                >
                  {this.props.subject}
                </Link>
              </TransparentButton>
            </div>
          ) : (
            <SkeletonWrapper
              theme="light"
              height={30}
              width={800}
              containerStyle={{ width: "100%", height: "30px" }}
            >
              <rect x="0" y="0" rx="4" ry="4" width="100" height={30} />
            </SkeletonWrapper>
          )}
        </div>
        <ExpansionPanel
          defaultExpanded
          className="videoExpansionPanel"
          classes={{
            expanded: this.props.classes.expansionExpanded
          }}
          style={{
            backgroundColor: colors.background,
            border: "none",
            boxShadow: "none",
            margin: "0px"
          }}
        >
          <ExpansionPanelSummary
            style={{
              marginTop: "0px",
              marginBottom: "0px"
            }}
            expandIcon={
              <DownArrow stroke={colors.fadedRed} width="15" height="15" />
            }
            classes={{
              expanded: this.props.classes.expansionRoot
            }}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p
              style={{
                fontSize: "16px",
                lineHeight: "42px",
                fontFamily: "Lato-SemiBold",
                color: colors.midnight
              }}
            >
              Mentor Biography
            </p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <LongDescription
              subject={this.props.subject}
              long_description={this.props.description}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel
          className="videoExpansionPanel"
          classes={{
            expanded: this.props.classes.mentorBioExpanded,
            root: this.props.classes.root
          }}
          style={{
            backgroundColor: colors.background,
            border: "none",
            boxShadow: "none"
          }}
        >
          <ExpansionPanelSummary
            classes={{
              expanded: this.props.classes.expansionRoot
            }}
            expandIcon={
              <DownArrow stroke={colors.fadedRed} width="15" height="15" />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <p
              style={{
                fontSize: "16px",
                lineHeight: "42px",
                fontFamily: "Lato-SemiBold",
                color: colors.midnight
              }}
            >
              Transcript
            </p>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <LongDescription
              // TODO: this will prevent a skeleton loader - we should come up with a better mechnism for this.
              long_description={
                this.props.transcript ||
                "A transcript is not available for this video."
              }
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }
}

const styles = {
  subject: {
    width: "fit-content"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    subject: state.digitalLibrary.currentVideo.subject,
    description: state.digitalLibrary.currentVideo.description,
    transcript: state.digitalLibrary.currentVideo.transcript
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styleSheet)(VideoMetaContent))
)
