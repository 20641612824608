import types from "../types"
import fetch from "../../../Utils/fetch"

export const getFormularyQuestions = (formularyType) => async (dispatch) => {
  try {
    const { data: formularyInputs } = await dispatch(
      fetch(`/formularyQuestions`, {
        type: "GET",
        data: {
          params: { type: formularyType }
        }
      })
    )

    /* Initialize validation properties for each section and each section steps */
    const sections = formularyInputs.reduce((validSectionsAcum, input) => {
      const sectionVisitedSteps =
        validSectionsAcum[input.section]?.visitedSteps || {}

      return {
        ...validSectionsAcum,
        [input.section]: {
          isValid: true,
          visitedSteps: {
            ...sectionVisitedSteps,
            [input.step]: false
          }
        }
      }
    }, {})

    dispatch({
      type: types.FETCH_FORMULARY,
      payload: { inputs: formularyInputs, sections }
    })
  } catch (e) {
    console.log(e)
  }
}

/* Storing form inputs on a global store is one of the worst practices in terms of frontend architectures
  but we're still stuck on the old application structure :(
*/
export const changeFormularyInput = (inputId, value) => ({
  type: types.CHANGE_FORMULARY_INPUT,
  payload: { inputId, value }
})

export const setCurrentFormulary = (formularyType, formularyTitle) => ({
  type: types.SET_CURRENT_FORMULARY,
  payload: { formularyType, formularyTitle }
})

export const saveCurrentStep = (currentStep) => async (dispatch, getState) => {
  try {
    const { formulary } = getState()
    const formularyInputs = formulary[formulary.currentFormularyType].inputs

    const currentStepInputs = Object.values(formularyInputs).filter(
      (input) => input.step === currentStep.stepId
    )

    if (currentStepInputs.length) {
      await dispatch(
        fetch(`/formularyResponse`, {
          type: "POST",
          data: {
            requestPayload: {
              formularyType: formulary.currentFormularyType,
              stepInputs: currentStepInputs
            }
          }
        })
      )
    }
  } catch (error) {
    console.log(error)
  }
}

export const submitFormulary = (currentStep, history) => async (
  dispatch,
  getState
) => {
  const {
    formulary: { currentFormularyType }
  } = getState()

  await dispatch(saveCurrentStep(currentStep))

  const { data: user } = await dispatch(
    fetch(`/submitFormulary`, {
      type: "POST",
      data: {
        requestPayload: {
          formularyType: currentFormularyType
        }
      }
    })
  )

  dispatch({
    type: types.FETCHED_USER,
    user
  })

  history.push("/home")
}

export const validateCurrentStep = (currentStep) => (dispatch, getState) => {
  const { formulary } = getState()
  const { inputs } = formulary[formulary.currentFormularyType]
  const { sectionId } = currentStep

  const isValid = Object.values(inputs)
    .filter((input) => input.section === sectionId)
    .every((input) => (input.required ? Boolean(input.value) : true))

  dispatch({
    type: types.VALIDATE_CURRENT_STEP,
    payload: {
      sectionId,
      isValid
    }
  })

  dispatch({
    type: types.UPDATE_VISITED_STEP_FLAG,
    payload: { stepId: currentStep.stepId, sectionId: currentStep.sectionId }
  })

  return isValid
}
