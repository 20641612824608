import React, { Component } from "react"
import { Player } from "video-react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { gtmClickHandler, gtmNonInteraction } from "../../Utils/gtm"

class VideoReactPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      finishedLogged: false,
      quarterLogged: false,
      halfLogged: false,
      threeQuarterLogged: false,
      paused: false,
      started: false
    }
  }

  componentDidMount() {
    this.props.autoPlay &&
      gtmNonInteraction({
        name: "AutoStartedVideoEvent",
        data: {
          action: "Auto Started",
          category: "Video Engagement",
          label: this.props.videoTitle
        }
      })

    this.player.subscribeToStateChange(this.handleStateChange.bind(this))
  }

  calcPercentWatched(currentTime, duration) {
    return Math.round((currentTime / duration) * 100)
  }

  handleStateChange(state) {
    const percentWatched = this.calcPercentWatched(
      state.currentTime,
      state.duration
    )
    switch (percentWatched) {
      case 25:
        !this.state.quarterLogged &&
          gtmClickHandler({
            name: "PlayedVideo25Event",
            data: {
              action: "Played 25%",
              category: "Video Engagement",
              label: this.props.videoTitle,
              value: Math.round(state.currentTime)
            }
          })
        this.setState({
          quarterLogged: true
        })
        break
      case 50:
        !this.state.halfLogged &&
          gtmClickHandler({
            name: "PlayedVideo50Event",
            data: {
              action: "Played 50%",
              category: "Video Engagement",
              label: this.props.videoTitle,
              value: Math.round(state.currentTime)
            }
          })
        this.setState({
          halfLogged: true
        })
        break
      case 75:
        !this.state.threeQuarterLogged &&
          gtmClickHandler({
            name: "PlayedVideo75Event",
            data: {
              action: "Played 75%",
              category: "Video Engagement",
              label: this.props.videoTitle,
              value: Math.round(state.currentTime)
            }
          })
        this.setState({
          threeQuarterLogged: true
        })
        break
      case 100:
        !this.state.endLogged &&
          gtmClickHandler({
            name: "FinishedVideoEvent",
            data: {
              action: "Finished",
              category: "Video Engagement",
              label: this.props.videoTitle,
              value: Math.round(state.currentTime)
            }
          })
        this.setState({
          endLogged: true
        })
        break
      default:
    }

    if (
      state.hasStarted &&
      state.paused &&
      !this.state.paused &&
      !state.ended
    ) {
      gtmClickHandler({
        name: "StoppedVideoEvent",
        data: {
          action: "Stopped",
          category: "Video Engagement",
          label: this.props.videoTitle,
          value: Math.round(state.currentTime)
        }
      })

      this.setState({
        paused: true
      })
    }

    if (state.hasStarted && !state.paused && this.state.paused) {
      gtmClickHandler({
        name: "StartedVideoEvent",
        data: {
          action: "Started",
          category: "Video Engagement",
          label: this.props.videoTitle,
          value: Math.round(state.currentTime)
        }
      })

      this.setState({
        paused: false
      })
    }

    if (state.hasStarted && !this.state.started && !this.props.autoPlay) {
      gtmClickHandler({
        name: "StartedVideoEvent",
        data: {
          action: "Started",
          category: "Video Engagement",
          label: this.props.videoTitle,
          value: Math.round(state.currentTime)
        }
      })

      this.setState({
        started: true
      })
    }
  }

  render() {
    return (
      <Player
        ref={(player) => {
          this.player = player
        }}
        poster={this.props.thumbnailUrl || null}
        {...this.props}
      >
        {this.props.children}
      </Player>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoReactPlayer)
)
