import React from "react"
import PYPTitle from "../PYPTitle/PYPTitle"
import PYPDescription from "../PYPDescription/PYPDescription"
import colors from "../../Constants/colors"
import "./PYPPartTitleContent.css"
import ContentLoader from "react-content-loader"

const SkeletonLoader = () => (
  <ContentLoader
    height={120}
    width={600}
    speed={2}
    primaryColor="#f5f4f7"
    secondaryColor="#e1e0e2"
  >
    <rect x="94" y="12" rx="4" ry="4" width="398" height="53" />
    <rect x="5" y="81" rx="3" ry="3" width="576" height="28" />
    <rect x="0" y="120" rx="3" ry="3" width="201" height="6" />
  </ContentLoader>
)

const PYPPartTitleContent = ({
  title,
  description,
  style,
  theme = "light"
}) => {
  const textColor = theme === "light" ? colors.midnight : colors.white
  const TextColor2 = theme === "light" ? "#292a2b" : colors.white
  return (
    <>
      {title ? (
        <div
          className="pypPartTitle"
          style={{ ...styles.componentContainer, ...style }}
        >
          <PYPTitle
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              color: textColor,
              textAlign: "center"
            }}
            title={title}
          />
          <PYPDescription
            style={{
              textAlign: "center",
              fontSize: "18px",
              color: TextColor2,
              paddingTop: "20px",
              fontFamily: "Lato-Light"
            }}
            description={description}
          />
        </div>
      ) : (
        <div
          className="pypPartTitle"
          style={{ ...styles.componentContainer, ...style }}
        >
          <SkeletonLoader />
        </div>
      )}
    </>
  )
}

const styles = {
  componentContainer: {
    display: "flex",
    backgroundColor: "#f5f6f8",
    flexDirection: "column",
    justifyContent: "flex-start",
    // alignItems: 'center',
    maxWidth: "800px",
    minWidth: "300px",
    alignSelf: "center"
  }
}

export default PYPPartTitleContent
