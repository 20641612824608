import types from "../../Actions/types"

const digitalLibraryReducer = (
  state = {
    initialized: false,
    currentVideo: { title: "", type: { name: "" } },
    currentCategoryId: -1,
    currentTypeId: -1,
    navigationItems: [],
    categories: {
      allIds: [],
      byId: {}
    },
    fetchingVideos: false,
    videos: {
      page: -1,
      perPage: -1,
      total: -1,
      allIds: [],
      lastPage: -1,
      byId: {}
    },
    fetchingCategories: false,
    types: [
      {
        videos: []
      },
      { videos: [] },
      {}
    ],
    fetchingLikeStatus: false,
    searching: false,
    transition: {
      fetching: false,
      playlists: []
    }
  },
  action
) => {
  switch (action.type) {
    case types.INITIALIZED_DIGITAL_LIBRARY:
      return {
        ...state,
        navigationItems: action.data.navigationItems,
        types: action.data.types,
        initialized: true
      }
    case types.CURRENT_VIDEO_LIKE_STATUS:
      if (action.data.likeStatus) {
        return {
          ...state,
          likeStatus: action.data.likeStatus.like_value,
          likeCount: action.data.likeStatus.likeCount,
          dislikeCount: action.data.likeStatus.dislikeCount,
          fetchingLikeStatus: false
        }
      }
      return {
        ...state,
        likeStatus: 0,
        fetchingLikeStatus: false
      }

    case types.SHOW_SEARCH_BAR:
      return {
        ...state,
        searching: true
      }
    case types.HIDE_SEARCH_BAR:
      return {
        ...state,
        searching: false
      }

    case types.CURRENT_VIDEO_LIKE:
      return {
        ...state,
        likeStatus: action.data.likeStatus.like_value,
        likeCount: action.data.likeStatus.likeCount,
        dislikeCount: action.data.likeStatus.dislikeCount
      }
    case types.FETCHED_CATEGORIES_VIDEOS:
      return {
        ...state,
        currentCategories: action.data.categories.sort((a, b) =>
          a.name > b.name ? 1 : -1
        ),
        videos: action.data.videos,
        currentCategoryId: action.data.currentCategoryId,
        currentTypeid: action.data.currentTypeId
      }
    case types.CURRENT_VIDEO_DISLIKE:
      return {
        ...state,
        likeStatus: action.data.likeStatus.like_value,
        likeCount: action.data.likeStatus.likeCount,
        dislikeCount: action.data.likeStatus.dislikeCount
      }
    case types.CURRENT_VIDEO_LIKE_RESET:
      return {
        ...state,
        likeStatus: action.data.likeStatus.like_value,
        likeCount: action.data.likeStatus.likeCount,
        dislikeCount: action.data.likeStatus.dislikeCount
      }
    case types.CLEAR_VIDEOS:
      return {
        ...state,
        categories: {
          allIds: [],
          byId: {}
        },
        currentVideo: { title: "", type: { name: "" } },
        videos: {
          page: -1,
          perPage: -1,
          total: -1,
          allIds: [],
          byId: {}
        },
        types: {
          allIds: [],
          byId: {}
        }
      }
    case types.FETCHING_CATEGORIES:
      return {
        ...state,
        fetchingCategories: true
      }
    case types.FETCHING_VIDEOS:
      return {
        ...state,
        fetchingVideos: true
      }
    case types.FETCHED_VIDEOS:
      // need to look into why the currentCategoryId = undefined..

      return {
        ...state,
        videos: action.data.videos,
        categories: action.data.categories
          ? action.data.categories
          : {
              allIds: [],
              byId: {}
            },
        currentCategoryId:
          action.data.currentCategoryId !== undefined
            ? action.data.currentCategoryId
            : state.currentCategoryId,
        currentTypeId: action.data.currentTypeId
          ? action.data.currentTypeId
          : state.currentTypeId,
        types: action.data.types ? action.data.types : state.types,
        fetchingVideos: false
      }
    case types.LOAD_MORE_VIDEOS:
      return {
        ...state,
        videos: action.data.videos,
        fetchingVideos: false
      }
    case types.FETCHED_VIDEOS_TYPES:
      return {
        ...state,
        types: action.data.types,
        videos: action.data.videos,
        fetchingVideos: false
      }
    case types.FETCHING_LIKE_STATUS:
      return {
        ...state,
        fetchingLikeStatus: true
      }
    case types.FETCHED_CATEGORIES:
      return {
        ...state,
        currentCategories: action.data.currentCategories,
        fetchingCategories: false
      }
    case types.SET_WATCHING_VIDEOS:
      return {
        ...state,
        currentVideo: action.data.currentVideo,
        fetchingVideos: false
      }
    case types.STORE_TRANSITION_PLAYLIST:
      return {
        ...state,
        transition: {
          ...state.transition,
          playlists: action.data.transitionPlaylists
        }
      }
    case types.STORE_SINGLE_PLAYLIST:
      return {
        ...state,
        transition: {
          fetching: false,
          playlists: action.data.playlist
        }
      }
    case types.CLEAR_TRANSITIONS:
      return {
        ...state,
        transition: {
          fetching: true,
          playlists: {}
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default digitalLibraryReducer
