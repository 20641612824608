import React from "react"
import applicationHashMap from "../ApplicationEditor/applicationHashMap"
import UserFieldEditor from "../UserFieldEditor/UserFieldEditor"

/*
  This component's primary objective is to pass in the inputs required to map for UserFieldEditor.
  It seems small, but it allows us to share a lot of code between the Application Process and Profile.
*/

const ApplicationInputs = ({ setInputData }) => {
  return <UserFieldEditor inputs={applicationHashMap} setInputData={setInputData} />
}

export default ApplicationInputs
