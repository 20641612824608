import React from "react"
import { withRouter } from "react-router-dom"
import colors from "../../Constants/colors.js"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import fourOhFourImg from "../../Assets/Images/404.png"
import CONSTANTS from "../../Constants/index"

export default withRouter((props) => {
  return (
    <div
      style={{
        ...styles.componentContainer,
        ...props.style
      }}
    >
      <h1
        style={{
          fontFamily: "Lato-SemiBold",
          fontSize: "40px",
          lineHeight: "42px",
          letterSpacing: "2px"
        }}
      >
        Oops,
      </h1>
      <p
        style={{
          fontSize: "25px",
          marginTop: "10px",
          letterSpacing: "2px",
          lineHeight: "42px"
        }}
      >
        something went wrong{" "}
      </p>
      <p
        style={{
          fontSize: "16px",
          lineHeight: "42px",
          color: colors.text
        }}
      >
        unfortunately, this page can't be found
      </p>
      <CommitButtonWithClickState
        style={{ marginTop: "20px", width: "154px" }}
        clickHandler={
          props.homePageHandler ||
          (() => {
            props.history.push(
              props.applicationSubmitted
                ? "/home"
                : `/application?title=${CONSTANTS.APPLICATION_PROCESS_FIRST_PAGE}`
            )
          })
        }
        type="red"
      >
        {" "}
        {props.applicationSubmitted
          ? "Go to home page"
          : "Return to application"}
      </CommitButtonWithClickState>
    </div>
  )
})

const styles = {
  componentContainer: {
    fontFamily: "Lato-Regular",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    backgroundColor: colors.midnight,
    backgroundSize: "cover",
    backgroundImage: `url(${fourOhFourImg})`
  }
}
