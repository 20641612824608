import React from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import colors from "../../Constants/colors"

const digitReg = /^\d+$/

const useStyles = makeStyles({
  root: {
    width: "90%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 30,
    overflowX: "auto",
    minWidth: 500,
    maxHeight: 500,
    height: "fit-content"
  },
  table: {
    minWidth: 650,
    maxHeight: 500,
    height: "fit-content"
  },
  header: {
    backgroundColor: colors.midnight,
    color: colors.white
  }
})

export default function TwoDimensionAnalyticHandler({
  analyticsPointer,
  tableTitle = "no title",
  fixed_values = []
}) {
  const classes = useStyles()
  const analyticsData = useSelector(
    (state) => state.analytics.analyticsData[analyticsPointer]
  )

  let tableCellTitles = (analyticsData && Object.keys(analyticsData[0])) || []
  tableCellTitles = tableCellTitles.filter((title) => title !== "title")
  const totalRowData = ["Total"]
  const tableRows =
    (analyticsData &&
      analyticsData.map((item, index) => {
        return (
          <TableRow key={index}>
            {Object.entries(item).map((entry, index) => {
              if (index === 0) {
                return (
                  <TableCell component="th" scope="row" key={index}>
                    {entry[1]}
                  </TableCell>
                )
              }
              if (totalRowData[index] && totalRowData[index] !== "N/A") {
                if (
                  fixed_values.includes(entry[0]) &&
                  analyticsData.length > 1
                ) {
                  totalRowData[index] = `${(
                    parseFloat(totalRowData[index]) +
                    (100 * entry[1]) / (analyticsData.length - 1)
                  ).toFixed(2)}%`
                } else {
                  totalRowData[index] += entry[1]
                }
              } else {
                totalRowData[index] = fixed_values.includes(entry[0])
                  ? `${(
                      (100 * entry[1]) /
                      (analyticsData.length > 1 ? analyticsData.length - 1 : 1)
                    ).toFixed(2)}%`
                  : digitReg.test(entry[1])
                  ? entry[1]
                  : "N/A"
              }
              return (
                <TableCell align="right" key={index}>
                  {fixed_values.includes(entry[0])
                    ? `${(100 * entry[1]).toFixed(2)}%`
                    : entry[1]}
                </TableCell>
              )
            })}
          </TableRow>
        )
      })) ||
    []
  tableRows.unshift(
    <TableRow key="total">
      <TableCell component="th" scope="row">
        Total
      </TableCell>
      {totalRowData.slice(1, totalRowData.length).map((value, index) => (
        <TableCell align="right" key={index}>
          {value}
        </TableCell>
      ))}
    </TableRow>
  )

  return (
    <div>
      <Paper className={classes.root}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.header} component="th" scope="row">
                {tableTitle}
              </TableCell>
              {tableCellTitles.map((title) => (
                <TableCell
                  className={classes.header}
                  align="right"
                  key={title}
                  // onClick={() => indexUpdater(title)}
                >
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{tableRows}</TableBody>
        </Table>
      </Paper>
    </div>
  )
}
