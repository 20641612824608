import React from "react";
import AlliedForcesPersonalInfoInputs from "../AlliedForcesPersonalInfoInputs/AlliedForcesPersonalInfoInputs";
import AlliedForcesLocationInputs from "../AlliedForcesLocationInputs/AlliedForcesLocationInputs";
import AlliedForcesTimelineInputs from "../AlliedForcesTimelineInputs/AlliedForcesTimelineInputs";
import AlliedForcesPersonalInfoInputsThree from "../AlliedForcesPersonalInfoInputsThree/AlliedForcesPersonalInfoInputsThree";
import AlliedForcesMilitaryOne from "../AlliedForcesMilitaryOne/AlliedForcesMilitaryOne";
import AlliedForcesBiographyOne from "../AlliedForcesBiographyOne/AlliedForcesBiographyOne";
import AlliedForcesBiographyTwo from "../AlliedForcesBiographyTwo/AlliedForcesBiographyTwo";
import AlliedForcesBiographyThree from "../AlliedForcesBiographyThree/AlliedForcesBiographyThree";
import AlliedForcesCareerOne from "../AlliedForcesCareerOne/AlliedForcesCareerOne";
import { AlliedForcesTransitionOne, AlliedForcesTransitionGoals } from "../AlliedForcesTransition";
import AlliedForcesSubmissionOne from "../AlliedForcesSubmissionOne/AlliedForcesSubmissionOne";
import AlliedForcesApplicationIntroduction from "../AlliedForcesApplicationIntroduction/AlliedForcesApplicationIntroduction";

const AlliedForcesApplicationHashMap = {
  "introduction-video": () => {
    return <AlliedForcesApplicationIntroduction />;
  },
  "personal_info-basic": (props) => {
    return (
      <AlliedForcesPersonalInfoInputs
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          );
        }}
      />
    );
  },
  "personal_info-address": (props) => {
    return (
      <AlliedForcesLocationInputs
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "personal_info-timeline": (props) => {
    return (
      <AlliedForcesTimelineInputs
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  relocation: (props) => {
    return (
      <AlliedForcesPersonalInfoInputsThree
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "military-basic": (props) => {
    return (
      <AlliedForcesMilitaryOne
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          );
        }}
      />
    );
  },
  biography: (props) => {
    return (
      <AlliedForcesBiographyOne
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "biography-motivations": (props) => {
    return (
      <AlliedForcesBiographyTwo
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "biography-top_priorities": (props) => {
    return (
      <AlliedForcesBiographyThree
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "career-interested_industries": (props) => {
    return (
      <AlliedForcesCareerOne
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "transition-challenges": (props) => {
    return (
      <AlliedForcesTransitionOne
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  },
  "transition-goals": () => <AlliedForcesTransitionGoals />,
  submission: (props) => {
    return (
      <AlliedForcesSubmissionOne
        {...props}
        renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          );
        }}
      />
    );
  }
};

export default AlliedForcesApplicationHashMap;
