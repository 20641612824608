import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Actions from "../../Redux/Actions"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import SecondaryNavDrawer from "../SecondaryNavDrawer/SecondaryNavDrawer"
import MobileNavItem from "../MobileNavItem/MobileNavItem"

export const PYPNavDrawer = (props) => {
  const isLoading = !props.initialized && !props.modules.length

  useEffect(() => {
    props.initializePYP(props.match.params.module || "Welcome to COMMIT")
  }, [])

  const processOptions = (option) => {
    return {
      ...option,
      isSelected: props.match.params.module === option.title,
      route: `/workshop/${option.title}`
    }
  }

  const addStatus = (pypModule) => {
    const unlocked = props.userModules.find((userModule) => {
      return userModule.id === pypModule.id
    })

    return {
      ...pypModule,
      isUnlocked: Boolean(unlocked)
    }
  }

  const navOptions = props.modules
    .map(processOptions)
    .map(addStatus)
    .map((option, i) => {
      return (
        <MobileNavItem
          style={{ padding: "10px 40px 10px 40px" }}
          hasSecondaryNavigation={false}
          {...option}
          key={i}
        >
          <p
            style={{
              gridColumnStart: 2,
              opacity: option.isUnlocked || option.isSelected ? 1 : 0.5
            }}
          >
            {option.title}
          </p>
        </MobileNavItem>
      )
    })

  return (
    <>
      <SecondaryNavDrawer
        navOptions={navOptions}
        title="Pursue Your Purpose"
        titleStyles={{ fontSize: "24px" }}
        isLoading={isLoading}
        // bottomButtonComponent={<CommitButtonWithClickState type="red" style={{height:'100%'}}> Download Data </CommitButtonWithClickState>}
      />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => ({
  ...props,
  modules:
    state.pyp.modules && state.pyp.modules.allIds
      ? state.pyp.modules.allIds.map(
          (moduleId) => state.pyp.modules.byId[moduleId]
        )
      : [],
  userModules: state.user.user.modules,
  initialized: state.pyp.initialized
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithDrawerConsumer(PYPNavDrawer))
)
