import * as ApplicationActions from "./Application/functions"
import * as SpouseApplicationActions from "./SpouseApplication/functions"
import * as DigitalLibraryActions from "./DigitalLibrary/functions"
import * as PYPActions from "./PYP/functions"
import * as UserActions from "./User/functions"
import * as WorkshopActions from "./Workshop/functions"
import * as NonDodIcApplicationActions from "./NonDodIcApplication/functions"
import * as AlliedForcesApplicationActions from "./AlliedForcesApplication/functions"

export default {
  ...ApplicationActions,
  ...SpouseApplicationActions,
  ...DigitalLibraryActions,
  ...PYPActions,
  ...UserActions,
  ...WorkshopActions,
  ...NonDodIcApplicationActions,
  ...AlliedForcesApplicationActions
}