const types = {
  SELECTED_SPOUSE_APPLICATION_NAV_ITEM: "SELECTED_SPOUSE_APPLICATION_NAV_ITEM",
  FETCHED_SPOUSE_APPLICATION: "FETCHED_SPOUSE_APPLICATION",
  TOGGLE_SPOUSE_UNFILLED: "TOGGLE_SPOUSE_UNFILLED",
  SUBMIT_SPOUSE_APPLICATION: "SUBMIT_SPOUSE_APPLICATION",
  SUBMITTED_SPOUSE_APPLICATION: "SUBMITTED_SPOUSE_APPLICATION",
  EDIT_SPOUSE_APPLICATION: "EDIT_SPOUSE_APPLICATION",
  INIT_SPOUSE_APPLICATION: "INIT_SPOUSE_APPLICATION",
  SET_SPOUSE_APPLICATION_INPUT_DATA: "SET_SPOUSE_APPLICATION_INPUT_DATA",
  SAVING_SPOUSE_APPLICATION: "SAVING_SPOUSE_APPLICATION",
  SPOUSE_APPLY_CLICKED: "SPOUSE_APPLY_CLICKED",
}

export default types
