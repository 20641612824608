import React from "react"

export const Filter = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
    >
      <path
        fill="#292E4C"
        fillRule="nonzero"
        d="M10.464 1.346v.87H9.006c-.169.46-.602.796-1.108.796-.506 0-.94-.335-1.109-.796H.61v-.87h6.18C6.958.886 7.392.55 7.898.55s.94.336 1.108.796h1.458zM7.898 8.037c-.506 0-.94.336-1.109.796H.61v.871h6.18c.169.46.603.796 1.109.796s.94-.336 1.108-.796h1.458v-.87H9.006a1.195 1.195 0 0 0-1.108-.797zM3.295 4.294c-.506 0-.94.335-1.108.796H.608v.87h1.579c.168.46.602.796 1.108.796.506 0 .94-.336 1.109-.796h6.06v-.87h-6.06a1.195 1.195 0 0 0-1.109-.796z"
      />
    </svg>
  )
}
