import React from "react"
import { Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

const CompetitiveTierRedirect = ({ children }) => {
  const { isTierConfirmed } = useSelector(({ user }) => {
    const hasUser = Object.keys(user?.user).length > 0

    return {
      isTierConfirmed: hasUser ? user?.user?.tier_confirmed : true
    }
  })

  if (!isTierConfirmed) {
    return <Redirect to="/application/status" />
  }

  return children
}

CompetitiveTierRedirect.propTypes = {
  children: PropTypes.node.isRequired
}

export default CompetitiveTierRedirect
