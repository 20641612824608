import React from "react"
import profileHashMap from "../ApplicationEditor/profileHashMap"
import UserFieldEditor from "../UserFieldEditor/UserFieldEditor"

/*
  This component's primary objective is to pass in the inputs required to map for UserFieldEditor.
  It seems small, but it allows us to share a lot of code between the Application Process and Profile and gives
  a place for adding functionality specific to either one.
*/

const ProfileInputs = () => {
  return <UserFieldEditor inputs={profileHashMap} />
}

export default ProfileInputs
