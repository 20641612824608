import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import colors from "../../Constants/colors.js"
import Actions from "../../Redux/Actions"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import FormHeader from "../FormHeader/FormHeader"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { FormMiddle } from "../FormMiddle/FormMiddle"
import { KeyHole } from "../../Icons/KeyHole"
import InputGroup from "../InputGroup/InputGroup"

const resetPasswordInputs = [
  {
    id: "password",
    inputType: "text",
    placeHolder: "New Password",
    type: "password",
    value: "",
    errorMessage: "Required",
    className: "loginPassword",
    submittable: false,
    required: true
  },
  {
    id: "confirmPassword",
    inputType: "text",
    type: "password",
    placeHolder: "Confirm New Password",
    value: "",
    className: "confirmPassword",
    submittable: false,
    required: true
  }
]

class ResetPassword extends Component {
  constructor() {
    super()
    this.handleInputChange = this.handleInputChange.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
    this.requiredValidators = this.requiredValidators.bind(this)
    this.navToSetPassword = this.navToSetPassword.bind(this)
    document.title = "Set Password"
  }

  handleInputChange(updatedInputs) {
    this.inputsArray = updatedInputs
  }

  requiredValidators = (inputData) => (input) => {
    return {
      ...input,
      error: !!(input.required && input.value.length === 0),
      errorMessage: "Required"
    }
  }

  passwordLengthValidator = (inputData) => (input) => {
    if (
      (input.id === "password" || input.id === "confirmPassword") &&
      input.value
    ) {
      return {
        ...input,
        error: input.value.length < 7,
        errorMessage: "Password must be at least 8 characters long."
      }
    }
    return input
  }

  confirmPasswordValidator(inputData) {
    return (input) => {
      if (input.id === "password" || input.id === "confirmPassword") {
        const password = inputData.find((input) => input.id === "password")
          .value
        const confirmPassword = inputData.find(
          (input) => input.id === "confirmPassword"
        ).value
        if (password !== confirmPassword) {
          return {
            ...input,
            error: true,
            errorMessage: "Password must match."
          }
        }
        return input
      }
      return input
    }
  }

  navToSetPassword() {
    this.props.history.push("/account/setNewPassword")
  }

  resetPassword(checkSubmittability, inputData) {
    return () => {
      checkSubmittability() &&
        this.props.resetPassword(
          inputData[0].value,
          this.props.history,
          this.props.match.params
        )
    }
  }

  render() {
    console.log(this.props.expiredToken)
    return (
      <div style={styles.componentContainer}>
        <InputGroup
          validators={[
            this.confirmPasswordValidator,
            this.passwordLengthValidator,
            this.requiredValidators
          ]}
          // containerClass="createAccountContainer"
          inputData={resetPasswordInputs}
          renderComponent={({
            inputComponent,
            inputData,
            checkSubmittability
          }) => {
            return (
              <AccountFormContainer>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    alignItems: "center"
                  }}
                >
                  <KeyHole style={{ marginBottom: "15px" }} />
                  <FormHeader
                    title="Enter Password"
                    type={this.props.expiredToken ? "exception" : "default"}
                    subTitle={
                      this.props.expiredToken
                        ? "Token Expired. Click button below to navigate to set password page."
                        : "Enter your new password below"
                    }
                  />
                </div>
                {inputComponent}
                <FormMiddle>
                  {this.props.expiredToken ? (
                    <CommitButtonWithClickState
                      type="red"
                      style={{ marginBottom: "15px", marginTop: "15px" }}
                      clickHandler={this.navToSetPassword}
                    >
                      Navigate to Set Password Page
                    </CommitButtonWithClickState>
                  ) : (
                    <CommitButtonWithClickState
                      type="red"
                      style={{ marginBottom: "15px", marginTop: "15px" }}
                      clickHandler={this.resetPassword(
                        checkSubmittability,
                        inputData
                      )}
                    >
                      Reset and Login
                    </CommitButtonWithClickState>
                  )}
                </FormMiddle>
              </AccountFormContainer>
            )
          }}
        />
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0
  },
  loginButton: {
    borderRadius: "19px",
    border: "solid 1px #e1373a",
    color: colors.fadedRed,
    backgroundColor: "#ffffff",
    width: "128px"
  },
  footerText: {
    fontSize: "13px"
  },
  createButtonHover: {
    backgroundColor: "#ffffff",
    color: "#E1373A",
    borderColor: "#E1373A"
  },
  createButton: {
    marginTop: "25px",
    width: "26%",
    borderRadius: "3px",
    transition: "all 0.3s ease-in-out",
    backgroundColor: colors.fadedRed,
    color: colors.white
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    expiredToken:
      state.errors.exception &&
      state.errors.exception.message ===
        "ExpiredJwtToken: E_JWT_TOKEN_EXPIRED: The jwt token has been expired. Generate a new one to continue"
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
)
