import React from "react"
import { useSelector } from "react-redux"
import InputGroup from "../InputGroup/InputGroup"

import "./SpouseActiveDutyInputs.css"

const initialInputs = [
  {
    id: 0,
    userField: "active_duty",
    inputType: "select",
    placeHolder: "",
    title: "Seeking/receiving services or has your spouse previously?",
    className: "active_duty",
    options: [
      { id: 1, value: "Yes" },
      { id: 0, value: "No" }
    ],
    required: true
  },
//   {
//     id: 1,
//     userField: "service_member_name",
//     inputType: "text",
//     placeHolder: "Service Member Name",
//     title: "Service Member Name",
//     options: []
//   },
//   {
//     id: 2,
//     userField: "serve_millitary",
//     inputType: "select",
//     placeHolder: "",
//     title: "Did you serve in the Military?",
//     options: [
//       { id: 1, value: "Yes" },
//       { id: 0, value: "No" }
//     ]
//   }
]

const SpouseActiveDutyInputs = (props) => {
  const application = useSelector((state) => {
    return state.spouseApplication.application;
  })

  
  const requiredValidators = () => (input) => ({
    ...input,
    error: !!(input.required && input.value.length === 0),
    errorMessage: "Required"
  })

    const inputDataWithValues = initialInputs.map((input) => {
        let value = Object.byString(application, input.userField) || ""
        return {
            ...input,
            value
        }
    })

  return (
    <InputGroup
      {...props}
      containerClass="spouseActiveDutyContainer"
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              checkSubmittability,
              inputComponent: inputComponent,
              validators: [requiredValidators],
            })}
          </>
        );
      }}
    />
  )
}

export default SpouseActiveDutyInputs
