import React, { Component } from "react"
import PropTypes from "prop-types"

import "./FormFooter.css"

export default class FormFooter extends Component {
  render() {
    return (
      <div
        className="formFooterContainer"
        style={{
          ...this.props.style,
          textAlign: "center"
        }}
      >
        {this.props.children}
      </div>
    )
  }
}

FormFooter.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}
