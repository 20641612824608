export const getStyleByUrl = (
  url,
  url_qualifier,
  selectedStyle,
  unselectedStyle
) => {
  // String begins with /URL_QUALIFIER
  const regexString = `^/${url_qualifier}`
  const regex = new RegExp(regexString, "gi")

  const selected = regex.test(url)

  return selected ? { ...selectedStyle } : { ...unselectedStyle }
}
