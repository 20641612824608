import { buildSteps } from "../../Utils/metadataUtils";

export const AlliedForcesApplicationMetadata = [
  {
    id: "introduction",
    title: "Welcome",
    steps: [
      {
        stepId: "introduction-video",
        title: "Welcome",
        name: "Introduction",
        url: "/application/allied-forces?title=introduction-video",
        body:
          "Our application requires thoughtful reflection on who you are, what you’re considering post-service, and how you’d like COMMIT to assist you. Please be thorough in your responses to give our team the best opportunity to partner with you.",
        inputIds: []
      }
    ],
    inputs: []
  },
  {
    id: "personal-info",
    title: "Personal Info",
    steps: [
      {
        stepId: "personal_info-basic",
        title: "Personal Info",
        name: "Personal Info - Basic",
        url: "/application/allied-forces?title=personal_info-basic",
        body: "To begin, we'd like to get to know you a little better.",
        inputIds: [0, 1, 2, 8, 9, 10, 11, 12, 13]
      },
      {
        stepId: "personal_info-address",
        name: "Personal Info - Address",
        url: "/application/allied-forces?title=personal_info-address",
        title: "Personal Info",
        body: "",
        id: 1,
        inputIds: [3, 4, 5, 6, 7, 23]
      },
      {
        stepId: "relocation",
        name: "Personal Info - Relocations",
        url: "/application/allied-forces?title=relocation",
        title: "Personal Info",
        body: "What are your top 3 locations of interest?",
        id: 1,
        inputIds: [14, 15, 16, 17, 18, 19]
      }
    ],
    inputs: [
      { id: 0, optional: true, userField: "nickName" },
      { id: 1, userField: "dateOfBirth" },
      { id: 2, userField: "phoneNumber" },
      { id: 3, userField: "address.streetAddress" },
      { id: 4, userField: "address.city" },
      { id: 5, userField: "address.state" },
      { id: 6, userField: "address.zipCode" },
      { id: 7, userField: "address.willLocate" },
      { id: 8, userField: "hearAboutCommitOther", optional: true },
      { id: 9, userField: "hearAboutCommit" },
      { id: 10, userField: "gender" },
      { id: 11, userField: "ethnicity" },
      { id: 12, userField: "marital_status" },
      { id: 13, userField: "linkedin_url" },
      { id: 14, userField: "address.country" },
      { id: 15, userField: "address.state" },
      { id: 16, userField: "address.city" },
      { id: 17, userField: "address.country" },
      { id: 18, userField: "address.state" },
      { id: 19, userField: "address.city" },
      { id: 20, userField: "address.country" },
      { id: 21, userField: "address.state" },
      { id: 22, userField: "address.city" },
      { id: 23, userField: "address.country" }
    ]
  },
  {
    id: "military",
    title: "Military",
    steps: [
      {
        stepId: "military-basic",
        name: "Military - Basic",
        url: "/application/allied-forces?title=military-basic",
        title: "Military",
        body: "We use this information to learn more about the veterans we serve.",
        inputIds: [0, 1, 2, 3, 4, 5, 6, 7, 8]
      }
    ],
    inputs: [
      { id: 0, userField: "military.branch" },
      { id: 1, userField: "military.lastRank" },
      { id: 2, userField: "military.status" },
      { id: 3, userField: "military.years_of_service" },
      { id: 4, userField: "military.retirementDate" },
      { id: 5, userField: "military.specialty" },
      { id: 6, userField: "military.lastDutyPosition" },
      { id: 7, userField: "military.sof" },
      { id: 8, userField: "military.sofUnit", optional: true }
    ]
  },
  {
    id: "biography",
    title: "My Story",
    steps: [
      {
        stepId: "biography",
        name: "Biography",
        url: "/application/allied-forces?title=biography",
        title: "Personal Summary",
        body:
          "A good summary is written in third person (i.e. use your first name, he/she, etc.).  The summary may be shared with the COMMIT community of mentors, alumni, and other networking contacts. Effective summaries often include duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Recommended to be 150 words in length (minimum 300 characters and maximum 1000 characters)",
        inputIds: [0],
        required: true
      },
      {
        stepId: "biography-top_priorities",
        title: "Priorities",
        name: "Biography - Top Priorities",
        url: "/application/allied-forces?title=biography-top_priorities",
        body: "What are your top five priorities for your first year out of uniform?",
        inputIds: [1]
      }
    ],
    inputs: [
      { id: 0, userField: "biography.biography" },
      { id: 1, userField: "biography.priorities" }
    ]
  },
  {
    id: "transition",
    title: "Transition",
    filled: false,
    steps: [
      {
        stepId: "transition-challenges",
        name: "Transition - Challenges",
        url: "/application/allied-forces?title=transition-challenges",
        title: "Transition",
        body: "What do you see as the biggest challenges in your transition? Everyone faces challenges, and understanding yours will help us identify the appropriate resources to assist you with your transition.",
        id: 0,
        inputIds: [0]
      },
      {
        stepId: "transition-goals",
        name: "Transition - Goals",
        url: "/application/allied-forces?title=transition-goals",
        title: "Transition",
        body: "What are your top three goals for working with COMMIT?",
        id: 1,
        inputIds: [1]
      }
    ],
    inputs: [
      { id: 0, userField: "transition.challenges" },
      { id: 1, userField: "transition.goals" }
    ]
  },
  {
    id: "career",
    title: "Career",
    filled: false,
    steps: [
      {
        stepId: "career-interested_industries",
        name: "Career - Interested Industries",
        url: "/application/allied-forces?title=career-interested_industries",
        title: "Career",
        body: "What industries are you most interested in pursuing? Select up to 5.",
        inputIds: [0, 1]
      }
    ],
    inputs: [
      { id: 0, userField: "interests" },
      { id: 1, userField: "career.otherInterest", optional: true }
    ]
  },
  {
    id: "submission",
    title: "Submission",
    steps: [
      {
        stepId: "submission",
        name: "Submission",
        url: "/application/allied-forces?title=submission",
        title: "Submission",
        body: "Our services are highly individualized, and we would like to better understand you. Please use the following space to add additional information that you believe will impact your transition and your work with COMMIT. Some examples are why you are applying to COMMIT, why you are leaving military service, what motivates you, what you want to carry forward or leave behind from your military life.",
        id: 0,
        inputIds: [0]
      }
    ],
    inputs: [
      { id: 0, userField: "submission.extraInfo" }
    ]
  }
];

export const AlliedForcesApplicationSteps = buildSteps(AlliedForcesApplicationMetadata);
