import types from "../../Actions/types"

const initialState = {
  isLoading: true,
  isLoadingWorkshops: false,
  isLoadingActivities: false,
  isLoadingworkshopEvents: false,
  hasError: false,
  attendees: [],
  workshops: [],
  activities: [],
  workshopEvents: [],
  programCoordinator: null
}

const workshopReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_WORKSHOPS: {
      const workshops = action.payload

      if (!workshops.length) {
        return { ...initialState }
      }

      return {
        ...state,
        workshops,
        isLoadingWorkshops: false
      }
    }
    case types.SET_LOADING:
      return {
        ...state,
        isLoadingWorkshops: action.payload
      }
    case types.SET_ERROR:
      return {
        ...state,
        hasError: true
      }
    case types.FETCHING_ATTENDEES:
      return {
        ...state,
        isLoading: true,
        hasError: false
      }
    case types.FETCHED_ATTENDEES:
      return {
        ...state,
        attendees: action.data,
        isLoading: false,
        hasError: false,
        programCoordinator: action.programCoordinator
      }
    case types.FETCH_ATTENDEES_ERROR:
      return {
        ...state,
        hasError: true,
        isLoading: false
      }
    case types.FETCHING_ACTIVITIES:
      return {
        ...state,
        isLoadingActivities: true
      }
    case types.FETCHED_ACTIVITIES:
      return {
        ...state,
        activities: action.data,
        isLoadingActivities: false
      }
    case types.FETCH_ACTIVITIES_ERROR:
      return {
        ...state,
        hasError: true,
        isLoadingActivities: false
      }
    case types.FETCHING_WORKSHOP_EVENTS:
      return {
        ...state,
        isLoadingworkshopEvents: true
      }
    case types.FETCHED_WORKSHOP_EVENTS:
      return {
        ...state,
        workshopEvents: action.data,
        isLoadingworkshopEvents: false
      }
    case types.FETCH_WORKSHOP_EVENTS_ERROR:
      return {
        ...state,
        hasError: true,
        isLoadingworkshopEvents: false
      }

    case types.UPDATE_USER_ACTIVITY_STATUSES: {
      const { payload: activityStatus } = action

      const updatedUserActivityStatuses = state.activities.map((activity) => {
        if (activity.id === activityStatus.activity_id) {
          activity.userActivityStatuses = activityStatus
          activity.userActivityStatuses.completed =
            activityStatus.completed === 1
        }

        return activity
      })

      return {
        ...state,
        activities: updatedUserActivityStatuses
      }
    }
    case types.LOGOUT:
      return { ...initialState }

    default:
      return state
  }
}

export default workshopReducer
