/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import colors from "../../Constants/colors"

import "./SpouseEvent.css"

const moment = require("moment")

const SpouseEvent = ({
  dashboardView = false,
  workshopEvents = [],
  history
}) => {
  const [showEvent, setShowEvent] = useState(false)
  const [nextEvent, setNextEvent] = useState({})

  useEffect(() => {
    if (workshopEvents?.length) {
      const eventsDate = workshopEvents.sort((event, nextEventWorkshop) => {
        // si evento actual es despues del siguiente
        if (
          moment.utc(event.date).isAfter(moment.utc(nextEventWorkshop.date))
        ) {
          return 1
        }

        return -1
      })

      // Filter events and keep the events that are after the current date
      const today = new Date();
      const dayAfterEventDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      )
      const filteredEventsDate = eventsDate.filter((event) =>
        moment.utc(event.date).isAfter(moment.utc(dayAfterEventDate))
      )

      if (filteredEventsDate.length) {
        setNextEvent(filteredEventsDate[0])
        setShowEvent(true)
      }
    }
  }, [workshopEvents])

  const redirectToEvent = () => {
    history.push(`/${nextEvent.webinar_link}`)
  }

  return showEvent ? (
    <div className="event-container">
      <div className="event-details">
        <div className="event-name">{nextEvent.session_name}</div>
        <div className="event-date">
          {moment.utc(nextEvent.date).format("MMMM DD")} - {nextEvent.time}{" "}
          (EST)
        </div>
        {!dashboardView && <div className="event-info">Zoom Conference</div>}
      </div>
      {!dashboardView && (
        <div className="event-remainder">
          <p>
            <span>Reminder:</span> Please complete the activities from your
            prior session and the pre-activities for this session before the
            next meeting.
          </p>
        </div>
      )}
      <a
        href={nextEvent.webinar_link}
        target="_blank"
        className="event-link-button"
      >
        OPEN ZOOM
      </a>
      {/* <CommitButtonWithClickState
        type="red"
        clickHandler={() => redirectToEvent()}
        style={styles.commitButton}
      >
      </CommitButtonWithClickState> */}
    </div>
  ) : (
    <div className="event-container">
      There are not any scheduled events for your cohort soon
    </div>
  )
}
export default withRouter(SpouseEvent)
