/* eslint-disable import/prefer-default-export */
export const buildSteps = (metadata) =>
  metadata
    .reduce((sectionsAcum, section) => {
      const currentStepsWithSection = section.steps.map((step, index) => ({
        ...step,
        sectionId: section.id,
        stepIndex: index
      }))

      return [...sectionsAcum, ...currentStepsWithSection]
    }, [])
    .map((step, index, steps) => ({
      ...step,
      isFirst: index === 0,
      isLast: index === steps.length - 1,
      index
    }))
