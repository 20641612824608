import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { changeFormularyInput } from "../../../../Redux/Actions/Formulary/functions"

import "./Likert.css"

const likertValues = [
  { key: 1, value: "Strongly Disagree" },
  { key: 2, value: "Disagree" },
  { key: 3, value: "Neither Disagree or Agree" },
  { key: 4, value: "Agree" },
  { key: 5, value: "Strongly Agree" }
]

const Likert = ({ title, inputId, value }) => {
  const dispatch = useDispatch()

  const onChange = ({ target }) => {
    dispatch(changeFormularyInput(inputId, target.value))
  }

  return (
    <div className="likert-input">
      <span className="title">{title}</span>

      <div className="options">
        {likertValues.map((likertValue) => {
          const isSelected = value === likertValue.value

          return (
            <input
              key={likertValue.key}
              id={likertValue.key}
              name={inputId}
              type="radio"
              onChange={onChange}
              checked={isSelected}
              value={likertValue.value}
            />
          )
        })}
      </div>
      <div className="description-container">
        <span>Strongly disagree</span>
        <span>Strongly agree</span>
      </div>
    </div>
  )
}

Likert.propTypes = {
  title: PropTypes.string.isRequired,
  inputId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired
}

export default Likert
