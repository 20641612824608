import React from "react"
import InputGroup from "../InputGroup/InputGroup"
import "./ProfilePersonalOne.css"
import { connect } from "react-redux"
import colors from "../../Constants/colors"
import { withStyles } from "@material-ui/core/styles"

const styleSheet = (theme) => ({
  label: {
    fontFamily: "Lato-Regular",
    color: colors.midnight
  }
})

class ProfilePersonalInfoInputs extends React.Component {
  constructor(props) {
    super(props)
    this.data = [
      {
        id: 0,
        className: "firstNameProfile",
        inputType: "text",
        userField: "firstName",
        placeHolder: "First Name",
        required: true,
        value: "",
        submittable: true
      },
      {
        id: 1,
        className: "lastNameProfile",
        inputType: "text",
        userField: "lastName",
        placeHolder: "Last Name",
        required: true,
        value: "",
        submittable: true
      },
      {
        id: 2,
        className: "emailProfile",
        inputType: "text",
        userField: "email",
        placeHolder: "Email",
        required: true,
        value: "",
        submittable: true
      },
      {
        id: 3,
        className: "nicknameProfile",
        inputType: "text",
        userField: "nickName",
        placeHolder: "Preferred Name/Nickname (Optional)",
        required: false,
        value: "",
        submittable: true
      },
      {
        id: 4,
        className: "linkedInProfile",
        inputType: "text",
        userField: "linkedin_url",
        placeHolder: "LinkedIn URL (Optional)",
        required: false,
        value: "",
        submittable: true
      },
      {
        id: 5,
        className: "phoneNumberProfile",
        inputType: "text",
        userField: "phoneNumber",
        placeHolder: "Phone Number",
        required: false,
        value: "",
        submittable: true
      }
    ]

    this.inputDataWithValues = this.data.map((input) => {
      return {
        ...input,
        value: props.application.application[input.userField] || ""
      }
    })

    this.state = {
      inputData: this.inputDataWithValues,
      otherValue: props.application.application.hearAboutCommitOther
    }
  }

  render() {
    return (
      <InputGroup
        {...this.props}
        containerClass="profilePersonalContainer"
        inputData={this.inputDataWithValues}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {this.props.renderComponent({
                ...this.props,
                inputData,
                inputComponent: (
                  <>
                    {inputComponent}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    />
                  </>
                ),
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application
  }
}

export default connect(mapStateToProps)(
  withStyles(styleSheet)(ProfilePersonalInfoInputs)
)
