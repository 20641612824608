import React, { useEffect } from "react"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors.js"
import FormHeader from "../FormHeader/FormHeader"
import FormFooter from "../FormFooter/FormFooter"
import "./Login.css"
import { withRouter } from "react-router-dom"
import ForgotPasswordAdornment from "../ForgotPasswordAdornment/ForgotPasswordAdornment"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { FormMiddle } from "../FormMiddle/FormMiddle"
import MediaQuery from "react-responsive"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import CommitInput from "../CommitInput/CommitInput"
import { useInput } from "../../Hooks/useInput"

const Login = (props) => {
  const { value: email, bind: bindEmail } = useInput("")
  const { value: password, bind: bindPassword } = useInput("")

  useEffect(() => {
    const logout = async () => {
      props.logout(props.history)
      props.resetErrors()
      document.title = "Login"
    }
    logout()
  }, [])

  const forgotPasswordHandler = () => {
    props.history.push("/account/forgotPassword")
  }

  const createAccountHandler = () => {
    props.resetErrors()
    props.history.push({
      pathname: "/account/createAccount",
      state: { from: props.history.location.pathname }
    })
  }

  const login = () => {
    props.login({ email, password }, props.history)
    // on mobile, if this doesn't scroll to top and user
    // input incorrect credentials, they may not see error
    // message
    document.getElementById("Content-Container").scrollTop = 0
  }

  return (
    <AccountFormContainer>
      <FormHeader
        style={{ maxWidth: "450px" }}
        title="Login"
        type={props.loginException ? "exception" : "default"}
        showFAQLink={
          props.loginException && props.loginException.type === "system"
        }
        subTitle={
          props.loginException
            ? props.loginException.message
            : "Enter your details below"
        }
      />
      <CommitInput
        id="email"
        inputType="text"
        placeHolder="Email"
        value=""
        className="loginEmail"
        errorMessage="Required"
        style={styles.inputStyle}
        submittable={false}
        {...bindEmail}
      />

      <CommitInput
        id="password"
        inputType="text"
        type="password"
        style={styles.inputStyle}
        placeHolder="Password"
        value=""
        className="loginPassword"
        required
        errorMessage="Required"
        submittable={false}
        adornment={<ForgotPasswordAdornment />}
        {...bindPassword}
      />
      <MediaQuery maxWidth={500}>
        <CommitButtonWithClickState
          style={styles.forgotPasswordButton}
          clickHandler={forgotPasswordHandler}
        >
          Forgot Password?
        </CommitButtonWithClickState>
      </MediaQuery>
      <FormMiddle>
        <CommitButtonWithClickState
          type="red"
          style={styles.loginButton}
          clickHandler={login}
        >
          Login
        </CommitButtonWithClickState>
      </FormMiddle>
      <FormFooter
        style={{
          display: "flex"
        }}
      >
        <p style={styles.footerText}>Don't have a COMMIT account yet?</p>
        <div onClick={createAccountHandler} style={styles.createAccountButton}>
          <button style={styles.createAccountText}>Create Account</button>
        </div>
      </FormFooter>
    </AccountFormContainer>
  )
}

const styles = {
  inputStyle: {
    marginTop: "10px",
    margiBottom: "10px"
  },
  createAccountText: {
    color: colors.fadedRed,
    fontSize: "14px",
    fontFamily: "Lato-Bold",
    marginLeft: "7px",
    marginBottom: "0xp",
    border: "none",
    backgroundColor: "transparent"
  },
  createAccountButton: {
    textDecoration: "none",
    color: colors.fadedRed,
    cursor: "pointer"
  },
  footerText: {
    fontSize: "14px",
    marginBottom: "0px"
  },
  loginButton: {
    width: "124px"
  },
  forgotPasswordButton: {
    color: colors.fadedRed,
    backgroundColor: colors.white,
    width: "124px",
    marginTop: "-10px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    loginException: state.errors.exception
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
