import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import colors from "../../Constants/colors"

import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

const styles = {
  container: {
    fontFamily: "Lato-Regular",
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    backgroundColor: colors.midnight
  },
  message: {
    fontSize: "22px",
    margin: "10px 12px",
    textAlign: "center"
  },
  redirectButton: {
    marginTop: "20px",
    width: "155px"
  }
}

const ForbiddenNetwork = ({ history }) => {
  return (
    <div style={styles.container}>
      <h1>403 - Forbidden</h1>
      <p style={styles.message}>
        We are currently building new functionality and will open it up to users
        by invitation in the future.
      </p>
      <CommitButtonWithClickState
        style={styles.redirectButton}
        clickHandler={() => history.push("/home")}
        type="red"
      >
        Go to home page
      </CommitButtonWithClickState>
    </div>
  )
}

ForbiddenNetwork.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
}

export default withRouter(ForbiddenNetwork)
