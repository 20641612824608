import React from "react"
import { connect, useSelector, useDispatch } from "react-redux"
import { withRouter, Redirect } from "react-router-dom"
import { bindActionCreators } from "redux"
import queryString from "query-string"
import Actions from "../../Redux/Actions"
import FourOhFour from "../FourOhFour/FourOhFour"
import { applicationSteps } from "../../Redux/Reducers/Application/applicationMetadata"
import { spouseApplicationSteps } from "../../Redux/Reducers/SpouseApplication/spouseApplicationMetadata"

class ErrorRenderer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false
    }

    this.goToHomePage = this.goToHomePage.bind(this)
  }

  

  componentDidCatch(error, info) {
    console.log(
      "::ERROR RENDERER ERROR::",
      error,
      "::ERROR RENDERER INFO:: ",
      info
    )
    this.setState({
      hasError: true
    })
  }

  componentDidMount() {
    if (this.props.errorData) {
      if (
        this.props.location.search &&
        this.props.location.search.includes("utm_medium=email")
      ) {
        this.props.setLoginRedirect(
          this.props.location.pathname + this.props.location.search
        )
      }
      this.props.logout(this.props.history, true)
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.errorData) {
      if (
        (this.props.location.search &&
          this.props.location.search.includes("utm_medium=email")) ||
        this.props.location.pathname.includes("utm_medium=email")
      ) {
        this.props.setLoginRedirect(
          this.props.location.pathname + this.props.location.search
        )
      }
      this.props.logout(this.props.history, true)
    }
  }

  goToHomePage() {
    this.props.history.push("/account/login")
  }

  render() {
    if (
      this.props.customRedirectUri &&
      !applicationSteps.find(
        (elem) =>
          elem.stepId === queryString.parse(this.props.location.search).title
      ) && 
      !spouseApplicationSteps.find(
        (elem) =>
          elem.stepId === queryString.parse(this.props.location.search).title
      )
    ) {
      return <FourOhFour />
    }
    if (this.props.errorData && this.props.errorData.redirectUri) {
      return (
        <Redirect
          to={{
            pathname: this.props.errorData.redirectUri
          }}
        />
      )
    }

    return this.props.hasError ? (
      <FourOhFour homePageHandler={this.goToHomePage} />
    ) : (
      <> {this.props.children} </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    hasError: state.errors.hasError,
    errorData: state.errors.errorData,
    rollbar: state.errors.rollbar
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorRenderer)
)
