import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Loader from "react-loader-spinner"
import colors from "../../Constants/colors.js"
import Actions from "../../Redux/Actions"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import InputGroup from "../InputGroup/InputGroup"
import VideoEditorHeader from "../VideoEditorHeader/VideoEditorHeader"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import TransparentButton from "../TransparentButton/TransparentButton"
import "./VideoEditor.css"
import GenerateThumbnailButton from "../GenerateThumbnailButton/GenerateThumbnailButton"
import ReactVideoThumbnail from "../ReactVideoThumbnail/ReactVideoThumbnail"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler.js"

export class VideoEditor extends React.Component {
  constructor(props) {
    super(props)
    this.inputData = props.video
  }

  requireFields = (missingFields) => {
    // TODO: Visual Indicator for missing fields
    this.props.toggleModal({ modal_mode: true, modal_usi: "videoUpload" })
  }

  publishVideo(inputData) {
    const requiredVideoInputData = this.inputData.filter(
      (index) =>
        index.className !== "videoRecommended" &&
        index.className !== "thumbnailTimestamp" &&
        index.className !== "videoTranscript"
    )
    let requiredFieldsCompleted = true
    const missingFieldsIds = []

    requiredVideoInputData.forEach((field) => {
      if (!field.value) {
        requiredFieldsCompleted = false
        missingFieldsIds.push(field.id)
      }
    })

    return requiredFieldsCompleted
      ? (e) => {
          this.props.publishVideo(inputData, this.props.video.id)
        }
      : (e) => {
          this.requireFields(missingFieldsIds)
        }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    const data = [
      // {
      //   id: 0,
      //   className: "videoImage",
      //   inputType: "imageUpload",
      //   value: this.props.video.s3_thumbnail_url,
      //   userField: "video.s3_thumbnail_url"
      // },
      {
        id: 1,
        className: "thumbnailTimestamp",
        userField: "video.timestamp",
        placeHolder: "Thumbnail Timestamp (hh:mm:ss)",
        type: "text",
        inputType: "text",
        value: this.props.video.timestamp,
        charLimit: 9,
        adornment: (
          <GenerateThumbnailButton
            clickHandler={this.props.generateThumbnailFromTimestamp}
          />
        )
      },
      {
        id: 2,
        className: "videoTitle",
        userField: "video.title",
        placeHolder: "Video Title",
        type: "text",
        inputType: "text",
        value: this.props.video.title,
        charLimit: 25
      },
      {
        id: 3,
        className: "videoSubject",
        inputType: "text",
        placeHolder: "Subject",
        userField: "video.subject",
        value: this.props.video.subject,
        charLimit: 40
      },
      {
        id: 4,
        className: "videoType",
        inputType: "select",
        title: "Video Type",
        userField: "video.type",
        value: this.props.video.type
          ? this.props.types.find((type) => {
              return type.id === this.props.video.type.id
            }).name
          : null,
        options: this.props.types.map((type, i) => {
          return {
            id: type.id,
            value: type.name
          }
        })
      },
      {
        id: 5,
        className: "videoCategory",
        inputType: "select",
        title: "Video Category",
        userField: "video.category",
        value: this.props.video.category
          ? this.props.categories.find((category) => {
              return category.id === this.props.video.category.id
            }).name
          : null,
        options: this.props.categories.map((category, i) => {
          return {
            id: category.id,
            value: category.name
          }
        })
      },
      {
        id: 6,
        className: "videoDescription",
        inputType: "longText",
        userField: "video.description",
        placeholder: "Write a description of the Video (*required*)",
        value: this.props.video.description
      },
      {
        id: 7,
        className: "videoTranscript",
        inputType: "longText",
        userField: "video.transcript",
        placeholder: "Transcript",
        value: this.props.video.transcript
      },
      {
        id: 8,
        className: "videoRecommended",
        inputType: "radio",
        userField: "video.is_recommended",
        description: "Recommended Video?",
        title: "Recommended Video?",
        value: !!this.props.video.is_recommended
      }
      // {
      //   id: 8,
      //   className: "videoTags",
      //   inputType: "filteredMultiSelect",
      //   // userField: "video.category",
      //   selectionOptions: this.props.tagCategories,
      //   selectionValue: 'Values',
      //   inputTitle: 'Tag',
      //   buttons: this.props.tags
      // },
    ]

    return (
      <>
        <ModalDialogHandler style={styles.modalOverlay} modal_usi="videoUpload">
          <p style={styles.modalContent}> Missing Fields! </p>
        </ModalDialogHandler>
        <AccountFormContainer
          style={{
            marginTop: "30px",
            marginBottom: "30px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <InputGroup
            changeHandler={(inputData) => {
              this.props.changeHandler(inputData)
            }}
            containerClass="videoEditorInputsContainer"
            inputData={data}
            renderComponent={({
              inputComponent,
              inputData,
              checkSubmittability
            }) => {
              this.inputData = inputData
              return (
                <>
                  <VideoEditorHeader>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        borderBottom: `solid 1px ${colors.borderLight}`,
                        paddingBottom: "20px"
                      }}
                    >
                      <h1 style={styles.videoStatus}>
                        {" "}
                        {this.props.justUploaded
                          ? "Upload Complete!"
                          : "Edit Video"}
                      </h1>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "40px"
                        }}
                      >
                        <TransparentButton style={{ height: "30px" }}>
                          <label
                            htmlFor="file-upload"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              fontSize: "14px",
                              alignItems: "center"
                            }}
                          >
                            Reupload
                          </label>
                        </TransparentButton>

                        <CommitButtonWithClickState
                          style={{
                            height: "40px",
                            marginLeft: "20px",
                            width: "116px"
                          }}
                          type="red"
                          clickHandler={this.publishVideo(inputData)}
                        >
                          Publish
                        </CommitButtonWithClickState>
                      </div>
                    </div>
                  </VideoEditorHeader>
                  <div style={{ marginTop: "25px" }}>
                    <div className="videoImage">
                      {this.props.generatingThumbnail ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          <Loader
                            type="Oval"
                            color={colors.midnight}
                            height="100"
                            width="100"
                          />
                        </div>
                      ) : (
                        <ReactVideoThumbnail
                          img={this.props.video.s3_thumbnail_url}
                          {...this.props}
                        />
                      )}
                    </div>

                    {inputComponent}

                    <div style={styles.deleteVideoButtonContainer}>
                      {!this.props.justUploaded && (
                        <>
                          <TransparentButton style={{ height: "30px" }}>
                            <div
                              onClick={this.props.deleteVideo}
                              style={styles.deleteVideoButton}
                            >
                              Delete Video
                            </div>
                          </TransparentButton>
                          {this.props.deleteVideoError && (
                            <span style={styles.deleteErrorMessage}>
                              {this.props.deleteVideoError}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )
            }}
          />
        </AccountFormContainer>
      </>
    )
  }
}

const styles = {
  videoStatus: {
    color: colors.midnight,
    lineHeight: "39px",
    fontFamily: "Lato-Bold",
    fontSize: "25px"
  },
  modalOverlay: {
    position: "absolute",
    width: "150px",
    height: "150px",
    top: "100",
    left: "100"
  },
  modalContent: {
    padding: "25px"
  },
  deleteErrorMessage: {
    fontSize: "12px",
    color: colors.fadedRed,
    fontFamily: "Lato-Regular"
  },
  deleteVideoButton: {
    cursor: "pointer",
    display: "flex",
    fontSize: "14px",
    alignItems: "center"
  },
  deleteVideoButtonContainer: {
    width: "100%",
    display: "flex",
    height: "30px",
    flexDirection: "column",
    alignItems: "flex-end"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const { editable, initialized } = state.application

  const { has_submitted_application } = state.user.user

  return {
    ...props,
    editable,
    applicationInitialized: initialized,
    hasSubmittedApplication: has_submitted_application
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoEditor)
)
