import React from "react"
import { withStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import colors from "../../Constants/colors"

const styleSheet = {
  item: {
    color: colors.white,
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    lineHeight: "20px",
    padding: "0px",
    height: "30px"
  }
}

const ApplicationNavItem = (props) => {
  const selectedStyle = props.isSelected && { fontFamily: "Lato-Bold" }
  return (
    <ListItem
      style={{ ...selectedStyle, ...props.style }}
      classes={{
        root: props.classes.item
      }}
    >
      {props.children}
    </ListItem>
  )
}

export default withStyles(styleSheet)(ApplicationNavItem)
