import React from "react"

export const Seen = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="6"
      viewBox="0 0 7 6"
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="#D8F0F8"
        strokeWidth=".857"
        d="M.147 3.294L2.476 5.32 6.97 1"
      />
    </svg>
  )
}
