import React from "react"
import PropTypes from "prop-types"
import colors from "../../Constants/colors.js"

const FormHeader = (props) => {
  const exceptionStyling = props.type === "exception" && styles.exceptionText
  return (
    <div style={{ ...styles.componentContainer, ...props.style }}>
      <div style={styles.title}> {props.title} </div>
      <div style={{ ...styles.subTitle, ...exceptionStyling }}>
        {props.subTitle.split("\n").map((textLine, index) => (
          <p key={index}>{textLine}</p>
        ))}
        {props.type === "exception" && props.showFAQLink && (
          <div>
            Check our{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.commitfoundation.org/faq"
            >
              FAQ
            </a>{" "}
            for more information.
          </div>
        )}
      </div>
    </div>
  )
}

const styles = {
  componentContainer: {
    width: "100%",
    border: 0
  },
  exceptionText: {
    color: colors.fadedRed,
    fontFamily: "Lato-Bold"
  },
  title: {
    textAlign: "center",
    color: colors.midnight,
    fontSize: "30px",
    fontFamily: "Lato-Semibold"
  },
  subTitle: {
    fontSize: "15px",
    marginTop: "20px",
    textAlign: "center",
    color: colors.gray,
    fontFamily: "Lato-Medium"
  }
}

FormHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
}

export default FormHeader
