import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import MediaQuery from "react-responsive"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import queryString from "query-string"
import colors from "../../Constants/colors"
import NetworkSearchBar from "../NetworkSeachBar/NetworkSearchBar"
import MemberCardContainer from "../MemberCardContainer/MemberCardContainer"
import ForbiddenNetwork from "../ForbiddenNetwork"
import GoogleTagManager from "../GoogleTagManager/GoogleTagManager"
import {
  getWorkshopAttendees,
  getUserWorkshops
} from "../../Redux/Actions/Workshop/functions"

const styles = {
  container: {
    width: "100vw",
    height: "100vh",
    minHeight: "100vh",
    justifyContent: "center",
    paddingTop: "118px",
    backgroundColor: "#f5f6f8",
    flexDirection: "horizontal",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    overflowX: "hidden"
  },
  title: {
    color: colors.mediumBlue,
    width: "259px",
    height: "45px",
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "40px",
    lineHeight: "23px",
    marginLeft: "33px"
  }
}

const NetworkPage = ({ history, location }) => {
  const dispatch = useDispatch()
  const {
    hasNetwork,
    spouseDashboard,
    user,
    campaignId,
    workshops
  } = useSelector(({ user: userState, workshop: workshopState }) => {
    const networkProgram = userState?.user?.programs?.find(
      ({ name }) => name === "Network"
    )

    return {
      hasNetwork: Boolean(networkProgram),
      spouseDashboard: queryString.parse(location.search).spouse,
      user: userState.user,
      campaignId: queryString.parse(location.search).campaignId,
      workshops: workshopState.workshops
    }
  })

  const fetchAttendees = () => {
    if (campaignId) {
      dispatch(getWorkshopAttendees(campaignId))
    }
  }

  useEffect(() => {
    document.title = "Online Network"

    if (window.dataLayer) {
      window.dataLayer.push({
        page_view: {
          page_path: `/network`,
          page_title: document.title
        }
      })
    }

    if (!workshops.length) {
      dispatch(getUserWorkshops())
    }

    if (spouseDashboard) {
      fetchAttendees()
    }
  }, [])

  const setDimensions = () => {
    try {
      window.dataLayer.push({
        dimensions: {
          rank: user?.military?.lastRank,
          USSOCOM: user?.military?.sof,
          time_until_separation:
            user?.military &&
            `${moment(user.military.retirementDate).diff(
              moment(),
              "months"
            )} Months`,
          military_status: user?.military?.status,
          gender: user?.gender
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  if (hasNetwork || spouseDashboard) {
    return (
      <GoogleTagManager
        gtmId={process.env.REACT_APP_NETWORK_GTM_ID}
        onFinishLoad={setDimensions}
      >
        <div style={styles.container}>
          <MediaQuery minWidth={601}>
            <p style={styles.title} id="networkTitle">
              Network
            </p>
          </MediaQuery>
          {/* <NetworkSearchBar campaignId={campaignId} /> */}
          <MemberCardContainer spouseDashboard={spouseDashboard} />
        </div>
      </GoogleTagManager>
    )
  }

  return <ForbiddenNetwork />
}

export default withRouter(NetworkPage)
