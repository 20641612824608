import React from "react";
import AlliedForcesApplicationHashMap from "../AlliedForcesApplicationEditor/AlliedForcesApplicationHashMap"; // Updated import path and hash map name
import UserFieldEditor from "../UserFieldEditor/UserFieldEditor";

/*
  This component's primary objective is to pass in the inputs required to map for UserFieldEditor.
  It seems small, but it allows us to share a lot of code between the Allied Forces Application Process and Profile.
*/

const AlliedForcesApplicationInputs = ({ setInputData }) => {
  return <UserFieldEditor inputs={AlliedForcesApplicationHashMap} setInputData={setInputData} />;
};

export default AlliedForcesApplicationInputs;
