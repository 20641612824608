import React from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { changeFormularyInput } from "../../../../Redux/Actions/Formulary/functions"

import "./NpsInput.css"

const npsValues = [...new Array(10).keys()]

const NpsInput = ({ inputId, title, value }) => {
  const dispatch = useDispatch()

  const onClick = (event) => {
    const npsValue = event.target.value

    dispatch(changeFormularyInput(inputId, npsValue))
  }

  return (
    <div className="nps-input">
      <span>{title}</span>
      <div className="options-container">
        {npsValues.map((index) => {
          const npsValue = (index + 1).toString()
          const isSelected = npsValue === value

          return (
            <button
              key={index}
              onClick={onClick}
              className={isSelected ? "selected" : ""}
              type="button"
              value={npsValue}
            >
              {index + 1}
            </button>
          )
        })}
      </div>
    </div>
  )
}

NpsInput.defaultProps = {
  value: ""
}

NpsInput.propTypes = {
  inputId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string
}

export default NpsInput
