import React from "react"
import colors from "../../Constants/colors"
import WithPYPBox from "../WithPYPBox/WithPYPBox.js"

const PYPExampleInputBox = ({
  title,
  text,
  size,
  style,
  preventScroll,
  img_url
}) => {
  const scrollStyle = {
    overflow: preventScroll ? "hidden" : "auto"
  }

  return (
    <WithPYPBox
      size={size}
      style={style}
      renderComponent={(props) => (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              minHeight: "65px"
            }}
          >
            <img
              alt="example user"
              src={img_url}
              height="65"
              width="65"
              style={{
                borderRadius: "50%",
                marginBottom: "5px"
              }}
            />
          </div>
          <div style={styles.title}>{title}</div>
          <div style={{ ...styles.text, ...scrollStyle }}>{text}</div>
        </>
      )}
    />
  )
}

const styles = {
  title: {
    fontFamily: "Lato-bold",
    fontSize: "17px",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "30px",
    marginBottom: "10px",
    // letterSpacing: "3.4px",
    color: colors.midnight,
    textAlign: "center"
  },
  text: {
    height: "100%",
    width: "100%",
    backgroundColor: colors.white,

    // Style the text
    fontFamily: "Lato-Light",
    color: colors.midnight,
    textAlign: "center",
    overflow: "auto",

    // Remove defaults
    resize: "none",
    border: "none",
    outline: "none",
    boxShadow: "none"
  }
}

export default PYPExampleInputBox
