import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import queryString from "query-string"
import { withRouter } from "react-router-dom"

import UserFieldEditorContainer from "../UserFieldEditorContainer/UserFieldEditorContainer"

import {
  FormularyFooter,
  FormularyHeader,
  FormularyNavMenu,
  FormularyInputs
} from "../Formulary"

import {
  postSurveyMetadata,
  postSurveySteps
} from "../../Redux/Reducers/Application/postSurveyMetadata"
import {
  setCurrentFormulary,
  getFormularyQuestions
} from "../../Redux/Actions/Formulary/functions"

const PostSurvey = ({ history }) => {
  const dispatch = useDispatch()
  const [isInitialized, setIsInitialized] = useState(false)

  const { title: stepId } = queryString.parse(history.location.search)

  useEffect(() => {
    ;(async () => {
      const formularyType = "postSurvey"

      dispatch(setCurrentFormulary(formularyType, "Post-Survey"))
      await dispatch(getFormularyQuestions(formularyType))

      setIsInitialized(true)
    })()
  }, [])

  return (
    <UserFieldEditorContainer
      drawerComponent={() => (
        <FormularyNavMenu
          stepId={stepId}
          formularyMetadata={postSurveyMetadata}
          title="In Progress Survey"
          steps={postSurveySteps}
        />
      )}
      initialized={isInitialized}
    >
      <FormularyHeader steps={postSurveySteps} />
      <FormularyInputs steps={postSurveySteps} />
      <FormularyFooter steps={postSurveySteps} stepId={stepId} />
    </UserFieldEditorContainer>
  )
}

PostSurvey.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({ search: PropTypes.string }),
    push: PropTypes.func
  }).isRequired
}

export default withRouter(PostSurvey)
