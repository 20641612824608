import { buildSteps } from "../../Utils/metadataUtils";

export const AlliedForcesPreSurveyMetadata = [
  {
    id: "survey",
    title: "Survey",
    display: true,
    steps: [
      {
        stepId: "information",
        title: "Survey",
        body:
          "The information below will provide a baseline about your perceptions of the transition process. Please read each statement and select the circle that most closely matches how you feel about the statement in relation to your transition.",
        url: `/pre-survey/allied-forces?title=information`
      }
    ]
  }
];

export const AlliedForcesPreSurveySteps = buildSteps(AlliedForcesPreSurveyMetadata);
