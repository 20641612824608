import React from "react"
import colors from "../../Constants/colors"

import "./PYPInputBox.css"

export class PYPInputBox extends React.Component {
  constructor(props) {
    super(props)

    /* props
      size - 'small', 'medium', or 'large'
      title
      maxCharacterCount - overrides default maxCharacterCount for a given size
      style - applied to the 'box' div

      Ex: <PYPInputBox title={'This is a small box...'} size={'small'} valueDidChange={(value) => console.log(value)}/>
    */

    // Calculate box dimensions depending on the size prop
    const { size } = props
    this.boxDimensions = {
      maxWidth:
        (size === "small" && "380px") ||
        (size === "medium" && "527px") ||
        (size === "large" && "605px") ||
        "380px",
      height:
        (size === "small" && "260px") ||
        (size === "medium" && "320px") ||
        (size === "large" && "391px") ||
        "260px"
      // height: '100%',
    }

    this.maxCharacterCount =
      props.maxCharacterCount || // if the maxCharacterCount prop has been passed, set that as maxCharacterCount
      (size === "small" && 250) || // defaults for each size...
      (size === "medium" && 500) ||
      (size === "large" && 1500) ||
      250 // default if no size prop is passed into component

    this.state = {
      // value: this.props.text,
      editing: false
      // remainingCharacters: this.maxCharacterCount
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
  }

  handleChange(event) {
    const numRemainingCharacters =
      this.maxCharacterCount - event.target.value.length
    if (numRemainingCharacters >= -25) {
      // only allow updates if character account
      this.props.valueDidChange(event.target.value)

      // this.setState({
      //   value: event.target.value,
      //   remainingCharacters: numRemainingCharacters
      // }, () => {
      //   this.props.valueDidChange(this.state.value)
      // });
    }
  }

  handleBlur() {
    this.setState({ editing: false })
  }

  handleFocus() {
    this.setState({ editing: true })
  }

  render() {
    const remainingCharacters =
      this.maxCharacterCount - (this.props.text ? this.props.text.length : 0)

    const conditionalBoxStyles = {
      ...this.boxDimensions,
      borderBottom: this.state.editing
        ? `solid 2px ${colors.mediumBlue}`
        : this.props.showIncomplete
        ? `solid 2px ${colors.fadedRed}`
        : `none`
    }

    const showBottomBorder = this.state.editing || this.props.showIncomplete

    const wordCountStyles = {
      bottom: showBottomBorder ? "12px" : "14px", // account for the 2px bottom border when editing
      color: remainingCharacters < 20 ? colors.fadedRed : colors.text
    }

    // const conditionalTextAreaContainer = {
    //   paddingBottom: showBottomBorder ? "2px" : "0px"
    // };

    return (
      <div
        className="pypInputBox"
        style={{
          ...styles.box,
          ...conditionalBoxStyles, // render a bottom border if user is editing
          ...this.props.style // apply styles passed in via the 'style' prop on PYPInputBox to the box div
        }}
      >
        <div style={styles.titleContainer}>
          {this.props.title && (
            <div style={styles.title}>{this.props.title}</div>
          )}

          {
            // Show incomplete warning if props.showIncomplete is true, the text field isn't empty, and user isn't editing
            this.props.showIncomplete &&
              !this.props.text.length &&
              !this.state.editing && (
                <div style={styles.incompleteError}>(Incomplete)</div>
              )
          }
        </div>

        <div
          style={{
            ...styles.textAreaContainer
          }}
        >
          <textarea
            value={this.props.text}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            style={styles.textArea}
          />
          <div style={styles.textFade} />
        </div>

        <div style={{ ...styles.wordCount, ...wordCountStyles }}>
          {remainingCharacters}
        </div>
      </div>
    )
  }
}

const styles = {
  box: {
    backgroundColor: colors.white,
    paddingLeft: "32px",
    paddingTop: "32px",
    paddingRight: "32px",
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.05)",
    borderRadius: "3px",
    flexDirection: "column",
    position: "relative",
    minWidth: "280px",
    display: "flex",
    width: "100%" // stretch as wide as the parent component allows, until hitting the maxWidth
  },
  titleContainer: {
    padding: "5px",
    display: "flex",
    flexDirection: "row"
  },
  title: {
    fontFamily: "Lato-Bold",
    fontSize: "17px",
    color: colors.midnight,
    minHeight: "30px",
    marginBottom: "7px"
  },
  incompleteError: {
    fontFamily: "Lato-Bold",
    fontSize: "17px",
    color: colors.fadedRed,
    height: "30px",
    marginBottom: "7px",
    marginLeft: "5px"
  },
  textAreaContainer: {
    display: "flex",
    width: "100%",
    flex: 1,
    position: "relative",
    paddingLeft: "4px"
  },
  textArea: {
    height: "100%",
    width: "100%",
    // backgroundColor: "red",
    backgroundColor: colors.white,

    // Style the text
    fontFamily: "Lato-Light",
    color: colors.midnight,

    // Remove defaults
    resize: "none",
    border: "none",
    outline: "none",
    boxShadow: "none"

    // I'm unsure if something like this is needed in react to remove boxShadow on all browsers
    // -webkit-box-shadow: none;
    // -moz-box-shadow: none;
  },

  textFade: {
    backgroundImage:
      "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
    height: "30px",
    width: "100%",
    position: "absolute",
    bottom: "0px",
    pointerEvents: "none" // allow the div to be clicked through
  },
  wordCount: {
    position: "absolute",
    right: "30px",
    fontFamily: "Lato-Medium",
    color: colors.text,
    fontSize: "14px"
  }
}

export default PYPInputBox
