import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import queryString from "query-string"
import colors from "../../Constants/colors.js"
import Actions from "../../Redux/Actions"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import FormHeader from "../FormHeader/FormHeader"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { Mail } from "../../Icons/Mail"

class Unsubscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unsubscribed: false,
      emailName: queryString.parse(props.history.location.search).emailName
    }
  }

  render() {
    let title
    let subtitle
    let button
    if (this.props.userLoggedIn) {
      if (this.state.unsubscribed === true) {
        title = "Thank you for your feedback"
        subtitle = "you have been unsubscribed."
      } else {
        title = "Unsubscribe"
        subtitle = "Click below to unsubscribe from video emails"
        button = (
          <CommitButtonWithClickState
            clickHandler={() => {
              this.props.unsubscribe(this.state.emailName)
              this.setState({
                unsubscribed: true
              })
            }}
            type="red"
            style={styles.unsubButton}
          >
            Unsubscribe
          </CommitButtonWithClickState>
        )
      }
    } else {
      title = "Redirecting to login"
      subtitle = "please login first"
    }
    return (
      <div style={styles.componentContainer}>
        <AccountFormContainer>
          <div style={styles.formContainer}>
            <Mail style={styles.mailIcon} />
            <FormHeader
              style={styles.formHeader}
              title={title}
              type="default"
              subTitle={subtitle}
            />
            {button}
          </div>
        </AccountFormContainer>
      </div>
    )
  }
}

const styles = {
  mailIcon: {
    marginBottom: "33px"
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  },
  formHeader: {
    marginTop: "33px"
  },
  unsubButton: {
    width: "154px",
    marginTop: "38px",
    marginBottom: "48px"
  },
  componentContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    marginTop: "-180px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0
  },
  loginButton: {
    borderRadius: "19px",
    border: "solid 1px #e1373a",
    color: colors.fadedRed,
    backgroundColor: "#ffffff",
    width: "128px"
  },
  footerText: {
    fontSize: "13px"
  },
  createButtonHover: {
    backgroundColor: "#ffffff",
    color: "#E1373A",
    borderColor: "#E1373A"
  },
  createButton: {
    marginTop: "25px",
    width: "26%",
    borderRadius: "3px",
    transition: "all 0.3s ease-in-out",
    backgroundColor: colors.fadedRed,
    color: colors.white
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    userLoggedIn: state.user.user.id != null
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Unsubscribe)
)
