import React from "react"
import { debounce } from "debounce"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import Actions from "../../Redux/Actions"
import UserFieldEditorNavMenu from "../UserFieldEditorNavMenu/UserFieldEditorNavMenu"
import ApplicationProgressCircle from "../SpouseApplicationProgressCircle/SpouseAppliacationProgressCircle"
import { allFormsFilled } from "../../Redux/Utils/reducerUtils"

class SpouseApplicationNavMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSubmitTooltip: false // whether or not to display the 'you must complete all sections' tooltip
    }
    this.navItemClickHandler = this.navItemClickHandler.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.getSubmitState = this.getSubmitState.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
  }

  handleClickOutside() {
    this.setState({
      showSubmitTooltip: false
    })
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  navItemClickHandler(id) {
    return debounce((e) => {
      this.props.application.currentInputData &&
        this.props.saveApplication(
          this.props.application.currentInputData,
          this.props.history
        )
    }, 200)
  }

  submitHandler() {
    if (!allFormsFilled(this.props.application.spouseApplicationMetadata)) {
      // Display the Tooltip modal
      this.setState({
        showSubmitTooltip: true
      })
    }
  }

  saveApplication(checkSubmittability, inputData) {
    return async (e) => {
      if (inputData) {
        inputData && checkSubmittability
          ? checkSubmittability(inputData) &&
            this.props.saveApplication(inputData, this.props.history)
          : await this.props.saveApplication(inputData, this.props.history)
      }
    }
  }

  getSubmitState() {
    if (allFormsFilled(this.props.application.spouseApplicationMetadata)) {
      if (this.props.application.application.has_submitted_application) {
        return "submitted"
      }
      return "finished"
    }
    if (this.props.application.application.has_submitted_application) {
      return "editing and unfinished"
    }
    return "unfinished"
  }

  render() {
    return (
      <UserFieldEditorNavMenu
        showSubmitTooltip={this.state.showSubmitTooltip}
        userFieldData={this.props.application.spouseApplicationMetadata}
        navItemClickHandler={this.navItemClickHandler}
        submitState={this.getSubmitState()}
        submitHandler={this.submitHandler}
        progressComponent={<ApplicationProgressCircle />}
        applicationIsComplete={allFormsFilled(
          this.props.application.spouseApplicationMetadata
        )}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state) => ({
  application: state.spouseApplication,
  user: state.user.user
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(SpouseApplicationNavMenu))
)
