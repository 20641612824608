import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import UserFieldEditorContainer from "../UserFieldEditorContainer/UserFieldEditorContainer"
import SpouseApplicationFooter from "../SpouseApplicationFooter/SpouseApplicationFooter"
import SpouseApplicationInputs from "../SpouseApplicationInputs/SpouseApplicationInputs"
import SpouseApplicationNavMenu from "../SpouseApplicationNavMenu/SpouseApplicationNavMenu"
import { spouseApplicationSteps } from "../../Redux/Reducers/SpouseApplication/spouseApplicationMetadata"
import Actions from "../../Redux/Actions"

import SpouseApplicationHeader from "./SpouseApplicationHeader"

import {
  setCurrentFormulary,
  getFormularyQuestions
} from "../../Redux/Actions/Formulary/functions"

const SpouseApplication = (props) => {
  const dispatch = useDispatch()
  const [inputData, setInputData] = useState({});

  const { applicationInitialized, submittingApplication, user } = useSelector(
    ({ spouseApplication, user: userState }) => ({
      applicationInitialized: spouseApplication.initialized,
      submittingApplication: spouseApplication.submittingApplication,
      user: userState?.user
    })
  )

  useEffect(() => {
    const pageTitle = queryString.parse(props.history.location.search).title
    const documentTitle = spouseApplicationSteps.find(
      (elem) => elem.stepId === pageTitle
    ).name
    document.title = `${documentTitle} |  Application Process`
    dispatch(Actions.fetchSpouseApplication("initialLoad", props.history))
  }, [])

  useEffect(() => {
    ;(async () => {
      const formularyType = "application"
      dispatch(setCurrentFormulary(formularyType, "Application"))
      await dispatch(getFormularyQuestions(formularyType))
    })()
  }, [])
  
  return (
    <>
      <UserFieldEditorContainer
        initialized={applicationInitialized && !submittingApplication}
        drawerComponent={() => <SpouseApplicationNavMenu />}
      >
        <SpouseApplicationHeader />
        <SpouseApplicationInputs setInputData={setInputData} />
        <SpouseApplicationFooter inputData={inputData} />
        </UserFieldEditorContainer>
    </>
  )
}

export default withRouter(SpouseApplication)
