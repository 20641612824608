import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"

const PYPPartDesktopFooterContent = ({
  decrementPartId,
  history,
  savePYP,
  currentPartComplete,
  incrementPartId,
  fetchingPartData
}) => {
  const nextHandler = () => {
    if (fetchingPartData) {
      return
    } // Nothing happens if data is still being fetched

    if (currentPartComplete) {
      savePYP()
      incrementPartId(history)
    }
  }

  const prevHandler = () => {
    if (!fetchingPartData) {
      savePYP()
      decrementPartId(history)
    }
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        position: "sticky",
        // marginTop: "2px",
        bottom: "0px"
      }}
    >
      <button
        id="pyp-previous-btn"
        type="white"
        style={{
          ...styles.mobilePrevButton,
          opacity: !fetchingPartData ? 1 : 0.4
        }}
        onClick={prevHandler}
      >
        Previous
      </button>
      <button
        id="pyp-next-btn"
        className="partBtnNext"
        type="red"
        style={{
          ...styles.mobileNextButton,
          opacity: !fetchingPartData && currentPartComplete ? 1 : 0.4
        }}
        onClick={nextHandler}
      >
        Next
      </button>
    </div>
  )
}

const styles = {
  mobilePrevButton: {
    width: "50%",
    height: "8.5vh",
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.white,
    fontFamily: "Lato-Regular",
    backgroundColor: colors.blueyGrey,
    border: "none"
  },
  mobileNextButton: {
    width: "50%",
    fontFamily: "Lato-Regular",
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "8.5vh",
    color: colors.white,
    backgroundColor: colors.fadedRed,
    border: "none"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    currentPartComplete: state.pyp.currentPartComplete,
    fetchingPartData: state.pyp.fetchingPartData || state.pyp.partIsLoading
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPPartDesktopFooterContent)
)
