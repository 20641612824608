import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import UserFieldEditorContainer from "../UserFieldEditorContainer/UserFieldEditorContainer"
import ApplicationFooter from "../ApplicationFooter/ApplicationFooter"
import ApplicationInputs from "../ApplicationInputs/ApplicationInputs"
import ApplicationNavMenu from "../ApplicationNavMenu/ApplicationNavMenu"
import { applicationSteps } from "../../Redux/Reducers/Application/applicationMetadata"
import Actions from "../../Redux/Actions"

import ApplicationHeader from "./ApplicationHeader"

import {
  setCurrentFormulary,
  getFormularyQuestions
} from "../../Redux/Actions/Formulary/functions"

const Application = (props) => {
  const dispatch = useDispatch()
  const [inputData, setInputData] = useState({});
  const { applicationInitialized, submittingApplication, user } = useSelector(
    ({ application, user: userState }) => ({
      applicationInitialized: application.initialized,
      submittingApplication: application.submittingApplication,
      user: userState?.user
    })
  )

  useEffect(() => {
    const pageTitle = queryString.parse(props.history.location.search).title
    const documentTitle = applicationSteps.find(
      (elem) => elem.stepId === pageTitle
    ).name
    document.title = `${documentTitle} |  Application Process`
    dispatch(Actions.fetchApplication("initialLoad", props.history))
  }, [])

  useEffect(() => {
    ;(async () => {
      const formularyType = "application"

      dispatch(setCurrentFormulary(formularyType, "Application"))
      await dispatch(getFormularyQuestions(formularyType))
    })()
  }, [])

  return (
    <>
      <UserFieldEditorContainer
        initialized={applicationInitialized && !submittingApplication}
        drawerComponent={() => <ApplicationNavMenu />}
      >
        <ApplicationHeader />
        <ApplicationInputs setInputData={setInputData} />
        <ApplicationFooter inputData={inputData} />
      </UserFieldEditorContainer>
    </>
  )
}

export default withRouter(Application)
