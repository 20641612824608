import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { WithDrawer } from "../WithDrawer/WithDrawer"
import colors from "../../Constants/colors"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import { LeftArrow } from "../../Icons/LeftArrow"
import MobileMenuButtomButtonBar from "../MobileMenuBottomButtonBar/MobileMenuBottomButtonBar"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

const styleSheet = {
  drawer: {
    width: "100%",
    height: "calc(100% - 60px)",
    backgroundColor: colors.midnight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "absolute"
  }
}

const SecondaryNavDrawer = (props) => {
  const loaderHeight = 40
  return (
    <>
      <div style={{ ...styles.container, ...props.style }}>
        <h1
          onClick={props.headerClickHandler || props.closeSecondary}
          style={{ ...styles.drawerTitle, ...props.titleStyles }}
        >
          <LeftArrow style={{ marginRight: "20px" }} stroke={colors.white} />{" "}
          {props.title}
        </h1>
        <div style={styles.optionsContainer}>
          {props.isLoading ? (
            <SkeletonWrapper height={500}>
              {new Array(6).fill().map((_, index) => (
                <rect
                  key={index}
                  x={40}
                  y={index * (loaderHeight * 2)}
                  width="80%"
                  height={loaderHeight}
                />
              ))}
            </SkeletonWrapper>
          ) : (
            props.navOptions
          )}
        </div>
        <MobileMenuButtomButtonBar clickHandler={props.bottomButtonHandler}>
          {props.bottomButtonComponent}
        </MobileMenuButtomButtonBar>
      </div>
    </>
  )
}

const styles = {
  drawerTitle: {
    color: colors.white,
    fontSize: "30px",
    fontFamily: "Lato-Bold",
    marginLeft: "40px",
    lineHeight: "46px",
    letterSpacing: "0.77px",
    display: "grid",
    gridTemplateColumns: "30px 1fr 20px",
    alignItems: "center"
  },
  container: {
    display: "grid",
    gridTemplateRows: "100px 1fr 9vh",
    height: "calc(100vh - 60px)",
    overflow: "auto"
  },
  optionsContainer: {
    marginBottom: "100px"
  }
}

SecondaryNavDrawer.propTypes = {
  navOptions: PropTypes.arrayOf(PropTypes.node).isRequired,
  title: PropTypes.string.isRequired,
  titleStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool
}

SecondaryNavDrawer.defaultProps = {
  isLoading: false
}

export default withRouter(
  WithDrawerConsumer(withStyles(styleSheet)(WithDrawer(SecondaryNavDrawer)))
)
