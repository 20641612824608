import React from "react"
import { withStyles } from "@material-ui/core/styles"
import colors from "../../Constants/colors"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

const styleSheet = {
  test: {
    color: colors.white,
    fontFamily: "Lato-SemiBold",
    fontSize: "20px",
    lineHeight: "33px",
    letterSpacing: "0.77px",
    padding: "0px"
  }
}

export const MobileWebNavItem = (props) => {
  return (
    <li
      onClick={props.onClick}
      style={{
        backgroundColor: colors.midnight,
        cursor: "pointer",
        lineHeight: 1.5,
        fontFamily: "Lato-SemiBold",
        display: "flex",
        flex: 1,
        padding: "10px 40px 10px 40px",
        fontSize: "20px",
        letterSpacing: "0.8px",
        color: "white",
        flexDirection: "column",
        justifyContent: "center",
        WebkitBoxOrient: "vertical",
        WebkitBoxDirection: "normal",
        borderBottom: "1px solid #787f8c"
      }}
    >
      <div 
        style={{
          color: colors.white,
          padding: "0px",
          paddingTop: "15px",
          paddingBottom: "15px",
          textDecoration: "none",
          position: "relative",
          backgroundColor: "transparent"
        }}
      >
        {" "}
        {props.children}{" "}
      </div>
    </li>
  )
}

export default WithDrawerConsumer(withStyles(styleSheet)(MobileWebNavItem))
