import React from "react"
import ContentLoader from "react-content-loader"
import "./SkeletonWrapper.css"

const SkeletonWrapper = ({
  theme,
  containerStyle,
  height,
  width,
  children
}) => {
  // Defaults to 'light' theme
  let primaryColor = "#e4e4ea"
  let secondaryColor = "#cbccce"

  if (theme === "dark") {
    primaryColor = "#11192F" // "#030b20"
    secondaryColor = "#051334"
  }

  return (
    <div
      className="skeletonWrapper"
      style={{ width: "100%", ...containerStyle }}
    >
      <ContentLoader
        height={height || 230}
        width={width || 600}
        speed={2}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      >
        {children}
      </ContentLoader>
    </div>
  )
}

export default SkeletonWrapper
