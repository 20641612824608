import React from "react"

export const Hamburger = (props) => {
  return (
    <svg
      onClick={props.onClick}
      style={{ ...props.style }}
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="20"
      viewBox="0 0 28 20"
    >
      <g fill="none" fillRule="nonzero">
        <path fill={props.fill || "#FFF"} d="M1.75 0H28l-1.75 3H0z" />
        <path fill={props.fill || "#FFF"} d="M1.75 9H28l-1.75 3H0z" />
        <path fill={props.fill || "#FFF"} d="M1.75 17H28l-1.75 3H0z" />
      </g>
    </svg>
  )
}
