import React, { useRef, useEffect, useState } from "react"
import "./ApplicationIntroduction.css"

import { Player, BigPlayButton } from "video-react"

const videoUrl =
  process.env.REACT_APP_APPLICATION_INTRODUCTION_VIDEO_URL || null

const ApplicationIntroduction = () => {
  const playerRef = useRef(null)
  const [hasStarted, setHasStarted] = useState(false)

  useEffect(() => {
    const player = playerRef?.current

    if (player) {
      player.subscribeToStateChange((state) => {
        setHasStarted(state.hasStarted)
      })
    }
  }, [])

  useEffect(() => {
    console.log("User has started video")
  }, [hasStarted])

  return (
    <div className="application-introduction-container">
      <Player ref={playerRef} src={videoUrl}>
        <BigPlayButton position="center" />
      </Player>
    </div>
  )
}

export default ApplicationIntroduction
