import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom"
import MediaQuery from "react-responsive"
import { useSelector, useDispatch } from "react-redux"

import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"
import CONSTANTS from "../../Constants"

import WebNavBar from "../WebNavbar/WebNavbar"
import ErrorRenderer from "../ErrorRenderer/ErrorRenderer"
import DigitalLibraryWrapper from "../DigitalLibraryWrapper/DigitalLibraryWrapper"
import Application from "../Application/Application"
import SpouseApplication from "../SpouseApplication/SpouseApplication"
import NonDodIcApplication from "../NonDodIcApplication/NonDodIcApplication"
import AlliedForcesApplication from "../AlliedForcesApplication/AlliedForcesApplication"
import ForgotPassword from "../ForgotPassword/ForgotPassword"
import PasswordResetSent from "../PasswordResetSent/PasswordResetSent"
import ResetPassword from "../ResetPassword/ResetPassword"
import Unsubscribe from "../Unsubscribe/Unsubscribe"
import ApplicationNavBar from "../ApplicationNavBar/ApplicationNavBar"
import AccountContent from "../AccountContent/AccountContent"
import VideoEditor from "../VideoEditorContainer/VideoEditorContainer"
import MobileWebNavDrawer from "../MobileWebNavDrawer/MobileWebNavDrawer"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import PursueYourPurpose from "../PursueYourPurpose/PursueYourPurpose"
import ApplicationStatus from "../ApplicationStatus/ApplicationStatus"
import LandingPage from "../LandingPage/LandingPage"
import FourOhFour from "../FourOhFour/FourOhFour"
import CreateAccount from "../CreateAccount/CreateAccount"
import Login from "../Login/Login"
import CompetitiveTierRedirect from "../CompetitiveTierRedirect/CompetitiveTierRedirect"
import IncompleteFormularyRedirect from "../IncompleteFormularyRedirect"
import MainMobileNavDrawer from "../MainMobileNavDrawer/MainMobileNavDrawer"
import UserOrSpinner from "../UserOrSpinner/UserOrSpinner"
import Analytics from "../Analytics/Analytics"
import AdminErrorRenderer from "../AdminErrorRenderer/AdminErrorRenderer"
import NetworkPage from "../NetworkPage/NetworkPage"
import Profile from "../Profile/Profile"
import ApiTimeoutModal from "../ApiTimeoutModal/ApiTimeoutModal"
import ProgramDisabledModal from "../ProgramDisabledModal/ProgramDisabledModal"
import AttendeesScreen from "../AttendeesScreen/AttendeesScreen"
import PrivacyPolicyModal, {
  modalUsi as privacyPolicyModalUsi
} from "../PrivacyPolicyModal/PrivacyPolicyModal"
import PreSurvey from "../PreSurvey"
import SimpleModal from "../SimpleModal"
import { ScheduleDVSModal, UpdateProfileModal } from "../Modals"
import PostSurvey from "../PostSurvey"
import SpouseCourse from "../SpouseCourse/SpouseCourse"

import "./CommitApp.css"

const applicationNavBarRoutes = [
  { path: "/workshop/:module", exact: true },
  { path: "/application/status", exact: true },
  { path: "/digitalLibrary/search/:searchTerm" },
  { path: "/digitalLibrary/*" },
  { path: "/home" },
  { path: "/analytics" },
  { path: "/digitalLibraryAdmin" },
  { path: "/network" },
  { path: "/attendees" },
  { path: "/application", desktopOnly: true },
  { path: "/profile", desktopOnly: true },
  { path: "/pre-survey", desktopOnly: true },
  { path: "/post-survey", desktopOnly: true },
  { path: "/virtualcohort", exact: true }
]

const mainMobileNavDrawerRoutes = [
  { path: "/home" },
  { path: "/analytics" },
  { path: "/application" },
  { path: "/network" },
  { path: "/profile" },
  { path: "/attendees" },
  { path: "/digitalLibrary/:action" },
  { path: "/workshop/:module", exact: true },
  { path: "/pre-survey", formularyRoute: true },
  { path: "/post-survey", formularyRoute: true },
  { path: "/virtualcohort", exact: true }
]

const CommitApp = () => {
  const dispatch = useDispatch()

  const { applicationSubmitted, user, token } = useSelector((state) => ({
    applicationSubmitted: state?.user?.user?.has_submitted_application,
    user: state.user?.user,
    token: state.api?.apiToken
  }))

  useEffect(() => {
    const hasLoadedUser = Boolean(Object.keys(user).length)
    const hasPrivacyPolicy = hasLoadedUser && Boolean(user.privacy_policy)

    if (hasLoadedUser && !hasPrivacyPolicy) {
      dispatch(
        Actions.toggleModal({
          modal_mode: true,
          modal_usi: privacyPolicyModalUsi
        })
      )
    }
  }, [user, dispatch])

  useEffect(() => {
    dispatch(Actions.fetchUser())
  }, [dispatch])

  useEffect(() => {
    if (token) {
      dispatch(Actions.retrieveAllPYPModules())
    }
  }, [token])

  return (
    <Router>
      <>
        <div className="contentContainer" id="Content-Container">
          <div
            className="content"
            style={{
              width: "100%",
              zIndex: "1030",
              top: "0px",
              position: "fixed"
            }}
          >
            <Switch>
              {applicationNavBarRoutes.map(
                ({ path, exact = false, desktopOnly = false }) => (
                  <Route
                    key={path}
                    path={path}
                    exact={exact}
                    render={() =>
                      desktopOnly ? (
                        <MediaQuery maxWidth={768}>
                          <ApplicationNavBar />
                        </MediaQuery>
                      ) : (
                        <ApplicationNavBar />
                      )
                    }
                  />
                )
              )}
              <Route path="/account/*" render={() => <WebNavBar />} />
            </Switch>
            {mainMobileNavDrawerRoutes.map(
              ({ path, exact = false, formularyRoute = false }) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  render={() => (
                    <MainMobileNavDrawer formularyRoute={formularyRoute} />
                  )}
                />
              )
            )}
          </div>
          <Route path="/account/*" render={() => <MobileWebNavDrawer />} />
          <div
            className="content"
            style={{
              backgroundColor: colors.background,
              width: "100%"
            }}
          >
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/home" />} />
              <Route
                exact
                path="/home"
                render={() => (
                  <ErrorRenderer>
                    <IncompleteFormularyRedirect>
                      <CompetitiveTierRedirect>
                        <ApiTimeoutModal />
                        <ProgramDisabledModal />
                        <UpdateProfileModal />
                        <ScheduleDVSModal />
                        <LandingPage />
                      </CompetitiveTierRedirect>
                    </IncompleteFormularyRedirect>
                  </ErrorRenderer>
                )}
              />
              <Route
                exact
                path="/network"
                render={() => (
                  <ErrorRenderer>
                    <CompetitiveTierRedirect>
                      <NetworkPage />
                    </CompetitiveTierRedirect>
                  </ErrorRenderer>
                )}
              />
              <Route
                exact
                path="/attendees"
                render={() => <AttendeesScreen />}
              />
              <Route
                exact
                path="/digitalLibrary"
                render={() => <Redirect to="/digitalLibrary/home" />}
              />
              <Route
                exact
                path="/account/createAccount"
                render={() => (
                  <AccountContent>
                    <CreateAccount />
                  </AccountContent>
                )}
              />
              <Route
                path="/account/login"
                render={() => (
                  <AccountContent>
                    <Login />
                  </AccountContent>
                )}
              />
              <Route
                exact
                path="/application/spouse"
                render={() => (
                  <ErrorRenderer
                    customRedirectUri={CONSTANTS.SPOUSE_APPLICATION_PROCESS_FIRST_PAGE}
                  >
                    <ApiTimeoutModal />
                    <ProgramDisabledModal />
                    <SpouseApplication />
                  </ErrorRenderer>
                )}
              />
              <Route
                exact
                path="/application"
                render={() => (
                  <ErrorRenderer
                    customRedirectUri={CONSTANTS.APPLICATION_PROCESS_FIRST_PAGE}
                  >
                    <ApiTimeoutModal />
                    <ProgramDisabledModal />
                    <Application />
                  </ErrorRenderer>
                )}
              />
              <Route
                exact
                path="/application/non-dod-ic"
                render={() => (
                  <ErrorRenderer
                    customRedirectUri={CONSTANTS.NON_DOD_IC_APPLICATION_PROCESS_FIRST_PAGE}
                  >
                    <ApiTimeoutModal />
                    <ProgramDisabledModal />
                    <NonDodIcApplication />
                  </ErrorRenderer>
                )}
              />
              <Route
                exact
                path="/application/allied-forces"
                render={() => (
                  <ErrorRenderer
                    customRedirectUri={CONSTANTS.ALLIED_FORCES_APPLICATION_PROCESS_FIRST_PAGE}
                  >
                    <ApiTimeoutModal />
                    <ProgramDisabledModal />
                    <AlliedForcesApplication />
                  </ErrorRenderer>
                )}
              />
              <Route exact path="/profile" render={() => <Profile />} />
              <Route
                path="/account/forgotPassword"
                render={() => (
                  <AccountContent>
                    <ForgotPassword />
                  </AccountContent>
                )}
              />
              <Route
                path="/account/setNewPassword"
                render={() => (
                  <AccountContent>
                    <ForgotPassword
                      title="Need to Set your Password?"
                      subTitle={
                        "Enter your email below and we'll send you a link to set your password!"
                      }
                    />
                  </AccountContent>
                )}
              />
              <Route
                path="/account/passwordResetSent"
                render={() => (
                  <AccountContent>
                    <PasswordResetSent />
                  </AccountContent>
                )}
              />
              {/* /resetPassword is deprecated, should keep this in for a few months for users caches. MB 06/17/19 */}
              <Route
                path="/account/resetPassword/:token"
                render={() => <Redirect to="/account/setPassword/:token" />}
              />
              <Route
                path="/account/setPassword/:token"
                render={() => (
                  <AccountContent>
                    <ResetPassword />
                  </AccountContent>
                )}
              />
              <Route
                path="/application/status"
                render={() => (
                  <ErrorRenderer>
                    <AccountContent>
                      <UserOrSpinner>
                        <ApplicationStatus />
                      </UserOrSpinner>
                    </AccountContent>
                  </ErrorRenderer>
                )}
              />
              <Route
                path="/account/unsubscribe"
                render={() => (
                  <ErrorRenderer>
                    <AccountContent>
                      <Unsubscribe />
                    </AccountContent>
                  </ErrorRenderer>
                )}
              />
              <Route
                path="/digitalLibraryAdmin"
                render={() => (
                  <AdminErrorRenderer>
                    <AccountContent>
                      <VideoEditor />
                    </AccountContent>
                  </AdminErrorRenderer>
                )}
              />
              <Route
                path="/digitalLibrary/*"
                render={() => (
                  <ErrorRenderer>
                    <CompetitiveTierRedirect>
                      <ApiTimeoutModal />
                      <ProgramDisabledModal />
                      <DigitalLibraryWrapper />
                    </CompetitiveTierRedirect>
                  </ErrorRenderer>
                )}
              />
              <Route
                path="/analytics"
                render={() => (
                  <ErrorRenderer>
                    <AdminErrorRenderer>
                      <Analytics />
                    </AdminErrorRenderer>
                  </ErrorRenderer>
                )}
              />
              {/* Spouse Course */}
              <Route
                path="/virtualcohort"
                render={() => (
                  <ErrorRenderer>
                    <CompetitiveTierRedirect>
                      <ApiTimeoutModal />
                      <ProgramDisabledModal />
                      <SpouseCourse />
                    </CompetitiveTierRedirect>
                  </ErrorRenderer>
                )}
              />
              <Route
                path="/workshop/:module"
                render={() => (
                  <ErrorRenderer>
                    <CompetitiveTierRedirect>
                      <ApiTimeoutModal />
                      <ProgramDisabledModal />
                      <PursueYourPurpose />
                    </CompetitiveTierRedirect>
                  </ErrorRenderer>
                )}
              />
              <Route
                path="/pre-survey"
                render={() => (
                  <ErrorRenderer>
                    <PreSurvey />
                  </ErrorRenderer>
                )}
              />
              <Route
                path="/post-survey"
                render={() => (
                  <ErrorRenderer>
                    <PostSurvey />
                  </ErrorRenderer>
                )}
              />
              <Route
                path="*"
                render={() => (
                  <FourOhFour applicationSubmitted={applicationSubmitted} />
                )}
              />
            </Switch>
          </div>
        </div>
        <PrivacyPolicyModal />
        <SimpleModal />
      </>
    </Router>
  )
}

export default WithDrawerConsumer(CommitApp)
