import { normalize, schema } from "normalizr"
import types from "../types"
import fetch from "../../../Utils/fetch"

const videoSchema = new schema.Entity("videos", {})
const categorySchema = new schema.Entity("categories", {})

const typeSchema = new schema.Entity("types", {
  videos: [videoSchema]
})

export const getVideoLikeStatus = (videoId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_LIKE_STATUS
      })

      const likeStatus = await dispatch(
        fetch(`/userVideoLikes`, {
          type: "GET",
          data: { params: { videoId } }
        })
      )
      dispatch({
        type: types.CURRENT_VIDEO_LIKE_STATUS,
        data: {
          likeStatus: likeStatus.data[0]
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const fetchPlaylistById = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.CLEAR_TRANSITIONS
      })
      const res = await dispatch(
        fetch(`/videoPlaylists/${id}`, {
          type: "GET"
        })
      )
      const videoIds = []

      const playlist = res.data
      if (playlist.video_ids) {
        playlist.video_ids
          .split(",")
          .forEach((id) => videoIds.push(parseInt(id)))
      }
      playlist.videos = videoIds

      const videos = await dispatch(
        fetch(`/listOfVideos`, {
          type: "POST",
          data: { requestPayload: { videoIds } }
        })
      )

      playlist.videos =
        playlist.videos &&
        playlist.videos.map(
          (id) => videos.data.filter((video) => video.id === id)[0]
        )

      dispatch({
        type: types.STORE_SINGLE_PLAYLIST,
        data: {
          playlist
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const fetchPlaylists = (filter) => {
  return async (dispatch, getState) => {
    try {
      const res = await dispatch(
        fetch(`/videoPlaylists/filter/${filter}`, {
          type: "GET"
        })
      )
      const videoIds = []
      const playlists = res.data.map((playlist) => {
        if (playlist.video_ids) {
          playlist.video_ids
            .split(",")
            .forEach((id) => videoIds.push(parseInt(id)))
        }
        return {
          ...playlist,
          videos:
            playlist.video_ids &&
            playlist.video_ids.split(",").map((id) => parseInt(id))
        }
      })
      const videos = await dispatch(
        fetch(`/listOfVideos`, {
          type: "POST",
          data: { requestPayload: { videoIds } }
        })
      )
      playlists.forEach((playlist) => {
        playlist.videos =
          playlist.videos &&
          playlist.videos.map(
            (id) => videos.data.filter((video) => video.id === id)[0]
          )
      })
      dispatch({
        type: types.STORE_TRANSITION_PLAYLIST,
        data: {
          transitionPlaylists: playlists
        }
      })
    } catch (e) {
      console.log(e, "ERROR")
    }
  }
}

export const hideSearchBar = () => {
  return (dispatch, getState) => {
    try {
      dispatch({
        type: types.HIDE_SEARCH_BAR
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const showSearchBar = () => {
  return (dispatch, getState) => {
    try {
      dispatch({
        type: types.SHOW_SEARCH_BAR
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const postVideoLike = (videoId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/userVideoLikes`, {
          type: "POST",
          data: { requestPayload: { videoId, likeValue: 1 } }
        })
      )

      const likeStatus = await dispatch(
        fetch(`/userVideoLikes`, {
          type: "GET",
          data: { params: { videoId } }
        })
      )

      dispatch({
        type: types.CURRENT_VIDEO_LIKE,
        data: {
          likeStatus: likeStatus.data[0]
        }
      })
      return
    } catch (e) {
      console.log(e)
    }
  }
}

export const postVideoDislike = (videoId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/userVideoLIkes`, {
          type: "POST",
          data: { requestPayload: { videoId, likeValue: -1 } }
        })
      )

      const likeStatus = await dispatch(
        fetch(`/userVideoLikes`, {
          type: "GET",
          data: { params: { videoId } }
        })
      )
      dispatch({
        type: types.CURRENT_VIDEO_DISLIKE,
        data: {
          likeStatus: likeStatus.data[0]
        }
      })
      return
    } catch (e) {
      console.log(e)
    }
  }
}

export const postVideoLikeReset = (videoId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/userVideoLIkes`, {
          type: "POST",
          data: { requestPayload: { videoId, likeValue: 0 } }
        })
      )

      const likeStatus = await dispatch(
        fetch(`/userVideoLikes`, {
          type: "GET",
          data: { params: { videoId } }
        })
      )
      dispatch({
        type: types.CURRENT_VIDEO_LIKE_RESET,
        data: {
          likeStatus: likeStatus.data[0]
        }
      })
      return
    } catch (e) {
      console.log(e)
    }
  }
}

export const addToRecentlyWatched = (videoId) => {
  return async (dispatch, getState) => {
    try {
      const res = await dispatch(
        fetch(`/recentlyWatched`, {
          type: "POST",
          data: { requestPayload: { videoId } }
        })
      )
      dispatch({
        type: types.ADD_TO_RECENTLY_WATCHED,
        data: res.data
      })
    } catch (data) {
      dispatch({
        type: types.ERROR,
        message: data,
        data: data.response
      })
    }
  }
}

export const initializeDigitalLibrary = ({ type, action }) => {
  return async (dispatch, getState) => {
    try {
      // TODO: should make this all get loaded async. no need to even have this dumb initialization function
      // it would get rid of that recommended flash. might also appear faster.

      const typesRes = await dispatch(
        fetch(`/types`, {
          type: "GET"
        })
      )

      const typesWithVideos = [
        {
          id: 4,
          name: "Recommended",
          description: ``,
          videos: []
        }
      ]
        .concat(typesRes.data)
        .map((type) => {
          return {
            ...type,
            videos: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          }
        })

      const normalizedTypes = normalize(
        {
          types: typesWithVideos,
          videos: [
            { id: 1, type_id: 1 },
            { id: 1, type_id: 2 },
            { id: 1, type_id: 3 }
          ]
        },
        {
          types: [typeSchema],
          videos: [videoSchema]
        }
      )

      dispatch({
        type: types.INITIALIZED_DIGITAL_LIBRARY,
        data: {
          navigationItems: typesRes.data,
          types: {
            allIds: normalizedTypes.result.types,
            byId: normalizedTypes.entities.types
          },
          initialized: true
        }
      })
    } catch (e) {
      if (
        e.response &&
        e.response.status === 401 &&
        e.response.data.message === `User isn't authorized for Digital Library`
      ) {
        dispatch({
          type: types.INITIALIZED_DIGITAL_LIBRARY,
          data: {}
        })
      }

      dispatch({
        type: types.ERROR,
        message: e,
        data: e.response
      })
    }
  }
}

export const searchVideosBySearchTerm = ({ searchTerm, page }) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.FETCHING_VIDEOS
      })

      const videos = await dispatch(
        fetch(
          `/searchVideos`,
          {
            type: "POST",
            data: { requestPayload: { searchTerm, page } }
          },
          {
            timeout: 20000
          }
        )
      )

      const n = normalize(
        {
          videos: videos.data.data
        },
        {
          videos: [videoSchema]
        }
      )

      dispatch({
        type: types.FETCHED_VIDEOS,
        data: {
          fetchingVideos: false,
          videos: {
            allIds: n.result.videos,
            byId: n.entities.videos,
            page: videos.data.page,
            lastPage: videos.data.lastPage,
            perPage: videos.data.perPage,
            total: videos.data.total
          }
        }
      })
    } catch (e) {
      dispatch({
        type: types.ERROR,
        message: e,
        data: e.response
      })
    }
  }
}

export const fetchOneVideo = ({ videoId }) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_VIDEOS
      })
      const res = await dispatch(
        fetch(`/video`, {
          type: "GET",
          data: { params: { videoId } }
        })
      )

      dispatch({
        type: types.SET_WATCHING_VIDEOS,
        data: {
          currentVideo: res.data[0]
        }
      })
    } catch (e) {
      dispatch({
        type: types.ERROR,
        data: e.response,
        message: e
      })
    }
  }
}

export const addToPlaylist = (videoId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/playlist`, {
          type: "POST",
          data: { requestPayload: { videoId } }
        })
      )
    } catch (e) {
      dispatch({
        type: types.ERROR,
        data: e.response,
        message: e
      })
    }
  }
}

// Specifically used to set the user's playlist and pagination data
export const setMyPlaylist = (playlist, page) => {
  return async (dispatch, getState) => {
    try {
      const n = normalize(
        {
          videos: playlist
        },
        {
          videos: [videoSchema]
        }
      )

      dispatch({
        type: types.FETCHED_VIDEOS,
        data: {
          videos: {
            allIds: n.result.videos,
            byId: n.entities.videos
          }
        }
      })
    } catch (e) {
      dispatch({
        type: types.ERROR,
        data: e.response,
        message: e
      })
    }
  }
}

export const removeFromPlaylist = (videoId) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/playlist`, {
          type: "DELETE",
          data: { requestPayload: { videoId } }
        })
      )
    } catch (e) {
      dispatch({
        type: types.ERROR,
        data: e.response,
        message: e
      })
    }
  }
}

export const fetchRecommendedVideos = (type, page) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCHING_VIDEOS
    })

    const recommendedVideos = await dispatch(
      fetch(
        `/searchVideos`,
        {
          type: "POST",
          data: { requestPayload: { is_recommended: true, limit: 20, page: 1 } }
        },
        {
          timeout: 20000
        }
      )
    )

    const n = normalize(
      {
        videos: recommendedVideos.data.data
      },
      {
        videos: [videoSchema]
      }
    )
    dispatch({
      type: types.FETCHED_VIDEOS,
      data: {
        videos: {
          allIds: n.result.videos,
          byId: n.entities.videos,
          page: recommendedVideos.data.page,
          lastPage: recommendedVideos.data.lastPage,
          perPage: recommendedVideos.data.perPage,
          total: recommendedVideos.data.total
        }
      }
    })
  }
}

const videoFetchingMap = {
  recommended: async (dispatch) => {
    const recommendedVideos = await dispatch(
      fetch(
        `/searchVideos`,
        {
          type: "POST",
          data: { requestPayload: { is_recommended: true, limit: 20, page: 1 } }
        },
        {
          timeout: 20000
        }
      )
    )

    // Have to format the data for normalizr
    // const types = [
    //   {
    //     id: 4,
    //     name: "Recommended",
    //     description: ``,
    //     videos: recommendedVideos.data.data.data
    //   }
    // ].concat(
    //   videos.data.map(datum => {
    //     return {
    //       ...datum.type,
    //       videos: datum.videos
    //     }
    //   })
    // )
    const n = normalize(
      {
        //        types: types,
        // videos: accumulatedVideos
        videos: recommendedVideos.data.data.data
      },
      {
        // types: [typeSchema],
        videos: [videoSchema]
      }
    )
    return {
      // types: {
      //   allIds: n.result.types,
      //   byId: n.entities.types
      // },
      videos: {
        allIds: n.result.videos,
        byId: n.entities.videos,
        page: recommendedVideos.data.data.page,
        lastPage: recommendedVideos.data.data.lastPage,
        perPage: recommendedVideos.data.data.perPage,
        total: recommendedVideos.data.data.total
      }
    }
  },
  home: async (dispatch) => {
    const videos = await dispatch(
      fetch(
        `/searchVideos`,
        {
          type: "POST",
          data: { requestPayload: { organize_by_types: true, limit: 10 } }
        },
        {
          timeout: 20000
        }
      )
    )

    const recommendedVideos = await dispatch(
      fetch(
        `/searchVideos`,
        {
          type: "POST",
          data: { requestPayload: { is_recommended: true, limit: 20, page: 1 } }
        },
        {
          timeout: 20000
        }
      )
    )
    // Have to format the data for normalizr
    const types = [
      {
        id: 4,
        name: "Recommended",
        description: ``,
        videos: recommendedVideos.data.data
      }
    ].concat(
      videos.data.map((datum) => {
        return {
          ...datum.type,
          videos: datum.videos
        }
      })
    )

    const accumulatedVideos = videos.data
      .reduce((accum, val, i) => {
        return accum.concat(val.videos)
      }, [])
      .concat(recommendedVideos.data.data)

    const n = normalize(
      {
        types,
        videos: accumulatedVideos
      },
      {
        types: [typeSchema],
        videos: [videoSchema]
      }
    )

    return {
      types: {
        allIds: n.result.types,
        byId: n.entities.types
      },
      videos: {
        allIds: n.result.videos,
        byId: n.entities.videos
      }
    }
  },
  my_videos: async (dispatch, getState) => {
    const playlist = getState().user.user.playlist.map((video) => {
      return {
        ...video,
        // Currently, if a video is in a user's playlist, he/she would've had to have seen it.
        // This doesn't feel like a stable solution to this - but it's quick and performant.
        // To rewrite in a proper way, you'll need to update what gets passed through the API
        userWatches: [{}],
        type_id: 1
      }
    })

    const recentlyWatched = getState().user.user.recentlyWatched.map(
      (video) => {
        return {
          ...video,
          // Currently, if a video is in a user's recently watched, he/she would've had to have seen it.
          // This doesn't feel like a stable solution to this - but it's quick and performant.
          // To rewrite in a proper way, you'll need to update what gets passed through the API
          userWatches: [{}],
          type_id: 2
        }
      }
    )

    const accumulatedVideos = playlist.concat(recentlyWatched)
    const videoTypes = [
      { id: 1, name: "Recently Watched", videos: recentlyWatched },
      { id: 2, name: "My Playlist", videos: playlist }
    ]

    const n = normalize(
      {
        types: videoTypes,
        videos: accumulatedVideos
      },
      {
        types: [typeSchema],
        videos: [videoSchema]
      }
    )

    return {
      types: {
        allIds: n.result.types,
        byId: n.entities.types
      },
      videos: {
        allIds: n.result.videos,
        byId: n.entities.videos
      }
    }
  },
  default: async (dispatch, getState, page) => {
    const dLState = getState().digitalLibrary

    const videoTypes = dLState.types.allIds.map(
      (typeId) => dLState.types.byId[typeId]
    )

    const matchedCategory =
      dLState.currentCategories &&
      dLState.currentCategories.find((category) => {
        return category.id === getState().digitalLibrary.currentCategoryId
      })

    const matchedType = videoTypes.find((type) => {
      return type.id === getState().digitalLibrary.typeId
    })

    const categoryId = matchedCategory ? matchedCategory.id : undefined
    const typeId = matchedType ? matchedType.id : undefined

    const videos = await dispatch(
      fetch(
        `/searchVideos`,
        {
          type: "POST",
          data: {
            requestPayload: {
              typeId,
              limit: 20,
              categoryId,
              page
            }
          }
        },
        {
          timeout: 20000
        }
      )
    )

    const n = normalize(
      {
        videos: videos.data.data
      },
      {
        videos: [videoSchema]
      }
    )

    return [
      {
        videos: {
          allIds: n.result.videos,
          byId: n.entities.videos
        }
      }
    ]
  }
}

export const loadVideos = (type, page) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCHING_VIDEOS
    })

    const videoDataFn = videoFetchingMap[type] || videoFetchingMap.default
    const videoData = await videoDataFn(dispatch, getState, page)

    dispatch({
      type: types.FETCHED_VIDEOS_TYPES,
      data: videoData
    })
  }
}

export const fetchMoreSearchResults = (limit, page, searchTerm) => {
  return async (dispatch, getState) => {
    const dLState = getState().digitalLibrary
    dispatch({
      type: types.FETCHING_VIDEOS
    })

    const videos = await dispatch(
      fetch(`/searchVideos`, {
        type: "POST",
        data: { requestPayload: { searchTerm, page } }
      })
    )

    const allCurrentVideos = dLState.videos.allIds.map(
      (videoId) => dLState.videos.byId[videoId]
    )

    const n = normalize(
      {
        videos: allCurrentVideos.concat(videos.data.data)
      },
      {
        videos: [videoSchema]
      }
    )

    const videoData = {
      videos: {
        allIds: [...new Set(n.result.videos)],
        byId: n.entities.videos,
        page: videos.data.page,
        lastPage: videos.data.lastPage,
        perPage: videos.data.perPage,
        total: videos.data.total
      }
    }

    dispatch({
      type: types.LOAD_MORE_VIDEOS,
      data: videoData
    })
  }
}

export const fetchMoreRecommendedVideos = (limit, page) => {
  return async (dispatch, getState) => {
    const dLState = getState().digitalLibrary

    dispatch({
      type: types.FETCHING_VIDEOS
    })

    const videos = await dispatch(
      fetch(`/searchVideos`, {
        type: "POST",
        data: { requestPayload: { is_recommended: true, limit: 20, page } }
      })
    )

    const allCurrentVideos = dLState.videos.allIds.map(
      (videoId) => dLState.videos.byId[videoId]
    )

    const n = normalize(
      {
        videos: allCurrentVideos.concat(videos.data.data)
      },
      {
        videos: [videoSchema]
      }
    )

    const videoData = {
      videos: {
        allIds: [...new Set(n.result.videos)],
        byId: n.entities.videos,
        page: videos.data.page,
        lastPage: videos.data.lastPage,
        perPage: videos.data.perPage,
        total: videos.data.total
      }
    }

    dispatch({
      type: types.LOAD_MORE_VIDEOS,
      data: videoData
    })
  }
}

export const loadMoreVideos = (limit, page) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCHING_VIDEOS
    })

    const dLState = getState().digitalLibrary

    const videoTypes = dLState.types.allIds.map(
      (typeId) => dLState.types.byId[typeId]
    )
    const allCurrentVideos = dLState.videos.allIds.map(
      (videoId) => dLState.videos.byId[videoId]
    )

    const videoCategories = dLState.categories.allIds.map(
      (categoryId) => dLState.categories.byId[categoryId]
    )

    const matchedCategory =
      videoCategories &&
      videoCategories.find((category) => {
        return category.id === getState().digitalLibrary.currentCategoryId
      })

    const matchedType = videoTypes.find((type) => {
      return type.id === getState().digitalLibrary.currentTypeId
    })

    const categoryId = matchedCategory ? matchedCategory.id : undefined
    const typeId = matchedType ? matchedType.id : undefined
    const videos = await dispatch(
      fetch(`/searchVideos`, {
        type: "POST",
        data: {
          requestPayload: {
            typeId,
            limit: limit || 20,
            categoryId: categoryId !== 0 ? categoryId : undefined,
            page
          }
        }
      })
    )

    const n = normalize(
      {
        videos: allCurrentVideos.concat(videos.data.data)
      },
      {
        videos: [videoSchema]
      }
    )

    const videoData = {
      videos: {
        allIds: [...new Set(n.result.videos)],
        byId: n.entities.videos,
        page: videos.data.page,
        lastPage: videos.data.lastPage,
        perPage: videos.data.perPage,
        total: videos.data.total
      }
    }

    dispatch({
      type: types.LOAD_MORE_VIDEOS,
      data: videoData
    })
  }
}

export const clearReduxVideos = (data) => {
  return async (dispatch, getState) => {
    // const categories = {
    //   data: [{}, {}, {}, {}, {}]
    // }

    // const currentVideo = { title: "", type: { name: "" } }

    const videos = [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
      { id: 8 },
      { id: 9 },
      { id: 10 }
    ]

    const videoTypes = getState().digitalLibrary.types

    const f =
      videoTypes.allIds &&
      videoTypes.allIds.map((typeId) => {
        const type = videoTypes.byId[typeId]
        return {
          ...type,
          videos: [1, 2, 3, 4, 5, 6]
        }
      })

    const n = normalize(
      {
        types: f,
        videos
      },
      {
        types: [typeSchema],
        videos: [videoSchema]
      }
    )

    dispatch({
      type: types.CLEAR_VIDEOS,
      data: {
        types: {
          allIds: n.result.types,
          byId: n.entities.types
        },
        videos: {
          allIds: n.result.videos,
          byId: n.entities.videos
        }
      }
    })
  }
}

export const loadVideosWithTypeAndCategory = (typeName, categoryName, page) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.FETCHING_VIDEOS
    })

    const typesRes = await dispatch(
      fetch(`/types`, {
        type: "GET"
      })
    )

    const typesWithVideos = [
      {
        id: 4,
        name: "Recommended",
        description: ``,
        videos: []
      }
    ]
      .concat(typesRes.data)
      .map((type) => {
        return {
          ...type,
          videos: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        }
      })

    // todo: typename should check an array of invalid typenames, not like this. could also just make a whole new function.
    // const videoTypes = dLState.types.allIds.map(
    //   typeId => dLState.types.byId[typeId]
    // )

    const matchedType = typesRes.data.find((type) => {
      return type.name.toLowerCase() === typeName
    })

    const typeId = matchedType ? matchedType.id : undefined

    const categories = await dispatch(
      fetch(`/getCategoriesByType`, {
        type: "GET",
        data: { params: { typeId: typeId !== 4 ? typeId : undefined } }
      })
    )

    const matchedCategory =
      categories.data &&
      categories.data.find((category) => {
        return category.name.toLowerCase() === categoryName
      })

    // todo: all this undefined shit is a little confusing - shoudl make the code more readable
    const categoryId = matchedCategory
      ? matchedCategory.id
      : categoryName === "all"
      ? 0
      : undefined

    const videos = await dispatch(
      fetch(`/searchVideos`, {
        type: "POST",
        data: {
          requestPayload: {
            typeId: typeId !== 4 ? typeId : undefined,
            limit: 20,
            categoryId: categoryId !== 0 ? categoryId : undefined,
            page,
            is_recommended: typeName === "recommended" ? true : undefined
          }
        }
      })
    )

    const n = normalize(
      {
        types: typesWithVideos,
        videos: videos.data.data,
        categories: [{ name: "All", id: 0 }]
          .concat(categories.data)
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      },
      {
        types: [typeSchema],
        videos: [videoSchema],
        categories: [categorySchema]
      }
    )

    dispatch({
      type: types.FETCHED_VIDEOS,
      data: {
        videos: {
          allIds: n.result.videos,
          byId: n.entities.videos,
          page: videos.data.page,
          lastPage: videos.data.lastPage,
          perPage: videos.data.perPage,
          total: videos.data.total
        },
        categories: {
          allIds: n.result.categories,
          byId: n.entities.categories
        },
        currentCategoryId: categoryId,
        currentTypeId: typeId,
        types: {
          allIds: n.result.types,
          byId: n.entities.types
        }
      }
    })
  }
}

export const clearCategoryAndVideos = (data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CLEAR_VIDEOS,
      data
    })
  }
}

export const fetchSidebarVideos = (video) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_VIDEOS
      })

      const recVideos = await dispatch(
        fetch(`/relatedVideos`, {
          type: "GET",
          data: {
            params: {
              pathHistory: getState().user.pathHistory,
              curVideoId: video.id,
              limit: 10
            }
          }
        })
      )

      const n = normalize(
        {
          videos: recVideos.data
        },
        {
          videos: [videoSchema]
        }
      )

      dispatch({
        type: types.FETCHED_VIDEOS,
        data: {
          videos: {
            allIds: n.result.videos,
            byId: n.entities.videos,
            page: recVideos.data.page,
            lastPage: recVideos.data.lastPage,
            perPage: recVideos.data.perPage,
            total: recVideos.data.total
          }
        }
      })
    } catch (e) {
      dispatch({
        type: types.ERROR,
        data: e.response,
        message: e
      })
    }
  }
}

export const getRecommendedVideos = (data) => {
  // data.numberOfVideos
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.FETCHING_VIDEOS
      })

      const videos = await dispatch(
        fetch(`/searchVideos`, {
          type: "POST",
          data: {
            requestPayload: {
              limit: data.limit,
              // searchTerm: "",
              page: "home"
            }
          }
        })
      )

      const n = normalize(
        {
          videos: videos.data.data
        },
        {
          videos: [videoSchema]
        }
      )

      dispatch({
        type: types.FETCHED_VIDEOS,
        data: {
          fetchingVideos: false,
          videos: {
            allIds: n.result.videos,
            byId: n.entities.videos
          }
        }
      })
    } catch (e) {
      dispatch({
        type: types.ERROR,
        data: e
      })
    }
  }
}
