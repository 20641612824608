import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Line } from "rc-progress"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import Tooltip from "../Tooltip/Tooltip.js"
import { lightThemePartNames } from "../PYPPartContainer/lightThemeParts"
import { gtmClickHandler } from "../../Utils/gtm"

const PYPPartDesktopFooterContent = ({
  decrementPartId,
  history,
  incrementPartId,
  parts,
  navigation,
  theme,
  savePYP,
  removePartCompletionErrors,
  showPartCompletionError,
  style,
  currentPartComplete,
  fetchingPartData,
  initializingPYPInputs,
  initializedPart,
  savingPYP
}) => {
  const calculateModuleProgress = () => {
    // + 1 because we want the user to start with progress
    const currentPartIndex = parts.allIds.indexOf(navigation.currentPartId) + 1
    return (currentPartIndex / parts.allIds.length) * 100
  }

  const nextHandler = async () => {
    // TODO: Figure out logic for allowing nextHandler to save / increment part

    if (
      fetchingPartData
      // || initializingPYPInputs //This can get us into certain states where initializingPYPInputs is set to true but never gets set back to false, meaning user can't move forward.
    ) {
      return
    } // Nothing happens if data is still being fetched

    if (currentPartComplete) {
      // hopefully, we don't need this - this event can be configured strictly in gtm
      gtmClickHandler({
        name: "NextClickEvent",
        data: {
          action: "Next Click",
          category: "PYP Engagement",
          label: parts.byId[navigation.currentPartId].name
        }
      })
      savePYP()
      incrementPartId(history)
    }
    // else {
    //   showPartCompletionError()
    // }
  }

  const prevHandler = async () => {
    if (savingPYP) {
      return
    }
    gtmClickHandler({
      name: "PreviousClickEvent",
      data: {
        action: "Previous Click",
        category: "PYP Engagement",
        label: navigation.currentPartId
      }
    })
    savePYP()
    decrementPartId(history)
  }

  return (
    <div style={style}>
      <CommitButtonWithClickState
        id="pyp-previous-btn"
        type="white"
        style={{
          borderRadius: "3px",
          width: "138px",
          opacity: !fetchingPartData ? 1 : 0.4
        }}
        hoverStyle={{
          width: "138px"
        }}
        clickedStyle={{
          width: "138px"
        }}
        clickHandler={prevHandler}
      >
        Previous
      </CommitButtonWithClickState>

      <div style={{ width: "60%" }}>
        <Line
          percent={calculateModuleProgress()}
          strokeWidth="1"
          strokeColor={theme === "dark" ? colors.blueyGrey : colors.midnight}
          strokeLinecap="square"
          trailColor={theme === "dark" ? "#1D283E" : "#D5D7DC"}
          style={{
            borderRadius: "2px"
          }}
        />
      </div>

      <Tooltip
        style={{
          display: "flex"
        }}
        show={navigation.showIncompletionError}
        width={270}
        height={50}
        modalHorizontalOffset={-80}
        arrowOffset={60}
        orientation="top"
        toggleModal={() => {
          // Called when user clicks outside of Tooltip modal
          removePartCompletionErrors()
        }}
        contentComponent={(props) => {
          // The modal body
          return (
            <div
              style={{
                color: colors.mediumBlue,
                fontSize: "16px",
                lineHeight: "22px",
                fontFamily: "Lato-Regular",
                width: "270px"
              }}
            >
              You must first complete all steps
            </div>
          )
        }}
        renderComponent={(props) => {
          return (
            <CommitButtonWithClickState
              id="pyp-next-btn"
              type="red"
              style={{
                borderRadius: "3px",
                width: "138px",
                opacity:
                  !savingPYP && !fetchingPartData && currentPartComplete
                    ? 1
                    : 0.4
              }}
              clickedStyle={{
                width: "138px"
              }}
              hoverStyle={{
                width: "138px"
              }}
              clickHandler={nextHandler}
            >
              Next
            </CommitButtonWithClickState>
          )
        }}
      />
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]

  return {
    ...props,
    parts: state.pyp.parts,
    navigation: state.pyp.navigation,
    currentPartComplete: state.pyp.currentPartComplete,
    // fetchingPartData: state.pyp.fetchingPartData || state.pyp.partIsLoading,
    savingPYP: state.pyp.savingPYP,
    initializingPYPInputs: state.pyp.initializingPYPInputs,
    // initializedPart: state.pyp.initializedPart,
    theme: lightThemePartNames.includes(currentPart.name) ? "light" : "dark"
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPPartDesktopFooterContent)
)
