import React from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import colors from "../../Constants/colors"
import ButtonSelection from "../ButtonSelection/ButtonSelection"
import { industriesList, industriesRemoved } from "../../Utils/industriesList"

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
  s = s.replace(/^\./, "") // strip a leading dot
  const a = s.split(".")
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (o && k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

const styleSheet = {
  inputText: {
    color: colors.midnight
  },
  label: {
    fontFamily: "Lato-Regular",
    marginTop: "0px",
    color: colors.midnight
  },
  root: {
    fontFamily: "Lato-Semibold",
    marginTop: "0px"
  }
}

class ProfileIndustries extends React.Component {
  constructor(props) {
    super(props)

    this.data = [
      {
        id: 1,
        userField: "interests_for_profile",
        inputType: "buttonSelection",
        options: industriesList,
        title: `What industries are you interested in?`
      },
      {
        id: 2,
        userField: "profession.otherInterest",
        inputType: "buttonSelection",
        title: `What industries are you interested in?`
      }
    ]

    this.handleChange = this.handleChange.bind(this)

    this.state = {
      // inputData is any updated items in the button selection. We initialize it
      // with 'no selections made yet' in the form of an empty array
      inputData: [],
      otherValue:
        Object.byString(
          props.application.application,
          "profession.otherInterest"
        ) || ""
    }
  }

  handleChange(e) {
    this.setState(
      {
        otherValue: e.target.value
      },
      this.props.changeHandler(
        this.state.inputData.concat([
          {
            userField: "profession.otherInterest",
            value: e.target.value
          }
        ])
      )
    )
  }

  render() {
    return (
      <ButtonSelection
        userField={this.data[0].userField}
        limit={5}
        style={{ marginTop: "20px" }}
        {...this.props}
        inputData={this.data[0].options
          .map((option, index) => {
            const isSelected = this.props.application.application.interestsForProfile.some(
              (interest) => interest.name === option
            )
            const isRemoved = industriesRemoved.some(
              (industryRemoved) => industryRemoved === option
            )

            if ((isRemoved && isSelected) || !isRemoved) {
              return {
                id: index,
                value: option,
                isSelected
              }
            }

            return null
          })
          .filter(Boolean)}
        changeHandler={(data) => {
          this.setState(
            {
              inputData: data
            },
            this.props.changeHandler(
              data.concat([
                {
                  userField: "profession.otherInterest",
                  value: this.state.otherValue
                }
              ])
            )
          )
        }}
        renderComponent={({ inputComponent, inputData }) => {
          return (
            <>
              {this.props.renderComponent({
                ...this.props,
                inputData: inputData.concat([
                  {
                    userField: "profession.otherInterest",
                    value: this.state.otherValue
                  }
                ]),
                inputComponent: (
                  <>
                    {inputComponent}
                    <div
                      className="otherInterestContainer"
                      style={{ marginTop: "50px" }}
                    >
                      <TextField
                        InputLabelProps={{
                          classes: {
                            root: this.props.classes.root
                          }
                        }}
                        classes={{
                          root: this.props.inputText
                        }}
                        value={this.state.otherValue}
                        label="Other"
                        type="text"
                        style={{
                          marginTop: "0px"
                        }}
                        id="standard-dense"
                        margin="dense"
                        onChange={this.handleChange}
                      />
                    </div>
                  </>
                )
              })}{" "}
            </>
          )
        }}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application
  }
}

export default connect(mapStateToProps)(
  withStyles(styleSheet)(ProfileIndustries)
)
