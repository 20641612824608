/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react"
import { ControlBar, BigPlayButton } from "video-react"
import ContentLoader from "react-content-loader"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ShowMoreText from "react-show-more-text"
import VideoReactPlayer from "../VideoReactPlayer/VideoReactPlayer"

import "./SessionWebinar.css"

const moment = require("moment")

const SkeletonLoader = () => (
  <ContentLoader
    height={475}
    width={720}
    speed={2}
    primaryColor="#030b20"
    secondaryColor="#051334"
  >
    <rect x="6" y="6" rx="5" ry="5" width="700" height="456" />
  </ContentLoader>
)

const SessionWebinar = ({ currentEvent }) => {
  const [textExpanded, setTextExpanded] = useState(false)
  const [isTextExpandedNeeded, setIsTextExpandedNeeded] = useState(false)

  useEffect(() => {
    if (currentEvent?.description?.length < 136) {
      setIsTextExpandedNeeded(false)
    } else {
      setIsTextExpandedNeeded(true)
    }
    setTextExpanded(false)
  }, [currentEvent])

  const executeOnClick = () => {
    setTextExpanded(false)
  }

  const descriptionLineBreaks = (string) =>
    string.split(";").map((text, index) => (
      <React.Fragment key={`${text + index}`}>
        {text}
        <br />
      </React.Fragment>
    ))

  if (!currentEvent || !Object.keys(currentEvent).length) return null
  const {
    session_name,
    description,
    video: [firstVideoResult],
    date
  } = currentEvent

  if (moment(date).isAfter(new Date())) {
    return (
      <div className="no-webinar-message">
        The video recording will be available here once the session has ended
      </div>
    )
  }

  // ShowMoreText has some issues and does not use the expanded properly when updating it.
  // It only uses it at rendering, therefore we have to make a trick to avoid rendering it when expanding the text.
  // That way we can make sure ShowMoreText will be re-rendered

  return (
    <div className="webinar-container">
      <h2 className="spouse-course-section-title" style={{ fontWeight: "900" }}>
        {session_name}
      </h2>
      <div className="session-description">
        {!isTextExpandedNeeded && (
          <div>{descriptionLineBreaks(description)}</div>
        )}

        {textExpanded ? (
          <div>
            {descriptionLineBreaks(description)}
            <a className="show-more-collapse" href="#" onClick={executeOnClick}>
              Show Less <ExpandLessIcon />
            </a>
          </div>
        ) : (
          isTextExpandedNeeded && (
            <ShowMoreText
              /* Default options */
              lines={2}
              more={
                <div>
                  Show More <ExpandMoreIcon />
                </div>
              }
              className="content-css"
              anchorClass="show-more-collapse"
              expanded={false}
              onClick={() => setTextExpanded(true)}
              truncatedEndingComponent="..."
            >
              {descriptionLineBreaks(description)}
            </ShowMoreText>
          )
        )}
      </div>

      <div className="webinar-video-container">
        {firstVideoResult ? (
          <>
            <VideoReactPlayer
              videoTitle={firstVideoResult?.title || ""}
              aspectRatio="auto"
              autoPlay={false}
              height="100%"
              width="100%"
              src={
                firstVideoResult?.s3_video_url ||
                "https://s3.us-east-2.amazonaws.com/pursue-your-purpose-assets/videos/Widen+your+aperture+-+Wildest+Thing.mp4"
              }
            >
              <BigPlayButton
                style={{ borderColor: "green" }}
                position="center"
              />
              <ControlBar
                style={{ color: "red" }}
                autoHide
                className="my-class"
              />
            </VideoReactPlayer>
          </>
        ) : (
          <SkeletonLoader />
        )}
      </div>
    </div>
  )
}

export default SessionWebinar
