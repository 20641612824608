import React from "react"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"
import WithPYPBox from "../WithPYPBox/WithPYPBox.js"

const SkeletonCard = ({ size, style, textAlign }) => (
  <WithPYPBox
    size={size || "small"}
    style={{
      // margin: '30px 30px 0px 30px',
      ...style,
      padding: "0px"
    }}
    renderComponent={(props) => (
      <SkeletonWrapper height={260} width={380} theme="light">
        {textAlign && textAlign === "center" ? (
          <>
            <rect x="110" y="30" rx="4" ry="4" width="159" height="20" />
            <rect x="50" y="70" rx="4" ry="4" width="280" height="11" />
            <rect x="40" y="90" rx="4" ry="4" width="300" height="11" />
            <rect x="140" y="110" rx="4" ry="4" width="100" height="11" />
          </>
        ) : (
          <>
            <rect x="30" y="30" rx="4" ry="4" width="159" height="20" />
            <rect x="30" y="70" rx="4" ry="4" width="280" height="11" />
            <rect x="30" y="90" rx="4" ry="4" width="300" height="11" />
            <rect x="30" y="110" rx="4" ry="4" width="100" height="11" />
          </>
        )}
      </SkeletonWrapper>
    )}
  />
)

export default SkeletonCard

// const SkeletonCard = () => (
//   <div style={{width: '380px', height: '260px', margin: '30px 30px 0px 30px'}}>
//     <SkeletonWrapper
//       height={260}
//       width={380}
//       theme={'light'}>
//       {
//         // Passing no children will create a skeleton loader with the full width and height of the Skeleton Wrapper
//       }
//     </SkeletonWrapper>
//   </div>
//
//
// )
//
// export default SkeletonCard
