import React from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import PYPStaticInputBox from "../PYPStaticInputBox/PYPStaticInputBox.js"
import SkeletonCard from "../SkeletonCard/SkeletonCard.js"
import ToggleSelector from "../ToggleSelector/ToggleSelector.js"

import Actions from "../../Redux/Actions"

class LayoutMetaDataLeadSelection extends React.Component {
  componentDidMount() {
    this.props.fetchPartData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPart.id !== this.props.currentPart.id) {
      this.props.fetchPartData()
    }
  }

  renderInputBoxes() {
    if (!this.props.inputBoxes.length) {
      if (this.props.numInputs) {
        return Array(this.props.numInputs)
          .fill(1)
          .map((num, i) => (
            <SkeletonCard
              key={i}
              size={this.props.inputSize}
              style={{ margin: "30px 30px 0px 30px" }}
            />
          ))
      }
      return (
        <SkeletonCard
          size={this.props.inputSize}
          style={{ margin: "30px 30px 0px 30px" }}
        />
      )
    }

    const inputBoxes = Object.values(this.props.inputBoxes).map(
      (input, key) => ({
        renderComponent: (props, key) => {
          return (
            <PYPStaticInputBox
              key={props.key}
              title={
                input.input_title_id
                  ? input.title.title
                  : (input.buttonSelectionTitle.buttonValue &&
                      input.buttonSelectionTitle.buttonValue.name) ||
                    (input.buttonSelectionTitle.customButtonValue &&
                      input.buttonSelectionTitle.customButtonValue.name)
              }
              size={input.size}
              text={input.text}
              style={{ margin: "30px 30px 0px 30px" }}
              onClick={props.selectComponent}
              selected={props.selected}
            />
          )
        }
      })
    )

    return (
      <ToggleSelector
        components={inputBoxes}
        initialSelectionIndex={this.props.inputBoxes.findIndex(
          (input) => input.buttonSelectionTitle.is_lead
        )}
        didSelectComponent={(index) => this.didSelectComponent(index)}
      />
    )
  }

  didSelectComponent(index) {
    this.props.setLeadUserInput(index)
  }

  render() {
    const inputBoxes = this.renderInputBoxes()
    return (
      <PYPLayoutContainer
        backgroundColor={colors.background}
        style={{ ...this.props.style }}
      >
        <PYPPartTitleContent
          title={this.props.metadata && this.props.metadata.title}
          description={this.props.metadata && this.props.metadata.description}
        />
        <div style={styles.inputBoxContainer}>{inputBoxes}</div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%", // 80%?
    padding: "46px 100px",
    minHeight: "100%", // 80%?
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    marginBottom: "30px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let inputBoxes = []

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.title[0]
    inputBoxes = partData.referenceInputs
  }

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    inputBoxes
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutMetaDataLeadSelection)
)
