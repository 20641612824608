import React from "react"
import { connect } from "react-redux"
import Loader from "react-loader-spinner"
import { withRouter, Redirect } from "react-router-dom"

import axios from "axios"
import queryString from "query-string"
import ReactS3Uploader from "react-s3-uploader"
import * as utils from "../../Redux/Utils/actionUtils"
import VideoEditor from "../VideoEditor/VideoEditor"
import colors from "../../Constants/colors.js"
import VideoUploadProgress from "../VideoUploadProgress/VideoUploadProgress"
import VideoUploader from "../VideoUploader/VideoUploader"

export class VideoEditorContainer extends React.Component {
  constructor(props) {
    super(props)

    const { videoId } = queryString.parse(this.props.location.search)
    this.videoId = videoId

    this.thumbnailTimeStampPattern = new RegExp(
      /^(?:1[0-2]|0[0-9]):[0-5][0-9]:[0-5][0-9]$/
    )

    this.state = {
      hasError: false,
      initialized: false,
      categories: [],
      justUploaded: false,
      videoEdits: null,
      types: [],
      currentEvent: this.videoId ? "editing" : "selectingImage",
      uploadError: null,
      deleteVideoError: null
    }

    this.getSignedUrl = this.getSignedUrl.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.generateThumbnailFromTimestamp = this.generateThumbnailFromTimestamp.bind(
      this
    )
    this.onUploadStart = this.onUploadStart.bind(this)
    this.onError = this.onError.bind(this)
    this.onProgress = this.onProgress.bind(this)
    this.onUploadFinish = this.onUploadFinish.bind(this)
    this.publishVideo = this.publishVideo.bind(this)
    this.createVideo = this.createVideo.bind(this)
    this.setJustUploaded = this.setJustUploaded.bind(this)
    this.errorHandler = this.errorHandler.bind(this)
    this.updateVideoS3Values = this.updateVideoS3Values.bind(this)
    this.changeHandler = this.changeHandler.bind(this)
    this.deleteVideo = this.deleteVideo.bind(this)
    this.host = process.env.REACT_APP_API_URL
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    })
  }

  async generateThumbnailFromTimestamp(inputData) {
    if (
      this.state.videoEdits &&
      this.state.videoEdits[0].value &&
      this.thumbnailTimeStampPattern.test(this.state.videoEdits[0].value) &&
      !this.state.generatingThumbnail
    ) {
      this.setState({
        generatingThumbnail: true
      })

      const thumbnailRes = await axios.post(
        `${this.host}/generateVideoThumbnail`,
        {
          timestamp: this.state.videoEdits[0].value,
          url: this.state.video.s3_video_url
        },
        {
          headers: { Authorization: this.props.api.apiToken }
        }
      )

      if (!thumbnailRes.data) {
        this.setState({
          generatingThumbnail: false
        })

        return
      }

      const updatedVideoRes = await axios.put(
        `${this.host}/video`,
        {
          id: this.state.video.id,
          videoUpdates: {
            s3_thumbnail_url: thumbnailRes.data
          }
        },
        {
          headers: { Authorization: this.props.api.apiToken }
        }
      )

      this.setState({
        video: updatedVideoRes.data,
        generatingThumbnail: false
      })
    }
  }

  async updateVideoS3Values(videoUrl) {
    const thumbnailRes = await axios.post(
      `${this.host}/generateVideoThumbnail`,
      {
        url: videoUrl
      },
      {
        headers: { Authorization: this.props.api.apiToken }
      }
    )

    const updatedVideoRes = await axios.put(
      `${this.host}/video`,
      {
        id: this.videoId,
        videoUpdates: {
          s3_thumbnail_url: thumbnailRes.data,
          s3_video_url: videoUrl
        }
      },
      {
        headers: { Authorization: this.props.api.apiToken }
      }
    )

    this.setState({
      video: updatedVideoRes.data,
      currentEvent: "editing",
      justUploaded: true
    })
  }

  errorHandler() {
    this.setState({
      currentEvent: "error"
    })
  }

  async getSignedUrl(file, callback) {
    const urlRes = await axios.post(
      `${this.host}/getSignedAWSUrl`,
      {
        fileName: file.name,
        fileType: file.type
      },
      {
        headers: {
          Authorization: this.props.api.apiToken
        }
      }
    )

    callback(urlRes.data)
  }

  onUploadFinish(fileData) {
    this.setState({
      currentEvent: "finalizing"
    })
    this.videoId
      ? this.updateVideoS3Values(fileData.publicUrl)
      : this.createVideo(fileData.publicUrl)
  }

  onProgress(percent) {
    this.setState({
      percentageComplete: percent
    })
  }

  onError(e) {
    console.log(e)
  }

  onUploadStart(file, next) {
    if (file.type === "video/mp4") {
      next(file)
      this.setState({
        currentEvent: "uploading"
      })
    } else {
      this.setState({
        uploadError: "Video must be of type mp4. Please try again."
      })
    }
  }

  changeHandler(inputData) {
    this.setState({
      videoEdits: inputData
    })
  }

  async handleFileUpload(e) {
    e.preventDefault()
    const data = new FormData()
    data.append("video", e.target.files[0])
    data.append("name", "video")

    await axios.post(`${this.host}/uploadVideo`, data, {
      headers: {
        Authorization: this.props.api.apiToken,
        "Content-Type": "multipart/form-data"
      }
    })

    this.setState({
      uploadingFile: true
    })
  }

  async componentDidMount() {
    const categories = await axios.get(`${this.host}/categories`, {
      headers: { Authorization: this.props.api.apiToken }
    })
    const types = await axios.get(`${this.host}/types`, {
      headers: { Authorization: this.props.api.apiToken }
    })

    // Maybe this gets called whenever there is an update to the button category
    // const buttonValuesRes = await axios.get(`${this.host}/buttonValues/`, {
    //   headers: { Authorization: this.props.api.apiToken },
    //   params: { partId: 3355 }
    // });

    // const tags = buttonValuesRes.data.map((button, i) => {
    //   return {
    //     name: button.name,
    //     button_value_id: button.pivot.button_value_id
    //   }
    // })

    const videoRes = this.videoId
      ? await axios.get(`${this.host}/video?videoId=${this.videoId}`, {
          headers: { Authorization: this.props.api.apiToken }
        })
      : null

    this.setState({
      initialized: true,
      categories: categories.data,
      types: types.data,
      video: videoRes ? videoRes.data[0] : null
      // tags: tags
    })
  }

  async publishVideo(inputData, videoId) {
    const processedInputs = inputData.map((input) => {
      if (input.inputType === "select") {
        return input.value
          ? {
              ...input,
              value: input.options.find(
                (option) => option.value === input.value
              ).id
            }
          : input
      }
      return input
    })

    await axios.put(
      `${this.host}/video`,
      {
        id: videoId,
        videoUpdates: utils.processInputData(processedInputs).video
      },
      {
        headers: { Authorization: this.props.api.apiToken }
      }
    )

    this.props.history.push("/digitalLibrary")
  }

  setJustUploaded() {
    this.setState({
      justUploaded: true
    })
  }

  async createVideo(url) {
    const res = await axios.post(
      `${this.host}/video`,
      {
        publicVideoUrl: url
      },
      {
        headers: { Authorization: this.props.api.apiToken }
      }
    )

    this.setState({
      video: res.data[0],
      justUploaded: true,
      currentEvent: "editing"
    })

    this.props.history.push(`/digitalLibraryAdmin?videoId=${res.data[0].id}`)
  }

  async deleteVideo() {
    try {
      await axios.delete(`${this.host}/video`, {
        data: {
          videoId: this.state.video.id
        },
        headers: { Authorization: this.props.api.apiToken }
      })

      this.setState({ deleteVideoError: null })
      this.props.history.push("/digitalLibrary")
    } catch (error) {
      this.setState({
        deleteVideoError: "An error has ocurred. Please try again."
      })
    }
  }

  getVideoComponent() {
    switch (this.state.currentEvent) {
      case "uploading":
        return (
          <VideoUploadProgress
            percentageComplete={this.state.percentageComplete}
          />
        )
      case "editing":
        return (
          <VideoEditor
            changeHandler={this.changeHandler}
            deleteVideo={this.deleteVideo}
            publishVideo={this.publishVideo}
            video={this.state.video}
            generatingThumbnail={this.state.generatingThumbnail}
            types={this.state.types}
            justUploaded={this.state.justUploaded}
            generateThumbnailFromTimestamp={this.generateThumbnailFromTimestamp}
            categories={this.state.categories}
            tagCategories={["Values, Industries"]}
            deleteVideoError={this.state.deleteVideoError}
          />
        )
      // tags={this.state.tags}
      case "finalizing":
        return (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Loader
              type="Oval"
              color={colors.midnight}
              height="100"
              width="100"
            />
            <h1 style={{ marginTop: "20px" }}> Finalizing Upload... </h1>
          </div>
        )
      case "selectingImage":
        return (
          <VideoUploader
            uploadError={this.state.uploadError}
            startUpload={this.startUpload}
            createVideo={this.createVideo}
          />
        )
      case "error":
        return (
          <h1
            style={{
              display: "flex",
              width: "100%",
              height: "100vh",
              color: colors.fadedRed,
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px"
            }}
          >
            {" "}
            Oops! We had trouble with the upload. Please refresh the page and
            try again.
          </h1>
        )
      default:
        return (
          <h1
            style={{
              display: "flex",
              width: "100%",
              height: "100vh",
              color: colors.fadedRed,
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px"
            }}
          >
            {" "}
            Oops! We had trouble with the upload. Please refresh the page and
            try again.
          </h1>
        )
    }
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <ReactS3Uploader
          style={{
            opacity: 0,
            height: "0px"
          }}
          s3path="/videos/"
          id="file-upload"
          onFinish={this.onUploadFinish}
          preprocess={this.onUploadStart}
          getSignedUrl={this.getSignedUrl}
          contentDisposition="auto"
          onProgress={this.onProgress}
          onError={this.errorHandler}
          autoUpload
        />

        {this.state.hasError ? (
          <Redirect to="/error" />
        ) : this.state.initialized ? (
          this.getVideoComponent(this.getSignedUrl)
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Loader
              type="Oval"
              color={colors.midnight}
              height="100"
              width="100"
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    api: state.api
  }
}

export default withRouter(connect(mapStateToProps)(VideoEditorContainer))
