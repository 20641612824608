import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors"

const InstructionalGuide = ({
  incrementGuide,
  children,
  instructionalGuide
}) => {
  const { guide, currentStepId } = instructionalGuide

  useEffect(() => {
    const applicationScrollContainer = document.querySelector(
      "#applicationScrollContainer"
    )
    const scrollSettings = { top: 0, left: 0, behavior: "smooth" }

    if (guide[currentStepId]?.name === "saveAndExit") {
      scrollSettings.top = applicationScrollContainer.scrollHeight
      applicationScrollContainer.scroll(scrollSettings)
    } else {
      return applicationScrollContainer.scroll(scrollSettings)
    }
  }, [currentStepId])

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          color: colors.mediumBlue,
          fontSize: "16px",
          lineHeight: "22px",
          fontFamily: "Lato-Regular"
        }}
      >
        {children}
      </div>
      <CommitButtonWithClickState
        style={{
          marginTop: "20px",
          fontFamily: "Lato-Heavy",
          width: "154px"
        }}
        type="red"
        clickHandler={incrementGuide}
      >
        {currentStepId === guide.length - 1 ? `Got It!` : `Next`}
      </CommitButtonWithClickState>
    </div>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    instructionalGuide: state.instructionalGuide
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructionalGuide)
