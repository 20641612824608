import React from "react"
import { withRouter } from "react-router-dom"
import InputAdornment from "@material-ui/core/InputAdornment"
import MediaQuery from "react-responsive"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors.js"

const ForgotPasswordAdornment = (props) => {
  const forgotPasswordHandler = () => {
    props.history.push("/account/forgotPassword")
  }

  return (
    <MediaQuery minWidth={500}>
      <InputAdornment position="end">
        <CommitButtonWithClickState
          style={styles.forgotPasswordButton}
          clickHandler={forgotPasswordHandler}
        >
          Forgot Password?
        </CommitButtonWithClickState>
      </InputAdornment>
    </MediaQuery>
  )
}

const styles = {
  forgotPasswordButton: {
    color: colors.fadedRed,
    backgroundColor: colors.white,
    width: "124px"
  }
}

export default withRouter(ForgotPasswordAdornment)
