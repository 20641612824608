import React from "react"
import moment from "moment"
import { Draggable } from "react-beautiful-dnd"
import CreateIcon from "@material-ui/icons/Create"
import RoadmapperEditInputCalenderContainer from "../RoadmapperEditInputCalenderContainer/RoadmapperEditInputCalenderContainer"
import colors from "../../Constants/colors"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import { useWindowResize } from "../../Hooks/useWindowResize"

const componentColors = {
  dateOutOfBoundsColor: colors.fadedRed, // "lightgray",
  validDateColor: "#00D061",
  invalidDateColor: "#FFC900"
}

const dateOutOfBounds = (moment_data, start, end) => {
  return moment(moment_data).isBefore(start) || moment(moment_data).isAfter(end)
}

export default function RoadmapperCalenderEvent({
  timeEvent, // : { id, title, moment_data },
  index,
  isDragDisabled,
  renderMoment,
  constraintStart,
  constraintEnd,
  updateParentState,
  editingComponent,
  updateEditEvent,
  deleteTimeEvent,
  skeletonLoader,
  boxColor = timeEvent.moment_data
    ? dateOutOfBounds(timeEvent.moment_data, constraintStart, constraintEnd)
      ? componentColors.dateOutOfBoundsColor
      : componentColors.validDateColor
    : componentColors.invalidDateColor
}) {
  const { width } = useWindowResize()
  const skeletonWidth = 0.75 * width > 700 ? 700 : 0.75 * width
  const skeletonWidthRatio = skeletonWidth / 290
  const enableEditing = () => {
    if (!skeletonLoader) {
      updateParentState({
        editing: true,
        eventIdBeingEdited: timeEvent.id
      })
    }
  }

  const finishEditing = () => {
    updateParentState({
      editing: false,
      eventIdBeingEdited: null
    })
  }

  return (
    <Draggable
      draggableId={timeEvent.id || "non-draggable"}
      index={index}
      isDragDisabled={isDragDisabled || skeletonLoader}
    >
      {(provided) => (
        <div
          className="draggableRoadmapperElement"
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {editingComponent ? (
            <RoadmapperEditInputCalenderContainer
              deleteTimeEvent={deleteTimeEvent}
              clickHandler={() => finishEditing()}
              timeEvent={timeEvent}
              updateEditEvent={updateEditEvent}
            />
          ) : timeEvent.id === 0 ? (
            <SkeletonWrapper
              height="290"
              width={skeletonWidth}
              containerStyle={{
                width: "100%",
                height: "290px"
              }}
            >
              <rect
                x={20 * skeletonWidthRatio}
                y="45"
                width={50 * skeletonWidthRatio}
                height="15"
              />
              <rect
                x={20 * skeletonWidthRatio}
                y="65"
                width={250 * skeletonWidthRatio}
                height="30"
              />
              <rect
                x={20 * skeletonWidthRatio}
                y="130"
                width={50 * skeletonWidthRatio}
                height="15"
              />
              <rect
                x={20 * skeletonWidthRatio}
                y="150"
                width={250 * skeletonWidthRatio}
                height="30"
              />
              <rect
                x={215 * skeletonWidthRatio}
                y="220"
                width={55 * skeletonWidthRatio}
                height="40"
              />
              <rect
                x={155 * skeletonWidthRatio}
                y="220"
                width={55 * skeletonWidthRatio}
                height="40"
              />
              {/* <rect x="10" y="10" width={75} height="5" />
              <rect x="10" y="22" width={20} height="2" />
              <rect x="10" y="25" width={30} height="5" />
              <rect x="55" y="22" width={20} height="2" />
              <rect x="55" y="25" width={30} height="5" /> */}
            </SkeletonWrapper>
          ) : (
            <>
              <span style={styles.leftItems}>
                <div
                  style={{
                    ...styles.rectangle,
                    backgroundColor: boxColor
                  }}
                />
                {/* <DragIndicatorIcon
                  style={{
                    ...styles.draggableIcon,
                    color: isDragDisabled
                      ? "rgba(0, 0, 0, 0.09)"
                      : styles.draggableIcon.color
                  }}
                /> */}
                <span
                  className="roadmapperEventTitle"
                  style={styles.calenderTitle}
                >
                  {timeEvent.title}
                </span>
              </span>
              <span style={styles.editClickSegment}>
                {editingComponent ? (
                  <>
                    <span style={styles.durationTimeStyle}>
                      {renderMoment(timeEvent.moment_data)}
                    </span>
                    <button
                      style={{
                        ...styles.editButton,
                        ...styles.editButtonDisabled
                      }}
                    >
                      <CreateIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <span
                      style={styles.durationTimeStyle}
                      className="roadmapperDisplayedTime"
                    >
                      {renderMoment(timeEvent.moment_data)}
                    </span>
                    <button
                      style={styles.editButton}
                      onClick={() => enableEditing()}
                    >
                      <CreateIcon className="roadmapperEventEditIcon" />
                    </button>
                  </>
                )}
              </span>
            </>
          )}
        </div>
      )}
    </Draggable>
  )
}

const styles = {
  durationTimeStyle: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "106%",
    alignItems: "center",
    textAlign: "right",
    color: "#8792A5",
    marginLeft: "10px",
    marginRight: "7px",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  editClickSegment: {
    // paddingTop: "calc(50% - 12px)",
    margin: "auto",
    height: "fit-content",
    minWidth: "30px",
    display: "flex",
    width: "100%", // "-webkit-fill-available",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  editButton: {
    height: "24px",
    color: "rgba(0, 0, 0, 0.09)",
    backgroundColor: "inherit",
    border: "none",
    margin: "auto",
    marginRight: "16px",
    marginLeft: "16px",
    padding: "0px"
  },
  rectangle: {
    alignItems: "center",
    width: "4px",
    height: "100%",
    borderRadius: "3px 0 0 3px",
    display: "flex",
    minWidth: "4px" // This is very important, will prevent css overrides
  },
  leftItems: { display: "flex", alignItems: "center" },
  draggableIcon: {
    color: "#9FA9BA",
    height: "18.67px"
  },
  editButtonDisabled: {
    color: "rgba(0, 0, 0, 0.09)"
  },
  draggableElement: {
    display: "flex",
    minHeight: "444px",
    width: "100%",
    background: "#ffffff",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px",
    margin: "5px 0px",
    height: "fit-content"
  },
  calenderTitle: {
    marginLeft: "51px"
  }
  // boxStyle: {
  //   height: "fit-content",
  //   minHeight: "40px",
  //   backgroundColor: "orange",
  //   margin: "8px auto",
  //   border: "1px solid orange",
  //   borderRadius: "2px",
  //   padding: "20px",
  //   width: "100%"
  // }
}
