import React from "react"
import moment from "moment"
import { Draggable } from "react-beautiful-dnd"
import CreateIcon from "@material-ui/icons/Create"
import DragIndicatorIcon from "@material-ui/icons/DragIndicator"
import RoadmapperEditInputContainer from "../RoadmapperEditInputContainer/RoadmapperEditInputContainer"
import colors from "../../Constants/colors"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import { useWindowResize } from "../../Hooks/useWindowResize"

const componentColors = {
  inValidValueColor: "#FFC900", // colors.fadedRed, //"lightgray",
  validValueColor: "#00D061",
  invalidTimeColor: colors.fadedRed, // "#00D061",
  nonValueColor: "#FFC900"
}

export default function RoadmapperEvent({
  timeEvent, // : { id, title, value, duration },
  accumulatedTime,
  index,
  isDragDisabled,
  renderMoment,
  constraintStart,
  constraintEnd,
  updateParentState,
  durationUnit,
  editingComponent,
  updateEditEvent,
  deleteTimeEvent,
  skeletonLoader,
  values,
  boxColor = values.filter((value) => value.name === timeEvent.value)[0]
    ? values.filter((value) => value.name === timeEvent.value)[0].id === null
      ? componentColors.nonValueColor
      : componentColors.validValueColor
    : componentColors.inValidValueColor,
  boxColor2 = timeEvent.duration / 60 +
    accumulatedTime / 60 +
    parseInt(constraintStart._i.split(":")[0]) +
    parseInt(constraintStart._i.split(":")[1]) / 60 >
  parseInt(constraintEnd._i.split(":")[0]) +
    parseInt(constraintEnd._i.split(":")[1]) / 60
    ? componentColors.invalidTimeColor
    : null
}) {
  const { width } = useWindowResize()
  const skeletonWidth = 0.75 * width > 700 ? 700 : 0.75 * width
  const skeletonWidthRatio = skeletonWidth / 290
  const enableEditing = () => {
    if (!skeletonLoader) {
      updateParentState({
        editing: true,
        eventIdBeingEdited: timeEvent.id
      })
    }
  }

  const finishEditing = () => {
    updateParentState({
      editing: false,
      eventIdBeingEdited: null
    })
  }

  return (
    <Draggable
      draggableId={timeEvent.id || "non-draggable"}
      index={index}
      isDragDisabled={editingComponent || skeletonLoader} // isDragDisabled}
    >
      {(provided) => (
        <div
          className="draggableRoadmapperElement"
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          {editingComponent ? (
            <RoadmapperEditInputContainer
              skeletonLoader={skeletonLoader}
              deleteTimeEvent={deleteTimeEvent}
              clickHandler={() => finishEditing()}
              timeEvent={timeEvent}
              durationUnit={durationUnit}
              updateEditEvent={updateEditEvent}
              values={values}
            />
          ) : timeEvent.id === 0 ? (
            <SkeletonWrapper
              height="290"
              width={skeletonWidth}
              containerStyle={{
                width: "100%",
                height: "290px"
              }}
            >
              <rect
                x={20 * skeletonWidthRatio}
                y="45"
                width={50 * skeletonWidthRatio}
                height="15"
              />
              <rect
                x={20 * skeletonWidthRatio}
                y="65"
                width={250 * skeletonWidthRatio}
                height="30"
              />
              <rect
                x={20 * skeletonWidthRatio}
                y="130"
                width={50 * skeletonWidthRatio}
                height="15"
              />
              <rect
                x={20 * skeletonWidthRatio}
                y="150"
                width={120 * skeletonWidthRatio}
                height="30"
              />
              <rect
                x={150 * skeletonWidthRatio}
                y="130"
                width={50 * skeletonWidthRatio}
                height="15"
              />
              <rect
                x={150 * skeletonWidthRatio}
                y="150"
                width={120 * skeletonWidthRatio}
                height="30"
              />
              <rect
                x={215 * skeletonWidthRatio}
                y="220"
                width={55 * skeletonWidthRatio}
                height="40"
              />
              <rect
                x={155 * skeletonWidthRatio}
                y="220"
                width={55 * skeletonWidthRatio}
                height="40"
              />
              {/* <rect x="10" y="10" width={75} height="5" />
              <rect x="10" y="22" width={20} height="2" />
              <rect x="10" y="25" width={30} height="5" />
              <rect x="55" y="22" width={20} height="2" />
              <rect x="55" y="25" width={30} height="5" /> */}
            </SkeletonWrapper>
          ) : (
            <>
              <span style={styles.leftItems}>
                <div
                  style={{
                    ...styles.rectangle,
                    backgroundColor: boxColor2 || boxColor
                  }}
                />
                <DragIndicatorIcon
                  style={{
                    ...styles.draggableIcon,
                    color: isDragDisabled
                      ? "rgba(0, 0, 0, 0.09)"
                      : styles.draggableIcon.color
                  }}
                />
                <span className="roadmapperEventTitle">{timeEvent.title}</span>
              </span>
              <span style={styles.editClickSegment}>
                {isDragDisabled ? (
                  <>
                    <span style={styles.durationTimeStyle}>
                      {renderMoment([
                        moment(constraintStart).add(
                          accumulatedTime,
                          durationUnit
                        ),
                        moment(constraintStart).add(
                          accumulatedTime + timeEvent.duration,
                          durationUnit
                        )
                      ])}
                    </span>
                    <button
                      style={{
                        ...styles.editButton,
                        ...styles.editButtonDisabled
                      }}
                    >
                      <CreateIcon />
                    </button>
                  </>
                ) : (
                  <>
                    <span
                      style={styles.durationTimeStyle}
                      className="roadmapperDisplayedTime"
                    >
                      {renderMoment([
                        moment(constraintStart).add(
                          accumulatedTime,
                          durationUnit
                        ),
                        moment(constraintStart).add(
                          accumulatedTime + timeEvent.duration,
                          durationUnit
                        )
                      ])}
                    </span>
                    <button
                      style={styles.editButton}
                      onClick={() => enableEditing()}
                    >
                      <CreateIcon className="roadmapperEventEditIcon" />
                    </button>
                  </>
                )}
              </span>
            </>
          )}
        </div>
      )}
    </Draggable>
  )
}

const styles = {
  // eventTitle: {
  //   minWidth: "379px",
  //   fontFamily: "Lato-Regular",
  //   fontStyle: "normal",
  //   fontWeight: "normal",
  //   fontSize: "16px",
  //   lineHeight: "106%",
  //   display: "flex",
  //   alignItems: "center",
  //   color: "#030D26",
  //   marginLeft: "28px"
  // },
  durationTimeStyle: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "106%",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#8792A5",
    marginLeft: "10px",
    paddingTop: "5px",
    paddingBottom: "5px"
  },
  editClickSegment: {
    // paddingTop: "calc(50% - 12px)",
    margin: "auto",
    height: "fit-content",
    minWidth: "30px",
    display: "flex",
    width: "100%", // "-webkit-fill-available",
    justifyContent: "flex-end"
  },
  editButton: {
    height: "24px",
    color: "rgba(0, 0, 0, 0.09)",
    backgroundColor: "inherit",
    border: "none",
    margin: "auto",
    marginRight: "16px",
    marginLeft: "16px",
    padding: "0px"
  },
  rectangle: {
    alignItems: "center",
    width: "4px",
    height: "100%",
    borderRadius: "3px 0 0 3px",
    display: "flex",
    minWidth: "4px" // This is very important, will prevent css overrides
  },
  leftItems: { display: "flex", alignItems: "center" },
  draggableIcon: {
    color: "#9FA9BA",
    height: "18.67px"
  },
  editButtonDisabled: {
    color: "rgba(0, 0, 0, 0.09)"
  },
  draggableElement: {
    display: "flex",
    minHeight: "444px",
    width: "100%",
    background: "#ffffff",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px",
    margin: "5px 0px",
    height: "fit-content"
  }
  // boxStyle: {
  //   height: "fit-content",
  //   minHeight: "40px",
  //   backgroundColor: "orange",
  //   margin: "8px auto",
  //   border: "1px solid orange",
  //   borderRadius: "2px",
  //   padding: "20px",
  //   width: "100%"
  // }
}
