import React from "react"

export const Key = (props) => {
  return (
    <div style={props.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="111"
        height="52"
        viewBox="0 0 111 52"
      >
        <g fill="none" fillRule="evenodd" stroke="#E1373A" strokeWidth="3">
          <path
            fill="#E1373A"
            fillOpacity=".17"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M32.181 35.477c-4.133 4.508-11.139 4.812-15.65.683-4.51-4.13-4.815-11.131-.681-15.637 4.132-4.508 11.139-4.812 15.648-.683 4.51 4.13 4.816 11.131.683 15.637zm73.727-12.663c-4.771-.019-7.165-.028-7.18-.03l-7.11-.028-4.19-.018-7.073-.03-4.88-.019-30.124-.124a21.897 21.897 0 0 0-6.462-10.784C29.926 3.572 16 4.178 7.785 13.137-.43 22.094.177 36.01 9.142 44.219c8.963 8.209 22.889 7.603 31.104-1.356a22.028 22.028 0 0 0 3.106-4.335 21.822 21.822 0 0 0 1.921-4.79l60.61.13c3.041 0 3.11-2.479 3.117-5.513.007-3.041-.05-5.534-3.092-5.541z"
          />
          <path d="M32.11 35.431c-4.105 4.48-11.062 4.783-15.542.679-4.479-4.104-4.782-11.063-.677-15.541 4.104-4.48 11.062-4.783 15.54-.679 4.48 4.105 4.783 11.063.68 15.541z" />
          <path
            fill="#E1373A"
            fillOpacity=".17"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M104 7.98v13.04c0 .539-.456.98-1.014.98h-3.342l-.704-.002c-.524-.038-.94-.463-.94-.979V7.981c0-.54.456-.981 1.014-.981h3.972c.558 0 1.014.441 1.014.98zM93 3.169v17.696c0 .547-.372 1.01-.869 1.135l-4.329-.02A1.168 1.168 0 0 1 87 20.866V3.169C87 2.526 87.511 2 88.137 2h3.726C92.487 2 93 2.526 93 3.169zM82 12.691v8.524c0 .31-.206.579-.5.702l-5.042-.018c-.272-.131-.458-.39-.458-.684v-8.524c0-.426.389-.774.863-.774h4.274c.474 0 .863.348.863.774z"
          />
        </g>
      </svg>
    </div>
  )
}
