import React from "react"
import "./PYPModuleTitleContent.css"
import PYPTitle from "../PYPTitle/PYPTitle"
import PYPDescription from "../PYPDescription/PYPDescription"
import PYPContentBorder from "../PYPContentBorder/PYPContentBorder"

const PYPTitleContent = (props) => {
  return (
    <div className={props.className} style={{ ...props.style }}>
      <PYPTitle
        style={styles.title}
        title={props.title}
        className="pypModuleTitle"
      />
      <PYPContentBorder key={props.title} />
      <PYPDescription
        style={styles.description}
        description={props.description}
        className="pypModuleDescription"
      />
    </div>
  )
}

const styles = {
  title: {
    //        marginLeft:'30px',
    fontFamily: "Lato-Bold",
    lineHeight: "42px"
    // whiteSpace: "nowrap"
  },
  description: {
    //      marginLeft:'30px',
    fontFamily: "Lato-Regular",
    fontSize: "15px"
  }
}

export default PYPTitleContent
