import React from "react"

export const KeyHole = (props) => {
  return (
    <div style={props.style}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="49"
        height="61"
        viewBox="0 0 49 61"
      >
        <path
          fill="#E1373A"
          fillOpacity=".17"
          fillRule="evenodd"
          stroke="#C33"
          strokeWidth="4"
          d="M44.853 22.395C44.853 11.132 35.693 2 24.39 2S3.927 11.132 3.927 22.395A20.285 20.285 0 0 0 9.92 36.813l1.142 1.139-.87 1.358-7.458 11.623C.658 54.518 3.253 59 7.413 59h34.174c4.16 0 6.755-4.482 4.68-8.067l-7.874-13.596 1.008-1.084a20.266 20.266 0 0 0 5.452-13.858z"
        />
      </svg>
    </div>
  )
}
