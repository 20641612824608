import React from "react"
import colors from "../../Constants/colors"

export const SideBar = ({ children, style }) => {
  return (
    <div style={{ ...styles.componentContainer, ...style }}>{children}</div>
  )
}

const styles = {
  componentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100vh",
    width: "200px",
    backgroundColor: colors.midnight
  }
}

export default SideBar
