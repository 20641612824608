import React from "react"

export const BackArrow = (props) => {
  return (
    <div onClick={props.onClick} style={{ cursor: "pointer", ...props.style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "15"}
        height={props.height || "24"}
        viewBox="0 0 15 24"
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={props.fill || "#292E4C"}
          strokeWidth={props.strokeWidth || "2"}
          d="M14 1.472L2 12.333l12 10.861"
        />
      </svg>
    </div>
  )
}
