import React from "react"
import { withStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"
import { withRouter } from "react-router-dom"
import colors from "../../Constants/colors"
import { RightArrow } from "../../Icons/RightArrow"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

const styleSheet = {
  btnLink: {
    color: colors.white,
    fontFamily: "Lato-Light",
    fontSize: "20px",
    lineHeight: "33px",
    letterSpacing: "0.77px",
    padding: "0px",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "30px 1fr 20px"
  }
}

const MobileNavItem = (props) => {
  const defaultClickHandler = () => {
    setTimeout(() => {
      props.route && props.history.push(props.route)
    }, 200)
    props.closeAllDrawers()
  }

  const clickHandler = () => {
    const clickHandlerToCall = props.clickHandler || defaultClickHandler
    clickHandlerToCall()
  }

  return (
    <ListItem
      button
      classes={{
        root: props.classes.btnLink
      }}
      onClick={clickHandler}
      style={{
        padding: "10px 40px 10px 40px",
        fontFamily: props.isSelected && "Lato-SemiBold"
      }}
    >
      {props.alert && <ErrorOutlineIcon style={{ color: colors.fadedRed }} />}
      {props.children}
      {props.hasSecondaryNavigation && <RightArrow />}
    </ListItem>
  )
}

export default withStyles(styleSheet)(
  withRouter(WithDrawerConsumer(MobileNavItem))
)
