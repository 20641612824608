import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import InputGroup from "../InputGroup/InputGroup"
import "./SpouseCareerPreferences.css"

const mapSelectOptions = (option, index) => {
    if (typeof option === "string") {
        return {
            id: index,
            value: option
        }
    }
    return {
        id: index,
        ...option
    }
}

const inputs = [
    {
        id: 0,
        className: "time_commit",
        inputType: "select",
        userField: "career.time_commit",
        title: "Able to commit 3-6 hours a month to this program?",
        required: true,
        value: "",
        options: ["Yes", "No"].map(
            mapSelectOptions
        )
    },
    {
        id: 1,
        className: "share_consent",
        inputType: "select",
        userField: "career.share_consent",
        title: "Share name and email with other participants for networking?",
        required: true,
        value: "",
        options: ["Yes", "No"].map(
            mapSelectOptions
        )
    },
    {
        id: 2,
        className: "class_preference",
        inputType: "select",
        userField: "career.class_preference",
        title: "Class Preference (typically a Thursday)",
        required: true,
        value: "",
        options: ["Thursday 12pm Eastern Time", "Wednesday 11am Pacific Time"].map(
            mapSelectOptions
        )
    }
]

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    const a = s.split(".")
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i]
        if (o && k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o
}

const validateField = (input, value) => {
    let error = false;
    let helperText = "";

    if (input.required && !value) {
      error = true;
      helperText = "This field is required";
    }

    return { error, helperText };
  };

const SpouseCareerPreferences = (props) => {
    const { changeHandler, renderComponent } = props
    const application = useSelector((state) => {
        return state.spouseApplication.application;
    })
    const initialInputDataWithValues = inputs.map((input) => ({
        ...input,
        value: Object.byString(application, input.userField) || ""
    }))
    const [inputData, setInputData] = useState(initialInputDataWithValues)
    const onInputDataChange = (data) => {
        setInputData(data)
        changeHandler(data)
        let is_valid = true;
        if (Object.keys(data).length > 0) {
        data.forEach((d) => {
            const { error, helperText } = validateField(d, d.value);
            d.error = error;
            d.helperText = helperText;
            if (error && is_valid) {
            is_valid = false;
            }
        });
        } else {
        is_valid = false;
        }
    }
    return (
        <InputGroup
          {...props}
          changeHandler={onInputDataChange}
          containerClass="personalInfoContainer"
          inputData={inputData}
          renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
            return (
              <>
                {props.renderComponent({
                  ...props,
                  inputData,
                  inputComponent,
                  checkSubmittability
                })}
              </>
            )
          }}
        />
    )
}

export default SpouseCareerPreferences
