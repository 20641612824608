import React from "react"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import colors from "../../Constants/colors"
import { Close } from "../../Icons/Close"
import { Hamburger } from "../../Icons/Hamburger"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

import { Logo } from "../../Icons/Logo"
import { Magnifier } from "../../Icons/Magnifier"
import Actions from "../../Redux/Actions"

const UpperMobileNavDefaultOptions = (props) => {
  return (
    <>
      {props.drawerOpen ? (
        <button style={styles.btn} aria-label="Close all navigation menus">
          <Close onClick={props.closeAllDrawers} />
        </button>
      ) : (
        <button style={styles.btn} aria-label="Open the navigation menus=">
          <Hamburger onClick={props.openDrawer} />
        </button>
      )}
      {props.history.location.pathname === "/application" ? (
        <Logo style={{ justifySelf: "center" }} />
      ) : (
        <Link style={{ justifySelf: "center" }} to="/home">
          <Logo />
        </Link>
      )}
      {/digitalLibrary/.test(props.match.path) && (
        <Magnifier
          style={{ justifySelf: "flex-end", marginRight: "20px" }}
          onClick={props.showSearchBar}
        />
      )}
    </>
  )
}

const styles = {
  btn: {
    justifySelf: "flex-start",
    marginLeft: "20px",
    backgroundColor: colors.midnight,
    border: "none"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  WithDrawerConsumer(
    connect(mapStateToProps, mapDispatchToProps)(UpperMobileNavDefaultOptions)
  )
)
