import React from "react"
import "./VideoCategoryWrapper.css"
import { withRouter } from "react-router-dom"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"
import TransparentButton from "../TransparentButton/TransparentButton"
import colors from "../../Constants/colors"
import { underscorify } from "../../Utils/urlParsingUtilities"
import { gtmClickHandler } from "../../Utils/gtm"
import { useWindowResize } from "../../Hooks/useWindowResize"

const VideoCategoryWrapper = (props) => {
  const navigateToCategoryView = (e) => {
    props.clearVideos()
    gtmClickHandler({
      name: "ViewAllVideoTypeEvent",
      data: {
        action: "View All Video Type",
        category: "Video Engagement",
        label: props.category.name
      }
    })
    props.history.push(
      `/digitalLibrary/${underscorify(props.category.name.toLowerCase())}/all`
    )
  }
  const { width } = useWindowResize()

  const renderedSubtitle = props.subtitle ? (
    <h3
      style={
        width > 715
          ? { ...styles.subtitle, marginLeft: "10px" }
          : styles.subtitle
      }
    >
      {props.subtitle}
    </h3>
  ) : (
    <></>
  )

  return (
    <div style={{ ...styles.base, ...props.containerBaseCSS }} className="base">
      <div
        className="burge"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "93%"
        }}
      >
        <span
          className="categoryHeaderContainer"
          style={{
            display: "flex",
            ...props.categoryContainerCSS
          }}
        >
          {props.category ? (
            <>
              <span style={{ ...props.categoryTitleDescContainerCSS }}>
                <div style={width > 715 ? styles.flexHeader : {}}>
                  <h3
                    style={{
                      ...styles.categoryTitle,
                      ...props.categoryTitleCSS
                    }}
                    className="categoryTitle"
                  >
                    {props.category && props.category.name}
                  </h3>
                  {renderedSubtitle}
                </div>
                <p
                  style={{
                    ...styles.categoryDesc,
                    ...props.categoryDescCSS
                  }}
                >
                  {props.category && props.category.description}
                </p>
              </span>
              {props.customButton ||
                (props.videoCount > 4 && props.notAllVideosShown && (
                  <TransparentButton
                    style={{ ...props.categoryButtonCSS }}
                    onClick={props.redirect || navigateToCategoryView}
                  >
                    <button
                      aria-label={`see   all ${props.category.name} videos`}
                      style={styles.seeAllButton}
                    >
                      {props.redirectText ? props.redirectText : "See All"}{" "}
                    </button>
                  </TransparentButton>
                ))}
            </>
          ) : (
            <SkeletonWrapper
              theme="light"
              containerStyle={{ maxWidth: "400px", height: "20px" }}
            >
              <rect x="0" y="0" rx="4" ry="4" width="400" height="20" />
            </SkeletonWrapper>
          )}
        </span>
        {props.children}
      </div>
    </div>
  )
}

const styles = {
  seeAllButton: {
    backgroundColor: "transparent",
    border: "none",
    color: colors.fadedRed
  },
  base: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  categoryTitle: {
    fontFamily: "Lato-Bold",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    letterSpacing: "normal",
    color: "#272728",
    display: "inline-block",
    margin: "0",
    marginRight: "20px"
  },
  categoryDesc: {
    fontFamily: "Lato-Medium",
    fontSize: "13px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.23",
    letterSpacing: "normal",
    color: colors.midnight,
    display: "inline-block",
    margin: "0",
    paddingTop: "5px"
  },
  containerSpacer: {
    border: "solid 1px #e1e0e0",
    marginTop: "50px"
  },
  subtitle: {
    height: "28px",
    fontFamily: "Lato-Bold",
    fontStyle: "italic",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#2A2434",
    marginTop: "12px"
  },
  flexHeader: {
    display: "flex"
  }
}

export default withRouter(VideoCategoryWrapper)
