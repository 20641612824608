import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import InputGroup from "../InputGroup/InputGroup"
import { changeNonDodIcWillRelocate } from "../../Redux/Actions/NonDodIcApplication/functions"
import { getCountries, getRegions } from "../../Utils/locationsApi"

import "./NonDodIcLocationInputs.css"

const initialInputs = [
  {
    id: 0,
    userField: "address.streetAddress",
    inputType: "text",
    placeHolder: "Street Address",
    title: "Street Address",
    required: true,
  },
  {
    id: 1,
    userField: "address.city",
    placeHolder: "City",
    inputType: "text",
    title: "City",
    required: true,
  },
  {
    id: 2,
    userField: "address.state",
    placeHolder: "State",
    inputType: "select",
    options: [],
    title: "State",
    required: true,
  },
  {
    id: 3,
    userField: "address.zipCode",
    placeHolder: "Zip Code",
    inputType: "text",
    title: "Zip Code",
    required: true,
  },
  {
    id: 4,
    userField: "address.country",
    placeHolder: "Country",
    inputType: "select",
    options: [],
    title: "Country",
    required: true,
  },
  {
    id: 5,
    userField: "address.willLocate",
    inputType: "select",
    placeHolder: "Are you planning to or willing to relocate? Y/N",
    title: "Are you planning to or willing to relocate?",
    options: [
      { id: 0, value: "No" },
      { id: 1, value: "Yes" }
    ],
    required: true,
  }
]

const NonDodIcLocationInputs = (props) => {
  const dispatch = useDispatch()
  const nonDodIcApplication = useSelector((state) => state.nonDodIcApplication.application)
  const [inputs, setInputs] = useState(initialInputs)

  const inputDataWithValues = inputs.map((input) => {
    let value = Object.byString(nonDodIcApplication, input.userField) || ""
    if (input.userField === "address.country") {
      value = value || "United States"
    }

    return {
      ...input,
      value
    }
  })

  const requiredValidators = (input) => ({
    ...input,
    error: !!(input.required && input.value.length === 0)
  })

  const dateOfBirthValidator = (input) => {
    if (input.textField === "dateOfBirth") {
      return {
        ...input,
        error: true
      }
    }
    return input
  }

  const updateStates = async (countryId) => {
    if (!countryId) return

    const statesInputIndex = inputs.findIndex(
      ({ userField }) => userField === "address.state"
    )

    const countryRegions = await getRegions(countryId)

    inputs[statesInputIndex] = {
      ...inputs[statesInputIndex],
      options: countryRegions
    }

    setInputs([...inputs])
  }

  const handleChange = (currentInput, value) => {
    if (!currentInput || !value) return

    switch (currentInput.userField) {
      case "address.willLocate":
        dispatch(changeNonDodIcWillRelocate(value === "Yes"))
        break
      case "address.country":
        const { countryId } =
          currentInput.options?.find((option) => option.value === value) || {}
        updateStates(countryId)
        break
      default:
        break
    }
  }

  useEffect(() => {
    ;(async () => {
      const countriesInputIndex = inputs.findIndex(
        ({ userField }) => userField === "address.country"
      )

      const countries = await getCountries()

      inputs[countriesInputIndex] = {
        ...inputs[countriesInputIndex],
        options: countries
      }

      const initialCountry = inputDataWithValues.find(
        ({ userField }) => userField === "address.country"
      )

      if (initialCountry.value && countries) {
        const { countryId } =
          countries?.find((option) => option.value === initialCountry.value) ||
          {}
        updateStates(countryId)
      }

      setInputs([...inputs])
    })()
  }, [])

  return (
    <InputGroup
      {...props}
      containerClass="nonDodIcLocationContainer"
      inputData={inputDataWithValues}
      onInputChange={handleChange}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              checkSubmittability,
              inputComponent,
              validators: [requiredValidators, dateOfBirthValidator]
            })}
          </>
        )
      }}
    />
  )
}

export default NonDodIcLocationInputs
