import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MediaQuery from "react-responsive"
import Loader from "react-loader-spinner"
import ChatIcon from "@material-ui/icons/Chat"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
// import { Hamburger } from '../Icons/Hamburger';
import { BackArrow } from "../../Icons/BackArrow"
import PYPPartComponentMap from "../PYPPartMap/PYPPartMap"
import PYPFooter from "../PYPFooter/PYPFooter"
import PYPPartDesktopFooterContent from "../PYPPartDesktopFooterContent/PYPPartDesktopFooterContent"
import PYPPartMobileFooterContent from "../PYPPartMobileFooterContent/PYPPartPartMobileFooterContent"
import { FullCommitLogo } from "../../Icons/FullCommitLogo"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import { lightThemePartNames } from "./lightThemeParts"
import { gtmClickHandler } from "../../Utils/gtm"
import CoachesNotesModal from "../CoachesNotesModal/CoachesNotesModal"
import coachesNotesParts from "./coachesNotesParts"
import Tooltip from "../Tooltip/Tooltip"

class PYPPartContainer extends React.Component {
  constructor(props) {
    super()
    this.lightThemePartNames = lightThemePartNames
    this.state = {
      modalOpen: false
    }
    this.clickChatIcon = this.clickChatIcon.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPartTitle !== this.props.currentPartTitle) {
      document.title = `${this.props.currentPartTitle} | ${this.props.currentModuleTitle} | Pursue Your Purpose`
      gtmClickHandler({
        name: "pageViewChangeEvent",
        data: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
    }
  }

  componentDidMount() {
    if (
      !this.props.unlockedUserModules.find((unlockedModule) => {
        return unlockedModule.module_id === this.props.currentModuleId
      })
    ) {
      // this.props.history.push('/workshop')
    }

    this.props.initializePYPPart(this.props.match, this.props.history)
    document.title = `${this.props.currentPartTitle} | ${this.props.currentModuleTitle} | Pursue Your Purpose`
  }

  footerShouldHaveDarkBackgroundColor() {
    const currentPart = this.props.parts.byId[this.props.currentPartId]
    // kind of a hack, might need a more robust implementation down the road
    return (
      currentPart.name.includes("Video") ||
      currentPart.name.includes("Ideal Work Day Roadmapper Reflection")
    )
  }

  getTheme() {
    const currentPart = this.props.parts.byId[this.props.currentPartId]
    return this.lightThemePartNames.includes(currentPart.name)
      ? "light"
      : "dark"
  }

  clickChatIcon() {
    this.props.toggleModal({
      modal_mode: true,
      modal_usi: "coachesNotes"
    })
  }

  render() {
    if (!this.props.initializedPart) {
      return (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader
            type="Oval"
            color={colors.midnight}
            height="100"
            width="100"
          />
        </div>
      )
    }
    const currentElements = this.props.parts.byId[this.props.currentPartId]
      .elements
    const elementString = currentElements
      .map((e) => this.props.elements.byId[e].name)
      .sort()
      .toString()
    const PageComponent =
      PYPPartComponentMap[elementString] || PYPPartComponentMap.default
    return (
      <>
        <div
          id="PYP_container"
          style={{
            ...styles.componentContainer,
            backgroundColor:
              this.getTheme() === "light" ? colors.background : colors.midnight
          }}
        >
          <CoachesNotesModal />
          <MediaQuery minWidth={600}>
            <div style={styles.hamburgerContainer}>
              <BackArrow
                onClick={() => {
                  gtmClickHandler({
                    name: "ExitModuleBackArrowClickedEvent",
                    data: {
                      action: "Exit Module Back Arrow Clicked",
                      category: "PYP Engagement",
                      label: this.props.match.params.module
                    }
                  })
                  // todo: we only need to save if it's certain kinds. This could be the place, but maybe not?
                  this.props.savePYP()
                  this.props.exitModule(this.props.history)
                }}
                fill={this.getTheme() === "light" ? colors.mediumBlue : "#fff"}
              />
              {coachesNotesParts.includes(this.props.currentPartTitle) && (
                <Tooltip
                  style={{
                    display: "flex"
                  }}
                  show={
                    !this.props.userGuides.length ||
                    !this.props.userGuides.find((guide) => {
                      return guide.unique_string_identifier === "COACHES_NOTES"
                    })
                  }
                  width={290}
                  height={50}
                  modalHorizontalOffset={-80}
                  arrowOffset={-45}
                  orientation="left"
                  toggleModal={() => {
                    // Called when user clicks outside of Tooltip modal
                    // removePartCompletionErrors()
                  }}
                  contentComponent={(props) => {
                    // The modal body
                    return (
                      <div
                        style={{
                          color: colors.mediumBlue,
                          fontSize: "16px",
                          lineHeight: "22px",
                          fontFamily: "Lato-Regular",
                          width: "270px"
                        }}
                      >
                        {/* This is specific to the only part currently using coaches notes. If you need to generalize, a hash map using a part name could work. */}
                        <p>
                          Click the Coach's notes icon for help thinking through
                          your ideal day.
                        </p>

                        <CommitButtonWithoutClickState
                          clickHandler={() => {
                            this.props.completeGuide("COACHES_NOTES")
                          }}
                          id="return to module"
                          type="red"
                          style={{
                            width: "150px",
                            marginTop: "20px"
                          }}
                        >
                          Got it!
                        </CommitButtonWithoutClickState>
                      </div>
                    )
                  }}
                  renderComponent={(props) => {
                    return (
                      <ChatIcon
                        style={{
                          color: colors.fadedRed,
                          marginRight: "20px",
                          cursor: "pointer"
                        }}
                        onClick={this.clickChatIcon}
                      />
                    )
                  }}
                />
              )}
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={599}>
            <div
              style={{
                ...styles.mobileHamburgerContainer,
                backgroundColor:
                  this.getTheme() === "light"
                    ? colors.background
                    : colors.midnight
              }}
            >
              <BackArrow
                style={{
                  marginLeft: "20px"
                }}
                onClick={() => {
                  // TODO: we only need to save if it's certain parts
                  this.props.savePYP()
                  this.props.exitModule(this.props.history)
                }}
                fill={this.getTheme() === "light" ? colors.mediumBlue : "#fff"}
              />
              <FullCommitLogo
                fill={
                  this.getTheme() === "light" ? colors.midnight : colors.white
                }
              />
              {/* todos: calendar icon part, getting the button working with modaldialoghandler.js, clean up code, make a PR */}
              {coachesNotesParts.includes(this.props.currentPartTitle) ? (
                <ChatIcon
                  style={{
                    color: colors.fadedRed,
                    marginRight: "20px",
                    cursor: "pointer"
                  }}
                  onClick={this.clickChatIcon}
                />
              ) : (
                <div style={{ width: "28px", marginRight: "20px" }} />
              )}
            </div>
          </MediaQuery>
          <PageComponent key={this.props.currentPartId} />
        </div>
        <PYPFooter
          style={{
            ...styles.footerContent,
            boxShadow:
              this.getTheme() === "light" &&
              "0 -20px 53px 0 rgba(0, 0, 0, 0.04)",
            backgroundColor:
              this.getTheme() === "light"
                ? colors.background
                : this.footerShouldHaveDarkBackgroundColor() && colors.midnight
          }}
          desktopComponent={
            <PYPPartDesktopFooterContent
              style={{
                ...styles.desktopFooter
              }}
            />
          }
          mobileComponent={<PYPPartMobileFooterContent />}
        />
      </>
    )
  }
}

const styles = {
  desktopFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center"
  },
  componentContainer: {
    width: "100%",
    minHeight: "100vh",
    color: "white"
  },
  footerContent: {
    display: "flex",
    zIndex: 2,
    alignItems: "center",
    width: "100%"
  },
  hamburgerContainer: {
    position: "absolute",
    paddingLeft: "50px",
    paddingTop: "50px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  mobileHamburgerContainer: {
    position: "absolute",
    paddingTop: "20px",
    paddingBottom: "20px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    parts: state.pyp.parts,
    currentPartId: state.pyp.navigation.currentPartId,
    currentPartTitle:
      state.pyp.parts &&
      state.pyp.navigation.currentPartId > -1 &&
      state.pyp.parts.byId[state.pyp.navigation.currentPartId] &&
      state.pyp.parts.byId[state.pyp.navigation.currentPartId].name,
    currentModuleId: state.pyp.navigation.currentModuleId,
    currentModuleTitle:
      state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title,
    elements: state.pyp.elements,
    unlockedUserModules: state.pyp.unlockedUserModules,
    initializedPart: state.pyp.initializedPart,
    userGuides: state.user.user.guides
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPPartContainer)
)
