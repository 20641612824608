import React from "react"

export const FormMiddle = (props) => {
  return (
    <div
      style={{
        ...styles.componentContainer,
        ...props.style
      }}
    >
      {props.children}
    </div>
  )
}

const styles = {
  componentContainer: {
    marginTop: "25px",
    paddingBottom: "30px"
  }
}
