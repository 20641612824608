import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import TransparentButton from "../TransparentButton/TransparentButton"
import { RedPlus } from "../../Icons/RedPlus"
import { RedMinus } from "../../Icons/RedMinus"
import Actions from "../../Redux/Actions"
import { gtmClickHandler } from "../../Utils/gtm"
import colors from "../../Constants/colors"

export class PlaylistControls extends React.Component {
  constructor(props) {
    super(props)
    this.removeFromPlaylist = this.removeFromPlaylist.bind(this)
    this.addToPlaylist = this.addToPlaylist.bind(this)
  }

  async addToPlaylist() {
    gtmClickHandler({
      name: "AddToPlaylistEvent",
      data: {
        action: "Add to Playlist",
        category: "Video Engagement",
        label: this.props.currentVideo.title
      }
    })
    await this.props.addToPlaylist(this.props.currentVideo.id)
    await this.props.fetchUser()
  }

  async removeFromPlaylist() {
    gtmClickHandler({
      name: "RemoveFromPlaylistEvent",
      data: {
        action: "Remove from Playlist",
        category: "Video Engagement",
        label: this.props.currentVideo.title
      }
    })
    await this.props.removeFromPlaylist(this.props.currentVideo.id)
    await this.props.fetchUser()
  }

  render() {
    return (
      <>
        {this.props.user.playlist.some((video) => {
          return video.id === this.props.currentVideo.id
        }) ? (
          <TransparentButton style={styles.buttonContainer}>
            <button onClick={this.removeFromPlaylist} style={styles.buttonSpan}>
              Remove from My Videos
            </button>
            <RedMinus />
          </TransparentButton>
        ) : (
          <TransparentButton style={styles.buttonContainer}>
            <button onClick={this.addToPlaylist} style={styles.buttonSpan}>
              Save to My Videos
            </button>
            <RedPlus />
          </TransparentButton>
        )}
      </>
    )
  }
}

const styles = {
  buttonContainer: {
    height: "30px",
    display: "flex"
  },
  buttonSpan: {
    border: "none",
    backgroundColor: "transparent",
    marginRight: "0px",
    color: colors.fadedRed
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    currentVideo: state.digitalLibrary.currentVideo,
    user: state.user.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistControls)
