import React, { Component } from "react"
import "./App.css"
import { Provider } from "react-redux"

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { BrowserRouter as Router } from "react-router-dom"
import DrawerProvider from "./Contexts/DrawerContext/DrawerContext"
import CommitApp from "./components/CommitApp/CommitApp"
import colors from "./Constants/colors.js"
import TagManager from 'react-gtm-module'

import store from "./Redux/store"

const theme = createMuiTheme({
  breakpoints: {
    values: {
      lg: 1350
    }
  },
  palette: {
    primary: {
      main: colors.midnight
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Lato-SemiBold"
  },
  overrides: {
    MuiNotchedOutline: {
      "&$focused": {
        borderWidth: "0.5px"
      }
    }
  }
})

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_SITE_GTM_ID
}

TagManager.initialize(tagManagerArgs)

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <DrawerProvider>
          <MuiThemeProvider theme={theme}>
            <Router>
              <CommitApp />
            </Router>
          </MuiThemeProvider>
        </DrawerProvider>
      </Provider>
    )
  }
}

export default App
