const types = {
  SELECTED_NON_DOD_IC_APPLICATION_NAV_ITEM: "SELECTED_NON_DOD_IC_APPLICATION_NAV_ITEM",
  FETCHED_NON_DOD_IC_APPLICATION: "FETCHED_NON_DOD_IC_APPLICATION",
  TOGGLE_NON_DOD_IC_UNFILLED: "TOGGLE_NON_DOD_IC_UNFILLED",
  SUBMIT_NON_DOD_IC_APPLICATION: "SUBMIT_NON_DOD_IC_APPLICATION",
  SUBMITTED_NON_DOD_IC_APPLICATION: "SUBMITTED_NON_DOD_IC_APPLICATION",
  EDIT_NON_DOD_IC_APPLICATION: "EDIT_NON_DOD_IC_APPLICATION",
  INIT_NON_DOD_IC_APPLICATION: "INIT_NON_DOD_IC_APPLICATION",
  SET_NON_DOD_IC_APPLICATION_INPUT_DATA: "SET_NON_DOD_IC_APPLICATION_INPUT_DATA",
  SAVING_NON_DOD_IC_APPLICATION: "SAVING_NON_DOD_IC_APPLICATION",
  NON_DOD_IC_APPLY_CLICKED: "NON_DOD_IC_APPLY_CLICKED",
  ERROR: "ERROR",  // Added this type if you need to handle errors globally
  WILL_NON_DOD_IC_RELOCATE_CHANGED: "WILL_NON_DOD_IC_RELOCATE_CHANGED", // If required
  TOGGLE_FETCHING_INTERESTS: "TOGGLE_FETCHING_INTERESTS", // If required
  UPDATE_INTERESTS: "UPDATE_INTERESTS", // If required
};

export default types;
