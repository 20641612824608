import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Actions from "../../Redux/Actions"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import SecondaryNavDrawer from "../SecondaryNavDrawer/SecondaryNavDrawer"
import MobileNavItem from "../MobileNavItem/MobileNavItem"
import ApplicationSubmitButton from "../ApplicationSubmitButton/ApplicationSubmitButton"

export const ApplicationProcessTertiaryNavDrawer = (props) => {
  const selectedOption = props.applicationOptions.find((option) => {
    return option.id === props.selectedOption.id
  })

  const processNavOptions = (option) => {
    const regEx = new RegExp(`/${option.title}/`)

    return {
      ...option,
      isSelected: regEx.test(props.match.url),
      clickHandler: () => {
        props.saveApplication(null, props.history)
        props.history.push(option.url)
        props.closeAllDrawers()
      }
    }
  }

  const filterHiddensteps = (step) => {
    return !step.hidden
  }

  const applicationProcessOptions =
    selectedOption &&
    selectedOption.steps
      .map(processNavOptions)
      .filter(filterHiddensteps)
      .map((option) => {
        return (
          <MobileNavItem
            style={{ padding: "10px 40px 10px 40px" }}
            hasSecondaryNavigation={false}
            alert={!option.filled && props.showUnfilledApplication}
            key={option.id}
            {...option}
          >
            <p style={{ gridColumnStart: 2 }}>{option.name}</p>
          </MobileNavItem>
        )
      })

  const bottomButtonHandler = () => {
    if (props.applicationIsComplete) {
      props.closeAllDrawers()
    } else {
      props.closeTertiaryDrawer()
      props.toggleUnfilled()
    }
  }

  return (
    <>
      <SecondaryNavDrawer
        headerClickHandler={() => {
          props.closeTertiaryDrawer()
        }}
        navOptions={applicationProcessOptions}
        title={selectedOption && selectedOption.title}
        bottomButtonComponent={
          <ApplicationSubmitButton
            applicationIsComplete={props.applicationIsComplete}
            onPressSubmit={bottomButtonHandler}
          />
        }
      />
    </>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    applicationOptions: state.user.user.has_submitted_application
    ? (state.user.user.is_spouse == 1 ? state.spouseApplication.spouseProfileMetadata : state.application.profileMetadata)
    : (state.user.user.is_spouse == 1 ? state.spouseApplication.spouseApplicationMetadata : state.application.applicationMetadata),
    showUnfilledApplication: state.application.showErrors,
    applicationIsComplete: state.application.applicationIsComplete
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(ApplicationProcessTertiaryNavDrawer))
)
