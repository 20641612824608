import React, { Component } from "react"

class ListWithSelectedComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      components: props.components
    }
    this.selectComponent = this.selectComponent.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // this feels a little hacky, but the only time we need to rerender
    // from a prop update is from an addition or removal of an item

    // we should rethink how were handling inputs - a lot of them
    // have state handled in two places, which is forcing us to use
    // these methods. We should only handle state in one place

    if (
      nextProps.components &&
      nextProps.components.length !== prevState.components.length
    ) {
      const newComponents = []
      for (let i = 0; i < nextProps.components.length; i++) {
        const component = nextProps.components[i]
        const index = prevState.components.findIndex(
          (e) => e.id === component.id && e.isCustom === component.isCustom
        )
        if (index < 0) {
          newComponents.push(component)
        }
      }

      return {
        components: [...newComponents, ...prevState.components]
      }
    }
    return null
  }

  selectComponent(index) {
    return (e) => {
      this.setState({
        components: this.state.components.map((component, i) => {
          return i === index
            ? { ...component, isSelected: !component.isSelected }
            : component
        })
      })
    }
  }

  render() {
    return (
      <>
        {this.state.components.map(({ isSelected, renderComponent }, i) => {
          return renderComponent({
            isSelected,
            selectOption: this.selectComponent(i),
            key: i,
            index: i
          })
        })}
      </>
    )
  }
}

export default ListWithSelectedComponent
