import React from "react"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import FourOhFour from "../FourOhFour/FourOhFour"

export default withRouter(function AdminErrorRenderer(props) {
  const state = useSelector((state) => ({
    roleId: state.user && state.user.user.role && state.user.user.role.id,
    fetchingUser: state.user && state.user.fetchingUser
  }))

  const goToHomePage = () => {
    props.history.push("/account/login")
  }

  return state.roleId === 1 ? (
    <> {props.children} </>
  ) : state.fetchingUser ? (
    <></>
  ) : (
    <FourOhFour homePageHandler={goToHomePage} />
  )
})
