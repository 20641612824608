import React from "react"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import TransparentButton from "../TransparentButton/TransparentButton"
import CommitInput from "../CommitInput/CommitInput"

export default class RoadmapperEditInputCalenderContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      event: {
        ...this.props.timeEvent
      }
    }
    this.deleteTimeEvent = this.deleteTimeEvent.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onTimeChange = this.onTimeChange.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
  }

  deleteTimeEvent = () => {
    this.props.deleteTimeEvent()
  }

  onChange = (e) => {
    const event = {
      ...this.state.event,
      [e.target.name]: e.target.value
    }
    this.props.updateEditEvent({ ...event })
    this.setState({ event })
  }

  onTimeChange = (e) => {
    const event = {
      ...this.state.event,
      moment_data: e.target.value
    }
    this.props.updateEditEvent({ ...event })
    this.setState({ event })
  }

  onTitleChange = (e) => {
    if (e.target.value.length < 255) {
      const event = {
        ...this.state.event,
        title: e.target.value
      }
      this.props.updateEditEvent({ ...event })
      this.setState({ event })
    }
  }

  componentDidMount() {
    this.props.updateEditEvent(this.props.timeEvent)
    const editedEvent = document.getElementById("roadmapperEditedEvent")
    editedEvent.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  render() {
    return (
      <div style={styles.container} id="roadmapperEditedEvent">
        <CommitInput
          inputType="text"
          placeHolder="Event Title"
          handleChange={this.onTitleChange}
          value={this.state.event.title}
        />
        <CommitInput
          title="Completion Date"
          inputType="date"
          // style={styles.inputStyle}
          placeHolder="Pick a Completion Date"
          value={this.state.event.moment_data}
          handleChange={this.onTimeChange}
        >
          {this.state.event.moment_data}
        </CommitInput>
        <div style={styles.buttonContainer}>
          <div style={styles.deleteButtonContainer}>
            <TransparentButton
              // style={styles.deleteButton}
              onClick={this.deleteTimeEvent}
            >
              Delete
            </TransparentButton>
          </div>
          <CommitButtonWithClickState
            type="red"
            clickHandler={this.props.clickHandler}
            id="FinsihEditingEvent"
            style={styles.saveButton}
          >
            {" "}
            Done{" "}
          </CommitButtonWithClickState>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: "inline-block",
    width: "100%",
    padding: "30px 18px 23px 25px",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px"
    // backgroundColor: colors.midGray
  },
  title: {
    // width: "291px",
    height: "18px",
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "17px",
    color: "#292E4C",
    margin: "0"
  },
  titleInput: {
    width: "100%",
    margin: "auto"
  },
  block: {
    display: "flex"
  },
  buttonContainer: {
    display: "flex",
    float: "right"
  },
  // deleteButton: {
  //   width: "62px",
  // },
  deleteButtonContainer: {
    width: "62px",
    margin: "auto",
    marginRight: "10px",
    marginLeft: "10px",
    height: "fit-content"
  },
  saveButton: {
    width: "133px"
  },
  valueSegment: {
    width: "calc(100% - 173px)",
    textAlign: "left"
  },
  durationSegment: {
    width: "153px",
    textAlign: "left",
    marginRight: "13px",
    fontSize: "13px"
  }
}
