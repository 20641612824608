import moment from "moment"
import React from "react"
import MediaQuery from "react-responsive"
import { withRouter } from "react-router-dom"
import CONSTANTS from "../../Constants"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

const styles = {
  commitButton: {
    fontFamily: "Lato-Bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    borderRadius: 3
  }
}

export default withRouter(function UserBiographyRender({ user, history }) {
  const militaryStatus = user?.military?.branch || "branch"
  const retirementDate = moment(user?.military?.retirementDate).format("LL")

  const redirectToEditBio = () => {
    history.push(`/profile?title=${CONSTANTS.PROFILE_FIRST_PAGE}`)
  }

  const BiographyText = () => {
    return (
      <div className="userBiographyContainer">
        <p
          id="userBiographyText"
          className="userBiographyTextClamped"
          style={{
            WebkitBoxOrient: "vertical"
          }}
        >
          {user?.biography?.biography}{" "}
        </p>
      </div>
    )
  }
  const RetirementDate = () => {
    return user?.military?.retirementDate ? (
      <p className="separation-date">
        <strong>Date of Separation: </strong>
        {retirementDate}
      </p>
    ) : null
  }

  return (
    <>
      <MediaQuery minWidth={651}>
        <header className="userBiographyHeader">
          <h1 className="userBiographyUsername">
            {user?.firstName} {user?.lastName}
          </h1>
          {/* To change when we have a way to identify 2S on the DB */}
          {user.workshops.length && (
            <h3 className="userBiographyMilitary">{militaryStatus}</h3>
          )}
        </header>
        <BiographyText />
        <RetirementDate />
        <span className="userBiographyRedirect">
          <CommitButtonWithClickState
            id="userBiographyRedirect"
            type="white"
            clickHandler={() => redirectToEditBio()}
            style={{ color: "#000000", width: "50%", ...styles.commitButton }}
          >
            Edit Profile
          </CommitButtonWithClickState>
        </span>
      </MediaQuery>
      <MediaQuery maxWidth={650}>
        <BiographyText />
        <RetirementDate />
        <span className="userBiographyRedirect">
          <CommitButtonWithClickState
            id="userBiographyRedirect"
            type="white"
            clickHandler={() => redirectToEditBio()}
            style={{ color: "#000000", width: "50%", ...styles.commitButton }}
          >
            Edit Profile
          </CommitButtonWithClickState>
        </span>
      </MediaQuery>
    </>
  )
})
