import { useSelector } from "react-redux"

// TODO: get the filters into their own hook for cohesion

// commenting out because COMMIT might change ideas about
// competitive tier DL access
// const tierFilter = user => navOption => {
//   return {
//     ...navOption,
//     toDisplay: user.competitive_tier > -1 || navOption.usi === "logout"
//   }
// }

const homeFilter = (user) => (navOption) => {
  if (navOption.usi === "home") {
    if (user.has_submitted_application && user.competitive_tier > -1) {
      return {
        ...navOption,
        toDisplay: true
      }
    }
    return {
      ...navOption,
      toDisplay: false
    }
  }
  return {
    ...navOption,
    toDisplay: navOption.toDisplay
  }
}

const pypFilter = (user) => (navOption) => {
  if (navOption.usi === "pyp") {
    if (
      user.programs &&
      user.programs.some(
        (program) =>
          program.name === "Pursue Your Purpose" &&
          program.pivot.status === "approved"
      ) &&
      process.env.REACT_APP_PYP_ENABLED !== "false"
    ) {
      return {
        ...navOption,
        toDisplay: true
      }
    }
    return {
      ...navOption,
      toDisplay: false
    }
  }
  return {
    ...navOption,
    toDisplay: navOption.toDisplay
  }
}

const spouse_filter = (user, workshops) => (navOption) => {
  if (navOption.usi === "spouseCourse") {
    if (workshops.length) {
      return {
        ...navOption,
        toDisplay: true
      }
    }
    return {
      ...navOption,
      toDisplay: false
    }
  }
  return {
    ...navOption,
    toDisplay: navOption.toDisplay
  }
}

const networkFilter = (user) => (navOption) => {
  if (navOption.usi === "network") {
    if (
      user.programs &&
      user.programs.some(
        (program) =>
          program.name === "Network" && program.pivot.status === "approved"
      ) &&
      user.community &&
      user.community.community_member === 1 &&
      user.linkedin_url
    ) {
      return {
        ...navOption,
        toDisplay: true
      }
    }
    return {
      ...navOption,
      toDisplay: false
    }
  }
  return {
    ...navOption,
    toDisplay: navOption.toDisplay
  }
}

const digitalLibraryFilter = (user) => (navOption) => {
  if (navOption.usi === "digitalLibrary") {
    if (
      user.programs &&
      user.programs.some(
        (program) =>
          program.name === "Digital Library" &&
          program.pivot.status === "approved"
      ) &&
      process.env.REACT_APP_DL_ENABLED !== "false"
    ) {
      return {
        ...navOption,
        toDisplay: true
      }
    }
    return {
      ...navOption,
      toDisplay: false
    }
  }
  return {
    ...navOption,
    toDisplay: navOption.toDisplay
  }
}

const analyticsFilter = (user) => (navOption) => {
  if (navOption && navOption.usi === "analytics") {
    if (user.role && user.role.id === 1) {
      return {
        ...navOption,
        toDisplay: true
      }
    }
    return {
      ...navOption,
      toDisplay: false
    }
  }
  return {
    ...navOption,
    ntoDisplay: navOption.toDisplay
  }
}

const runFilledMiddleware = (...funcs) => {
  return (value) => {
    return funcs.reduceRight((acc, func) => {
      return acc.map(func)
    }, value)
  }
}

const useNavOptions = (match) => {
  const { user, workshops } = useSelector((state) => ({
    user: state.user.user,
    workshops: state.workshop?.workshops
  }))
  const options = [
    {
      text: "Home",
      usi: "home",
      isSelected: /home/.test(match.path)
    },
    {
      text: "Workshops",
      usi: "spouseCourse",
      isSelected: /virtualcohort/.test(match.path)
    },
    {
      text: "Digital Library",
      usi: "digitalLibrary",
      isSelected: /digitalLibrary/.test(match.path)
    },
    {
      text: "Pursue Your Purpose",
      usi: "pyp",
      isSelected: /workshop/.test(match.path)
    },
    {
      text:
        user && user.has_submitted_application
          ? "Edit Profile"
          : "Edit Application",
      usi: "editProfile",
      isSelected: /profile/.test(match.path),
      toDisplay: true
    },
    {
      text: "Analytics",
      usi: "analytics",
      isSelected: /analytics/.test(match.path),
      toDisplay: true
    },
    {
      text: "Network",
      usi: "network",
      isSelected: /network/.test(match.path),
      toDisplay: true
    },
    { text: "Logout", usi: "logout", toDisplay: true }
  ]

  const filteredOptions = runFilledMiddleware(
    pypFilter(user),
    homeFilter(user),
    spouse_filter(user, workshops),
    analyticsFilter(user),
    // tierFilter(user),
    digitalLibraryFilter(user),
    networkFilter(user)
  )(options)

  return filteredOptions
}

export default useNavOptions
