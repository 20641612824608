import types from "../../Actions/types"
import { preSurveyMetadata } from "./preSurveyMetadata"
import { applicationMetadata } from "./applicationMetadata"
import { profileMetadata } from "./profileMetadata"
import { spouseProfileMetadata } from "../SpouseApplication/spouseProfileMetadata"
import { postSurveyMetadata } from "./postSurveyMetadata"
import * as utils from "../../Utils/reducerUtils"

const applicationReducer = (
  state = {
    showErrors: false,
    applicationMetadata,
    profileMetadata,
    preSurveyMetadata,
    postSurveyMetadata,
    initialized: false,
    userWillReLocate: false,
    submittingApplication: false,
    saving: false,
    application: {},
    applicationIsComplete: false,
    interests: [],
    fetchingInterests: false,
    spouseProfileMetadata
  },
  action
) => {
  switch (action.type) {
    case types.SUBMITTED_APPLICATION:
      return {
        ...state,
        submittingApplication: false
      }
    case types.LOGOUT:
      return {
        showErrors: false,
        applicationMetadata,
        profileMetadata,
        preSurveyMetadata,
        postSurveyMetadata,
        initialized: false
      }
    case types.TOGGLE_UNFILLED:
      return {
        ...state,
        showErrors: true,
        applicationMetadata: state.applicationMetadata.map((input) => {
          return {
            ...input,
            steps: input.steps.map((step) => {
              return !step.filled
                ? {
                    ...step,
                    unseenError: true
                  }
                : step
            })
          }
        }),
        profileMetadata: state.profileMetadata.map((input) => {
          return {
            ...input,
            steps: input.steps.map((step) => {
              return !step.filled
                ? {
                    ...step,
                    unseenError: true
                  }
                : step
            })
          }
        })
      }
    case types.SUBMIT_APPLICATION:
      
      return {
        ...state,
        showErrors: false,
        submittingApplication: true
      }
    case types.INIT_APPLICATION:
      
      const updatedMetaData = utils.processNavOptionsForApplication(
        action.data.application,
        state.applicationMetadata
      )
      const metaData1 = action.data.application && action.data.application.is_spouse === 1 ? (state.spouseProfileMetadata ? state.spouseProfileMetadata : spouseProfileMetadata): (state.application && state.application.profileMetadata ? state.application.profileMetadata : profileMetadata)
      const updatedProfileMetaData = utils.processNavOptionsforProfile(
        action.data.application,
        metaData1
      )

      return {
        ...state,
        application: action.data.application,
        applicationMetadata: updatedMetaData,
        applicationIsComplete: utils.allFormsFilled(updatedMetaData),
        profileIsComplete: utils.allFormsFilled(updatedProfileMetaData),
        initialized: true,
        profileMetadata: updatedProfileMetaData,
        userWillReLocate:
          action.data.application.address &&
          action.data.application.address.willLocate === "Yes"
      }
      case types.FETCHED_SPOUSE_APPLICATION:
        
        const updatedSpouseApplicationMetaData = utils.processNavOptionsForApplication(
          action.data.application,
          state.applicationMetadata
        )
        const updatedSpouseProfileData = utils.processNavOptionsforProfile(
          action.data.application,
          state.profileMetadata
        )
        return {
          ...state,
          spouseApplication: action.data.application,
          applicationMetadata: updatedSpouseApplicationMetaData,
          saving: false,
          applicationIsComplete: utils.allFormsFilled(updatedSpouseApplicationMetaData),
          profileIsComplete: utils.allFormsFilled(updatedSpouseProfileData),
          profileMetadata: updatedSpouseProfileData,
          currentInputData: null,
          userWillReLocate:
            action.data.application.address &&
            action.data.application.address.willLocate === "Yes"
        }
    case types.SET_APPLICATION_INPUT_DATA:
      return {
        ...state,
        currentInputData: action.data.inputData
      }
    case types.LOGIN:
      
      return {
        ...state,
        initialized: false,
        showErrors: false
      }
    case types.EDIT_APPLICATION:
      
      return {
        ...state,
        currentInputData: null,
        saving: false
      }

    case types.FETCHED_APPLICATION:
      
      const updatedApplicationMetaData = utils.processNavOptionsForApplication(
        action.data.application,
        state.applicationMetadata
      )

      const metaData2 = action.data.application && action.data.application.is_spouse === 1 ? (state.spouseProfileMetadata ? state.spouseProfileMetadata : spouseProfileMetadata): (state.application && state.application.profileMetadata ? state.application.profileMetadata : profileMetadata)
      const updatedProfileData = utils.processNavOptionsforProfile(
        action.data.application,
        metaData2
      )

      return {
        ...state,
        application: action.data.application,
        applicationMetadata: updatedApplicationMetaData,
        saving: false,
        applicationIsComplete: utils.allFormsFilled(updatedApplicationMetaData),
        profileIsComplete: utils.allFormsFilled(updatedProfileData),
        profileMetadata: updatedProfileData,
        currentInputData: null,
        userWillReLocate:
          action.data.application.address &&
          action.data.application.address.willLocate === "Yes"
      }
    case types.SAVING_APPLICATION:
      
      return {
        ...state,
        saving: true
      }
    case types.WILL_RELOCATE_CHANGED:
      
      const updatedMeta = utils.processNavOptionsForApplication(
        state.application,
        state.applicationMetadata
      )

      const metaData3 = action.data.application && action.data.application.is_spouse === 1 ? (state.spouseProfileMetadata ? state.spouseProfileMetadata : spouseProfileMetadata): (state.application && state.application.profileMetadata ? state.application.profileMetadata : profileMetadata)
      const updatedProfileMeta = utils.processNavOptionsforProfile(
        state.application,
        metaData3
      )

      return {
        ...state,
        userWillReLocate: action.data.value,
        applicationMetadata: updatedMeta,
        profileMetadata: updatedProfileMeta
      }
    case types.UPDATE_INTERESTS:
      
      return {
        ...state,
        interests: action.data.interests
      }
    case types.TOGGLE_FETCHING_INTERESTS:
      return {
        ...state,
        fetchingInterests: action.data.toggle
      }
    case types.UPDATE_LIKERT_SPOUSE:
        const { userField, value } = action.payload;
        const newState = { ...state };
        const fieldArray = userField.split(".");
        let currentState = newState.spouseApplication.application;
        for (let i = 0; i < fieldArray.length - 1; i++) {
            currentState = currentState[fieldArray[i]];
        }

        // Update the value at the specified location
        currentState[fieldArray[fieldArray.length - 1]] = value;

      return newState;

    default:
      return {
        ...state
      }
  }
}

export default applicationReducer
