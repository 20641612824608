import React from "react";
import { debounce } from "debounce";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer";
import Actions from "../../Redux/Actions";
import UserFieldEditorNavMenu from "../UserFieldEditorNavMenu/UserFieldEditorNavMenu";
import AlliedForcesApplicationProgressCircle from "../AlliedForcesApplicationProgressCircle/AlliedForcesApplicationProgressCircle"; // Adjusted import path
import { allFormsFilled } from "../../Redux/Utils/reducerUtils";

class AlliedForcesApplicationNavMenu extends React.Component { // Adjusted component name
  constructor(props) {
    super(props);

    this.state = {
      showSubmitTooltip: false, // whether or not to display the 'you must complete all sections' tooltip
    };

    this.navItemClickHandler = this.navItemClickHandler.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getSubmitState = this.getSubmitState.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  handleClickOutside() {
    this.setState({
      showSubmitTooltip: false,
    });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  navItemClickHandler(id) {
    return debounce((e) => {
      this.props.alliedForcesApplication.currentInputData && // Adjusted to match Allied Forces application
        this.props.saveAlliedForcesApplication( // Adjusted action function
          this.props.alliedForcesApplication.currentInputData,
          this.props.history
        );
    }, 200);
  }

  submitHandler() {
    if (!allFormsFilled(this.props.alliedForcesApplication.applicationMetadata)) { // Adjusted to match Allied Forces application
      // Display the Tooltip modal
      this.setState({
        showSubmitTooltip: true,
      });
    }
  }

  saveAlliedForcesApplication(checkSubmittability, inputData) { // Adjusted function name
    return async (e) => {
      if (inputData) {
        inputData && checkSubmittability
          ? checkSubmittability(inputData) &&
            this.props.saveAlliedForcesApplication(inputData, this.props.history) // Adjusted action function
          : await this.props.saveAlliedForcesApplication(inputData, this.props.history); // Adjusted action function
      }
    };
  }

  getSubmitState() {
    if (allFormsFilled(this.props.alliedForcesApplication.applicationMetadata)) { // Adjusted to match Allied Forces application
      if (this.props.alliedForcesApplication.alliedForcesApplication.has_submitted_application) { // Adjusted to match Allied Forces application
        return "submitted";
      }
      return "finished";
    }
    if (this.props.alliedForcesApplication.alliedForcesApplication.has_submitted_application) { // Adjusted to match Allied Forces application
      return "editing and unfinished";
    }
    return "unfinished";
  }

  render() {
    return (
      <UserFieldEditorNavMenu
        showSubmitTooltip={this.state.showSubmitTooltip}
        userFieldData={this.props.alliedForcesApplication.applicationMetadata} // Adjusted to match Allied Forces application
        navItemClickHandler={this.navItemClickHandler}
        submitState={this.getSubmitState()}
        submitHandler={this.submitHandler}
        progressComponent={<AlliedForcesApplicationProgressCircle />} // Adjusted progress component
        applicationIsComplete={allFormsFilled(
          this.props.alliedForcesApplication.applicationMetadata // Adjusted to match Allied Forces application
        )}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => ({
  alliedForcesApplication: state.alliedForcesApplication, // Adjusted to match Allied Forces application state
  user: state.user.user,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithDrawerConsumer(AlliedForcesApplicationNavMenu)) // Adjusted component name
);
