import React from "react"
import { Link } from "react-router-dom"
import colors from "../../Constants/colors"

const WithDots = (props) => {
  const getBackgroundColor = (step) => {
    if (!step.filled && step.visited) {
      return colors.fadedRed
    }

    if (step.filled) {
      return colors.white
    }

    return colors.gray
  }

  const buildDots = (step) => {
    return (
      <Link
        to={`${step.url}&reload=true`}
        key={step.stepId}
        style={{
          height: "7px",
          marginRight: "5px",
          width: "7px",
          borderRadius: "3.5px",
          backgroundColor: getBackgroundColor(step)
        }}
      />
    )
  }

  return (
    <>
      {props.renderComponent({
        ...props
      })}
      <div style={{ display: "flex", width: "100%", marginLeft: "30px" }}>
        {props.dots.map(buildDots)}
      </div>
    </>
  )
}

export default WithDots
