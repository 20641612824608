import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import moment from "moment"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import Actions from "../../Redux/Actions"
import VideoCategoryWrapper from "../VideoCategoryWrapper/VideoCategoryWrapper"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"
import { useWindowResize } from "../../Hooks/useWindowResize"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"

const DialogContent = MuiDialogContent

const FilteredPlaylists = ({ filter, history }) => {
  const { width } = useWindowResize()
  Object.keys(baseStyles).forEach((key) => {
    styles[key] = baseStyles[key]
  })
  if (width < 601) {
    Object.keys(smallVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...smallVPStyles[key] }
    })
  }

  const { playlists, retirementDate, guides } = useSelector((state) => ({
    ...state.digitalLibrary.transition,
    ...state.user.user.military,
    ...state.user.user
  }))
  const dispatch = useDispatch()
  const [numThumbs, updateNumThumbs] = useState(4)

  useEffect(() => {
    const fetchPlaylists = () => {
      dispatch(Actions.fetchPlaylists(filter))
    }

    const setDocumentTitle = () => {
      document.title = `All | Playlists | The Digital Library`
      window.dataLayer.push({
        page_view: {
          page_title: document.title
        }
      })
    }

    setDocumentTitle()

    fetchPlaylists()
  }, [])

  const receiveVideoDisplayCount = (num) => {
    if (numThumbs !== num) {
      updateNumThumbs(num)
    }
  }

  const fillCategoryWrapper = (playlist, index) => {
    return (
      <VideoCategoryWrapper
        clearVideos={() => console.log("all videos")}
        category={{ name: playlist.title }}
        videoCount={playlist.videos && playlist.videos.length}
        notAllVideosShown={
          playlist.videos && playlist.videos.length > numThumbs
        }
        redirect={() =>
          history.push(`/digitalLibrary/videoPlaylists/${playlist.id}`)
        }
        subtitle={playlist.subtitle}
      >
        <VideoThumbnailList
          from="transition"
          playlist={playlist.title}
          loaded={!!(playlist.videos && playlist.videos.length)}
          skeletonLoaderCount={8}
          style={{
            justifyContent: "flex-start"
          }}
          dynamicThumbnailCountOnResize
          videos={playlist && playlist.videos}
          sendVideoDisplayCount={receiveVideoDisplayCount}
          alwaysSendNumbers
        />
      </VideoCategoryWrapper>
    )
  }
  const renderedPlaylists =
    (playlists.length &&
      playlists.map((playlist, index) =>
        fillCategoryWrapper(playlist, index)
      )) ||
    [
      { videos: [{}, {}, {}, {}, {}, {}, {}, {}] },
      { videos: [{}, {}, {}, {}, {}, {}, {}, {}] }
    ].map((playlist, index) => fillCategoryWrapper(playlist, index))

  const monthsUntilSeparation = Math.round(
    (moment(retirementDate).unix() - moment().unix()) / 2629743
  )

  const handleClose = () => {
    dispatch(Actions.completeGuide("JOURNEY_OVERVIEW"))
  }

  const modal = (
    <div style={styles.modalContainer}>
      <Dialog
        style={{ padding: "0px", margin: "0px" }}
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={
          width > 600 &&
          (!guides.length ||
            !guides.find((guide) => {
              return guide.unique_string_identifier === "JOURNEY_OVERVIEW"
            }))
        }
        PaperProps={{ style: { ...styles.modalContainer } }}
      >
        <DialogContent style={styles.modalTextBox}>
          <p style={styles.modalTitle}>
            Welcome to the Digital Library Journey
          </p>
          <p style={styles.modalBody}>
            The Digital Library is a tremendous resource to reframe transition
            challenges as opportunities. In an effort to align these videos with
            your individual transition timing, we’ve built the Digital Library
            Journey. As you approach your retirement or separation date, the
            Journey playlist will update to strategically sequence messages
            worthy of consideration at the optimum time.
          </p>
        </DialogContent>
        <div style={styles.modalFooter}>
          <CommitButtonWithoutClickState
            clickHandler={() => handleClose()}
            id="journey overview"
            type="red"
            style={styles.modalButton}
          >
            Got It!{" "}
          </CommitButtonWithoutClickState>
        </div>
      </Dialog>
    </div>
  )

  const title =
    monthsUntilSeparation > 0
      ? monthsUntilSeparation === 1
        ? "You are 1 Month from Transition"
        : `You are ${monthsUntilSeparation} Months from Transition`
      : `You are ${monthsUntilSeparation * -1} months Past Transition`

  const headerBodyText = (
    <p style={styles.headerBody}>
      The videos here are specifically curated to your individual transition
      timeline. Exploring these concepts in this deliberate sequence will
      reinforce a purposeful transition plan. You can catch up on resources by
      scrolling down.{" "}
      {monthsUntilSeparation > 6
        ? `The next batch of videos will appear when you are
        ${
          monthsUntilSeparation > 15
            ? "15 "
            : monthsUntilSeparation > 12
            ? "12 "
            : "6 "
        }
        months from transition.`
        : ""}
    </p>
  )

  const renderedTitle = (
    <div style={styles.titleContainer}>
      <p style={styles.title}>{title}</p>
      {headerBodyText}
    </div>
  )

  return (
    <>
      {modal}
      <div style={styles.container}>
        {renderedTitle}
        {renderedPlaylists}
      </div>
    </>
  )
}

const styles = {}

const baseStyles = {
  loading: {
    width: "fit-content",
    margin: "auto",
    marginTop: "55px"
  },
  container: {
    marginTop: "25px"
  },
  title: {
    maxWidth: "1000px",
    width: "100%",
    height: "fit-content",
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "25px",
    lineHeight: "28px",
    color: "#2A2434",
    marginTop: "15px",
    marginBottom: "20px"
  },
  titleContainer: {
    maxWidth: "1000px",
    width: "93%",
    margin: "auto",
    marginLeft: "3.5%"
  },
  headerBody: {
    maxWidth: "1000px",
    width: "100%",
    margin: "auto",
    height: "fit-content",
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#2A2434",
    marginBottom: "30px"
  },
  modalTitle: {
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "30px",
    lineHeight: "42px",
    paddingBottom: "30px"
  },
  modalBody: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "30px"
  },
  modalTextBox: {
    padding: "20px",
    width: "calc(100% - 100px)",
    margin: "auto"
  },
  modalContainer: {
    maxWidth: "800px",
    width: "80%",
    background: "#F5F6F8"
  },
  modalButton: {
    width: "150px",
    marginRight: "24px",
    marginLeft: "calc(100% - 174px)",
    marginBottom: "18px",
    marginTop: "17px"
  },
  modalFooter: {
    width: "100%",
    height: "73px",
    background: "white"
  }
}

const smallVPStyles = {}

export default withRouter(FilteredPlaylists)
