import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch, useSelector } from "react-redux"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import { toggleModal } from "../../Redux/Actions/User/functions"
import { finishPyp } from "../../Redux/Actions/PYP/functions"

export const modalUsi = "pypModal"

const styles = {
  footerButton: {
    width: "100px",
    marginLeft: "20x",
    marginRight: "26px",
    marginBottom: "20px"
  },
  modalContainer: {
    maxWidth: "800px",
    width: "85%",
    background: "#F5F6F8"
  },
  overlay: {
    padding: "40px"
  },
  text: {
    fontFamily: "Lato-Regular",
    fontSize: "16px"
  }
}

const PYPModal = () => {
  const dispatch = useDispatch()
  const currentModuleId = useSelector(
    (state) => state.pyp.navigation.currentModuleId
  )

  const closeModal = () => {
    dispatch(
      toggleModal({
        modal_mode: false,
        modal_usi: modalUsi
      })
    )
  }

  const onOkClick = () => {
    dispatch(finishPyp(currentModuleId))
    closeModal()
  }

  return (
    <ModalDialogHandler
      modal_usi={modalUsi}
      paperProps={{ style: styles.modalContainer }}
      footer={
        <DialogActions>
          <CommitButtonWithoutClickState
            clickHandler={onOkClick}
            id={modalUsi}
            type="white"
            style={styles.footerButton}
          >
            Complete
          </CommitButtonWithoutClickState>
        </DialogActions>
      }
    >
      <div style={styles.overlay}>
        <h5>CONGRATULATIONS!</h5>
        <p style={styles.text}>
          You have completed COMMIT&apos;s online transitioning platform, Pursue
          Your Purpose. Upon completion of Pursue Your Purpose, you will receive
          a link via email enabling you to schedule a call with your Director of
          Veteran Services to discuss the next steps in your work with COMMIT
          Foundation. If you do not receive this email please reach out to us at
          <a href="mailto:dvs@commitfounation.org"> dvs@commitfounation.org</a>.
        </p>
      </div>
    </ModalDialogHandler>
  )
}

export default PYPModal
