import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setInputData } from "../../Redux/Actions/Application/functions"
import { toggleModal } from "../../Redux/Actions/User/functions"

import { modalUsi } from "../SimpleModal/SimpleModal"

import CheckBox from "../CheckBox"

import "./Transition.css"

const TransitionGoals = () => {
  const dispatch = useDispatch()

  const { goals, options } = useSelector((state) => ({
    goals: !window.location.href.includes('spouse') ? state.application.application.transition?.goals || "" : state.spouseApplication.application.transition?.goals || "",
    options: !window.location.href.includes('spouse') ? 
      Object.values(state.formulary.application.inputs).find(
        ({ section }) => section === "transition"
      )?.options || []
      :  [
        // Update the options array with the provided options
        { id: 1, text: "Identify who I am as a person" },
        { id: 2, text: "Increase communication about transition with my spouse" },
        { id: 3, text: "Develop a strategy for personal career growth" },
        { id: 4, text: "Share my experiences with others" },
        { id: 5, text: "Understand more about my own purpose" },
        { id: 6, text: "Create goals for my personal purpose work after the military" },
        { id: 7, text: "Meet others that are going through a shared experience" },
        { id: 8, text: "Gain confidence in my personal skills in abilities" },
        { id: 9, text: "Networking opportunities" }
      ]
  }))

  const [goalsValues, setGoalsValues] = useState(() =>
    goals.split(";").filter((goal) => Boolean(goal))
  )

  const onOptionClick = (option) => {
    const isOptionSelected = goalsValues.includes(option.text)
    const newGoalsValues = isOptionSelected
      ? goalsValues.filter((value) => value !== option.text)
      : [...goalsValues, option.text]

    if (newGoalsValues.length > 3) {
      dispatch(
        toggleModal({
          modal_mode: true,
          modal_usi: modalUsi,
          title: "ERROR",
          text: "You can only choose up to three options."
        })
      )
      return
    }

    setGoalsValues(newGoalsValues)

    /* Doing this to keep backwards compatibility with legacy implementation */
    dispatch(
      setInputData([
        {
          userField: "transition.goals",
          value: newGoalsValues.join(";"),
          required: true
        }
      ])
    )
  }

  return (
    <div className="transition-goals-container">
      {options.map((option) => (
        <CheckBox
          key={option.id}
          id={option.id.toString()}
          text={option.text}
          checked={goalsValues.includes(option.text)}
          onClick={() => onOptionClick(option)}
          className="checkBox"
        />
      ))}
    </div>
  )
}

export default TransitionGoals
