import React from "react"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import TransparentButton from "../TransparentButton/TransparentButton"
import CommitInput from "../CommitInput/CommitInput"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

export default class RoadmapperEditInputContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      event: {
        ...this.props.timeEvent
      }
    }
    this.deleteTimeEvent = this.deleteTimeEvent.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onValueChange = this.onValueChange.bind(this)
    this.onDurationChange = this.onDurationChange.bind(this)
    this.onTitleChange = this.onTitleChange.bind(this)
  }

  deleteTimeEvent = () => {
    this.props.deleteTimeEvent()
  }

  onChange = (e) => {
    const event = {
      ...this.state.event,
      [e.target.name]: e.target.value
    }
    this.props.updateEditEvent({ ...event })
    this.setState({ event })
  }

  onValueChange = (e) => {
    const event = {
      ...this.state.event,
      value: e.target.value
    }
    this.props.updateEditEvent({ ...event })
    this.setState({ event })
  }

  onDurationChange = (e) => {
    const event = {
      ...this.state.event,
      duration: parseInt(e.target.value)
    }
    this.props.updateEditEvent({ ...event })
    this.setState({ event })
  }

  onTitleChange = (e) => {
    if (e.target.value.length < 255) {
      const event = {
        ...this.state.event,
        title: e.target.value
      }
      this.props.updateEditEvent({ ...event })
      this.setState({ event })
    }
  }

  componentDidMount() {
    this.props.updateEditEvent(this.props.timeEvent)
    const editedEvent = document.getElementById("roadmapperEditedEvent")
    editedEvent.scrollIntoView({ behavior: "smooth" })
  }

  render() {
    return (
      <div style={styles.container} id="roadmapperEditedEvent">
        {this.props.timeEvent.id === 0 ? (
          <SkeletonWrapper />
        ) : (
          <>
            <CommitInput
              inputType="text"
              placeHolder="Activity"
              handleChange={this.onTitleChange}
              value={this.state.event.title}
            />
            <div style={styles.block}>
              <CommitInput
                style={styles.durationSegment}
                name="duration"
                title="Time Allowed"
                inputType="select"
                value={`${this.state.event.duration} minutes`}
                options={[
                  { value: `15 minutes` },
                  { value: `30 minutes` },
                  { value: `45 minutes` },
                  { value: `60 minutes` },
                  { value: `90 minutes` },
                  { value: `120 minutes` }
                ]}
                handleChange={this.onDurationChange}
              />
              <CommitInput
                style={styles.valueSegment}
                title="Value"
                inputType="select"
                name="value"
                value={this.state.event.value}
                options={
                  this.props.values &&
                  this.props.values.map((value) => {
                    return {
                      value: value.name
                    }
                  })
                }
                handleChange={this.onValueChange}
              />
            </div>
            <div style={styles.buttonContainer}>
              <div style={styles.deleteButtonContainer}>
                <TransparentButton
                  // style={styles.deleteButton}
                  onClick={this.deleteTimeEvent}
                >
                  Delete
                </TransparentButton>
              </div>
              <CommitButtonWithClickState
                type="red"
                clickHandler={this.props.clickHandler}
                id="FinsihEditingEvent"
                style={styles.saveButton}
              >
                {" "}
                Done{" "}
              </CommitButtonWithClickState>
            </div>
          </>
        )}
      </div>
    )
  }
}

const styles = {
  container: {
    display: "inline-block",
    width: "100%",
    padding: "30px 18px 23px 25px",
    boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
    borderRadius: "3px"
  },
  title: {
    height: "18px",
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "17px",
    color: "#292E4C",
    margin: "0"
  },
  titleInput: {
    width: "100%",
    margin: "auto"
  },
  block: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  buttonContainer: {
    display: "flex",
    float: "right"
  },
  // deleteButton: {
  //   width: "62px",
  // },
  deleteButtonContainer: {
    width: "62px",
    margin: "auto",
    marginRight: "10px",
    marginLeft: "10px",
    height: "fit-content"
  },
  saveButton: {
    width: "133px"
  },
  valueSegment: {
    width: "calc(100% - 175px)",
    minWidth: "160px",
    maxWidth: "340px",
    textAlign: "left",
    marginLeft: "5px"
  },
  durationSegment: {
    width: "165px",
    textAlign: "left",
    marginRight: "auto",
    fontSize: "13px"
  }
}
