import React from "react"
import colors from "../../Constants/colors.js"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

/* props:
programTitle
programDescritpion
statusText
buttonText
buttonAction
*/

export const LockedProgramView = (props) => {
  return (
    <div
      style={{
        ...styles.componentContainer,
        ...props.style
      }}
    >
      <div style={styles.content}>
        <h1
          style={{
            fontFamily: "Lato-SemiBold",
            fontSize: "40px",
            lineHeight: "42px",
            letterSpacing: "2px"
          }}
        >
          {props.programTitle}
        </h1>

        <div
          style={{
            height: "1px",
            width: "140px",
            backgroundColor: colors.fadedRed,
            margin: "30px 0px"
          }}
        />

        <p
          style={{
            fontFamily: "Lato-Regular",
            fontSize: "16px",
            lineHeight: "27px",
            textAlign: "center"
          }}
        >
          {props.programDescritpion}
        </p>

        <div style={{ height: "50px", width: "100%" }} />

        <p
          style={{
            fontFamily: "Lato-Italic",
            fontSize: "15px",
            lineHeight: "27px",
            textAlign: "center"
          }}
        >
          {props.statusText}
        </p>
        <CommitButtonWithClickState
          style={{ width: "auto", marginTop: "20px" }}
          clickHandler={props.buttonAction}
          type="red"
        >
          {props.buttonText}
        </CommitButtonWithClickState>
      </div>
    </div>
  )
}

const styles = {
  componentContainer: {
    fontFamily: "Lato-Regular",
    height: "calc(100vh - 60px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: colors.white,
    backgroundColor: colors.midnight,
    backgroundSize: "cover"
  },
  content: {
    maxWidth: "580px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px"
  }
}

export default LockedProgramView
