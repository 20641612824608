import React from "react";
import { debounce } from "debounce";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer";
import Actions from "../../Redux/Actions";
import UserFieldEditorNavMenu from "../UserFieldEditorNavMenu/UserFieldEditorNavMenu";
import NonDodIcApplicationProgressCircle from "../NonDodIcApplicationProgressCircle/NonDodIcApplicationProgressCircle"; // Adjusted import path
import { allFormsFilled } from "../../Redux/Utils/reducerUtils";

class NonDodIcApplicationNavMenu extends React.Component { // Adjusted component name
  constructor(props) {
    super(props);

    this.state = {
      showSubmitTooltip: false, // whether or not to display the 'you must complete all sections' tooltip
    };

    this.navItemClickHandler = this.navItemClickHandler.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getSubmitState = this.getSubmitState.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  handleClickOutside() {
    this.setState({
      showSubmitTooltip: false,
    });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  navItemClickHandler(id) {
    return debounce((e) => {
      this.props.nonDodIcApplication.currentInputData && // Adjusted to match Non-DOD IC application
        this.props.saveNonDodIcApplication( // Adjusted action function
          this.props.nonDodIcApplication.currentInputData,
          this.props.history
        );
    }, 200);
  }

  submitHandler() {
    if (!allFormsFilled(this.props.nonDodIcApplication.applicationMetadata)) { // Adjusted to match Non-DOD IC application
      // Display the Tooltip modal
      this.setState({
        showSubmitTooltip: true,
      });
    }
  }

  saveNonDodIcApplication(checkSubmittability, inputData) { // Adjusted function name
    return async (e) => {
      if (inputData) {
        inputData && checkSubmittability
          ? checkSubmittability(inputData) &&
            this.props.saveNonDodIcApplication(inputData, this.props.history) // Adjusted action function
          : await this.props.saveNonDodIcApplication(inputData, this.props.history); // Adjusted action function
      }
    };
  }

  getSubmitState() {
    if (allFormsFilled(this.props.nonDodIcApplication.applicationMetadata)) { // Adjusted to match Non-DOD IC application
      if (this.props.nonDodIcApplication.nonDodIcApplication.has_submitted_application) { // Adjusted to match Non-DOD IC application
        return "submitted";
      }
      return "finished";
    }
    if (this.props.nonDodIcApplication.nonDodIcApplication.has_submitted_application) { // Adjusted to match Non-DOD IC application
      return "editing and unfinished";
    }
    return "unfinished";
  }

  render() {
    return (
      <UserFieldEditorNavMenu
        showSubmitTooltip={this.state.showSubmitTooltip}
        userFieldData={this.props.nonDodIcApplication.applicationMetadata} // Adjusted to match Non-DOD IC application
        navItemClickHandler={this.navItemClickHandler}
        submitState={this.getSubmitState()}
        submitHandler={this.submitHandler}
        progressComponent={<NonDodIcApplicationProgressCircle />} // Adjusted progress component
        applicationIsComplete={allFormsFilled(
          this.props.nonDodIcApplication.applicationMetadata // Adjusted to match Non-DOD IC application
        )}
      />
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state) => ({
  nonDodIcApplication: state.nonDodIcApplication, // Adjusted to match Non-DOD IC application state
  user: state.user.user,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithDrawerConsumer(NonDodIcApplicationNavMenu)) // Adjusted component name
);
