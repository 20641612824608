/*
References redux analytics data and normalizes subkeys from an object with any numbers of keys whose children are objects containing the normalized subkeys
*/

import React, { useState } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import colors from "../../Constants/colors"

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 10,
    overflowX: "auto",
    height: "fit-content"
  },
  table: {
    height: "fit-content"
  },
  header: {
    backgroundColor: colors.midnight,
    color: colors.white
  }
})

export default function CountAndViewAnalytics({
  analyticsPointer,
  tableTitle = "Table Title",
  tableCellTitles = [],
  style
}) {
  const [indexer, updateIndexer] = useState(null)
  const [reverseSort, updateReverseSort] = useState(false)
  const classes = useStyles()
  const analyticsData = useSelector(
    (state) => state.analytics[analyticsPointer]
  )

  const rowCreator = (row) => (
    <TableRow key={row[0]}>
      <TableCell component="th" scope="row">
        {row[0]}
      </TableCell>
      {row.slice(1, row.length).map((value, index) => (
        <TableCell align="right" key={index}>
          {value}
        </TableCell>
      ))}
    </TableRow>
  )

  const rowSorter = (a, b, indexer) => {
    if (a[tableCellTitles.indexOf(indexer) + 1] === "NO DATA") {
      if (b[tableCellTitles.indexOf(indexer) + 1] === "NO DATA") {
        return 0
      }
      return 1
    }
    if (b[tableCellTitles.indexOf(indexer) + 1] === "NO DATA") {
      return -1
    }
    return reverseSort
      ? a[tableCellTitles.indexOf(indexer) + 1] -
          b[tableCellTitles.indexOf(indexer) + 1]
      : b[tableCellTitles.indexOf(indexer) + 1] -
          a[tableCellTitles.indexOf(indexer) + 1]
  }

  const indexUpdater = (title) => {
    if (indexer === title) {
      updateReverseSort(!reverseSort)
    } else {
      updateIndexer(title)
      updateReverseSort(false)
    }
  }

  let tableRows = []

  if (analyticsData) {
    const allSubKeys = []
    Object.entries(analyticsData).forEach((entry) => {
      if (entry[0]) {
        tableCellTitles.push(entry[0])
        Object.keys(entry[entry.length - 1]).forEach((key) => {
          if (!allSubKeys.includes(key) && key !== "undefined") {
            allSubKeys.push(key)
          }
        })
      }
    })

    allSubKeys.forEach((subKey) => {
      const analytics = []
      Object.entries(analyticsData).forEach((entry) => {
        if (entry[entry.length - 1][subKey]) {
          analytics.push(entry[entry.length - 1][subKey])
        } else {
          analytics.push("NO DATA")
        }
      })
      if (analytics.length) {
        tableRows.push([subKey, ...analytics])
      }
    })
    if (indexer) {
      tableRows = tableRows.sort((a, b) => rowSorter(a, b, indexer))
    }
    tableRows = tableRows.map((row) => rowCreator(row))
  }

  return (
    <Paper className={classes.root} style={{ ...style }}>
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.header} component="th" scope="row">
              {tableTitle}
            </TableCell>
            {tableCellTitles.map((title) => (
              <TableCell
                className={classes.header}
                align="right"
                key={title}
                onClick={() => indexUpdater(title)}
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{tableRows}</TableBody>
      </Table>
    </Paper>
  )
}
