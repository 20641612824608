import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import { Input, InputGroupAddon, InputGroup } from "reactstrap"
import { Magnifier } from "../../Icons/Magnifier"
import colors from "../../Constants/colors"
import "./VideoSearchbar.css"
import { Close } from "../../Icons/Close"
import WithEnterClick from "../WithEnterClick/WithEnterClick"

const styleSheet = {
  input: {
    width: "100%",
    color: "green",
    "&::placeholder": {
      color: colors.midnight
    }
  }
}

export const VideoSearchbar = (props) => {
  return (
    <WithEnterClick
      onEnterClick={props.enterKeyHandler}
      renderComponent={() => (
        <div className="searchbarContainer">
          <InputGroup style={{ height: "44px" }}>
            <InputGroupAddon addonType="prepend">
              <Magnifier
                width="15px"
                height="15px"
                style={styles.searchMagnifier}
                color="#787f8c"
              />
            </InputGroupAddon>
            <Input
              style={{
                height: "44px",
                borderRadius: "0px",
                fontFamily: "Lato-Light",
                fontSize: "15px",
                color: colors.midnight,
                backgroundClip: "border-box"
              }}
              className="searchInput"
              aria-label="Search all videos"
              onChange={props.changeHandler}
              value={props.value || ""}
              placeholder={props.placeholder || "Search all videos"}
            />
            <InputGroupAddon addonType="append">
              <div onClick={props.clearSearch} style={styles.searchMagnifier}>
                {(props.value || props.placeholder) && (
                  <Close width="15px" height="15px" fill="#787f8c" />
                )}
              </div>
              <button
                id="dl-search-btn"
                style={styles.searchButton}
                onClick={props.enterKeyHandler}
              >
                Search
              </button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      )}
    />
  )
}

const styles = {
  searchButton: {
    height: "44px",
    width: "74px",
    borderTopRightRadius: "3px",
    borderBottomRightRadius: "3px",
    backgroundColor: colors.fadedRed,
    fontSize: "15px",
    color: colors.white,
    border: "0px",
    fontFamily: "Lato-light",
    cursor: "pointer",
    textAlign: "center",
    lineHeight: "0px"
  },
  searchMagnifier: {
    backgroundColor: colors.white,
    borderTopLeftRadius: "3px",
    borderBottomLeftRadius: "3px",
    paddingRight: "7px",
    paddingLeft: "7px",
    display: "flex",
    alignItems: "center"
  }
}

export default withRouter(withStyles(styleSheet)(VideoSearchbar))
