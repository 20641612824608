import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loader from "react-loader-spinner"
import queryString from "query-string"
import GoogleTagManager from "../GoogleTagManager/GoogleTagManager"
import UserActionCard from "../LandingPage/UserActionCard/UserActionCard"
import ActivitiesList from "../ActivitiesList/ActivitiesList"
import SessionList from "../SessionList/SessionList"
import SpouseEvent from "../SpouseEvent/SpouseEvent"
import SessionWebinar from "../SessionWebinar/SessionWebinar"
import CohortList from "../CohortList/CohortList"
import {
  getUserWorkshops,
  getWorkshopEvents
} from "../../Redux/Actions/Workshop/functions"
import types from "../../Redux/Actions/Workshop/types"

import "./SpouseCourse.css"

function SpouseCourse() {
  const dispatch = useDispatch()
  const [sessionNumber, setSessionNumber] = useState(1)
  const [currentEvent, setCurrentEvent] = useState({})

  const {
    programCoordinator,
    coachUser,
    workshops,
    isLoadingWorkshops,
    workshopEvents
  } = useSelector((state) => ({
    programCoordinator: state.workshop?.programCoordinator || null,
    coachUser: state.user?.user.dvsPerson?.coachUser || null,
    workshops: state.workshop?.workshops || [],
    isLoadingWorkshops: state.workshop.isLoadingWorkshops,
    workshopEvents: state.workshop?.workshopEvents
  }))

  const { workshop: workshopId } = queryString.parse(window.location.search)
  if (!workshopId && workshops.length && workshops[0].id) {
    window.location.assign('/virtualcohort?workshop='+workshops[0].id);
  }
  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        page_view: {
          page_title: document.title
        }
      })
    }

    const getWorkshopevents = async () => {
      try {
        const campaignWorkshop = await dispatch(getUserWorkshops())
        // const { workshop: workshopId } = queryString.parse(window.location.search)
        dispatch(getWorkshopEvents(workshopId))
      } catch (error) {
        dispatch({ type: types.SET_ERROR, payload: true })
      }
    }

    getWorkshopevents()
  }, [])

  useEffect(() => {
    if (workshopEvents.length) {
      const [filteredWorkshopEvent] = workshopEvents.filter(
        (workshopEvent) => workshopEvent.session_number === sessionNumber
      )

      setCurrentEvent(filteredWorkshopEvent)
    }
  }, [workshopEvents, sessionNumber])

  if (isLoadingWorkshops) {
    return (
      <div
        className="workshopBannerLoader"
        style={{
          height: "100vh",
          width: "100vw"
        }}
      >
        <Loader type="Oval" color="#00BFFF" height="50%" width="50%" />
      </div>
    )
  }

  const programCoordinatorHtml = programCoordinator && programCoordinator.map((programCoordinatorPerson) => {
    return (
      <UserActionCard
        key={programCoordinatorPerson.id}
        spouseDashboard
        showBorder
        target={"programCoordinator"+programCoordinatorPerson.id}
        isShortCard
        user={programCoordinatorPerson}
        customStyles={{
          imageSize: { width: "50px", height: "50px" },
          container: { padding: "13px 19px" }
        }}
      />
    )
  })
  const current_workshops = workshops.filter((workshop) => workshop.id === workshopId)
  const current_workshop = current_workshops[0]
  
  return (
    <GoogleTagManager gtmId={process.env.REACT_APP_PYP_GTM_ID}>
      <div className="spouse-course-container">
        <h2 className="page-title">
          <span>Dashboard /</span>
          {current_workshop && current_workshop.title
            ? current_workshop.title
            : "No program title was provided."}
        </h2>
        <div className="spouse-course-sections-container">
          <div className="left-section">
            <h1 className="spouse-course-section-title">Your Next Event</h1>
            <SpouseEvent workshopEvents={workshopEvents} />

            {coachUser && (
              <div
                className="card-action-container"
                style={{ flex: "1 1 25%", paddingRight: 32 }}
              >
                <h1 className="spouse-course-section-title">
                  Your Executive Coach
                </h1>
                <UserActionCard
                  spouseDashboard
                  showBorder
                  target="coach"
                  isShortCard
                  user={coachUser}
                  customStyles={{
                    imageSize: { width: "50px", height: "50px" },
                    container: { padding: "13px 19px" }
                  }}
                />
              </div>
            )}

            <h1 className="spouse-course-section-title">
              Program Participants
            </h1>
            <CohortList />
            {programCoordinatorHtml && (
              <div
                className="card-action-container"
                style={{ flex: "1 1 100%", paddingRight: 32 }}
                >
                <h1 className="spouse-course-section-title">
                  Program Coordinators
                </h1>
                {programCoordinatorHtml}
              </div>
            )}
          </div>
          <div className="right-section">
            <SessionList
              setSessionNumber={setSessionNumber}
              sessionNumber={sessionNumber}
            />
            <SessionWebinar currentEvent={currentEvent} />

            <ActivitiesList sessionNumber={sessionNumber} />
          </div>
        </div>
      </div>
    </GoogleTagManager>
  )
}

export default SpouseCourse
