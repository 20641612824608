import React, { Component } from "react"
import UserFieldEditorHeaderSectionDescription from "../UserFieldEditorSectionDescription/UserFieldEditorSectionDescription"
import UserFieldEditorHeaderSectionTitle from "../UserFieldEditorHeaderSectionTitle/UserFieldEditorHeaderSectionTitle"

class UserFieldEditorHeader extends Component {
  render() {
    const isBiograpy =
      this.props.title === "Personal Summary" ? (
        <a
          href="https://www.commitfoundation.org/staff-bios"
          target="_blank"
          style={styles.biographyLink}
        >
          View COMMIT Team Examples
        </a>
      ) : (
        ""
      )

    return (
      <>
        <div
          style={{
            ...styles.containerStyle,
            ...this.props.style,
            marginTop: "30px"
          }}
        >
          <div style={styles.columns} />
          <div style={styles.columns}>
            <p style={styles.questionText}>
              {this.props.sectionTitle}
              {` ${this.props.currentStepIndex + 1}/${this.props.stepsLength}`}
            </p>
          </div>
          <div style={styles.columns} />
        </div>
        <div style={{ margin: "50px auto 0px auto", width: "80%" }}>
          <UserFieldEditorHeaderSectionTitle>
            {this.props.title}
          </UserFieldEditorHeaderSectionTitle>
          <UserFieldEditorHeaderSectionDescription
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              width: "80%",
              marginRight: "auto",
              marginLeft: "auto",
              textAlign: "center"
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>
                {this.props.description} <span>{isBiograpy}</span>
              </div>
            </div>
          </UserFieldEditorHeaderSectionDescription>
        </div>
      </>
    )
  }
}

const styles = {
  containerStyle: {
    display: "grid",
    gridTemplateColumns: "auto auto auto"
  },
  columns: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  questionText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#787f8c"
  },
  biographyLink: {
    color: "#2F539D"
  }
}

export default UserFieldEditorHeader
