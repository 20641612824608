import types from "../../Actions/NonDodIcApplication/types";
import { NonDodIcApplicationMetadata } from "./NonDodIcApplicationMetadata";
import { NonDodIcPostSurveyMetadata } from "./NonDodIcPostSurveyMetadata";
import { spouseProfileMetadata } from "../SpouseApplication/spouseProfileMetadata"
import { NonDodIcPreSurveyMetadata } from "./NonDodIcPreSurveyMetadata";
import { NonDodIcProfileMetadata } from "./NonDodIcProfileMetadata";
import * as utils from "../../Utils/reducerUtils";

const NonDodIcApplicationReducer = (
  state = {
    showErrors: false,
    applicationMetadata: NonDodIcApplicationMetadata, // Default to Non-DOD IC application metadata
    profileMetadata: NonDodIcProfileMetadata,
    preSurveyMetadata: NonDodIcPreSurveyMetadata,
    postSurveyMetadata: NonDodIcPostSurveyMetadata,
    initialized: false,
    userWillReLocate: false,
    submittingApplication: false,
    saving: false,
    application: {},
    applicationIsComplete: false,
    interests: [],
    fetchingInterests: false,
    spouseProfileMetadata: NonDodIcProfileMetadata,
    nonDodIcApplication: {}, // State for Non-DOD IC application
    nonDodIcApplicationIsComplete: false,
  },
  action
) => {
  try {
    switch (action.type) {
      case types.SUBMITTED_NON_DOD_IC_APPLICATION:
        return {
          ...state,
          submittingApplication: false
        };
      case types.LOGOUT:
        return {
          showErrors: false,
          applicationMetadata: NonDodIcApplicationMetadata, // Reset to Non-DOD IC application metadata
          profileMetadata: NonDodIcProfileMetadata,
          preSurveyMetadata: NonDodIcPreSurveyMetadata,
          postSurveyMetadata: NonDodIcPostSurveyMetadata,
          initialized: false
        };
      case types.TOGGLE_NON_DOD_IC_UNFILLED:
        return {
          ...state,
          showErrors: true,
          applicationMetadata: state.applicationMetadata.map((input) => {
            return {
              ...input,
              steps: input.steps.map((step) => {
                return !step.filled
                  ? {
                      ...step,
                      unseenError: true
                    }
                  : step;
              })
            };
          }),
          profileMetadata: state.profileMetadata.map((input) => {
            return {
              ...input,
              steps: input.steps.map((step) => {
                return !step.filled
                  ? {
                      ...step,
                      unseenError: true
                    }
                  : step;
              })
            };
          })
        };
      case types.SUBMIT_NON_DOD_IC_APPLICATION:
        return {
          ...state,
          showErrors: false,
          submittingApplication: true
        };
      case types.INIT_NON_DOD_IC_APPLICATION:
        const updatedMetaData = utils.processNavOptionsForApplication(
          action.data.application,
          state.applicationMetadata
        );
        const metaData1 = action.data.application && action.data.application.is_spouse === 1
          ? (state.spouseProfileMetadata ? state.spouseProfileMetadata : NonDodIcProfileMetadata)
          : (state.application && state.application.profileMetadata ? state.application.profileMetadata : NonDodIcProfileMetadata);

        const updatedProfileMetaData = utils.processNavOptionsforProfile(
          action.data.application,
          metaData1
        );

        return {
          ...state,
          application: action.data.application,
          applicationMetadata: updatedMetaData,
          applicationIsComplete: utils.allFormsFilled(updatedMetaData),
          profileIsComplete: utils.allFormsFilled(updatedProfileMetaData),
          initialized: true,
          profileMetadata: updatedProfileMetaData,
          userWillReLocate:
            action.data.application.address &&
            action.data.application.address.willLocate === "Yes"
        };
      case types.FETCHED_NON_DOD_IC_APPLICATION:
        const updatedNonDodIcApplicationMetaData = utils.processNavOptionsForApplication(
          action.data.application,
          state.applicationMetadata
        );
        const updatedNonDodIcProfileData = utils.processNavOptionsforProfile(
          action.data.application,
          state.profileMetadata
        );
        return {
          ...state,
          nonDodIcApplication: action.data.application,
          applicationMetadata: updatedNonDodIcApplicationMetaData,
          saving: false,
          nonDodIcApplicationIsComplete: utils.allFormsFilled(updatedNonDodIcApplicationMetaData),
          profileIsComplete: utils.allFormsFilled(updatedNonDodIcProfileData),
          profileMetadata: updatedNonDodIcProfileData,
          currentInputData: null,
          userWillReLocate:
            action.data.application.address &&
            action.data.application.address.willLocate === "Yes"
        };
      case types.SET_NON_DOD_IC_APPLICATION_INPUT_DATA:
        return {
          ...state,
          currentInputData: action.data.inputData
        };
      case types.LOGIN:
        return {
          ...state,
          initialized: false,
          showErrors: false
        };
      case types.EDIT_NON_DOD_IC_APPLICATION:
        return {
          ...state,
          currentInputData: null,
          saving: false
        };

      case types.FETCHED_NON_DOD_IC_APPLICATION:
        const updatedApplicationMetaData = utils.processNavOptionsForApplication(
          action.data.application,
          state.applicationMetadata
        );

        const metaData2 = action.data.application && action.data.application.is_spouse === 1
          ? (state.spouseProfileMetadata ? state.spouseProfileMetadata : NonDodIcProfileMetadata)
          : (state.application && state.application.profileMetadata ? state.application.profileMetadata : NonDodIcProfileMetadata);

        const updatedProfileData = utils.processNavOptionsforProfile(
          action.data.application,
          metaData2
        );

        return {
          ...state,
          application: action.data.application,
          applicationMetadata: updatedApplicationMetaData,
          saving: false,
          applicationIsComplete: utils.allFormsFilled(updatedApplicationMetaData),
          profileIsComplete: utils.allFormsFilled(updatedProfileData),
          profileMetadata: updatedProfileData,
          currentInputData: null,
          userWillReLocate:
            action.data.application.address &&
            action.data.application.address.willLocate === "Yes"
        };
      case types.SAVING_NON_DOD_IC_APPLICATION:
        return {
          ...state,
          saving: true
        };
      case types.WILL_NON_DOD_IC_RELOCATE_CHANGED:
        const updatedMeta = utils.processNavOptionsForApplication(
          state.application,
          state.applicationMetadata
        );

        const metaData3 = action.data.application && action.data.application.is_spouse === 1
          ? (state.spouseProfileMetadata ? state.spouseProfileMetadata : NonDodIcProfileMetadata)
          : (state.application && state.application.profileMetadata ? state.application.profileMetadata : NonDodIcProfileMetadata);

        const updatedProfileMeta = utils.processNavOptionsforProfile(
          state.application,
          metaData3
        );

        return {
          ...state,
          userWillReLocate: action.data.value,
          applicationMetadata: updatedMeta,
          profileMetadata: updatedProfileMeta
        };
      case types.UPDATE_INTERESTS:
        return {
          ...state,
          interests: action.data.interests
        };
      case types.TOGGLE_FETCHING_INTERESTS:
        return {
          ...state,
          fetchingInterests: action.data.toggle
        };
      case types.UPDATE_LIKERT_SPOUSE:
        const { userField, value } = action.payload;
        const newState = { ...state };
        const fieldArray = userField.split(".");
        let currentState = newState.spouseApplication.application;
        for (let i = 0; i < fieldArray.length - 1; i++) {
          currentState = currentState[fieldArray[i]];
        }

        // Update the value at the specified location
        currentState[fieldArray[fieldArray.length - 1]] = value;

        return newState;

      default:
        return {
          ...state
        };
    }
  } catch (error) {
    console.error("Error occurred in applicationReducer:", error);
    return state;
  }
};

export default NonDodIcApplicationReducer;
