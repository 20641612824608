import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

import Actions from "../../Redux/Actions"
import InputGroup from "../InputGroup/InputGroup"
import useDebouncedEffect from "../../Hooks/useDebouncedEffect"

const inputs = [
  {
    id: 0,
    inputType: "longText",
    userField: "submission.extraInfo",
    title:
      "Our services are highly individualized and we would like to better understand you. Please use the following space to add additional information. Some examples are why are you applying to COMMIT, what motivates you, what do you want to carry forward or leave behind from your military life?",
    required: true,
  }
]

const SubmissionOne = (props) => {
  const dispatch = useDispatch()

  const { application, submissionInput } = useSelector((state) => {
    const [submissionInputState = {}] =
      state.application?.currentInputData || []

    return {
      application: !window.location.href.includes('spouse') ? state.application.application : state.spouseApplication.application,
      submissionInput:
        submissionInputState?.userField === "submission.extraInfo"
          ? submissionInputState.value
          : null
    }
  })

  useDebouncedEffect(
    () => {
      if (submissionInput === null) return

      dispatch(
        Actions.saveApplication(
          [
            {
              ...inputs[0],
              value: submissionInput
            }
          ],
          props.history
        )
      )
    },
    500,
    [submissionInput]
  )

  const inputDataWithValues = inputs.map((input) => ({
    ...input,
    value: Object.byString(application, input.userField) || ""
  }))

  return (
    <InputGroup
      {...props}
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              inputComponent
            })}
          </>
        )
      }}
    />
  )
}

export default withRouter(SubmissionOne)
