import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import VideoSearchBar from "../VideoSearchbar/VideoSearchbar"
import Actions from "../../Redux/Actions"
import { gtmClickHandler } from "../../Utils/gtm"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

export class VideoSearchBarContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inputValue: "",
      placeholder: ""
    }

    this.changeHandler = this.changeHandler.bind(this)
    this.enterKeyHandler = this.enterKeyHandler.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    this.setState({
      inputValue: this.props.match.params.searchTerm
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      if (this.props.match.path === "/digitalLibrary/search/:searchTerm") {
        this.setState({
          inputValue: this.props.match.params.searchTerm
        })
      }
    }
  }

  // TODO rename this method - it also gets triggered on the search button click
  enterKeyHandler() {
    if (
      this.state.inputValue &&
      this.state.inputValue !== this.props.match.params.searchTerm
    ) {
      this.props.clearReduxVideos()

      gtmClickHandler({
        name: "SearchedVideoEvent",
        data: {
          action: "Searched",
          category: "Video Engagement",
          label: this.state.inputValue
        }
      })

      this.props.history.push({
        pathname: `/digitalLibrary/search/${this.state.inputValue}`,
        state: { from: this.props.history.location.pathname }
      })

      this.props.closeAllDrawers()
    }
  }

  clearSearch() {
    this.setState({
      inputValue: "",
      placeholder: ""
    })
  }

  changeHandler(e) {
    this.setState({
      inputValue: e.target.value
    })
  }

  render() {
    return (
      <VideoSearchBar
        changeHandler={this.changeHandler}
        value={this.state.inputValue}
        onClick={this.onClick}
        enterKeyHandler={this.enterKeyHandler}
        placeholder={this.state.placeholder}
        clearSearch={this.clearSearch}
      />
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  WithDrawerConsumer(
    connect(mapStateToProps, mapDispatchToProps)(VideoSearchBarContainer)
  )
)
