import React, { useState } from "react"
import { useSelector } from "react-redux"
import AgreeDisagree from "./AgreeDisagree"
import "./SpouseCareerMisc.css"
import { useDispatch } from "react-redux"

const inputs = [
    {
        id: 0,
        className: "next_step_confiedence",
        inputType: "select",
        userField: "career.next_step_confiedence",
        title: "Confident about next steps in career or purpose work",
        required: true,
        value: ""
    },
    {
        id: 1,
        className: "lifestyle_options",
        inputType: "select",
        userField: "career.lifestyle_options",
        title: "Understand host of possible options and lifestyle options available",
        required: true,
        value: ""
    },
    {
        id: 2,
        className: "adequate_information",
        inputType: "select",
        userField: "career.adequate_information",
        title: "Have adequate information to navigate next life goal or career move",
        required: true,
        value: ""
    },
    {
        id: 3,
        className: "network",
        inputType: "select",
        userField: "career.network",
        title: "Know how to leverage your network",
        required: true,
        value: ""
    },
    {
        id: 4,
        className: "apply_skills",
        inputType: "select",
        userField: "career.apply_skills",
        title: "Know how to apply my skills and experience to future opportunities",
        required: true,
        value: ""
    }
]

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    const a = s.split(".")
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i]
        if (o && k in o) {
            o = o[k]
        } else {
            return
        }
    }
    return o
}


const SpouseCareerMisc = (props) => {
    const { changeHandler } = props
    const application = useSelector((state) => {
        return state.spouseApplication.application;
    })
    const [inputData, setInputData] = useState(
        inputs.map((input) => ({
          ...input,
          value: Object.byString(application, input.userField) || ""
        }))
    );
    const onInputDataChange = (id, newValue) => {
        const updatedInputData = inputData.map((input) =>
          input.id === id ? { ...input, value: newValue } : input
        );
        setInputData(updatedInputData);
        changeHandler(updatedInputData)
        // dispatch({
        //     type: "UPDATE_LIKERT_SPOUSE", // Replace with the actual action type
        //     payload: { userField: inputs[id].userField, newValue }
        //   });
    };
    return (
        <div className="main-parent">
        {inputData.map((input) => {
        return (
            <AgreeDisagree
                key={input.id}
                title={input.title}
                inputId={input.userField}
                value={input.value}
                onChange={(newValue) => onInputDataChange(input.id, newValue)}
            />
        )
        })}
        </div>
    )
}

export default SpouseCareerMisc
