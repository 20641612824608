import React from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"

import PYPInputBox from "../PYPInputBox/PYPInputBox.js"
import PYPCarousel from "../PYPCarousel/PYPCarousel.js"
import PYPStaticInputBox from "../PYPStaticInputBox/PYPStaticInputBox.js"
import SkeletonCard from "../SkeletonCard/SkeletonCard.js"

import Actions from "../../Redux/Actions"

class LayoutMetaDetaReferenceUserInput extends React.Component {
  componentDidMount() {
    if (!this.props.pyp.savingPYP) {
      this.props.fetchPartData().then(() => this.checkForUninitializedInputs())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
      this.props.fetchPartData().then(() => this.checkForUninitializedInputs())
    }
  }

  checkForUninitializedInputs() {
    if (this.props.inputBoxes.length <= 0) {
      this.props.initializePYPInputs(
        this.props.partDataId,
        this.props.inputTitles
      )
    }
  }

  getReferenceComponent() {
    let inputs
    let loading
    if (this.props.referenceInputs.length) {
      inputs = this.props.referenceInputs.map((input) => {
        const title = input.title
          ? input.title.title
          : (input.buttonSelectionTitle.buttonValue &&
              input.buttonSelectionTitle.buttonValue.name) ||
            (input.buttonSelectionTitle.customButtonValue &&
              input.buttonSelectionTitle.customButtonValue.name)
        return (
          <PYPStaticInputBox
            title={title}
            size={input.size}
            text={input.text}
            style={{ height: "100%" }}
          />
        )
      })
      loading = false
    } else {
      const skeleton = (
        <SkeletonCard size={this.props.inputSize} style={{ height: "100%" }} />
      )

      inputs = Array(this.props.numInputs)
        .fill(1)
        .map(() => skeleton)
      loading = true
    }
    return (
      <PYPCarousel
        cards={inputs}
        loading={loading}
        cardHeight={260}
        cardWidth={380}
        theme="dark"
      />
    )
  }

  renderInputBox() {
    if (!this.props.inputBoxes.length) {
      return (
        <SkeletonCard
          size={this.props.inputSize}
          style={{ margin: "31px 30px 0px 30px" }}
        />
      )
    }
    return Object.values(this.props.inputBoxes).map((box, key) => {
      // Look up the value of the title from within props.inputBoxes
      const boxTitle = this.props.inputTitles.find((title) => {
        return title.id === box.input_title_id
      }).title // Grab title

      return (
        <PYPInputBox
          key={key}
          text={box.text}
          placeholder=""
          style={{ margin: "31px 30px 0px 30px" }}
          title={boxTitle}
          size={box.size}
          valueDidChange={(value) =>
            this.props.setPYPInputText(this.props.partDataId, box.id, value)
          }
          showIncomplete={this.props.pyp.navigation.showIncompletionError}
        />
      )
    })
  }

  render() {
    const referenceComponent = this.getReferenceComponent()
    const inputBox = this.renderInputBox()
    return (
      <PYPLayoutContainer
        backgroundColor={colors.background}
        style={{ ...this.props.style }}
      >
        <PYPPartTitleContent
          title={this.props.metadata.title}
          description={this.props.metadata.description}
        />

        <div style={styles.bottomSection}>
          <div style={styles.section}>{referenceComponent}</div>
          <div style={styles.section}>{inputBox}</div>
        </div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  bottomSection: {
    flex: 1,

    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px",
    alignItems: "flex-start"
  },
  section: {
    flex: "1 1 300px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "360px",
    marginBottom: "40px"
  },
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let inputBoxes = []
  let inputTitles = []
  let referenceInputs = []

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.title[0]
    inputBoxes = partData.inputs
    inputTitles = partData.inputTitle
    referenceInputs = partData.referenceInputs
  }

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    inputBoxes,
    inputTitles,
    referenceInputs
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutMetaDetaReferenceUserInput)
)
