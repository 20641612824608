import React from "react"
import PropTypes from "prop-types"

import "./CheckBox.css"

const CheckBox = ({ id, text, checked, onClick, className }) => {
  return (
    <div className={`custom-checkbox ${checked ? "checked" : ""} ${className}`}>
      <input
        type="radio"
        id={id}
        name={id}
        onClick={onClick}
        checked={checked}
      />
      <label htmlFor={id}>{text}</label>
    </div>
  )
}

CheckBox.defaultProps = {
  className: ""
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default CheckBox
