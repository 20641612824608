import React from "react"

class ToggleSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedIndex:
        props.initialSelectionIndex == null ? null : props.initialSelectionIndex
    }
  }

  didSelectComponent(index) {
    this.setState({
      selectedIndex: index
    })
    this.props.didSelectComponent(index)
  }

  render() {
    return (
      <>
        {this.props.components.map(({ renderComponent }, i) => {
          const selected = i === this.state.selectedIndex

          return renderComponent({
            selected,
            selectComponent: this.didSelectComponent.bind(this, i),
            key: i,
            index: i
          })
        })}
      </>
    )
  }
}

export default ToggleSelector
