import React from "react"
import colors from "../../Constants/colors"

const NavBar = ({ children, style }) => {
  return (
    <nav style={{ ...styles.componentContainer, ...style }}>{children}</nav>
  )
}

const styles = {
  componentContainer: {
    backgroundColor: colors.midnight,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "60px"
  }
}

export default NavBar
