import React from "react"
import CoachesNotes from "../CoachesNotes/IdealDayNotes/IdealDayNotes"

const coachesNotesMap = {
  "Ideal Work Day Roadmapper": (props) => {
    return <CoachesNotes />
  }
}

export default coachesNotesMap
