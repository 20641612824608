const types = {
  INITIALIZED_DIGITAL_LIBRARY: "INITIALIZED_DIGITAL_LIBRARY",
  FETCHING_CATEGORIES: "FETCHING_CATEGORIES",
  FETCHED_RECOMMENDED: "FETCHED_RECOMMENDED",
  FETCHING_VIDEOS: "FETCHING_VIDEOS",
  SAVING_USER_SEARCH: "SAVING_USER_SEARCH",
  SAVED_USER_SEARCH: "SAVED_USER_SEARCH",
  UPDATING_USER_SEARCH: "UPDATING_USER_SEARCH",
  UPDATED_USER_SEARCH: "UPDATED_USER_SEARCH",
  FETCHED_CATEGORIES: "FETCHED_CATEGORIES",
  SET_WATCHING_VIDEOS: "SET_WATCHING_VIDEOS",
  RETRIEVED_VIDEOS_META_DATA: "RETRIEVED_VIDEOS_META_DATA",
  FETCHED_VIDEOS: "FETCHED_VIDEOS",
  CURRENT_VIDEO_LIKE_STATUS: "CURRENT_VIDEO_LIKE_STATUS",
  CURRENT_VIDEO_LIKE: "CURRENT_VIDEO_LIKE",
  CURRENT_VIDEO_DISLIKE: "CURRENT_VIDEO_DISLIKE",
  CURRENT_VIDEO_LIKE_RESET: "CURRENT_VIDEO_LIKE_RESET",
  ADD_TO_RECENTLY_WATCHED: "ADD_TO_RECENTLY_WATCHED",
  VIDEO_UNSUBSCRIBE: "VIDEO_UNSUBSCRIBE",
  CLEAR_VIDEOS: "CLEAR_VIDEOS",
  FETCHED_CATEGORIES_VIDEOS: "FETCHED_CATEGORIES_VIDEOS",
  FETCHING_LIKE_STATUS: "FETCHING_LIKE_STATUS",
  FETCHED_VIDEOS_TYPES: "FETCHED_VIDEOS_TYPES",
  LOAD_MORE_VIDEOS: "LOAD_MORE_VIDEOS",
  SHOW_SEARCH_BAR: "SEARCHING_DL",
  HIDE_SEARCH_BAR: "HIDE_SEARCH_BAR",
  STORE_TRANSITION_PLAYLIST: "STORE_TRANSITION_PLAYLIST",
  STORE_SINGLE_PLAYLIST: "STORE_SINGLE_PLAYLIST",
  CLEAR_TRANSITIONS: "CLEAR_TRANSITIONS"
}

export default types
