import React from "react"
import { debounce } from "debounce"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import Actions from "../../Redux/Actions"
import UserFieldEditorNavMenu from "../UserFieldEditorNavMenu/UserFieldEditorNavMenu"
import { allFormsFilled } from "../../Redux/Utils/reducerUtils"

class ProfileNavMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showSubmitTooltip: false // whether or not to display the 'you must complete all sections' tooltip
    }

    this.navItemClickHandler = this.navItemClickHandler.bind(this)

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.getSubmitState = this.getSubmitState.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.getProfileMetadata = this.getProfileMetadata.bind(this)
  }

  getProfileMetadata() {
    return this.props.application.profileMetadata
  }

  handleClickOutside() {
    this.setState({
      showSubmitTooltip: false
    })
  }

  componentDidMount() {
    // this.setModalDimensions()
    document.addEventListener("mousedown", this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside)
  }

  navItemClickHandler(id) {
    return debounce((e) => {
      this.props.application.currentInputData &&
        this.props.saveApplication(
          this.props.application.currentInputData,
          this.props.history
        )
    }, 200)
  }

  submitHandler() {
    if (!allFormsFilled(this.getProfileMetadata())) {
      // Display the Tooltip modal
      this.setState({
        showSubmitTooltip: true
      })
    }
  }

  saveApplication(checkSubmittability, inputData) {
    return async (e) => {
      if (inputData) {
        inputData && checkSubmittability
          ? checkSubmittability(inputData) &&
            this.props.saveApplication(inputData, this.props.history)
          : await this.props.saveApplication(inputData, this.props.history)
      }
    }
  }

  getSubmitState() {
    if (allFormsFilled(this.getProfileMetadata())) {
      if (this.props.application.application.has_submitted_application) {
        return "submitted"
      }
      return "finished"
    }
    if (this.props.application.application.has_submitted_application) {
      return "editing and unfinished"
    }
    return "unfinished"
  }
  render() {
    return (
      <UserFieldEditorNavMenu
        showSubmitTooltip={this.state.showSubmitTooltip}
        baseUrl="profile"
        userFieldData={this.getProfileMetadata()}
        navItemClickHandler={this.navItemClickHandler}
        submitState={this.getSubmitState()}
        submitHandler={this.submitHandler}
        applicationIsComplete={allFormsFilled(
          this.getProfileMetadata()
        )}
        progressComponent={
          <div style={styles.yourProfileText}>Your Profile</div>
        }
      />
    )
  }
}

const styles = {
  yourProfileText: {
    color: "white",
    display: "flex",
    alignItems: "flex-end",
    fontSize: "18px",
    fontFamily: "Lato-Bold",
    height: "100%",
    paddingBottom: "20px",
    marginLeft: "14px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application,
    spouseApplication: state.spouseApplication,
    user: state.user.user
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(ProfileNavMenu))
)
