import React from "react"
import SpousePersonalInfoInputs from "../SpousePersonalInfoInputs/SpousePersonalInfoInputs"
import SpouseLocationInputs from "../SpouseLocationInputs/SpouseLocationInputs"
import TimelineInputs from "../TimelineInputs/TimelineInputs"
import PersonalInfoInputsThree from "../PersonalInfoInputsThree/PersonalInfoInputsThree"
import SpouseActiveDutyInputs from "../SpouseActiveDutyInputs/SpouseActiveDutyInputs"
import MilitaryOne from "../MilitaryOne/MilitaryOne"
import BiographyOne from "../BiographyOne/BiographyOne"
import BiographyImpact from "../BiographyImpact/BiographyImpact"
import BiographyInspiration from "../BiographyInspiration/BiographyInspiration"
import BiographyChallenge from "../BiographyChallenge/BiographyChallenge"
import BiographyCareerMove from "../BiographyCareerMove/BiographyCareerMove"
import BiographyCareersPurpose from "../BiographyCareersPurpose/BiographyCareersPurpose"
import BiographyStrengthsSkills from "../BiographyStrengthsSkills/BiographyStrengthsSkills"
import SpouseCareerMisc from "../SpouseCareerMisc/SpouseCareerMisc"
import BiographyMotivates from "../BiographyMotivates/BiographyMotivates"
import BiographyMilitaryLife from "../BiographyMilitaryLife/BiographyMilitaryLife"
import BiographyTwo from "../BiographyTwo/BiographyTwo"
import BiographyThree from "../BiographyThree/BiographyThree"
import CareerOne from "../CareerOne/CareerOne"
import SpouseCareerSettings from "../SpouseCareerSettings/SpouseCareerSettings"
import { TransitionOne, TransitionGoals } from "../Transition"
import SubmissionOne from "../SubmissionOne/SubmissionOne"
import SpouseServiceMemberName from "../SpouseServiceMemberName/SpouseServiceMemberName"
import SpouseMilitaryBasic from "../SpouseMilitaryBasic/SpouseMilitaryBasic"
import SpouseCareerPreferences from "../SpouseCareerPreferences/SpouseCareerPreferences"

const applicationHashMap = {
  "personal_info-basic": (props) => {
    return (
      <SpousePersonalInfoInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "personal_info-address": (props) => {
    return (
      <SpouseLocationInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "personal_info-timeline": (props) => {
    return (
      <TimelineInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "spouse_info-active_duty": (props) => {
    return (
      <SpouseActiveDutyInputs
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "spouse_info-service_member_name": (props) => {
    return (
      <SpouseServiceMemberName
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  relocation: (props) => {
    return (
      <PersonalInfoInputsThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "military-initial": (props) => {
    return (
      <SpouseMilitaryBasic
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "military-basic": (props) => {
    return (
      <MilitaryOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  biography: (props) => {
    return (
      <BiographyOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-spouse_impact": (props) => {
    return (
      <BiographyImpact
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-career_move": (props) => {
    return (
      <BiographyCareerMove
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-inspiration": (props) => {
    return (
      <BiographyInspiration
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-motivations": (props) => {
    return (
      <BiographyTwo
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-top_priorities": (props) => {
    return (
      <BiographyThree
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-careers_purpose": (props) => {
    return (
      <BiographyCareersPurpose
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "career-strengths": (props) => {
    return (
      <BiographyStrengthsSkills
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-challenge": (props) => {
    return (
      <BiographyChallenge
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-military_life": (props) => {
    return (
      <BiographyMilitaryLife
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "biography-motivates": (props) => {
    return (
      <BiographyMotivates
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "career-interested_industries": (props) => {
    return (
      <CareerOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "career-career_setting": (props) => {
    return (
      <SpouseCareerSettings
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "career-preference": (props) => {
    return (
      <SpouseCareerPreferences
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "career-misc": (props) => {
    return (
      <SpouseCareerMisc
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                inputComponent,
                checkSubmittability
              })}
            </>
          )
        }}
      />
    )
  },
  "transition-challenges": (props) => {
    return (
      <TransitionOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                checkSubmittability,
                inputData,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  },
  "transition-goals": () => <TransitionGoals />,
  submission: (props) => {
    return (
      <SubmissionOne
        {...props}
        renderComponent={({
          inputComponent,
          inputData,
          checkSubmittability
        }) => {
          return (
            <>
              {props.renderComponent({
                ...props,
                inputData,
                checkSubmittability,
                inputComponent
              })}
            </>
          )
        }}
      />
    )
  }
}

export default applicationHashMap
