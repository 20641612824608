import React, { useEffect } from "react"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import UserFieldEditorContainer from "../UserFieldEditorContainer/UserFieldEditorContainer"
import ProfileHeader from "../ProfileHeader/ProfileHeader"
import ProfileFooter from "../ProfileFooter/ProfileFooter"
import ProfileInputs from "../ProfileInputs/ProfileInputs"
import ProfileNavMenu from "../ProfileNavMenu/ProfileNavMenu"
import { profileSteps } from "../../Redux/Reducers/Application/profileMetadata"
import { spouseProfileSteps } from "../../Redux/Reducers/SpouseApplication/spouseProfileMetadata"
import Actions from "../../Redux/Actions"

const Application = (props) => {
  const dispatch = useDispatch()
  
  useEffect(() => {
    const pageTitle = queryString.parse(props.history.location.search).title
    const stepOd = profileSteps.find((elem) => elem.stepId === pageTitle)
    const stepSpouse = spouseProfileSteps.find((elem) => elem.stepId === pageTitle)
    const documentTitle = stepOd ? stepOd.name : stepSpouse.name
    document.title = documentTitle ? `${documentTitle} |  Edit Profile` : 'Edit Profile'
    dispatch(Actions.fetchApplication("initialLoad", props.history))
  }, [])

  const { applicationInitialized, submittingApplication } = useSelector(
    ({ application }) => ({
      applicationInitialized: application.initialized,
      submittingApplication: application.submittingApplication
    })
  )

  return (
    <UserFieldEditorContainer
      initialized={applicationInitialized && !submittingApplication}
      drawerComponent={() => <ProfileNavMenu />}
    >
      <ProfileHeader />
      <ProfileInputs />
      <ProfileFooter />
    </UserFieldEditorContainer>
  )
}

export default withRouter(Application)
