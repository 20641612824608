import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserFieldEditorContainer from "../UserFieldEditorContainer/UserFieldEditorContainer";
import AlliedForcesApplicationFooter from "../AlliedForcesApplicationFooter/AlliedForcesApplicationFooter";
import AlliedForcesApplicationInputs from "../AlliedForcesApplicationInputs/AlliedForcesApplicationInputs";
import AlliedForcesApplicationNavMenu from "../AlliedForcesApplicationNavMenu/AlliedForcesApplicationNavMenu";
import { AlliedForcesApplicationSteps } from "../../Redux/Reducers/AlliedForcesApplication/AlliedForcesApplicationMetadata";
import Actions from "../../Redux/Actions/AlliedForcesApplication/functions";

import AlliedForcesApplicationHeader from "./AlliedForcesApplicationHeader";

import {
  setCurrentFormulary,
  getFormularyQuestions,
} from "../../Redux/Actions/Formulary/functions";

const AlliedForcesApplication = (props) => {
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({});
  const { applicationInitialized, submittingApplication, user } = useSelector(
    ({ alliedForcesApplication = {}, user: userState = {} }) => ({
      applicationInitialized: alliedForcesApplication.initialized || false,
      submittingApplication: alliedForcesApplication.submittingApplication || false,
      user: userState.user || null,
    })
  );

  useEffect(() => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    const documentTitle = AlliedForcesApplicationSteps.find(
      (elem) => elem.stepId === pageTitle
    ).name;
    document.title = `${documentTitle} | Allied Forces Application Process`;
    dispatch(Actions.fetchAlliedForcesApplication("initialLoad", props.history)); // Updated action
  }, [dispatch, props.history]);

  useEffect(() => {
    (async () => {
      const formularyType = "allied-forces-application";

      dispatch(setCurrentFormulary(formularyType, "Allied Forces Application"));
      await dispatch(getFormularyQuestions(formularyType));
    })();
  }, [dispatch]);

  return (
    <>
      <UserFieldEditorContainer
        initialized={applicationInitialized && !submittingApplication}
        drawerComponent={() => <AlliedForcesApplicationNavMenu />}
      >
        <AlliedForcesApplicationHeader />
        <AlliedForcesApplicationInputs setInputData={setInputData} />
        <AlliedForcesApplicationFooter inputData={inputData} />
      </UserFieldEditorContainer>
    </>
  );
};

export default withRouter(AlliedForcesApplication);
