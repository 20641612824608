import React, { useRef, useEffect, useState } from "react";
import "./AlliedForcesApplicationIntroduction.css";

import { Player, BigPlayButton } from "video-react";

// Use a specific environment variable for the Allied Forces introduction video
const videoUrl = process.env.REACT_APP_APPLICATION_INTRODUCTION_VIDEO_URL || null;

const AlliedForcesApplicationIntroduction = () => {
  const playerRef = useRef(null);
  const [hasStarted, setHasStarted] = useState(false);

  useEffect(() => {
    const player = playerRef?.current;

    if (player) {
      player.subscribeToStateChange((state) => {
        setHasStarted(state.hasStarted);
      });
    }
  }, []);

  useEffect(() => {
    if (hasStarted) {
      console.log("User has started introduction video");
    }
  }, [hasStarted]);

  return (
    <div className="allied-forces-application-introduction-container">
      <Player ref={playerRef} src={videoUrl}>
        <BigPlayButton position="center" />
      </Player>
    </div>
  );
};

export default AlliedForcesApplicationIntroduction;
