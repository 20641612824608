import React from "react"
import colors from "../../Constants/colors"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"

const VideoTitle = (props) => {
  return props.title ? (
    <p style={{ ...styles.videoTitle, ...props.style }}>{props.title}</p>
  ) : (
    <SkeletonWrapper theme="light" containerStyle={styles.skeletonContainer}>
      <rect x="0" y="0" rx="4" ry="4" width="300" height="40" />
    </SkeletonWrapper>
  )
}

const styles = {
  videoTitle: {
    fontFamily: "Lato-Semibold",
    fontSize: "30px",
    color: colors.midnight
  },
  skeletonContainer: {
    maxWidth: "600px",
    height: "30px",
    minWidth: "300px"
  }
}

export default VideoTitle
