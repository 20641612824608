import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
// import { fetchRecommendedVideos, fetchMoreRecommendedVideos} from "../Redux/Actions"
import { withRouter } from "react-router-dom"
import Actions from "../../Redux/Actions"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"
import DigitalLibraryInfiniteScroll from "../DigitalLibraryInfiniteScroll/DigitalLibraryInfiniteScroll"

export class SearchResults extends React.Component {
  constructor() {
    super()
    this.state = {
      searchOccurred: false,
      scrollReady: false
    }

    this.finishLoading = this.finishLoading.bind(this)
  }

  componentDidMount() {
    this.props.fetchRecommendedVideos()
  }

  finishLoading() {
    this.setState({
      scrollReady: true
    })
  }

  render() {
    return (
      <>
        <DigitalLibraryInfiniteScroll
          loadMore={this.props.fetchMoreRecommendedVideos}
          hasMore={this.props.page < this.props.lastPage}
          scrollReady={this.state.scrollReady}
        >
          <VideoThumbnailList
            finishLoading={this.finishLoading}
            style={{
              listStyleType: "none",
              padding: "calc(3.5% + 5px)",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              width: "100%",
              display: "flex"
            }}
            videos={this.props.videos}
          />
        </DigitalLibraryInfiniteScroll>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  const { fetchRecommendedVideos, fetchMoreRecommendedVideos } = Actions

  return bindActionCreators(
    {
      fetchRecommendedVideos,
      fetchMoreRecommendedVideos
    },
    dispatch
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    videos: state.digitalLibrary.videos.allIds.map(
      (videoId) => state.digitalLibrary.videos.byId[videoId]
    ),
    fetchingVideos: state.digitalLibrary.fetchingVideos,
    page: state.digitalLibrary.videos.page,
    lastPage: state.digitalLibrary.videos.lastPage
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchResults)
)
