/* eslint-disable react/no-unknown-property */
import React from "react"
import PropTypes from "prop-types"

const GrayOverlay = ({ width, height, borderR, text }) => {
  const viewBox = `0 0 ${width} ${height}`
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <defs>
        <rect id="a" width={width} height={height} rx={borderR} ry={borderR} />
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(0)">
        <mask id="b" fill="#fff">
          <use href="#a" xlinkHref="#a" />
        </mask>
        <use
          fill="#030D26"
          fillOpacity=".745"
          fillRule="nonzero"
          href="#a"
          xlinkHref="#a"
        />
        <text
          fill="#FFF"
          fontFamily="Lato-Semibold, Lato"
          fontSize="17.073"
          fontWeight="500"
          mask="url(#b)"
        >
          <tspan
            x="50%"
            y="50%"
            dominant-baseline="middle"
            text-anchor="middle"
          >
            {text || "Change"}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

GrayOverlay.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  borderR: PropTypes.string.isRequired,
  text: PropTypes.shape.isRequired
}
export default GrayOverlay
