import React from "react"
import { connect } from "react-redux"
import InputGroup from "../InputGroup/InputGroup"

const BiographyMotivates = (props) => {
  const application = window.location.href.includes('spouse') ?  props.spouseApplication : props.application
  const data = [
    {
      id: 'biography',
      inputType: "longText",
      defaultValue: `A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)`,
      placeholder: `What motivates you?`,
      charLimit: 1000,
      userField: "biography.motivations",
      title: `Biograpy`,
      required: true,
    }
  ]

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    const a = s.split(".")
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (o && k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  }

  const inputDataWithValues = data.map((input) => {
    return {
      ...input,
      value:
        Object.byString(application, input.userField) || ""
    }
  })

  return (
    <InputGroup
      {...props}
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              inputComponent,
              checkSubmittability
            })}
          </>
        )
      }}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application.application,
    spouseApplication: state.spouseApplication.application
  }
}

export default connect(mapStateToProps)(BiographyMotivates)
