import React from "react"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import TransparentButton from "../TransparentButton/TransparentButton"

const VideoEditControls = (props) => {
  const navigateToVideoEdit = () => {
    const { videoId } = queryString.parse(props.location.search)
    props.history.push(`/digitalLibraryAdmin?videoId=${videoId}`)
  }

  return (
    <TransparentButton>
      <div onClick={navigateToVideoEdit} style={styles.anchor}>
        Edit Video
      </div>
    </TransparentButton>
  )
}

const styles = {
  anchor: {
    height: "30px",
    display: "flex"
  }
}

export default withRouter(VideoEditControls)
