import types from "../../Actions/types"
import { spousePreSurveyMetadata } from "./spousePreSurveyMetadata"
import { spouseApplicationMetadata } from "./spouseApplicationMetadata"
import { spouseProfileMetadata } from "./spouseProfileMetadata"
import { spousePostSurveyMetadata } from "./spousePostSurveyMetadata"
import * as utils from "../../Utils/reducerUtils"

const spouseApplicationReducer = (
  state = {
    showErrors: false,
    spouseApplicationMetadata,
    spouseProfileMetadata,
    spousePreSurveyMetadata,
    spousePostSurveyMetadata,
    initialized: false,
    submittingSpouseApplication: false,
    saving: false,
    application: {},
    applicationIsComplete: false,
    interests: [],
    fetchingInterests: false
  },
  action
) => {
  switch (action.type) {
    case types.SUBMITTED_SPOUSE_APPLICATION:
      return {
        ...state,
        submittingApplication: false
      }
    case types.LOGOUT:
      return {
        showErrors: false,
        spouseApplicationMetadata,
        spouseProfileMetadata,
        spousePreSurveyMetadata,
        spousePostSurveyMetadata,
        initialized: false
      }
    case types.TOGGLE_SPOUSE_UNFILLED:
      return {
        ...state,
        showErrors: true,
        spouseApplicationMetadata: state.spouseApplicationMetadata.map((input) => {
          return {
            ...input,
            steps: input.steps.map((step) => {
              return !step.filled
                ? {
                    ...step,
                    unseenError: true
                  }
                : step
            })
          }
        }),
        spouseProfileMetadata: state.spouseProfileMetadata.map((input) => {
          return {
            ...input,
            steps: input.steps.map((step) => {
              return !step.filled
                ? {
                    ...step,
                    unseenError: true
                  }
                : step
            })
          }
        })
      }
    case types.SUBMIT_SPOUSE_APPLICATION:
      return {
        ...state,
        showErrors: false,
        submittingSpouseApplication: true
      }
    case types.UPDATE_LIKERT_SPOUSE:
      const { userField, value } = action.payload;
      const newState = { ...state };
      newState.spouseApplication.application[userField] = value;
      return newState;
    case types.INIT_SPOUSE_APPLICATION:
      
      const updatedMetaData = utils.processNavOptionsForApplication(
        action.data.application,
        state.spouseApplicationMetadata
      )
      
      const updatedProfileMetaData = utils.processNavOptionsforProfile(
        action.data.application,
        state.spouseProfileMetadata
      )

      return {
        ...state,
        application: action.data.application,
        spouseApplicationMetadata: updatedMetaData,
        applicationIsComplete: utils.allFormsFilled(updatedMetaData),
        profileIsComplete: utils.allFormsFilled(updatedProfileMetaData),
        initialized: true,
        profileMetadata: updatedProfileMetaData,
        userWillReLocate:
          action.data.application.address &&
          action.data.application.address.willLocate === "Yes"
      }
    case types.SET_SPOUSE_APPLICATION_INPUT_DATA:
      return {
        ...state,
        currentInputData: action.data.inputData
      }
    case types.LOGIN:
      return {
        ...state,
        initialized: false,
        showErrors: false
      }
    case types.EDIT_APPLICATION:
      return {
        ...state,
        currentInputData: null,
        saving: false
      }

    case types.FETCHED_SPOUSE_APPLICATION:
      const updatedApplicationMetaData = utils.processNavOptionsForApplication(
        action.data.application,
        state.spouseApplicationMetadata
      )

      const updatedProfileData = utils.processNavOptionsforProfile(
        action.data.application,
        state.profileMetadata
      )
      return {
        ...state,
        application: action.data.application,
        spouseApplicationMetadata: updatedApplicationMetaData,
        saving: false,
        applicationIsComplete: utils.allFormsFilled(updatedApplicationMetaData),
        profileIsComplete: utils.allFormsFilled(updatedProfileData),
        profileMetadata: updatedProfileData,
        currentInputData: null,
        userWillReLocate:
          action.data.application.address &&
          action.data.application.address.willLocate === "Yes"
      }
    case types.SAVING_SPOUSE_APPLICATION:
      return {
        ...state,
        saving: true
      }
    case types.WILL_RELOCATE_SPOUSE_CHANGED:
      const updatedMeta = utils.processNavOptionsForApplication(
        state.application,
        state.spouseApplicationMetadata
      )

      const updatedProfileMeta = utils.processNavOptionsforProfile(
        state.application,
        state.spouseApplicationMetadata
      )

      return {
        ...state,
        userWillReLocate: action.data.value,
        applicationMetadata: updatedMeta,
        profileMetadata: updatedProfileMeta
      }
    case types.UPDATE_INTERESTS:
      return {
        ...state,
        interests: action.data.interests
      }
    case types.TOGGLE_FETCHING_INTERESTS:
      return {
        ...state,
        fetchingInterests: action.data.toggle
      }

    default:
      return {
        ...state
      }
  }
}

export default spouseApplicationReducer
