/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import queryString from "query-string"
import { setLoginRedirect } from "../../Redux/Actions/User/functions"
import { getWorkshopAttendees } from "../../Redux/Actions/Workshop/functions"
import { BiographyModal } from "../Modals"
import defaultImg from "../../Assets/Images/icons/defaultNetworkImage.svg"
import CohortMembers from "./CohortMembers"
import Actions from "../../Redux/Actions"
import { LeftArrow } from "../../Icons/LeftArrow"
import { gtmClickHandler } from "../../Utils/gtm"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

import "./CohortList.css"

const styles = {
  commitButton: {
    fontFamily: "Lato-Regular",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    borderRadius: 5,
    color: "#ffffff",
    width: "100%",
    marginTop: "20px"
  }
}

const CohortList = ({ history, location }) => {
  const dispatch = useDispatch()
  const [expandMemberCard, setExpandMemberCard] = useState(false)
  const [cohortMember, setCohortMember] = useState({})

  const { errorData, attendees, isLoading, hasError, workshops } = useSelector(
    (state) => ({
      errorData: state.errors.errorData,
      user: state.user.user,
      attendees: state.workshop.attendees,
      isLoading: state.workshop.isLoading,
      hasError: state.workshop.hasError,
      workshops: state.workshop?.workshops
    })
  )

  const onLinkedInPress = ({ fullName, linkedIn }) => {
    gtmClickHandler({
      name: "LinkedInClickEvent",
      data: {
        action: "LinkedIn Clicked",
        category: "User Interaction",
        label: fullName,
        value: linkedIn
      }
    })
  }

  const onBiographyPress = (user) => {
    dispatch(
      Actions.toggleModal({
        modal_mode: true,
        modal_usi: `${BiographyModal.modalUsi}cohortMember`
      })
    )

    gtmClickHandler({
      name: "BiographyClickEvent",
      data: {
        action: "Biography Clicked",
        category: "User Interaction",
        label: user.fullName
      }
    })
  }

  const fetchAttendees = async () => {
    if (workshops.length) {
      const { workshop: workshopId } = queryString.parse(window.location.search)
      const campaign = workshopId
      dispatch(getWorkshopAttendees(campaign))
    }
  }

  const expandCard = (user) => {
    setCohortMember(user)
    setExpandMemberCard(true)
  }

  const getProfession = () => {
    if (cohortMember.currentJob) {
      return cohortMember.currentJob
    }

    if (!cohortMember?.interests?.length) {
      return "Undecided"
    }

    return cohortMember.interests.reduce(
      (currProfession, interest) =>
        currProfession.length
          ? `${currProfession}, ${interest}`
          : `Interested in ${interest}`,
      ""
    )
  }

  const buildMemberCard = () => {
    return (
      <>
        <div className="member-card-active">
          <div className="back-button-container">
            <LeftArrow height="25px" width="25px" strokeWidth="1" />
            <button
              type="button"
              className="back-button"
              onClick={() => setExpandMemberCard(false)}
            >
              BACK
            </button>
          </div>
          <div className="member-personal-info">
            <div className="member-picture">
              <img
                src={cohortMember.profile_image_url || defaultImg}
                alt="cohortMember profile"
              />
            </div>
            <div>
              <p className="member-name">{cohortMember.fullName}</p>
              <p className="member-location">
                {cohortMember.city}
                {cohortMember.state && `, ${cohortMember.state}`}
              </p>
              <p className="member-profession">{getProfession()}</p>
            </div>
            <div />
          </div>
          <div className="action-links spouse-action-links">
            <button
              className="biography-button"
              type="button"
              onClick={() => onBiographyPress(cohortMember)}
            >
              Biography
            </button>
            {cohortMember?.linkedIn ? (
              <a
                href={cohortMember.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onLinkedInPress(cohortMember)}
              >
                LinkedIn
              </a>
            ) : (
              <button type="button" className="disabled">
                LinkedIn
              </button>
            )}
          </div>
        </div>
        <BiographyModal user={cohortMember} target="cohortMember" />
      </>
    )
  }

  const redirectToNetwork = () => {
    const { workshop: workshopId } = queryString.parse(window.location.search)
    const campaign = workshopId
    history.push(`/network?spouse=true&campaignId=${campaign}`)
  }

  useEffect(() => {
    if (errorData && errorData.redirectUri) {
      const redirectPath = `${location.pathname}${location.search}`
      dispatch(setLoginRedirect(redirectPath))
      history.push("/account/login")
    }
  }, [errorData])

  useEffect(() => {
    fetchAttendees()
  }, [workshops])

  return (
    <div className="CohortListContainer spouse-course-section-content">
      {workshops.length && expandMemberCard ? (
        buildMemberCard()
      ) : (
        <>
          <CohortMembers
            isLoading={isLoading}
            attendees={attendees}
            hasError={hasError}
            fetchAttendees={fetchAttendees}
            expandCard={(user) => expandCard(user)}
          />
          <div className="network-link-container">
            <CommitButtonWithClickState
              type="red"
              clickHandler={() => redirectToNetwork()}
              style={styles.commitButton}
            >
              VIEW NETWORK
            </CommitButtonWithClickState>
          </div>
        </>
      )}
    </div>
  )
}

export default withRouter(CohortList)
