import React from "react"
import colors from "../../Constants/colors"

export default function AnalyticButtonCard({
  title,
  value,
  style,
  index,
  type,
  subject,
  category,
  likes,
  dislikes,
  isRecommended
}) {
  return (
    <div
      style={{
        backgroundColor: colors.white,
        width: "50%",
        minHeight: "fit-content",
        margin: "0px",
        padding: "20px",
        border: `2px solid ${colors.warmGrey}`,
        boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.05)",
        ...style
      }}
    >
      <h5>
        <b>{index + 1}: </b>
        {title}
      </h5>
      <p>Unique Views: {value} </p>
      {type ? <p>TYPE: {type}</p> : <></>}
      {subject ? <p>MENTOR: {subject}</p> : <></>}
      {category ? <p>CATEGORY: {category}</p> : <></>}
      {isRecommended ? <p>Video is Recommended</p> : <></>}
      {likes || likes === 0 ? (
        <p>
          LIKES: {likes}, DISLIKES: {dislikes}
        </p>
      ) : (
        <></>
      )}
    </div>
  )
}
