import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import "./PYPModuleSplashContainer.css"
import colors from "../../Constants/colors"
import PYPModuleTitleContent from "../PYPModuleTitleContent/PYPModuleTitleContent"
import CommitDrawer from "../CommitDrawer/CommitDrawer"
import PYPSidebar from "../PYPSideBar/PYPSideBar.js"
import PYPFooter from "../PYPFooter/PYPFooter"
import PYPModuleSplashMobileFooterContent from "../PYPModuleSplashMobileFooterContent/PYPModuleSplashMobileFooterContent"
import PYPModuleSplashDesktopFooterContent from "../PYPModuleSplashDesktopFooterContent/PYPModuleSplashDesktopFooterContent"

class PYPModuleSplashContainer extends React.Component {
  constructor() {
    super()

    // TODO: verify that this is deprecated, then remove
    this.lightThemeModuleNames = [
      "Welcome to COMMIT",
      "Widen Your Aperture",
      "Peak Experience",
      "Check Your Pack",
      "Courage vs. Confidence",
      // "Ideal Work Day",
      "Values",
      // "Ideal Personal Day",
      "Building Your Narrative",
      "1,000 Cups of Coffee",
      "95th Birthday"
    ]
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.pyp.navigation.currentModuleId !==
      prevProps.pyp.navigation.currentModuleId
    ) {
      const currentModule = this.props.pyp.modules.byId[
        this.props.pyp.navigation.currentModuleId
      ]
      document.title = `${currentModule.title} | Pursue Your Purpose`
      window.dataLayer.push({
        page_view: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
    }
  }

  componentDidMount() {
    const currentModule = this.props.pyp.modules.byId[
      this.props.pyp.navigation.currentModuleId
    ]
    document.title = `${currentModule.title} | Pursue Your Purpose`
    window.dataLayer &&
      window.dataLayer.push({
        page_view: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
  }

  render() {
    const currentModule = this.props.pyp.modules.byId[
      this.props.pyp.navigation.currentModuleId
    ]
    return (
      <>
        <div
          className="pypSplashModuleContainer"
          style={{
            ...styles.componentContainer,
            backgroundImage: `linear-gradient(to right, #000000, #000000 13%, rgba(0, 0, 0, 0)), url(${currentModule.img_url})`
          }}
        >
          <CommitDrawer
            style={{
              height: "auto",
              minWidth: "250px",
              width: "20.93%" // include this if you want the sidebar to expand and take up 21% of the width of the screen when > minWidth
            }}
            desktopMenu={(props) => {
              return <PYPSidebar />
            }}
          />
          <PYPModuleTitleContent
            title={currentModule.title}
            description={currentModule.description}
            theme={
              this.lightThemeModuleNames.includes(currentModule.title)
                ? "light"
                : "light"
            }
            style={styles.titleContent}
            className="pypTitleContentWrapper"
          />
        </div>
        <PYPFooter
          style={styles.footerContent}
          desktopComponent={<PYPModuleSplashDesktopFooterContent />}
          mobileComponent={<PYPModuleSplashMobileFooterContent />}
        />
      </>
    )
  }
}

const styles = {
  componentContainer: {
    marginTop: "60px",
    width: "100%",
    backgroundColor: colors.midnight,
    color: "white",
    backgroundSize: "cover",
    display: "flex"
  },
  footerContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  titleContent: {
    paddingTop: "7%",
    paddingLeft: "5%",
    paddingRight: "5%"
  },
  mobileButton: {
    width: "100%",
    fontFamily: "Lato-Regular",
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "8.5vh",
    color: colors.white
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    pyp: state.pyp
  }
}

export default withRouter(connect(mapStateToProps)(PYPModuleSplashContainer))
