import React from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import UserFieldEditorFooter from "../UserFieldEditorFooter/UserFieldEditorFooter"
import {
  validateCurrentStep,
  submitFormulary,
  saveCurrentStep
} from "../../Redux/Actions/Formulary/functions"
import { logout } from "../../Redux/Actions/User/functions"

import { getCurrentStep } from "./utils"

const FormularyFooter = ({ history, steps, stepId }) => {
  const dispatch = useDispatch()

  const { hasInvalidSection } = useSelector(({ formulary }) => ({
    hasInvalidSection: Object.values(
      formulary[formulary.currentFormularyType].sections
    ).some((section) => !section.isValid)
  }))

  const currentStep = getCurrentStep(steps, stepId)

  const { isFirst, isLast, index } = currentStep

  const saveCurrentStepChanges = () => {
    dispatch(saveCurrentStep(currentStep))
  }

  const nextHandler = async () => {
    const isCurrentStepValid = dispatch(validateCurrentStep(currentStep))

    if (!isLast) {
      saveCurrentStepChanges()
      history.push(steps[index + 1].url)
      return
    }

    if (!isCurrentStepValid || hasInvalidSection) return

    await dispatch(submitFormulary(currentStep, history))
  }

  const previousHandler = () => {
    if (!isFirst) {
      saveCurrentStepChanges()

      history.push(steps[index - 1].url)
    }
  }

  const saveAndExitHandler = () => {
    saveCurrentStepChanges()
    dispatch(logout())
    history.push("/account/login")
  }

  return (
    <UserFieldEditorFooter
      nextHandler={nextHandler}
      previousHandler={previousHandler}
      nextText={isLast ? "Finish" : "Next"}
      renderPreviousButton={!isFirst}
      saveAndExitHandler={saveAndExitHandler}
      toRenderSaveAndExitButton
    />
  )
}

FormularyFooter.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({ search: PropTypes.string }),
    push: PropTypes.func
  }).isRequired,
  stepId: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withRouter(FormularyFooter)
