import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import InfiniteScroll from "react-infinite-scroller"
import Loader from "react-loader-spinner"
import { debounce } from "debounce"
import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"

export class DigitalLibraryInfiniteScroll extends React.Component {
  constructor(props) {
    super(props)
    this.loadMore = debounce(this.loadMore.bind(this), 100)
  }

  loadMore() {
    if (this.props.scrollReady && !this.props.fetchingVideos) {
      this.props.loadMore(
        10,
        this.props.page + 1,
        this.props.match.params.searchTerm
      )
    }
  }

  render() {
    return (
      <InfiniteScroll
        threshold={0}
        initialLoad={false}
        useWindow={false}
        pageStart={0}
        hasMore={this.props.hasMore}
        loadMore={this.loadMore}
        loader={
          <div style={styles.loaderContainer}>
            <Loader
              type="Oval"
              color={colors.midnight}
              height="50"
              width="50"
            />
          </div>
        }
      >
        {this.props.children}
      </InfiniteScroll>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    fetchingVideos: state.digitalLibrary.fetchingVideos,
    page: state.digitalLibrary.videos.page
  }
}

const styles = {
  loaderContainer: {
    width: "100%",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DigitalLibraryInfiniteScroll)
)
