import React from "react"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import "./videoThumbnailDescription.css"
import colors from "../../Constants/colors"

export const VideoThumbnailDescription = (props) => {
  if (!props.description) {
    return (
      <SkeletonWrapper
        theme="light"
        containerStyle={{
          height: "50px"
        }}
      >
        <rect x="5" y="0" rx="3" ry="3" width="480" height="25" />
        <rect x="5" y="30" rx="3" ry="3" width="480" height="25" />
      </SkeletonWrapper>
    )
  }
  return (
    <div className="videoDescription" style={styles.videoDescription}>
      {props.description}
    </div>
  )
}

const styles = {
  videoDescription: {
    fontFamily: "Lato-Light",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.14",
    letterSpacing: "normal",
    color: colors.midnight,
    marginRight: "20px",
    marginBottom: "20px",
    WebkitBoxOrient: "vertical"
  }
}

export default VideoThumbnailDescription
