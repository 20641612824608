import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Loader from "react-loader-spinner"

import colors from "../../Constants/colors"
import CohortMembersCard from "./CohortMembersCard"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

const CohortMembers = ({ isLoading, attendees, hasError, fetchAttendees, expandCard }) => {
  if (hasError) {
    return (
      <div style={styles.messageContainer}>
        <span>There was an error loading the attendees list.</span>
        <br />
        <CommitButtonWithClickState
          type="red"
          clickHandler={fetchAttendees}
          style={styles.tryAgainButton}
        >
          Try again
        </CommitButtonWithClickState>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div className="workshopBannerLoader">
        <Loader type="Oval" color="#00BFFF" height={80} width={80} />
      </div>
    )
  }

  if (!attendees.length) {
    return (
      <div style={styles.messageContainer}>
        <span>No users found for this workshop.</span>
      </div>
    )
  }

  return (
    <div className="cohortListContainer">
      {attendees.map((user) => (
        <CohortMembersCard
          key={user.id}
          user={user}
          expandCard={() => expandCard(user)}
        />
      ))}
    </div>
  )
}

const styles = {
  cardContainer: {
    display: "flex",
    flexDireciton: "row",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
    height: "fit-content",
    width: "calc(100vw - 66px)",
    maxWidth: "1295px",
    margin: "auto"
  },
  messageContainer: {
    textAlign: "center",
    fontFamily: "Lato-Regular, sans-serif",
    color: colors.mediumBlue
  },
  tryAgainButton: {
    width: "150px",
    marginTop: 22
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center"
  }
}

export default CohortMembers
