import React, { Component } from "react"
import colors from "../../Constants/colors.js"

export default class UserFieldEditorSectionDescription extends Component {
  render() {
    return (
      <div style={{ ...styles.componentContainer, ...this.props.style }}>
        {this.props.children}
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    lineHeight: "23px",
    align: "center",
    color: colors.gray,
    display: "flex",
    justifyContent: "center"
  }
}
