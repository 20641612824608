import types from "../../Actions/AlliedForcesApplication/types";
import { AlliedForcesApplicationMetadata } from "./AlliedForcesApplicationMetadata";
import { AlliedForcesProfileMetadata } from "./AlliedForcesProfileMetadata";
import { AlliedForcesPreSurveyMetadata } from "./AlliedForcesPreSurveyMetadata";
import { AlliedForcesPostSurveyMetadata } from "./AlliedForcesPostSurveyMetadata";
import * as utils from "../../Utils/reducerUtils";

const AlliedForcesApplicationReducer = (
  state = {
    showErrors: false,
    applicationMetadata: AlliedForcesApplicationMetadata,
    profileMetadata: AlliedForcesProfileMetadata,
    preSurveyMetadata: AlliedForcesPreSurveyMetadata,
    postSurveyMetadata: AlliedForcesPostSurveyMetadata,
    initialized: false,
    submittingApplication: false,
    saving: false,
    application: {},
    applicationIsComplete: false,
    interests: [],
    fetchingInterests: false,
    alliedForcesApplication: {},
    alliedForcesApplicationIsComplete: false,
  },
  action
) => {
  try {
    switch (action.type) {
      case types.SUBMITTED_ALLIED_FORCES_APPLICATION:
        return {
          ...state,
          submittingApplication: false,
        };
      case types.LOGOUT:
        return {
          showErrors: false,
          applicationMetadata: AlliedForcesApplicationMetadata,
          profileMetadata: AlliedForcesProfileMetadata,
          preSurveyMetadata: AlliedForcesPreSurveyMetadata,
          postSurveyMetadata: AlliedForcesPostSurveyMetadata,
          initialized: false,
        };
      case types.TOGGLE_ALLIED_FORCES_UNFILLED:
        return {
          ...state,
          showErrors: true,
          applicationMetadata: state.applicationMetadata.map((input) => ({
            ...input,
            steps: input.steps.map((step) =>
              !step.filled ? { ...step, unseenError: true } : step
            ),
          })),
          profileMetadata: state.profileMetadata.map((input) => ({
            ...input,
            steps: input.steps.map((step) =>
              !step.filled ? { ...step, unseenError: true } : step
            ),
          })),
        };
      case types.SUBMIT_ALLIED_FORCES_APPLICATION:
        return {
          ...state,
          showErrors: false,
          submittingApplication: true,
        };
      case types.INIT_ALLIED_FORCES_APPLICATION:
        const updatedMetaData = utils.processNavOptionsForApplication(
          action.data.application,
          state.applicationMetadata
        );
        const updatedProfileMetaData = utils.processNavOptionsforProfile(
          action.data.application,
          state.profileMetadata
        );

        return {
          ...state,
          application: action.data.application,
          applicationMetadata: updatedMetaData,
          applicationIsComplete: utils.allFormsFilled(updatedMetaData),
          profileIsComplete: utils.allFormsFilled(updatedProfileMetaData),
          initialized: true,
          profileMetadata: updatedProfileMetaData,
          userWillReLocate:
            action.data.application.address &&
            action.data.application.address.willLocate === "Yes",
        };
      case types.FETCHED_ALLIED_FORCES_APPLICATION:
        const updatedAlliedForcesApplicationMetaData = utils.processNavOptionsForApplication(
          action.data.application,
          state.applicationMetadata
        );
        const updatedAlliedForcesProfileData = utils.processNavOptionsforProfile(
          action.data.application,
          state.profileMetadata
        );

        return {
          ...state,
          alliedForcesApplication: action.data.application,
          applicationMetadata: updatedAlliedForcesApplicationMetaData,
          saving: false,
          alliedForcesApplicationIsComplete: utils.allFormsFilled(updatedAlliedForcesApplicationMetaData),
          profileIsComplete: utils.allFormsFilled(updatedAlliedForcesProfileData),
          profileMetadata: updatedAlliedForcesProfileData,
          currentInputData: null,
          userWillReLocate:
            action.data.application.address &&
            action.data.application.address.willLocate === "Yes",
        };
      case types.SET_ALLIED_FORCES_APPLICATION_INPUT_DATA:
        return {
          ...state,
          currentInputData: action.data.inputData,
        };
      case types.LOGIN:
        return {
          ...state,
          initialized: false,
          showErrors: false,
        };
      case types.EDIT_ALLIED_FORCES_APPLICATION:
        return {
          ...state,
          currentInputData: null,
          saving: false,
        };
      case types.SAVING_ALLIED_FORCES_APPLICATION:
        return {
          ...state,
          saving: true,
        };
      case types.UPDATE_INTERESTS:
        return {
          ...state,
          interests: action.data.interests,
        };
      case types.TOGGLE_FETCHING_INTERESTS:
        return {
          ...state,
          fetchingInterests: action.data.toggle,
        };
      default:
        return {
          ...state,
        };
    }
  } catch (error) {
    console.error("Error occurred in AlliedForcesApplicationReducer:", error);
    return state;
  }
};

export default AlliedForcesApplicationReducer;
