import React from "react"
import { withRouter } from "react-router-dom"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import SecondaryNavDrawer from "../SecondaryNavDrawer/SecondaryNavDrawer"
import MobileNavItem from "../MobileNavItem/MobileNavItem"
import useDigitalLibraryOptions from "../../Hooks/useDigitalLibraryOptions"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

export const DigitalLibraryNavDrawer = (props) => {
  const goToMyVideos = () => {
    props.history.push("/digitalLibrary/my_videos")
    props.closeAllDrawers()
  }

  const digitalLibraryOptions = useDigitalLibraryOptions(props.match).map(
    (option, index) => (
      <MobileNavItem
        style={{ padding: "10px 40px 10px 40px" }}
        {...option}
        key={index}
      >
        <p style={{ gridColumnStart: 2 }}>{option.text}</p>
      </MobileNavItem>
    )
  )

  return (
    <>
      <SecondaryNavDrawer
        navOptions={digitalLibraryOptions}
        title="Digital Library"
        bottomButtonComponent={
          <CommitButtonWithClickState
            type="red"
            style={{ height: "100%" }}
            clickHandler={goToMyVideos}
          >
            {" "}
            My Videos{" "}
          </CommitButtonWithClickState>
        }
      />
    </>
  )
}

export default withRouter(WithDrawerConsumer(DigitalLibraryNavDrawer))
