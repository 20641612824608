import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { useSelector } from "react-redux"
import colors from "../../Constants/colors"

const useStyles = makeStyles({
  root: {
    width: "100%",
    margin: "auto",
    marginTop: 10,
    marginBottom: 20,
    overflowX: "auto",
    minWidth: 500
  },
  table: {
    minWidth: 650
  },
  header: {
    backgroundColor: colors.midnight,
    color: colors.white
  }
})

const buckets = {
  rank: {
    "E 1-3": ["E-1", "E-2", "E-3"],
    "E 4-6": ["E-4", "E-5", "E-6"],
    "E 7-9": ["E-7", "E-8", "E-9"],
    "W 1-3": ["W-1", "W-2", "W-3"],
    "W 4-5": ["W-4", "W-5"],
    "O 1-2": ["O-1", "O-2"],
    "O 3-6": ["O-3", "O-4", "O-5", "O-6"],
    "O 7-10": ["O-7", "O-8", "O-9", "O-10", "O-?"]
  },
  branch: {
    Army: ["Army"],
    Navy: ["Navy"],
    "Air Force": ["Air Force"],
    Marines: ["Marines"],
    "Coast Guard": ["Coast Guard"],
    "National Guard": ["National Guard"],
    "Space Force": ["Space Force"]
  },
  status: {
    "Active Duty": ["Active Duty"],
    Separated: ["Separated"],
    Retired: ["Retired"],
    Reserve: ["Reserve"],
    "National Guard": ["National Guard"],
    Discharged: ["Discharged"],
    Dismissed: ["Dismissed"],
    "Medical Discharge": [
      "Medical Separation (Pending or Complete)",
      "Separated (Medical)"
    ]
  },
  timeUntilSep: {
    ">18m": [">18"],
    "12-18m": ["12-18"],
    "6-12m": ["6-12"],
    "3-6m": ["3-6"],
    "<3m": ["<3"]
    // "Already Separated": ["past"]
  },
  sof: {
    YES: ["Yes"],
    NO: ["No"]
  },
  gender: {
    Male: ["Male"],
    Female: ["Female"],
    Other: ["Other"],
    "Prefer Not": ["Prefer Not To Say"]
  },
  ethnicities: {
    "American Indian": ["American Indian"],
    Asian: ["Asian"],
    Black: ["Black"],
    Latino: ["Latino"],
    "Pacific Islander": ["Pacific Islander"],
    White: ["White / Caucasian"],
    Other: ["Other"],
    "Prefer Not": ["Prefer Not To Say"]
  },
  willLocate: {
    YES: ["yes"],
    NO: ["no"]
  }
}

const sortRowsAndTitles = (inTitles, inRow, bucketTitle) => {
  const newTitles = []
  for (const key in buckets[bucketTitle]) {
    newTitles.push(key)
  }
  const newValues = []
  newTitles.forEach((title) => newValues.push(0))
  inTitles.forEach((title, index) => {
    for (const key in buckets[bucketTitle]) {
      if (buckets[bucketTitle][key].includes(title)) {
        newValues[newTitles.indexOf(key)] += parseFloat(inRow[index])
      }
    }
  })
  return {
    tableRows: newValues,
    tableCellTitles: newTitles
  }
}

export default function UserDataTable({ bucketTitle, tableTitle }) {
  const classes = useStyles()

  const analyticsData = useSelector(
    (state) => state.analytics.analyticsData[bucketTitle]
  )

  let tableCellTitles = []
  let tableRows = [{ title: "Total" }]
  let total = 0

  for (const key in analyticsData) {
    // tableCellTitles.push(<TableCell align="right">{key}</TableCell>)
    tableCellTitles.push(key)
    // tableRows.push({ title: [key] })
    total += analyticsData[key]
  }

  tableRows = tableRows.map((row) => {
    const elements = []
    for (const key in analyticsData) {
      elements.push(
        (100 * analyticsData[key]) / total
        // <TableCell align="right">
        //   {((100 * analyticsData[key]) / total).toFixed(2)}%
        // </TableCell>
      )
    }
    return {
      ...row,
      elements
    }
  })

  const newTableData = sortRowsAndTitles(
    tableCellTitles,
    tableRows[0].elements,
    bucketTitle
  )

  tableRows[0].elements = newTableData.tableRows
  tableCellTitles = newTableData.tableCellTitles
  tableRows[0].elements = tableRows.map((row) =>
    row.elements.map((element, index) => (
      <TableCell align="right" key={index}>
        {element.toFixed(2)}%
      </TableCell>
    ))
  )
  tableCellTitles = tableCellTitles.map((title, index) => (
    <TableCell className={classes.header} align="right" key={index}>
      {title}
    </TableCell>
  ))

  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell
              className={classes.header}
              component="th"
              scope="row"
              key="tableTitle"
            >
              {tableTitle}
            </TableCell>
            {tableCellTitles}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell key="titleElement" component="th" scope="row">
                {row.title}
              </TableCell>
              {row.elements}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
