/* eslint-disable no-empty */
/* eslint-disable radix */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import queryString from "query-string"
import MediaQuery from "react-responsive"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {
  getUserWorkshops,
  getWorkshopEvents
} from "../../Redux/Actions/Workshop/functions"
import "./SessionList.css"

const moment = require("moment")

const SessionList = ({ sessionNumber, setSessionNumber }) => {
  const { workshopEvents } = useSelector((state) => ({
    workshopEvents: state.workshop?.workshopEvents || []
  }))
  // const { workshop: workshopId } = queryString.parse(window.location.search)
  // const { workshopEvents } = getWorkshopEvents(workshopId)
  // console.log(getWorkshopEvents(workshopId))
  const [filteredEvents, setFilteredEvents] = useState([])
  const [open, setOpen] = useState(false)

  const changeActiveTab = (targetId) => {
    try {
      document
        .querySelector(`.active-session`)
        .classList.remove("active-session")
      document.querySelector(`#${targetId}`).classList.add("active-session")
    } catch (error) {}
  }

  const getCookie = (cookieName) => {
    const cname = `${cookieName}=`
    const cookieArray = document.cookie.split(";")

    for (let i = 0; i < cookieArray.length; i++) {
      let c = cookieArray[i]
      while (c.charAt(0) === " ") {
        c = c.substring(1)
      }
      if (c.indexOf(cname) === 0) {
        return c.substring(cname.length, c.length)
      }
    }
    return ""
  }

  const setNewEventCookie = (eventId) => {
    document.cookie = `session-${eventId}=true;`
  }

  const checkCookie = (eventId) => {
    const isNewSession = getCookie(`session-${eventId}`)

    if (isNewSession !== "") {
      return false
    }
    setNewEventCookie(eventId)

    return true
  }

  const addClassToWorkshops = () => {
    workshopEvents.map((event) => {
      // checkCookie(event.id)

      const session = document.querySelector(`#session-${event.session_number}`)
      if (!session) return false
      session.dataset.eventId = event.id
      // if current date is one week before the session/event date,
      // we activate the session for the user to see pre-session activities
      const eventDate = new Date(event.date)

      const weekDayBeforeEventDate = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate() - 7
      )

      if (
        moment.utc(new Date()).isSameOrAfter(moment.utc(weekDayBeforeEventDate))
      ) {
        session.classList.add("clickable")
        // checkCookie(event.id)
      } else {
        session.classList.add("disable-session")
      }

      return event
    })
  }

  const addClickEvent = () => {
    try {
      document.querySelectorAll(".session-name.clickable").forEach((item) => {
        item.addEventListener("click", (e) => {
          const newSessionNumber = e.target.id.split("-")[1]

          changeActiveTab(e.target.id)

          setSessionNumber(parseInt(newSessionNumber))
        })
      })
    } catch (error) {}
  }

  useEffect(() => {
    try {
      if (workshopEvents.length) {
        const sortedEvents = workshopEvents.sort((event, nextEventWorkshop) => {
          // if current event is after next one
          if (
            moment.utc(event.date).isAfter(moment.utc(nextEventWorkshop.date))
          ) {
            return 1
          }

          return -1
        })

        const eventObjs = sortedEvents.map((event) => {
          checkCookie(event.id)

          const isNewSession = getCookie(`session-${event.id}`)
          const eventDate = new Date(event.date)

          if (isNewSession === "true") {
            const weekDayBeforeEventDate = new Date(
              eventDate.getFullYear(),
              eventDate.getMonth(),
              eventDate.getDate() - 7
            )

            if (
              moment
                .utc(new Date())
                .isBefore(moment.utc(weekDayBeforeEventDate))
            ) {
              return { ...event, is_new: false }
            }

            return { ...event, is_new: true }
          }

          return { ...event, is_new: false }
        })

        setFilteredEvents(eventObjs)
      }
    } catch (error) {
      console.log(error)
    }
  }, [workshopEvents])

  useEffect(() => {
    try {
      const event = document.querySelector(`#session-${sessionNumber}`)
      event.classList.add("active-session")

      const { eventId } = event.dataset
      const isNewSession = getCookie(`session-${eventId}`)

      if (isNewSession === "true") {
        document.cookie = `session-${eventId}=false;`
        const newTag = event.querySelector(".new-session-text")

        if (newTag) {
          newTag.remove()
        }
      }
    } catch (error) {}
  }, [sessionNumber, filteredEvents])

  useEffect(() => {
    addClassToWorkshops()
    addClickEvent()
  }, [filteredEvents])

  const changeDropdown = (e) => {
    // eslint-disable-next-line prefer-destructuring
    const newSessionNumber = e.target.value.split("-")[1]
    setSessionNumber(parseInt(newSessionNumber))
  }

  const addDisabled = (event) => {
    const eventDate = new Date(event.date)

    const weekDayBeforeEventDate = new Date(
      eventDate.getFullYear(),
      eventDate.getMonth(),
      eventDate.getDate() - 7
    )
    if (
      moment.utc(new Date()).isSameOrAfter(moment.utc(weekDayBeforeEventDate))
    ) {
      return false
    }

    return true
  }

  const handleClose = (e) => {
    const { target } = e

    const isNewText = [...target.classList].includes("new-session-text")
    let eventId
    if (isNewText) {
      eventId = target.parentElement?.dataset?.eventId
    } else {
      eventId = target.dataset.eventId
    }

    const newFilteredEvents = filteredEvents.map((event) => {
      if (event.id === +eventId) {
        return { ...event, is_new: false }
      }

      return event
    })

    setFilteredEvents(newFilteredEvents)
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <MediaQuery maxWidth={767}>
        {filteredEvents.length > 0 && (
          <Select
            value={`session-${sessionNumber}`}
            onChange={changeDropdown}
            className="session-dropdown"
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
          >
            {filteredEvents.map((event, index) => (
              <MenuItem
                key={`session-${event.session_number}`}
                id={`session-${event.session_number}`}
                className="dropdownItem session-name"
                value={`session-${event.session_number}`}
                disabled={addDisabled(event)}
              >
                <div
                  className="session-name"
                  key={`session-${event.session_number}`}
                  data-event-id={event.id}
                  id={`session-${event.session_number}`}
                >
                  {`${event.session_name} `}
                  {event.is_new && index > 0 ? (
                    <div className="new-session-text">New!</div>
                  ) : (
                    ""
                  )}
                </div>
              </MenuItem>
            ))}
          </Select>
        )}
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <div className="sessions-container">
          {filteredEvents.map((event) => (
            <div
              className="session-name"
              key={`session-${event.session_number}`}
              id={`session-${event.session_number}`}
              data-event-id={event.id}
            >
              {`${event.session_name} `}
              {event.is_new && <div className="new-session-text">New!</div>}
            </div>
          ))}
        </div>
      </MediaQuery>
    </>
  )
}

export default SessionList
