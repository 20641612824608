import React from "react"
import "./PYPLayoutContainer.css"

const PYPLayoutContainer = (props) => {
  const conditionalStyles = {
    backgroundImage:
      props.backgroundImage && (`url(${props.backgroundImage})` || "none"),
    backgroundColor: props.backgroundColor || "transparent"
  }
  return (
    <div
      className="layoutContainerResponsive"
      style={{ ...conditionalStyles, ...props.style }}
    >
      {props.children}
    </div>
  )
}

export default PYPLayoutContainer
