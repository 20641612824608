Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
  s = s.replace(/^\./, "") // strip a leading dot
  const a = s.split(".")
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (o && k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

const runFilledMiddleware = (...funcs) => {
  return (value) => {
    return funcs.reduceRight((acc, func) => {
      return acc.map(func)
    }, value)
  }
}

const fillProfileLocations = (application) => (item) => {
  if (item.title === "Location") {
    const filledSteps = item.steps.map((step) => {
      if (step.stepId === "relocation") {
        return {
          ...step,
          filled: true
        }
      }
      return step
    })

    return {
      ...item,
      steps: filledSteps,
      filled: filledSteps.every((step) => step.filled)
    }
  }
  return item
}

const fillPersonalInfo = (application) => (item) => {
  if (item.title === "Personal Info") {
    const filledSteps = item.steps.map((step) => {
      if (step.stepId === "personal_info-basic") {
        if (
          application.hearAboutCommit === "Other (Please describe below)" &&
          !application.hearAboutCommitOther
        ) {
          return {
            ...step,
            filled: false
          }
        }
        return step
      }
      if (step.stepId === "relocation") {
        return {
          ...step,
          filled: true
        }
      }
      return step
    })

    const hearAboutCommitFilled =
      application.hearAboutCommit === "Other (Please describe below)"
        ? Boolean(application.hearAboutCommitOther)
        : true

    return {
      ...item,
      steps: filledSteps,
      filled: filledSteps.every((step) => step.filled) && hearAboutCommitFilled
    }
  }

  if (item.title === "Biography") {
    const filledSteps = item.steps.map((step) => {
      if (step?.stepId === "biography") {
        if (application?.biography?.biography?.length < 300) {
          return {
            ...step,
            filled: false
          }
        }

        return step
      }

      return step
    })

    return {
      ...item,
      steps: filledSteps,
      filled: filledSteps.every((step) => step.filled)
    }
  }
  
  return item
}

const fillIndustryStep = (
  itemTitle,
  buttonsField,
  customIndustryField,
  buttonStepId,
  otherStepFields
) => (application) => (item) => {
  var is_spouse = item.steps.some((step) => {
    return step.url.includes("spouse")
  })
  if (item.title === itemTitle && !is_spouse) {
    const buttonsFieldValue = Object.byString(application, buttonsField) || ''; // Fallback to empty string if undefined or null
    const customIndustryValue = Object.byString(application, customIndustryField) || ''; // Fallback to empty string if undefined or null

    const isFilled = !!(buttonsFieldValue.length || customIndustryValue);

    return {
      ...item,
      steps: item.steps.map((step) => {
        if (step.stepId === buttonStepId) {
          return {
            ...step,
            filled: isFilled
          }
        }
        return step
      }),
      filled: otherStepFields
        ? isFilled && Object.byString(application, otherStepFields)
        : isFilled
    }
  }
  return item;
}

const fillProfileIndustries = fillIndustryStep(
  "Career",
  "interestsForProfile",
  "profession.otherInterest",
  6
)

const fillCareer = fillIndustryStep(
  "Career",
  "interests",
  "career.otherInterest",
  9
)

const fillDefaultForms = (application) => (item) => {
  const steps = item.steps.map((step) => {
    const hasMissingValue = step.inputIds.some((inputId) => {
      const value = Object.byString(application, item.inputs[inputId].userField)
      if (step.name === "My Account Settings" && value === 0) {
        return false
      }
      if (step.url === "/application/spouse?title=spouse_info-service_member_name" && application.active_duty === "No") {
        return false
      }
      if (step.url === "/application/spouse?title=military-basic" && application.serve_millitary === "No") {
        return false
      } else if (step.url === "/application/spouse?title=military-basic" && item.inputs[inputId].userField==="military.sofUnit" && application.military.sof==="No") {
        return false
      }
      return Array.isArray(value)
        ? !(value.length > 0)
        : (value === null && !item.inputs[inputId].optional) ||
            value === undefined ||
            (!value && !item.inputs[inputId].optional)
    })
    return {
      ...step,
      filled: !hasMissingValue
    }
  })
  return {
    ...item,
    steps,
    filled: !steps.some((step) => {
      return !step.filled
    })
  }
}
// this is to support the case where the value is undefined, but optional
const fillProfessionalProfile = (application) => (item) => {
  const steps = item.steps.map((step) => {
    if (step.name === "Professional Information") {
      const hasMissingValue = step.inputIds.some((inputId) => {
        const value = Object.byString(
          application,
          item.inputs[inputId].userField
        )

        return Array.isArray(value)
          ? !(value.length > 0)
          : (value === null && !item.inputs[inputId].optional) ||
              (value === undefined && !item.inputs[inputId].optional) ||
              (!value && !item.inputs[inputId].optional)
      })

      return {
        ...step,
        filled: !hasMissingValue
      }
    }
    return {
      ...step
    }
  })

  return {
    ...item,
    steps,
    filled: !steps.some((step) => {
      return !step.filled
    })
  }
}

const setDisplayedOptions = (metadata, application) =>
  metadata.map((option) => {
    if (option.title === "Location") {
      const steps = option.steps.map((step) => {
        if (step.stepId === "relocation") {
          return {
            ...step,
            hidden: !(
              application.address && application.address.willLocate === "Yes"
            )
          }
        }
        return step
      })
      return {
        ...option,
        steps,
        display: true
      }
    }
    return {
      ...option,
      display: true
    }
  })

export const allFormsFilled = (forms) => {
   const hasUnfilledForm = forms.some((form) => {
      if (form.display) {
        return !form.filled
      }
    return false
  })
  return !hasUnfilledForm
}

// These could be curried to be a little more DRY. Needed to get this working for a patch and didn't have time.
export const processNavOptionsForApplication = (
  userApplication,
  applicationMetadata
) => {
  const configuredNavOptions = setDisplayedOptions(
    applicationMetadata,
    userApplication
  )
  return runFilledMiddleware(
    fillCareer(userApplication),
    fillPersonalInfo(userApplication),
    fillDefaultForms(userApplication)
  )(configuredNavOptions)
}

export const processNavOptionsforProfile = (
  userApplication,
  profileMetadata
) => {
  const configuredNavOptions = setDisplayedOptions(
    profileMetadata,
    userApplication
  )
  return runFilledMiddleware(
    fillProfileIndustries(userApplication),
    fillProfileLocations(userApplication),
    fillProfessionalProfile(userApplication),
    fillDefaultForms(userApplication)
  )(configuredNavOptions)
}
