import React from "react"

export const Play = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6"
    >
      <path fill="#D8F0F8" fillRule="nonzero" d="M6 3L0 6V0z" />
    </svg>
  )
}
