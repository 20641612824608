import React from "react"

export const RightArrow = (props) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9"
        height="19"
        viewBox="0 0 9 19"
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke="#FFF"
          d="M0 1l9 8.5L0 18"
        />
      </svg>
    </div>
  )
}
