import React from "react"
import colors from "../../Constants/colors"

// Simply renders a PYP box container. Pass in the size via the 'size' prop, or pass in custom dimensions using the style prp
const WithPYPBox = (props) => {
  const boxDimensions = {
    maxWidth:
      (props.size === "small" && "380px") ||
      (props.size === "medium" && "527px") ||
      (props.size === "large" && "605px") ||
      "380px",
    height:
      (props.size === "small" && "260px") ||
      (props.size === "medium" && "320px") ||
      (props.size === "large" && "391px") ||
      "260px"
    // height: '100%'
  }

  const borderStyles = {
    border: `solid 1px transparent`,
    boxShadow: props.selected
      ? `0 0 0 1px ${colors.fadedRed}, 0 2px 20px 0 rgba(0, 0, 0, 0.05)`
      : "0 2px 20px 0 rgba(0, 0, 0, 0.05)" /* emulate the border */
  }

  return (
    <div
      style={{
        ...borderStyles,
        ...styles.box,
        ...boxDimensions,
        ...props.style // apply styles passed in via the 'style' prop on PYPInputBox to the box div
      }}
      onClick={props.onClick}
    >
      {props.renderComponent({
        ...props
      })}
    </div>
  )
}

const styles = {
  box: {
    backgroundColor: colors.white,
    padding: "32px",
    borderRadius: "3px",
    flexDirection: "column",
    position: "relative",
    minWidth: "280px",
    display: "flex",
    width: "100%" // stretch as wide as the parent component allows, until hitting the maxWidth
  }
}

export default WithPYPBox
