import thunk from "redux-thunk"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import { save, load } from "redux-localstorage-simple"
import Rollbar from "rollbar"

import constants from "../Constants"
import { fetchMiddleware } from "./Middleware/apiWrapper"
import reducer from "./Reducers/index"

const rollbar =
  constants.ROLLBAR_KEY &&
  new Rollbar({
    accessToken: constants.ROLLBAR_KEY,
    captureUncaught: false,
    captureUnhandledRejections: false
  })

const store = createStore(
  reducer,
  load({ states: ["pyp.navigation", "api.refreshToken", "api.apiToken"] }),
  composeWithDevTools(
    applyMiddleware(
      thunk,
      fetchMiddleware(rollbar),
      save({ states: ["pyp.navigation"] }),
      save({ states: ["api.refreshToken", "api.apiToken"] })
    )
  )
)

export default store
