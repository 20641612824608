import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import Loader from "react-loader-spinner"

import { fetchApplication } from "../../../../Redux/Actions/Application/functions"
import "./TopThreeGoals.css"

const TopThreeGoals = ({ title }) => {
  const dispatch = useDispatch()

  const { goals } = useSelector((state) => {
    return {
      goals: state.application.application?.transition?.goals?.split(";") || []
    }
  })

  useEffect(() => {
    dispatch(fetchApplication(true))
  }, [])

  return (
    <div className="top-three-goals">
      <span className="title">{title}</span>
      <div className="goal-options-container">
        {goals.length > 0 ? (
          goals.map((goal) => (
            <p className="goal-option">
              <span>{goal}</span>
            </p>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader type="ThreeDots" color="#292e4c" height={80} width={80} />
          </div>
        )}
      </div>
    </div>
  )
}

TopThreeGoals.propTypes = {
  title: PropTypes.string.isRequired
}

export default TopThreeGoals
