import React from "react"
// import PropTypes from 'prop-types'

const WithEnterClick = (props) => {
  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      event.preventDefault()
      props.onEnterClick()
    }
  }
  return (
    <div className="enter-key-handler" onKeyPress={handleKeyPress}>
      {props.renderComponent({
        ...props
      })}
    </div>
  )
}

export default WithEnterClick
