import React from "react"
import colors from "../../Constants/colors"
import WithPYPBox from "../WithPYPBox/WithPYPBox.js"

const PYPStaticInputBox = ({
  title,
  size,
  text,
  selected,
  style,
  onClick,
  preventScroll
}) => {
  // const boxDimensions = {
  //   maxWidth: (size == 'small' && '380px') || (size == 'medium' && '527px') || (size == 'large' && '605px') || '380px',
  //   height: (size == 'small' && '260px') || (size == 'medium' && '320px') || (size == 'large' && '391px') || '260px',
  // }

  // max-height: 260px;
  // height: 100%;

  const scrollStyle = {
    overflow: preventScroll ? "hidden" : "auto"
  }

  return (
    <WithPYPBox
      onClick={onClick}
      size={size}
      style={style}
      selected={selected}
      renderComponent={(props) => (
        <>
          <div style={styles.titleContainer}>
            {title && <div style={styles.title}>{title}</div>}
          </div>

          <div style={{ ...styles.textAreaContainer, ...scrollStyle }}>
            {text}
          </div>
        </>
      )}
    />
  )
}

const styles = {
  titleContainer: {
    display: "flex",
    flexDirection: "row"
  },
  title: {
    fontFamily: "Lato-Bold",
    fontSize: "17px",
    color: colors.midnight,
    minHeight: "30px",
    marginBottom: "7px"
  },
  textAreaContainer: {
    height: "100%",
    width: "100%",
    wordBreak: "break-word",
    backgroundColor: colors.white,

    // Style the text
    fontFamily: "Lato-Light",
    color: colors.midnight,

    overflow: "auto"
  },

  textFade: {
    backgroundImage: "linear-gradient(to bottom, transparent, white)",
    height: "20px",
    width: "100%",
    position: "absolute",
    bottom: "0px",
    pointerEvents: "none" // allow the div to be clicked through
  },
  wordCount: {
    position: "absolute",
    right: "30px",
    fontFamily: "Lato-Medium",
    color: colors.text,
    fontSize: "14px"
  }
}

export default PYPStaticInputBox
