import React, { useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { WithDrawer } from "../WithDrawer/WithDrawer"
import colors from "../../Constants/colors"
import constants from "../../Constants"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import MobileNavItem from "../MobileNavItem/MobileNavItem"
import DigitalLibraryNavDrawer from "../DigitalLibraryNavDrawer/DigitalLibraryNavDrawer"
import WorkshopsNavDrawer from "../WorkshopsNavDrawer/WorkshopsNavDrawer"
import PYPNavDrawer from "../PYPNavDrawer/PYPNavDrawer"
import ApplicationProcessNavDrawer from "../ApplicationProcessNavDrawer/ApplicationProcessNavDrawer"
import useNavOptions from "../../Hooks/useNavOptions"
import Actions from "../../Redux/Actions"
import { FormularyNavDrawer } from "../Formulary"

// maybe we could apply the routes in the hook
const routeMap = {
  home: "/home",
  digitalLibrary: `/digitalLibrary/home`,
  editProfile: `/profile?title=${constants.PROFILE_FIRST_PAGE}`,
  pyp: `/workshop/Welcome to COMMIT`,
  logout: "/account/login",
  analytics: "/analytics",
  network: "/network",
  spouseCourse: "/virtualcohort"
}

const styleSheet = {
  drawer: {
    width: "100%",
    backgroundColor: colors.midnight,
    marginTop: "60px",
    height: "calc(100% - 60px)"
  },
  secondaryDrawer: {
    marginTop: "60px",
    width: "100%",
    height: "100vh",
    backgroundColor: colors.midnight
  },
  tertiaryDrawer: {
    width: "100%",
    height: "100%",
    top: "60px",
    backgroundColor: colors.midnight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}

const MainMobileNavDrawer = (props) => {
  useEffect(() => {
    const { pathname, search } = props.location

    if (/digitalLibrary/.test(pathname)) {
      props.openSecondaryDrawer(() => <DigitalLibraryNavDrawer />)
    } else if (
      /application\?title/.test(`${pathname}${search}`) ||
      /profile/.test(pathname)
    ) {
      props.openSecondaryDrawer(() => <ApplicationProcessNavDrawer />)
    } else if (/virtualcohort/.test(pathname)) {
      props.openSecondaryDrawer(() => <WorkshopsNavDrawer />)
    } else if (/workshop/.test(pathname)) {
      props.openSecondaryDrawer(() => <PYPNavDrawer />)
    } else if (props.formularyRoute) {
      props.openSecondaryDrawer(() => <FormularyNavDrawer />)
    }
  }, [])

  const clickHandlerMap = {
    home: null,
    digitalLibrary: () => {
      props.openSecondaryDrawer(() => {
        return <DigitalLibraryNavDrawer />
      })
    },
    spouseCourse: () => {
      props.openSecondaryDrawer(() => {
        return <WorkshopsNavDrawer />
      })
    },
    pyp: () => {
      props.openSecondaryDrawer(() => {
        return <PYPNavDrawer />
      })
    },
    editProfile: () => {
      props.openSecondaryDrawer(() => {
        return <ApplicationProcessNavDrawer />
      })
    },
    logout: () => {
      // TODO: get this into a hook
      props.logout()
      props.history.push("/account/login")
      props.closeAllDrawers()
    },
    analytics: () => {
      props.history.push("/analytics")
      props.closeAllDrawers()
    },
    network: () => {
      props.history.push("/network")
      props.closeAllDrawers()
    }
  }

  const options = useNavOptions(props.match)

  const applyRoutes = (option) => ({
    ...option,
    route: routeMap[option.usi]
  })

  const applySecondaryNavigationIcon = (option) => {
    const usisThatHaveSecondaryNav = ["digitalLibrary", "pyp", "editProfile", "spouseCourse"]
    return {
      ...option,
      hasSecondaryNavigation: usisThatHaveSecondaryNav.includes(option.usi)
    }
  }

  const applyClickHandlers = (option) => ({
    ...option,
    clickHandler: clickHandlerMap[option.usi]
  })

  const filterNonPermissibleOptions = (option) => option.toDisplay

  const navOptions = options
    .filter(filterNonPermissibleOptions)
    .map(applySecondaryNavigationIcon)
    .map(applyRoutes)
    .map(applyClickHandlers)
    .map((option, index) => (
      <MobileNavItem
        style={{ padding: "10px 40px 10px 40px" }}
        {...option}
        key={index}
      >
        <p style={{ gridColumnStart: 2 }}>{option.text}</p>
      </MobileNavItem>
    ))

  return (
    <>
      {/* Secondary Drawer */}
      <Drawer
        anchor="right"
        variant="persistent"
        classes={{
          paper: props.classes.secondaryDrawer
        }}
        SlideProps={{ unmountOnExit: true }}
        open={props.secondaryOpen}
      >
        {props.renderSecondaryComponent && props.renderSecondaryComponent()}
      </Drawer>

      {/* Tertiary Drawer */}
      <Drawer
        anchor="right"
        variant="persistent"
        classes={{
          paper: props.classes.tertiaryDrawer
        }}
        SlideProps={{ unmountOnExit: true }}
        open={props.openTertiary}
      >
        {props.renderTertiaryComponent && props.renderTertiaryComponent()}
      </Drawer>

      <div style={{ paddingTop: "25px" }}>{navOptions}</div>
    </>
  )
}

function mapDispatchToProps(dispatch) {
  const { logout } = Actions
  return bindActionCreators({ logout }, dispatch)
}

export default WithDrawerConsumer(
  withRouter(
    withStyles(styleSheet)(
      WithDrawer(connect(null, mapDispatchToProps)(MainMobileNavDrawer))
    )
  )
)
