import React, { Component } from "react"
import { withRouter, Redirect, Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Loader from "react-loader-spinner"
import moment from "moment"
import colors from "../../Constants/colors.js"
import CommitDrawer from "../CommitDrawer/CommitDrawer"
import DigitalLibraryNavMenu from "../DigitalLibraryNavMenu/DigitalLibraryNavMenu"
import Actions from "../../Redux/Actions"

import VideoLibraryHeader from "../VideoLibraryHeader/VideoLibraryHeader"
import FocusedVideoContainer from "../FocusedVideoContainer/FocusedVideoContainer"
import SearchResults from "../SearchResults/SearchResults"
import Playlist from "../Playlist/Playlist.js"
import LockedProgramView from "../LockedProgramView/LockedProgramView.js"
import ListOfPlaylists from "../ListOfPlaylists/ListOfPlaylists"
import VideoThumbnailListWithFilters from "../VideoThumbnailListWithFilters/VideoThumbnailListWithFilters"
import RecentlyWatched from "../RecentlyWatched/RecentlyWatched"
import DL_GUIDE from "../DL_GUIDE/DL_GUIDE"
import Recommended from "../Recommended/Recommended"
import GoogleTagManager from "../GoogleTagManager/GoogleTagManager"
import constants from "../../Constants"
import InvisibleGuideCompletion from "../InvisibleGuideCompletion/InvisibleGuideCompletion.js"
import FilteredPlaylists from "../FilteredPlaylists/FilteredPlaylists.js"
import SingleFilteredPlaylist from "../SingleFilteredPlaylist/SingleFilteredPlaylist"

class DigitalLibraryWrapper extends Component {
  constructor() {
    super()
    this.setDimensions = this.setDimensions.bind(this)

    this.dl = (
      <GoogleTagManager
        gtmId={process.env.REACT_APP_DL_GTM_ID}
        onFinishLoad={this.setDimensions}
      >
        <InvisibleGuideCompletion GUIDE_USI="LANDING_PAGE_DIGITAL_LIBRARY" />
        <DL_GUIDE />
        <Switch>
          <Route
            path="/digitalLibrary/:action/:type/:page"
            render={() => {
              return (
                <CommitDrawer
                  style={{ height: "100%" }}
                  desktopMenu={() => {
                    return <DigitalLibraryNavMenu />
                  }}
                />
              )
            }}
          />
          <Route
            path="/digitalLibrary/:action"
            render={() => {
              return (
                <CommitDrawer
                  style={{ height: "100%" }}
                  desktopMenu={(props) => {
                    return <DigitalLibraryNavMenu />
                  }}
                />
              )
            }}
          />
        </Switch>

        <div
          id="DL-container"
          style={{
            width: "100%",
            overflow: "auto",
            WebkitOverflowScrolling: "touch"
          }}
        >
          <Switch>
            <Route
              exact
              path="/digitalLibrary/watch"
              component={FocusedVideoContainer}
            />
            <Route
              path="/digitalLibrary/videoPlaylists/:id"
              render={({ match }) => {
                return (
                  <>
                    <VideoLibraryHeader for="playlist_with_title" />
                    <SingleFilteredPlaylist id={match.params.id} />
                  </>
                )
              }}
            />
            <Route
              path="/digitalLibrary/playlist/:page"
              render={() => {
                return <Redirect to="/digitalLibrary/my_playlist/all" />
              }}
            />

            <Route
              path="/digitalLibrary/recentlyWatched"
              render={() => {
                return <Redirect to="/digitalLibrary/recently_watched/all" />
              }}
            />
            <Route
              path="/digitalLibrary/results/:type/:category/:page"
              render={({ match }) => {
                return <Redirect to="/digitalLibrary/home" />
              }}
            />
            <Route
              exact
              path="/digitalLibrary/recommended/all"
              render={() => {
                return (
                  <>
                    <VideoLibraryHeader for="recommended" />
                    <Recommended />
                  </>
                )
              }}
            />
            <Route
              exact
              path="/digitalLibrary/recently_watched/all"
              render={() => {
                return (
                  <>
                    <VideoLibraryHeader for="recentlyWatched" />
                    <RecentlyWatched />
                  </>
                )
              }}
            />
            <Route
              exact
              path="/digitalLibrary/my_playlist/all"
              render={() => {
                return (
                  <>
                    <VideoLibraryHeader for="playlist" />
                    <Playlist />
                  </>
                )
              }}
            />
            <Route
              exact
              path="/digitalLibrary/transition"
              render={() => {
                return (
                  <>
                    <VideoLibraryHeader for="filtered_playlists" />
                    <FilteredPlaylists filter="transition" />
                  </>
                )
              }}
            />
            <Route
              exact
              path="/digitalLibrary/:type"
              render={() => {
                return (
                  <>
                    <VideoLibraryHeader for="categories" />
                    <ListOfPlaylists />
                  </>
                )
              }}
            />
            <Route
              path="/digitalLibrary/search/:searchTerm"
              render={() => {
                return <SearchResults />
              }}
            />
            <Route
              path="/digitalLibrary/:type/:category"
              render={({ match }) => {
                return (
                  <>
                    <VideoLibraryHeader for="categories" />
                    <VideoThumbnailListWithFilters />
                  </>
                )
              }}
            />
            <Route
              path="/digitalLibrary/playlist/:page"
              render={() => {
                return <Redirect to="/digitalLibrary/my_videos" />
              }}
            />
          </Switch>
        </div>
      </GoogleTagManager>
    )
  }

  componentDidMount() {
    this.props.initializeDigitalLibrary(this.props.match.params)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      document.getElementById("DL-container").scrollTop = 0
    }
  }

  renderDLView() {
    const { programs } = this.props

    const dlProgram =
      programs && programs.find((program) => program.name === "Digital Library")

    // Currently, if a user hasn't submitted an app, they'll be in a pending status.
    if (dlProgram && dlProgram.pivot.status === "pending") {
      return (
        <LockedProgramView
          programTitle="Digital Library"
          programDescritpion="Gain access to more than 400 video interviews that cover key insights and best practices from highly successful COMMIT alumni and leaders in Corporate America."
          statusText={`It looks like you haven't applied yet. Would you like to?`}
          buttonText="Apply to the Digital Library"
          buttonAction={() =>
            this.props.history.push(
              `/application?title=${constants.APPLICATION_PROCESS_FIRST_PAGE}`
            )
          }
        />
      )
    }

    if (dlProgram && dlProgram.pivot.status === "approved") {
      return this.dl // Render the entire DL (declared at bottom of this file)
    }
    // This would only be triggered if a user doesn't have a DL Program, which isn't possible atm.
    // This has process changed so much, I think we should keep this here until we're certain this is the
    // way it will be for the long-term.
    return (
      <LockedProgramView
        programTitle="Digital Library"
        programDescritpion="Thanks for applying to the Digital Library. It typically takes us two business days to review and approve Digital Library access."
        statusText={`You've already applied! Your application is under review.`}
        buttonText="Back to my applications"
        buttonAction={() => this.props.history.push(`/application`)}
      />
    )
  }

  setDimensions() {
    if (this.props.user) {
      let pypStatus = ""
      if (this.props.user.modules) {
        const userModules = this.props.user && this.props.user.modules
        if (userModules.length === 12 && userModules[11].pivot.completed) {
          pypStatus = "Completed"
        } else if (userModules.length) {
          pypStatus = "In Progress"
        } else pypStatus = "Unenrolled"
      }

      window.dataLayer.push({
        dimensions: {
          rank:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.lastRank,
          USSOCOM:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.sof,
          military_status:
            this.props.user &&
            this.props.user.military &&
            this.props.user.military.status,
          time_until_separation:
            this.props.user &&
            this.props.user.military &&
            `${moment(this.props.user.military.retirementDate).diff(
              moment(),
              "months"
            )} Months`,
          application_aubmitted: this.props.user.has_submitted_application
            ? "Yes"
            : "No",
          PYP_status: pypStatus,
          gender: this.props.user && this.props.user.gender
        }
      })
    }
  }

  render() {
    return (
      <div
        className="digitalLibraryContainer"
        ref={this.myRef}
        style={{ ...styles.componentContainer, ...this.props.style }}
      >
        {/* not rendering the DL view until weve fetched the user and any dl initialization */}
        {this.props.initialized && this.props.user.id ? (
          this.renderDLView()
        ) : (
          <div style={styles.loaderContainer}>
            <Loader
              type="Oval"
              color={colors.midnight}
              height="100"
              width="100"
            />
          </div>
        )}
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%",
    marginTop: "60px",
    height: "calc(100vh - 60px)",
    display: "flex",
    backgroundColor: colors.white,
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    initialized: state.digitalLibrary.initialized,
    programs: state.user.user && state.user.user.programs,
    user: state.user.user
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DigitalLibraryWrapper)
)
