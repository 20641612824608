import React from "react"
import { ControlBar, BigPlayButton } from "video-react"
import "./PYPVideoContainer.css"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import ContentLoader from "react-content-loader"
import Actions from "../../Redux/Actions"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import VideoReactPlayer from "../VideoReactPlayer/VideoReactPlayer.js"

const SkeletonLoader = () => (
  <ContentLoader
    height={475}
    width={720}
    speed={2}
    primaryColor="#030b20"
    secondaryColor="#051334"
  >
    <rect x="6" y="6" rx="5" ry="5" width="700" height="456" />
  </ContentLoader>
)

class PYPVideoContainer extends React.Component {
  componentDidMount() {
    this.props.fetchPartData()
  }

  render() {
    return (
      <PYPLayoutContainer style={{ ...this.props.style }}>
        <div className="pypVideoLayoutContainer">
          {this.props.videoUrl ? (
            <VideoReactPlayer
              videoTitle={`${this.props.match.params.module} Video`}
              aspectRatio="auto"
              autoPlay={false}
              height="100%"
              width="100%"
              src={
                this.props.videoUrl ||
                "https://s3.us-east-2.amazonaws.com/pursue-your-purpose-assets/videos/Widen+your+aperture+-+Wildest+Thing.mp4"
              }
            >
              <BigPlayButton
                style={{ borderColor: "green" }}
                position="center"
              />
              <ControlBar
                style={{ color: "red" }}
                autoHide
                className="my-class"
              />
            </VideoReactPlayer>
          ) : (
            <SkeletonLoader />
          )}
        </div>
      </PYPLayoutContainer>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  let videoUrl

  if (currentPart.partData) {
    const partDataId = currentPart.partData
    const partData = state.pyp.partData.byId[partDataId]

    if (partData && partData.data.video.length) {
      videoUrl = partData.data.video[0].url
    }
  }

  return {
    ...props,
    videoUrl
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPVideoContainer)
)
