import React from "react";
import NonDodIcApplicationHashMap from "../NonDodIcApplicationEditor/NonDodIcApplicationHashMap"; // Updated import path and hash map name
import UserFieldEditor from "../UserFieldEditor/UserFieldEditor";

/*
  This component's primary objective is to pass in the inputs required to map for UserFieldEditor.
  It seems small, but it allows us to share a lot of code between the Non-DOD IC Application Process and Profile.
*/

const NonDodIcApplicationInputs = ({ setInputData }) => {
  return <UserFieldEditor inputs={NonDodIcApplicationHashMap} setInputData={setInputData} />;
};

export default NonDodIcApplicationInputs;
