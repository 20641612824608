import React from "react"

export const LeftArrow = (props) => {
  return (
    <div style={{ display: "flex", ...props.style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "10"}
        height={props.height || "14"}
        viewBox="0 0 10 14"
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke || "#E1373A"}
          strokeWidth={props.strokeWidth || "3"}
          d="M9 13L3 7l6-6"
        />
      </svg>
    </div>
  )
}
