import React, { useEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import UpperMobileNavDefaultOptions from "../UpperMobileNavDefaultOptions/UpperMobileNavDefaultOptions"
import MobileDigitalLibrarySearch from "../MobileDigitalLibrarySearch/MobileDigitalLibrarySearch"
import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"

const UpperMobileNavMenu = (props) => {
  useEffect(() => {
    props.match.params.searchTerm && props.showSearchBar()
  }, [props.match.url])

  return (
    <nav
      style={{
        ...styles.componentContainer,
        gridTemplateColumns: props.searchingDL ? "1fr" : "33.3% 33.3% 33.3%"
      }}
    >
      {props.searchingDL ? (
        <MobileDigitalLibrarySearch />
      ) : (
        <UpperMobileNavDefaultOptions />
      )}
    </nav>
  )
}

const styles = {
  componentContainer: {
    backgroundColor: colors.midnight,
    width: "100%",
    height: "60px",
    display: "grid",
    alignItems: "center"
  },
  btn: {
    justifySelf: "flex-start",
    marginLeft: "20px",
    backgroundColor: colors.midnight,
    border: "none"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    searchingDL: state.digitalLibrary.searching
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpperMobileNavMenu)
)
