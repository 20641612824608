import React from "react"
import {
  Page,
  Document,
  StyleSheet,
  Text,
  View,
  Image,
  Font
} from "@react-pdf/renderer"
import FullLogoDark from "../../Assets/Images/FullLogoDark.png"
import LatoRegular from "../../Assets/Fonts/Lato-Regular.ttf"
import LatoBold from "../../Assets/Fonts/Lato-Bold.ttf"

Font.register({
  family: "Lato",
  fonts: [
    {
      src: LatoRegular
    },
    {
      src: LatoBold,
      fontWeight: 700
    }
  ]
})

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    fontFamily: "Lato",
    paddingBottom: 40
  },
  module: {
    margin: "0 20 10 20",
    padding: 10,
    borderBottom: "1 solid #9FA9BA",
    paddingBottom: 30
  },
  pageHeader: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    justifyContent: "flex-start",
    top: 20,
    left: 0,
    right: 0,
    height: 50,
    margin: "0 25 40 25",
    borderBottom: "2 solid #9FA9BA",
    fontSize: "4mm",
    letterSpacing: ".2mm"
  },
  pageNumbers: {
    textAlign: "right",
    height: 30,
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: 65
  },
  pageTitle: {
    color: "#030D26",
    marginRight: "auto",
    height: 30,
    position: "absolute",
    bottom: 0,
    left: 110,
    whiteSpace: "pre"
  },
  buttonTitle: {
    fontWeight: 700
  },
  partTitle: {
    fontSize: "4.5mm",
    marginBottom: 10,
    fontWeight: 700
  },
  partInput: {
    fontSize: "4mm",
    marginBottom: 15
  },
  title: {
    fontSize: "6mm",
    marginBottom: 10,
    fontWeight: 700
  },
  logo: {
    height: 30,
    marginRight: 25
  }
})

export default function PYPDownloadPage({ PYP_modules, firstName = "Jim" }) {
  return (
    <Document title="Pursue Your Purpose">
      <Page size="A4" style={styles.page} wrap>
        <View
          style={styles.pageHeader}
          render={({ pageNumber, totalPages }) => (
            <>
              <Image src={FullLogoDark} style={styles.logo} />
              <Text style={styles.pageTitle}>
                {" "}
                {`${firstName}'s ${" "}Pursuing ${" "}Your ${" "}Purpose ${" "}Information`}{" "}
              </Text>
              <Text
                style={styles.pageNumbers}
                render={({ pageNumber, totalPages }) =>
                  `Page  ${pageNumber}  of  ${totalPages}`
                }
              />
            </>
          )}
          fixed
        />
        {PYP_modules &&
          PYP_modules.map((singleModule, index) => {
            return (
              <View key={index} style={styles.module}>
                <Text style={styles.title}>{singleModule.module_title}</Text>
                {singleModule &&
                  singleModule.part_data.map((PYP_part, index) => {
                    return (
                      <View key={index} style={styles.part} wrap>
                        <Text style={styles.partTitle}>{PYP_part.title}</Text>
                        {PYP_part.user_inputs.map((input, index) => {
                          return (
                            <Text key={index} style={styles.partInput}>
                              {" "}
                              <Text style={styles.buttonTitle}>
                                {input.title} {" - "}{" "}
                              </Text>
                              {input.input}
                            </Text>
                          )
                        })}
                      </View>
                    )
                  })}
              </View>
            )
          })}
      </Page>
    </Document>
  )
}
