import React, { Component } from "react"

class ListWithSelectedComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      components: props.components
    }
    this.selectComponent = this.selectComponent.bind(this)
  }

  selectComponent(index) {
    return (e) => {
      this.setState({
        components: this.state.components.map((component, i) => {
          return i === index
            ? { ...component, isSelected: true }
            : { ...component, isSelected: false }
        })
      })
    }
  }

  render() {
    return (
      <>
        {this.state.components.map(({ isSelected, renderComponent }, i) => {
          return renderComponent({
            isSelected,
            selectOption: this.selectComponent(i),
            key: i,
            index: i
          })
        })}
      </>
    )
  }
}

export default ListWithSelectedComponent
