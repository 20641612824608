import React from "react"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { withStyles } from "@material-ui/core/styles"
import colors from "../../Constants/colors.js"

const styleSheet = {
  labelRoot: {
    color: colors.fadedRed,
    fontFamily: "Lato-Regular"
  },
  iOSSwitchBase: {
    "&$iOSChecked": {
      color: colors.white,
      boxShadow: 0,
      "& + $iOSBar": {
        backgroundColor: colors.fadedRed
      }
    }
  },
  iOSChecked: {
    transform: "translateX(15px)",
    "& + $iOSBar": {
      opacity: 1,
      border: "none"
    }
  },
  iOSBar: {
    borderRadius: 13,
    width: 42,
    height: 20,
    marginTop: -10,
    marginLeft: -21,
    border: "solid 1px",
    borderColor: colors.fadedRed,
    backgroundColor: colors.fadedRed,
    opacity: 1
  },
  iOSIcon: {
    width: 16,
    height: 16,
    boxShadow: "none"
  }
}

export class CommitCheckedButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.value || false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(name) {
    return (e) => {
      this.setState({
        checked: e.target.checked
      })
      this.props.onChange({
        target: {
          value: e.target.checked
        }
      })
    }
  }

  render() {
    return (
      <FormControlLabel
        classes={{
          label: this.props.classes.labelRoot
        }}
        control={
          <>
            <span
              style={{
                whiteSpace: "nowrap",
                color: colors.warmGrey,
                fontSize: "16px",
                lineHeight: "17px",
                fontfamily: "Lato-Regular"
              }}
            >
              {this.props.title}
            </span>
            <Switch
              classes={{
                switchBase: this.props.classes.iOSSwitchBase,
                iconChecked: this.props.classes.iOSIconChecked,
                checked: this.props.classes.iOSChecked
              }}
              onChange={this.handleChange("checked")}
              checked={this.state.checked}
            />
          </>
        }
        label={this.state.checked ? "Yes" : "No"}
      />
    )
  }
}

export default withStyles(styleSheet)(CommitCheckedButton)
