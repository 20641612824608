import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import { withStyles } from "@material-ui/core/styles"
import moment from "moment"
import colors from "../../Constants/colors.js"
import FormDropdown from "../FormDropdown/FormDropdown"
import LongTextInput from "../LongTextInput/LongTextInput"
import ReactVideoThumbnail from "../ReactVideoThumbnail/ReactVideoThumbnail"
import CommitCheckedButton from "../CommitCheckedButton/CommitCheckedButton"
import StatelessFormDropdown from "../StatelessFormDropdown/StatelessFormDropdown.js"

const styleSheet = (theme) => ({
  inputText: {
    color: colors.midnight
  },
  label: {
    fontFamily: "Lato-Regular",
    color: colors.midnight
  },
  root: {
    fontFamily: "Lato-Semibold"
  },
  multiLine: {
    [theme.breakpoints.down("md")]: {
      marginTop: "-15px"
    }
  },
  manyLine: {
    marginTop: "-25px"
  },
  longLine: {
    [theme.breakpoints.down(1500)]: {
      marginTop: "-15px"
    }
  }
})

class CommitInput extends PureComponent {
  getInput(type) {
    // The following code is AWESOME!
    // with es6, we are removing the classes prop and only passing the remainder to the child component
    const { classes, ...options } = this.props
    const rootSelectLabelClass =
      this.props.placeHolder && this.props.placeHolder.length > 40
        ? `${this.props.classes.label}, ${this.props.classes.longLine}`
        : this.props.classes.label
    switch (type) {
      case "date":
        const rootDateLabelClass =
          this.props.placeHolder.length > 40
            ? this.props.placeHolder.length > 80
              ? `${this.props.classes.label} ${this.props.classes.manyLine}`
              : `${this.props.classes.label} ${this.props.classes.multiLine}`
            : this.props.classes.label
        return (
          <TextField
            InputLabelProps={{
              style:
                this.props.placeHolder.length > 40 ? this.props.inputStyle : {},
              classes: {
                root: rootDateLabelClass
              },
              shrink: true
            }}
            classes={{
              root: this.props.inputText
            }}
            // convert utc for Safari
            defaultValue={
              moment(this.props.value).isValid()
                ? moment(this.props.value).utc().format("YYYY-MM-DD")
                : ""
            }
            error={this.props.error && this.props.renderError}
            type="date"
            style={styles.input}
            id={this.props.placeHolder}
            label={this.props.placeHolder}
            margin="dense"
            InputProps={{
              endAdornment: this.props.adornment
              // startAdornment: this.props.startAdornment
            }}
            onChange={this.props.handleChange}
          />
        )
      case "imageUpload":
        return <ReactVideoThumbnail img={this.props.value} {...this.props} />
      case "radio":
        return (
          <CommitCheckedButton
            {...options}
            {...classes}
            onChange={this.props.handleChange}
          />
        )
      case "textWithDefault":
        const rootLabelClassWithDefault =
          this.props.placeHolder.length > 40
            ? `${this.props.classes.label} ${this.props.classes.multiLine}`
            : this.props.classes.label
        return (
          <TextField
            InputLabelProps={{
              classes: {
                root: rootLabelClassWithDefault
              }
            }}
            classes={{
              root: this.props.inputText
            }}
            defaultValue={this.props.defaultValue}
            // value={this.props.value || ""}
            error={this.props.error && this.props.renderError}
            type={this.props.type || "text"}
            style={{ ...styles.input, ...this.props.style }}
            id={this.props.placeHolder}
            label={this.props.placeHolder}
            margin="dense"
            InputProps={{
              endAdornment: this.props.adornment
              // startAdornment: this.props.startAdornment
            }}
            onChange={this.props.handleChange}
          />
        )
      case "text":
        const rootLabelClass =
          this.props.placeHolder.length > 40
            ? `${this.props.classes.label} ${this.props.classes.multiLine}`
            : this.props.classes.label
        return (
          <TextField
            InputLabelProps={{
              classes: {
                root: rootLabelClass
              }
            }}
            classes={{
              root: this.props.inputText
            }}
            value={this.props.value || ""}
            error={this.props.error && this.props.renderError}
            type={this.props.type || "text"}
            style={styles.input}
            id={this.props.placeHolder}
            label={this.props.placeHolder}
            margin="dense"
            InputProps={{
              endAdornment: this.props.adornment
              // startAdornment: this.props.startAdornment
            }}
            onChange={this.props.handleChange}
          />
        )
      case "numberInput":
        const rootLabelNumberInputClass =
          this.props.placeHolder.length > 40
            ? `${this.props.classes.label} ${this.props.classes.multiLine}`
            : this.props.classes.label

        return (
          <TextField
            InputLabelProps={{
              classes: {
                root: rootLabelNumberInputClass
              }
            }}
            classes={{
              root: this.props.inputText
            }}
            value={this.props.value || ""}
            error={this.props.error && this.props.renderError}
            type="number"
            style={styles.input}
            id={this.props.placeHolder}
            label={this.props.placeHolder}
            margin="dense"
            InputProps={{
              endAdornment: this.props.adornment,
              autoComplete: "off"

              // startAdornment: this.props.startAdornment
            }}
            onChange={this.props.handleChange}
          />
        )
      case "number":
        const rootLabelInputClass =
          this.props.placeHolder.length > 40
            ? `${this.props.classes.label}, ${this.props.classes.multiLine}`
            : this.props.classes.label
        return (
          <TextField
            InputLabelProps={{
              classes: {
                root: rootLabelInputClass
              }
            }}
            classes={{
              root: this.props.inputText
            }}
            value={this.props.value || ""}
            error={this.props.error && this.props.renderError}
            type={this.props.type || "text"}
            style={styles.input}
            id={this.props.placeHolder}
            label={this.props.placeHolder}
            margin="dense"
            InputProps={{
              endAdornment: this.props.adornment
              // startAdornment: this.props.startAdornment
            }}
            onChange={this.props.handleChange}
          />
        )
      case "select":
        return (
          <FormDropdown
            labelClass={rootSelectLabelClass}
            value={this.props.value}
            title={this.props.title}
            options={this.props.options.map((option) => {
              return { ...option, isSelected: "false" }
            })}
            onChange={this.props.handleChange}
            disabled={this.props.disabled}
          />
        )
      case "stateless-select":
        return (
          <StatelessFormDropdown
            innerStyle={this.props.innerStyle}
            style={this.props.style}
            labelClass={rootSelectLabelClass}
            value={this.props.value}
            title={this.props.title}
            options={this.props.options.map((option) => {
              return { ...option, isSelected: "false" }
            })}
            onChange={this.props.handleChange}
          />
        )
      case "time":
        return (
          <TextField
            InputLabelProps={{
              classes: {
                root: rootDateLabelClass
              },
              shrink: true
            }}
            classes={{
              root: this.props.inputText
            }}
            // convert utc for Safari
            defaultValue={
              moment(this.props.value).isValid()
                ? moment(this.props.value).format("HH:mm a")
                : ""
            }
            error={this.props.error && this.props.renderError}
            min={this.props.min}
            max={this.props.max}
            value={this.props.value}
            type="time"
            style={styles.input}
            id={this.props.value}
            margin="dense"
            InputProps={{
              endAdornment: this.props.adornment
              // startAdornment: this.props.startAdornment
            }}
            onChange={this.props.handleChange}
          />
        )
      case "longText":
        return <LongTextInput {...options} />

      default:
        return null
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          ...styles.componentContainer,
          ...this.props.style
        }}
      >
        {this.getInput(this.props.inputType)}
        {this.props.error ? (
          <FormHelperText
            error
            id="component-error-text"
            style={{ marginTop: "0px", fontSize: '15px' }}
          >
            {this.props.helperText || "Error"}
          </FormHelperText>
        ) : null}
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  input: {
    width: "100%"
  }
}

CommitInput.propTypes = {
  placeHolder: PropTypes.string
}

export default withStyles(styleSheet, { withTheme: true })(CommitInput)
