import React from "react"

const likertValues = [
    { key: 1, value: "Strongly Disagree" },
    { key: 2, value: "Disagree" },
    { key: 3, value: "Neither Disagree or Agree" },
    { key: 4, value: "Agree" },
    { key: 5, value: "Strongly Agree" }
]

const AgreeDisagree = ({ title, inputId, value, onChange }) => {
    const handleRadioChange = (e) => {
        // Call the provided onChange handler to update the value
        onChange(e.target.value);
    };
    return (
        <div className="likert-input">
            <span className="title text-center">{title}</span>
            <div className="options">
                {likertValues.map((likertValue) => {
                    return (
                        <input
                            id={likertValue.key}
                            name={inputId}
                            type="radio"
                            checked={value === likertValue.value}
                            value={likertValue.value}
                            onChange={handleRadioChange}
                        />
                    )
                })}
            </div>
            <div className="description-container">
                <span>Strongly Disagree</span>
                <span className="hidden-xs">Disagree</span>
                <span>Neither Disagree or Agree</span>
                <span className="hidden-xs">Agree</span>
                <span>Strongly Agree</span>
            </div>
        </div>
    )
}

export default AgreeDisagree
