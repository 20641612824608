import React, { Component } from "react"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { withStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import colors from "../../Constants/colors"

const styleSheet = {
  root: {
    fontFamily: "Lato-Regular",
    color: colors.midnight,
    width: "100%"
  },
  formControl: {
    minWidth: 120
  },
  rootTwo: {
    display: "flex",
    flexWrap: "wrap"
  }
}
class CommitDropdown extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedOptionValue: props.value || ""
    }

    this.selectOption = this.selectOption.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value && !prevState.selectedOptionValue) {
      this.setState({
        selectedOptionValue: this.props.value
      })
    }
  }

  selectOption(e) {
    this.setState(
      {
        selectedOptionValue: e.target.value
      },
      () => {
        this.props.onChange(e)
      }
    )
  }

  render() {
    return (
      <form
        className={this.props.classes.rootTwo}
        style={{ ...this.props.style }}
      >
        <FormControl
          classes={{
            root: this.props.classes.root
          }}
          disabled={this.props.disabled}
        >
          <InputLabel
            classes={{
              root: `${this.props.labelClass} ${this.props.classes.root}`
            }}
            htmlFor={`title_${this.props.title}`}
          >
            {this.props.title}
          </InputLabel>
          <Select
            value={this.state.selectedOptionValue}
            onChange={this.selectOption}
            classes={{
              root: this.props.classes.root
            }}
            inputProps={{
              id: `title_${this.props.title}`
            }}
          >
            {this.props.options.map((option, i) => (
              <MenuItem
                key={i}
                value={option.value}
                style={{
                  padding: ".25em",
                  fontSize: "14px",
                  cursor: "pointer",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start"
                }}
                className="dropdownItem"
              >
                {option.value}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{this.props.helperText}</FormHelperText>
        </FormControl>
      </form>
    )
  }
}

export default withStyles(styleSheet)(CommitDropdown)
