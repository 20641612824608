import React from "react"
import { useSelector, useDispatch } from "react-redux"
import Actions from "../../Redux/Actions"
import Tooltip from "../Tooltip/Tooltip"
import colors from "../../Constants/colors"
import { useWindowResize } from "../../Hooks/useWindowResize"
import OutsideClickWrapper from "../../Hooks/outsideClickWrapper"

const DL_GUIDE = () => {
  const guideComplete = useSelector((state) => {
    return (
      state.user.user.guides &&
      state.user.user.guides.find((guide) => {
        return guide.unique_string_identifier === "DIGITAL_DIRECTION"
      })
    )
  })
  const { width } = useWindowResize()
  const dispatch = useDispatch()

  const modal = (
    <Tooltip
      style={{
        display: "flex",
        position: "absolute",
        left: "450px",
        background: "#C4C4C4"
      }}
      modalBodyStyles={styles.modalBodyStyles}
      customBurgeStyles={styles.customBurge}
      show={!guideComplete && width > 768}
      width={290}
      height={50}
      modalHorizontalOffset={40}
      arrowOffset={-20}
      orientation="custom"
      customDimensions={styles.customDimensions}
      toggleModal={() => {
        dispatch(Actions.completeGuide("DIGITAL_DIRECTION"))
      }}
      contentComponent={(props) => {
        // The modal body
        return (
          <div style={styles.modalInner}>
            <p style={styles.header}>Start Here!</p>
            <p>
              The Journey contians a currated list of resources for you, based
              on your current time to transition.
            </p>
          </div>
        )
      }}
      renderComponent={(props) => {
        return (
          <></>
          // <ChatIcon
          //   style={{
          //     color: colors.fadedRed,
          //     marginRight: "20px",
          //     cursor: "pointer"
          //   }}
          //   onClick={this.clickChatIcon}
          // />
        )
      }}
    />
  )

  return (
    <OutsideClickWrapper
      clickHandler={() => dispatch(Actions.completeGuide("DIGITAL_DIRECTION"))}
    >
      {modal}
    </OutsideClickWrapper>
  )
}

const styles = {
  modalInner: {
    padding: "15px",
    color: colors.mediumBlue,
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Lato-Regular",
    background: "#C4C4C4",
    width: "400px"
  },
  customBurge: {
    width: "0px",
    height: "96px",
    borderTop: "48px solid transparent",
    borderBottom: "48px solid transparent",
    borderRight: "20px solid #C4C4C4",
    position: "absolute",
    left: `-20px`,
    top: `0px`
  },
  customDimensions: {
    left: "221px",
    top: "145px",
    margin: "0px",
    width: "290px",
    height: "400px"
  },
  modalBodyStyles: {
    padding: "0px"
  },
  header: {
    fontFamily: "Lato-Bold"
  }
}

export default DL_GUIDE
