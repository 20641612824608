import React, { Component } from "react"
import PropTypes from "prop-types"
import colors from "../../Constants/colors"
import "./CommitButtonWithClickState.css"

const styles = {
  componentContainer: {
    width: "100%",
    minHeight: "44px",
    cursor: "pointer",
    border: 0,
    fontFamily: "Lato-Regular",
    padding: "10px",
    fontSize: "14px",
    lineHeight: 1.21,
    whiteSpace: "nowrap"
  }
}

// This component persists styling of a clicked button without anything
// from the parent. If any lifting state is needed for that persistence - check out
// CommitButtonWithouClickState, where you handle it in the parent
export default class CommitButtonWithClickState extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isClicked: false,
      isHovered: false,
      justDeselected: false
    }

    this.clickHandler = this.clickHandler.bind(this)
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseLeaveHandler = this.onMouseLeaveHandler.bind(this)
  }

  onMouseEnterHandler() {
    // A hacky thing to determine if we're on a touch device or not.
    // Code within index.js adds the ' hasHover' class to the body element of the
    // app whenever the mouse moves (meaning user is not on a touch device)
    // Here, you can check if ' hasHover' class exists. If not, don't set isHovered to true
    if (
      document.body.className === "hasHover" ||
      document.body.className === " hasHover"
    ) {
      this.setState({ isHovered: true })
    }
  }

  onMouseLeaveHandler() {
    this.setState({ isHovered: false, justDeselected: false })
  }

  clickHandler(passedClickFunc) {
    return (event) => {
      this.props.gtmHandler &&
        this.props.gtmHandler({
          action: this.props.action,
          category: this.props.category,
          label: this.props.id
        })

      passedClickFunc(event)
      this.setState({
        justDeselected: !!this.state.isClicked,
        isClicked: !this.state.isClicked
      })
    }
  }

  render() {
    const passedClickFunc = this.props.clickHandler
      ? this.props.clickHandler
      : () => {}
    const passedClickedStyle = this.props.clickedStyle
      ? this.props.clickedStyle
      : {}

    let passedDefaultStyle = {}
    let passedHoverStyle = {}

    switch (this.props.type) {
      case "gray":
        passedDefaultStyle = {
          border: `solid 1px ${colors.warmGrey}`,
          borderRadius: "3px",
          transition: "all 0.3s ease-in-out",
          backgroundColor: colors.warmGrey,
          color: colors.white
        }
        break
      case "whiteMediumBlue":
        passedDefaultStyle = {
          border: `solid 1px ${colors.mediumBlue}`,
          borderRadius: "3px",
          transition: "all 0.3s ease-in-out",
          backgroundColor: colors.white,
          color: colors.mediumBlue
        }
        passedHoverStyle = {
          backgroundColor: colors.mediumBlue,
          color: "#fff",
          borderRadius: "3px",
          border: `solid 1px ${colors.mediumBlue}`,
          transition: "all 0.3s ease-in-out"
        }
        break
      case "mediumBlue":
        passedDefaultStyle = {
          border: "solid 1px",
          borderRadius: "3px",
          transition: "all 0.3s ease-in-out",
          backgroundColor: colors.mediumBlue,
          color: colors.white
        }
        break
      case "red":
        passedDefaultStyle = {
          // width: "154px",
          border: "solid 1px",
          borderRadius: "3px",
          borderColor: colors.fadedRed,
          transition: "all 0.3s ease-in-out",
          backgroundColor: colors.fadedRed,
          color: colors.white
        }
        passedHoverStyle = {
          backgroundColor: "#ffffff",
          color: "#E1373A",
          borderRadius: "3px",
          border: "solid 1px",
          borderColor: "#E1373A",
          transition: "all 0.3s ease-in-out"
        }
        break
      case "white":
        passedDefaultStyle = {
          // width: "154px",
          border: "solid 1px",
          borderColor: colors.fadedRed,
          transition: "all 0.3s ease-in-out",
          backgroundColor: colors.white,
          color: colors.fadedRed
        }
        passedHoverStyle = {
          backgroundColor: colors.fadedRed,
          color: colors.white,
          border: "solid 1px",
          borderColor: "#E1373A",
          transition: "all 0.3s ease-in-out"
        }
        break
      default:
    }

    passedDefaultStyle = { ...passedDefaultStyle, ...this.props.style }
    passedHoverStyle = { ...passedHoverStyle, ...this.props.hoverStyle }

    const isClickedStyle =
      this.state.isClicked || this.props.isClicked ? passedClickedStyle : null
    const isHoveredStyle = this.state.isHovered ? passedHoverStyle : null
    const isJustDeselectedStyle = this.state.justDeselected
      ? passedDefaultStyle
      : null
    return (
      <button
        className="commitButton hasHover"
        type="button"
        style={{
          ...styles.componentContainer,
          ...passedDefaultStyle,
          ...this.props.style,
          ...isClickedStyle,
          ...isHoveredStyle,
          ...isJustDeselectedStyle
        }}
        aria-label={this.props.label}
        id={this.props.id}
        data-id={this.props.dataId}
        onClick={this.clickHandler(passedClickFunc)}
        onMouseEnter={this.onMouseEnterHandler}
        onMouseLeave={this.onMouseLeaveHandler}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    )
  }
}



CommitButtonWithClickState.defaultProps = {
  disabled: false
}

CommitButtonWithClickState.propTypes = {
  clickHandler: PropTypes.func,
  clickedStyle: PropTypes.object,
  hoverStyle: PropTypes.object,
  disabled: PropTypes.bool
}
