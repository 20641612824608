import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import Loader from "react-loader-spinner"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"

class UserOrSpinner extends React.Component {
  componentDidMount() {
    // This is to catch the case when a user logs out and hits the back button (or navigates to a page without creds)
    // The redux and component hiearchy is designed so the user should never be loggingIn
    // when this mounts, but this conditional is just a safety net - this could trigger a loop of a user logging in,
    // and then immediately getting logged out, user logging in, getting logged out, user logging in...
    if (!this.props.userLoggingIn) {
      // This will only get run after the api token has been set, verify that with the redux if you want!
      // this means that there is no token, and we didn't get here through login. So we need to redirect to login
      // and get a new one
      if (!this.props.refreshToken) {
        this.props.reportError(
          "we have a user trying to access the userOrSpinner without an apitoken"
        )
        this.props.logout()
        this.props.history.push("/account/login")
      }
    }
  }

  render() {
    return (
      <>
        {this.props.userId ? (
          this.props.children
        ) : (
          <div style={styles.loaderContainer}>
            <Loader
              type="Oval"
              color={colors.midnight}
              height="100"
              width="100"
            />
          </div>
        )}
      </>
    )
  }
}

const styles = {
  loaderContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    userId: state.user.user && state.user.user.id,
    userloggingIn: state.user.user && state.user.loggingIn,
    refreshToken: state.api && state.api.refreshToken
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserOrSpinner)
)
