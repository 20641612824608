import React from "react"
import PropTypes from "prop-types"

import "./ProgressBar.css"

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        <span className="progress-bar-fill" style={{ width: `${progress}%` }} />
      </div>
      <span>{progress}% Completed</span>
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number
}

ProgressBar.defaultProps = {
  progress: 0
}

export default ProgressBar
