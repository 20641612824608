import { normalize, schema } from "normalizr"
import uuid from "uuid/v1"
import apiRequest from "../../../Utils/apiRequest"
import types from "../types"

const partDataSchema = new schema.Entity("partData", {})
const navigationPartSchema = new schema.Entity("navigationPart", {})

const elementSchema = new schema.Entity("elements", {})
const partSchema = new schema.Entity("parts", {
  elements: [elementSchema],
  partData: partDataSchema
})

const moduleSchema = new schema.Entity("modules", {
  parts: [partSchema],
  navigationParts: [navigationPartSchema]
})

const courseSchema = new schema.Entity("courses", {
  modules: [moduleSchema]
})

// const videoSchema = new schema.Entity("videos", {})
// const categorySchema = new schema.Entity("categories", {})

// const typeSchema = new schema.Entity("types", {
//   videos: [videoSchema]
// })

const fetch = (url, params, requestPayload) => ({
  type: "FETCH",
  url,
  params,
  requestPayload: {
    timeout: 20000
  }
})

const internals = {}

// used during increment, initialize, and de-increment part
internals.getElementString = (currentElements, pyp) => {
  return currentElements
    .map((element) => {
      if (element.name) {
        return element.name
      }
      return pyp.elements.byId[element].name
    })
    .sort()
    .toString()
}

export const exitModule = (history) => {
  return async (dispatch, getState) => {
    const { pyp } = getState()
    history.push(
      `/workshop/${pyp.modules.byId[pyp.navigation.currentModuleId].title}`
    )
    try {
      dispatch({
        type: types.EXIT_MODULE
      })
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const navigateToModule = (moduleTitle, history) => {
  return async (dispatch, getState) => {
    const { modules } = getState().pyp
    const module = modules.allIds.find((m) => {
      return modules.byId[m].title === moduleTitle
    })
    dispatch({
      type: types.NAVIGATE_TO_MODULE,
      data: {
        moduleId: module
      }
    })
  }
}

const getCurrentPartIndex = (parts, partId) => {
  return parts.allIds.indexOf(partId)
}

const getNextPartIndex = (currentPartId, parts, currentPartIndex) => {
  return currentPartIndex < parts.allIds.length - 1
    ? parts.allIds.indexOf(currentPartId) + 1
    : -1
}

const getCurrentModuleIndex = (modules, moduleId) => {
  return modules.allIds.indexOf(moduleId)
}

const getPreviousPartIndex = (currentPartIndex) => {
  return currentPartIndex > 0 ? currentPartIndex - 1 : -1
}

const fetchMetaDataUserInputData = async (dispatch, partId) => {
  const inputTitlesRes = await dispatch(
    fetch(`/inputTitles`, {
      type: "GET",
      data: {
        params: { partId }
      }
    })
  )

  const inputsRes = await dispatch(
    fetch(`/userInputs`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )
  const titleRes = await dispatch(
    fetch(`/pypPartMetaData`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  const asyncApiCalls = await Promise.all([inputTitlesRes, inputsRes, titleRes])

  // Todo: Could set initializingPYPInputs to true here... Or set PYPInputsInitialized to false

  return {
    id: uuid(),
    data: {
      title: asyncApiCalls[2].data,
      inputTitle: asyncApiCalls[0].data,
      inputs: asyncApiCalls[1].data || []
    }
  }
}

const fetchButtonData = async (dispatch, partId, withReference) => {
  try {
    let btnPartId = partId
    if (withReference) {
      // Get all referenced parts for this particular part
      const referencedPartRes = await dispatch(
        fetch(`/partReferences`, {
          type: "GET",
          data: {
            params: { partId }
          }
        })
      )
      btnPartId = referencedPartRes.data[0].part_reference_id
    }

    const buttonValuesRes = await dispatch(
      fetch(`/buttonValues`, {
        type: "GET",
        data: {
          params: {
            partId: btnPartId
          }
        }
      })
    )

    /*
 Custom Button Values are currently unused. It was a feature that was approved, but never released due to client concerns.
 It's possible it might make it back in, so we didn't remove the code.
*/
    // const customButtonValuesRes = await axios.get(
    //   `/customButtonValues/`,
    //   {
    //     headers: { Authorization: apiToken },
    //     params: { partId: btnPartId }
    //   }
    // );

    // const customButtonValues = customButtonValuesRes.data.map(value => {
    //   return { ...value, isCustom: true };
    // });

    const titleRes = await dispatch(
      fetch(`/pypPartMetaData`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    /*
 Custom Button Values are currently unused. It was a feature that was approved, but never released due to client concerns.
 It's possible it might make it back in, so we didn't remove the code.
*/
    return {
      id: uuid(),
      data: {
        buttonValues: [
          // ...customButtonValues,
          ...buttonValuesRes.data
        ],
        // customButtonValues: customButtonValues,
        title: titleRes.data
      }
    }
  } catch (e) {
    console.log(e)
    dispatch({
      type: types.ERROR,
      data: e
    })
  }
}

const fetchMetaDataReference = async (dispatch, partId) => {
  const titleRes = await dispatch(
    fetch(`/pypPartMetaData`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  // Get all referenced parts for this particular part
  // const referencedPartRes = await axios.get(`/partReferences/`, {
  //   headers: { Authorization: apiToken },
  //   params: { partId }
  // })
  const referencedPartRes = await dispatch(
    fetch(`/partReferences`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  let referenceInputs = []
  for (const part of referencedPartRes.data) {
    // fetch the referenced part
    const referenceInputsRes = await dispatch(
      fetch(`/userInputs`, {
        type: "GET",
        data: {
          params: {
            partId: part.part_reference_id
          }
        }
      })
    )

    referenceInputs = [...referenceInputs, ...referenceInputsRes.data]
  }

  // TODO: All the above could be done with just two asynchronous API calls!
  return {
    id: uuid(),
    data: {
      title: titleRes.data,
      referenceInputs
    }
  }
}

const fetchMetaDataUserInput = async (dispatch, partId) => {
  // 1) Get title META DATA
  const titleRes = await dispatch(
    fetch(`/pypPartMetaData`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  // 2) Get REFERENCES to previous input cards
  // Get all referenced parts for this particular part

  const referencedPartRes = await dispatch(
    fetch(`/partReferences`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  let referenceInputs = []
  for (const part of referencedPartRes.data) {
    // fetch the referenced part

    const referenceInputsRes = await dispatch(
      fetch(`/userInputs`, {
        type: "GET",
        data: {
          params: {
            partId: part.part_reference_id
          }
        }
      })
    )

    referenceInputs = [...referenceInputs, ...referenceInputsRes.data]
  }

  // 3) Get data for inputs - INPUT TITLES & USER INPUTS
  const inputTitlesRes = await dispatch(
    fetch(`/inputTitles`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  const inputsRes = await dispatch(
    fetch(`/userInputs`, {
      type: "GET",
      data: {
        params: {
          partId
        }
      }
    })
  )

  return {
    id: uuid(),
    data: {
      title: titleRes.data,
      referenceInputs,
      inputTitle: inputTitlesRes.data,
      inputs: inputsRes.data || []
    }
  }
}

const apiMap = {
  "Button Selection Stage 2,Meta Data": async (apiToken, partId) => {
    return fetchButtonData(apiToken, partId, true)
  },
  "Button Selection,Meta Data": async (apiToken, partId) => {
    return fetchButtonData(apiToken, partId, false)
  },
  Video: async (dispatch, partId) => {
    const videoRes = await dispatch(
      fetch(`/pypVideos`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    return {
      id: uuid(),
      data: {
        video: videoRes.data
      }
    }
  },
  Instruction: async (dispatch, partId) => {
    const instructionRes = await dispatch(
      fetch(`/instructions`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    return {
      id: uuid(),
      data: {
        instruction: instructionRes.data
      }
    }
  },
  "Meta Data,User Input Large": async (dispatch, partId) => {
    return fetchMetaDataUserInputData(dispatch, partId)
  },
  "Meta Data,User Input Medium": async (dispatch, partId) => {
    return fetchMetaDataUserInputData(dispatch, partId)
  },
  "Meta Data,User Input Small,User Input Small,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInputData(dispatch, partId)
  },
  "Meta Data,User Input Small": async (dispatch, partId) => {
    return fetchMetaDataUserInputData(dispatch, partId)
  },

  "Meta Data,Reference Lead Selection Small": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },

  "Meta Data,Reference Box Single Small": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },
  "Meta Data,Reference Box Single Medium": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },
  "Meta Data,Reference Box Single Large": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Small": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Medium": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Large": async (dispatch, partId) => {
    return fetchMetaDataReference(dispatch, partId)
  },

  // There's potentially 18 of these
  "Meta Data,Reference Box Single Small,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Single Small,User Input Medium": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Single Small,User Input Large": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },

  "Meta Data,Reference Box Single Medium,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Single Medium,User Input Medium": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Single Medium,User Input Large": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },

  "Meta Data,Reference Box Single Large,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Single Large,User Input Medium": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Single Large,User Input Large": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Small,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Small,User Input Medium": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Small,User Input Large": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },

  "Meta Data,Reference Box Carousel Medium,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Medium,User Input Medium": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Medium,User Input Large": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },

  "Meta Data,Reference Box Carousel Large,User Input Small": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Large,User Input Medium": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },
  "Meta Data,Reference Box Carousel Large,User Input Large": async (
    dispatch,
    partId
  ) => {
    return fetchMetaDataUserInput(dispatch, partId)
  },

  // This Layout takes values entered from buttons in previous parts and renders user input boxes with titles from the button values
  "Meta Data,Reference User Input,User Input Small": async (
    dispatch,
    partId
  ) => {
    const titleRes = await dispatch(
      fetch(`/pypPartMetaData`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    // Get all referenced parts for this particular part
    // const referencedPartRes = await axios.get(`/partReferences/`, {
    //   headers: { Authorization: apiToken },
    //   params: { partId }
    // })
    const referencedPartRes = await dispatch(
      fetch(`/partReferences`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    // Here, assuming only one part is referenced:
    const referencedPartId = referencedPartRes.data[0].part_reference_id

    const buttonValuesRes = await dispatch(
      fetch(`/buttonValues`, {
        type: "GET",
        data: {
          params: {
            partId: referencedPartId
          }
        }
      })
    )

    // Fetch any existing user inputs:
    const inputsRes = await dispatch(
      fetch(`/userInputs`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    return {
      id: uuid(),
      data: {
        title: titleRes.data,
        buttonValues: buttonValuesRes.data,
        inputs: inputsRes.data || []
        // referenceInputs: referenceInputs
      }
    }
  },
  "Meta Data,Reference User Input,User Input Small,User Input Small,User Input Small": async (
    dispatch,
    partId
  ) => {
    const titleRes = await dispatch(
      fetch(`/pypPartMetaData`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    // Get all referenced parts for this particular part
    const referencedPartRes = await dispatch(
      fetch(`/partReferences`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    // Fetch any existing user inputs:
    const inputsRes = await dispatch(
      fetch(`/userInputs`, {
        type: "GET",
        data: {
          params: {
            partId
          }
        }
      })
    )

    const asyncApiCalls = await Promise.all([
      titleRes,
      referencedPartRes,
      inputsRes
    ])

    // Here, assuming only one part is referenced:
    const referencedPartId = asyncApiCalls[1].data[0].part_reference_id

    const buttonValuesRes = await dispatch(
      fetch(`/buttonValues`, {
        type: "GET",
        data: {
          params: {
            partId: referencedPartId
          }
        }
      })
    )

    /*
 Custom Button Values are currently unused. It was a feature that was approved, but never released due to client concerns.
 It's possible it might make it back in, so we didn't remove the code.
*/

    // const customButtonValuesRes = await axios.get(
    //   `/customButtonValues/`,
    //   {
    //     headers: { Authorization: apiToken },
    //     params: { partId: referencedPartId }
    //   }
    // );
    // const customButtonValues = customButtonValuesRes.data.map(value => {
    //   return { ...value, isCustom: true };
    // });

    return {
      id: uuid(),
      data: {
        title: asyncApiCalls[0].data,
        buttonValues: [
          // ...customButtonValues,
          ...buttonValuesRes.data
        ],
        inputs: asyncApiCalls[2].data || []
        // referenceInputs: referenceInputs
      }
    }
  },
  "Meta Data,Roadmapper": async (dispatch, partId) => {
    try {
      const partTitleRes = await dispatch(
        fetch(`/pypPartMetaData`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      const timeEvents = await dispatch(
        fetch("/timeEvents", {
          type: "GET",
          data: {
            params: { partId }
          }
        })
      )
      const eventOrder = await dispatch(
        fetch("/eventOrder", {
          type: "GET",
          data: {
            params: { partId }
          }
        })
      )
      // Test once ideal:day:v:2 attaches part_references
      const referencedPartRes = await dispatch(
        fetch(`/partReferences`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )

      const constraintsRes = await dispatch(
        fetch("/inputSingleLine", {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[0].part_reference_id
            }
          }
        })
      )

      const buttonValuesRes = await dispatch(
        fetch(`/buttonValues`, {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[1].part_reference_id
            }
          }
        })
      )
      return {
        id: uuid(),
        data: {
          partTitle: partTitleRes.data,
          userTimeEvents: timeEvents.data,
          eventOrder: eventOrder.data,
          referencedPartData: {
            constraints: constraintsRes.data,
            buttonValues: buttonValuesRes.data
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  "Action Planner,Meta Data": async (dispatch, partId) => {
    try {
      const partTitleRes = await dispatch(
        fetch(`/pypPartMetaData`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      let dateEvents = await dispatch(
        fetch("/dateEvent", {
          type: "GET",
          data: {
            params: { partId }
          }
        })
      )
      if (!dateEvents.data.length) {
        dateEvents = await dispatch(
          fetch(`/defaultDateEvents`, {
            type: "POST",
            data: {
              requestPayload: { partId }
            }
          })
        )
      }
      const eventOrder = await dispatch(
        fetch("/eventOrder", {
          type: "GET",
          data: {
            params: { partId }
          }
        })
      )
      const referencedPartRes = await dispatch(
        fetch(`/partReferences`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )

      const constraintsRes = await dispatch(
        fetch("/inputSingleLine", {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[0].part_reference_id
            }
          }
        })
      )
      return {
        id: uuid(),
        data: {
          partTitle: partTitleRes.data,
          userDateEvents: dateEvents.data,
          eventOrder: eventOrder.data,
          referencedPartData: {
            constraints: constraintsRes.data
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  "Constraints,Meta Data": async (dispatch, partId) => {
    try {
      const partTitleRes = await dispatch(
        fetch(`/pypPartMetaData`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      const constraintsRes = await dispatch(
        fetch("/inputSingleLine", {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      return {
        id: uuid(),
        data: {
          partTitle: partTitleRes.data,
          constraints: constraintsRes.data
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  "Bargraph,Meta Data": async (dispatch, partId) => {
    try {
      const partTitleRes = await dispatch(
        fetch(`/pypPartMetaData`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      const referencedPartRes = await dispatch(
        fetch(`/partReferences`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      // console.log(referencedPartRes.data, "FULL_PART_RES_SHOULD_HAVE_PART_IDS")
      // [0]: constraints
      // [1]: buttons
      // [2]: timeEvents
      const constraintsRes = await dispatch(
        fetch("/inputSingleLine", {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[0].part_reference_id
            }
          }
        })
      )
      const buttonValuesRes = await dispatch(
        fetch(`/buttonValues`, {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[1].part_reference_id
            }
          }
        })
      )
      const timeEventsRes = await dispatch(
        fetch("/timeEvents", {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[2].part_reference_id
            }
          }
        })
      )
      return {
        id: uuid(),
        data: {
          partTitle: partTitleRes.data,
          referencedPartData: {
            constraints: constraintsRes.data,
            buttonValues: buttonValuesRes.data,
            timeEvents: timeEventsRes.data
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  "Calender,Meta Data": async (dispatch, partId) => {
    try {
      const partTitleRes = await dispatch(
        fetch(`/pypPartMetaData`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )
      const referencedPartRes = await dispatch(
        fetch(`/partReferences`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )

      const constraintsRes = await dispatch(
        fetch("/inputSingleLine", {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[0].part_reference_id
            }
          }
        })
      )

      const dateEventsRes = await dispatch(
        fetch("/dateEvent", {
          type: "GET",
          data: {
            params: {
              partId: referencedPartRes.data[1].part_reference_id
            }
          }
        })
      )

      return {
        id: uuid(),
        data: {
          partTitle: partTitleRes.data,
          referencedPartData: {
            dateEvents: dateEventsRes.data.map((item) => ({
              title: item.title,
              time: item.moment_data
            })),
            start: {
              title: "Today",
              time: constraintsRes.data[0].text
            },
            end: {
              title: "My Next Chapter",
              time: constraintsRes.data[1].text
            }
          }
        }
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.ERROR,
        data: error.response
      })
    }
  },
  "Meta Data,User Input Example": async (dispatch, partId) => {
    try {
      const partTitleRes = await dispatch(
        fetch(`/pypPartMetaData`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )

      const userInputExampleRes = await dispatch(
        fetch(`/userInputExamples`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )

      return {
        id: uuid(),
        data: {
          partTitle: partTitleRes.data,
          userInputExamples: userInputExampleRes.data
        }
      }
    } catch (e) {
      console.log(e)
    }
  },
  default: () => {
    return []
  }
}

export const incrementPartId = (history) => {
  return async (dispatch, getState) => {
    try {
      const {
        pyp,
        user: { user }
      } = getState()

      // Don't allow user to change parts if data is being fetched for the current part
      if (pyp.fetchingPartData) {
        return
      }

      dispatch({
        type: types.INCREMENTING_PYP
      })

      const { currentPartId, currentModuleId } = pyp.navigation
      let nextModuleIndex
      const updatedUnlockedUserModules = pyp.unlockedUserModules

      const currentIndex = getCurrentPartIndex(pyp.parts, currentPartId)
      let nextIndex = getNextPartIndex(currentPartId, pyp.parts, currentIndex)
      const currentModuleIndex = getCurrentModuleIndex(
        pyp.modules,
        currentModuleId
      )
      const currentUserModuleId = pyp.unlockedUserModules.find(
        (unlockedModule) => unlockedModule.module_id === currentModuleId
      ).id
      const moduleAlreadyCompleted =
        pyp.unlockedUserModules.find(({ id }) => id === currentUserModuleId)
          ?.completed === 1

      // We've navigated to the end of the module
      if (nextIndex === -1) {
        if (currentModuleIndex < pyp.modules.allIds.length - 1) {
          nextModuleIndex = currentModuleIndex + 1
        } else {
          nextModuleIndex = currentModuleIndex
          nextIndex = currentIndex
        }

        if (!moduleAlreadyCompleted) {
          await dispatch(
            fetch(`/userModules/${currentUserModuleId}`, {
              type: "PUT",
              data: {
                requestPayload: {
                  completed: true,
                  isFinalModule:
                    currentModuleIndex === pyp.modules.allIds.length - 1
                }
              }
            })
          )
        }

        await dispatch(
          fetch(`/userModules`, {
            type: "POST",
            data: {
              requestPayload: {
                moduleId:
                  pyp.modules.byId[pyp.modules.allIds[nextModuleIndex]].id
              }
            }
          })
        )

        const updatedUnlockedUserModulesRes = await dispatch(
          fetch(`/userModules`, {
            type: "GET"
          })
        )

        history.push(
          `/workshop/${
            pyp.modules.byId[pyp.modules.allIds[nextModuleIndex]].title
          }`
        )

        const nextPartId = pyp.parts.allIds[nextIndex]

        dispatch({
          type: types.INCREMENT_PART,
          data: {
            partId: nextPartId,
            moduleId: pyp.modules.allIds[nextModuleIndex],
            unlockedUserModules: updatedUnlockedUserModulesRes.data,
            partData: pyp.partData
          }
        })

        const userRes = await dispatch(
          fetch(`/user`, {
            type: "GET"
          })
        )

        dispatch({
          type: types.FETCHED_USER,
          user: userRes.data[0]
        })

        // navigating to the next part within the module
      } else {
        nextModuleIndex = currentModuleIndex

        dispatch({
          type: types.INCREMENT_PART,
          data: {
            partId: pyp.parts.allIds[nextIndex],
            moduleId: pyp.modules.allIds[nextModuleIndex],
            unlockedUserModules: updatedUnlockedUserModules
          }
        })
      }

      /* Display the pyp congratulations modal before final module */
      const { data: updatedUserModules } = await dispatch(
        fetch(`/userModules`, {
          type: "GET"
        })
      )
      const hasUnlockedAllModules =
        pyp.modules.allIds.length === updatedUserModules.length
      const hasFinishedAllModules = updatedUserModules.every(
        ({ completed }) => completed
      )
      const hasCoach = Boolean(user.pyp.has_coaching)
      const isSecondLastStep = nextIndex === pyp.parts.allIds.length - 1

      if (
        !hasCoach &&
        !moduleAlreadyCompleted &&
        !hasFinishedAllModules &&
        hasUnlockedAllModules &&
        isSecondLastStep
      ) {
        dispatch({
          type: types.TOGGLE_MODAL,
          data: {
            modal: {
              pypModal: true
            }
          }
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.ERROR,
        data: error.response
      })
      // history.push('/');
    }
  }
}

export const fetchPartData = () => {
  return async (dispatch, getState) => {
    try {
      // Dispatch fetching action
      dispatch({
        type: types.FETCHING_PART_DATA
      })

      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const currentElements = pyp.parts.byId[currentPartId].elements

      const elementString = internals.getElementString(currentElements, pyp)
      const apiCall = apiMap[elementString] || apiMap.default
      const data = await apiCall(dispatch, currentPartId)

      const part = pyp.parts.byId[currentPartId]
      part.partData = data

      const n = normalize(
        {
          parts: [part],
          partData: [data]
        },
        {
          parts: [partSchema],
          partData: [partDataSchema]
        }
      )

      const elements = pyp.parts.byId[currentPartId].elements.map(
        (elementId) => pyp.elements.byId[elementId]
      )

      // // NOTE: This not ever happen anymore because now you can't increment parts until data has finished fetching...
      // // Important! By the time the api calls above are returned, a user could
      // // have clicked 'Next' or 'Previous' and changed the current part!
      // // This checks to ensure the user is on the same part the API call was triggered from.
      // const newCurrentPartId = getState().pyp.navigation.currentPartId
      // if(currentPartId !== newCurrentPartId) {
      //   // If now on a different part, return from the fuction without any dispatch
      //   // navigation.fetchingPartData will be left to true
      //   return
      // }

      dispatch({
        type: types.FETCHED_PART_DATA,
        data: {
          partData: {
            allIds: n.result.partData,
            byId: n.entities.partData
          },
          updatedPart: n.entities.parts,
          currentPartComplete: partIsComplete(elements, data.data)
        }
      })

      // const newState = getState();
      // debugger;
      // Check if inputs have been intialized
      // if(data.data.hasOwnProperty('inputs') && !data.data.inputs.length) {
      //   const inputsToMake = currentElements
      //     .map(elementId => pyp.elements.byId[elementId])
      //     .filter(element => element.name.includes("User Input"));
      //
      //   // Iterate through each inputTitle and perform a POST to create a new inputTitle. Save each response within an array
      //   const userInputInsertions = inputsToMake.map(async (input, i) => {
      //     debugger;
      //     return await axios.post(
      //       `/userInputs`,
      //       {
      //         partId: currentPartId,
      //         titleId: data.data.inputTitle[i].id,
      //         size: getInputSize(input),
      //         text: ""
      //       },
      //       { headers: { Authorization: getState().api.apiToken } }
      //     );
      //   });
      //
      //   const inputsRes = await Promise.all(userInputInsertions); //.map(input => input.data)
      //
      //   dispatch({
      //     type: types.CREATE_PYP_INPUTS,
      //     data: {
      //       partDataId: data.id,
      //       inputs: inputsRes.map(input => input.data)
      //     }
      //   });
      // }
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.ERROR,
        data: error.response
      })
      // history.push('/');
    }
  }
}

export const navigateToWorkshop = (history, match) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      // we might not have the course or module data
      // todo: the code should figure that out and get it or not depending on the current state
      const courseRes = await dispatch(
        fetch(`/courses`, {
          type: "GET",
          data: {
            params: {
              name: "Pursue Your Purpose"
            }
          }
        })
      )

      const moduleRes = await dispatch(
        fetch(`/courses/${courseRes.data.id}/modules`, {
          type: "GET"
        })
      )

      const courseData = courseRes.data
      courseData.modules = moduleRes.data

      let moduleToNavigateTo

      // if user has navigation stored in localstorage, we go there,
      // else we go to the first module
      if (pyp.navigation.currentModuleId > -1) {
        moduleToNavigateTo = moduleRes.data.find((module) => {
          return module.id === pyp.navigation.currentModuleId
        })
      } else {
        moduleToNavigateTo = moduleRes.data[0]
      }

      history.push(`/workshop/${moduleToNavigateTo.title}`)
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.ERROR,
        data: error
      })
    }
  }
}

export const decrementPartId = (history) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()

      // Don't allow user to change parts if data is being fetched for the current part
      if (pyp.fetchingPartData) {
        return
      }

      const { currentPartId, currentModuleId } = pyp.navigation
      const currentPartIndex = getCurrentPartIndex(pyp.parts, currentPartId)
      const previousIndex = getPreviousPartIndex(currentPartIndex)
      const moduleIndex = getCurrentModuleIndex(pyp.modules, currentModuleId)

      if (previousIndex === -1) {
        history.push(
          `/workshop/${pyp.modules.byId[pyp.modules.allIds[moduleIndex]].title}`
        )
      } else {
        // const previousPart = pyp.parts.byId[pyp.parts.allIds[previousIndex]];
        // history.push(
        // `/workshop/${pyp.modules.byId[pyp.modules.allIds[moduleIndex]].title}/content?what=prev`
        // );
      }

      dispatch({
        type: types.DECREMENT_PART,
        data: {
          partId: pyp.parts.allIds[previousIndex],
          moduleId: pyp.modules.allIds[moduleIndex]
        }
      })
    } catch (data) {
      console.log("error:", data)
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const beginModule = (history) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentModuleId } = pyp.navigation

      history.push(
        `/workshop/${pyp.modules.byId[currentModuleId].title}/content`
      )

      // TOOD: only if started isn't set to true

      const currentUnlockedUserModuleIdModule = pyp.unlockedUserModules.find(
        (unlockedModule) => {
          return unlockedModule.module_id === currentModuleId
        }
      ).id

      const userModules = await dispatch(
        fetch(`/userModules`, {
          type: "GET"
        })
      )

      const currentModule = userModules.data.filter((module) => {
        return module.module_id === currentModuleId
      })[0]

      const moduleAlreadyCompleted = currentModule.completed === 1
      const moduleAlreadyStarted = currentModule.started === 1

      // window.dataLayer.push({
      //   pypModule: pyp.modules.byId[currentModule.module_id].title
      // })

      if (!moduleAlreadyStarted) {
        await dispatch(
          fetch(`/userModules/${currentUnlockedUserModuleIdModule}`, {
            type: "PUT",
            data: {
              requestPayload: {
                started: true
              }
            }
          })
        )

        const updatedUnlockedUserModules = await dispatch(
          fetch(`/userModules`, {
            type: "GET"
          })
        )

        const userRes = await dispatch(
          fetch(`/user`, {
            type: "GET"
          })
        )

        dispatch({
          type: types.FETCHED_USER,
          user: userRes.data[0]
        })

        try {
          dispatch({
            type: types.BEGIN_MODULE,
            data: {
              unlockedUserModules: updatedUnlockedUserModules.data,
              module: pyp.modules.byId[currentModule.module_id]
            }
          })
        } catch (error) {
          console.error(error)
          dispatch({
            type: types.ERROR,
            data: error.response
          })
        }
      } else if (!moduleAlreadyCompleted) {
        // these two dispatches are for google analytics and not for redux
        dispatch({
          type: types.RESUME_MODULE,
          data: {
            module: pyp.modules.byId[currentModule.module_id]
          }
        })
      } else {
        dispatch({
          type: types.UPDATE_MODULE,
          data: {
            module: pyp.modules.byId[currentModule.module_id]
          }
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: types.ERROR,
        data: error.response
      })
      // history.push('/');
    }
  }
}

const checkIfConstraintsAreFilled = () => (partData) => {
  const { constraints } = partData
  return !!(constraints && constraints[0] && constraints[1])
}

const checkIfButtonSelectionFilled = (limit, stage) => (partData) => {
  const buttonSelections = partData.buttonValues.filter((buttonValue) => {
    if (partData.valuesToUpdate) {
      return (
        buttonValue.buttonSelections.length &&
        (stage ? buttonValue.buttonSelections[0].pivot.stage === 2 : true) &&
        !partData.valuesToUpdate.find((value) => {
          return value.id === buttonValue.id && !value.isSelected
        })
      )
    }
    return (
      buttonValue.buttonSelections.length &&
      (stage ? buttonValue.buttonSelections[0].pivot.stage === 2 : true)
    )
  })

  return partData.valuesToUpdate
    ? partData.selectedItems.length >= limit
    : buttonSelections.length >= limit
}

const checkIfInputFilled = () => (partData) => {
  const unfilledInput = partData.inputs.find((input) => !input.text.length)

  if (unfilledInput || !partData.inputs.length) {
    return false
  }
  return true
}

const checkIfLeadSelectionComplete = () => (partData) => {
  const indexOfLead = partData.referenceInputs.findIndex(
    (input) => input.buttonSelectionTitle.is_lead
  )
  if (indexOfLead < 0 || !partData.referenceInputs.length) {
    return false
  }
  return true
}

const fillCheckerMap = {
  "User Input Small": checkIfInputFilled(),
  "User Input Medium": checkIfInputFilled(),
  "User Input Large": checkIfInputFilled(),
  "Button Selection": checkIfButtonSelectionFilled(10, null),
  "Button Selection Stage 2": checkIfButtonSelectionFilled(3, 2),
  "Reference Lead Selection Small": checkIfLeadSelectionComplete(),
  "Reference Lead Selection Medium": checkIfLeadSelectionComplete(),
  "Reference Lead Selection Large": checkIfLeadSelectionComplete(),
  Constraints: checkIfConstraintsAreFilled(),
  default: () => {
    console.warn("fillCheckerMap received invalid argument")
  }
}

const partIsComplete = (partElements, partData) => {
  const needToBeFilledElements = [
    "User Input Small",
    "User Input Medium",
    "User Input Large",
    "Button Selection",
    "Button Selection Stage 2",
    "Reference Lead Selection Small",
    "Reference Lead Selection Medium",
    "Reference Lead Selection Large",
    "Constraints"
  ]

  const elementsThatNeedFilling = partElements.filter((element) =>
    needToBeFilledElements.includes(element.name)
  )
  if (elementsThatNeedFilling.length) {
    return elementsThatNeedFilling.reduce((isFilled, element) => {
      if (!isFilled) {
        return false
      }
      const fillCheckerFn =
        fillCheckerMap[element.name] || fillCheckerMap.default
      return fillCheckerFn(partData)
    }, true)
  }
  return true
}

export const initializePYPPart = (match, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.INITIALIZING_PART
      })
      const { pyp } = getState()
      const { courses } = pyp
      const currentModule = pyp.modules.byId[pyp.navigation.currentModuleId]

      const partsRes = await dispatch(
        fetch(
          `/courses/${courses.byId[courses.allIds[0]].id}/modules/${
            currentModule.id
          }/parts`,
          {
            type: "GET"
          }
        )
      )

      // get from the last visited part associated with the currentModule
      const partId =
        pyp.navigation.moduleNavigationMetaData[currentModule.id]
          .lastVisitedPart

      let part = partsRes.data.find((part) => {
        return part.id === partId
      })

      // user hit back button, exited module, etc.
      if (!part) {
        part = partsRes.data[0]
      }

      // Generate PYP element string for mapping to api call
      const elementString = internals.getElementString(part.elements, pyp)

      const apiCall = apiMap[elementString] || apiMap.default
      const data = await apiCall(dispatch, part.id)

      part.partData = data

      const normalizedData = normalize(
        {
          parts: partsRes.data,
          partData: [data],
          elements: partsRes.data[0].elements
        },
        {
          parts: [partSchema],
          partData: [partDataSchema],
          elements: [elementSchema]
        }
      )

      if (
        !pyp.unlockedUserModules.find((unlockedModule) => {
          return unlockedModule.module_id === pyp.navigation.currentModuleId
        })
      ) {
        history.push(`/workshop/${match.params.module}`)
      }

      dispatch({
        type: types.INITIALIZED_PART,
        data: {
          parts: {
            allIds: normalizedData.result.parts,
            byId: normalizedData.entities.parts
          },
          partData: {
            allIds: normalizedData.result.partData,
            byId: normalizedData.entities.partData
          },
          elements: { byId: normalizedData.entities.elements },
          navigation: {
            currentPartId: part.id
          },
          currentPartComplete: partIsComplete(part.elements, data.data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

const getInputSize = (input) => {
  const inputMap = {
    "User Input Small": "small",
    "User Input Large": "large",
    "User Input Medium": "medium"
  }

  return inputMap[input.name]
}

// Called when a PYP part is mounted. Creates new inputs within the database
export const initializePYPInputs = (partDataId, inputTitles) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.INITIALIZING_PYP_INPUTS
      })
      const { pyp } = getState()
      const currentPart = pyp.parts.byId[pyp.navigation.currentPartId]
      const { elements } = currentPart
      const inputsToMake = elements
        .map((elementId) => pyp.elements.byId[elementId])
        .filter((element) => element.name.includes("User Input"))

      // Iterate through each inputTitle and perform a POST to create a new inputTitle. Save each response within an array

      const userInputInsertions = inputsToMake.map(async (input, i) => {
        return dispatch(
          fetch(`/userInputs`, {
            type: "POST",
            data: {
              requestPayload: {
                partId: currentPart.id,
                titleId: inputTitles[i].id,
                size: getInputSize(input)
              }
            }
          })
        )
      })

      const inputsRes = await Promise.all(userInputInsertions)

      const currentPartData = pyp.partData.byId[currentPart.partData]

      const partData = {
        ...pyp.partData,
        byId: {
          ...pyp.partData.byId,
          [currentPartData.id]: {
            ...pyp.partData.byId[currentPartData.id],
            data: {
              ...pyp.partData.byId[currentPartData.id].data,
              inputs: inputsRes.map((input) => input.data)
            }
          }
        }
      }

      dispatch({
        type: types.CREATE_PYP_INPUTS,
        data: {
          partDataId,
          partData
          // inputs: inputsRes.map(input => input.data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

// Called when a PYP part is mounted. Creates new inputs within the database
export const updatePYPInputsFromButton = (
  partDataId,
  partId,
  selectedButtons,
  inputBoxes
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.SAVING_PYP
      })
      // TODO: For each button that was deselected in the previous part, DELETE the cooresponding input box
      // ~ For every inputBox that EXISTS ALREADY, where its button_value_id does NOT EXIST in selectedButtons, DELETE IT

      for (const input of inputBoxes) {
        if (
          !selectedButtons.find((btn) => {
            return (
              (input.buttonSelectionTitle &&
                input.buttonSelectionTitle.buttonValue &&
                input.buttonSelectionTitle.button_value_id &&
                input.buttonSelectionTitle.buttonValue.id ===
                  btn.buttonSelections[0].pivot.button_value_id) ||
              /*
 Custom Button Values are currently unused. It was a feature that was approved, but never released due to client concerns.
 It's possible it might make it back in, so we didn't remove the code.
*/
              (input.buttonSelectionTitle &&
                input.buttonSelectionTitle.customButtonValue &&
                input.buttonSelectionTitle.custom_button_value_id &&
                input.buttonSelectionTitle.customButtonValue.id ===
                  btn.buttonSelections[0].pivot.custom_button_value_id)
            )
          })
        ) {
          // Delete that input
          await dispatch(
            fetch(`/userInputs/${input.id}`, {
              type: "DELETE"
            })
          )

          // TODO: Update the store to reflect these deletions (which should update the UI)
        }
      }

      // Creates a new input box for each selectd button that does not already have a cooresponding input box
      // ~ For every selectedButton where its id does NOT ALREADY EXIST as a button_value_id in inputBoxes, POST to create a new inputBox
      const inputs = []
      //"/buttonValues/SalesforceValues"


      let sortedSelectedButtons = [];
      if(partId == 22){
        const valuesRes = await dispatch(
          fetch(`/salesforceValues`, {
            type: "GET"
          })
        )
        const values = valuesRes?.data

        for (const [index, btn] of selectedButtons.entries()) {
          if(btn.name != values.Value_1__c && btn.name != values.Value_2__c && btn.name != values.Value_3__c){
            sortedSelectedButtons.push(btn)
          }
        }

        for (const [index, btn] of selectedButtons.entries()) {
          if(btn.name == values.Value_1__c){
            sortedSelectedButtons.splice( 0, 0, btn );
          }
          else if(btn.name == values.Value_2__c){
            sortedSelectedButtons.splice( 1, 0, btn );
          }
          else if(btn.name == values.Value_3__c){
            sortedSelectedButtons.splice( 2, 0, btn );
          }

        }

        /*const filteredSelectedButtons = sortedSelectedButtons.filter((btn, index)=>{
          return !!btn
        })*/
      }
      else if (partId == 28){
        const cypRes = await dispatch(
          fetch(`/salesforceCheckYourPack`, {
            type: "GET"
          })
        )
        const cyp = cypRes?.data

        for (const [index, btn] of selectedButtons.entries()) {
          if(btn.name != cyp.Skill_1__c && btn.name != cyp.Skill_2__c && btn.name != cyp.Skill_3__c){
            sortedSelectedButtons.push(btn)
          }
        }

        for (const [index, btn] of selectedButtons.entries()) {
          if(btn.name == cyp.Skill_1__c){
            sortedSelectedButtons.splice( 0, 0, btn );
          }
          else if(btn.name == cyp.Skill_2__c){
            sortedSelectedButtons.splice( 1, 0, btn );
          }
          else if(btn.name == cyp.Skill_3__c){
            sortedSelectedButtons.splice( 2, 0, btn );
          }

        }

        /*const filteredSelectedButtons = sortedSelectedButtons.filter((btn, index)=>{
          return !!btn
        })*/
      }
      else{
        sortedSelectedButtons  = selectedButtons.sort((a, b) =>
          a.name > b.name ? 1 : -1
        )
      }

      for (const [index, btn] of sortedSelectedButtons.entries()) {
        if (
          !inputBoxes.find((input) => {
            return (
              (input.buttonSelectionTitle &&
                input.buttonSelectionTitle.buttonValue &&
                input.buttonSelectionTitle.button_value_id &&
                input.buttonSelectionTitle.buttonValue.id ===
                  btn.buttonSelections[0].pivot.button_value_id) ||
              /*
 Custom Button Values are currently unused. It was a feature that was approved, but never released due to client concerns.
 It's possible it might make it back in, so we didn't remove the code.
*/
              (input.buttonSelectionTitle &&
                input.buttonSelectionTitle.customButtonValue &&
                input.buttonSelectionTitle.custom_button_value_id &&
                input.buttonSelectionTitle.customButtonValue.id ===
                  btn.buttonSelections[0].pivot.custom_button_value_id)
            )
          })
        ) {
          const res = await dispatch(
            fetch(`/userInputs`, {
              type: "POST",
              data: {
                requestPayload: {
                  partId,
                  buttonId: btn.buttonSelections[0].pivot.button_value_id,
                  customButtonId:
                    btn.buttonSelections[0].pivot.custom_button_value_id,
                  inputPosition: index + 1
                }
              }
            })
          )
          inputs.push(res.data)
        }
      }

      const updatedInputs = await dispatch(
        fetch(`/userInputs`, {
          type: "GET",
          data: {
            params: {
              partId
            }
          }
        })
      )

      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const currentPart = pyp.parts.byId[currentPartId]
      const currentPartData = pyp.partData.byId[currentPart.partData]
      const currentElements = currentPart.elements.map((elementId) => {
        return pyp.elements.byId[elementId]
      })

      const partData = {
        ...pyp.partData,
        byId: {
          ...pyp.partData.byId,
          [currentPartData.id]: {
            ...pyp.partData.byId[currentPartData.id],
            data: {
              ...pyp.partData.byId[currentPartData.id].data,
              inputs: updatedInputs.data
            }
          }
        }
      }

      // This PUSHES the newly created inputs into the inputs field of the reducer
      dispatch({
        type: types.CREATE_PYP_INPUTS,
        data: {
          partData,
          currentPartComplete: partIsComplete(
            currentElements,
            partData.byId[currentPartData.id].data
          )
        }
      })

      dispatch({
        type: types.SAVED_PYP
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const createButton = (name, partId, userId) => {
  return async (dispatch, getState) => {
    try {
      /*
 Custom Button Values are currently unused. It was a feature that was approved, but never released due to client concerns.
 It's possible it might make it back in, so we didn't remove the code.
*/
      // API call to create a new button
      const res = await dispatch(
        fetch(`/customButtonValues`, {
          type: "POST",
          data: { requestPayload: { name, partId } }
        })
      )

      // Add buttonSelections and isCustom properties to the button,
      // since these properties are not returned after the POST and would require re-fetching all buttons
      const newCustomButton = res.data
      newCustomButton.buttonSelections = []
      newCustomButton.isCustom = true

      dispatch({
        type: types.INSERT_CUSTOM_BUTTON,
        data: {
          newButton: newCustomButton
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
}

export const setPYPInputText = (partDataId, inputId, value) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const currentPart = pyp.parts.byId[currentPartId]
      const currentPartData = pyp.partData.byId[currentPart.partData]
      const currentElements = currentPart.elements.map((elementId) => {
        return pyp.elements.byId[elementId]
      })

      const newInputs = pyp.partData.byId[currentPartData.id].data.inputs.map(
        (input) => {
          if (input.id === inputId) {
            input.text = value
          }
          return input
        }
      )

      const partData = {
        ...pyp.partData,
        byId: {
          ...pyp.partData.byId,
          [currentPartData]: {
            ...pyp.partData.byId[currentPartData.id],
            data: {
              ...pyp.partData.byId[currentPartData.id].data,
              inputs: newInputs
            }
          }
        }
      }

      dispatch({
        type: types.SET_PYP_INPUT_DATA,
        data: {
          partData,
          currentPartComplete: partIsComplete(
            currentElements,
            partData.byId[currentPartData.id].data
          )
        }
      })
    } catch (response) {
      console.error(response)
      dispatch({
        type: "ERROR",
        data: {
          message: response.data
        }
      })
    }
  }
}

// const saveButtonSelection = async (apiToken, buttonValueId) => {
//   const res = await axios.post(
//     `/userButtonValues/`,
//     {
//       buttonValueId
//     },
//     { headers: { Authorization: apiToken } }
//   );

//   return res;
// };

const saveApiMap = {
  "Button Selection,Meta Data": async (dispatch, partData) => {
    try {
      const valuesToUpdate =
        partData.valuesToUpdate && partData.valuesToUpdate.value

      const insertions =
        valuesToUpdate &&
        valuesToUpdate
          .filter((value) => value.isSelected)
          .map((selection) => {
            return { id: selection.id, isCustom: selection.isCustom }
          })

      const deletions =
        valuesToUpdate &&
        valuesToUpdate
          .filter((value) => !value.isSelected)
          .map((selection) => {
            return { id: selection.id, isCustom: selection.isCustom }
          })

      insertions &&
        insertions.length &&
        (await dispatch(
          fetch(`/userButtonValues`, {
            type: "POST",
            data: {
              requestPayload: {
                buttonValues: insertions
              }
            }
          })
        ))

      deletions &&
        deletions.length &&
        (await dispatch(
          fetch(`/userButtonValues`, {
            type: "DELETE",
            data: {
              requestPayload: {
                buttonValues: deletions
              }
            }
          })
        ))
    } catch (e) {
      console.log(e)
    }
  },
  "Button Selection Stage 2,Meta Data": async (dispatch, partData) => {
    // TODO: we could generalize this to 'upgrade' button selection
    // where we simply upgrade the current stage rather than hard code
    // 1 -> 2
    const valuesToUpdate =
      partData.valuesToUpdate && partData.valuesToUpdate.value

    const updates =
      valuesToUpdate &&
      valuesToUpdate.map((selection) => {
        return {
          id: selection.id,
          isCustom: selection.isCustom,
          stage: selection.isSelected ? 2 : 1
        }
      })

    updates &&
      updates.length &&
      (await dispatch(
        fetch(`/userButtonValues`, {
          type: "PUT",
          data: {
            requestPayload: {
              userButtonValues: updates
            }
          }
        })
      ))
  },
  default: () => {}
}

// TODO: Currently this 'action creator' triggers 0 (that's right folks, ZERO) actions. Not sure how shameful such a thing is.
// it's the most shameful thing one can do in redux. I just puked thinking about it.

export const savePYP = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState()
      // Exit savePYP function if data is still being fetched. There's nothing to save yet!
      if (state.pyp.fetchingPartData) {
        return
      }

      dispatch({
        type: types.SAVING_PYP
      })

      const currentElements =
        state.pyp.parts.byId[state.pyp.navigation.currentPartId].elements
      const elementString = internals.getElementString(
        currentElements,
        state.pyp
      )
      const currentPart =
        state.pyp.parts.byId[state.pyp.navigation.currentPartId]
      if (!currentPart.partData) {
        return
      }
      const partDataId = currentPart.partData
      const partData = state.pyp.partData.byId[partDataId].data
      // If this part has text input boxes
      if (partData.inputs && partData.inputs.length) {

        const getValuePosition = async (input, values, index) => {
            for (const [key, value] of Object.entries(values)) {
              if (value == input?.buttonSelectionTitle?.buttonValue?.name){
                if(key == 'Value_1__c'){
                  return 1
                }
                else if (key == 'Value_2__c'){
                  return 2
                }
                else if (key == 'Value_3__c'){
                  return 3
                }
              }
            }
            return index + 1

        }
        const getCypPosition = async (input, cyp, index) => {
          for (const [key, value] of Object.entries(cyp)) {
            if (value == input?.buttonSelectionTitle?.buttonValue?.name){
              if(key == 'Skill_1__c'){
                return 1
              }
              else if (key == 'Skill_2__c'){
                return 2
              }
              else if (key == 'Skill_3__c'){
                return 3
              }
            }
          }
          return index + 1

        }
        const getInputPosition = async (index) => {
          const currentModule =
            state.pyp.modules.byId[state.pyp.navigation.currentModuleId]

          /* Only courage vs confidence has a different behavior */
          if (currentModule?.title === "Courage vs. Confidence") {
            return {
              "Courage vs. Confidence 2.0 Inputs 1": 1,
              "Courage vs. Confidence 2.0 Inputs 2": 2,
              "Courage vs. Confidence 2.0 Inputs 3": 3
            }[currentPart.name]
          }

          return index + 1
        }
        const { inputs } = partData
        /** We were using a Promise.all to fetch 3 requests at the same time, 
         * and was probably causing conflicts in the data stored on salesforce,
         * so we decided to make 1 request at a time */
        let values;
        let cyp;
        if (state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title === "Values"){
          const valuesRes = await dispatch(
            fetch(`/salesforceValues`, {
              type: "GET"
            })
          )
          values = valuesRes?.data
        }
        else if (state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title === "Check Your Pack"){
          const cypRes = await dispatch(
            fetch(`/salesforceCheckYourPack`, {
              type: "GET"
            })
          )
          cyp = cypRes?.data
        }
        
        console.log('state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title', state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title)
        for (let i = 0; i < inputs.length; i++) {
          const input = partData.inputs[i]
          try {
            await dispatch(
              fetch(`/userInputs/${input.id}`, {
                type: "PUT",
                data: {
                  requestPayload: {
                    text: input.text,
                    inputPosition: state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title === "Values" ? await getValuePosition(input, values, i) : 
                      state.pyp.modules.byId[state.pyp.navigation.currentModuleId].title === "Check Your Pack" ? await getCypPosition(input, cyp, i) : 
                        await getInputPosition(i),
                    isInputGroup: inputs.length > 1
                  }
                }
              })
            )
          } catch (error) {
            console.log({ error })
          }
        }
      } else if (partData.userTimeEvents && partData.userTimeEvents.length) {
        await dispatch(
          fetch(`/timeEvents/updateAirtable`, {
            type: "POST",
            data: { requestPayload: { partId: partData.eventOrder.part_id } }
          })
        )
      } else if (partData.userDateEvents && partData.userDateEvents.length) {
        await dispatch(
          fetch(`/dateEvents/updateAirtable`, {
            type: "POST",
            data: { requestPayload: { partId: partData.eventOrder.part_id } }
          })
        )
      }
      // If this part has button values
      else if (partData.buttonValues) {
        const apiCall = saveApiMap[elementString] || saveApiMap.default
        await apiCall(dispatch, state.pyp.partData.byId[partDataId].data)
      }
      // If this part is a Reference Lead Selection Small/Medium/Large
      else if (
        partData.referenceInputs &&
        elementString.includes("Reference Lead Selection")
      ) {
        // An array of user_button_values to update
        const updates = partData.referenceInputs.map((input) => {
          return {
            id:
              input.buttonSelectionTitle.button_value_id ||
              input.buttonSelectionTitle.custom_button_value_id,
            isCustom: !input.buttonSelectionTitle.button_value_id,
            is_lead: input.buttonSelectionTitle.is_lead
          }
        })

        // Make the API call
        updates &&
          updates.length &&
          (await dispatch(
            fetch(`/userButtonValues`, {
              type: "PUT",
              data: {
                requestPayload: {
                  userButtonValues: updates
                }
              }
            })
          ))
      }

      dispatch({
        type: types.SAVED_PYP
      })
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        data
      })
    }
  }
}

export const checkForPartCompletion = () => {
  return async (dispatch, getState) => {
    try {
      const state = getState()

      const currentPart =
        state.pyp.parts.byId[state.pyp.navigation.currentPartId]
      const partDataId = currentPart.partData
      const partData = state.pyp.partData.byId[partDataId].data

      if (partData.inputs && partData.inputs.length) {
        // If this part has text input boxes
        // Iterate through each input and check if a textbox has been left blank
        let incomplete = false
        for (const input of partData.inputs) {
          if (input.text.length <= 0) {
            incomplete = true
            break
          }
        }

        dispatch({
          type: types.UPDATE_PART_INCOMPLETION_ERROR,
          data: { showIncompletionError: incomplete }
        })
      }
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const fetchPartInputs = () => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation

      const currentElements = pyp.parts.byId[currentPartId].elements
      const elementString = internals.getElementString(currentElements, pyp)
      const apiCall = apiMap[elementString] || apiMap.default
      const data = await apiCall(dispatch, currentPartId)

      const part = pyp.parts.byId[currentPartId]
      part.partData = data

      const elements = pyp.parts.byId[currentPartId].elements.map(
        (elementId) => pyp.elements.byId[elementId]
      )

      const n = normalize(
        {
          parts: [part],
          partData: [data]
        },
        {
          parts: [partSchema],
          partData: [partDataSchema]
        }
      )

      dispatch({
        type: types.FETCHED_PART_INPUTS,
        data: {
          updatedPart: n.entities.parts,
          partData: { allIds: n.result.partData, byId: n.entities.partData },
          currentPartComplete: partIsComplete(elements, data.data)
        }
      })
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const showPartCompletionError = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.UPDATE_PART_INCOMPLETION_ERROR,
        data: { showIncompletionError: true }
      })
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const partIsLoading = (partIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.PART_IS_LOADING,
        data: { partIsLoading }
      })
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const removePartCompletionErrors = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.UPDATE_PART_INCOMPLETION_ERROR,
        data: { showIncompletionError: false }
      })
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const initializePYP = (moduleTitle) => {
  return async (dispatch, getState) => {
    try {
      const {
        pyp
        // user
      } = getState()

      //   const userPypProgram = user.user.programs.find(program => {
      //     return (
      //       program.name === "Pursue Your Purpose" &&
      //       program.pivot.status === "approved"
      //     );
      //   });

      //   const userPypProgram = user.user.programs.find(program => {
      //     return (
      //       program.name === "Pursue Your Purpose" &&
      //       program.pivot.status === "approved"
      //     );
      //   });

      //   const userCourses = await axios.get(`/user/${user.user.id}/course`,{
      //     headers: { Authorization: api.apiToken },
      // });

      // if (!userPypProgram) {
      //   dispatch({
      //     type: types.ERROR,
      //     data: {
      //       status: 500,
      //       message: "Not authorized for PYP"
      //     }
      //   });
      // }

      // need to test this

      dispatch({
        type: types.INITIALIZING_PYP
      })

      const courseRes = await dispatch(
        fetch(`/courses`, {
          type: "GET",
          data: {
            params: {
              name: "Pursue Your Purpose"
            }
          }
        })
      )

      const moduleRes = await dispatch(
        fetch(`/courses/${courseRes.data.id}/modules`, {
          type: "GET"
        })
      )

      // for normalization below
      const courseData = courseRes.data
      courseData.modules = moduleRes.data

      const matchedModuleFromTitle = moduleRes.data.find((module) => {
        return module.title === moduleTitle
      })

      let modulesNavigationMetaData

      if (!pyp.navigation.moduleNavigationMetaData) {
        modulesNavigationMetaData = moduleRes.data.map((module) => {
          return { id: module.id, lastVisitedPart: -1 }
        })
      } else {
        modulesNavigationMetaData = pyp.navigation.moduleNavigationMetaData
      }

      const normalizedData = normalize(
        {
          courses: [courseData],
          modules: moduleRes.data,
          navigationParts: modulesNavigationMetaData
        },
        {
          courses: [courseSchema],
          modules: [moduleSchema],
          navigationParts: [navigationPartSchema]
        }
      )

      let unlockedUserModulesRes = await dispatch(
        fetch(`/userModules`, {
          type: "GET"
        })
      )

      if (!unlockedUserModulesRes.data.length) {
        await dispatch(
          fetch(`/userModules`, {
            type: "POST",
            data: {
              requestPayload: {
                moduleId: moduleRes.data[0].id
              }
            }
          })
        )

        unlockedUserModulesRes = await dispatch(
          fetch(`/userModules`, {
            type: "GET"
          })
        )
      }

      // url doesn't match any modules
      if (!matchedModuleFromTitle) {
        dispatch({
          type: types.ERROR
        })
      }

      dispatch({
        type: types.INITIALIZED_PYP,
        data: {
          course: {
            allIds: normalizedData.result.courses,
            byId: normalizedData.entities.courses
          },
          modules: {
            allIds: normalizedData.result.modules,
            byId: normalizedData.entities.modules
          },
          currentModuleId: matchedModuleFromTitle.id,
          unlockedUserModules: unlockedUserModulesRes.data,
          moduleNavigationMetaData: normalizedData.entities.navigationPart
        }
      })
    } catch (error) {
      console.log("EROOR 1", error)
      dispatch({
        type: types.ERROR,
        data: error
      })
      // need to add history to call
      // history.push('/');
    }
  }
}

export const setLeadUserInput = (index) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const currentPart = pyp.parts.byId[currentPartId]
      const currentPartData = pyp.partData.byId[currentPart.partData]
      const currentElements = currentPart.elements.map((elementId) => {
        return pyp.elements.byId[elementId]
      })

      const newReferenceInputs = currentPartData.data.referenceInputs.map(
        (input, i) => {
          return {
            ...input,
            buttonSelectionTitle: {
              ...input.buttonSelectionTitle,
              is_lead: index === i
            }
          }
        }
      )

      const partData = {
        ...pyp.partData,
        byId: {
          ...pyp.partData.byId,
          [currentPartData.id]: {
            ...pyp.partData.byId[currentPartData.id],
            data: {
              ...pyp.partData.byId[currentPartData.id].data,
              referenceInputs: newReferenceInputs
            }
          }
        }
      }

      dispatch({
        type: types.SET_PYP_REFERENCE_INPUTS,
        data: {
          // referenceInputs: newReferenceInputs,
          partData,
          currentPartComplete: partIsComplete(
            currentElements,
            partData.byId[currentPartData.id].data
          )
        }
      })
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const setButtonSelection = (buttonSelections) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const currentPart = pyp.parts.byId[currentPartId]
      const currentPartData = pyp.partData.byId[currentPart.partData]
      const currentElements = currentPart.elements.map((elementId) => {
        return pyp.elements.byId[elementId]
      })

      const partData = {
        ...pyp.partData,
        byId: {
          ...pyp.partData.byId,
          [currentPartData.id]: {
            ...pyp.partData.byId[currentPartData.id],
            data: {
              ...pyp.partData.byId[currentPartData.id].data,
              valuesToUpdate: buttonSelections.value,
              selectedItems: buttonSelections.selectedItems
            }
          }
        }
      }

      dispatch({
        type: types.SET_PYP_BUTTON_SELECTION,
        data: {
          buttonSelections, // buttonSelections[0].value,
          currentPartComplete: partIsComplete(
            currentElements,
            partData.byId[currentPartData.id].data
          )
        }
      })
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const retrieveAllPYPModules = (course_id) => {
  return async (dispatch, getState) => {
    try {
      const token = getState().api.apiToken
      apiRequest.defaults.headers.common.Authorization = token

      const courseRes = await apiRequest.get(`/courses`, {
        params: {
          name: "Pursue Your Purpose"
        }
      })

      const moduleRes = await apiRequest.get(
        `/courses/${courseRes.data.id}/modules`,
        {
          type: "GET"
        }
      )

      const normalizedModules = normalize(
        {
          modules: moduleRes.data
        },
        {
          modules: [moduleSchema]
        }
      )

      const data = {
        allIds: normalizedModules.result.modules,
        byId: normalizedModules.entities.modules
      }

      dispatch({
        type: types.FETCHED_MODULES,
        data
      })
    } catch (response) {
      dispatch({
        type: types.FETCHED_MODULES,
        data: []
      })
    }
  }
}

export const retrieveAllUserModulesWithParts = () => {
  return async (dispatch, getState) => {
    try {
      const courseRes = await dispatch(
        fetch(`/courses`, {
          type: "GET",
          data: {
            params: {
              name: "Pursue Your Purpose"
            }
          }
        })
      )

      const allModulesRes = await dispatch(
        fetch(`/courses/${courseRes.data.id}/modules`, {
          type: "GET"
        })
      )

      const userModulesRes = await dispatch(
        fetch(`/userModules`, {
          type: "GET"
        })
      )

      const filteredModules = allModulesRes.data.filter((item) =>
        userModulesRes.data.find(
          (userModule) => userModule.module_id === item.id
        )
      )

      const retrievePartIds = async (moduleId) => {
        return await dispatch(
          fetch(`/courses/${courseRes.data.id}/modules/${moduleId}/parts`, {
            type: "GET"
          })
        )
      }

      const modulePartIds = filteredModules.map((singleModule) =>
        retrievePartIds(singleModule.id)
      )

      const promisedParts = await Promise.all(modulePartIds)

      const modulesWithParts = filteredModules.map((singleModule, index) => {
        return {
          // ...singleModule,
          id: singleModule.id,
          title: singleModule.title,
          parts_sequence: singleModule.parts_sequence,
          parts: promisedParts[index].data
        }
      })

      const metaParts = modulesWithParts.map(async (singleModule) => {
        const populatedParts = singleModule.parts.map(async (part) => {
          const metaResponse = await fetchMetaDataUserInputData(
            dispatch,
            part.id
          )
          return metaResponse.data
        })

        const promisedPopulatedParts = await Promise.all(populatedParts)

        const formattedPromisedPopulatedParts = promisedPopulatedParts.map(
          (part) => ({
            inputs:
              part.inputs.length &&
              part.inputs.map((input) => ({
                text: input.text,
                title: input.title && input.title.title,
                buttonTitle:
                  input.buttonSelectionTitle &&
                  input.buttonSelectionTitle.buttonValue.name
              })),
            title: part.title.length && part.title[0].title,
            description: part.title.length && part.title[0].description
          })
        )

        return formattedPromisedPopulatedParts
      })

      const promisedMetaParts = await Promise.all(metaParts)

      const modulesWithPartData = modulesWithParts.map(
        (singleModule, index) => {
          return {
            ...singleModule,
            parts: promisedMetaParts[index]
          }
        }
      )

      const modulesWithRenderableParts = modulesWithPartData.map(
        (singleModule) => {
          return {
            ...singleModule,
            parts: singleModule.parts.filter((part) => part.inputs.length)
          }
        }
      )
      // console.log(modulesWithRenderableParts, "EXCLUDING MODULES WITHOUT INPUT")
      return modulesWithRenderableParts
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const retrieveUserModulesWithPopulatedInputs = () => {
  return async (dispatch, getState) => {
    try {
      const populatedRes = await dispatch(
        fetch("/userModuleData", {
          type: "GET"
        })
      )
      return populatedRes.data
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const retrieveUsersTimeEventsByPartId = (partId) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      let currentPartId
      if (partId) {
        currentPartId = partId
      } else {
        currentPartId = pyp.navigation && pyp.navigation.currentPartId
      }
      const timeEvents = await dispatch(
        fetch("/timeEvents", {
          type: "GET",
          data: {
            params: { partId: currentPartId }
          }
        })
      )
      return timeEvents.data
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const storeNewTimeEvent = (eventData) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const { title, duration, value_id } = eventData
      const eventResponse = await dispatch(
        fetch("/timeEvents", {
          type: "POST",
          data: {
            requestPayload: {
              params: {
                partId: currentPartId,
                events: [
                  {
                    title,
                    duration,
                    value_id
                  }
                ]
              }
            }
          }
        })
      )

      return eventResponse
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const storeUpdatedTimeEvent = (eventData) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const eventResponse = await dispatch(
        fetch(`/timeEvents/${currentPartId}`, {
          type: "PUT",
          data: {
            requestPayload: {
              event: { ...eventData }
            }
          }
        })
      )

      return eventResponse
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const deleteDateEvent = (eventData) => {
  return async (dispatch, getState) => {
    try {
      const eventResponse = await dispatch(
        fetch(`/dateEvent/${eventData.id}`, {
          type: "DELETE"
        })
      )

      return eventResponse
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const storeUpdatedDateEvent = (eventData) => {
  return async (dispatch, getState) => {
    try {
      const eventResponse = await dispatch(
        fetch(`/dateEvent/${eventData.id}`, {
          type: "PUT",
          data: {
            requestPayload: {
              event: { ...eventData }
            }
          }
        })
      )

      return eventResponse
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const updateEventOrder = (updatedEventOrder) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      await dispatch(
        fetch(`/eventOrder/${currentPartId}`, {
          type: "PUT",
          data: { requestPayload: { inputs: updatedEventOrder } }
        })
      )
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const deleteTimeEvent = (eventData) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      const eventResponse = await dispatch(
        fetch(`/part/${currentPartId}/event/${eventData.id}`, {
          type: "DELETE"
        })
      )

      return eventResponse
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const createDateEventAndUpdateOrder = (allEventData, editedEvent) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      if (allEventData.length > 1) {
        if (editedEvent) {
          await dispatch(
            fetch(`/dateEvent/${editedEvent.id}`, {
              type: "PUT",
              data: {
                requestPayload: {
                  event: { ...editedEvent }
                }
              }
            })
          )
        }
        const eventResponse = await dispatch(
          fetch(`/parts/${currentPartId}/dateEvent`, {
            type: "POST",
            data: {
              requestPayload: {
                event: allEventData[allEventData.length - 1]
              }
            }
          })
        )
        const updatedEventOrder = allEventData.map((event) => event.id)
        updatedEventOrder[updatedEventOrder.length - 1] = eventResponse.data.id
        await dispatch(
          fetch(`/eventOrder/${currentPartId}`, {
            type: "PUT",
            data: { requestPayload: { inputs: updatedEventOrder } }
          })
        )
        return updateEventOrder
      }
      await dispatch(
        fetch(`/dateEvent`, {
          type: "POST",
          data: {
            requestPayload: {
              partId: currentPartId,
              event: {
                ...allEventData[allEventData.length - 1]
              }
            }
          }
        })
      )
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const createTimeEventAndUpdateOrder = (
  allEventData,
  editedEvent,
  eventOrder
) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation
      if (allEventData.length > 1) {
        if (editedEvent) {
          await dispatch(
            fetch(`/timeEvents/${currentPartId}`, {
              type: "PUT",
              data: {
                requestPayload: {
                  event: { ...editedEvent }
                }
              }
            })
          )
        }
        const eventResponse = await dispatch(
          fetch(`/parts/${currentPartId}/timeEvent`, {
            type: "POST",
            data: {
              requestPayload: {
                event: allEventData[allEventData.length - 1]
              }
            }
          })
        )
        // eventOrder[eventOrder.length - 1] = eventResponse.data.id
        allEventData.sort((a, b) => {
          if (a.id === 0) {
            return 1
          }
          if (b.id === 0) {
            return -1
          }
          return eventOrder.indexOf(a.id) - eventOrder.indexOf(b.id) > 0
            ? 1
            : -1
        })
        const updatedEventOrder = allEventData.map((event) => event.id)
        updatedEventOrder[updatedEventOrder.length - 1] = eventResponse.data.id
        await dispatch(
          fetch(`/eventOrder/${currentPartId}`, {
            type: "PUT",
            data: { requestPayload: { inputs: updatedEventOrder } }
          })
        )
        return updateEventOrder
      }
      await dispatch(
        fetch(`/timeEvents`, {
          type: "POST",
          data: {
            requestPayload: {
              partId: currentPartId,
              events: [
                {
                  ...allEventData[allEventData.length - 1],
                  value_id: allEventData[allEventData.length - 1].value_id
                }
              ]
            }
          }
        })
      )
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

// requires both time constraints to be sent through
export const storeTimeConstraints = ({ constraint1, constraint2 }) => {
  return async (dispatch, getState) => {
    try {
      const { pyp } = getState()
      const { currentPartId } = pyp.navigation

      dispatch({
        type: types.SAVING_PYP
      })

      await dispatch(
        fetch(`/inputSingleLine`, {
          type: "POST",
          data: {
            requestPayload: {
              partId: currentPartId,
              inputs: [constraint1, constraint2]
            }
          }
        })
      )

      dispatch({
        type: types.SAVED_PYP
      })
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
    // localhost:3333/v1/inputSingleLine
  }
}

export const updateSingleLineInputById = ({ newValue, id }) => {
  return async (dispatch, getState) => {
    try {
      await dispatch(
        fetch(`/inputSingleLine/byId/${id}`, {
          type: "PUT",
          data: {
            requestPayload: {
              text: newValue
            }
          }
        })
      )
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response
        }
      })
    }
  }
}

export const finishPyp = (moduleId) => async (dispatch) => {
  await dispatch(
    fetch(`/finishPyp`, {
      type: "POST",
      data: {
        requestPayload: {
          moduleId
        }
      }
    })
  )
}
