import React from "react"

export const RedX = (props) => {
  return (
    <div style={{ ...props.style }}>
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Workshop"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="IW12"
            transform="translate(-333.000000, -278.000000)"
            fill="#E1373A"
            fillRule="nonzero"
          >
            <path
              d="M343.072562,286.116448 L348.282486,291.326372 L346.049661,293.559196 L340.839737,288.349272 L335.51531,293.6737 L333.282486,291.440875 L338.606913,286.116448 L333.396989,280.906524 L335.629814,278.6737 L340.839737,283.883624 L345.935157,278.788204 L348.167982,281.021028 L343.072562,286.116448 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
