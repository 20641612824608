import React, { useState } from "react"

export default function TabHandler({
  clickHandler,
  tabs,
  style,
  highlightStyle,
  selectedId,
  hoverStyle
}) {
  const [hoveredId, toggleHoverId] = useState(-1)
  return tabs.map((id) => (
    <div
      onClick={() => clickHandler(id)}
      key={id}
      style={
        id === selectedId
          ? { ...style, ...highlightStyle }
          : hoveredId === id
          ? { ...style, ...hoverStyle }
          : { ...style }
      }
      onMouseEnter={() => toggleHoverId(id)}
      onMouseLeave={() => toggleHoverId(-1)}
    >
      {id}
    </div>
  ))
}
