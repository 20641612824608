import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MediaQuery from "react-responsive"
import Actions from "../../Redux/Actions"
import ImageCropper from "../ImageCropper/ImageCropper"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    modal: state.modal && state.modal.modal.cropProfile,
    image: state.modal.image_for_cropping,
    isUpdatingThumbnail: state.user.isUpdatingProfileThumbnail
  }
}

const styles = (theme) => ({
  root: {
    margin: 0
  },
  closeButton: {
    position: "absolute"
  }
})

const DialogTitle = withStyles(styles)((props) => {
  return <>{/* Dialog Title Can Go Here */}</>
})

const DialogContent = MuiDialogContent

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class ModalCropOverlay extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        open: false,
        loaded: false
      }
      this.handleUpload = this.handleUpload.bind(this)
    }

    handleUpload = async (imageData) => {
      await this.props.uploadUserThumbnail(imageData)
    }

    handleClose = () => {
      this.props.toggleModalCrop(false)
      this.setState({ open: false, loaded: false })
    }

    componentDidUpdate() {
      if (this.state.loaded === false && this.props.modal) {
        this.setState({ open: this.props.modal, loaded: true })
      }
    }

    componentDidMount() {
      if (this.state.loaded === false && this.props.modal) {
        this.setState({ open: this.props.modal, loaded: true })
      }
    }

    render() {
      return (
        <div>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.open}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}
            />
            <DialogContent dividers>
              <MediaQuery minWidth={445}>
                <ImageCropper
                  image={this.props.image}
                  handleUpload={this.handleUpload}
                  handleClose={this.handleClose}
                  height={250}
                  width={254}
                  isUpdatingThumbnail={this.props.isUpdatingThumbnail}
                />
              </MediaQuery>
              <MediaQuery maxWidth={444}>
                <ImageCropper
                  image={this.props.image}
                  handleUpload={this.handleUpload}
                  handleClose={this.handleClose}
                  height={125}
                  width={127}
                  isUpdatingThumbnail={this.props.isUpdatingThumbnail}
                />
              </MediaQuery>
            </DialogContent>
          </Dialog>
        </div>
      )
    }
  }
)
