import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { FullCommitLogo } from "../../Icons/FullCommitLogo"
import colors from "../../Constants/colors"
import { Close } from "../../Icons/Close"
import { Hamburger } from "../../Icons/Hamburger"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

const styleSheet = {
  root: {
    width: "100%",
    backgroundColor: colors.midnight,
    marginTop: "60px"
  },
  test: {
    color: colors.white,
    fontFamily: "Lato-Semibold",
    fontSize: "20px",
    lineHeight: "33px",
    letterSpacing: "0.8px"
  }
}
const styles = {
  componentContainer: {
    backgroundColor: colors.midnight,
    height: "60px",
    width: "90%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  logoContainer: {
    width: "35%",
    display: "flex",
    justifyContent: "center"
  },
  rightContent: {
    width: "65%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "10%"
  }
}
class WebMobileNav extends Component {
  constructor(props) {
    super(props)
    this.clickFunc = props.clickHandler ? props.clickHandler : () => {}
    this.state = {
      searching: false
    }
    this.toggleSearch = this.toggleSearch.bind(this)
  }

  toggleSearch() {
    this.setState({
      searching: !this.state.searching
    })
  }

  clickHandler(direction) {
    return () => {
      this.clickFunc(direction)
      this.props.toggleDrawer()
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    return (
      <div style={styles.componentContainer}>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <a href="https://www.commitfoundation.org">
            <FullCommitLogo />
          </a>
          <div style={{ width: "30px" }} onClick={this.toggleSearch}>
            <div
              style={{ width: "30px" }}
              onClick={() => {
                this.props.toggleDrawer()
                // this.props.closeSecondary();
                this.setState({
                  navOpen: !this.state.navOpen
                })
              }}
            >
              {this.props.drawerOpen ? (
                <Close />
              ) : (
                <Hamburger style={{ height: "14px" }} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WithDrawerConsumer(withStyles(styleSheet)(WebMobileNav))
