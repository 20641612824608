import React, { useMemo } from "react"
import PropTypes from "prop-types"

import FormularyStepInputs from "./FormularyStepInputs"
import UserFieldEditor from "../UserFieldEditor/UserFieldEditor"

const FormularyInputs = ({ steps }) => {
  const inputsByStepId = useMemo(
    () =>
      steps.reduce(
        (inputsAcum, step) => ({
          ...inputsAcum,
          [step.stepId]: () => <FormularyStepInputs stepId={step.stepId} />
        }),
        {}
      ),
    []
  )

  return <UserFieldEditor inputs={inputsByStepId} />
}

FormularyInputs.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({ stepId: PropTypes.string.isRequired })
  ).isRequired
}

export default FormularyInputs
