import React from "react"

export const DefaultUserThumbnail = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 144 142"
    >
      <defs>
        <rect id="a" width="126.341" height="124.634" rx="62.317" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 1)">
          <use
            fill={props.darkerGray ? "#676e7a" : "#8792A5"}
            fillRule="nonzero"
            transform="translate(6.83 7.683)"
            href="#a"
            xlinkHref="#a"
          />
          <rect
            width="140"
            height="138.293"
            y=".854"
            stroke="#E1373A"
            strokeWidth="3.415"
            rx="69.146"
          />
        </g>
        <g fill="#FFF">
          <text
            fontFamily="Lato-Semibold, Lato"
            fontSize="17.073"
            fontWeight="500"
            transform="translate(10.537 39.415)"
          >
            <tspan x="21.849" y="49.61">
              {props.withoutText ? "" : "Add Image"}
            </tspan>
          </text>
          <path
            d={props.withoutText ? "" : "M70.832 39.415h4.044v25.61h-4.044z"}
          />{" "}
          {/* M70.832 47.415h8.044v45.61h-8.044z */}
          <path
            d={props.withoutText ? "" : "M85.659 50.198v4.044h-25.61v-4.044z"}
          />
        </g>
      </g>
    </svg>
  )
}
