import queryString from "query-string"
import types from "../types"
import * as utils from "../../Utils/actionUtils"
import fetch from "../../../Utils/fetch"

export const toggleSpouseUnfilled = () => {
  return (dispatch) => {
    try {
      dispatch({
        type: types.TOGGLE_SPOUSE_UNFILLED
      })
    } catch (e) {
      console.log(e)
    }
  }
}

export const changeWillRelocate = (value) => (dispatch) => {
  dispatch({
    type: types.WILL_RELOCATE_SPOUSE_CHANGED,
    data: {
      value
    }
  })
}

export const fetchSpouseInterests = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.TOGGLE_FETCHING_INTERESTS,
        data: { toggle: true }
      })
      const res = await dispatch(
        fetch(`/interests`, {
          type: "GET"
        })
      )
      dispatch({
        type: types.UPDATE_INTERESTS,
        data: { interests: res.data }
      })
      dispatch({
        type: types.TOGGLE_FETCHING_INTERESTS,
        data: { toggle: false }
      })
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const fetchSpouseApplication = (initialLoad, history) => {
  return async (dispatch) => {
    try {
      const { data } = await dispatch(
        fetch(`/spouse-application`, {
          type: "GET"
        })
      )
      if (initialLoad) {
        dispatch({
          type: types.INIT_SPOUSE_APPLICATION,
          data: {
            application: utils.formatIncomingApplication(data),
            selectedApplicationInput: {
              selectedItemId: 0,
              selectedStepId: 1
            }
          }
        })
      } else {
        dispatch({
          type: types.FETCHED_SPOUSE_APPLICATION,
          data: {
            application: utils.formatIncomingApplication(data),
            history
          }
        })
      }
    } catch (data) {
      dispatch({
        type: types.ERROR,
        data: data.response
      })
    }
  }
}

export const saveSpouseApplication = (inputData, history, next) => {
  return async (dispatch, getState) => {
    try {
      const pageTitle = queryString.parse(history.location.search).title
      const data = inputData || getState().application.currentInputData

      if (data) {
        await dispatch(
          fetch(
            `/spouse-application`,
            {
              type: "POST",
              data: {
                requestPayload: utils.processInputData(
                  utils.formatInputData(data, pageTitle)
                )
              }
            },
            {
              timeout: 20000
            }
          )
        )

        const res = await dispatch(
          fetch(`/spouse-application`, {
            type: "GET"
          })
        )

        dispatch({
          type: types.FETCHED_SPOUSE_APPLICATION,
          data: {
            application: utils.formatIncomingApplication(res.data)
          }
        })
        if (next) {
          history.push(next)
        }
      }
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        message: data,
        data: data.response
      })
    }
  }
}
export const setSpouseInputData = (inputData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_SPOUSE_APPLICATION_INPUT_DATA,
        data: { inputData }
      })
    } catch (response) {
      dispatch({
        type: "ERROR",
        data: {
          message: response.data
        }
      })
    }
  }
}

export const finalizeAndSubmitSpouseApplication = (history, inputData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SUBMIT_APPLICATION
      })

      if (inputData) {
        await dispatch(
          fetch(`/spouse-application`, {
            type: "POST",
            data: { requestPayload: utils.processInputData(inputData) }
          })
        )
      }

      await dispatch(
        fetch(`/spouse-submitApplication`, {
          type: "POST",
          data: {
            requestPayload: null
          }
        })
      )

      const res = await dispatch(
        fetch(`/spouse-application`, {
          type: "GET"
        })
      )

      dispatch({
        type: types.FETCHED_USER,
        user: res.data
      })

      history.push("/application/status")

      dispatch({
        type: types.SUBMITTED_APPLICATION
      })
    } catch (data) {
      console.error(data)
      dispatch({
        type: types.ERROR,
        message: data,
        data: data.response
      })
    }
  }
}
