import React from "react"
import queryString from "query-string"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import UserFieldEditorHeader from "../UserFieldEditorHeader/UserFieldEditorHeader"

import { getCurrentStep } from "./utils"

const FormularyHeader = ({ history, steps }) => {
  const {
    title,
    sectionTitle,
    currentStepIndex,
    stepsLength,
    description
  } = useSelector(({ application, formulary }) => {
    const { currentFormularyType } = formulary
    const { title: stepId } = queryString.parse(history.location.search)

    const currentStep = getCurrentStep(steps, stepId)

    const section = application[`${currentFormularyType}Metadata`].find(
      ({ id }) => id === currentStep.sectionId
    )

    return {
      title: currentStep.title,
      sectionTitle: section.title,
      stepsLength: section.steps.length,
      currentStepIndex: currentStep.stepIndex,
      description: currentStep.body
    }
  })

  return (
    <UserFieldEditorHeader
      sectionTitle={sectionTitle}
      title={title}
      currentStepIndex={currentStepIndex}
      stepsLength={stepsLength}
      description={description}
    />
  )
}

FormularyHeader.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({ search: PropTypes.string })
  }).isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withRouter(FormularyHeader)
