import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MediaQuery from "react-responsive"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { withStyles } from "@material-ui/core/styles"
import Actions from "../../Redux/Actions"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import colors from "../../Constants/colors"
import { Filter } from "../../Icons/Filter"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import { DownArrow } from "../../Icons/DownArrow"
import { underscorify, spacify } from "../../Utils/urlParsingUtilities"
import { gtmClickHandler } from "../../Utils/gtm"

const styleSheet = {
  expansionRoot: {
    marginBottom: "0px !important",
    marginTop: "0px !important",
    backgroundColor: colors.white,
    minHeight: "32px"
  }
}

export class VideoFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersOpen: true
    }

    this.mobileFilterPanelChange = this.mobileFilterPanelChange.bind(this)
    this.changeCategory = this.changeCategory.bind(this)
  }

  changeCategory(e) {
    if (
      spacify(this.props.match.params.category) !==
      e.target.dataset.id.toLowerCase()
    ) {
      // TODO: this shoudln't update the categories
      this.props.clearReduxVideos()
      gtmClickHandler({
        name: "FilterClickedEvent",
        data: {
          action: `Filter Clicked`,
          category: "Video Category",
          label: e.target.dataset.id
        }
      })
      this.props.history.push(
        `/digitalLibrary/${this.props.match.params.type}/${underscorify(
          e.target.dataset.id.toLowerCase()
        )}`
      )
    }
  }

  mobileFilterPanelChange(event, expanded) {
    this.setState({
      filtersOpen: expanded
    })
  }

  // TODO: get the inline styling into an object

  render() {
    return (
      <>
        <MediaQuery maxWidth={768}>
          <ExpansionPanel
            defaultExpanded
            className="videoExpansionPanel"
            classes={{
              expanded: this.props.classes.expansionExpanded
            }}
            style={{
              backgroundColor: colors.white,
              border: "none",
              boxShadow: "none",
              margin: "0px",
              cursor: "pointer"
            }}
          >
            <ExpansionPanelSummary
              style={{
                marginTop: "0px",
                marginBottom: "0px",
                width: "fit-content",
                minHeight: "32px"
              }}
              expandIcon={
                <DownArrow stroke={colors.midnight} width="11" height="11" />
              }
              classes={{
                expanded: this.props.classes.expansionRoot
              }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <h2
                  style={{
                    color: colors.mediumBlue,
                    fontSize: "14px",
                    fontFamily: "Lato-SemiBold",
                    marginRight: "4px",
                    marginBottom: "0px",
                    display: "flex"
                  }}
                >
                  Filtered by :{" "}
                  <p
                    style={{
                      fontFamily: "Lato-light",
                      textTransform: "capitalize",
                      marginLeft: "5px"
                    }}
                  >
                    {spacify(this.props.match.params.category)}{" "}
                  </p>
                </h2>
              </span>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  flexDirection: "row",
                  paddingTop: "13px",
                  paddingBottom: "13px"
                }}
              >
                {this.props.categories.map((category, i) => {
                  // category widths for skeleton loaders
                  const categoryWidths = [
                    90,
                    90,
                    200,
                    90,
                    90,
                    90,
                    90,
                    200,
                    90,
                    90,
                    200
                  ]
                  return category.name ? (
                    <CommitButtonWithoutClickState
                      clickedStyle={{
                        backgroundColor: colors.midnight,
                        color: colors.white
                      }}
                      key={i}
                      dataId={category.name}
                      id={category.name}
                      clickHandler={this.changeCategory}
                      isClicked={
                        category.name.toLowerCase() ===
                        spacify(this.props.match.params.category)
                      }
                      style={{
                        width: "auto",
                        margin: "8px",
                        height: "44px"
                      }}
                      type="whiteMediumBlue"
                    >
                      {category.name}
                    </CommitButtonWithoutClickState>
                  ) : (
                    <SkeletonWrapper
                      key={i}
                      containerStyle={{
                        width: `${categoryWidths[i] || 90}px`,
                        margin: "5px 10px",
                        height: "40px"
                      }}
                      theme="light"
                      height={40}
                      width={categoryWidths[i] || 90}
                    >
                      <rect
                        x="0"
                        y="0"
                        rx="4"
                        ry="4"
                        width={`${categoryWidths[i] || 90}`}
                        height={40}
                      />
                    </SkeletonWrapper>
                  )
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            <h2
              style={{
                color: colors.mediumBlue,
                fontSize: "14px",
                fontFamily: "Lato-SemiBold",
                marginRight: "4px",
                marginBottom: "0px"
              }}
            >
              Filter
            </h2>
            <Filter />
          </span>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              flexDirection: "row",
              paddingTop: "13px",
              paddingBottom: "13px",
              borderBottom: "solid 1px #e1e0e0",
              marginLeft: "20px",
              marginRight: "20px"
            }}
          >
            {this.props.categories.map((category, i) => {
              const categoryWidths = [
                90,
                90,
                200,
                90,
                90,
                90,
                90,
                200,
                90,
                90,
                200
              ]
              return category.name ? (
                <CommitButtonWithoutClickState
                  clickedStyle={{
                    backgroundColor: colors.midnight,
                    color: colors.white
                  }}
                  label={`filter videos by ${category.name}`}
                  key={i}
                  id={category.name}
                  dataId={category.name}
                  clickHandler={this.changeCategory}
                  isClicked={
                    category.name.toLowerCase() ===
                    spacify(this.props.match.params.category)
                  }
                  style={{ width: "auto", margin: "8px", height: "44px" }}
                  type="whiteMediumBlue"
                >
                  {category.name}
                </CommitButtonWithoutClickState>
              ) : (
                <SkeletonWrapper
                  key={i}
                  containerStyle={{
                    width: `${categoryWidths[i]}px`,
                    marginLeft: "20px",
                    marginTop: "10px"
                  }}
                  theme="light"
                  height={50}
                  width={`${categoryWidths[i]}`}
                >
                  <rect
                    x="0"
                    y="0"
                    rx="4"
                    ry="4"
                    width={`${categoryWidths[i]}`}
                    height={50}
                  />
                </SkeletonWrapper>
              )
            })}
          </div>
        </MediaQuery>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const { categories } = state.digitalLibrary

  return {
    ...props,
    categories: categories.allIds.length
      ? state.digitalLibrary.categories.allIds.map(
          (categoryId) => state.digitalLibrary.categories.byId[categoryId]
        )
      : // skeleton loaders
        new Array(20).fill({})
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styleSheet)(VideoFilters))
)
