import { DragDropContext } from "react-beautiful-dnd"
import React, { Component } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import RoadmapperDroppableContainer from "../RoadmapperDroppableContainer/RoadmapperDroppableContainer"
import "./RoadmapperDraggableContainer.css"
import RoadmapperConstraintEvent from "../RoadmapperConstraintEvent/RoadmapperConstraintEvent"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import RoadmapperCalenderContainer from "../RoadmapperCalenderContainer/RoadmapperCalenderContainer"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

/* props
momentFormat: ["h:mm",""]
momentJoiner: " - "
timeConstraints: {
  start: moment(""),
  end: moment("")
} 
durationUnit: "minutes||whatever"
*/

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class RoadmapperDraggableContainer extends Component {
    constructor(props) {
      super(props)

      this.state = {
        awaiting: false,
        eventIds: null,
        editing: false,
        isBeingDragged: false,
        isDragDisabled: false,
        eventIdBeingEdited: null,
        skeletonLoader: false,
        skeletonWidth: 0,
        skeletonWidthRatio: 0,
        editedEvent: {
          title: "",
          value: null,
          duration: 15
        },
        loading: true,
        timeEvents: null
      }

      this.renderMoment = this.renderMoment.bind(this)
      this.onDragEnd = this.onDragEnd.bind(this)
      this.onDragStart = this.onDragStart.bind(this)
      this.updateState = this.updateState.bind(this)
      this.storeUpdatedTimeEvent = this.storeUpdatedTimeEvent.bind(this)
      this.updateEditEvent = this.updateEditEvent.bind(this)
      this.deleteTimeEvent = this.deleteTimeEvent.bind(this)
      this.addTimeEvent = this.addTimeEvent.bind(this)
      this.updateConstraint = this.updateConstraint.bind(this)
      this.addDateEvent = this.addDateEvent.bind(this)
      this.updateEditCalenderEvent = this.updateEditCalenderEvent.bind(this)
      this.deleteCalenderEvent = this.deleteCalenderEvent.bind(this)
      this.dateSortingFunction = this.dateSortingFunction.bind(this)
    }

    resizeSkeletonWidth = () => {
      const container = document.getElementById("skeletonContainer")
      this.setState({
        skeletonWidth: container.clientWidth,
        skeletonWidthRatio: container.clientWidth / 300
      })
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.resizeSkeletonWidth)
    }

    componentDidMount(prevState) {
      this.resizeSkeletonWidth()
      window.addEventListener("resize", this.resizeSkeletonWidth)
      if (!this.props.loading) {
        this.setState({
          eventIds:
            this.props.eventOrder &&
            this.props.eventOrder.map((eventId) => eventId),
          loading: false,
          timeEvents:
            this.props.userTimeEvents &&
            this.props.userTimeEvents.map((event) => event)
        })
      }
    }

    componentDidUpdate() {
      if (this.state.loading && !this.props.loading) {
        this.setState({
          eventIds:
            this.props.eventOrder &&
            this.props.eventOrder.map((eventId) => eventId),
          loading: false,
          timeEvents:
            this.props.userTimeEvents &&
            this.props.userTimeEvents.map((event) => event)
        })
      }
    }

    renderCalenderMoment = (momentData) => {
      return momentData
        ? moment(momentData, "YYYY:MM:DD").format("MMM Do")
        : "TBD"
    }

    renderMoment = (momentData) => {
      return momentData
        .map((singleMoment, index) => {
          let currentMoment = singleMoment.format(
            this.props.momentFormat[index] ||
              this.props.momentFormat[0] ||
              this.props.momentFormat
          )
          if (index !== momentData.length - 1) {
            currentMoment = currentMoment.replace(/pm|am|PM|AM/gi, "")
          } else {
            currentMoment = ` ${currentMoment}`
          }
          return currentMoment
        })
        .join(this.props.momentJoiner)
    }

    updateState = (inObject) => {
      let { editedEvent } = this.state
      let newTimeEvents = this.state.timeEvents.map((event) => event)
      if (
        inObject.eventIdBeingEdited !== this.state.eventIdBeingEdited &&
        this.state.eventIdBeingEdited !== null &&
        this.state.editedEvent.title
      ) {
        this.storeUpdatedTimeEvent(this.state.editedEvent)
        editedEvent = {
          title: "",
          value: null,
          duration: 15
        }
        newTimeEvents = this.state.timeEvents.map((event) => {
          return event.id === this.state.eventIdBeingEdited
            ? this.state.editedEvent
            : event
        })
      }
      this.setState({ ...inObject, timeEvents: newTimeEvents, editedEvent })
    }

    onDragEnd = async (result) => {
      const { destination, source } = result
      if (
        !destination ||
        (destination.droppableId === source.droppableId &&
          destination.index === source.index)
      ) {
        return
      }

      const newOrderedEventIds = [...this.state.eventIds]
      newOrderedEventIds.splice(source.index, 1)
      newOrderedEventIds.splice(
        destination.index,
        0,
        this.state.eventIds[source.index]
      )

      this.setState({
        eventIds: [...newOrderedEventIds],
        trackedTime: moment(this.props.timeConstraints.start),
        isBeingDragged: false
      })

      await this.changeEventOrder(newOrderedEventIds)
      await this.storeUpdatedTimeEvent(this.state.timeEvents[0])
    }

    onDragStart = (result) => {
      this.setState({ isBeingDragged: true })
    }

    dateSortingFunction = (a, b) => {
      if (a.moment_data) {
        if (b.moment_data) {
          return moment(b.moment_data).isBefore(a.moment_data) ? 1 : -1
        }
        return -1
      }
      if (b.moment_data) {
        return 1
      }
      return 0
    }

    storeUpdatedTimeEvent = async (eventData) => {
      if (this.props.calender) {
        await this.props.storeUpdatedDateEvent(eventData, this.state.eventIds)
        const sortedEvents = this.state.timeEvents.sort((a, b) =>
          this.dateSortingFunction(a, b)
        )
        this.setState({ eventIds: sortedEvents.map((event) => event.id) })
      } else {
        await this.props.storeUpdatedTimeEvent(eventData)
      }
    }

    deleteTimeEvent = async () => {
      if (this.state.awaiting === false) {
        const newEventOrder = this.state.eventIds.filter(
          (id) => id !== this.state.editedEvent.id
        )
        const newTimeEvents = this.state.timeEvents.filter(
          (event) => event.id !== this.state.editedEvent.id
        )
        const { editedEvent } = this.state
        this.setState({
          awaiting: true,
          timeEvents: newTimeEvents,
          editedEvent: { title: "", duration: 15, value: null },
          eventIds: newEventOrder
        })
        await this.changeEventOrder(newEventOrder)
        await this.props.deleteTimeEvent(editedEvent)
        this.setState({
          awaiting: false
        })
      }
    }

    deleteCalenderEvent = async () => {
      if (this.state.awaiting === false) {
        const newEventOrder = this.state.eventIds.filter(
          (id) => id !== this.state.editedEvent.id
        )
        const newTimeEvents = this.state.timeEvents.filter(
          (event) => event.id !== this.state.editedEvent.id
        )
        this.setState({
          awaiting: true
        })
        await this.changeEventOrder(newEventOrder)
        await this.props.deleteDateEvent(this.state.editedEvent)
        this.setState({
          timeEvents: newTimeEvents,
          editedEvent: { title: "", moment_data: 0 },
          eventIds: newEventOrder,
          awaiting: false
        })
      }
    }

    changeEventOrder = async (updatedEventOrder) => {
      await this.props.updateEventOrder(updatedEventOrder)
    }

    updateConstraint = async (newTime, AMorPM, calender) => {
      if (calender) {
        debugger
        await this.props.updateSingleLineInputById({
          newValue: newTime,
          id:
            AMorPM === "am"
              ? this.props.timeConstraints.startId
              : this.props.timeConstraints.endId
        })
      } else {
        await this.props.updateSingleLineInputById({
          newValue: newTime,
          id:
            AMorPM === "am"
              ? this.props.timeConstraints.startId
              : this.props.timeConstraints.endId
        })
      }
      await this.props.fetchPartData()
    }

    updateEditEvent = (eventData) => {
      this.setState({
        editedEvent: {
          ...eventData,
          value_id:
            this.props.values.filter(
              (values) => values.name === eventData.value
            )[0] &&
            this.props.values.filter(
              (values) => values.name === eventData.value
            )[0].id
        }
      })
    }

    updateEditCalenderEvent = (eventData) => {
      this.setState({
        editedEvent: {
          ...eventData
        }
      })
    }

    addTimeEvent = async (eventData) => {
      if (this.state.awaiting === false) {
        let allEvents =
          this.state.timeEvents &&
          this.state.timeEvents.map((event) => {
            return event.id === this.state.eventIdBeingEdited
              ? this.state.editedEvent
              : event
          })
        if (allEvents)
          allEvents.push({
            title: "",
            value_id: this.props.values[0].id,
            duration: 15,
            id: 0
          })
        if (!allEvents)
          allEvents = [
            {
              title: "",
              value_id: this.props.values[0].id,
              duration: 15,
              id: 0
            }
          ]
        let editedEvent
        if (this.state.editedEvent.title) {
          editedEvent = this.state.editedEvent
        }
        const tempOrder =
          (this.state.eventIds &&
            this.state.eventIds.map((eventId) => eventId)) ||
          []
        tempOrder.push(0)
        this.setState({
          awaiting: true,
          eventIdBeingEdited: null,
          skeletonLoader: true,
          eventIds: tempOrder,
          loading: false,
          timeEvents: allEvents.map((event) => event)
        })
        await this.props.createTimeEventAndUpdateOrder(
          allEvents,
          editedEvent,
          tempOrder
        )
        await this.props.fetchPartData().then(() =>
          this.setState({
            awaiting: false,
            skeletonLoader: false,
            eventIds:
              this.props.eventOrder &&
              this.props.eventOrder.map((eventId) => eventId),
            loading: false,
            timeEvents: this.props.userTimeEvents.map((event) => event),
            eventIdBeingEdited:
              this.props.eventOrder &&
              this.props.eventOrder[this.props.eventOrder.length - 1]
          })
        )
        // TODO: turn into transitionalScroll, not jump
        window.scrollTo(0, document.body.scrollHeight)
      }
    }

    addDateEvent = async (eventData) => {
      if (this.state.awaiting === false) {
        let allEvents =
          this.state.timeEvents &&
          this.state.timeEvents.map((event) => {
            return event.id === this.state.eventIdBeingEdited
              ? this.state.editedEvent
              : event
          })
        if (allEvents)
          allEvents.push({
            title: "",
            moment_data: "",
            id: 0
          })
        if (!allEvents) allEvents = [{ title: "", moment_data: "", id: 0 }]
        let editedEvent
        if (this.state.editedEvent.title) {
          editedEvent = this.state.editedEvent
        }
        const tempOrder =
          (this.state.eventIds &&
            this.state.eventIds.map((eventId) => eventId)) ||
          []
        tempOrder.push(0)
        this.setState({
          eventIds: tempOrder,
          eventIdBeingEdited: null,
          skeletonLoader: true,
          awaiting: true,
          timeEvents: allEvents.map((event) => event)
        })
        await this.props.createDateEventAndUpdateOrder(
          allEvents,
          editedEvent,
          tempOrder
        )
        await this.props.fetchPartData().then(() =>
          this.setState({
            awaiting: false,
            skeletonLoader: false,
            eventIds:
              this.props.eventOrder &&
              this.props.eventOrder.map((eventId) => eventId),
            loading: false,
            timeEvents: this.props.userTimeEvents.map((event) => event),
            eventIdBeingEdited:
              this.props.eventOrder &&
              this.props.eventOrder[this.props.eventOrder.length - 1]
          })
        )
        // TODO: turn into transitionalScroll, not jump
        window.scrollTo(0, document.body.scrollHeight)
      }
    }

    render() {
      return (
        <div id="skeletonContainer" style={styles.dragContainer}>
          {!this.state.loading ? (
            <>
              <RoadmapperConstraintEvent
                time={
                  this.props.calender
                    ? this.renderMoment([this.props.timeConstraints.start])
                    : this.props.timeConstraints.start
                }
                title={this.props.calender ? "Finish PYP" : "Wake Up"}
                AMorPM="am"
                updateConstraint={this.updateConstraint}
                calender={this.props.calender}
              />
              <DragDropContext
                onDragEnd={this.onDragEnd}
                onDragStart={this.onDragStart}
              >
                {this.props.calender ? (
                  <RoadmapperCalenderContainer
                    skeletonLoader={this.state.skeletonLoader}
                    constraintStart={this.props.timeConstraints.start}
                    constraintEnd={this.props.timeConstraints.end} // for condition rendering when over
                    timeEvents={this.state.timeEvents}
                    updateParentState={this.updateState}
                    renderMoment={this.renderCalenderMoment}
                    durationUnit={this.props.durationUnit}
                    updateEditEvent={this.updateEditCalenderEvent}
                    eventIds={this.state.eventIds}
                    eventIdBeingEdited={this.state.eventIdBeingEdited}
                    deleteTimeEvent={this.deleteCalenderEvent}
                    values={this.props.values}
                  />
                ) : (
                  <RoadmapperDroppableContainer
                    skeletonLoader={this.state.skeletonLoader}
                    constraintStart={this.props.timeConstraints.start}
                    constraintEnd={this.props.timeConstraints.end} // for condition rendering when over
                    timeEvents={this.state.timeEvents}
                    updateParentState={this.updateState}
                    renderMoment={this.renderMoment}
                    durationUnit={this.props.durationUnit}
                    updateEditEvent={this.updateEditEvent}
                    eventIds={this.state.eventIds}
                    eventIdBeingEdited={this.state.eventIdBeingEdited}
                    deleteTimeEvent={this.deleteTimeEvent}
                    values={this.props.values}
                  />
                )}
              </DragDropContext>
              <RoadmapperConstraintEvent
                time={
                  this.props.calender
                    ? this.renderMoment([this.props.timeConstraints.end])
                    : this.props.timeConstraints.end
                }
                title={
                  this.props.calender
                    ? "The beginning of my next chapter"
                    : "Go To Bed"
                }
                AMorPM="pm"
                updateConstraint={this.updateConstraint}
                calender={this.props.calender}
              />
              <CommitButtonWithClickState
                type="red"
                style={styles.addEventButton}
                clickHandler={
                  this.props.calender ? this.addDateEvent : this.addTimeEvent
                }
              >
                {" "}
                Add Event{" "}
              </CommitButtonWithClickState>
            </>
          ) : (
            <SkeletonWrapper
              height="300"
              width={this.state.skeletonWidth}
              containerStyle={{
                width: "100%",
                height: "300px"
              }}
            >
              <rect
                x={0}
                y={0}
                width={800 * this.state.skeletonWidthRatio}
                height="40"
              />
              <rect
                x={0}
                y="50"
                width={800 * this.state.skeletonWidthRatio}
                height="40"
              />
              <rect
                x={0}
                y="100"
                width={800 * this.state.skeletonWidthRatio}
                height="40"
              />
              <rect
                x={0}
                y="150"
                width={800 * this.state.skeletonWidthRatio}
                height="40"
              />
              <rect
                x={0}
                y="200"
                width={800 * this.state.skeletonWidthRatio}
                height="40"
              />
              <rect
                x={0}
                y="250"
                width={800 * this.state.skeletonWidthRatio}
                height="40"
              />
            </SkeletonWrapper>
          )}
        </div>
      )
    }
  }
)

const styles = {
  dragContainer: {
    margin: "auto",
    width: "75%",
    maxWidth: "699px"
  },
  addEventButton: {
    marginTop: "16px",
    marginBottom: "16px",
    maxWidth: "133px",
    height: "44px"
  }
}
