import React from "react"
import { withRouter } from "react-router-dom"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Drawer from "@material-ui/core/Drawer"
import colors from "../../Constants/colors"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import ListWithSelectedComponent from "../ListWithSelectedComponent/ListWithSelectedComponent"
import Actions from "../../Redux/Actions"
import MobileWebNavItem from "../MobileWebNavItem/MobileWebNavItem"
import WebSecondaryDrawerContent from "../WebSecondaryDrawerContent/WebSecondaryDrawerContent"
import "./MobileWebNavDrawer.css"
import constants from "../../Constants"

const styleSheet = {
  drawer: {
    width: "100%",
    backgroundColor: colors.midnight,
    marginTop: "60px"
  },
  secondaryDrawer: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.midnight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}

const baseUrl = "https://www.commitfoundation.org"

class MobileWebNavDrawer extends React.Component {
  constructor(props) {
    super(props)
    this.navOptions = [
      {
        text: "Veteran Programs",
        isSelected: true,
        drawerOpen: this.props.drawerOpen,
        renderComponent: (props, index) => {
          return (
            <MobileWebNavItem
              key={props.key}
              onClick={() => {
                this.props.openSecondaryDrawer(() => {
                  return (
                    <WebSecondaryDrawerContent
                      title="Veteran Programs"
                      options={[
                        {
                          name: "One-on-one Transition Assistance",
                          link: "one-on-one"
                        },
                        { name: "Digital Library", link: "digital-library" },
                        {
                          name: "Pursue Your Purpose",
                          link: "pursueyourpurpose"
                        },
                        {
                          name: "Transition Mentoring Workshops",
                          link: "transition-mentoring"
                        }
                      ]}
                    />
                  )
                })
              }}
            >
              Veteran Programs
            </MobileWebNavItem>
          )
        }
      },
      {
        text: "About",
        isSelected: false,
        renderComponent: (props, index) => {
          return (
            <MobileWebNavItem
              key={props.key}
              onClick={() => {
                this.props.openSecondaryDrawer(() => {
                  return (
                    <WebSecondaryDrawerContent
                      title="About"
                      options={[
                        { name: "Mission", link: "mission" },
                        { name: "Our Team", link: "team" }
                      ]}
                    />
                  )
                })
              }}
            >
              About
            </MobileWebNavItem>
          )
        }
      },
      {
        text: "Support Us",
        isSelected: false,
        renderComponent: (props, index) => {
          return (
            <MobileWebNavItem
              key={props.key}
              onClick={() => {
                this.props.openSecondaryDrawer(() => {
                  return (
                    <WebSecondaryDrawerContent
                      title="Support Us"
                      options={[
                        { name: "Mentors", link: "our-mentors" },
                        { name: "Partners", link: "partners" },
                        { name: "Donors", link: "donate" }
                      ]}
                    />
                  )
                })
              }}
            >
              Support Us{" "}
            </MobileWebNavItem>
          )
        }
      },
      {
        text: "Apply",
        isSelected: false,
        renderComponent: (props, index) => {
          return (
            <MobileWebNavItem key={props.key}>
              {" "}
              <a
                style={{ textDecoration: "none", color: "inherit" }}
                href="https://pursueyourpurpose.commitfoundation.org/account/createAccount"
                // onClick={() => {
                //   this.props.closeAllDrawers()
                // }}
              >
                Apply
              </a>
            </MobileWebNavItem>
          )
        }
      }
    ]
  }

  render() {
    const { navOptions } = this
    return (
      <div
        className="mobileWebNavDrawerContainer"
        style={{
          marginTop: "60px",
          position: "fixed",
          overflow: "auto",
          width: "100%",
          height: this.props.drawerOpen ? "calc(100% - 60px)" : "0%",
          opacity: this.props.drawerOpen ? 1 : 0,
          minHeight: this.props.drawerOpen ? "calc(100% - 60px)" : "0%",
          backgroundColor: colors.midnight,
          color: "white",
          visibility: this.props.drawerOpen ? "visible" : "hidden",
          display: "flex",
          zIndex: this.props.drawerOpen ? 99 : -1,
          flexDirection: "column"
        }}
      >
        <Drawer
          variant="persistent"
          anchor="right"
          classes={{
            paper: this.props.classes.drawer
          }}
          open={this.props.secondaryOpen}
        >
          {this.props.renderSecondaryComponent &&
            this.props.renderSecondaryComponent()}
        </Drawer>
        <ListWithSelectedComponent components={navOptions} />
        <div
          style={{
            display: "flex",
            bottom: "0px",
            width: "100%",
            // position:'fixed',
            WebkitBoxOrient: "horizontal",
            WebkitBoxDirection: "normal",
            flexDirection: "row",
            WebkitboxPack: "justify",
            justifyContent: "space-between",
            fontSize: "20px",
            backgroundColor: colors.white,
            padding: "10px 40px 10px 40px",
            maxHeight: "100px",
            minHeight: "100px",
            flexGrow: 1,
            flexBasis: "auto",
            borderBottom: "1px solid #787f8c"
          }}
        >
          <div style={styles.bottomButton}>
            {" "}
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href="https://pursueyourpurpose.commitfoundation.org/account/login"
            >
              {" "}
              Login
            </a>{" "}
          </div>
          <div style={styles.bottomButton}>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={constants.DONATE_URL}
            >
              Donate
            </a>
          </div>
          <div style={styles.bottomButton}>
            {" "}
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={`${baseUrl}/contact`}
            >
              Contact{" "}
            </a>
          </div>
        </div>{" "}
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    userThroughRedux: state.user.user
  }
}

const styles = {
  bottomButton: {
    display: "flex",
    cursor: "pointer",
    padding: 0,
    fontFamily: "Lato-SemiBold",
    letterSpacing: "0.8px",
    borderBottom: "none",
    textDecoration: "none",
    flexDirection: "column",
    justifyContent: "center",
    color: colors.midnight,
    fontSize: "16px"
  }
}

export default WithDrawerConsumer(
  withRouter(
    withStyles(styleSheet)(
      connect(mapStateToProps, mapDispatchToProps)(MobileWebNavDrawer)
    )
  )
)
