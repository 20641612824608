import React from "react"
// import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import WithLeftBorder from "../WithLeftBorder/WithLeftBorder"
import colors from "../../Constants/colors"

const PYPSidebarItem = ({
  item,
  clickHandler,
  isSelected,
  textStyle,
  uniqueKey,
  children,
  showUnfilled
}) => {
  const isSelectedStyle = isSelected && { fontFamily: "Lato-Bold", opacity: 1 }

  return (
    <div onClick={clickHandler} style={styles.itemContainer} key={uniqueKey}>
      <WithLeftBorder
        isSelected={isSelected}
        renderComponent={(props) => (
          <Link
            key={uniqueKey}
            to={`/workshop/${item.title}`}
            className="pypSideBarItem"
            style={{ ...styles.itemTitle, ...textStyle, ...isSelectedStyle }}
          >
            {item.title}
          </Link>
        )}
      />
    </div>
  )
}

const styles = {
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "35px",
    margin: "5px 0px 5px 0px",
    cursor: "pointer"
  },
  itemTitle: {
    color: colors.white,
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    lineHeight: "20px",
    marginLeft: "38px",
    whiteSpace: "nowrap",
    textDecoration: "none"
  }
}

export default PYPSidebarItem
