/* Background history:
  This was duplicated across the frontend, for now, let's make this list unique but this should ideally
  be fetched from the backend (interests table)
*/

export const industriesRemoved = [
  "Banking / Finance",
  "Banking",
  "Defense / Aerospace",
  "Management Consulting",
  "Private Equity",
  "Risk Management",
  "Security",
  "Shipping / Transportation",
  "Shipping",
  "Chemicals",
  "Communication",
  "Law",
  "Machinery",
  "Marketing",
  "Marketing/Media",
  "Media",
  "Other",
  "Sales/Marketing",
  "Teaching",
  "Transportation",
  "Utilities",
  "Non-Profit"
]

export const industriesList = [
  "Aerospace",
  "Agriculture",
  "Apparel",
  "Banking / Finance",
  "Biotechnology",
  "Business Management",
  "Communications / Media / Marketing",
  "Construction",
  "Consulting",
  "Creative Arts",
  "Cyber Technologies",
  "Data Science / Analytics",
  "Defense",
  "Defense / Aerospace",
  "Education",
  "Electronics",
  "Energy",
  "Engineering",
  "Entertainment",
  "Entrepreneurship",
  "Environmental",
  "Financial Services",
  "Food & Beverage",
  "Government",
  "Healthcare (Physical / Mental)",
  "Hospitality",
  "Human Performance / Leadership",
  "Information Technology",
  "Insurance",
  "Logistics",
  "Manufacturing",
  "Management Consulting",
  "Not For Profit",
  "Operations",
  "Private Equity",
  "Product Management",
  "Real Estate",
  "Recreation",
  "Retail",
  "Risk Management",
  "Sales",
  "Security",
  "Shipping / Transportation",
  "Skilled Trades",
  "Social Enterprise",
  "Sports Management",
  "Talent Acquisition",
  "Technology",
  "Telecommunications",
  "Venture Capital",
  "Undecided"
]
