import React from "react"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"

export const modalUsi = "privacyPolicyModal"

const styles = {
  footerButton: {
    width: "40%",
    maxWidth: "150px"
  },
  modalContainer: {
    maxWidth: "500px",
    width: "85%",
    background: "#F5F6F8"
  },
  privacyPolicyLink: {
    color: colors.fadedRed
  },
  modalContent: {
    padding: 40
  },
  buttonsContainer: {
    display: "flex",
    margin: "30px 0",
    justifyContent: "space-around"
  }
}

const PrivacyPolicyModal = ({ history }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => ({
    user: state.user?.user
  }))

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: modalUsi
      })
    )
  }

  const onBackClick = () => {
    closeModal()
    dispatch(Actions.logout())
    history.push("/account/login")
  }

  const onAcceptClick = () => {
    closeModal()
    dispatch(Actions.acceptPrivacyPolicy(user.id))
  }

  return (
    <ModalDialogHandler
      modal_usi={modalUsi}
      paperProps={{ style: styles.modalContainer }}
      disableBackdropClick
    >
      <div style={styles.modalContent}>
        <span>
          The COMMIT Foundation has a new{" "}
          <a
            href={process.env.REACT_APP_PRIVACY_POLICY_URL}
            target="_blank"
            rel="noopener noreferrer"
            style={styles.privacyPolicyLink}
          >
            Privacy Policy
          </a>
          . Please review and accept the privacy policy to continue.
        </span>
        <div style={styles.buttonsContainer}>
          <CommitButtonWithoutClickState
            clickHandler={onBackClick}
            id="biographyModal"
            type="white"
            style={styles.footerButton}
          >
            Back
          </CommitButtonWithoutClickState>
          <CommitButtonWithoutClickState
            clickHandler={onAcceptClick}
            id="biographyModal"
            type="red"
            style={styles.footerButton}
          >
            Accept
          </CommitButtonWithoutClickState>
        </div>
        <span>
          Questions please contact{" "}
          <a
            href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
            style={styles.privacyPolicyLink}
          >
            support
          </a>
          .
        </span>
      </div>
    </ModalDialogHandler>
  )
}

PrivacyPolicyModal.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
}

export default withRouter(PrivacyPolicyModal)
