import React from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Actions from "../../Redux/Actions"
import PYPInstructionContentContainer from "../PYPInstructionContentContainer/PYPInstructionContentContainer"
import backgroundSVGMap from "./backgroundMap"

class PYPInstructionLayout extends React.Component {
  componentDidMount() {
    this.props.fetchPartData()
  }

  render() {
    return (
      <div
        style={{
          ...styles.componentContainer,
          backgroundImage:
            this.props.title && `url(${backgroundSVGMap[this.props.title]})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          ...this.props.style
        }}
      >
        <PYPInstructionContentContainer
          title={this.props.title}
          description={this.props.description}
        />
      </div>
    )
  }
}

const styles = {
  componentContainer: {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData
  const instruction = (state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data.instruction[0]) || {
    title: null,
    description: null
  }

  return {
    ...props,
    title: instruction.title,
    description: instruction.description
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPInstructionLayout)
)
