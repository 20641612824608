import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import { getStyleByUrl } from "../../Utils/conditionalStyling"
import "./ArrayStyleHandlerByUrl.css"

/*  Use
Takes in an array of objects and maps them

style = {}
clickHandler = function

listItems = {
  text: "",
  className: "",
  functionParams: '', //Requires a default clickHandler
  function: '', //Overwrites functionParams && clickhandler
  componentType: "" //Overwrites default button, case needs to be mase in switch statement
  style: "" //Overwrites default style
}

*/

export default withRouter(function ArrayStyleHandlerByUrl({
  location,
  selectedStyle,
  unselectedStyle,
  listItems,
  buttonStyle,
  clickHandler
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }
  const content = listItems.map((item, index) => {
    if (item) {
      const tempStyle = getStyleByUrl(
        location.pathname,
        item.url_qualifier,
        selectedStyle,
        unselectedStyle
      )

      const paramHandler = (e) => {
        return item.function
          ? item.function()
          : clickHandler(e, item.functionParams)
      }

      switch (item.componentType) {
        case "custom":
          return <item.customComponent {...item} />
        case "cohort":
          const listItemsHtml = item.children && item.children.map((child) => {
            return (
              <li key={child.value}>
                <a href={`${child.functionParams}`}>
                  {child.text}
                </a>
              </li>
            )
          })
          return (
            <div className="dropdown" style={{height: '100%'}}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <button key={`${index}`}
              className={item.className}
              style={{ ...buttonStyle, ...tempStyle, ...item.style }}
              >{item.text}</button>
                {isOpen && (
                <ul className="dropdown-menu">
                   {listItemsHtml}
                </ul>
                )}
            </div>
          )
        default:
          return (
            <button
              key={`${index}`}
              className={item.className}
              style={{ ...buttonStyle, ...tempStyle, ...item.style }}
              onClick={(e) => paramHandler(e)}
              aria-label={`Navigate to ${item.label}`}
            >
              {item.text}
            </button>
          )
      }
    }
    return null
  })

  return content
})
