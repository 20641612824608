import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch } from "react-redux"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import Actions from "../../Redux/Actions"

const modalUsi = "biographyModal"

const styles = {
  footerButton: {
    width: "100px",
    marginLeft: "20x",
    marginRight: "26px",
    marginBottom: "20px"
  },
  modalContainer: {
    maxWidth: "800px",
    width: "85%",
    background: "#F5F6F8"
  },
  modalOverlay: {
    padding: "40px"
  },
  biographyLabel: {
    fontFamily: "Lato-Regular",
    fontSize: "14px"
  },
  biographyModalText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px"
  }
}

const BiographyModal = ({ user, target }) => {
  const dispatch = useDispatch()
  const customModalUsi = `${modalUsi}${target}`;

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: customModalUsi
      })
    )
  }

  return (
    <ModalDialogHandler
      modal_usi={customModalUsi}
      paperProps={{ style: styles.modalContainer }}
      footer={
        <DialogActions>
          <CommitButtonWithoutClickState
            clickHandler={closeModal}
            id="biographyModal"
            type="white"
            style={styles.footerButton}
          >
            Ok
          </CommitButtonWithoutClickState>
        </DialogActions>
      }
    >
      <div style={styles.modalOverlay}>
        <h5>{user.fullName}</h5>
        <span style={styles.biographyLabel}>Biography</span>
        <hr />
        <p style={styles.biographyModalText}>
          {user.biography}
          <br />
        </p>
      </div>
    </ModalDialogHandler>
  )
}

BiographyModal.modalUsi = modalUsi

export default BiographyModal
