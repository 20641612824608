import React from "react"
import PropTypes from "prop-types"
import MediaQuery from "react-responsive"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import InstructionalModal from "../InstructionalModal/InstructionalModal"
import InstructionalGuide from "../InstructionalGuide/InstructionalGuide"
import colors from "../../Constants/colors"

const UserFieldEditorFooter = (props) => {
  return (
    <>
      <MediaQuery minWidth={600}>
        <div style={styles.containerStyle}>
          <div
            style={{
              display: "flex",
              width: "80%",
              margin: "auto",
              paddingBottom: "30px",
              paddingTop: "30px",
              justifyContent: "space-between"
            }}
          >
            {props.renderPreviousButton ? (
              <div style={styles.buttonContainer}>
                <CommitButtonWithClickState
                  type="white"
                  style={{ borderRadius: "3px", width: "154px" }}
                  clickHandler={props.previousHandler}
                >
                  Previous
                </CommitButtonWithClickState>
              </div>
            ) : (
              <div
                style={{
                  width: "154px"
                }}
              />
            )}
            <InstructionalModal
              step="saveAndExit"
              orientation="top"
              contentComponent={() => {
                return (
                  <InstructionalGuide>
                    You can save your application at anytime by clicking the
                    “Save & Exit” button. When you log back in you can pick up
                    right where you left off.
                  </InstructionalGuide>
                )
              }}
              renderComponent={() => {
                return (
                  props.toRenderSaveAndExitButton && (
                    <div style={styles.buttonContainer}>
                      <div
                        style={{ ...styles.saveAndExit, textAlign: "center" }}
                        onClick={props.saveAndExitHandler}
                      >
                        Save and Exit
                      </div>
                    </div>
                  )
                )
              }}
            />
            <div style={styles.buttonContainer}>
              <CommitButtonWithClickState
                type="red"
                style={{ borderRadius: "3px", width: "154px" }}
                hoverStyle={{ borderRadius: "3px" }}
                clickHandler={props.nextHandler}
              >
                {props.nextText}
              </CommitButtonWithClickState>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={599}>
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: "50px",
            position: "fixed",
            bottom: "0px",
            zIndex: 2
          }}
        >
          {props.renderPreviousButton ? (
            <div
              type="white"
              style={styles.mobilePrevButton}
              onClick={props.previousHandler}
            >
              Previous
            </div>
          ) : (
            <div
              style={{ ...styles.mobilePrevButton, color: colors.lightGray }}
            />
          )}
          <div
            type="red"
            className="nextBtn"
            style={styles.mobileNextButton}
            onClick={props.nextHandler}
          >
            {props.nextText}
          </div>
        </div>
      </MediaQuery>
    </>
  )
}

const styles = {
  mobilePrevButton: {
    width: "50%",
    height: "8.5vh",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.lightGray,
    boxShadow: "inset 0 0 1px #000000",
    fontFamily: "Lato-SemiBold",
    backgroundColor: colors.white
  },
  mobileNextButton: {
    width: "50%",
    fontFamily: "Lato-SemiBold",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "8.5vh",
    color: colors.white,
    backgroundColor: colors.fadedRed
  },
  containerStyle: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginTop: "25px",
    marginBottom: "25px",
    backgroundColor: colors.background
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonStyles: {
    height: "40px",
    borderRadius: "3px",
    border: "solid 1px #e8e8e8",
    fontFamily: "Lato-Regular",
    fontSize: "14px"
  },
  saveAndExit: {
    cursor: "pointer",
    fontFamily: "Lato-MediumItalic",
    textDecoration: "underline",
    fontSize: "16px",
    color: "#030d26"
  }
}

UserFieldEditorFooter.defaultProps = {
  saveAndExitHandler: () => {}
}

UserFieldEditorFooter.propTypes = {
  nextHandler: PropTypes.func.isRequired,
  previousHandler: PropTypes.func.isRequired,
  renderPreviousButton: PropTypes.bool.isRequired,
  saveAndExitHandler: PropTypes.func,
  toRenderSaveAndExitButton: PropTypes.bool.isRequired,
  nextText: PropTypes.string.isRequired
}

export default UserFieldEditorFooter
