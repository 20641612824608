import React from "react"

export const GrayUpload = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="118"
      height="98"
      viewBox="0 0 118 98"
    >
      <g fill="#9FA9BA" fillRule="nonzero">
        <path d="M114.245 90.542H11.264c-2.07 0-3.755-1.672-3.755-3.728V21.728C7.51 19.67 5.83 18 3.755 18S0 19.67 0 21.728v65.087C0 92.983 5.053 98 11.264 98h102.981c2.074 0 3.755-1.67 3.755-3.728 0-2.06-1.681-3.73-3.755-3.73zM106.736 10.813H53.508L41.683.88A3.76 3.76 0 0 0 39.265 0H3.755A3.75 3.75 0 0 0 0 3.746a3.75 3.75 0 0 0 3.755 3.746h34.14l11.824 9.933a3.76 3.76 0 0 0 2.418.88h54.6c2.07 0 3.754 1.68 3.754 3.746v54.203A3.75 3.75 0 0 0 114.245 80 3.75 3.75 0 0 0 118 76.254V22.05c0-6.197-5.053-11.238-11.264-11.238z" />
        <path d="M53.507 67.341c1.017 1.821 3.288 2.456 5.073 1.417l20.543-11.96A3.81 3.81 0 0 0 81 53.501a3.81 3.81 0 0 0-1.877-3.298L43.562 29.498a3.657 3.657 0 0 0-3.71.015A3.812 3.812 0 0 0 38 32.796v41.407C38 76.3 39.665 78 41.72 78c2.053 0 3.719-1.7 3.719-3.797v-34.87L69.774 53.5l-14.879 8.661c-1.784 1.039-2.406 3.357-1.388 5.18z" />
      </g>
    </svg>
  )
}
