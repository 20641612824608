import React from "react"
import "./PYPFooterContainer.css"

const PYPFooterContainer = (props) => {
  return (
    <div
      className={`pypFooterContainer ${props.className}`}
      style={{ ...styles.componentContainer, ...props.style }}
    >
      {props.children}
    </div>
  )
}

const styles = {
  componentContainer: {
    width: "100%",
    position: "fixed",
    bottom: "0px"
  }
}

export default PYPFooterContainer
