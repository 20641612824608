import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setInputData } from "../../Redux/Actions/Application/functions"
import CheckBox from "../CheckBox"
import store from "../../Redux/store"

const SpouseCareerSettings = () => {
  const dispatch = useDispatch()

  const { goals, options } = useSelector((state) => ({
    goals: state.spouseApplication.application.career?.career_setting || "",
    options: [
      // Update the options array with the provided options
      { id: 1, text: "Large Corporations" },
      { id: 2, text: "Small Business" },
      { id: 3, text: "Non-profit" },
      { id: 4, text: "Civil Service" },
      { id: 5, text: "Entrepreneurship" },
      { id: 6, text: "Volunteer Work" },
      { id: 7, text: "Working in the home" }
    ]
  }))

  const [goalsValues, setGoalsValues] = useState(() =>
    goals.split(";").filter((goal) => Boolean(goal))
  )

  const onOptionClick = (option) => {
    const isOptionSelected = goalsValues.includes(option.text)
    const newGoalsValues = isOptionSelected
      ? goalsValues.filter((value) => value !== option.text)
      : [...goalsValues, option.text]
    setGoalsValues(newGoalsValues)
    /* Doing this to keep backwards compatibility with legacy implementation */
    dispatch(
      setInputData([
        {
          userField: "career.career_setting",
          value: newGoalsValues.join(";"),
          required: true
        }
      ])
    )
  }
  return (
    <div className="transition-goals-container">
      {options.map((option) => (
        <CheckBox
          key={option.id}
          id={option.id.toString()}
          text={option.text}
          checked={goalsValues.includes(option.text)}
          onClick={() => onOptionClick(option)}
          className="checkBox"
        />
      ))}
    </div>
  )
}

export default SpouseCareerSettings