import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import LongTextInput from "../../../LongTextInput/LongTextInput"

import { changeFormularyInput } from "../../../../Redux/Actions/Formulary/functions"
import useDebouncedEffect from "../../../../Hooks/useDebouncedEffect"

const TextArea = ({ inputId, value, placeholder }) => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState(value)

  useDebouncedEffect(
    () => {
      dispatch(changeFormularyInput(inputId, inputValue))
    },
    500,
    [inputValue]
  )

  return (
    <LongTextInput
      handleChange={(e) => setInputValue(e.target.value)}
      value={inputValue}
      placeholder={placeholder}
    />
  )
}

TextArea.defaultProps = {
  placeholder: ""
}

TextArea.propTypes = {
  inputId: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string
}

export default TextArea
