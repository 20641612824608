import React from "react"
import CommitInput from "../CommitInput/CommitInput"
import InputGroupStateHandler from "../InputGroupStateHandler/InputGroupStateHandler"

const InputGroup = (props) => {
  const buildInput = (changeHandler) => (inputData, i) => {
    return (
      <CommitInput
        key={i}
        {...inputData}
        handleChange={changeHandler(inputData)}
      />
    )
  }

  return (
    <InputGroupStateHandler
      {...props}
      inputData={props.inputData}
      renderComponent={({ changeHandler, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              checkSubmittability,
              inputComponent: (
                <div className={props.containerClass}>
                  {inputData.map(buildInput(changeHandler))}
                </div>
              )
            })}
          </>
        )
      }}
    />
  )
}

export default InputGroup
