import { buildSteps } from "../../Utils/metadataUtils";

export const AlliedForcesProfileMetadata = [
  {
    id: "personal-info",
    title: "Personal Info",
    steps: [
      {
        stepId: "personal",
        name: "Personal Information",
        url: "/profile/allied-forces?title=personal",
        title: "Personal Information",
        body: "Edit your personal information.",
        id: 0,
        inputIds: [0, 1, 2, 3, 4, 5]
      },
      {
        stepId: "location",
        name: "My Address",
        url: "/profile/allied-forces?title=location",
        title: "My Location",
        body: "Where do you live?",
        id: 1,
        inputIds: [6, 7, 8, 9, 10]
      },
      {
        stepId: "biography",
        name: "Biography",
        url: "/profile/allied-forces?title=biography",
        title: "Biography",
        body:
          "A good biography should be approximately 150 words in length and may include the duration of your service, key leadership positions, future career interests, your current timeline for transition, what you would like to get out of the COMMIT experience, and any personal details you would like to highlight. Your bio will be shared with COMMIT mentors. Please write in the third person and avoid references to specific ranks, awards, or units. (minimum 300 characters and maximum 1000 characters)",
        id: 2,
        inputIds: [11]
      }
    ],
    inputs: [
      { id: 0, userField: "firstName" },
      { id: 1, userField: "lastName" },
      { id: 2, userField: "email" },
      { id: 3, userField: "nickName", optional: true },
      { id: 4, userField: "linkedin_url", optional: true },
      { id: 5, userField: "phoneNumber" },
      { id: 6, userField: "address.streetAddress" },
      { id: 7, userField: "address.city" },
      { id: 8, userField: "address.state" },
      { id: 9, userField: "address.zipCode" },
      { id: 10, userField: "address.country" },
      { id: 11, userField: "biography.biography" }
    ]
  },
  {
    id: "military",
    title: "Military",
    filled: false,
    steps: [
      {
        stepId: "military",
        name: "Military Information",
        url: "/profile/allied-forces?title=military",
        title: "Military",
        body: "We use this information to learn more about the veterans we serve.",
        id: 0,
        inputIds: [0, 1, 2, 3]
      }
    ],
    inputs: [
      { id: 0, userField: "military.branch" },
      { id: 1, userField: "military.specialty" },
      { id: 2, userField: "military.lastRank" },
      { id: 3, userField: "military.lastDutyPosition" }
    ]
  },
  {
    id: "professional",
    title: "Career",
    filled: false,
    steps: [
      {
        stepId: "professional-basic",
        name: "Professional Information",
        url: "/profile/allied-forces?title=professional-basic",
        title: "Professional Information",
        body: "Provide some information about your professional life that other veterans can see.",
        id: 0,
        inputIds: [0]
      },
      {
        stepId: "professional-industries",
        name: "Interested Industries",
        url: "/profile/allied-forces?title=professional-industries",
        title: "Industries",
        body: "What industries are you interested in?",
        id: 1,
        inputIds: [1, 2]
      }
    ],
    inputs: [
      { id: 0, userField: "community.current_job", optional: true },
      { id: 1, userField: "interests_for_profile" },
      { id: 2, userField: "profession.otherInterest", optional: true }
    ]
  }
];

export const AlliedForcesProfileSteps = buildSteps(AlliedForcesProfileMetadata);
