import React from "react"
import { ResponsiveBar } from "@nivo/bar"
import colors from "../../Constants/colors"
/*
colors = [“color_a”,”color_b”,”color_c”, ect...]
keys = [“key_1”,”key_2”,ect…]
itemData = [
		{
			title: “To Be Displayed At Bottom”, //indexBy="title"
			itemOrder: [“key_1”,”key_2”, ect...],
			key_1: unitMeasurement,
			key_2: unitMeasurement,
			key_x: …,
		},
  ]
  
Need to keep track of Bar Graph max which would be 
*/

// unused function - calculates ticks at the top if design changes
// const calcTicks = (data, tickStep = 30, finalTick = true) => {
//   let maxTotal = 0
//   data.forEach(dataPoint => {
//     if (maxTotal < dataPoint.total) maxTotal = dataPoint.total
//     if (maxTotal < dataPoint.Sleep) maxTotal = dataPoint.Sleep
//   })
//   let ticks = []
//   for (let i = 0; i <= maxTotal; i += tickStep) {
//     ticks.push(i)
//   }
//   if (maxTotal % tickStep !== 0 && finalTick) ticks.push(maxTotal)
//   return ticks
// }

export default function CommitResponsiveBar({
  longestValue = 9,
  data,
  keys,
  inColors = ["lightgray"],
  tickStep,
  yUnits,
  xUnits,
  rightMarginModifier = 0,
  bottomMarginModifier = 0,
  small = false,
  vertical,
  percentage = true,
  fontColor,
  axisLeft,
  axisBottom,
  axisRight,
  axisTop,
  leftAxisLegendTitle = "",
  withLabel = false,
  fontSize
}) {
  const theme = {
    fontFamily: "Lato-Regular",
    fontSize: 16,
    textColor: fontColor || colors.white,
    fill: fontColor || colors.white,
    annotations: {
      text: {
        color: fontColor || colors.white,
        fill: colors.white,
        fontSize: 11,
        fontFamily: "Lato-Bold"
      }
    },
    tooltip: {
      container: {
        color: colors.midnight,
        fontSize: 14
      }
    },
    axis: {
      ticks: {
        line: {
          stroke: colors.white
        },
        text: {
          fill: fontColor || colors.white
        }
      },
      legend: {
        text: {
          fontSize: fontSize || 10,
          fill: fontColor || colors.white
        }
      }
    },
    labels: {
      text: {
        // textLength: 14,
      }
    }
  }
  return (
    <ResponsiveBar
      layout={vertical ? "vertical" : "horizontal"}
      theme={theme}
      data={data}
      keys={keys}
      indexBy="title"
      reverse={false}
      margin={{
        top: 0,
        right: 80 + rightMarginModifier,
        bottom: 50 + bottomMarginModifier,
        left: longestValue * 8 + 40
      }}
      padding={0.35}
      colors={(itemData) => {
        return inColors[itemData.id] || inColors.default
      }}
      axisBottom={axisBottom || null}
      axisRight={axisRight || null}
      axisLeft={
        axisLeft || {
          ...null,
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: leftAxisLegendTitle,
          legendPosition: "middle",
          legendOffset: -longestValue * 7 - 15
        }
      }
      axisTop={axisTop || null}
      tooltipFormat={(d) => d + (percentage ? "%" : "")}
      labelFormat={(d) => {
        return <>{withLabel ? d : ""}</> // add d into the {} to render
      }}
      labelSkipWidth={small ? 50 : 30}
      labelSkipHeight={12}
      gridYValues={[]} // calcTicks(data, tickStep, false)}
      motionStiffness={10}
      motionDamping={5}
    />
  )
}
