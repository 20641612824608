import React from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

import {
  Likert,
  TextArea,
  MultiSelectGroup,
  NpsInput,
  MultiSelect,
  TopThreeGoals
} from "./inputs"

import "./Formulary.css"

const FormularyStepInputs = ({ stepId }) => {
  const { inputs } = useSelector(({ formulary }) => {
    const formularyInputs = Object.values(
      formulary[formulary.currentFormularyType].inputs
    )

    return {
      inputs: formularyInputs
        .filter((input) => input.step === stepId)
        .sort((a, b) => a.order - b.order)
    }
  })

  const renderInput = (input) => {
    switch (input.inputType) {
      case "likert":
        return (
          <Likert
            key={input.id}
            inputId={input.id}
            title={input.title}
            value={input.value}
          />
        )
      case "textarea":
        return (
          <TextArea
            key={input.id}
            inputId={input.id}
            value={input.value}
            placeholder={input.title}
          />
        )
      case "multiselect":
        return (
          <MultiSelect
            key={input.id}
            inputId={input.id}
            selectedOptions={input.value || []}
          />
        )
      case "multiselectGroup":
        return (
          <MultiSelectGroup
            key={input.id}
            inputId={input.id}
            options={input.options}
            selectedOptions={input.value || []}
          />
        )
      case "nps":
        return (
          <NpsInput
            key={input.id}
            inputId={input.id}
            title={input.title}
            value={input.value}
          />
        )
      case "booleanDropdown":
        return null
      case "topThreeGoals":
        return <TopThreeGoals key={input.id} title={input.title} />
      default:
        return null
    }
  }

  if (!inputs.length) {
    return (
      <div className="formulary-container formulary-empty-inputs-container">
        <span>
          The step <code>{stepId}</code> has no inputs.
        </span>
        <p>
          Insert inputs on the <code>formulary_questions</code> table for the
          given formulary (<code>type</code> column)
        </p>
      </div>
    )
  }

  return <div className="formulary-container">{inputs.map(renderInput)}</div>
}

FormularyStepInputs.propTypes = {
  stepId: PropTypes.string.isRequired
}

export default FormularyStepInputs
