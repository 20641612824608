import React from "react"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

export const VideoThumbnailTitle = (props) => {
  if (!props.title) {
    return (
      <SkeletonWrapper
        theme="light"
        containerStyle={styles.skeletonWrapperContainerStyle}
      >
        <rect x="5" y="0" rx="4" ry="4" width="300" height="30" />
      </SkeletonWrapper>
    )
  }
  return <p style={styles.videoTitle}>{props.title}</p>
}

const styles = {
  videoTitle: {
    fontFamily: "Lato-Semibold",
    marginTop: "10px",
    lineHeight: "16px",
    marginBottom: "5px",
    fontSize: "14px",
    letterSpacing: "normal",
    color: "#272728"
  },
  skeletonWrapperContainerStyle: {
    maxWidth: "600px",
    height: "30px",
    minWidth: "300px",
    marginTop: "10px"
  }
}

export default VideoThumbnailTitle
