import React from "react"
import "./PYPTitle.css"

const PYPTitle = (props) => {
  return (
    <h1
      className={`${props.className} pypTitle`}
      style={{
        ...styles.title,
        color: props.theme === "light" ? "white" : "white",
        ...props.style
      }}
    >
      {props.title}
    </h1>
  )
}

const styles = {
  title: {
    // height: '40px', //TODO: Do we want to put a heigh constraint on this for any reason? To enforce that title don't wrap to the next line? I say no. - Sam
    fontFamily: "Lato-Bold",
    //        fontSize: '40px',
    lineHeight: "42px"
  }
}

export default PYPTitle
