import React, { useState } from "react"
import { withRouter, Link } from "react-router-dom"
import Loader from "react-loader-spinner"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors.js"
import FormHeader from "../FormHeader/FormHeader"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { LeftArrow } from "../../Icons/LeftArrow"
import { FormMiddle } from "../FormMiddle/FormMiddle"
import Actions from "../../Redux/Actions"
import CommitInput from "../CommitInput/CommitInput"
import { useInput } from "../../Hooks/useInput"

const ForgotPassword = (props) => {
  const { value: email, bind: bindEmail } = useInput("")
  const [sendingEmail, setSendingEmail] = useState(false)
  document.title = "Forgot Password"

  const sendPasswordResetEmail = () => {
    props.sendPasswordResetEmail({ email }, props.history)
    setSendingEmail(true)
  }

  const navToLogin = () => {
    props.history.push("/account/login")
  }

  return sendingEmail ? (
    <Loader type="Oval" color={colors.midnight} height="100" width="100" />
  ) : (
    <div style={styles.componentContainer}>
      <AccountFormContainer>
        <div onClick={navToLogin} style={styles.backToLoginContainer}>
          <LeftArrow />
          <Link to="/account/login" style={styles.backToLoginText}>
            Back to login
          </Link>
        </div>
        <FormHeader
          style={styles.formHeader}
          title={props.title || "Forgot your password?"}
          subTitle={
            props.subTitle ||
            `Enter your email below and we'll send you a link to reset your password!`
          }
        />
        <CommitInput
          id="email"
          style={styles.inputStyle}
          inputType="text"
          placeHolder="Email"
          value=""
          className="loginEmail"
          errorMessage="Required"
          submittable={false}
          {...bindEmail}
        />
        <FormMiddle>
          <CommitButtonWithClickState
            type="red"
            clickHandler={sendPasswordResetEmail}
          >
            Send Via Email
          </CommitButtonWithClickState>
        </FormMiddle>
      </AccountFormContainer>
    </div>
  )
}

const styles = {
  inputStyle: {
    marginTop: "10px",
    margiBottom: "10px"
  },
  formHeader: {
    marginTop: "50px"
  },
  backToLoginContainer: {
    cursor: "pointer",
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  backToLoginText: {
    marginLeft: "20px",
    color: colors.fadedRed,
    fontSize: "16px",
    fontFamily: "Lato-Semibold",
    textDecoration: "none"
  },
  componentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
)
