import React from "react"

export const RedPlus = (props) => {
  return (
    <div style={{ cursor: "pointer" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
      >
        <g fill="#E1373A" fillRule="evenodd">
          <path d="M4 0h2v10H4z" />
          <path d="M10 4v2H0V4z" />
        </g>
      </svg>
    </div>
  )
}
