import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import colors from "../../Constants/colors"
import defaultImg from "../../Assets/Images/icons/defaultNetworkImage.svg"
import { useWindowResize } from "../../Hooks/useWindowResize"
import "./MembersCard.css"

const styles = {}
const baseStyles = {
  container: {
    minWidth: "249px",
    maxWidth: "249px",
    height: "315px",
    textAlign: "center",
    margin: "7.5px 5px 18.5px 5px",
    paddingLeft: "15px",
    paddingRight: "15px",
    background: colors.white,
    border: "1px solid rgba(159, 169, 186, 0.202219)",
    boxSizing: "border-box",
    boxShadow: "12px 18px 34px rgba(0, 0, 0, 0.05)",
    borderRadius: "5px",
    color: colors.midnight,
    overflow: "hidden",
    position: "relative"
  },
  self: {
    borderColor: colors.fadedRed
  },
  skeletonContainer: {
    minWidth: "249px",
    maxWidth: "249px",
    height: "315px",
    margin: "5px",
    marginBottom: "25px"
  },
  skeletonChild: {
    minWidth: "249px",
    maxWidth: "249px",
    width: "249px",
    height: "315px"
  },
  profileImage: {
    borderRadius: "50px",
    width: "75px",
    height: "75px",
    marginTop: "27px",
    objectFit: "cover"
  },
  upper: {
    height: "280px"
  },
  titles: {
    marginTop: "18px"
  },
  name: {
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "20px",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: "2"
  },
  location: {
    fontFamily: "Lato-Italic",
    fontStyle: "italic",
    color: colors.blueyGrey,
    fontWeight: "300",
    fontSize: "13px",
    lineHeight: "20px"
  },
  profession: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    width: "calc(100% - 30px)",
    margin: "auto",
    marginTop: "2px",
    textAlign: "center",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: "2"
  },
  specialtyBox: {
    height: "44px"
  },
  specialtyHeader: {
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px"
  },
  specialty: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.blueyGrey,
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: "1"
  },
  line: {
    width: "90%",
    height: "1px",
    border: "1px solid rgb(232, 232, 232)",
    margin: "auto",
    marginTop: "12px",
    marginBottom: "12px"
  },
  footerButton: {
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    color: colors.fadedRed,
    textAlign: "right",
    marginRight: "0px",
    letterSpacing: "-0.1px"
  },
  contactContainer: {
    width: "fill-available",
    display: "flex"
  },
  emptyLink: {
    color: colors.fadedGray
  }
}

const largerText = {
  fontSize: "18.8353px",
  lineHeight: "27px"
}
const smallVPStyles = {
  container: {
    width: "calc(100vw - 40px)",
    height: "433.8px",
    maxWidth: "335px",
    minWidth: "280px",
    margin: "auto",
    marginBottom: "40px"
  },
  skeletonContainer: {
    width: "calc(100vw - 40px)",
    height: "418.8px",
    maxWidth: "400px",
    minWidth: "calc(100vw - 40px)", // "280px",
    margin: "auto",
    marginBottom: "100px"
  },
  skeletonChild: {
    height: "363.8px",
    maxWidth: "400px",
    minWidth: "280px",
    width: "calc(100vw - 158px)",
    margin: "auto",
    marginBottom: "100px"
  },
  profession: {
    ...largerText
  },
  name: {
    fontSize: "21.5261px",
    lineHeight: "27px"
  },
  location: {
    fontSize: "17.49px",
    lineHeight: "27px"
  },
  profile_image: {
    height: "100.9px",
    width: "100.9px",
    marginTop: "36.33px"
  },
  specialty: {
    ...largerText
  },
  specialtyBox: {
    height: "59.2px"
  },
  specialtyHeader: {
    ...largerText
  },
  upper: {
    height: "385px"
  },
  footerButton: {
    ...largerText,
    marginRight: "5.8px"
  },
  line: {
    marginTop: "16.14px",
    marginBottom: "16.14px"
  }
}

const MembersCard = ({
  user,
  onLinkedInPress,
  onBiographyPress,
  showMilitaryBranch
}) => {
  const { width } = useWindowResize()
  const loggedUser = useSelector((state) => state.user.user)
  const isSelf = loggedUser.id === user.id
  const isMentor = user?.tags?.includes("Mentor")

  const getProfession = () => {
    if (user.currentJob) {
      return user.currentJob
    }

    if (!user?.interests?.length) {
      return "Undecided"
    }

    return user.interests.reduce(
      (currProfession, interest) =>
        currProfession.length
          ? `${currProfession}, ${interest}`
          : `Interested in ${interest}`,
      ""
    )
  }

  const getContainerStyle = () => {
    if (user.loading) {
      return styles.skeletonContainer
    }

    return isSelf ? { ...styles.container, ...styles.self } : styles.container
  }

  Object.keys(baseStyles).forEach((key) => {
    styles[key] = baseStyles[key]
  })

  if (width < 601) {
    Object.keys(smallVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...smallVPStyles[key] }
    })
    if (width < 480) {
      styles.skeletonContainer.marginBottom = `${Math.floor(width / 60)}px`
    }
  }

  return (
    <div className="member-card" style={getContainerStyle()}>
      {isMentor && (
        <span className="mentor-badge" data-testid="mentor-badge">
          Mentor
        </span>
      )}
      {user.loading ? (
        <SkeletonWrapper
          width={100}
          height={126}
          containerStyle={styles.skeletonChild}
        />
      ) : (
        <>
          <div style={styles.upper}>
            <img
              src={user.profile_image_url || defaultImg}
              style={styles.profileImage}
              alt="user profile"
            />
            <div style={styles.titles}>
              <p style={styles.name}>{user.fullName}</p>
              <p style={styles.profession}> {getProfession()}</p>
              <p style={styles.location}>
                {user.city}
                {user.state && `, ${user.state}`}
              </p>
            </div>
            <div style={styles.line} />
            {user.military && (
              <>
                {user.military.specialty && (
                  <div style={styles.specialtyBox}>
                    <p style={styles.specialtyHeader}>Military Occupation:</p>
                    <p style={styles.specialty}>
                      {isSelf && !isMentor ? (
                        <a
                          className="militaryOccupationLink"
                          href="/profile?title=military"
                        >
                          Update required
                        </a>
                      ) : (
                        user.military.specialty
                      )}
                    </p>
                  </div>
                )}
                {showMilitaryBranch && user.military.branch && (
                  <div style={styles.specialtyBox}>
                    <p style={styles.specialtyHeader}>Military Branch:</p>
                    <p style={styles.specialty}>{user.military.branch}</p>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            style={{
              ...styles.contactContainer,
              justifyContent: onBiographyPress ? "space-between" : "flex-end"
            }}
          >
            {onBiographyPress && (
              <span
                style={styles.footerButton}
                className="footerButton"
                onClick={onBiographyPress}
                role="button"
                tabIndex={0}
                onKeyPress={onBiographyPress}
              >
                Biography
              </span>
            )}
            {user.linkedIn ? (
              <a
                href={user.linkedIn}
                target="__blank"
                style={styles.footerButton}
                onClick={onLinkedInPress}
              >
                LinkedIn
              </a>
            ) : (
              <span style={{ ...styles.footerButton, ...styles.emptyLink }}>
                LinkedIn
              </span>
            )}
          </div>
        </>
      )}
    </div>
  )
}

MembersCard.defaultProps = {
  onLinkedInPress: () => {},
  onBiographyPress: null,
  showMilitaryBranch: false
}

MembersCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    loading: PropTypes.bool,
    city: PropTypes.string,
    currentJob: PropTypes.string,
    fullName: PropTypes.string,
    interests: PropTypes.arrayOf(PropTypes.string),
    linkedIn: PropTypes.string,
    military: PropTypes.object,
    profile_image_url: PropTypes.string,
    state: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onLinkedInPress: PropTypes.func,
  onBiographyPress: PropTypes.func,
  showMilitaryBranch: PropTypes.bool
}

export default MembersCard
