import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import MediaQuery from "react-responsive"
import { toggleModal } from "../../Redux/Actions/User/functions"

import { ScheduleDVSModal, UpdateProfileModal } from "../Modals"

import BiographyContainer from "./UserBiographyContainer/UserBiographyContainer"
import Top3GoalsLanding from "./Top3GoalsLanding/Top3GoalsLanding"
import BiographyDigitalLibrary from "../BiographyDigitalLibrary/BiographyDigitalLibrary"
import BiographyPYP from "../BiographyPYP/BiographyPYP"
import BiographySpouseCourse from "../BiographySpouseCourse/BiographySpouseCourse"
import ModalCropOverlay from "../ModalCropOverlay/ModalCropOverlay"
import UserActionCard from "./UserActionCard/UserActionCard"
import { getUserWorkshops } from "../../Redux/Actions/Workshop/functions"
import { profileMetadata } from "../../Redux/Reducers/Application/profileMetadata"
import { withRouter } from "react-router-dom";
import Actions from "../../Redux/Actions"

import "./landingPage.css"

Object.byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
  s = s.replace(/^\./, "") // strip a leading dot
  const a = s.split(".")
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (o && k in o) {
      o = o[k]
    } else {
      return
    }
  }
  return o
}

const LandingPage = (props) => {
  const dispatch = useDispatch()

  const [showDVS, setShowDVS] = useState(false)
  const [isFieldMissing, setIsFieldMissing] = useState(false)
  const dvsInfo = useSelector((state) => {
    if (!state.user?.user?.dvsPerson) return null
    const {
      dvsPersonUser: user,
      schedule_meeting_url: scheduleMeetingUrl
    } = state.user?.user?.dvsPerson

    return {
      ...user,
      scheduleMeetingUrl
    }
  })
console.log("dvsInfo", dvsInfo)
  const { user, workshops, application, hasCheckedProfile } = useSelector((state) => ({
    user: state.user?.user,
    workshops: state.workshop?.workshops,
    application: state.application?.application,
    hasCheckedProfile: state.user.hasCheckedProfile
  }))

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        page_view: {
          page_path: `/home`,
          page_title: document.title
        }
      })
    }
    dispatch(Actions.fetchApplication("initialLoad", props.history))
  }, [])

  useEffect(() => {
    const hasUser = Object.keys(user).length > 0
    const competitiveTier = user?.competitive_tier
    const isMeetingScheduled = user?.dvsPerson?.is_meeting_scheduled ?? true
    setShowDVS(hasUser && [0, 1].indexOf(competitiveTier) === -1)
    const lastActivityDate = new Date(user.last_activity);
    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

    if ((lastActivityDate < sixtyDaysAgo || isFieldMissing ) && !hasCheckedProfile){
      dispatch(
        toggleModal({
          modal_mode: true,
          modal_usi: UpdateProfileModal.modalUsi
        })
      )
    }
    else{
      if (showDVS && !isMeetingScheduled) {
        dispatch(
          toggleModal({
            modal_mode: true,
            modal_usi: ScheduleDVSModal.modalUsi
          })
        )
      }
    }

    // check if user is spouse (We don't longer use competitive_tier === 3 to identify spouses
    // in the future there might be spouses with competitive_tier === 2
    dispatch(getUserWorkshops())
  }, [user, isFieldMissing])

  useEffect(() =>{
    let fieldValue;
    let newMissingFieldsArray = [];
    if(!isFieldMissing && typeof application != 'undefined' && Object.keys(application).length > 0 && application.hasOwnProperty('id')){
      for (let section = 0; section < profileMetadata.length; section++){

        let inputs = profileMetadata[section].inputs;

        for (let inputIndex = 0; inputIndex < inputs.length; inputIndex++){

          fieldValue = Object.byString(
            application,
            inputs[inputIndex].userField
          );
          /*if(inputs[inputIndex].userField == 'interests_for_profile' && fieldValue?.length < 5 && !Object.byString(application, inputs[inputIndex + 1].userField)){
          }
          else if(!fieldValue){
          }
          */


          if (!inputs[inputIndex].optional && (inputs[inputIndex].userField == 'interests_for_profile' ? fieldValue?.length < 5 && !Object.byString(application, inputs[inputIndex + 1].userField): !fieldValue)) {
            setIsFieldMissing(
              inputs[inputIndex].userField == 'interests_for_profile' ? fieldValue?.length < 5 && !Object.byString(application, inputs[inputIndex + 1].userField): !fieldValue
            )
          }
        }
      }
    }
  }, [application])
  return (
    <div className="landingPageContainer">
      <div className="left-section">
        <BiographyContainer />
        {dvsInfo && showDVS ? (
          <div className="dvs-landing-container">
            <MediaQuery minWidth={651}>
              <h1 className="section-title">
                Your Director of Veteran Services
              </h1>
            </MediaQuery>
            <MediaQuery maxWidth={650}>
              <h1 className="section-title">Your DVS</h1>
            </MediaQuery>
            {dvsInfo ? (
              <UserActionCard
                showScheduleButton
                target="dvs"
                is_spouse={user.is_spouse}
                customStyles={{
                  container: { padding: 28 }
                }}
                user={dvsInfo}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      <div className="right-section">
        <Top3GoalsLanding />
        <h1 className="PYP-title">Your Dashboard</h1>
        {/* To change when we have a way to identify 2S on the DB */}
        {workshops.length ? <BiographySpouseCourse /> : null}
        <BiographyPYP />
        <BiographyDigitalLibrary />
      </div>
      <ModalCropOverlay />
    </div>
  )
}

export default withRouter(LandingPage)
