import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import CommitInput from "../CommitInput/CommitInput"
import Actions from "../../Redux/Actions"

/* Component Data
  --CommitInput has a map of types - maybe this is a new types for the switch statement
      --format will be stored as a string HH:MM on a 24h time frame
      --will need to rendered through moment
*/

const styles = {
  inputStyle: {
    minWidth: "200px"
  },
  inputStyle2: {
    fontSize: "18px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(function TimeConstraintSelector({
  title,
  style,
  AMorPM,
  updateTimeConstraints,
  value,
  loaded,
  calender
}) {
  const options = []
  ;(() => {
    for (let i = 2; i < 12; i++) {
      options.push({
        value: `${i}:00${AMorPM}`
      })
      options.push({
        value: `${i}:30${AMorPM}`
      })
    }
    options.push({
      value: `12:00${AMorPM === "pm" ? "am" : "pm"}`
    })
  })()

  const changeStateHandler = (e) => {
    updateTimeConstraints(e.target.value, AMorPM)
  }

  return (
    <>
      {value || loaded ? (
        calender ? (
          <CommitInput
            inputType="date"
            inputStyle={{ marginTop: "-15px" }}
            style={{ ...styles.inputStyle, ...styles.inputStyle2 }}
            placeHolder={title}
            value={value}
            handleChange={changeStateHandler}
          >
            {" "}
            {value}{" "}
          </CommitInput>
        ) : (
          <CommitInput
            style={styles.inputStyle}
            inputType="select"
            title={title}
            value={value}
            options={options}
            handleChange={changeStateHandler}
          >
            {value}
          </CommitInput>
        )
      ) : (
        <h1> {value}</h1>
      )}
    </>
  )
})
