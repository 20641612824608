import React from "react"
import colors from "../../Constants/colors"
import "./LongDescription.css"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"

export const LongDescription = ({ long_description }) => {
  return (
    <div className="longDescriptionContainer" style={styles.componentContainer}>
      {long_description ? (
        long_description.split("\n").map((text, i) => {
          if (text.length) {
            return <p key={i}>{text}</p>
          }
          return <br key={i} />
        })
      ) : (
        <SkeletonWrapper
          theme="light"
          containerStyle={{ maxWidth: "400px", height: "100px" }}
          height={400}
          width={400}
        >
          >
          <rect x="2" y="0" rx="4" ry="4" width="117" height="20" />
          <rect x="2" y="41" rx="3" ry="3" width="450" height="12" />
          <rect x="2" y="66" rx="3" ry="3" width="480" height="12" />
          <rect x="2" y="94" rx="3" ry="3" width="301" height="12" />
        </SkeletonWrapper>
      )}
    </div>
  )
}

const styles = {
  componentContainer: {
    fontSize: "16px",
    paddingRight: "20px",
    lineHeight: "23px",
    letterSpacing: "0px",
    fontFamily: "Lato-Regular",
    color: colors.gray,
    paddingBottom: "20px",
    width: "100%"
  },
  description: {
    marginTop: "15px",
    fontSize: "16px",
    lineHeight: "23px",
    fontFamily: "Lato-Light"
  },
  videoContainer: {
    height: "500px",
    width: "500px"
  }
}
