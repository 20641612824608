import React, { useState } from "react"
import { withRouter, Link } from "react-router-dom"
import { connect, useSelector } from "react-redux"
import { bindActionCreators } from "redux"
import Person from "@material-ui/icons/Person"
import { Logo } from "../../Icons/Logo"
import Actions from "../../Redux/Actions"

import ArrayStyleHandlerByUrl from "../ArrayStyleHandlerByUrl/ArrayStyleHandlerByUrl"
import colors from "../../Constants/colors"
import "./GlobalDesktopNavBar.css"
import CardArrayHandler from "../CardArrayHandler/CardArrayHandler"
import CONSTANTS from "../../Constants/index"
import OutsideClickWrapper from "../../Hooks/outsideClickWrapper"

const GlobalDesktopNavBar = ({
  navigateToWorkshop,
  saveApplication,
  currentInputData,
  user,
  toggleModal,
  logout,
  history
}) => {
  const [nameTagHovered, toggleHovered] = useState(false)
  const [hiddenDisplayLock, toggleHiddenLock] = useState(false)
  const workshops = useSelector(state => state.workshop?.workshops)

  const navOptions = [
    {
      value: "analytics",
      url_qualifier: "analytics",
      text: "Analytics",
      componentType: "normal",
      functionParams: "/analytics",
      className: "navHoverWithUnderline",
      label: "Analytics"
    },
    {
      value: "spouseCourse",
      url_qualifier: "spouseCourse",
      text: "Workshops",
      functionParams: "/virtualcohort",
      componentType: "cohort",
      className:
        process.env.REACT_APP_PYP_ENABLED !== "false"
          ? "navHoverWithUnderline"
          : "disabledNavItem",
      label: "Workshops",
      children: [
      ]
    },
    {
      value: "digitalLibrary",
      url_qualifier: "digitalLibrary",
      text: "Digital Library",
      componentType: "normal",
      function:
        process.env.REACT_APP_DL_ENABLED !== "false"
          ? null
          : () =>
              toggleModal({ modal_mode: true, modal_usi: "programDisabled" }),
      functionParams: "/digitalLibrary/home",
      className:
        process.env.REACT_APP_DL_ENABLED !== "false"
          ? "navHoverWithUnderline"
          : "disabledNavItem",
      label: "Digital Library"
    },
    {
      value: "pyp",
      url_qualifier: "workshop",
      text: "Pursue Your Purpose",
      componentType: "normal",
      function:
        process.env.REACT_APP_PYP_ENABLED !== "false"
          ? () => navigateToWorkshop(history)
          : () =>
              toggleModal({ modal_mode: true, modal_usi: "programDisabled" }),
      functionParams: "/workshop",
      className:
        process.env.REACT_APP_PYP_ENABLED !== "false"
          ? "navHoverWithUnderline"
          : "disabledNavItem",
      label: "Pursue Your Purpose"
    },
    {
      value: "network",
      url_qualifier: "network",
      text: "Network",
      componentType: "normal",
      functionParams: "/network",
      className:
        !user.community ||
        (user.community &&
          (user.community.community_member === 0 || !user.linkedin_url))
          ? "disabledNavItem"
          : "navHoverWithUnderline",
      label: "Network",
      function:
        (!user.community ||
          (user.community &&
            (user.community.community_member === 0 || !user.linkedin_url))) &&
        (() => toggleModal({ modal_mode: true, modal_usi: "networkDisabled" }))
    }
  ]

  const hiddenButtons = [
    {
      text: "Home",
      componentType: "button-with-underline",
      underlineStyle: { ...styles.underlineStyle },
      function: () => history.push(`/home`)
    },
    {
      text: "Edit Profile",
      componentType: "button-with-underline",
      underlineStyle: { ...styles.underlineStyle },
      function: () =>
        history.push(`/profile?title=${CONSTANTS.PROFILE_FIRST_PAGE}`)
    },
    {
      text: "Logout",
      function: () => {
        if (currentInputData) {
          saveApplication(currentInputData)
          logout()
          history.push("/account/login")
        } else {
          logout()
          history.push("/account/login")
        }
      }
    }
  ]

  const tierFilter = (user) => (navOption) => {
    return {
      ...navOption,
      toDisplay: user.competitive_tier > -1 || navOption.value === "logout"
    }
  }

  const spouse_filter = (user) => (navOption) => {
    if (navOption && navOption.value === "spouseCourse") {
      // check if user is spouse
      if (workshops.length) {
        const navOptionUpdated = navOption
        workshops.map((workshop) => {
          navOptionUpdated.children.push({
          value: workshop.id,
          url_qualifier: workshop.id,
          text: workshop.title,
          functionParams: "/virtualcohort?workshop="+workshop.id,
          className:
            process.env.REACT_APP_PYP_ENABLED !== "false"
              ? "navHoverWithUnderline"
              : "disabledNavItem",
          label: workshop.title,
        })
        })
        // const navOptionUpdated = navOption
        // navOptionUpdated.text = workshops[0].title || "Workshop"
        return navOptionUpdated
      }
    } else {
      return navOption
    }
  }

  const pypFilter = (user) => (navOption) => {
    if (navOption && navOption.value === "pyp") {
      if (
        user.programs &&
        user.programs.some(
          (program) =>
            program.name === "Pursue Your Purpose" &&
            program.pivot.status === "approved"
        )
      ) {
        return navOption
      }
    } else {
      return navOption
    }
  }

  const networkFilter = (user) => (navOption) => {
    if (navOption && navOption.value === "network") {
      if (
        user.programs &&
        user.programs.some(
          (program) =>
            program.name === "Network" && program.pivot.status === "approved"
        )
      ) {
        return navOption
      }
    } else {
      return navOption
    }
  }

  const dlFilter = (user) => (navOption) => {
    if (navOption && navOption.value === "digitalLibrary") {
      if (
        user.programs &&
        user.programs.some(
          (program) =>
            program.name === "Digital Library" &&
            program.pivot.status === "approved"
        )
      ) {
        return navOption
      }
    } else {
      return navOption
    }
  }

  const analyticsFilter = (user) => (navOption) => {
    if (navOption && navOption.value === "analytics") {
      if (user.role && user.role.id === 1) {
        return navOption
      }
    } else {
      return navOption
    }
  }

  const runFilledMiddleware = (...funcs) => {
    return (value) => {
      return funcs.reduceRight((acc, func) => {
        return acc.map(func)
      }, value)
    }
  }

  const finalOptions = runFilledMiddleware(
    pypFilter(user),
    spouse_filter(user),
    dlFilter(user),
    analyticsFilter(user),
    networkFilter(user),
    tierFilter(user)
  )(navOptions)

  if (finalOptions[0]) {
    finalOptions[0].style = { marginLeft: "25px", marginRight: "0px" }
  }

  const clickHandler = (e, route) => {
    e.preventDefault()
    history.push(route)
  }

  const toggleHiddenDisplay = (toDisplay) => {
    if (hiddenDisplayLock === false) {
      toggleHovered(toDisplay)
    }
  }

  const toggleHiddenDisplayLock = () => {
    if (hiddenDisplayLock === false) {
      toggleHovered(true)
      toggleHiddenLock(true)
    } else {
      toggleHovered(false)
      toggleHiddenLock(false)
    }
  }

  const closeHiddenMenuOption = () => {
    toggleHiddenLock(false)
    toggleHovered(false)
  }

  const browserRedirect = (e, route) => {
    history.location.pathname === route
      ? console.log("Already on that page")
      : history.push(route)
  }

  const userHasAccess = finalOptions.filter(
    (option) => option && option.value === "digitalLibrary"
  )
  return (
    <section style={styles.navContentContainer}>
      {userHasAccess.length ? (
        <Link aria-label="Go to home" to="/home" style={styles.logo}>
          <Logo />
        </Link>
      ) : (
        <Link aria-label="Go to login" to="/account/login" style={styles.logo}>
          <Logo />
        </Link>
      )}
      {user.programs && (
        <ArrayStyleHandlerByUrl
          buttonStyle={styles.navButtonStyle}
          selectedStyle={styles.selectedStyle}
          unselectedStyle={styles.unselectedStyle}
          key={user.id}
          listItems={finalOptions}
          clickHandler={(e, route) => clickHandler(e, route)}
        />
      )}
      <section
        className="navItemWithDrop"
        style={styles.nameCard}
        onMouseEnter={() => toggleHiddenDisplay(true)}
        onMouseLeave={() => toggleHiddenDisplay(false)}
      >
        {" "}
        {user.profile_image_url ? (
          <img
            alt="profile"
            src={user.profile_image_url}
            style={styles.thumbnailImage}
            onClick={(e) =>
              browserRedirect(e, userHasAccess.length ? "/home" : "")
            }
          />
        ) : (
          user.id && (
            <Person
              style={styles.defaultUserStyles}
              onClick={(e) =>
                browserRedirect(e, userHasAccess.length ? "/home" : "")
              }
            />
          )
        )}
        <OutsideClickWrapper clickHandler={() => closeHiddenMenuOption()}>
          <div
            // TODO: proposed solution div with onclick (check to see if allowds for tabular navigation for screen reader)
            // href="#"
            tabIndex="0"
            aria-label="more navigation items"
            onClick={() => {
              toggleHiddenDisplayLock()
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                toggleHiddenDisplay(!nameTagHovered)
              }
            }}
          >
            <p
              style={styles.userName}
              onClick={() => toggleHiddenDisplayLock()}
            >
              {user.firstName}
            </p>
          </div>
        </OutsideClickWrapper>
        <section
          className="navDropDownSection"
          style={{
            ...styles.hiddenCard,
            marginTop: hiddenDisplayLock ? "12px" : "",
            visibility: nameTagHovered ? "visible" : "hidden",
            opacity: nameTagHovered ? 1 : 0
          }}
        >
          <CardArrayHandler
            listItems={
              userHasAccess.length
                ? hiddenButtons
                : hiddenButtons.filter((button) => {
                    if (button.text !== "Home") {
                      return button
                    }
                    return null
                  })
            }
            style={styles.hcButton}
          />
        </section>
      </section>
    </section>
  )
}

const styles = {
  logo: {
    marginRight: "auto"
  },
  defaultUserStyles: {
    fill: "#fff",
    backgroundColor: "#212D51",
    borderRadius: "100%",
    padding: "3px",
    width: "29px",
    height: "29px"
  },
  underlineStyle: {
    width: "113px",
    height: "2px",
    border: "1px solid #E8E8E8",
    margin: "auto"
  },
  hcButton: {
    width: "100%",
    height: "45px",
    left: "24px",
    fontFamily: "Lato-Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "15px",
    color: colors.midnight,
    border: 0,
    padding: "9px 0px 9px 24px",
    cursor: "pointer",
    textAlign: "left",
    backgroundColor: colors.white
  },
  hiddenCard: {
    position: "absolute",
    width: "161px",
    height: "fit-content",
    paddingTop: "10px",
    paddingBottom: "10px",
    right: "17px",
    top: "60px",
    background: colors.white,
    boxShadow: "0px 10px 30px rgba(0,0,0,0.2)",
    textAlign: "center",
    visibility: "visible"
  },
  userName: {
    minWidth: "20px",
    width: "fit-content",
    marginLeft: "15px",
    minHeight: "23px",
    fontFamily: "Lato-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "17px",
    lineHeight: "20px",
    alignItems: "center",
    textAlign: "right",
    color: colors.softGray,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    paddingTop: "10px",
    paddingBottom: "10px"
  },
  thumbnailImage: {
    height: "29px",
    width: "29px",
    borderRadius: "18px"
  },
  nameCard: {
    marginLeft: "15px",
    marginRight: "33px",
    display: "flex",
    alignItems: "center",
    height: "100%",
    cursor: "pointer"
  },
  navOptions: {
    display: "flex",
    height: "17px"
  },
  navContentContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "20px"
  },
  navContentLeftContainer: {
    display: "flex"
  },
  navButtonStyle: {
    minWidth: "75px",
    width: "fit-content",
    height: "100%",
    left: "2px",
    top: "0px",
    fontFamily: "Lato-Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "17px",
    lineHeight: "20px",
    display: "flex",
    textAlign: "center",
    border: "0",
    padding: "0",
    color: colors.softGray,
    background: "none",
    alignItems: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    float: "right"
  },
  selectedStyle: {
    fontWeight: "bold",
    marginTop: "3px",
    borderBottom: `3px solid ${colors.fadedRed}`,
    height: "calc(100% - 3px)"
  },
  unselectedStyle: {
    fontWeight: "normal",
    borderBottomColor: colors.midnight
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const { currentInputData } = state.application
  const tempDl =
    state.user.user.programs &&
    state.user.user.programs.find(
      (program) => program.name === "Digital Library"
    )
  return {
    ...props,
    fetchingUser: state.user.fetchingUser,
    user: state.user.user || null,
    dlAccess: tempDl && tempDl.status === "approved",
    currentInputData
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GlobalDesktopNavBar)
)
