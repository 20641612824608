import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"
import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"

const FocusedVideoSidebar = (props) => {
  useEffect(() => {
    props.currentVideo.title && props.fetchSidebarVideos(props.currentVideo)
  }, [props.currentVideo.title])

  return (
    <>
      <h2
        className="thumbnailContainerTitle thumbnailTitleDescriptionWrapper"
        style={styles.titleStyles}
      >
        Related Videos
      </h2>
      <VideoThumbnailList
        from="focusedVideoSidebar"
        finishLoading={props.finishLoading}
        videoListTitle="Related Videos"
        videos={props.videos}
      />
    </>
  )
}

const styles = {
  titleStyles: {
    fontFamily: "Lato-Bold",
    fontSize: "17px",
    marginBottom: "15px",
    letterSpacing: "0",
    color: colors.midnight,
    width: "inherit"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    videos: state.digitalLibrary.videos.allIds.map(
      (videoId) => state.digitalLibrary.videos.byId[videoId]
    ),
    currentVideo: state.digitalLibrary.currentVideo
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FocusedVideoSidebar)
)
