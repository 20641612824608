import React, { useRef, useState, useEffect } from "react"
import VideoTitleDescriptionThumbnail from "../VideoTitleDescriptionThumbnail/VideoTitleDescriptionThumbnail"
import "./ThumbnailContainer.css"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
import { gtmClickHandler } from "../../Utils/gtm"
import { uppercasify, despacify } from "../../Utils/urlParsingUtilities"
import { useResizeThumbnailCalculation } from "../../Hooks/useResizeThumbnailCalculation"

let counter = 0

const VideoThumbailListResizeAdjusting = (props) => {
  const [videosLoaded, setVideosLoaded] = useState(false)
  const listRef = useRef(null)
  const thumbnailLimitData = useResizeThumbnailCalculation(
    props.dynamicThumbnailCountOnResize
  )

  useEffect(() => {
    if (props.playlist === "My Playlist") {
      props.sendVideoDisplayCount(
        thumbnailLimitData.numOfThumbnails,
        props.playlist
      )
    }
    if (props.alwaysSendNumbers) {
      props.sendVideoDisplayCount(
        thumbnailLimitData.numOfThumbnails,
        props.playlist
      )
    }
  }, [props.playlist])

  useEffect(() => {
    counter = 0
  }, [props.match.url])

  const navigateToVideo = (e) => {
    if (e.currentTarget.dataset.videoid) {
      e.preventDefault()
      props.clearReduxVideos()
      handleGTMEvent(e.currentTarget.dataset.videoid)
    }
  }

  const handleGTMEvent = (videoId) => {
    switch (props.from) {
      case "focusedVideoSidebar":
        break
      case "listOfPlaylists":
        gtmClickHandler({
          name: `${uppercasify(despacify(props.playlist))}ClickedEvent`,
          data: {
            action: `${
              props.playlist.charAt(0).toUpperCase() + props.playlist.slice(1)
            } Clicked`,
            category: "Video Category",
            label: props.videos.find(
              (video) => video.id.toString() === videoId.toString()
            ).title
          }
        })
        break
      case "landingPage":
        break
      case "listWithFilters":
        break
      default:
        console.log("no gtm click event occurred for the thumbnail")
    }
  }

  const onLoad = () => {
    counter++
    if (counter === props.videos.length) {
      setVideosLoaded(true)
      props.finishLoading && props.finishLoading()
    }
  }

  if (props.loaded && !props.videos.length) {
    return (
      <span
        style={{
          display: "flex",
          width: "100%",
          color: colors.midnight,
          height: "200px",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {props.noVideosMessage || "No Videos Found"}
      </span>
    )
  }

  let videos = thumbnailLimitData
    ? props.videos.slice(0, thumbnailLimitData.numOfThumbnails)
    : props.videos

  if (!videos.length) {
    videos = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  }

  let from = props.from

  const videoArray = videos.map((video, i) => {
    return (
      <li
        style={
          thumbnailLimitData && thumbnailLimitData.width > 1099
            ? {
                ...styles.listItem,
                width: props.setWidth,
                height: props.setHeight,
                margin: props.margin || "5px"
              }
            : { ...styles.listItem }
        }
        key={video ? `${video.id}${i}` : i}
        onClick={navigateToVideo}
        data-videoid={video.id}
        className={from === 'focusedVideoSidebar' ? "thumbnailTitleDescriptionWrapperSidebar" : "thumbnailTitleDescriptionWrapper" }
      >
        {video.id ? (
          <Link
            style={{ textDecoration: "none" }}
            to={`/digitalLibrary/watch?videoId=${video.id}`}
          >
            <VideoTitleDescriptionThumbnail
              video={video}
              onLoad={onLoad}
              renderSkeletonLoaders={videosLoaded}
            />
          </Link>
        ) : (
          <VideoTitleDescriptionThumbnail
            video={video}
            onLoad={onLoad}
            renderSkeletonLoaders={videosLoaded}
          />
        )}
      </li>
    )
  })

  return (
    <ul
      aria-label="Video thumbnail lists, categorized by video type"
      ref={listRef}
      onClick={navigateToVideo}
      style={{
        ...styles.list,
        ...props.style
      }}
      className={from === 'focusedVideoSidebar' ? "thumbnailContainerList" : ""}
    >
      {videoArray}
    </ul>
  )
}

const styles = {
  list: {
    justifyContent: "center",
    listStyleType: "none",
    flexWrap: "wrap",
    width: "100%",
    display: "flex",
    margin: "0px",
    padding: "0px"
  },
  listItem: {
    height: "inherit",
    width: "inherit",
    margin: "5px",
    cursor: "pointer"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoThumbailListResizeAdjusting)
)
