import React from "react"
import colors from "../../Constants/colors.js"
import "./AccountFormContainer.css"
import { withPaper } from "../WithPaper/WithPaper"

const AccountFormContainer = (props) => {
  return (
    <div
      className="commitFormContainer"
      style={{
        ...styles.componentContainer,
        ...props.style
      }}
    >
      {props.children}
    </div>
  )
}

const styles = {
  componentContainer: {
    paddingTop: "50px",
    paddingBottom: "50px",
    width: "100%",
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  }
}

export default withPaper(AccountFormContainer)
