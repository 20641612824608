import React from "react"
import { DrawerConsumer } from "./DrawerContext"

export const WithDrawerConsumer = (BaseComponent) => (props) => {
  return (
    <DrawerConsumer>
      {(context) => <BaseComponent {...props} {...context} />}
    </DrawerConsumer>
  )
}
