import React, { memo } from "react"
import moment from "moment"
import { Seen } from "../../Icons/Seen"
import { Play } from "../../Icons/Play"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import "./ReactVideoThumbnail.css"

const buildDurationString = (duration) => {
  const secondsInt = parseInt(duration)
  if (secondsInt >= 3600) {
    return moment().startOf("day").seconds(duration).format("hh:mm:ss")
  }
  return moment().startOf("day").seconds(duration).format("mm:ss")
}

class ReactVideoThumbnail extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false
    }
    this.load = this.load.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.img !== this.props.img) {
      this.setState({
        loaded: false
      })
    }
  }

  load() {
    this.props.onLoad && this.props.onLoad()
    this.setState({
      loaded: true
    })
  }

  render() {
    return (
      //  <LazyLoad height={window.innerWidth > 500 ? 150 : 175} offsetVertical={500}>
      <div
        className={this.state.loaded ? "dlThumb loaded" : "dlThumb"}
        title={this.props.screenReaderTitle}
        style={{
          ...styles.imageContainer,
          ...this.props.style
        }}
      >
        <div
          style={{
            display: this.state.loaded ? "block" : "none",
            width: "inherit"
          }}
        >
          <img
            alt={this.props.title}
            width="100%"
            height="100%"
            src={this.props.img}
            className="imdg"
            onLoad={this.load}
          />
        </div>
        <div
          className="dlThumbSkeleton"
          style={{
            width: "100%",
            display: this.state.loaded ? "none" : "block"
          }}
        >
          <SkeletonWrapper theme="light" height={130} width={230}>
            <rect x="0" y="0" rx="0" ry="0" width="230" height={160} />
          </SkeletonWrapper>
        </div>

        <div style={styles.videoMetaDataContainer} className="videoMetaData">
          {this.props.userHasSeenVideo ? (
            <span style={styles.statusWrapper}>
              <p style={styles.statusText}>Seen</p>
              <Seen />
            </span>
          ) : (
            <span style={styles.statusWrapper}>
              <p style={styles.statusText}>Watch</p>
              <Play />
            </span>
          )}
          <p style={{ marginRight: "10px" }}>
            {this.props.durationInSeconds &&
              buildDurationString(this.props.durationInSeconds)}
          </p>
        </div>
      </div>
      //  </LazyLoad>
    )
  }
}

const styles = {
  statusWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  statusText: {
    marginRight: "3px"
  },
  imageContainer: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    flexDirection: "center",
    justifyContent: "center",
    alignItems: "flex-end",
    fontSize: "30px",
    lineHeight: "30px",
    letterSpacing: 0
    // backgroundBlendMode: "multiply, normal",
    // backgroundSize: "cover"
  },
  videoMetaDataContainer: {
    display: "flex",
    position: "absolute",
    zIndex: 9,
    width: "inherit",
    justifyContent: "space-between",
    alignItems: "flex-end",
    paddingLeft: "8px",
    lineHeight: "23px",
    fontSize: "10px",
    color: "#fff",
    fontFamily: "Lato-light"
  }
}

export default memo(ReactVideoThumbnail)
