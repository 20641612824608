import React from "react"
// import PropTypes from 'prop-types'
import colors from "../../Constants/colors"

const WithLeftBorder = (props) => {
  const isSelectedStyle = props.isSelected && styles.selected
  return (
    <div style={isSelectedStyle || null}>
      {props.renderComponent({
        ...props
      })}
    </div>
  )
}

const styles = {
  selected: {
    borderLeft: `solid 4px ${colors.fadedRed}`
  }
}

export default WithLeftBorder
