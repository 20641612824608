import React from "react"
import { withStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import colors from "../../Constants/colors"
import "../NavItem/NavItem"
import { WithBottomBorder } from "../WithBottomBorder/WithBottomBorder"
import "./DesktopNavItem.css"

// TODO: get this as a constant
const baseUrl = "https://www.commitfoundation.org"

const styleSheet = {
  root: {
    width: "100%",
    backgroundColor: colors.midnight,
    marginTop: "60px"
  },
  test: {
    color: colors.white,
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    lineHeight: "33px",
    letterSpacing: "0.8px"
  }
}

class DesktopNavItem extends React.Component {
  constructor() {
    super()
    this.state = {
      test: true
    }
  }

  render() {
    const selectedStyle = this.props.isSelected && { fontFamily: "Lato-Bold" }
    if (this.props.popoverOptions) {
      return (
        <ul className="nav" style={{ ...styles.navItem }}>
          <li
            className="drop"
            onMouseEnter={() => {
              this.setState({
                test: "block"
              })
            }}
          >
            <div className="barf">{this.props.children}</div>
            <ul
              style={{
                paddingTop: "20px",
                paddingBottom: "15px",
                display: this.state.test
              }}
              className={`dropdown-${this.props.id}`}
            >
              {this.props.popoverOptions.map((option, key) => {
                const borderBottom =
                  key + 1 < this.props.popoverOptions.length
                    ? styles.bottomBorder
                    : {}
                return (
                  <div
                    key={key}
                    className="webDropdownItem"
                    style={styles.webDropdownItemAnchor}
                  >
                    <div
                      style={{ ...styles.webDropdownItemText, ...borderBottom }}
                    >
                      <a
                        style={{ textDecoration: "none", color: "inherit" }}
                        href={`${baseUrl}/${option.link}`}
                      >
                        {option.title}
                      </a>
                    </div>
                  </div>
                )
              })}
            </ul>
          </li>
        </ul>
      )
    }
    return (
      <ListItem
        className="nav__menu-item"
        style={{ ...styles.navItem, ...selectedStyle }}
        onClick={this.props.clickHandler}
      >
        {this.props.children}
      </ListItem>
    )
  }
}
const styles = {
  bottomBorder: {
    borderBottom: "1px solid #e8e8e8"
  },
  webDropdownItemText: {
    display: "flex",
    fontWeight: 400,
    fontFamily: "Lato-Regular",
    paddingBottom: "15px",
    width: "70%"
  },

  webDropdownItemAnchor: {
    paddingTop: "10px",
    paddingBottom: "0px",
    // display: "block",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    clear: "both",
    fontSize: "16px",
    // textAlign: "left",
    color: "#212529",
    textAlign: "center",
    whiteSpace: "nowrap",
    border: "0px"
  },
  applyButton: {
    backgroundColor: colors.white,
    color: colors.mediumBlue,
    fontFamily: "Lato-Semibold",
    fontSize: "16px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "5px",
    paddingTop: "5px",
    margin: "auto",
    lineHeight: "24px",
    marginLeft: "1rem"
  },
  navItem: {
    color: colors.white,
    height: "100%",
    fontFamily: "Lato-Semibold",
    fontSize: "18px",
    whiteSpace: "nowrap",
    cursor: "pointer",
    alignItems: "center"
  },
  componentContainer: {
    backgroundColor: colors.midnight,
    padding: "0px",
    height: "60px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  logoContainer: {
    width: "35%",
    display: "flex",
    justifyContent: "center"
  },
  rightContent: {
    width: "65%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: "10%"
  }
}

export default withStyles(styleSheet)(WithBottomBorder(DesktopNavItem))
