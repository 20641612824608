import React from "react"
import colors from "../../Constants/colors"

export const withPaper = (BaseComponent) => (props) => {
  return (
    <div style={{ ...styles.componentContainer, ...props.paperStyle }}>
      <BaseComponent {...props} />
    </div>
  )
}

const styles = {
  componentContainer: {
    boxShadow: "0 2px 20px 0 rgba(0, 0, 0, 0.05)",
    backgroundColor: colors.white
  }
}
