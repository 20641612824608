import React from "react"

const MobileMenuBottomButtonBar = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "9vh",
        bottom: "0px",
        zIndex: 1,
        position: "absolute"
      }}
    >
      {props.children}
      {/* <ApplicationSubmitButton  submitState={'unfinished'}/> */}
      {/* <CommitButtonWithClickState
      clickHandler={props.clickHandler}
      type="red"
    >
      {props.children || "Save and Exit"}
    </CommitButtonWithClickState> */}
    </div>
  )
}

export default MobileMenuBottomButtonBar
