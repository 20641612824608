const types = {
  ERROR: "ERROR",
  RESET_ERRORS: "RESET_ERRORS",
  OPEN_ERROR_MODAL: "OPEN_ERROR_MODAL",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  EXCEPTION: "EXCEPTION",
  FETCHING_USER: "FETCHING_USER",
  FETCHED_USER: "FETCHED_USER",
  SET_API_KEY: "SET_API_KEY",
  CLEAR_API_KEY: "CLEAR_API_KEY",
  UPDATE_PATH_HISTORY: "UPDATE_PATH_HISTORY",
  INCREMENT_INSTRUCTION_STEP: "INCREMENT_INSTRUCTION_STEP",
  GUIDE_COMPLETE: "GUIDE_COMPLETE",
  REDIRECT_404: "REDIRECT_404",
  REDIRECT_LOGIN: "REDIRECT_LOGIN",
  APPLY_CLICKED: "APPLY_CLICKED",
  SET_LOGIN_REDIRECT: "SET_LOGIN_REDIRECT",
  REFRESHED_TOKEN: "REFRESHED_TOKEN",
  CREATING_ACCOUNT: "CREATING_ACCOUNT",
  TOGGLE_MODAL_CROP: "TOGGLE_MODAL_CROP",
  TOGGLE_MODAL: "TOGGLE_MODAL",
  SETUP_ROLLBAR: "SETUP_ROLLBAR",
  LOGGING_IN: "LOGGING_IN",
  REFRESHING_TOKEN: "REFRESHING_TOKEN",
  FETCHING_ANALYTICS: "FETCHING_ANALYTICS",
  FETCHED_ANALYTICS: "FETCHED_ANALYTICS",
  FETCHED_BUTTON_ANALYTICS: "FETCHED_BUTTON_ANALYTICS",
  FETCHED_VIDEO_ANALYTICS: "FETCHED_VIDEO_ANALYTICS",
  UPDATE_SEARCH_PARAMS: "UPDATE_SEARCH_PARAMS",
  RESET_SEARCH_PARAMS: "RESET_SEARCH_PARAMS",
  UPDATE_INTERESTS: "UPDATE_INTERESTS",
  TOGGLE_FETCHING_INTERESTS: "TOGGLE_FETCHING_INTERESTS",
  FETCHING_SEARCH_USERS: "FETCHING_SEARCH_USERS",
  SET_SEARCH_USERS: "SET_SEARCH_USERS",
  ADD_SEARCH_USERS: "ADD_SEARCH_USERS",
  UPDATING_PROFILE_THUMBNAIL: "UPDATING_PROFILE_THUMBNAIL",
  UPDATED_PROFILE_THUMBNAIL: "UPDATED_PROFILE_THUMBNAIL",
  TOGGLE_HAS_CHECKED_PROFILE: "TOGGLE_HAS_CHECKED_PROFILE"
}

export default types
