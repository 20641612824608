import React from "react"
import "./PYPDescription.css"

const PYPDescription = (props) => {
  return (
    <h3
      className={`pypDescriptionContainer ${props.className}`}
      style={{
        ...styles.description,
        color: props.theme === "light" ? "white" : "white",
        ...props.style
      }}
    >
      {props.description}
    </h3>
  )
}

const styles = {
  description: {
    fontFamily: "Lato-Regular",
    fontSize: "15px"
  }
}

export default PYPDescription
