import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import moment from "moment"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import RoadmapperDraggableContainer from "../RoadmapperDraggableContainer/RoadmapperDraggableContainer"

import Actions from "../../Redux/Actions"

/*  Component Functionality
  -Pull in data for part (ideal personal/work day)
  -Aggregate data into array of time events + constraints
  -Aggregate top 10 values
*/

const styles = {
  container: {
    alignItems: "center",
    textAlign: "center"
  },
  layoutContainer: {
    justifyContent: "start"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let timeEvents = null
  let eventOrder = null
  let constraints = []
  let buttonValues = []

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data

  if (partData) {
    metadata = partData.partTitle[0]
    eventOrder = partData.eventOrder.sequence
    constraints = {
      start: moment(partData.referencedPartData.constraints[0].text, "hh:mm a"),
      end: moment(partData.referencedPartData.constraints[1].text, "hh:mm a"),
      startId: partData.referencedPartData.constraints[0].id,
      endId: partData.referencedPartData.constraints[1].id
    }
    buttonValues = partData.referencedPartData.buttonValues.filter(
      (buttonValue) => {
        return buttonValue.buttonSelections.length
      }
    )
    buttonValues.unshift({
      id: null,
      name: "Non-Value"
    })
    timeEvents = partData.userTimeEvents.map((event) => {
      return {
        ...event,
        value:
          event.value_id === null ||
          !buttonValues.filter((button) => button.id === event.value_id)[0]
            ? "Non-Value"
            : buttonValues.filter((button) => button.id === event.value_id)[0]
                .name
      }
    })
  }

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    initializedPart: state.pyp.initializedPart,
    fetchingPartData: state.pyp.fetchingPartData,
    initializingPYPInputs: state.pyp.initializingPYPInputs,
    timeEvents,
    eventOrder,
    constraints,
    buttonValues
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class LayoutMetaDataReferenceUserTimeEvents extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        timeEvents: null,
        loading: true
      }
    }

    componentDidMount() {
      if (!this.props.pyp.savingPYP) {
        this.props.fetchPartData().then(() => this.setState({ loading: false }))
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
        this.props.fetchPartData().then(() => this.setState({ loading: false }))
      }
    }

    render() {
      return (
        <PYPLayoutContainer
          backgroundColor={colors.background}
          style={{
            ...styles.layoutContainer
          }}
        >
          <PYPPartTitleContent
            title={this.props.metadata.title}
            description={this.props.metadata.description}
          />
          <div style={styles.container} id="DraggableContainer">
            <RoadmapperDraggableContainer
              values={this.props.buttonValues}
              momentFormat={this.props.momentFormat || ["h:mm a", "h:mm a"]}
              momentJoiner={this.props.momentJoiner || " - "}
              timeConstraints={
                this.props.constraints || {
                  start: moment("6:00", "hh:mm a"),
                  end: moment("20:00", "hh:mm")
                }
              }
              loading={this.state.loading}
              durationUnit={this.props.durationUnit || "minutes"}
              userTimeEvents={this.props.timeEvents}
              eventOrder={this.props.eventOrder}
            />
          </div>
        </PYPLayoutContainer>
      )
    }
  }
)
