import { buildSteps } from "../../Utils/metadataUtils"

export const spouseApplicationMetadata = [
  /* The video is not going to be ready by the time we launch application v3.
    When the time comes, just uncomment this and change the REACT_APP_APPLICATION_INTRODUCTION_VIDEO_URL env var
  */
  /*{
    id: "introduction",
    title: "Welcome",
    steps: [
      {
        stepId: "introduction-video",
        title: "Welcome",
        name: "Introduction",
        url: "/application?title=introduction-video",
        body:
          "Our application requires thoughtful reflection on who you are, what you’re considering post-service, and how you’d like COMMIT to assist you.  Please be thorough in your responses to give our team the best opportunity to partner with you. ",
        inputIds: []
      }
    ],
    inputs: []
  },*/
  {
    id: "personal-info",
    title: "Personal Info",
    steps: [
      {
        stepId: "personal_info-basic",
        title: "Personal Info",
        name: "Personal Info - Basic",
        url: "/application/spouse?title=personal_info-basic",
        body: "To begin, we'd like to get to know you a little better.",
        inputIds: [0]
      },
      {
        stepId: "personal_info-address",
        name: "Personal Info - Address",
        url: "/application/spouse?title=personal_info-address",
        title: "Personal Info - Address",
        body: "",
        id: 1,
        inputIds: [1, 2, 3, 4, 5, 6]
      },
      {
        stepId: "relocation",
        name: "Personal Info - Relocations",
        url: "/application/spouse?title=relocation",
        title: "Personal Info",
        body: "What are your top 3 locations of interest?",
        id: 1,
        inputIds: [8, 9, 10, 11, 12, 13]
      },
      {
        stepId: "personal_info-timeline",
        name:  "Personal Info - Timeline",
        url: "/application/spouse?title=personal_info-timeline",
        title: "Personal Info - Timeline",
        body: "Timeline to transition?",
        id: 7,
        inputIds: [7]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "dateOfBirth"
      },
      {
        id: 1,
        userField: "phoneNumber"
      },
      {
        id: 2,
        userField: "address.streetAddress"
      },
      {
        id: 3,
        userField: "address.city"
      },
      {
        id: 4,
        userField: "address.state"
      },
      {
        id: 5,
        userField: "address.zipCode"
      },
      {
        id: 6,
        userField: "address.country"
      },
      {
        id: 7,
        userField: "timeline"
      },
      {
        id: 8,
        userField: "address.country"
      },
      {
        id: 9,
        userField: "address.state"
      },
      {
        id: 10,
        userField: "address.city"
      },
      {
        id: 11,
        userField: "address.country"
      },
      {
        id: 12,
        userField: "address.state"
      },
      {
        id: 13,
        userField: "address.city"
      }
    ]
  },
  {
    id: "spouse-info",
    title: "Spouse Info",
    steps: [
      {
        stepId: "spouse_info-active_duty",
        name: "Spouse Info - Active Duty",
        url: "/application/spouse?title=spouse_info-active_duty",
        title: "Spouse Info - Active Duty",
        body: "Is your spouse (active duty service member) seeking/receiving services through COMMIT or has your spouse previously?",
        id: 0,
        inputIds: [0]
      },
      {
        stepId: "spouse_info-service_member_name",
        name: "Spouse Info - Spouse information",
        url: "/application/spouse?title=spouse_info-service_member_name",
        title: "Spouse Info - Spouse information",
        body: "Service Member Name",
        id: 1,
        inputIds: [1]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "active_duty"
      },
      {
        id: 1,
        userField: "service_member_name"
      },
    ]
  },
  {
    id: "military",
    title: "Military",
    steps: [
      {
        stepId: "military-initial",
        name: "Military - Service",
        url: "/application/spouse?title=military-initial",
        title: "Military - Service",
        body: "Did you serve in the Military?",
        inputIds: [9]
      },
      {
        stepId: "military-basic",
        name: "Military - Basic",
        url: "/application/spouse?title=military-basic",
        title: "Military",
        body: "We use this information to learn more about the veterans we serve.",
        inputIds: [0, 1, 2, 3, 4, 5, 6, 7, 8]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "military.status"
      },
      {
        id: 1,
        userField: "military.branch"
      },
      {
        id: 2,
        userField: "military.years_of_service"
      },
      {
        id: 3,
        userField: "military.retirementDate"
      },
      {
        id: 4,
        userField: "military.sof"
      },
      {
        id: 5,
        userField: "military.sofUnit"
      },
      {
        id: 6,
        userField: "military.lastRank"
      },
      {
        id: 7,
        userField: "military.specialty"
      },
      {
        id: 8,
        userField: "military.lastDutyPosition"
      },
      {
        id: 9,
        userField: "serve_millitary"
      }
    ]
  },
  {
    id: "biography",
    title: "My Story",
    steps: [
      {
        stepId: "biography",
        name: "Biography",
        url: "/application/spouse?title=biography",
        title: "Personal Summary",  
        body:
          "Provide a bio of yourself. The bio can include your career, if applicable, your family, your interests, other purpose work etc. (maximum 1000 characters)",
        inputIds: [0], 
      },
      {
        stepId: "biography-spouse_impact",
        title: "Impact",
        name: "Biography - Impact",
        url: "/application/spouse?title=biography-spouse_impact",
        body:
          "How do you believe your life and/or your career has been impacted by being a military family?",
        inputIds: [1]
      },
      {
        stepId: "biography-career_move",
        name: "Biography - Career Move",
        url: "/application/spouse?title=biography-career_move",
        title: "Career Move",
        body:
          "How do you believe your spouse’s next career move will impact the decisions you make for your future purpose work or career?",
        inputIds: [7]
      },
      {
        stepId: "biography-inspiration",
        name: "Biography - Inspiration",
        url: "/application/spouse?title=biography-inspiration",
        title: "Inspiration",
        body:
          "What inspired you to apply for the Family Transition Program",
        inputIds: [2]
      },
      {
        stepId: "biography-challenge",
        name: "Biography - Challenges",
        url: "/application/spouse?title=biography-challenge",
        title: "Challenges",
        body:
          "What do you see as your biggest challenges to this next move?",
        inputIds: [3]
      },
      {
        stepId: "biography-military_life",
        name: "Biography - Military Life",
        url: "/application/spouse?title=biography-military_life",
        title: "Military Life",
        body:
          "What about your military life would you like to carry forward?",
        inputIds: [4]
      },
      {
        stepId: "biography-top_priorities",
        name: "Biography - Top Priorities",
        url: "/application/spouse?title=biography-top_priorities",
        title: "Top Priorities",
        body:
          "What are your top five priorities for your first year of post-military life?",
        inputIds: [5]
      },
      {
        stepId: "biography-motivates",
        name: "Biography - Motivates",
        url: "/application/spouse?title=biography-motivates",
        title: "Motivates",
        body:
          "What motivates you?",
        inputIds: [6]
      },
      {
        stepId: "biography-careers_purpose",
        name: "Career Purpose",
        url: "/application/spouse?title=biography-careers_purpose",
        title: "Career Purpose",
        body: "What is your Career or Life Purpose?",
        inputIds: [8]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "biography.biography"
      },
      {
        id: 1,
        userField: "biography.impact"
      },
      {
        id: 2,
        userField: "biography.inspiration"
      },
      {
        id: 3,
        userField: "biography.challenge"
      },
      {
        id: 4,
        userField: "biography.military_life"
      },
      {
        id: 5,
        userField: "biography.priorities"
      },
      {
        id: 6,
        userField: "biography.motivations"
      },
      {
        id: 7,
        userField: "biography.next_impact"
      },
      {
        id: 8,
        userField: "biography.careers_purpose"
      }
    ]
  },
  {
    id: "career",
    title: "Career",
    filled: false,
    steps: [
      {
        stepId: "career-career_setting",
        name: "Career - Career Setting",
        url: "/application/spouse?title=career-career_setting",
        title: "Life/Career Settings",
        body: "What type of setting(s) are you interested in? Check all that apply:",
        id: 0,
        inputIds: [0]
      },
      {
        stepId: "transition-goals",
        name: "Transition - Goals",
        url: "/application/spouse?title=transition-goals",
        title: "Transition",
        body: "What are your top three goals for working with COMMIT?",
        id: 1,
        inputIds: [11]
      },
      {
        stepId: "career-strengths",
        name: "Career - Strengths and Skills",
        url: "/application/spouse?title=career-strengths",
        title: "Strengths and Skills",
        body:
          "What strengths and skills will you bring to your next career/ purpose work?",
        inputIds: [2]
      },
      {
        stepId: "career-preference",
        name: "Career - Preferences",
        url: "/application/spouse?title=career-preference",
        title: "Preferences",
        body:
          "Use the strongly agree; agree; neutral; disagree; strongly disagree scale for all of the questions following",
        inputIds: [8,9,10]
      },
      {
        stepId: "career-misc",
        name: "Career - Misc",
        url: "/application/spouse?title=career-misc",
        title: "Miscellaneous",
        body:
          "Use the strongly agree; agree; neutral; disagree; strongly disagree scale for all of the questions following",
        inputIds: [3,4,5,6,7]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "career.career_setting"
      },
      {
        id: 1,
        userField: "career.interests",
        optional: true
      },
      {
        id: 2,
        userField: "biography.strengths_skills"
      },
      {
        id: 3,
        userField: "career.next_step_confiedence"
      },
      {
        id: 4,
        userField: "career.lifestyle_options"
      },
      {
        id: 5,
        userField: "career.adequate_information"
      },
      {
        id: 6,
        userField: "career.network"
      },
      {
        id: 7,
        userField: "career.apply_skills"
      },
      {
        id: 8,
        userField: "career.time_commit"
      },
      {
        id: 9,
        userField: "career.share_consent"
      },
      {
        id: 10,
        userField: "career.class_preference"
      },
      {
        id: 11,
        userField: "transition.goals"
      }
    ]
  },
  {
    id: "submission",
    title: "Submission",
    steps: [
      {
        stepId: "submission",
        name: "Submission",
        url: "/application/spouse?title=submission",
        title: "Submission",
        body:
          "Our services are highly individualized and we would like to better understand you. Please use the following space to add additional information that you believe will impact your transition and your work with COMMIT. Some examples are why are you applying to COMMIT, why are you leaving military service, what motivates you, what do you want to carry forward or leave behind from your military life?",
        id: 0,
        inputIds: [0]
      }
    ],
    inputs: [
      {
        id: 0,
        userField: "submission.extraInfo"
      }
    ]
  }
]

export const spouseApplicationSteps = buildSteps(spouseApplicationMetadata)
