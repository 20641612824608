import React from "react"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Actions from "../../Redux/Actions"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    modal: state.modal.modal
  }
}

const DialogContent = MuiDialogContent

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class ModalDialogHandler extends React.Component {
    static defaultProps = {
      disableBackdropClick: false,
      showCloseButton: false
    }

    constructor(props) {
      super(props)
      this.state = {
        open: false,
        loaded: false
      }
      this.handleClose = this.handleClose.bind(this)
    }

    handleClose = () => {
      this.props.toggleHasCheckedProfile(true);
      this.props.toggleModal({
        modal_mode: false,
        modal_usi: this.props.modal_usi
      })
      // this.setState({ open: false, loaded: false })
    }

    componentDidUpdate() {
      if (
        this.state.loaded === false &&
        this.props.modal[this.props.modal_usi]
      ) {
        // this.setState({ open: this.props.modal_usi, loaded: true })
      }
    }

    componentDidMount() {
      if (
        this.state.loaded === false &&
        this.props.modal[this.props.modal_usi]
      ) {
        // this.setState({ open: this.props.modal_usi, loaded: true })
      }
    }

    render() {
      return (
        <div style={this.props.style}>
          <Dialog
            style={{ padding: "0px", margin: "0px" }}
            onClose={this.handleClose}
            aria-labelledby="customized-dialog-title"
            open={this.props.modal[this.props.modal_usi] || false}
            PaperProps={this.props.paperProps}
            disableBackdropClick={this.props.disableBackdropClick}
          >
            {this.props.showCloseButton && (
              <IconButton
                aria-label="Close"
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  color: "#9e9e9e"
                }}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            )}
            <DialogContent style={{ padding: "0px" }}>
              {this.props.children}
            </DialogContent>
            {this.props.footer}
          </Dialog>
        </div>
      )
    }
  }
)
