import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import Actions from "../../Redux/Actions"

const styles = {
  modalOverlay: {
    padding: "30px",
    width: "400px"
  },
  innerText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px"
  }
}

const NetworkDisabledModal = (props) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user && state.user.user)

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: "networkDisabled"
      })
    )
  }

  const navigateToProfile = () => {
    closeModal()

    props.history.push("/profile?title=professional-basic")
  }

  const msgNum =
    (user.community && user.community.community_member === 0) ||
    !user.community ||
    !user.community.community_member
      ? user.linkedin_url
        ? "no community"
        : "neither"
      : "no linkedin"

  const noAccessText = {
    "no community": (
      <>Select 'Yes' to 'Opt-In' on the settings page of your profile.</>
    ),
    "no linkedin": (
      <>
        Copy and paste your linkedin URL into the 'LinkedIn URL' field on the
        'Personal Info' page of your profile.
      </>
    ),
    neither: (
      <>
        Select 'Yes' to 'Opt-In' on the settings page of your profile.
        <br />
        <br />
        Copy and paste your linkedin URL into the 'LinkedIn URL' field on the
        'Personal Info' page of your profile.
      </>
    )
  }

  return (
    <ModalDialogHandler
      // style={styles.modalOverlay}
      modal_usi="networkDisabled"
      footer={
        <DialogActions>
          <CommitButtonWithoutClickState
            clickHandler={closeModal}
            id="network disabled reminder"
            type="white"
            style={{
              width: "164px",
              borderRadius: "3px",
              marginLeft: "26x",
              marginBottom: "20px"
            }}
          >
            Got It!
          </CommitButtonWithoutClickState>
          <CommitButtonWithoutClickState
            clickHandler={navigateToProfile}
            id="network disabled profile"
            type="red"
            style={{
              width: "164px",
              marginLeft: "20x",
              marginRight: "26px",
              marginBottom: "20px"
            }}
          >
            Edit Profile
          </CommitButtonWithoutClickState>
        </DialogActions>
      }
    >
      <div style={styles.modalOverlay}>
        <p style={styles.innerText}>
          Before accessing COMMIT's Online Network you must configure the
          following:
          <br />
          <br />
          {noAccessText[msgNum]}
        </p>
      </div>
    </ModalDialogHandler>
  )
}

export default withRouter(NetworkDisabledModal)
