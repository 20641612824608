import React from "react"
import TextField from "@material-ui/core/TextField"
import FormHelperText from "@material-ui/core/FormHelperText"
import { withStyles } from "@material-ui/core/styles"
import colors from "../../Constants/colors.js"

const styleSheet = {
  text: {
    fontFamily: "Lato-Regular",
    color: colors.text
  },
  inputFocused: {
    border: "0px"
  }
}

const LongTextInput = (props) => {
  return (
    <>
      <TextField
        value={props.value || ""}
        InputProps={{
          classes: {
            root: props.classes.text
          }
        }}
        variant="outlined"
        multiline
        placeholder={props.placeholder || ""}
        rows="14"
        error={props.error && props.renderError}
        style={styles.input}
        onChange={props.handleChange}
      />
      <div
        style={{
          width: "100%",
          height: "5px",
          marginTop: "2px"
        }}
      >
        {props.error ? (
          <FormHelperText
            error
            id="component-error-text"
            style={{ color:"#fff", marginTop: "0px" }}
          >
            Required
          </FormHelperText>
        ) : null}
      </div>
    </>
  )
}

const styles = {
  componentContainer: {
    width: "100%",
    backgroundColor: colors.white,
    borderRadius: "4px",
    border: "solid 0.5px #e8e8e8"
  },
  input: {
    width: "100%",
    backgroundColor: colors.white,
    color: colors.lightGray
  }
}

export default withStyles(styleSheet)(LongTextInput)
