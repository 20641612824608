import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import colors from "../../Constants/colors.js"
import Actions from "../../Redux/Actions"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { GrayUpload } from "../../Icons/GrayUpload"

export class VideoUploader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadingFile: false,
      percentageComplete: 0
    }
  }

  render() {
    return (
      <AccountFormContainer
        style={{
          paddingRight: "0px",
          paddingLeft: "0px",
          paddingBottom: "0px"
        }}
      >
        <p
          style={{
            color: colors.fadedRed,
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "Lato-Bold"
          }}
        >
          {" "}
          {this.props.uploadError && this.props.uploadError}{" "}
        </p>
        <GrayUpload />
        <label
          htmlFor="file-upload"
          style={{
            cursor: "pointer",
            marginTop: "75px",
            color: colors.midnight,
            fontFamily: "Lato-Medium",
            textAlign: "center",
            fontSize: "25px",
            lineHeight: "31px"
          }}
        >
          Click Me
        </label>
        <p
          style={{
            fontFamily: "Lato-Regular",
            fontSize: "16px",
            lineHeight: "31px",
            textAlign: "center",
            marginBottom: "50px",
            color: colors.gray
          }}
        >
          {" "}
          to upload a video
        </p>
      </AccountFormContainer>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    user: state.user.user
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoUploader)
)
