import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import Actions from "../../Redux/Actions"
import TimeConstraintSelector from "../TimeConstraintSelector/TimeConstraintSelector"

const styles = {
  constraintsContainer: {
    color: colors.midnight,
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "150px",
    maxWidth: "800px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  layoutContainer: {
    justifyContent: "start"
  },
  startingConstraint: {
    width: "250px",
    marginRight: "auto"
  },
  endingConstraint: {
    width: "250px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let constraints = []
  let calender = false

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.partTitle[0]
    constraints = partData.constraints
  }
  if (currentPart && currentPart.name === "Action Plan Constraints")
    calender = true

  return {
    ...props,
    calender,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    initializedPart: state.pyp.initializedPart,
    fetchingPartData: state.pyp.fetchingPartData,
    initializingPYPInputs: state.pyp.initializingPYPInputs,
    constraints
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class LayoutMetaDataConstraints extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        startingConstraint:
          this.props.constraints &&
          this.props.constraints[0] &&
          this.props.constraints[0].text,
        endingConstraint:
          this.props.constraints &&
          this.props.constraints[1] &&
          this.props.constraints[1].text,
        loaded: false
      }
      this.updateTimeConstraints = this.updateTimeConstraints.bind(this)
      this.convertAMPM = this.convertAMPM.bind(this)
      this.reconvertAMPM = this.reconvertAMPM.bind(this)
    }

    componentDidMount() {
      if (!this.props.pyp.savingPYP) {
        this.props.fetchPartData().then(() => this.setState({ loaded: true }))
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
        this.props.fetchPartData().then(() => this.setState({ loaded: true }))
      }
      if (
        !prevState.startingConstraint &&
        !this.state.startingConstraint &&
        this.props.constraints[0]
      ) {
        this.setState({
          startingConstraint: this.props.constraints[0].text,
          endingConstraint: this.props.constraints[1].text,
          loaded: true
        })
      }
    }

    convertAMPM = (time, AMorPM) => {
      let convertedTime = ""
      AMorPM === "am"
        ? (convertedTime = `0${time.split("am")[0]}`)
        : (convertedTime = `${
            parseInt(time.split("pm")[0].split(":")[0]) + 12
          }:${time.split("pm")[0].split(":")[1]}`)
      if (convertedTime.length === 6) convertedTime = convertedTime.substr(1)
      if (convertedTime.length > 5)
        convertedTime =
          AMorPM === "am"
            ? convertedTime.split("pm")[0].substr(1)
            : `${parseInt(convertedTime.split("am")[0].split(":")[0])}:${
                convertedTime.split("am")[0].split(":")[1]
              }`
      return convertedTime
    }

    reconvertAMPM = (time, AMorPM) => {
      let convertedTime = ""
      AMorPM === "am"
        ? (convertedTime = `${parseInt(time.split(":")[0])}:${
            time.split(":")[1]
          }${parseInt(time.split(":")[0]) === 12 ? "pm" : "am"}`)
        : (convertedTime = `${parseInt(time.split(":")[0]) - 12}:${
            time.split(":")[1]
          }${parseInt(time.split(":")[0]) === 24 ? "am" : "pm"}`)
      return convertedTime
    }

    updateTimeConstraints = (value, AMorPM) => {
      let constraint1 = this.state.startingConstraint
      let constraint2 = this.state.endingConstraint
      AMorPM === "am"
        ? (constraint1 = this.props.calender
            ? value
            : this.convertAMPM(value, "am"))
        : (constraint2 = this.props.calender
            ? value
            : this.convertAMPM(value, "pm"))

      this.setState({
        startingConstraint: constraint1,
        endingConstraint: constraint2
      })
      if (constraint1 && constraint2) {
        this.props.storeTimeConstraints({ constraint1, constraint2 })
      }
    }

    render() {
      return (
        <PYPLayoutContainer
          backgroundColor={colors.background}
          style={{ ...this.props.style, ...styles.layoutContainer }}
        >
          <PYPPartTitleContent
            title={this.props.metadata.title}
            description={this.props.metadata.description}
          />
          <div style={styles.constraintsContainer}>
            {" "}
            <div style={styles.startingConstraint}>
              <TimeConstraintSelector
                title={
                  this.props.calender
                    ? "When do you plan to finish PYP? (MM/DD/YYYY)"
                    : "What time do you wake up?"
                }
                AMorPM="am"
                updateTimeConstraints={this.updateTimeConstraints}
                value={
                  this.state.startingConstraint
                    ? this.props.calender
                      ? this.state.startingConstraint
                      : this.reconvertAMPM(this.state.startingConstraint, "am")
                    : null
                }
                loaded={this.state.loaded}
                calender={this.props.calender}
              />
            </div>
            <div style={styles.endingConstraint}>
              <TimeConstraintSelector
                value={
                  this.state.endingConstraint
                    ? this.props.calender
                      ? this.state.endingConstraint
                      : this.reconvertAMPM(this.state.endingConstraint, "pm")
                    : null
                }
                title={
                  this.props.calender
                    ? "When do you want to begin your next chapter? (MM/DD/YYYY)"
                    : "What time do you go to bed?"
                }
                AMorPM="pm"
                updateTimeConstraints={this.updateTimeConstraints}
                loaded={this.state.loaded}
                calender={this.props.calender}
              />
            </div>
          </div>
        </PYPLayoutContainer>
      )
    }
  }
)
