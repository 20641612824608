import React, { Component } from "react"
import equal from "fast-deep-equal"

export class InputGroupStateHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inputs: props.inputData,
      inputData: props.inputData
    }
    this.handleChange = this.handleChange.bind(this)
    this.checkSubmittability = this.checkSubmittability.bind(this)
  }

  componentDidUpdate(prevState) {
    /* Since we use the inputData as the initial state,
      if we change the inputs dynamically we also need to update the state
      so we check if the length of select inputs has changed and then update the options
    */
    const filterSelectInputs = ({ inputType }) => inputType === "select"
    const stateSelectInputs = prevState.inputData.filter(filterSelectInputs)
    const propsSelectInputs = this.props.inputData.filter(filterSelectInputs)
    const optionsHasChanged = stateSelectInputs.some((stateInput, index) => {
      const propsSelectInputOptions = propsSelectInputs[index].options
      const stateSelectInputOptions = stateInput.options

      const propsSelectedInputClassName = propsSelectInputs[index].className
      const stateSelectInputClassName = stateInput.className

      return (
        !equal(propsSelectInputOptions, stateSelectInputOptions) ||
        propsSelectedInputClassName !== stateSelectInputClassName
      )
    })

    if (optionsHasChanged) {
      this.setState({
        inputs: this.props.inputData,
        inputData: this.props.inputData
      })
    }
  }

  runValidators(...funcs) {
    return (value) => {
      return funcs.reduceRight((acc, func) => {
        return acc.map(func(this.state.inputData))
      }, value)
    }
  }

  checkSubmittability(inputData) {
    const processedInputData = this.props.validators
      ? this.runValidators(...this.props.validators)(this.state.inputData)
      : this.state.inputData
    const hasError = processedInputData.some((i) => i.error)

    return hasError
      ? this.setState({
          inputData: processedInputData
        })
      : true
  }

  handleChange(input) {
    return (e) => {
      const updatedInputs = this.state.inputs.map((updatedInput) => {
        if (input.id === updatedInput.id) {
          return input.charLimit
            ? {
                ...updatedInput,
                value:
                  e.target.value.length < input.charLimit
                    ? e.target.type === "password"
                      ? e.target.value.trim()
                      : e.target.value
                    : input.value,
                error: false
              }
            : {
                ...updatedInput,
                value:
                  e.target.type === "password"
                    ? e.target.value.trim()
                    : e.target.value,
                error: false
              }
        }
        return {
          ...updatedInput,
          error: false
        }
      })

      if (this.props.onInputChange) {
        this.props.onInputChange(input, e.target.value)
      }

      this.props.changeHandler && this.props.changeHandler(updatedInputs)

      this.setState({
        inputs: updatedInputs,
        inputData: updatedInputs
      })
    }
  }

  render() {
    return (
      <>
        {this.props.renderComponent({
          ...this.props,
          inputs: this.state.inputs,
          inputData: this.state.inputData,
          changeHandler: this.handleChange,
          checkSubmittability: this.checkSubmittability
        })}
      </>
    )
  }
}

export default InputGroupStateHandler
