import React from "react"
import PYPTitle from "../PYPTitle/PYPTitle"
import PYPDescription from "../PYPDescription/PYPDescription"
import PYPContentBorder from "../PYPContentBorder/PYPContentBorder"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper.js"
import "./PYPInstructionContentContainer.css"

const PYPInstructionContentContainer = ({ title, description, style }) => {
  return (
    <div
      className="pypInstructionContentContainer"
      style={{ ...styles.componentContainer, ...style }}
    >
      {title && description ? (
        <>
          <PYPTitle style={styles.title} title={title} />
          <PYPContentBorder />
          <PYPDescription
            className="pypInstructionDescription"
            style={styles.description}
            description={description}
          />
        </>
      ) : (
        <SkeletonWrapper theme="dark" containerStyle={{ maxWidth: "600px" }}>
          <rect x="97" y="1" rx="4" ry="4" width="398" height="53" />
          <rect x="48" y="95" rx="3" ry="3" width="501" height="123" />
        </SkeletonWrapper>
      )}
    </div>
  )
}

const styles = {
  componentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    height: "fit-content",
    paddingBottom: "50px"
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: "70px"
  },
  description: {
    textAlign: "center"
  }
}

export default PYPInstructionContentContainer
