import React from "react"
import { withStyles } from "@material-ui/core/styles"
import ListItem from "@material-ui/core/ListItem"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import colors from "../../Constants/colors"
import { RightArrow } from "../../Icons/RightArrow"
import Actions from "../../Redux/Actions"

const styleSheet = {
  test: {
    color: colors.white,
    fontFamily: "Lato-SemiBold",
    fontSize: "20px",
    lineHeight: "33px",
    letterSpacing: "0.77px",
    padding: "0px"
  }
}

const NavItem = (props) => {
  if (props.children === "Digital Library") {
    return (
      <ListItem
        button
        classes={{
          root: props.classes.test
        }}
        onClick={props.clickHandler}
        style={{
          padding: "10px 40px 10px 40px",
          backgroundColor: props.isSelected && colors.mediumBlue
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {props.children}
          <RightArrow />
        </div>
      </ListItem>
    )
  }
  return (
    <ListItem
      button
      classes={{
        root: props.classes.test
      }}
      onClick={props.clickHandler}
      style={{
        padding: "10px 40px 10px 40px",
        backgroundColor: props.isSelected && colors.mediumBlue
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        {props.children}
        <RightArrow />
      </div>
    </ListItem>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withStyles(styleSheet)(
  connect(mapStateToProps, mapDispatchToProps)(NavItem)
)
