const types = {
  FETCHING_MODULES: "FETCHING_MODULES",
  FETCHING_PARTS: "FETCHING_MODULES",
  FETCHED_PARTS: "FETCHED_PARTS",
  FETCHED_MODULES: "FETCHED_MODULES",
  BEGIN_MODULE: "BEGIN_MODULE",
  RESUME_MODULE: "RESUME_MODULE", // no reducer
  UPDATE_MODULE: "UPDATE_MODULE", // no reducer
  INCREMENT_PART: "INCREMENT_PART",
  DECREMENT_PART: "DECREMENT_PART",
  EXIT_MODULE: "EXIT_MODULE",
  NAVIGATE_TO_MODULE: "NAVIGATE_TO_MODULE",
  INITIALIZED_PYP: "INITIALIZED_PYP",
  INITIALIZING_PYP: "INITIALIZING_PYP",
  INITIALIZED_PART: "INITIALIZED_PART",
  INITIALIZING_PART: "INITIALIZING_PART",
  INITIALIZING_PYP_INPUTS: "INITIALIZING_PYP_INPUTS",
  CREATE_PYP_INPUTS: "CREATE_PYP_INPUTS",
  SET_PYP_INPUT_DATA: "SET_PYP_INPUT_DATA",
  SET_PYP_BUTTON_SELECTION: "SET_PYP_BUTTON_SELECTION",
  SET_PYP_REFERENCE_INPUTS: "SET_PYP_REFERENCE_INPUTS",
  UPDATE_PART_INCOMPLETION_ERROR: "UPDATE_PART_INCOMPLETION_ERROR",
  SAVING_PYP: "SAVING_PYP",
  SAVED_PYP: "SAVED_PYP",
  INCREMENTING_PYP: "INCREMENTING_PYP",
  INCREMENTED_PYP: "INCREMENTED_PYP",
  INSERT_CUSTOM_BUTTON: "INSERT_CUSTOM_BUTTON",
  ADD_INTERVAL: "ADD_INTERVAL",
  CLEAR_INTERVAL: "CLEAR_INTERVAL",
  FETCHING_PART_DATA: "FETCHING_PART_DATA",
  FETCHED_PART_DATA: "FETCHED_PART_DATA",
  FETCHED_PART_INPUTS: "FETCHED_PART_INPUTS",
  PART_IS_LOADING: "PART_IS_LOADING"
}

export default types
