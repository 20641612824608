/* This is a helper function to work with a custom middleware - check apiWrapper
  obs: This is definitely something that can be improved but we inherited it like that :(
 */

const fetch = (url, params, requestPayload) => ({
  type: "FETCH",
  url,
  params,
  requestPayload
})

export default fetch
