import React from "react"
import { connect } from "react-redux"
import InputGroup from "../InputGroup/InputGroup"

const BiographyThree = (props) => {
  const application = window.location.href.includes('spouse') ?  props.spouseApplication : props.application
  const data = [
    {
      id: 0,
      inputType: "longText",
      userField: "biography.priorities",
      title:
        "What are your top five priorities for your first year out of uniform?",
      placeholder: "What are your top five priorities?",
      required: true,
    }
  ]

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    const a = s.split(".")
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (o && k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  }

  const inputDataWithValues = data.map((input) => {
    return {
      ...input,
      value:
        Object.byString(application, input.userField) || ""
    }
  })

  return (
    <InputGroup
      {...props}
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              inputComponent,
              checkSubmittability
            })}
          </>
        )
      }}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application.application,
    spouseApplication: state.spouseApplication.application
  }
}

export default connect(mapStateToProps)(BiographyThree)
