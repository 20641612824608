import React from "react"

export const Close = (props) => {
  return (
    <svg
      onClick={props.onClick}
      style={{ ...props.style }}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "22"}
      height={props.height || "19"}
      viewBox="0 0 22 19"
    >
      <g fill={props.fill || "#D8D8D8"} fillRule="nonzero">
        <path d="M17.983.396L.747 17.632l3.27.972L21.253 1.368z" />
        <path d="M17.983 18.604L.747 1.368l3.27-.972 17.236 17.236z" />
      </g>
    </svg>
  )
}
