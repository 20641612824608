const types = {
    SELECTED_ALLIED_FORCES_APPLICATION_NAV_ITEM: "SELECTED_ALLIED_FORCES_APPLICATION_NAV_ITEM",
    FETCHED_ALLIED_FORCES_APPLICATION: "FETCHED_ALLIED_FORCES_APPLICATION",
    TOGGLE_ALLIED_FORCES_UNFILLED: "TOGGLE_ALLIED_FORCES_UNFILLED",
    SUBMIT_ALLIED_FORCES_APPLICATION: "SUBMIT_ALLIED_FORCES_APPLICATION",
    SUBMITTED_ALLIED_FORCES_APPLICATION: "SUBMITTED_ALLIED_FORCES_APPLICATION",
    EDIT_ALLIED_FORCES_APPLICATION: "EDIT_ALLIED_FORCES_APPLICATION",
    INIT_ALLIED_FORCES_APPLICATION: "INIT_ALLIED_FORCES_APPLICATION",
    SET_ALLIED_FORCES_APPLICATION_INPUT_DATA: "SET_ALLIED_FORCES_APPLICATION_INPUT_DATA",
    SAVING_ALLIED_FORCES_APPLICATION: "SAVING_ALLIED_FORCES_APPLICATION",
    ALLIED_FORCES_APPLY_CLICKED: "ALLIED_FORCES_APPLY_CLICKED",
    ERROR: "ERROR",  // Retained for global error handling
    WILL_ALLIED_FORCES_RELOCATE_CHANGED: "WILL_ALLIED_FORCES_RELOCATE_CHANGED",
    TOGGLE_FETCHING_INTERESTS: "TOGGLE_FETCHING_INTERESTS", 
    UPDATE_INTERESTS: "UPDATE_INTERESTS",
  };
  
  export default types;
  