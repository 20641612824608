import React, { useState } from "react"
import { useDispatch } from "react-redux"
import Loader from "react-loader-spinner"

import colors from "../../Constants/colors"
import { gtmClickHandler } from "../../Utils/gtm"
import MembersCard from "../MembersCard/MembersCard"
import { BiographyModal } from "../Modals"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import Actions from "../../Redux/Actions"

const AttendeesList = ({ isLoading, attendees, hasError, fetchAttendees }) => {
  const dispatch = useDispatch()
  const [currentUser, setCurrentUser] = useState({})

  const onLinkedInPress = ({ fullName, linkedIn }) => {
    gtmClickHandler({
      name: "LinkedInClickEvent",
      data: {
        action: "LinkedIn Clicked",
        category: "User Interaction",
        label: fullName,
        value: linkedIn
      }
    })
  }

  const onBiographyPress = (user) => {
    setCurrentUser(user)

    dispatch(
      Actions.toggleModal({
        modal_mode: true,
        modal_usi: BiographyModal.modalUsi
      })
    )

    gtmClickHandler({
      name: "BiographyClickEvent",
      data: {
        action: "Biography Clicked",
        category: "User Interaction",
        label: user.fullName
      }
    })
  }

  if (hasError) {
    return (
      <div style={styles.messageContainer}>
        <span>There was an error loading the attendees list.</span>
        <br />
        <CommitButtonWithClickState
          type="red"
          clickHandler={fetchAttendees}
          style={styles.tryAgainButton}
        >
          Try again
        </CommitButtonWithClickState>
      </div>
    )
  }

  if (isLoading) {
    return (
      <div style={styles.loaderContainer}>
        <Loader type="Oval" color={colors.midnight} height="100" width="100" />
      </div>
    )
  }

  if (!attendees.length) {
    return (
      <div style={styles.messageContainer}>
        <span>No users found for this workshop.</span>
      </div>
    )
  }

  return (
    <>
      <div style={styles.cardContainer}>
        {attendees.map((user) => (
          <MembersCard
            key={user.id}
            user={user}
            onLinkedInPress={() => onLinkedInPress(user)}
            onBiographyPress={() => onBiographyPress(user)}
            showMilitaryBranch
          />
        ))}
      </div>
      <BiographyModal user={currentUser} />
    </>
  )
}

const styles = {
  cardContainer: {
    display: "flex",
    flexDireciton: "row",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
    height: "fit-content",
    width: "calc(100vw - 66px)",
    maxWidth: "1295px",
    margin: "auto"
  },
  messageContainer: {
    textAlign: "center",
    fontFamily: "Lato-Regular, sans-serif",
    color: colors.mediumBlue
  },
  tryAgainButton: {
    width: "150px",
    marginTop: 22
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center"
  }
}

export default AttendeesList
