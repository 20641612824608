import React from "react"
import { connect } from "react-redux"
import ApplicationNavItem from "../ApplicationNavItem/ApplicationNavItem"
import WithLeftBorder from "../WithLeftBorder/WithLeftBorder"
import WithDots from "../WithDots/WithDots"
import WithCheck from "../WithCheck/WithCheck"
import InstructionalModal from "../InstructionalModal/InstructionalModal"
import InstructionalGuide from "../InstructionalGuide/InstructionalGuide"

const ApplicationNavItemContainer = ({
  item,
  clickHandler,
  dotClickHandler,
  isSelected,
  children,
  toRenderGuide,
  showUnfilled,
  willLocate,
  spouseApplication
}) => {
    const toRenderDot = ({ stepId }) => {
        if (!window.location.href.includes('spouse')) {
            return stepId === "relocation" ? willLocate : true
        } else {
            if (stepId === "relocation") {
                return willLocate
            } else if (stepId === "spouse_info-service_member_name") {
                return spouseApplication.active_duty === "Yes"
            } else if (stepId === "military-basic") {
                return spouseApplication.serve_millitary === "Yes"
            } else {
                return true
            }
        }
    }
  return (
    <div onClick={clickHandler} style={styles.componentContainer}>
      <WithCheck
        style={{ marginRight: "20px" }}
        renderCheckMark={!item.filled && showUnfilled}
        renderComponent={(props) => (
          <WithLeftBorder
            isSelected={isSelected}
            renderComponent={(props) =>
              toRenderGuide ? (
                <InstructionalModal
                  step="dots"
                  contentComponent={(props) => {
                    return (
                      <InstructionalGuide>
                        These dots allow you to navigate between sub-sections of
                        the application.
                      </InstructionalGuide>
                    )
                  }}
                  renderComponent={(props) => (
                    <WithDots
                      item={item}
                      clickHandler={dotClickHandler}
                      style={{ marginLeft: "30px" }}
                      isSelected={isSelected}
                      dots={item.steps.filter(toRenderDot)}
                      renderComponent={(props) => {
                        return (
                          <ApplicationNavItem
                            isSelected={isSelected}
                            style={{ marginLeft: "30px" }}
                          >
                            {children}
                          </ApplicationNavItem>
                        )
                      }}
                    />
                  )}
                />
              ) : (
                <WithDots
                  clickHandler={dotClickHandler}
                  style={{ marginLeft: "30px" }}
                  isSelected={isSelected}
                  dots={item.steps.filter(toRenderDot)}
                  renderComponent={(props) => {
                    return (
                      <ApplicationNavItem
                        isSelected={isSelected}
                        style={{ marginLeft: "30px" }}
                      >
                        {children}
                      </ApplicationNavItem>
                    )
                  }}
                />
              )
            }
          />
        )}
      />
    </div>
  )
}

const styles = {
  componentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "60px",
    cursor: "pointer"
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    willLocate: window.location.href.includes('spouse') ? state.spouseApplication.application.address && state.spouseApplication.application.address.willLocate=="Yes" : state.application.userWillReLocate,
    spouseApplication: state.spouseApplication.application
  }
}

export default connect(mapStateToProps)(ApplicationNavItemContainer)
