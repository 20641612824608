import React from "react"

export const Logo = (props) => {
  return (
    <div style={{ cursor: "pointer", ...props.style }} onClick={props.onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="84"
        height="18"
        viewBox="0 0 84 18"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#E1373A"
            d="M61.186 17.993c-6.715.097-13.417-1.076-20.05-2.806-3.893-1.017-7.793-2.022-11.772-2.647-5.53-.869-11.084-1.144-16.673-.773-4.126.271-8.135 1.111-12.082 2.311-.191.058-.446.257-.572-.01-.15-.313.19-.39.375-.5 3.734-2.22 7.762-3.636 11.988-4.49 5.766-1.167 11.575-1.282 17.424-.839 5.609.427 11.04 1.755 16.464 3.131 4.469 1.136 8.958 2.168 13.547 2.637 4.313.442 8.634.532 12.967.24a61.79 61.79 0 0 0 9.635-1.4c.203-.047.461-.183.549.114.082.283-.213.34-.391.436-3.342 1.778-6.9 2.918-10.591 3.653-3.518.7-7.074 1-10.818.943"
          />
          <path
            fill="#E1373A"
            d="M37 6.081c1.405-.397 2.747-.633 4.097-.825 4.292-.605 8.513-.054 12.703.797 5.045 1.026 10.12 1.853 15.263 2.237 4.729.355 9.459.294 14.186-.04.105-.006.21-.017.316-.023.17-.008.369-.022.422.178.07.263-.168.289-.346.335-2.078.541-4.167 1.048-6.288 1.399-4.01.66-8.06.9-12.12.856-5.598-.062-11.103-.837-16.543-2.108-3.709-.867-7.389-1.865-11.148-2.534A.873.873 0 0 1 37 6.081"
          />
          <path
            fill="#FFF"
            d="M24.174 4.946c-3.219-.092-6.434.014-9.617.524-4.024.645-7.964 1.642-11.726 3.31-.082.036-.162.07-.244.102-.182.07-.417.218-.54.016-.159-.26.121-.399.28-.53 1.844-1.51 3.84-2.763 5.916-3.897C11.776 2.539 15.534 1.29 19.437.567 23.31-.15 27.217-.18 31.126.439c2.812.449 5.585 1.042 8.281 1.999.235.083.631.092.59.42-.037.295-.426.24-.67.267-2.875.33-5.667 1.073-8.474 1.757-1.333.326-2.703.073-4.056.064-.875-.004-1.748 0-2.623 0"
          />
        </g>
      </svg>
    </div>
  )
}
