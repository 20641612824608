import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import CommitInput from "../CommitInput/CommitInput"
import { states } from "../../Constants/locations"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import { useWindowResize } from "../../Hooks/useWindowResize"

// Breakpoint for mobile: 768
// Breakpoint for singular card: 527
const styles = {}
const baseStyles = {
  flex: {
    display: "flex",
    flexDirection: "row"
  },
  verticalFlex: {
    display: "flex",
    flexDirection: "column"
  },
  searchContainer: {
    flexWrap: "wrap",
    // width: "fit-content",
    width: "calc(100vw - 76px)",
    maxWidth: "1285px",
    margin: "auto",
    marginBottom: "65px"
  },
  filterContainer: {
    minWidth: "100px",
    height: "32px",
    padding: "0px",
    paddingTop: "5px",
    marginRight: "30px"
  },
  innerFilterContainer: {
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  unapplied: {
    color: "red"
  },
  search: {
    height: "40px",
    marginRight: "10px",
    marginBottom: "20px"
  },
  searchBar: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "25px"
  },
  searchField: {
    width: "262px",
    padding: "0px"
  },
  unappliedFilterReminder: {
    height: "16px",
    fontFamily: "Lato-Regular",
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    color: colors.fadedRed,
    marginBottom: "4px",
    width: "calc(100vw - 76px)",
    maxWidth: "1285px",
    marginLeft: "33px",
    margin: "auto",
    paddingLeft: "385px"
  },
  filters: {
    width: "fit-content",
    marginBottom: "20px"
  },
  buttonStyle: {
    width: "fit-content",
    minWidth: "100px",
    paddingLeft: "20px",
    paddingRight: "20px",
    height: "40px",
    marginLeft: "0px",
    marginRight: "10px",
    marginTop: "12px"
  },
  searchButtonStyle: {
    marginRight: "15px",
    borderRadius: "0px 3px 3px 0px",
    marginTop: "13px"
  },
  searchHoverStyle: {
    borderRadius: "0px 3px 3px 0px"
  },
  skeletonContainer: {
    marginLeft: "23px",
    flexWrap: "wrap"
  },
  buttonRadius: {
    borderRadius: "3px"
  },
  buttonContainer: {
    minWidth: "240px"
  }
}

const midVPStyles = {
  unappliedFilterReminder: {
    marginLeft: "33px",
    paddingLeft: "0px"
  }
}

const smallVPStyles = {
  filterContainer: {
    marginLeft: "0px",
    marginRight: "0px",
    marginBottom: "20px",
    marginTop: "10px"
  },
  filters: {
    width: "calc(100vw - 66px)"
  },
  searchField: {
    width: "calc(100vw - 157px)"
  },
  unappliedFilterReminder: {
    marginLeft: "33px",
    midWidth: "calc(100vw - 66px)",
    width: "calc(100vw - 66px)",
    height: "32px",
    textAlign: "center",
    justifyContent: "center",
    paddingLeft: "0px"
  }
}

export default withRouter(function NetworkSearchBar(props) {
  const { width } = useWindowResize()
  Object.keys(baseStyles).forEach((key) => {
    styles[key] = baseStyles[key]
  })
  if (width < 1028) {
    Object.keys(midVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...midVPStyles[key] }
    })
  }
  if (width < 601) {
    Object.keys(smallVPStyles).forEach((key) => {
      styles[key] = { ...baseStyles[key], ...smallVPStyles[key] }
    })
  }
  let filters
  const { searchTerms, interests, fetchingInterests } = useSelector(
    (state) => ({
      searchTerms: state.network,
      interests: state.application.interests,
      fetchingInterests: state.application.fetchingInterests
    })
  )
  const dispatch = useDispatch()
  const [unappliedCount, setUnappliedCount] = useState(0)

  const filterTerms = [
    {
      text: "Location",
      term: "filter_state",
      options: [{ value: "Location" }].concat(
        states.map((state) => ({ value: state.name }))
      )
    }
    // {
    //   text: "Industry",
    //   term: "filter_interests",
    //   options: [{ value: "Industry" }].concat(
    //     (interests &&
    //       interests.map((interest) => ({ value: interest.name }))) || [""]
    //   )
    // },
    // {
    //   text: "Branch",
    //   term: "filter_branch",
    //   options: [{ value: "Branch" }].concat([
    //     { value: "Air Force" },
    //     { value: "Army" },
    //     { value: "Marines" },
    //     { value: "Navy" },
    //     { value: "National Guard" },
    //     { value: "Coast Guard" },
    //     { value: "Space Force"}
    //   ])
    // }
  ]

  useEffect(() => {
    dispatch(
      Actions.refreshNetworkSearch(queryString.parse(props.location.search))
    )
  }, [props.location.search, dispatch])

  useEffect(() => {
    if (!interests || (interests.length < 1 && !fetchingInterests)) {
      dispatch(Actions.fetchInterests())
    }
  }, [dispatch])

  useEffect(() => {
    let count = 0
    filterTerms.forEach((filter) => {
      const urlValue = queryString.parse(props.location.search)[filter.term]
      const unapplied = !(
        searchTerms[filter.term] === urlValue ||
        (!urlValue && searchTerms[filter.term] === "")
      )
      if (unapplied) {
        count++
      }
    })
    setUnappliedCount(count)
  }, [searchTerms])

  const buildFilters = () =>
    filterTerms.map((filter, index) => {
      const urlValue = queryString.parse(props.location.search)[filter.term]
      const unapplied = !(
        searchTerms[filter.term] === urlValue || searchTerms[filter.term] === ""
      )
      let innerWidth = "calc(100vw - 66px)"
      if (width > 600) {
        innerWidth = `${
          11 *
          (filter.options &&
            filter.options.reduce((a, b) =>
              ((a.value && a.value.length) || a.value || a) >
              ((b.value && b.value.length) || 0)
                ? (a.value && a.value.length) || a || 0
                : (b.value && b.value.length) || b.value || b || 0
            ))
        }px`
      }
      // if (width < 601) {
      //   styles.filterContainer = {

      //   }
      // }
      return (
        <CommitInput
          inputType="stateless-select"
          key={index}
          placeHolder={filter.text}
          value={
            (searchTerms[filter.term].length && searchTerms[filter.term]) ||
            filter.text
          }
          options={filter.options}
          handleChange={(e) => changeHandler(e, index)}
          style={
            unapplied
              ? {
                  ...styles.filterContainer,
                  ...styles.unapplied,
                  width: innerWidth
                }
              : {
                  ...styles.filterContainer,
                  width: innerWidth
                }
          }
          innerStyle={styles.innerFilterContainer}
        />
      )
    })

  const calculateRoute = (reset) => {
    let route = `/network?spouse=true&campaignId=${props.campaignId}`

    if (reset) {
      return route
    }
    const concatSymbol = "&"

    if (searchTerms.search_text) {
      route = route.concat(
        `${concatSymbol}search_${searchTerms.search_item}=${searchTerms.search_text}`
      )
    }

    filterTerms.forEach((item) => {
      if (searchTerms[item.term] !== "") {
        route = route.concat(
          `${concatSymbol + item.term}=${searchTerms[item.term]}`
        )
      }
    })
    return route
  }

  const search = (reset = false) => {
    const route = calculateRoute(reset)
    if (route === props.location.pathname + props.location.search) {
      console.log("Already on that page!")
    } else {
      props.history.push(route)
    }
  }

  const reset = () => {
    dispatch(Actions.resetNetworkSearch())
    search(true)
  }

  const changeHandler = (e, index) => {
    const newTerms = {
      ...searchTerms,
      [filterTerms[index].term]:
        filterTerms[index].text === e.target.value ? "" : e.target.value
    }
    dispatch(Actions.updateNetworkSearch(newTerms))
  }

  const textChangeHandler = (e, term) => {
    const newTerms = {
      ...searchTerms,
      [term]: e.target.value
    }
    dispatch(Actions.updateNetworkSearch(newTerms))
  }

  filters = filters || buildFilters()

  const unappliedFilters =
    unappliedCount === 0
      ? ""
      : `${unappliedCount} unapplied filter${
          unappliedCount > 1 ? "s" : ""
        }, click ‘apply filter${
          unappliedCount > 1 ? "s" : ""
        }’ to see matching veterans!`

  const searchField = (
    <form
      style={{ ...styles.search, ...styles.flex }}
      onSubmit={(e) => {
        e.preventDefault()
        search()
      }}
    >
      <CommitInput
        inputType="text"
        placeHolder="Search names..."
        value={searchTerms.search_text}
        handleChange={(e) => textChangeHandler(e, "search_text")}
        style={styles.searchField}
      />
      <CommitButtonWithoutClickState
        clickHandler={() => search()}
        id="network search"
        type="red"
        style={{ ...styles.buttonStyle, ...styles.searchButtonStyle }}
        hoverStyle={styles.searchHoverStyle}
      >
        Search
      </CommitButtonWithoutClickState>
    </form>
  )

  const renderedButtons = (
    <div style={{ ...styles.buttonContainer, ...styles.flex }}>
      <CommitButtonWithoutClickState
        clickHandler={() => reset()}
        id="network reset"
        type="white"
        style={{ ...styles.buttonStyle, ...styles.buttonRadius }}
      >
        Reset
      </CommitButtonWithoutClickState>
      {unappliedCount === 0 ? (
        <></>
      ) : (
        <CommitButtonWithoutClickState
          clickHandler={() => search()}
          id="network apply filters"
          type="red"
          style={styles.buttonStyle}
        >
          Apply Filters
        </CommitButtonWithoutClickState>
      )}
    </div>
  )

  const skeletonRender = [250, 80, 120, 200, 120, 90].map((s, i) => (
    <SkeletonWrapper
      key={i}
      containerStyle={{
        width: `${s || 90}px`,
        margin: "5px 10px",
        height: "40px"
      }}
      theme="light"
      height={40}
      width={s || 90}
    >
      <rect x="0" y="0" rx="4" ry="4" width={`${s || 90}`} height={40} />
    </SkeletonWrapper>
  ))
  return (
    <>
      <p style={styles.unappliedFilterReminder}>{unappliedFilters} </p>
      {interests && interests.length ? (
        <div style={{ ...styles.searchContainer, ...styles.flex }}>
          <div style={{ ...styles.searchBar, ...styles.flex }}>
            {searchField}
            <div
              style={
                width > 600
                  ? { ...styles.filters, ...styles.flex }
                  : { ...styles.filters, ...styles.verticalFlex }
              }
            >
              {filters}
            </div>
          </div>
          {renderedButtons}
        </div>
      ) : (
        <div style={{ ...styles.skeletonContainer, ...styles.flex }}>
          {skeletonRender}
        </div>
      )}
    </>
  )
})
