import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { debounce } from "debounce"
import { PDFDownloadLink } from "@react-pdf/renderer"
import moment from "moment"
import PYPDownloadPage from "../PYPDownloadPage/PYPDownloadPage"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"

const styles = {
  redButton: {
    width: "100%",
    transition: "all 0.3s ease-in-out",
    backgroundColor: "inherit",
    color: colors.white,
    height: "55px",
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "left",
    marginLeft: "38px",
    paddingLeft: "0px"
  },
  greyButton: {
    width: "100%",
    transition: "all 0.3s ease-in-out",
    backgroundColor: "inherit",
    color: colors.charcoalGrey,
    height: "55px",
    fontSize: "16px",
    textAlign: "left",
    marginLeft: "38px",
    paddingLeft: "0px"
  },
  hovered: {
    textDecoration: "underline"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const { user } = state.user
  return {
    ...props,
    pyp: state.pyp,
    userModules:
      user.modules &&
      user.modules.map((item) => {
        return {
          id: item.id,
          title: item.title,
          parts_sequence:
            item.parts_sequence &&
            item.parts_sequence.split(",").map((partId) => parseInt(partId))
        }
      }),
    firstName: user && user.firstName
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class PYPDownloadContainer extends Component {
      constructor(props) {
        super(props)
        this.state = {
          fetchingPYP: false,
          pypPartInitialized: false,
          PYP_modules_with_parts: null
        }
        this.retrieveDataByPartId = this.retrieveDataByPartId.bind(this)
        this.initializePYP = debounce(this.initializePYP.bind(this), 1000)
      }

      retrieveDataByPartId = async () => {
        this.setState({ fetchingPYP: true })
        // client side loading const PYP_module_data = await this.props.retrieveAllUserModulesWithParts()
        const PYP_module_data = await this.props.retrieveUserModulesWithPopulatedInputs()
        this.setState({
          PYP_modules_with_parts: PYP_module_data,
          fetchingPYP: false
        })
      }

      initializePYP = () => {
        this.props.initializePYP(this.props.userModules[0].title)
      }

      render() {
        return (
          <>
            {this.state.PYP_modules_with_parts ? (
              <PDFDownloadLink
                document={
                  <PYPDownloadPage
                    PYP_modules={this.state.PYP_modules_with_parts}
                    firstName={this.props.firstName}
                  />
                }
                fileName={`${
                  this.props.firstName
                }-Pursue-Your-Purpose-${moment().format("MM/DD/YYYY")}.pdf`}
              >
                {({ blob, url, loading, error }) =>
                  loading ? (
                    <CommitButtonWithoutClickState
                      id="PYP PDF loading"
                      style={styles.greyButton}
                    >
                      Loading Document...
                    </CommitButtonWithoutClickState>
                  ) : (
                    <CommitButtonWithoutClickState
                      id="PYP PDF download"
                      style={styles.redButton}
                      hoverStyle={styles.hovered}
                    >
                      Download PDF
                    </CommitButtonWithoutClickState>
                  )
                }
              </PDFDownloadLink>
            ) : (
              <>
                {this.state.fetchingPYP ? (
                  <CommitButtonWithoutClickState
                    id="Preparing PYP Download"
                    style={styles.greyButton}
                  >
                    Preparing
                  </CommitButtonWithoutClickState>
                ) : (
                  <CommitButtonWithoutClickState
                    id="Prepare PYP Download"
                    style={styles.redButton}
                    hoverStyle={styles.hovered}
                    clickHandler={this.retrieveDataByPartId}
                  >
                    Get my PYP PDF
                  </CommitButtonWithoutClickState>
                )}
              </>
            )}
          </>
        )
      }
    }
  )
)
