import { useWindowResize } from "./useWindowResize"

export const useResizeThumbnailCalculation = (
  dynamicThumbnailCountOnResize
) => {
  const { width } = useWindowResize()
  let numOfThumbnails = 0

  if (!dynamicThumbnailCountOnResize) {
    return null
  }

  switch (true) {
    case width > 1670:
      numOfThumbnails = 10
      break
    case width > 1377:
      numOfThumbnails = 8
      break
    case width > 1105:
      numOfThumbnails = 6
      break
    case width > 992:
      numOfThumbnails = 4
      break
    case width < 991:
      numOfThumbnails = 4
      break
    default:
      numOfThumbnails = 8
  }

  return {
    numOfThumbnails,
    width
  }
}
