import React, { useState } from "react"
import { Droppable } from "react-beautiful-dnd"
import RoadmapperEvent from "../RoadmapperEvent/RoadmapperEvent"

export default function RoadmapperDroppableContainer({
  eventIds,
  timeEvents,
  constraintEnd,
  constraintStart,
  isEditing,
  updateParentState,
  renderMoment,
  isBeingDragged,
  durationUnit,
  isDragDisabled,
  eventIdBeingEdited,
  updateEditEvent,
  deleteTimeEvent,
  values,
  skeletonLoader
}) {
  const [accumulatedTimes, updateAccumulatedTimes] = useState([])

  const changeSingleAccumulatedTime = (newTime, index) => {
    const tempArr = accumulatedTimes || 0
    tempArr[index] = newTime
    updateAccumulatedTimes(tempArr)
  }

  return (
    <div>
      <Droppable droppableId="TIME_EVENTS">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={styles.container}
          >
            {timeEvents && eventIds ? (
              eventIds.map((id, index) => {
                changeSingleAccumulatedTime(
                  index !== 0 &&
                    timeEvents.filter(
                      (event) => event.id === eventIds[index - 1]
                    )[0].duration + accumulatedTimes[index - 1],
                  index
                )
                return (
                  <RoadmapperEvent
                    skeletonLoader={skeletonLoader}
                    {...provided.droppableProps}
                    constraintStart={constraintStart}
                    constraintEnd={constraintEnd}
                    isEditing={isEditing}
                    updateParentState={updateParentState}
                    timeEvent={timeEvents.filter((event) => event.id === id)[0]}
                    key={timeEvents.filter((event) => event.id === id)[0].id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                    accumulatedTime={accumulatedTimes[index]}
                    renderMoment={renderMoment}
                    isBeingDragged={isBeingDragged}
                    durationUnit={durationUnit}
                    updateEditEvent={updateEditEvent}
                    deleteTimeEvent={deleteTimeEvent}
                    values={values}
                    editingComponent={
                      eventIdBeingEdited ===
                      timeEvents.filter((event) => event.id === id)[0].id
                    }
                  />
                )
              })
            ) : (
              <></>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

const styles = {
  container: {
    maxWidth: "699px"
  }
}
