import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Actions from "../../Redux/Actions";
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer";
import UserFieldEditorFooter from "../UserFieldEditorFooter/UserFieldEditorFooter";
import { spouseApplicationSteps } from "../../Redux/Reducers/SpouseApplication/spouseApplicationMetadata";
import constants from "../../Constants";
import { allFormsFilled } from "../../Redux/Utils/reducerUtils";
import SpouseApplicationConfirmationModal from "./SpouseApplicationConfirmationModal";
import { validateCurrentStep } from "../../Redux/Actions/Formulary/functions";
import store from "../../Redux/store";

const SpouseApplicationFooter = (props) => {
  const getNextPage = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;

    const currentPageIndex = spouseApplicationSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    );

    if (pageTitle === "personal_info-address") {
      if (props.spouseApplication && props.willLocate) {
        return spouseApplicationSteps[currentPageIndex + 1].stepId;
      }
      return spouseApplicationSteps[currentPageIndex + 2].stepId;
    }
    return spouseApplicationSteps[currentPageIndex + 1].stepId;
  };

  const getPreviousPage = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
  
    const currentPageIndex = spouseApplicationSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    );
  
    if (currentPageIndex === 0) {
      return pageTitle;
    }
  
    if (pageTitle === "personal_info-timeline") {
      if (props.spouseApplication && props.willLocate) {
        return spouseApplicationSteps[currentPageIndex - 1].stepId;
      }
      return spouseApplicationSteps[currentPageIndex - 2].stepId;
    } else if (pageTitle === "military-initial") {
      if (props.spouseApplication.application.active_duty === "Yes") {
        return spouseApplicationSteps[currentPageIndex - 1].stepId;
      }
      return spouseApplicationSteps[currentPageIndex - 2].stepId;
    } else if (pageTitle === "biography") {
      if (props.spouseApplication.application.serve_millitary === "Yes") {
        return spouseApplicationSteps[currentPageIndex - 1].stepId;
      }
      return spouseApplicationSteps[currentPageIndex - 2].stepId;
    }
    return spouseApplicationSteps[currentPageIndex - 1].stepId;
  };

  const displayErrorMessage = (message) => {
    removeErrorMessage();
    const dateInput = document.querySelector('input[type="date"]');
    const errorMessage = "Invalid date of birth.";
    const errorElement = document.createElement("span");
    errorElement.textContent = errorMessage;
    errorElement.style.fontSize = "16px";
    errorElement.classList.add("text-danger");
    const parentElement = dateInput.parentNode;
    parentElement.parentNode.insertBefore(
      errorElement,
      parentElement.nextSibling
    );
  };

  const removeErrorMessage = () => {
    const errorElement = document.querySelector(".text-danger");
    if (errorElement) {
      errorElement.remove();
    }
  };

  const validateField = (input, value) => {
    let error = false;
    let helperText = "";
    if (input.required && !value) {
      error = true;
      helperText = "This field is required";
    }
  
    return { error, helperText };
  };
  

  const nextHandler = () => {
    let is_valid = true;
    let data;
    if (props.inputData !== "" && (props.currentInputData === null || props.currentInputData === undefined)) {
      data = props.inputData;
    } else if (props.inputData === "" && (props.currentInputData !== null && props.currentInputData !== undefined)) {
      data = props.currentInputData;
    } else if (props.inputData !== "" && (props.currentInputData !== null && props.currentInputData !== undefined)) {
      data = props.currentInputData;
    } else {
      is_valid = false;
    }

    if (data && Object.keys(data).length > 0) {
      data.forEach((d) => {
        const { error, helperText } = validateField(d, d.value);
        d.error = error;
        d.helperText = helperText;
        if (error && is_valid) {
          is_valid = false;
        }
      });
    } else {
      is_valid = false;
    }

      const pageTitle = queryString.parse(props.history.location.search).title;
      if (pageTitle === "personal_info-basic") {
        const dateInput = document.querySelector('input[type="date"]');
        const selectedDate = new Date(dateInput.value);
        const minDate = new Date("1950-01-01");
        const maxDate = new Date();
        if (selectedDate < minDate || selectedDate > maxDate) {
          displayErrorMessage();
          return false;
        } else {
          removeErrorMessage();
        }
      }

      if (pageTitle !== constants.APPLICATION_PROCESS_LAST_PAGE) {
        const currentPageIndex = spouseApplicationSteps.findIndex(
          (elem) => elem.stepId === pageTitle
        );

        let next_page;
        if (pageTitle === "personal_info-address") {
          next_page =
            document.querySelector('.relocation input[type="hidden"]').value ===
            "Yes"
              ? spouseApplicationSteps[currentPageIndex + 1].stepId
              : spouseApplicationSteps[currentPageIndex + 2].stepId;
        } else if (pageTitle === "spouse_info-active_duty") {
          next_page =
            document.querySelector('.active_duty input[type="hidden"]').value ===
            "Yes"
              ? spouseApplicationSteps[currentPageIndex + 1].stepId
              : spouseApplicationSteps[currentPageIndex + 2].stepId;
        } else if (pageTitle === "military-initial") {
          next_page =
            document.querySelector('.serve_millitary input[type="hidden"]')
              .value === "Yes"
              ? spouseApplicationSteps[currentPageIndex + 1].stepId
              : spouseApplicationSteps[currentPageIndex + 2].stepId;
        } else {
          next_page = getNextPage();
        }

        props.currentInputData &&
          props.saveApplication(props.currentInputData, props.history);
        props.history.push(`spouse?title=${next_page}`);

        if (!is_valid) {
          props.history.push(`spouse?title=${getPreviousPage()}`);
          const appSectionTitle = document.querySelector(".appSectionTitle");

          // Add Error Message
          removeErrorMessage();
          const errorMessage =
            "Please fill all the required (non-optional) fields before proceeding.";
          const errorElement = document.createElement("div");
          errorElement.textContent = errorMessage;
          errorElement.style.fontSize = "16px";
          errorElement.style.textAlign = "center";
          errorElement.classList.add("text-danger");
          const parentElement = appSectionTitle.parentElement;
          parentElement.appendChild(errorElement);
        } else {
          removeErrorMessage();
        }
      } else {
        submitApplication();
      }
  };

  const previousHandler = () => {
    removeErrorMessage();
    const pageTitle = queryString.parse(props.history.location.search).title;
    if (pageTitle !== constants.APPLICATION_PROCESS_FIRST_PAGE) {
      props.currentInputData &&
        props.saveApplication(props.currentInputData, props.history);
      //props.history.push(`spouse?title=${getPreviousPage()}`);
      // Navigate and reload
      const navigateAndReload = (url) => {
        window.location.href = url;
      };

      navigateAndReload(`spouse?title=${getPreviousPage()}`);
    }

  };

  const saveAndExitHandler = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    if (pageTitle === "personal_info-basic") {
      const dateInput = document.querySelector('input[type="date"]');
      const selectedDate = new Date(dateInput.value);
      const minDate = new Date("1950-01-01");
      const maxDate = new Date();
      if (selectedDate < minDate || selectedDate > maxDate) {
        displayErrorMessage();
        return false;
      } else {
        removeErrorMessage();
      }
    }

    if (props.currentInputData) {
      props.saveApplication(props.currentInputData, props.history);
      if (props.user.has_submitted_application) {
        props.history.push("/home");
      } else {
        props.logout();
        props.history.push("/account/login");
      }
    } else if (props.user.has_submitted_application) {
      props.history.push("/home");
    } else {
      props.logout();
      props.history.push("/account/login");
    }
    props.closeAllDrawers();
  };

  const finalizeAndSubmitApplication = () => {
    props.finalizeAndSubmitApplication(props.history, props.currentInputData);
    props.closeAllDrawers();
  };

  const submitApplication = () => {

    if (allFormsFilled(props.spouseApplication.spouseApplicationMetadata)) {
      if (props.user.has_submitted_application) {
        props.saveApplication(props.currentInputData, props.history);
        props.history.push("/home");
      } else {
        props.toggleModal({
          modal_mode: true,
          modal_usi: "applicationConfirmation",
        });
      }
    } else {
      // Add Error Message
      const appSectionTitle = document.querySelector(".appSectionTitle");
      removeErrorMessage();
      const errorMessage =
          "Please fill all the required (non-optional) fields before proceeding.";
      const errorElement = document.createElement("div");
      errorElement.textContent = errorMessage;
      errorElement.style.fontSize = "16px";
      errorElement.style.textAlign = "center";
      errorElement.classList.add("text-danger");
      const parentElement = appSectionTitle.parentElement;
      parentElement.appendChild(errorElement);
      props.saveApplication(props.currentInputData, props.history);
      props.toggleUnfilled();
      props.openDrawerAndPreventTertiary();
    }
  };

  const pageTitle = queryString.parse(props.history.location.search).title;

  return (
    <>
      <UserFieldEditorFooter
        nextHandler={nextHandler}
        previousHandler={previousHandler}
        renderPreviousButton={
          queryString.parse(props.history.location.search).title !==
          constants.APPLICATION_PROCESS_FIRST_PAG
        }
        saveAndExitHandler={saveAndExitHandler}
        toRenderSaveAndExitButton={!props.user.has_submitted_application}
        nextText={
          pageTitle === constants.APPLICATION_PROCESS_LAST_PAGE
            ? props.user.has_submitted_application
              ? "Finish Editing"
              : "Submit"
            : "Next"
        }
      />
      <SpouseApplicationConfirmationModal
        submitHandler={finalizeAndSubmitApplication}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  try {
    const { applicationMetadata, currentInputData } = state.application;

    const pageTitle = queryString.parse(props.history.location.search).title;
    const { stepId } = spouseApplicationSteps.find(
      (elem) => elem.stepId === pageTitle
    );

    let selectedStep;

    applicationMetadata.find((input) => {
      return input.steps.find((step) => {
        if (step.stepId === stepId) {
          selectedStep = step;
        }
        return step.stepId === stepId;
      });
    });
    return {
      ...props,
      currentInputData,
      spouseApplication: state.spouseApplication,
      selectedStep,
      spouseApplicationInitialized: state.spouseApplication.initialized,
      willLocate:
        state.spouseApplication.application.address &&
        state.spouseApplication.application.address.willLocate == "Yes",
      user: state.user.user,
    };
  } catch (e) {
    if (
      e.toString() === "TypeError: Cannot read property 'stepId' of undefined"
    ) {
      props.history.push("/account/login");
    }
    throw e;
  }
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(SpouseApplicationFooter))
);
