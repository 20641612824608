import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import WithModuleSplashPageFunctionality from "../WithModuleSplashPageFunctionality/WithModuleSplashPageFunctionality"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

const PYPPartDesktopFooterContent = (props) => {
  return (
    <WithModuleSplashPageFunctionality>
      {({ clickHandler, buttonText, foundUnlockedModule }) => (
        <CommitButtonWithClickState
          type={foundUnlockedModule ? "red" : "gray"}
          id="pyp-module-btn"
          style={{
            borderRadius: "3px",
            width: "138px"
          }}
          clickHandler={clickHandler}
        >
          {buttonText}
        </CommitButtonWithClickState>
      )}
    </WithModuleSplashPageFunctionality>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    parts: state.pyp.parts,
    navigation: state.pyp.navigation
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PYPPartDesktopFooterContent)
)
