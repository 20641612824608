import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import PropTypes from "prop-types"

import { FullCommitLogo } from "../../Icons/FullCommitLogo"
import colors from "../../Constants/colors"

import { getCurrentStep } from "./utils"
import lockIcon from "../../Assets/Images/icons/lock.png"
import { RedX } from "../../Icons/RedX"
import WithDots from "../WithDots/WithDots"
import ApplicationNavItem from "../ApplicationNavItem/ApplicationNavItem"
import { submitFormulary } from "../../Redux/Actions/Formulary/functions"

const styles = {
  navContentContainer: {
    display: "flex",
    width: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "relative"
  },
  navUpperSection: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-start",
    minHeight: "65px"
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    padding: "10px 0px 20px 30px",
    minHeight: "65px"
  },
  progressDialContainer: {
    minHeight: "100px",
    flex: 2,
    marginLeft: "16px",
    maxHeight: "200px"
  },
  navOptionsContainer: {},
  navOptionSelected: {
    borderLeft: `solid 4px ${colors.fadedRed}`
  },
  navOption: {
    display: "flex",
    flexDirection: "column",
    minHeight: "60px",
    cursor: "pointer",
    justifyContent: "center",
    color: "white",
    textDecoration: "none"
  },
  title: {
    color: "white",
    display: "flex",
    alignItems: "flex-end",
    fontSize: "18px",
    fontFamily: "Lato-Bold",
    height: "100%",
    paddingBottom: "20px",
    marginLeft: "14px"
  },
  errorCheckMark: {
    position: "absolute",
    right: "20px"
  },
  footerButtonContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "55px",
    backgroundColor: colors.fadedRed,
    height: "100%",
    border: "none"
  },
  footerButtonText: {
    fontFamily: "Lato-Regular",
    fontSize: "16px",
    color: colors.white
  }
}

const getInputFilled = (input) => {
  if (!input.required) return true

  return Array.isArray(input.value)
    ? input.value.length > 0
    : Boolean(input.value)
}

const FormularyNavMenu = ({
  stepId,
  formularyMetadata,
  title,
  steps,
  history
}) => {
  const dispatch = useDispatch()

  const { inputsByStep, isFormularyComplete, formularySections } = useSelector(
    ({ formulary }) => {
      const { inputs, sections } = formulary[formulary.currentFormularyType]
      const formularyInputs = Object.values(inputs)

      return {
        formularySections: sections,
        inputsByStep: formularyInputs.reduce((inputsAcum, input) => {
          const stepInputs = inputsAcum[input.step] || {}

          return {
            ...inputsAcum,
            [input.step]: {
              ...stepInputs,
              [input.id]: {
                ...input,
                filled: getInputFilled(input)
              }
            }
          }
        }, {}),
        isFormularyComplete: formularyInputs.every((input) =>
          getInputFilled(input)
        )
      }
    }
  )

  const currentStep = getCurrentStep(steps, stepId)

  const onSubmitFormulary = async () => {
    if (!isFormularyComplete) return

    await dispatch(submitFormulary(currentStep, history))
  }

  return (
    <div style={styles.navContentContainer}>
      <div style={styles.navUpperSection}>
        <div style={styles.logoContainer}>
          <FullCommitLogo />
        </div>
        <div style={styles.progressDialContainer}>
          <div style={styles.title}>{title}</div>
        </div>
        <div style={styles.navOptionsContainer}>
          {formularyMetadata
            .filter((item) => item.display)
            .map((section) => {
              const isSelected = section.steps.some(
                (step) => step.stepId === stepId
              )

              const { isValid, visitedSteps } = formularySections[section.id]

              const dots = section.steps.map((step) => ({
                ...step,
                filled: Object.values(inputsByStep[step.stepId] || {}).every(
                  (input) => input.filled
                ),
                visited: visitedSteps[step.stepId] || false
              }))

              return (
                <Link
                  key={section.id}
                  style={styles.navOption}
                  to={section.steps[0].url}
                >
                  <div style={isSelected ? styles.navOptionSelected : {}}>
                    <WithDots
                      dots={dots}
                      renderComponent={() => (
                        <ApplicationNavItem
                          isSelected={isSelected}
                          style={{ marginLeft: "30px" }}
                        >
                          <span>{section.title}</span>
                        </ApplicationNavItem>
                      )}
                    />
                  </div>
                  {!isValid && <RedX style={styles.errorCheckMark} />}
                </Link>
              )
            })}
        </div>
      </div>
      <div>
        <button
          style={{
            ...styles.footerButtonContainer,
            ...(isFormularyComplete
              ? {}
              : { backgroundColor: colors.blueyGrey })
          }}
          onClick={onSubmitFormulary}
          type="button"
        >
          <p style={styles.footerButtonText}>Submit</p>
          {!isFormularyComplete && (
            <img
              alt="commitImg"
              style={{ height: "19px", width: "14px", marginLeft: "8px" }}
              src={lockIcon}
            />
          )}
        </button>
      </div>
    </div>
  )
}

FormularyNavMenu.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({ search: PropTypes.string })
  }).isRequired,
  stepId: PropTypes.string.isRequired,
  formularyMetadata: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default withRouter(FormularyNavMenu)
