import React from "react"
import { withRouter, Link } from "react-router-dom"
import queryString from "query-string"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { FullCommitLogo } from "../../Icons/FullCommitLogo"
import ApplicationSubmitButton from "../ApplicationSubmitButton/ApplicationSubmitButton"
import colors from "../../Constants/colors"
import InstructionalModal from "../InstructionalModal/InstructionalModal"
import InstructionalGuide from "../InstructionalGuide/InstructionalGuide"
import Tooltip from "../Tooltip/Tooltip"
import ApplicationNavItemContainer from "../ApplicationNavItemContainer/ApplicationNavItemContainer"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"

import Actions from "../../Redux/Actions"

class UserFieldEditorNavMenu extends React.Component {
  dotClickHandler = (item, event) => {
    const { application, saveApplication, history } = this.props

    if (application.currentInputData) {
      saveApplication(application.currentInputData, history)
    }

    event.stopPropagation()
  }

  render() {
    return (
      <div style={styles.navContentContainer} className="navContainer">
        <div style={styles.navUpperSection} className="navUpperSection">
          <div style={styles.logoContainer}>
            <FullCommitLogo />
          </div>
          <div style={styles.progressDialContainer}>
            {this.props.progressComponent}
          </div>
          <InstructionalModal
            style={styles.navOptionsContainer}
            step="navigation"
            contentComponent={(props) => {
              return (
                <InstructionalGuide>
                  Use the left panel to jump anywhere in the application at
                  anytime.
                </InstructionalGuide>
              )
            }}
            renderComponent={(props) => {
              return (
                <div className="container" style={styles.navOptions}>
                  {this.props.userFieldData
                    .filter((item) => item.display)
                    .map((item, i) => {
                      const isSelected = item.steps.some((step) => {
                        const pageTitle = queryString.parse(
                          this.props.history.location.search
                        ).title
                        return step.stepId.includes(pageTitle)
                      })
                      return (
                        <ApplicationNavItemContainer
                          showUnfilled={
                            this.props.application.showErrors &&
                            item.title !== "Submission" &&
                            item.steps.some((step) => step.unseenError)
                          }
                          toRenderGuide={isSelected}
                          key={i}
                          item={item}
                          user={this.props.user}
                          isSelected={isSelected}
                          clickHandler={this.props.navItemClickHandler(item.id)}
                          dotClickHandler={this.dotClickHandler}
                        >
                          <Link
                            style={{
                              color: "white",
                              textDecoration: "none"
                            }}
                            to={`/${
                              window.location.href.includes('non-dod-ic')
                                ? 'application/non-dod-ic'
                                : window.location.href.includes('spouse')
                                ? 'application/spouse'
                                : window.location.href.includes('allied-forces')
                                ? 'application/allied-forces'
                                : this.props.baseUrl || 'application'
                              }?title=${item.steps[0].stepId}&reload=true`}
                          >
                            {item.title}
                          </Link>
                        </ApplicationNavItemContainer>
                      )
                    })}
                </div>
              )
            }}
          />
        </div>

        <div style={styles.navLowerSection} className="navLowerSection">
          <Tooltip
            style={{
              display: "flex"
            }}
            show={this.props.showSubmitTooltip}
            width={270}
            height={50}
            modalHorizontalOffset={0}
            arrowOffset={0}
            orientation="top"
            contentComponent={(props) => {
              // The modal body
              return (
                <div
                  style={{
                    color: colors.mediumBlue,
                    fontSize: "16px",
                    lineHeight: "22px",
                    fontFamily: "Lato-Regular",
                    width: "270px"
                  }}
                >
                  You must first complete all steps
                </div>
              )
            }}
            renderComponent={(props) => {
              return (
                <ApplicationSubmitButton
                  onPressSubmit={this.props.submitHandler}
                  submitState={this.props.submitState}
                  applicationIsComplete={this.props.applicationIsComplete}
                />
              )
            }}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  navContentContainer: {
    display: "flex",
    width: "100%",
    minHeight: "100%",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  navUpperSection: {
    // Container for the logo, progress dial, and nav items
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "flex-start",
    minHeight: "65px"
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    padding: "10px 0px 20px 30px",
    minHeight: "65px"
  },
  progressDialContainer: {
    minHeight: "100px",
    flex: 2,
    marginLeft: "16px",
    maxHeight: "200px"
  },
  navOptionsContainer: {
    // applied to the InstructionalModal. Wraps navOptions div (below)
    flex: 7,
    overflow: "auto",
    padding: "0px",
    minHeight: 0 /* for Firefox */
  },
  navOptions: {
    padding: "0px"
  },
  navLowerSection: {
    // fixed to bottom - this only contains the submit button
    width: "100%"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: !window.location.href.includes('spouse') ? state.application : state.spouseApplication,
    user: state.user.user
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WithDrawerConsumer(UserFieldEditorNavMenu))
)
