import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
const mapStateToProps = (state, props) => {
  return {
    ...props,
    userLoaded: !!state.user.user.id,
    guides: state.user.user.guides
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  class InvisibleGuideCompletion extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        guideCallMade: false
      }
      this.guide =
        (this.props.guides &&
          this.props.guides.find(
            (guide) => guide.unique_string_identifier === this.props.GUIDE_USI
          )) ||
        null
      this.completeUserGuide = this.completeUserGuide.bind(this)
    }

    completeUserGuide = (GUIDE_USI) => {
      this.setState({ guideCallMade: true })
      this.props.completeGuide(GUIDE_USI)
    }

    componentDidMount() {
      if (
        this.props.userLoaded &&
        this.state.guideCallMade === false &&
        (!this.guide || !this.guide.pivot.completed)
      ) {
        this.completeUserGuide(this.props.GUIDE_USI)
      }
    }

    componentDidUpdate() {
      if (
        this.props.userLoaded &&
        this.state.guideCallMade === false &&
        (!this.guide || !this.guide.pivot.completed)
      ) {
        this.completeUserGuide(this.props.GUIDE_USI)
      }
    }

    render() {
      return <></>
    }
  }
)
