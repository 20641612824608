import React from "react"
import MediaQuery from "react-responsive"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

const styles = {
  header: { height: "45px", width: "171px", margin: "34px auto" },
  body: {
    marginTop: "15px",
    height: "72px",
    width: "100%",
    gridArea: "userBiographyText",
    paddingBottom: "200px"
  }
}
export default class UserBiographySkeletonRender extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      skeletonRatio: 0
    }
  }

  componentDidMount() {
    this.resizeSkeletonWidth()
    window.addEventListener("resize", this.resizeSkeletonWidth)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeSkeletonWidth)
  }

  resizeSkeletonWidth = () => {
    const container = document.getElementById("skeletonContainer")
    this.setState({ skeletonRatio: 72 / container.clientWidth })
  }

  render() {
    const { skeletonRatio } = this.state
    return (
      <>
        <MediaQuery minWidth={651}>
          <span
            id="skeletonContainer"
            style={{
              width: "100%",
              height: "100%"
            }}
          >
            <SkeletonWrapper
              containerStyle={styles.header}
              height="45"
              width="170"
            >
              <rect x="0" y="0" width="170" height="22" />
              <rect x="0" y="24" width="84" height="18" />
              <rect x="86" y="24" width="170" height="18" />
            </SkeletonWrapper>
            <SkeletonWrapper
              containerStyle={styles.body}
              width="100"
              height={100 * skeletonRatio}
            >
              <rect x="20" y="0" width="60" height={30 * skeletonRatio} />
              <rect
                x="0"
                y={35 * skeletonRatio}
                width="100"
                height={30 * skeletonRatio}
              />
              <rect
                x="10"
                y={70 * skeletonRatio}
                width="80"
                height={30 * skeletonRatio}
              />
            </SkeletonWrapper>
            <span className="userBiographyRedirect">
              <CommitButtonWithClickState
                type="whiteMediumBlue"
                style={{ width: "50%" }}
              />
            </span>
          </span>
        </MediaQuery>

        <MediaQuery maxWidth={650}>
          <SkeletonWrapper
            containerStyle={styles.header}
            height="56"
            width="171"
          >
            <rect x="0" y="0" width="165" height="26" />
            <rect x="0" y="30" width="65" height="26" />
            <rect x="70" y="30" width="95" height="26" />
          </SkeletonWrapper>
          <div
            id="skeletonContainer"
            style={{
              width: "100%",
              height: "166px",
              gridArea: "userBiographyText",
              marginLeft: "27px"
            }}
          >
            <SkeletonWrapper
              containerStyle={styles.body}
              width="100"
              height={200 * skeletonRatio}
            >
              <rect x="0" y="0" width="90" height={30 * skeletonRatio} />
              <rect
                x="0"
                y={35 * skeletonRatio}
                width="90"
                height={30 * skeletonRatio}
              />
              <rect
                x="0"
                y={70 * skeletonRatio}
                width="90"
                height={30 * skeletonRatio}
              />
              <rect
                x="0"
                y={105 * skeletonRatio}
                width="90"
                height={30 * skeletonRatio}
              />
              <rect
                x="0"
                y={140 * skeletonRatio}
                width="75"
                height={30 * skeletonRatio}
              />
            </SkeletonWrapper>
          </div>
        </MediaQuery>
      </>
    )
  }
}
