import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import VideoFilters from "../VideoFilters/VideoFilters"
import DigitalLibraryInfiniteScroll from "../DigitalLibraryInfiniteScroll/DigitalLibraryInfiniteScroll"
import { spacify, uppercasify } from "../../Utils/urlParsingUtilities"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"

export class VideoThumbnailListWithFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollReady: false,
      filtersOpen: true,
      loaded: false
    }

    this.finishLoading = this.finishLoading.bind(this)
    this.loadMoreVideos = this.loadMoreVideos.bind(this)
  }

  componentDidMount() {
    this.props.loadVideosWithTypeAndCategory(
      spacify(this.props.match.params.type),
      spacify(this.props.match.params.category),
      1
    )

    document.title = `${uppercasify(
      this.props.match.params.category
    )}  | ${uppercasify(this.props.match.params.type)} | The Digital Library`
    window.dataLayer.push({
      page_view: {
        page_path: `${
          this.props.location.pathname + this.props.location.search
        }`,
        page_title: document.title
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.props.loadVideosWithTypeAndCategory(
        spacify(this.props.match.params.type),
        spacify(this.props.match.params.category),
        1
      )

      this.setState({
        loaded: false,
        scrollReady: true
      })

      document.title = `${uppercasify(
        this.props.match.params.category
      )}  | ${uppercasify(this.props.match.params.type)} | The Digital Library`

      window.dataLayer.push({
        page_view: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
    } else if (
      prevProps.match.fetchingVideos &&
      !this.props.match.fetchingVideos
    ) {
      this.setState({
        loaded: true
      })
    }
  }

  finishLoading() {
    this.setState({
      scrollReady: true
    })
  }

  loadMoreVideos() {
    this.setState({
      scrollReady: false
    })
    this.props.loadMoreVideos(20, this.props.page + 1)
  }

  render() {
    return (
      <DigitalLibraryInfiniteScroll
        hasMore={this.props.page < this.props.lastPage}
        scrollReady={this.state.scrollReady}
        loadMore={this.loadMoreVideos}
      >
        <section style={{ margin: "20px 0px" }}>
          <VideoFilters />
          <VideoThumbnailList
            style={{
              listStyleType: "none",
              padding: "calc(3.5% + 5px)",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              width: "100%",
              display: "flex"
            }}
            from="listWithFilters"
            loaded={this.state.loaded}
            finishLoading={this.finishLoading}
            videos={this.props.videos}
          />
        </section>
      </DigitalLibraryInfiniteScroll>
    )
  }
}

function mapDispatchToProps(dispatch) {
  const { loadVideosWithTypeAndCategory, loadMoreVideos } = Actions
  return bindActionCreators(
    { loadVideosWithTypeAndCategory, loadMoreVideos },
    dispatch
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    fetchingVideos: state.digitalLibrary.fetchingVideos,
    videos: state.digitalLibrary.videos.allIds.map(
      (videoId) => state.digitalLibrary.videos.byId[videoId]
    ),
    page: state.digitalLibrary.videos.page,
    lastPage: state.digitalLibrary.videos.lastPage
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoThumbnailListWithFilters)
)
