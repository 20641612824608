// eslint-disable-next-line
import React from "react"
import PropTypes from "prop-types"

const WithAdminAccount = ({
  renderAdminComponent,
  renderNonAdminComponent,
  user
}) => {
  return (
    <>
      {user && user.role && user.role.name === "admin"
        ? renderAdminComponent()
        : renderNonAdminComponent()}
    </>
  )
}

WithAdminAccount.propTypes = {
  adminComponent: PropTypes.func,
  nonAdminComponent: PropTypes.func,
  user: PropTypes.object
}

export default WithAdminAccount
