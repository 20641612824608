const useDigitalLibraryOptions = (match) => {
  const options = [
    {
      text: "Overview",
      usi: "home",
      isSelected: /digitalLibrary\/home/.test(match.url),
      route: "/digitalLibrary/home"
    },
    {
      text: "Journey",
      usi: "journey",
      isSelected: /digitalLibrary\/transition/.test(match.url),
      route: "/digitalLibrary/transition"
    },
    {
      text: "Insights",
      usi: "insights",
      isSelected: /insights/.test(match.url),
      route: "/digitalLibrary/insights/all"
    },
    {
      text: "Industry",
      usi: "industry",
      isSelected: /industry/.test(match.url),
      route: "/digitalLibrary/industry/all"
    },
    {
      text: "Full Interviews",
      usi: "fullInterviews",
      isSelected: /full_interviews/.test(match.url),
      route: "/digitalLibrary/full_interviews/all"
    }
  ]

  return options
}

export default useDigitalLibraryOptions
