import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import Actions from "../../Redux/Actions"
import UserBiographyRender from "../UserBiographyRender/UserBiographyRender"
import UserBiographySkeletonRender from "../UserBiographySkeletonRender/UserBiographySkeletonRender"

import "./userBiography.css"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    military: state.user?.user?.military,
    competitive_tier: state.user?.user?.competitive_tier,
    firstName: state.user.user.firstName,
    lastName: state.user.user.lastName,
    biography: state.user.user.biography && state.user.user.biography.biography,
    id: state.user.user.id,
    workshops: state.workshop?.workshops
  }
}

class UserBiography extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  async componentDidMount() {
    // TODO: React doesn't doing async calls in componentWillMount. I think this is for updating a bio then coming back? Could we do this in a componentDidMount?
    const { fetchUser } = this.props
    await fetchUser()
    this.setState({ loaded: true })
  }

  render() {
    const { loaded } = this.state
    const {
      id,
      firstName,
      lastName,
      military,
      workshops,
      biography
    } = this.props

    const renderedBio =
      id && loaded ? (
        <UserBiographyRender
          user={{
            firstName,
            lastName,
            military,
            workshops,
            biography: {
              biography
            }
          }}
        />
      ) : (
        <UserBiographySkeletonRender />
      )
    return <>{renderedBio}</>
  }
}

UserBiography.propTypes = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  military: PropTypes.objectOf(PropTypes.any),
  biography: PropTypes.string,
  fetchUser: PropTypes.func,
  workshops: PropTypes.arrayOf(PropTypes.any)
}

UserBiography.defaultProps = {
  id: 0,
  firstName: "",
  lastName: "",
  military: {},
  biography: "Consider adding a biography!",
  fetchUser: () => {},
  workshops: []
}

export default connect(mapStateToProps, mapDispatchToProps)(UserBiography)
