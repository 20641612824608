import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import colors from "../../Constants/colors"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import { LeftArrow } from "../../Icons/LeftArrow"

const styleSheet = {
  drawer: {
    width: "100%",
    backgroundColor: colors.midnight,
    marginTop: "60px"
  },
  secondaryDrawer: {
    width: "100%",
    height: "100%",
    backgroundColor: colors.midnight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}

const baseUrl = "https://www.commitfoundation.org"

export class WebSecondaryDrawerContent extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          backgroundColor: colors.midnight,
          color: "white",
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "auto"
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            onClick={this.props.closeSecondary}
            style={{
              cursor: "pointer",
              // marginLeft: "40px",
              paddingLeft: "10px",
              display: "flex",
              padding: "10px 40px 10px 40px",
              marginTop: "25px",
              marginBottom: "25px",
              alignItems: "center",
              flex: 1
            }}
          >
            <LeftArrow stroke="#787f8c" strokeWidth="1" />
            <div
              style={{
                marginLeft: "25px",
                letterSpacing: "0.8px",
                fontFamily: "Lato-SemiBold",
                textDecoration: "none",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "20px",
                color: "#787f8c"
              }}
            >
              Back
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 3,
              padding: "10px 40px 10px 40px",
              letterSpacing: "0.8px"
            }}
          >
            <h1
              style={{
                fontSize: "22px",
                color: colors.white,
                paddingBottom: "10px",
                fontFamily: "Lato-SemiBold"
              }}
            >
              {" "}
              {this.props.title}{" "}
            </h1>
            {this.props.options.map((option) => {
              return (
                <div
                  style={{
                    marginBottom: "10px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    fontFamily: "Lato-Light",
                    fontSize: "18px",
                    cursor: "pointer",
                    color: colors.white
                  }}
                >
                  {" "}
                  <a
                    style={{ textDecoration: "none", color: "inherit" }}
                    href={
                      option.customLink
                        ? option.customLink
                        : `${baseUrl}/${option.link}`
                    }
                  >
                    {option.name}{" "}
                  </a>
                </div>
              )
            })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            bottom: "0px",
            width: "100%",
            // position: "fixed",
            WebkitBoxOrient: "horizontal",
            WebkitBoxDirection: "normal",
            flexDirection: "row",
            WebkitboxPack: "justify",
            justifyContent: "space-between",
            fontSize: "20px",
            backgroundColor: colors.white,
            padding: "10px 40px 10px 40px",
            maxHeight: "100px",
            minHeight: "100px",
            flexGrow: 1,
            flexBasis: "auto",
            borderBottom: "1px solid #787f8c"
          }}
        >
          <div style={styles.bottomButton}>
            {" "}
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={`${baseUrl}/login`}
            >
              {" "}
              Login
            </a>{" "}
          </div>
          <div style={styles.bottomButton}>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={`${baseUrl}/donate`}
            >
              Donate{" "}
            </a>
          </div>
          <div style={styles.bottomButton}>
            {" "}
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href={`${baseUrl}/contact`}
            >
              Contact{" "}
            </a>
          </div>
        </div>{" "}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    userThroughRedux: state.user.user
  }
}

const styles = {
  bottomButton: {
    display: "flex",
    cursor: "pointer",
    padding: 0,
    // fontWeight: 600,
    fontFamily: "Lato-SemiBold",
    letterSpacing: "0.8px",
    borderBottom: "none",
    textDecoration: "none",
    flexDirection: "column",
    justifyContent: "center",
    color: colors.midnight,
    fontSize: "16px"
  }
}

export default WithDrawerConsumer(
  withStyles(styleSheet)(connect(mapStateToProps)(WebSecondaryDrawerContent))
)
