import React from "react"
import { ControlBar, BigPlayButton } from "video-react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import VideoReactPlayer from "../VideoReactPlayer/VideoReactPlayer.js"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"

class DigitalLibraryVideoPlayer extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title && this.props.title) {
      document.title = `${this.props.title} | The Digital Library`
      window.dataLayer.push({
        page_view: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
    }
  }

  render() {
    return this.props.videoUrl ? (
      <div className="video" style={styles.videoContainer}>
        <VideoReactPlayer
          videoTitle={this.props.title}
          aspectRatio="auto"
          autoPlay={false}
          height="100%"
          width="100%"
          src={this.props.videoUrl}
          thumbnailUrl={this.props.thumbnailUrl}
        >
          <BigPlayButton position="center" />
          <ControlBar style={{ color: "red" }} autoHide className="my-class" />
        </VideoReactPlayer>
      </div>
    ) : (
      <SkeletonWrapper
        theme="light"
        containerStyle={{}}
        height={450}
        width={800}
      >
        <rect x="0" y="0" rx="4" ry="4" width="100%" />
      </SkeletonWrapper>
    )
  }
}

const styles = {
  videoContainer: {
    boxShadow: "0 6px 40px 10px rgba(0, 0, 0, 0.15)",
    width: "100%"
  }
}

const mapStateToProps = (state) => ({
  thumbnailUrl: state.digitalLibrary.currentVideo.s3_thumbnail_url,
  videoUrl: state.digitalLibrary.currentVideo.s3_video_url,
  title: state.digitalLibrary.currentVideo.title
})

export default withRouter(connect(mapStateToProps)(DigitalLibraryVideoPlayer))
