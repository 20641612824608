import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { useSelector } from "react-redux"
import colors from "../../Constants/colors"

const useStyles = makeStyles({
  root: {
    height: "fit-content",
    width: "100%",
    margin: "50px",
    overflowX: "auto"
  },
  table: {
    height: "fit-content"
  },
  header: {
    backgroundColor: colors.midnight,
    color: colors.white
  }
})

export default function DeviceTable() {
  const classes = useStyles()

  const analyticsData = useSelector(
    (state) =>
      state.analytics.analyticsData["Device Usage"] &&
      state.analytics.analyticsData["Device Usage"].data
  )

  const tableCellTitles = [
    <TableCell
      key="TableTitle"
      className={classes.header}
      component="th"
      scope="row"
    >
      Device
    </TableCell>
  ]
  let tableRows = []

  for (const key in analyticsData) {
    tableCellTitles.push(
      <TableCell
        key={analyticsData[key].displayName}
        className={classes.header}
        align="right"
      >
        {analyticsData[key].displayName}
      </TableCell>
    )
    for (const innerKey in analyticsData[key]) {
      if (
        tableRows.filter((item) => item.title === innerKey).length ||
        innerKey === "displayName"
      ) {
        // do nothing
      } else {
        tableRows.push({ title: innerKey })
      }
    }
  }

  tableRows = tableRows.map((row) => {
    const elements = []
    for (const key in analyticsData) {
      elements.push(
        <TableCell align="right" key={key}>
          {row.title === "total"
            ? analyticsData[key][row.title]
            : `${(
                (100 * analyticsData[key][row.title]) /
                analyticsData[key].total
              ).toFixed(2)}%`}
        </TableCell>
      )
    }
    return {
      ...row,
      elements
    }
  })
  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>{tableCellTitles}</TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row" key="cellTitle">
                {row.title}
              </TableCell>
              {row.elements}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}
