import React from "react"
import PYPVideoContainer from "../PYPVideoContainer/PYPVideoContainer"
import PYPInstructionLayout from "../PYPInstructionLayout/PYPInstructionLayout"
import LayoutMetaDataUserInput from "../LayoutMetaDataUserInput/LayoutMetaDataUserInput"
import LayoutMetaDataUserInputExample from "../LayoutMetaDataUserInputExample/LayoutMetaDataUserInputExample"
import LayoutMetaDataReference from "../LayoutMetaDataReference/LayoutMetaDataReference.js"
import LayoutButtonSelectionMetaData from "../LayoutButtonSelectionMetaData/LayoutButtonSelectionMetaData.js"
import LayoutButtonSelectionStage2MetaData from "../LayoutButtonSelectionStage2MetaData/LayoutButtonSelectionStage2MetaData.js"
import LayoutMetaDataReferenceUserInput from "../LayoutMetaDataReferenceUserInput/LayoutMetaDataReferenceUserInput.js"
import LayoutMetaDataReferenceUserInputUserInput from "../LayoutMetaDataReferenceUserInputUserInput/LayoutMetaDataReferenceUserInputUserInput.js"
import LayoutMetaDataLeadSelection from "../LayoutMetaDataLeadSelection/LayoutMetaDataLeadSelection.js"
import LayoutMetaDataReferenceUserTimeEvents from "../LayoutMetaDataReferenceUserTimeEvents/LayoutMetaDataReferenceUserTimeEvents"
import LayoutMetaDataConstraints from "../LayoutMetaDataConstraints/LayoutMetaDataConstraints"
import LayoutMetaDataGraphs from "../LayoutMetaDataGraphs/LayoutMetaDataGraphs"
import LayoutMetaDataActionPlanner from "../LayoutMetaDataActionPlanner/LayoutMetaDataActionPlanner"
import LayoutMetaDataCalender from "../LayoutMetaDataCalender/LayoutMetaDataCalender"

const PYPPartComponentMap = {
  Video: (props) => {
    return <PYPVideoContainer />
  },
  Instruction: (props) => {
    return <PYPInstructionLayout />
  },
  "Meta Data,User Input Small": (props) => {
    return <LayoutMetaDataUserInput inputSize="small" />
  },
  "Meta Data,User Input Small,User Input Small,User Input Small": (props) => {
    return <LayoutMetaDataUserInput inputSize="small" numInputs={3} />
  },
  "Meta Data,User Input Medium": (props) => {
    return <LayoutMetaDataUserInput inputSize="medium" />
  },
  "Meta Data,User Input Large": (props) => {
    return <LayoutMetaDataUserInput inputSize="large" />
  },
  "Meta Data,Reference Lead Selection Small": (props) => {
    return <LayoutMetaDataLeadSelection inputSize="small" numInputs={3} />
  },

  // reflections
  "Meta Data,Reference Box Single Small": (props) => {
    return (
      <LayoutMetaDataReference
        referenceBoxType="single"
        referenceBoxSize="small"
      />
    )
  },
  "Meta Data,Reference Box Single Medium": (props) => {
    return (
      <LayoutMetaDataReference
        referenceBoxType="single"
        referenceBoxSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Single Large": (props) => {
    return (
      <LayoutMetaDataReference
        referenceBoxType="single"
        referenceBoxSize="large"
      />
    )
  },
  "Meta Data,Reference Box Carousel Small": (props) => {
    return (
      <LayoutMetaDataReference
        referenceBoxType="carousel"
        referenceBoxSize="small"
      />
    )
  },
  "Meta Data,Reference Box Carousel Medium": (props) => {
    return (
      <LayoutMetaDataReference
        referenceBoxType="carousel"
        referenceBoxSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Carousel Large": (props) => {
    return (
      <LayoutMetaDataReference
        referenceBoxType="carousel"
        referenceBoxSize="large"
      />
    )
  },

  "Meta Data,User Input Example": (props) => {
    return <LayoutMetaDataUserInputExample />
  },
  "Button Selection,Meta Data": (props) => {
    return <LayoutButtonSelectionMetaData />
  },
  "Button Selection Stage 2,Meta Data": (props) => {
    return <LayoutButtonSelectionStage2MetaData />
  },

  // All the fun combinations of Single vs Carousel and Small/Medium/Large Reference boxes X Small/Medium/Large User Inputs
  // This is rather appauling but YOLO

  "Meta Data,Reference Box Single Small,User Input Small": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="small"
        inputSize="small"
      />
    )
  },
  "Meta Data,Reference Box Single Small,User Input Medium": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="small"
        inputSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Single Small,User Input Large": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="small"
        inputSize="large"
      />
    )
  },

  "Meta Data,Reference Box Single Medium,User Input Small": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="medium"
        inputSize="small"
      />
    )
  },
  "Meta Data,Reference Box Single Medium,User Input Medium": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="medium"
        inputSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Single Medium,User Input Large": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="medium"
        inputSize="large"
      />
    )
  },

  "Meta Data,Reference Box Single Large,User Input Small": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="large"
        inputSize="small"
      />
    )
  },
  "Meta Data,Reference Box Single Large,User Input Medium": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="large"
        inputSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Single Large,User Input Large": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="single"
        referenceBoxSize="large"
        inputSize="large"
      />
    )
  },

  "Meta Data,Reference Box Carousel Small,User Input Small": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="small"
        inputSize="small"
      />
    )
  },
  "Meta Data,Reference Box Carousel Small,User Input Medium": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="small"
        inputSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Carousel Small,User Input Large": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="small"
        inputSize="large"
      />
    )
  },

  "Meta Data,Reference Box Carousel Medium,User Input Small": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="medium"
        inputSize="small"
      />
    )
  },
  "Meta Data,Reference Box Carousel Medium,User Input Medium": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="medium"
        inputSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Carousel Medium,User Input Large": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="medium"
        inputSize="large"
      />
    )
  },

  "Meta Data,Reference Box Carousel Large,User Input Small": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="large"
        inputSize="small"
      />
    )
  },
  "Meta Data,Reference Box Carousel Large,User Input Medium": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="large"
        inputSize="medium"
      />
    )
  },
  "Meta Data,Reference Box Carousel Large,User Input Large": (props) => {
    return (
      <LayoutMetaDataReferenceUserInput
        referenceBoxType="carousel"
        referenceBoxSize="large"
        inputSize="large"
      />
    )
  },

  // button selction
  "Meta Data,Reference User Input,User Input Small": (props) => {
    return <LayoutMetaDataReferenceUserInputUserInput inputSize="small" />
  },
  "Meta Data,Reference User Input,User Input Small,User Input Small,User Input Small": (
    props
  ) => {
    return (
      <LayoutMetaDataReferenceUserInputUserInput
        inputSize="small"
        numInputs={3}
      />
    )
  },
  "Meta Data,Reference User Input,User Input Medium": (props) => {
    return <LayoutMetaDataReferenceUserInputUserInput inputSize="medium" />
  },
  "Meta Data,Reference User Input,User Input Large": (props) => {
    return <LayoutMetaDataReferenceUserInputUserInput inputSize="large" />
  },
  // Requires timeEvents to be added in the elements
  "Meta Data,Roadmapper": (props) => {
    return (
      <LayoutMetaDataReferenceUserTimeEvents
        momentFormat={["h:mm a", "h:mm a"]}
        momentJoiner="-"
      />
    )
  },
  "Constraints,Meta Data": (props) => {
    return <LayoutMetaDataConstraints />
  },
  "Bargraph,Meta Data": (props) => {
    return <LayoutMetaDataGraphs />
  },
  "Calender,Meta Data": (props) => {
    return <LayoutMetaDataCalender />
  },
  "Action Planner,Meta Data": (props) => {
    return <LayoutMetaDataActionPlanner />
  },
  // "Meta Data,TimeEvents Long-Term" : props => {
  //   return <LayoutMetaDataReferenceUserTimeEvents momentFormat={["MMM-DD", "MMM-DD"]} momentJoiner={"-"}/>
  // },
  default: (props) => {
    return <div>in progress</div>
  }
}

export default PYPPartComponentMap
