import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import VideoCategoryWrapper from "../VideoCategoryWrapper/VideoCategoryWrapper"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"
import { uppercasify } from "../../Utils/urlParsingUtilities"

export class ListsOfPlaylists extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false,
      notAllVideosShown: {}
    }

    this.receiveVideoDisplayCount = this.receiveVideoDisplayCount.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.url !== this.props.match.url) {
      this.props.loadVideos(this.props.match.params.type)
      this.setState({
        loaded: false
      })
      document.title = `${uppercasify(
        this.props.match.params.type
      )} | The Digital Library`
      window.dataLayer.push({
        page_view: {
          page_path: `${
            this.props.location.pathname + this.props.location.search
          }`,
          page_title: document.title
        }
      })
    } else if (prevProps.fetchingVideos && !this.props.fetchingVideos) {
      this.setState({
        loaded: true
      })
    }
  }

  componentDidMount() {
    this.props.loadVideos(this.props.match.params.type)
    document.title = `${uppercasify(
      this.props.match.params.type
    )} | The Digital Library`
    window.dataLayer.push({
      page_view: {
        page_path: `${
          this.props.location.pathname + this.props.location.search
        }`,
        page_title: document.title
      }
    })
  }

  mapTypeToNoVideos(type) {
    switch (type) {
      case "My Playlist":
        return "Add a video to your playlist!"
      case "Recently Watched":
        return "Start watching videos!"
      default:
        return ""
    }
  }

  receiveVideoDisplayCount(numDisplayedVideos, typeName) {
    this.setState({
      notAllVideosShown: {
        ...this.state.notAllVideosShown,
        [typeName]:
          numDisplayedVideos <
          this.props.types.filter((type) => type.name === typeName)[0].videos
            .length
      }
    })
  }

  render() {
    return (
      <ul style={styles.unorderedList}>
        {this.props.types.map((type, i) => {
          return (
            <li key={i}>
              <VideoCategoryWrapper
                clearVideos={this.props.clearReduxVideos}
                category={type}
                videoCount={type.videos.length}
                notAllVideosShown={
                  type.name === "My Playlist"
                    ? this.state.notAllVideosShown[type.name]
                    : true
                }
              >
                <VideoThumbnailList
                  noVideosMessage={this.mapTypeToNoVideos(type.name)}
                  limit={10}
                  from="listOfPlaylists"
                  playlist={type.name}
                  loaded={this.state.loaded}
                  skeletonLoaderCount={10}
                  dynamicThumbnailCountOnResize
                  style={{
                    justifyContent: "flex-start",
                    padding: "0px"
                  }}
                  videos={type.videos}
                  sendVideoDisplayCount={this.receiveVideoDisplayCount}
                />
              </VideoCategoryWrapper>
            </li>
          )
        })}
      </ul>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  try {
    const { types, videos } = state.digitalLibrary

    return {
      ...props,
      types: types.allIds.length
        ? types.allIds.map((typeId) => {
            return {
              ...types.byId[typeId],
              // TODO: this reduction might or might not be necessary - it's to support browser back/forward navigation. It's possible that the videos and types get out of sync and theres
              // nothing that we can do about that. this ensures that we don't get undefined values for videos
              videos: videos.allIds.length
                ? videos.allIds.length &&
                  types.byId[typeId].videos.reduce((videoArray, videoId) => {
                    if (videos.byId[videoId]) {
                      videoArray.push(videos.byId[videoId])
                    }
                    return videoArray
                  }, [])
                : []
            }
          })
        : [
            { id: -1, videos: [] },
            { id: -1, videos: [] },
            { id: -1, videos: [] }
          ],
      fetchingVideos: state.digitalLibrary.fetchingVideos
    }
  } catch (e) {
    console.log(e)
  }
}

const styles = {
  unorderedList: {
    listStyle: "none",
    padding: "0px",
    paddingTop: "30px"
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListsOfPlaylists)
)
