import React from "react"
import TagManager from 'react-gtm-module'

class GoogleTagManager extends React.Component {
  constructor() {
    super()
    this.state = {
      loaded: false
    }
  }

  componentDidMount() {
   /* const t = function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js"
      })
      const f = d.getElementsByTagName(s)[0]
      const j = d.createElement(s)
      const dl = l !== "dataLayer" ? `&l=${l}` : ""
      j.async = false
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
      f.parentNode.insertBefore(j, f)
    }

    t(window, document, "script", "dataLayer", `GTM-${this.props.gtmId}`)
    */
    const tagManagerArgs = {
      gtmId: this.props.gtmId
    };
   
    TagManager.initialize(tagManagerArgs);

    setTimeout(
      this.setState({
        loaded: true
      })
    );

    this.props.onFinishLoad && this.props.onFinishLoad();
  }
  
  render() {
    return (
      <>
        {/*<noscript>
          <iframe
            title="google tag manager"
            src={`https://www.googletagmanager.com/ns.html?id=GTM-${this.props.gtmId}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>

        <script async src={`https://www.googletagmanager.com/gtag/js?id=${this.props.gtmId}`}></script>
        <script>
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', {`${this.props.gtmId}`});
        </script>*/}


        {this.state.loaded ? this.props.children : "Loading..."}
      </>
    )
  }
}

export default GoogleTagManager
