import React from "react"
import { bindActionCreators } from "redux"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import colors from "../../Constants/colors"

import PYPLayoutContainer from "../PYPLayoutContainer/PYPLayoutContainer.js"
import PYPPartTitleContent from "../PYPPartTitleContent/PYPPartTitleContent.js"
import PYPInputBox from "../PYPInputBox/PYPInputBox.js"
import SkeletonCard from "../SkeletonCard/SkeletonCard.js"

import Actions from "../../Redux/Actions"

class LayoutMetaDataUserInput extends React.Component {
  componentDidMount() {
    if (!this.props.pyp.savingPYP) {
      this.props.fetchPartData().then(() => this.checkForUninitializedInputs())
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pyp.savingPYP && !this.props.pyp.savingPYP) {
      this.props.fetchPartData().then(() => this.checkForUninitializedInputs())
    }
  }

  checkForUninitializedInputs() {
    if (this.props.inputBoxes.length <= 0) {
      this.props.initializePYPInputs(
        this.props.partDataId,
        this.props.inputTitles
      )
    }
  }

  renderInputBoxes() {
    if (!this.props.inputBoxes.length) {
      const skeleton = (i) => (
        <SkeletonCard
          key={i}
          size={this.props.inputSize}
          style={{ margin: "30px 30px 0px 30px" }}
        />
      )
      if (this.props.numInputs) {
        return Array(this.props.numInputs)
          .fill(1)
          .map((e, i) => skeleton(i))
      }
      return skeleton(0)
    }

    return Object.values(this.props.inputTitles).map((inputTitle, key) => {
      // Look up the value of the title from within props.inputBoxes

      const boxInput = this.props.inputBoxes.find((box) => {
        return box.input_title_id === inputTitle.id
      })

      return (
        <PYPInputBox
          key={key}
          text={boxInput ? boxInput.text : ""}
          placeholder=""
          style={{ margin: "30px 30px 0px 30px" }}
          title={inputTitle.title}
          size={boxInput ? boxInput.size : "small"}
          valueDidChange={(value) =>
            this.props.setPYPInputText(
              this.props.partDataId,
              boxInput.id,
              value
            )
          }
          showIncomplete={this.props.pyp.navigation.showIncompletionError}
        />
      )
    })
  }

  render() {
    const inputBoxes = this.renderInputBoxes()
    return (
      <PYPLayoutContainer
        backgroundColor={colors.background}
        style={{ ...this.props.style }}
      >
        <PYPPartTitleContent
          title={this.props.metadata && this.props.metadata.title}
          description={this.props.metadata && this.props.metadata.description}
        />
        <div style={styles.inputBoxContainer}>{inputBoxes}</div>
      </PYPLayoutContainer>
    )
  }
}

const styles = {
  inputBoxContainer: {
    width: "100%",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
    flexWrap: "wrap",
    marginBottom: "30px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
  const partDataId = currentPart.partData

  let metadata = { title: null, description: null }
  let inputBoxes = []
  let inputTitles = []

  // The partData will get fetched separately
  const partData =
    state.pyp.partData.byId[partDataId] &&
    state.pyp.partData.byId[partDataId].data
  if (partData) {
    metadata = partData.title[0]
    inputBoxes = partData.inputs
    inputTitles = partData.inputTitle
  }

  // don't sort if every inputtitle doesn't have a seq
  const dontSort = inputTitles.some((inputTitle) => !inputTitle.sequence)

  return {
    ...props,
    pyp: state.pyp,
    currentPart,
    partDataId,
    metadata,
    inputBoxes,
    inputTitles: dontSort
      ? inputTitles
      : inputTitles.sort((a, b) => (a.sequence > b.sequence ? 1 : -1)),
    initializedPart: state.pyp.initializedPart,
    fetchingPartData: state.pyp.fetchingPartData,
    initializingPYPInputs: state.pyp.initializingPYPInputs
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LayoutMetaDataUserInput)
)
