import React from "react"
import colors from "../../../Constants/colors"
import "./IdealDayNotes.css"

const IdealDayNotes = (props) => {
  return (
    <div
      style={{
        ...props.style,
        color: colors.midnight,
        backgroundColor: colors.background
      }}
    >
      <h2
        className="notesTitle"
        style={{
          fontFamily: "Lato-SemiBold",
          borderBottom: `solid 1px #D8D8D8`
        }}
      >
        Some Questions to Consider...
      </h2>
      <div className="notesContentWrapper">
        {questions.map((questionObj) => {
          return (
            <div>
              {questionObj.category && (
                <h3
                  style={{
                    fontSize: "17px",
                    marginBottom: "10px",
                    fontFamily: "Lato-Bold",
                    color: colors.midnight
                  }}
                >
                  {questionObj.category}
                </h3>
              )}
              <ul
                style={{
                  padding: "0px"
                }}
              >
                {questionObj.questions.map((question) => {
                  return (
                    <li
                      style={{
                        fontFamily: "Lato-Regular",
                        color: colors.text,
                        listStyleType: "none",
                        lineHeight: "22px",
                        marginBottom: "8px"
                      }}
                    >
                      {question}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const questions = [
  {
    category: "",
    questions: [
      "Do you wake to an alarm?",
      "What time?",
      "What happens between the time you wake up and the time you have lunch?",
      "Do you take kids to school?",
      "Is there a commute? If so, what does it look like?",
      "What is the office like? Sunny?",
      "Do you share space?",
      "Are you working from home?",
      "With whom do you work?",
      "Over the course of a month: How many times a month do you want to travel for work? How many nights a week are you home for dinner or out with others?",
      "How many days a week do you want to work from home?",
      "Do you need silence to process or create?",
      "Do you prefer to work outdoors, indoors, or a blend of both?",
      "Do you work better independently or with a team?",
      "What does your ideal day look like – does it start early, late, or a blend of both?",
      "What’s your ideal commute – walk, bike, drive, work from home?",
      "What do the leaders at your firm look like – what are their leadership styles?",
      "Do you like to manage others?",
      "What do you wear to work every day?",
      "Is exercise a desire or possibility?",
      "Do you spend time in meetings?",
      "Is it a corporate environment?",
      "How often do you travel?",
      "What is the time you spend on physical vs. mental engagement?",
      "How much of your day do you spend managing or mentoring vs. doing?",
      "How much of your time are you Speaking vs. Thinking vs. Writing?",
      "Are you working in an Independent/ Autonomous way",
      "Or is it Team-Based/Collaborative Work?",
      "Who do you want to impact?",
      "Are you at home?",
      "Cooking or going out to dinner?",
      "Netflix or a book, or an instrument?",
      "Hobby?",
      "How do you feel at the end of the day, and why?"
    ]
  }
]

export default IdealDayNotes
