import React from "react"
// import PropTypes from 'prop-types'
import CommitNavBar from "../CommitNavBar/CommitNavBar"
import WebDesktopNavMenu from "../WebDesktopNavMenu/WebDesktopNavMenu"
import WebMobileNav from "../WebMobileNav/WebMobileNav"

export const WebNavbar = () => {
  return (
    <CommitNavBar
      desktopMenu={() => {
        return <WebDesktopNavMenu />
      }}
      mobileMenu={() => {
        return <WebMobileNav />
      }}
    />
  )
}

export default WebNavbar
