import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import Loader from "react-loader-spinner"
import Actions from "../../Redux/Actions"
import colors from "../../Constants/colors"
import InboundTrafficTable from "../InboundTrafficTable/InboundTrafficTable"
import DeviceUsageTable from "../DeviceUsageTable/DeviceUsageTable"
import CommitInput from "../CommitInput/CommitInput"
import { useInput } from "../../Hooks/useInput"
import AnalyticFetchingTable from "../AnalyticFetchingTable/AnalyticFetchingTable"
import UserDataTable from "../UserDataTable/UserDataTable"
import TabHandler from "../../Hooks/TabHandler"
import CountAndViewAnalytics from "../CountAndViewAnalytics/CountAndViewAnalytics"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import TwoDimensionAnalyticHandler from "../TwoDimensionAnalyticHandler/TwoDimensionAnalyticHandler"
import AnalyticsUsageGraphContainer from "../AnalyticsUsageGraphContainer/AnalyticsUsageGraphContainer"

const styles = {
  userTableContainer: {
    maxWidth: "100vw",
    width: "90%",
    margin: "auto"
  },
  extendedContainer: {
    width: "90%",
    margin: "auto"
  },
  innerExtendedTable: {
    margin: "auto"
  },
  tabBase: {
    color: colors.gray,
    padding: "30px 20px",
    margin: "0px",
    height: "80px",
    minWidth: "fit-content",
    textAlign: "center",
    cursor: "pointer"
  },
  tabHighlight: {
    color: colors.midnight,
    border: `2px solid ${colors.fadedRed}`,
    borderStyle: "none none solid none"
  },
  tabContainer: {
    display: "flex",
    width: "fit-content",
    margin: "auto"
  },
  contentHeader: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginBottom: "30px",
    height: "fit-content",
    textAlign: "center",
    backgroundColor: colors.white
  },
  dateInput: {
    maxWidth: "200px",
    marginRight: "15px",
    height: "calc(100% - 40px)"
  },
  fetchButton: {
    width: "140px",
    height: "44px",
    marginTop: "20px",
    borderRadius: "3px"
  },
  container: {
    marginTop: "60px",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    minHeight: "calc(100vh - 60px)",
    textAlign: "center"
  },
  rightTables: {
    width: "40%"
  },
  rightTableSingle: {
    width: "100%"
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  fullWidthContainer: {
    width: "90%",
    margin: "auto"
  },
  contentContainer: {
    width: "80%",
    minWidth: "fit-content",
    margin: "auto"
  },
  dateFetchingContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    height: "80px",
    margin: "auto"
  }
}

const Analytics = () => {
  const determineQuarter = (inDate) => {
    if (!inDate) {
      inDate = new Date()
    }
    const year = inDate.getFullYear()
    const Q1 = new Date(`01-01-${year}`)
    const Q2 = new Date(`04-01-${year}`)
    const Q3 = new Date(`07-01-${year}`)
    const Q4 = new Date(`10-01-${year}`)
    if (inDate >= Q4) {
      return Q4
    }
    if (inDate >= Q3) {
      return Q3
    }
    if (inDate >= Q2) {
      return Q2
    }
    return Q1
  }

  const getCurrentDate = (inDate) => {
    let currentDate
    if (!inDate) {
      currentDate = new Date()
    } else {
      currentDate = inDate
    }
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth()
    const date = currentDate.getDate()
    return `${year}-${month < 9 ? "0" : ""}${month + 1}-${
      date < 10 ? "0" : ""
    }${date}`
  }
  const dispatch = useDispatch()
  const [tabId, updateTabId] = useState("Usage")
  const { value: startDate, bind: bindStartDate } = useInput(
    getCurrentDate(determineQuarter())
  )
  const { value: endDate, bind: bindEndDate } = useInput(getCurrentDate())

  const { analytics } = useSelector((state) => state)

  const userTableMap = [
    {
      bucket: "willLocate",
      title: "Willing To Relocate"
    },
    {
      bucket: "ethnicities",
      title: "Ethnicity"
    },

    {
      bucket: "gender",
      title: "Gender"
    },
    {
      bucket: "branch",
      title: "Military Branch"
    },
    {
      bucket: "rank",
      title: "Rank"
    },
    {
      bucket: "status",
      title: "Separation Status"
    },
    {
      bucket: "timeUntilSep",
      title: "Time Until Separation"
    },
    {
      bucket: "sof",
      title: "USSOCOM"
    }
  ]

  const fetchAnalytics = async () => {
    dispatch(Actions.fetchAnalytics(startDate, endDate))
  }

  useEffect(() => {
    fetchAnalytics(startDate, endDate)
  }, [dispatch])

  const fetchingTableMap = {
    "button-by-type": async (buttonType, limit) => {
      dispatch(Actions.fetchButtonAnalytics(buttonType, limit))
    },
    "video-by-type": async (videoType, limit) => {
      dispatch(
        Actions.fetchVideoAnalytics(videoType, limit, startDate, endDate)
      )
    }
  }

  const changeTab = (newId) => {
    updateTabId(newId)
  }

  const userTables = userTableMap.map((item, index) => (
    <UserDataTable
      bucketTitle={item.bucket}
      tableTitle={item.title}
      key={index}
    />
  ))

  const tabMap = {
    Usage: <AnalyticsUsageGraphContainer />,
    Demographic: <div style={styles.userTableContainer}>{userTables}</div>,
    "Inbound Traffic": (
      <div style={styles.fullWidthContainer}>
        <DeviceUsageTable />
        <InboundTrafficTable />
      </div>
    ),
    "Digital Library": (
      <div style={styles.innerContainer}>
        <AnalyticFetchingTable
          dispatchFunction={fetchingTableMap["video-by-type"]}
          options={["All", "Insights", "Industry", "Full Interviews"]}
          analyticsPointer="videoAnalytics"
          cardFormat="two-input"
          title="Most Watched Videos"
          dropdownTitle="Pick a type"
          fetchTextStart="Get Top Videos"
          fetchTextSecond="Get New Videos"
          placeHolder="No videos loaded"
        />
        <div style={styles.rightTables}>
          <CountAndViewAnalytics
            style={styles.rightTableSingle}
            analyticsPointer="videoTypeAnalytics"
            tableTitle="Video Type Stats"
          />
          <CountAndViewAnalytics
            style={styles.rightTableSingle}
            analyticsPointer="videoCategoryAnalytics"
            tableTitle="Video Category Stats"
          />
        </div>
      </div>
    ),
    PYP: (
      <>
        <AnalyticFetchingTable
          dispatchFunction={fetchingTableMap["button-by-type"]}
          options={["All", "values", "strengths", "industries"]}
          analyticsPointer="buttonAnalytics"
          cardFormat="two-input"
          title="Top PYP Buttons (all time)"
          dropdownTitle="Pick a category"
          fetchTextStart="Get Top Buttons"
          fetchTextSecond="Get New Buttons"
          placeHolder="No buttons loaded"
          style={styles.extendedContainer}
          innerStyle={styles.innerExtendedTable}
        />
      </>
    ),
    Email: (
      <>
        <TwoDimensionAnalyticHandler
          analyticsPointer="mailchimp_analytics"
          tableTitle="Mailchimp Stats"
          fixed_values={["open_rate"]}
        />
      </>
    )
  }

  const renderedTab = tabMap[tabId]

  return (
    <div style={styles.container}>
      <div style={styles.contentHeader}>
        <div style={styles.tabContainer}>
          <TabHandler
            clickHandler={changeTab}
            selectedId={tabId}
            tabs={Object.keys(tabMap)}
            style={styles.tabBase}
            highlightStyle={styles.tabHighlight}
            hoverStyle={styles.tabHighlight}
          />
        </div>
        <div style={styles.dateFetchingContainer}>
          <div style={styles.dateInput}>
            <CommitInput
              inputType="date"
              placeHolder="start date"
              style={{
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "10px"
              }}
              {...bindStartDate}
              {...startDate}
            />
          </div>
          <div style={styles.dateInput}>
            <CommitInput
              inputType="date"
              placeHolder="end date"
              style={{
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "10px"
              }}
              {...bindEndDate}
              {...endDate}
            />
          </div>
          <CommitButtonWithClickState
            clickHandler={() => fetchAnalytics()}
            type="white"
            style={styles.fetchButton}
          >
            Fetch Analytics
          </CommitButtonWithClickState>
        </div>
      </div>
      {analytics.fetchingAnalytics ? (
        <div
          className="landingPageLoader"
          style={{
            width: "100%",
            height: "calc(100vh - 60px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Loader
            type="Oval"
            color={colors.midnight}
            height="100px"
            width="100px"
          />
        </div>
      ) : (
        <div style={styles.contentContainer}>{renderedTab}</div>
      )}
    </div>
  )
}

export default Analytics
