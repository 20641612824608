import React, { useEffect, useReducer } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import InputGroup from "../InputGroup/InputGroup";
import "./NonDodIcPersonalInfoInputsThree.css";
import { getCountries, getRegions, getCities } from "../../Utils/locationsApi";
import { setNonDodIcInputData } from "../../Redux/Actions/NonDodIcApplication/functions";
import useDebouncedEffect from "../../Hooks/useDebouncedEffect";

const initialInputsState = {
  country1: {
    userField: "relocations.country1",
    placeHolder: "Country",
    inputType: "select",
    options: [],
    title: "Country",
    className: "select-input",
    disabled: true,
    value: "",
    required: true,
  },
  state1: {
    userField: "relocations.state1",
    placeHolder: "State",
    title: "State",
    inputType: "select",
    options: [],
    className: "select-input",
    disabled: true,
    value: "",
    required: true,
  },
  city1: {
    userField: "relocations.city1",
    placeHolder: "City",
    title: "City",
    inputType: "select",
    options: [],
    className: "select-input",
    disabled: true,
    value: "",
  },
  country2: {
    userField: "relocations.country2",
    placeHolder: "Country",
    inputType: "select",
    options: [],
    title: "Country",
    className: "select-input",
    disabled: true,
    value: "",
    required: true,
  },
  state2: {
    userField: "relocations.state2",
    placeHolder: "State",
    title: "State",
    inputType: "select",
    className: "select-input",
    options: [],
    disabled: true,
    value: "",
    required: true,
  },
  city2: {
    userField: "relocations.city2",
    placeHolder: "City",
    title: "City",
    inputType: "select",
    options: [],
    className: "select-input",
    disabled: true,
    value: "",
  },
  country3: {
    userField: "relocations.country3",
    placeHolder: "Country",
    inputType: "select",
    options: [],
    title: "Country",
    className: "select-input",
    disabled: true,
    value: "",
    required: true,
  },
  state3: {
    userField: "relocations.state3",
    placeHolder: "State",
    title: "State",
    inputType: "select",
    options: [],
    className: "select-input",
    disabled: true,
    value: "",
    required: true,
  },
  city3: {
    userField: "relocations.city3",
    placeHolder: "City",
    title: "City",
    inputType: "select",
    options: [],
    className: "select-input",
    disabled: true,
    value: "",
  }
};

const inputsActions = {
  UPDATE_ALL_COUNTRIES: "UPDATE_ALL_COUNTRIES",
  UPDATE_SELECT_OPTIONS: "UPDATE_SELECT_OPTIONS",
  UPDATE_INITIAL_VALUES: "UPDATE_INITIAL_VALUES",
  CHANGE_INPUT_VALUE: "CHANGE_INPUT_VALUE"
};

const inputsReducer = (state, action) => {
  switch (action.type) {
    case inputsActions.UPDATE_ALL_COUNTRIES: {
      const countries = action.payload;

      return {
        ...state,
        country1: {
          ...state.country1,
          options: countries,
          disabled: false
        },
        country2: {
          ...state.country2,
          options: countries,
          disabled: false
        },
        country3: {
          ...state.country3,
          options: countries,
          disabled: false
        }
      };
    }
    case inputsActions.UPDATE_SELECT_OPTIONS: {
      const { inputKey, data } = action.payload;

      return {
        ...state,
        [inputKey]: {
          ...state[inputKey],
          options: data,
          disabled: false
        }
      };
    }
    case inputsActions.UPDATE_INITIAL_VALUES: {
      const initialValues = action.payload;

      return Object.entries(state).reduce(
        (acumInputs, [inputKey, input], index) => {
          return {
            ...acumInputs,
            [inputKey]: {
              ...input,
              id: index,
              value: initialValues[inputKey]
            }
          };
        },
        {}
      );
    }
    case inputsActions.CHANGE_INPUT_VALUE: {
      const { value, userField } = action.payload;
      const inputKey = userField.split(".")[1];

      return {
        ...state,
        [inputKey]: {
          ...state[inputKey],
          value
        }
      };
    }
    default:
      return state;
  }
};

const NonDodIcPersonalInfoInputsThree = (props) => {
  const [inputDataState, dispatch] = useReducer(
    inputsReducer,
    initialInputsState
  );

  const fetchInitialStatesAndCities = async (countries, inputNumber) => {
    if (!countries.length) return;

    const { relocations } = props.application.application;

    const country = relocations[`country${inputNumber}`];
    const state = relocations[`state${inputNumber}`];

    const { countryId } = countries.find(({ value }) => country === value) || {};
    const regions = await getRegions(countryId);
    const { stateId } = regions.find(({ value }) => state === value) || {};

    dispatch({
      type: inputsActions.UPDATE_SELECT_OPTIONS,
      payload: {
        inputKey: `state${inputNumber}`,
        data: regions
      }
    });

    if (stateId) {
      dispatch({
        type: inputsActions.UPDATE_SELECT_OPTIONS,
        payload: {
          inputKey: `city${inputNumber}`,
          data: await getCities(stateId)
        }
      });
    }
  };

  const onInputChange = async (input, value) => {
    /* For every input change, we fetch more options dynamically */
    const { userField } = input;
    const inputNumber = userField.slice(-1);

    const countryInput = `relocations.country${inputNumber}`;
    const stateInput = `relocations.state${inputNumber}`;
    const cityInput = `relocations.city${inputNumber}`;

    dispatch({
      type: inputsActions.CHANGE_INPUT_VALUE,
      payload: { userField, value }
    });

    if (userField === countryInput) {
      const { countryId } = input.options.find(
        (option) => option.value === value
      ) || {};

      dispatch({
        type: inputsActions.UPDATE_SELECT_OPTIONS,
        payload: {
          inputKey: `state${inputNumber}`,
          data: await getRegions(countryId)
        }
      });

      /* Clear city select options when country input changes */
      dispatch({
        type: inputsActions.UPDATE_SELECT_OPTIONS,
        payload: {
          inputKey: `city${inputNumber}`,
          data: []
        }
      });

      /* Clear state and city inputs when country input changes */
      dispatch({
        type: inputsActions.CHANGE_INPUT_VALUE,
        payload: { userField: stateInput, value: "" }
      });

      dispatch({
        type: inputsActions.CHANGE_INPUT_VALUE,
        payload: { userField: cityInput, value: "" }
      });
    }

    if (userField === stateInput) {
      const { stateId } = input.options.find((option) => option.value === value) || {};

      dispatch({
        type: inputsActions.UPDATE_SELECT_OPTIONS,
        payload: {
          inputKey: `city${inputNumber}`,
          data: await getCities(stateId)
        }
      });

      /* Clear city input when state input changes */
      dispatch({
        type: inputsActions.CHANGE_INPUT_VALUE,
        payload: { userField: cityInput, value: "" }
      });
    }
  };

  useEffect(() => {
    const loadInitialValues = async () => {
      dispatch({
        type: inputsActions.UPDATE_INITIAL_VALUES,
        payload: props.application.application.relocations
      });

      const countries = await getCountries();

      dispatch({
        type: inputsActions.UPDATE_ALL_COUNTRIES,
        payload: countries
      });

      /* Fetch input options for states and cities on initial load */
      fetchInitialStatesAndCities(countries, "1");
      fetchInitialStatesAndCities(countries, "2");
      fetchInitialStatesAndCities(countries, "3");
    };

    loadInitialValues();
  }, []);

  useDebouncedEffect(
    () => {
      /* Sync input state with global store */
      props.setNonDodIcInputData(Object.values(inputDataState));
    },
    500,
    [inputDataState]
  );

  return (
    <InputGroup
      {...props}
      containerClass="personalInfoInputsThree"
      inputData={Object.values(inputDataState)}
      onInputChange={onInputChange}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) =>
        props.renderComponent({
          ...props,
          inputData,
          inputComponent,
          checkSubmittability
        })
      }
    />
  );
};

NonDodIcPersonalInfoInputsThree.propTypes = {
  application: PropTypes.shape({
    application: PropTypes.shape({
      relocations: PropTypes.object
    })
  }).isRequired,
  setNonDodIcInputData: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  application: state.nonDodIcApplication, 
  apiToken: state.api.apiToken
});

export default connect(mapStateToProps, { setNonDodIcInputData })(
  NonDodIcPersonalInfoInputsThree
);
