const types = {
  SELECTED_APPLICATION_NAV_ITEM: "SELECTED_APPLICATION_NAV_ITEM",
  FETCHED_APPLICATION: "FETCHED_APPLICATION",
  TOGGLE_UNFILLED: "TOGGLE_UNFILLED",
  SUBMIT_APPLICATION: "SUBMIT_APPLICATION",
  SUBMITTED_APPLICATION: "SUBMITTED_APPLICATION",
  EDIT_APPLICATION: "EDIT_APPLICATION",
  INIT_APPLICATION: "INIT_APPLICATION",
  SET_APPLICATION_INPUT_DATA: "SET_APPLICATION_INPUT_DATA",
  SAVING_APPLICATION: "SAVING_APPLICATION",
  APPLY_CLICKED: "APPLY_CLICKED",
  WILL_RELOCATE_CHANGED: "WILL_RELOCATE_CHANGED",
  WILL_RELOCATE_SPOUSE_CHANGED: "WILL_RELOCATE_SPOUSE_CHANGED",
  FETCHED_SPOUSE_APPLICATION: "FETCHED_SPOUSE_APPLICATION",
  UPDATE_LIKERT_SPOUSE: "UPDATE_LIKERT_SPOUSE"
}

export default types
