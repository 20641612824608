import React, { Component } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MediaQuery from "react-responsive"
import { withRouter } from "react-router-dom"
import VideoCategoryWrapper from "../VideoCategoryWrapper/VideoCategoryWrapper"
import Actions from "../../Redux/Actions"
import "./BiographyDigitalLibrary.css"
import SkeletonWrapper from "../SkeletonWrapper/SkeletonWrapper"
import ProgramApprovalContainer from "../ProgramApprovalContainer/ProgramApprovalContainer"
import colors from "../../Constants/colors"
import digitalLibraryPageLarge from "../../Assets/Images/DL-page.png"
import TransparentButton from "../TransparentButton/TransparentButton"
import VideoThumbnailList from "../VideoThumbnailList/VideoThumbnailList"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  try {
    const { videos } = state.digitalLibrary

    return {
      ...props,
      videos: videos.allIds.map((id) => {
        return videos.byId[id]
      }),
      guides: state.user.user.guides,
      LANDING_PAGE_DIGITAL_LIBRARY_GUIDE: state.user.user.guides // If the guide exists in pivot table, return it
        ? state.user.user.guides.find(
            (guide) =>
              guide.unique_string_identifier === "LANDING_PAGE_DIGITAL_LIBRARY"
          )
        : null,
      fetchingVideos: state.digitalLibrary.fetchingVideos,
      approvalProps: {
        approvalMessage:
          "COMMIT’s Digital Library is thoughtfully curated with hundreds of proprietary video interviews with our deep bench of COMMIT mentors and alumni.",
        route: "/digitalLibrary",
        redirectButtonText: "Start Watching",
        GUIDE_USI: "LANDING_PAGE_DIGITAL_LIBRARY"
      }
    }
  } catch (e) {
    return {}
  }
}

const styles = {
  exploreButton: {
    backgroundColor: "transparent",
    border: "none",
    color: colors.fadedRed
  },
  containerBaseCSS: {
    width: "100%",
    marginBottom: "0px"
  },
  spanCSS: {
    display: "flex",
    height: "auto",
    width: "auto",
    margin: "25px 0px 9px 5px",
    alignItems: "center"
  },
  categoryTitleCSS: {
    width: "171px",
    height: "28px",
    fontFamily: "Lato-SemiBold",
    fontSize: "18px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.11,
    letterSpacing: "0.62px",
    color: "#030d26"
  },
  categoryDescCSS: {
    width: "auto",
    height: "28px",
    fontFamily: "Lato-Regular",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.43,
    letterSpacing: "0.49px"
  },
  categoryButtonCSS: {
    gridArea: "button",
    textAlign: "right",
    height: "100%",
    cursor: "pointer",
    color: colors.fadedRed,
    fontSize: "16px",
    fontFamily: "Lato-Regular",
    marginRight: "9%"
  },
  categoryButtonContainerCSS: {
    marginRight: "5px"
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class BiographyDigitalLibrary extends Component {
      constructor(props) {
        super(props)
        this.state = {
          loaded: false,
          skeletonWidth: 0
        }
        this.digitalLibraryRedirect = this.digitalLibraryRedirect.bind(this)
      }

      async componentDidMount() {
        this.resizeSkeletonWidth()
        await this.props.getRecommendedVideos({ limit: 4 })
        this.setState({ loaded: true })
        window.addEventListener("resize", this.resizeSkeletonWidth)
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.resizeSkeletonWidth)
      }

      resizeSkeletonWidth = () => {
        const container = document.getElementById(
          "biographyDigitalLibraryContainer"
        )
        if (container) {
          this.setState({ skeletonWidth: container.clientWidth })
        }
      }

      digitalLibraryRedirect() {
        this.props.history.push("/DigitalLibrary")
      }

      render() {
        const exploreLibraryButton = (
          <div style={{ ...styles.categoryButtonContainerCSS }}>
            <TransparentButton
              style={{ ...styles.categoryButtonCSS }}
              onClick={this.digitalLibraryRedirect}
            >
              <button
                type="button"
                aria-label="explore digital library"
                style={styles.exploreButton}
              >
                Explore
              </button>
            </TransparentButton>
          </div>
        )

        const renderedDigitalLibrary =
          this.props.guides &&
          this.props.LANDING_PAGE_DIGITAL_LIBRARY_GUIDE &&
          this.state.loaded ? (
            <div
              className="biographyDigitalLibraryContainer"
              id="biographyDigitalLibraryContainer"
            >
              <VideoCategoryWrapper
                containerBaseCSS={{ ...styles.containerBaseCSS }}
                categoryTitleCSS={{ ...styles.categoryTitleCSS }}
                categoryDescCSS={{ ...styles.categoryDescCSS }}
                categoryTitleDescContainerCSS={{ gridArea: "titleDesc" }}
                customButton={exploreLibraryButton}
                categoryContainerCSS={{ ...styles.spanCSS }}
                category={{
                  name: "Digital Library"
                }}
                limit={4}
              >
                <VideoThumbnailList
                  setHeight="inherit"
                  setWidth="20%"
                  margin="0 10px"
                  from="landingPage"
                  playlist="reccomended"
                  loaded={this.state.loaded}
                  skeletonLoaderCount={4}
                  style={{
                    justifyContent: "space-between"
                  }}
                  dynamicThumbnailCountOnResize
                  // TODO: not a huge fan of this ||, but it's necessary in the current implmentation
                  // for browser forward/back navigation
                  // videos={type.videos}
                  videos={this.props.videos}
                />
              </VideoCategoryWrapper>
            </div>
          ) : this.props.guides && this.state.loaded ? (
            <ProgramApprovalContainer
              name="digitalLibrary"
              data={{ ...this.props.approvalProps }}
              pageImage={digitalLibraryPageLarge}
            />
          ) : (
            <div
              className="biographyDigitalLibraryContainer"
              id="biographyDigitalLibraryContainer"
            >
              <MediaQuery minWidth={892}>
                <SkeletonWrapper
                  height="290"
                  width={this.state.skeletonWidth}
                  containerStyle={{
                    width: `${this.state.skeletonWidth}px`,
                    height: "290px"
                  }}
                >
                  <rect
                    x={this.state.skeletonWidth * 0.05}
                    y="33.5"
                    width={this.state.skeletonWidth * 0.4}
                    height="223"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="70"
                    width={this.state.skeletonWidth * 0.22}
                    height="40"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.73}
                    y="70"
                    width={this.state.skeletonWidth * 0.22}
                    height="40"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="120"
                    width={this.state.skeletonWidth * 0.45}
                    height="20"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="150"
                    width={this.state.skeletonWidth * 0.45}
                    height="20"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.5}
                    y="180"
                    width={this.state.skeletonWidth * 0.4}
                    height="20"
                  />
                </SkeletonWrapper>
              </MediaQuery>
              <MediaQuery maxWidth={891}>
                <SkeletonWrapper
                  height="290"
                  width={this.state.skeletonWidth}
                  containerStyle={{
                    width: `${this.state.skeletonWidth - 20}px`,
                    height: "290px"
                  }}
                >
                  <rect
                    x={this.state.skeletonWidth * 0.1}
                    y="80"
                    width="200"
                    height="132"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.2 + 200}
                    y="80"
                    width={this.state.skeletonWidth * 0.22}
                    height="40"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.43 + 200}
                    y="80"
                    width={this.state.skeletonWidth * 0.22}
                    height="40"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.2 + 200}
                    y="130"
                    width={this.state.skeletonWidth * 0.45}
                    height="20"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.2 + 200}
                    y="160"
                    width={this.state.skeletonWidth * 0.45}
                    height="20"
                  />
                  <rect
                    x={this.state.skeletonWidth * 0.2 + 200}
                    y="190"
                    width={this.state.skeletonWidth * 0.4}
                    height="20"
                  />
                </SkeletonWrapper>
              </MediaQuery>
            </div>
          )
        return <>{renderedDigitalLibrary}</>
      }
    }
  )
)
