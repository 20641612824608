import React from "react"

export const ThumbsUp = (props) => {
  return (
    <svg
      width="20"
      style={{ ...props.style }}
      height="23"
      viewBox="0 0 20 23"
      onClick={props.onClick}
    >
      <defs>
        <path id="a" d="M.113.01h14.903v19.22H.113z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(4.301 .132)">
          <mask
            id="b"
            fill="#fff"
            dangerouslySetInnerHTML={{ __html: '<use xlink:href="#a" />' }}
          />
          <path
            fill={props.fill || "black"}
            d="M7.371 4.783c.212-1.011.074-1.968-.513-2.827-.214-.314-.512-.583-.953-.509-.369.063-.546.361-.612.67-.263 1.228-.698 2.386-1.356 3.457-.477.777-1.049 1.482-1.836 1.957-.367.222-.52.469-.518.883.009 2.666.004 5.331.003 7.997 0 .525.061.584.608.584h4.3v-.011c1.481 0 2.962.014 4.443-.006.658-.01 1.038-.375 1.159-.998.131-.676.27-1.35.409-2.025.177-.861.36-1.72.533-2.582.167-.838.298-1.684.49-2.517.216-.938-.35-1.746-1.314-1.706-1.06.044-2.115.18-3.173.27-.773.064-1.546.134-2.32.167-.395.017-.616-.226-.651-.61a.678.678 0 0 1 .517-.703c.354-.083.559-.284.611-.653m-6.097.31c.193.217.401.265.653-.007.742-.801 1.359-1.686 1.79-2.681.2-.463.254-.989.377-1.485.444-1.796 2.364-2.24 3.607-1.18.842.718 1.258 1.672 1.284 2.756.019.765-.082 1.536-.16 2.301-.032.308.158.327.342.319.572-.025 1.144-.071 1.714-.124.332-.03.661-.095.993-.136 2.201-.27 3.806 1.521 3.178 3.837-.233.857-.361 1.742-.538 2.614-.165.814-.327 1.63-.497 2.442-.147.697-.267 1.402-.466 2.084-.239.816-.811 1.347-1.628 1.6-.91.281-1.85.166-2.778.171-2.28.014-4.562.021-6.843-.011-.576-.009-.998.193-1.41.549-.158.137-.398.242-.606.25"
            mask="url(#b)"
          />
        </g>
        <path
          fill={props.fill || "black"}
          d="M8.984 21.615c1.911 0 3.822-.005 5.734.002.585.002.87.391.682.948a.643.643 0 0 1-.35.342c-.194.073-.422.078-.635.078-3.63.005-7.262.005-10.894.003-.178 0-.36-.006-.535-.034-.417-.067-.6-.304-.577-.718.02-.346.284-.605.662-.615.513-.014 1.027-.009 1.54-.01h4.373v.004zM2.066 6.747a.632.632 0 0 0-.632.63v10.07c0 .348.283.63.632.63h1.777a.632.632 0 0 0 .633-.63V7.377a.632.632 0 0 0-.633-.63H2.066zm1.777 12.76H2.066A2.065 2.065 0 0 1 0 17.446V7.377c0-1.135.927-2.059 2.066-2.059h1.777c1.14 0 2.067.924 2.067 2.06v10.07a2.065 2.065 0 0 1-2.067 2.058z"
        />
      </g>
    </svg>
  )
}
