import React from "react"
import { connect } from "react-redux"
import InputGroup from "../InputGroup/InputGroup"
import "./AboutYouOne.css"

const AboutYouOne = (props) => {
  const data = [
    {
      id: 0,
      className: "branchOfService",
      inputType: "select",
      options: [
        { id: 0, value: "Active Duty Spouse/Partner" },
        { id: 1, value: "Veteran Spouse/Partner" },
        { id: 5, value: "Other" }
      ],
      placeHolder: "Branch",
      userField: "active_duty_status",
      title: "What is your status?"
    },
    {
      id: 1,
      inputType: "text",
      className: "specialtyProfile",
      placeHolder: "Last Duty Station",
      userField: "last_duty_station",
      title: `Last Duty Station`
    },
    {
      id: 2,
      userField: "how_long_connected",
      className: "rank",
      inputType: "select",
      options: [
        { id: 0, value: "0-4 years" },
        { id: 1, value: "5-10 years" },
        { id: 2, value: "10-20 years" },
        { id: 3, value: "20+ years" }
      ],
      title: "How long have you been connected to the military?"
    }
  ]

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1") // convert indexes to properties
    s = s.replace(/^\./, "") // strip a leading dot
    const a = s.split(".")
    for (let i = 0, n = a.length; i < n; ++i) {
      const k = a[i]
      if (o && k in o) {
        o = o[k]
      } else {
        return
      }
    }
    return o
  }

  const inputDataWithValues = data.map((input) => {
    return {
      ...input,
      value:
        Object.byString(props.application.application, input.userField) || ""
    }
  })

  return (
    <InputGroup
      {...props}
      containerClass="profileMilitaryOneContainer"
      inputData={inputDataWithValues}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) => {
        return (
          <>
            {props.renderComponent({
              ...props,
              inputData,
              inputComponent,
              checkSubmittability
            })}
          </>
        )
      }}
    />
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    application: state.application
  }
}

export default connect(mapStateToProps)(AboutYouOne)
