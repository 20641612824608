import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useDispatch, useSelector } from "react-redux"
import MediaQuery from "react-responsive"

import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import userIcon from "../../Assets/Images/user-placeholder.png"

import {
  toggleModal,
  scheduleDVSMeeting
} from "../../Redux/Actions/User/functions"

const modalUsi = "scheduleDVSModal"

const styles = {
  modalContainer: {
    maxWidth: "800px",
    width: "85%",
    background: "#F5F6F8",
    fontFamily: "Lato-Regular"
  },
  modalOverlay: {
    padding: "36px 56px 0 56px"
  },
  header: {
    display: "flex",
    marginBottom: "36px"
  },
  headerImageContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  headerImageRedCircle: {
    height: "170px",
    width: "170px",
    borderRadius: "85px",
    border: "3px solid red",
    padding: "4px"
  },
  imagePlaceholder: {
    backgroundColor: "#c4c4c4bd",
    borderRadius: "85px",
    width: "100%",
    height: "100%"
  },
  headerContentContainer: {
    flex: 2,
    marginLeft: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  headerContentTitle: {
    fontSize: "24px",
    fontWeight: 600
  },
  headerContentSubtitle: {
    fontSize: "18px",
    marginBottom: "10px",
    fontFamily: "Lato-Light"
  },
  headerContentLocation: {
    fontSize: "14px",
    fontStyle: "italic",
    marginBottom: "20px",
    fontFamily: "Lato-Light"
  },
  headerContactLink: {
    color: "black",
    fontSize: "14px",
    textDecoration: "underline"
  },
  contentContainer: {
    marginBottom: "50px"
  },
  footerButton: {
    margin: "0 56px 36px 56px ",
    height: "58px",
    fontSize: "16px",
    borderRadius: "10px"
  },
  footerMessage: {
    marginLeft: "64px",
    fontFamily: "Lato-Bold"
  }
}

const ScheduleDVSModal = () => {
  const dispatch = useDispatch()

  const dvsInfo = useSelector((state) => {
    const { dvsPersonUser: user, schedule_meeting_url: scheduleMeetingUrl } =
      state.user?.user?.dvsPerson || {}

    return {
      imageUrl: user?.profile_image_url,
      linkedInUrl: user?.linkedin_url,
      firstName: user?.firstName,
      lastName: user?.lastName,
      profession: user?.profession?.otherInterest,
      city: user?.address?.city,
      state: user?.address?.state,
      biography: user?.biography?.biography,
      scheduleMeetingUrl
    }
  })

  const onScheduleMeetingPress = () => {
    if (!dvsInfo.scheduleMeetingUrl) return

    window.open(dvsInfo.scheduleMeetingUrl)

    dispatch(scheduleDVSMeeting())

    dispatch(
      toggleModal({
        modal_mode: false,
        modal_usi: modalUsi
      })
    )
  }

  return (
    <ModalDialogHandler
      modal_usi={modalUsi}
      paperProps={{ style: styles.modalContainer }}
      footer={
        <>
          <span style={styles.footerMessage}>
            <strong>
              Next Step: Click below to schedule your initial appointment
            </strong>
          </span>
          <DialogActions>
            <CommitButtonWithoutClickState
              clickHandler={onScheduleMeetingPress}
              id={modalUsi}
              type={dvsInfo.scheduleMeetingUrl ? "whiteMediumBlue" : "gray"}
              style={styles.footerButton}
            >
              Schedule a meeting / Calendly
            </CommitButtonWithoutClickState>
          </DialogActions>
        </>
      }
      showCloseButton
    >
      <div style={styles.modalOverlay}>
        <div style={styles.header}>
          <MediaQuery minWidth={769}>
            <div style={styles.headerImageContainer}>
              <div style={styles.headerImageRedCircle}>
                <img
                  src={dvsInfo.imageUrl || userIcon}
                  alt="User icon placeholder"
                  style={styles.imagePlaceholder}
                />
              </div>
            </div>
          </MediaQuery>
          <div style={styles.headerContentContainer}>
            <span style={styles.headerContentTitle}>
              {`${dvsInfo.firstName} ${dvsInfo.lastName}`}
            </span>
            <span style={styles.headerContentSubtitle}>
              {dvsInfo.profession}
            </span>
            <span style={styles.headerContentLocation}>
              {dvsInfo.city} {dvsInfo.state && `/ ${dvsInfo.state}`}
            </span>
            <a
              style={styles.headerContactLink}
              href={dvsInfo.linkedInUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Linkedin
            </a>
          </div>
        </div>
        <div style={styles.contentContainer}>
          <span>{dvsInfo.biography}</span>
        </div>
      </div>
    </ModalDialogHandler>
  )
}

ScheduleDVSModal.modalUsi = modalUsi

export default ScheduleDVSModal
