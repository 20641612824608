import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserFieldEditorContainer from "../UserFieldEditorContainer/UserFieldEditorContainer";
import NonDodIcApplicationFooter from "../NonDodIcApplicationFooter/NonDodIcApplicationFooter";
import NonDodIcApplicationInputs from "../NonDodIcApplicationInputs/NonDodIcApplicationInputs";
import NonDodIcApplicationNavMenu from "../NonDodIcApplicationNavMenu/NonDodIcApplicationNavMenu";
import { NonDodIcApplicationSteps } from "../../Redux/Reducers/NonDodIcApplication/NonDodIcApplicationMetadata";
import Actions from "../../Redux/Actions/NonDodIcApplication/functions";

import NonDodICApplicationHeader from "./NonDodIcApplicationHeader";

import {
  setCurrentFormulary,
  getFormularyQuestions,
} from "../../Redux/Actions/Formulary/functions";

const NonDodIcApplication = (props) => {
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState({});
  const { applicationInitialized, submittingApplication, user } = useSelector(
    ({ nonDodIcApplication = {}, user: userState = {} }) => ({
      applicationInitialized: nonDodIcApplication.initialized || false,
      submittingApplication: nonDodIcApplication.submittingApplication || false,
      user: userState.user || null,
    })
  );

  useEffect(() => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    const documentTitle = NonDodIcApplicationSteps.find(
      (elem) => elem.stepId === pageTitle
    ).name;
    document.title = `${documentTitle} | Non-DOD IC Application Process`;
    dispatch(Actions.fetchNonDodIcApplication("initialLoad", props.history)); // Updated action
  }, [dispatch, props.history]);

  useEffect(() => {
    (async () => {
      const formularyType = "non-dod-ic-application";

      dispatch(setCurrentFormulary(formularyType, "Non-DOD IC Application"));
      await dispatch(getFormularyQuestions(formularyType));
    })();
  }, [dispatch]);

  return (
    <>
      <UserFieldEditorContainer
        initialized={applicationInitialized && !submittingApplication}
        drawerComponent={() => <NonDodIcApplicationNavMenu />}
      >
        <NonDodICApplicationHeader />
        <NonDodIcApplicationInputs setInputData={setInputData} />
        <NonDodIcApplicationFooter inputData={inputData} />
      </UserFieldEditorContainer>
    </>
  );
};

export default withRouter(NonDodIcApplication);
