import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Actions from "../../Redux/Actions";
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer";
import UserFieldEditorFooter from "../UserFieldEditorFooter/UserFieldEditorFooter";
import { NonDodIcApplicationSteps } from "../../Redux/Reducers/NonDodIcApplication/NonDodIcApplicationMetadata";
import constants from "../../Constants";
import { allFormsFilled } from "../../Redux/Utils/reducerUtils";
import ApplicationConfirmationModal from "./NonDodIcApplicationConfirmationModal"; // Adjust if you have a custom confirmation modal

const NonDodIcApplicationFooter = (props) => {
  const getNextPage = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    const currentPageIndex = NonDodIcApplicationSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    );

    if (pageTitle === "personal_info-address") {
      if (props.application && props.application.userWillReLocate) {
        return NonDodIcApplicationSteps[currentPageIndex + 1].stepId;
      }
      return NonDodIcApplicationSteps[currentPageIndex + 2].stepId;
    }
    return NonDodIcApplicationSteps[currentPageIndex + 1].stepId;
  };

  const getPreviousPage = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    const currentPageIndex = NonDodIcApplicationSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    );

    if (currentPageIndex === 0) {
      return pageTitle;
    }

    if (pageTitle === "military-basic") {
      if (props.application && props.application.userWillReLocate) {
        return NonDodIcApplicationSteps[currentPageIndex - 1].stepId;
      }
      return NonDodIcApplicationSteps[currentPageIndex - 2].stepId;
    }
    return NonDodIcApplicationSteps[currentPageIndex - 1].stepId;
  };

  const displayErrorMessage = (message) => {
    removeErrorMessage();
    const dateInput = document.querySelector('input[type="date"]');
    const errorMessage = "Invalid date of birth.";
    const errorElement = document.createElement("span");
    errorElement.textContent = errorMessage;
    errorElement.style.fontSize = "16px";
    errorElement.classList.add("text-danger");
    const parentElement = dateInput.parentNode;
    parentElement.parentNode.insertBefore(
      errorElement,
      parentElement.nextSibling
    );
  };

  const removeErrorMessage = () => {
    const errorElement = document.querySelector(".text-danger");
    if (errorElement) {
      errorElement.remove();
    }
  };

  const checkStepFilled = (pageTitle) => {
    let applicationMetadata = props.application.applicationMetadata;
    let selectedStep;
    let isfilled = true;

    try {
      const { stepId } = NonDodIcApplicationSteps.find(
        (elem) => elem.stepId === pageTitle
      );
      applicationMetadata.find((input) => {
        input.steps.find((step) => {
          if (step.stepId === stepId) {
            selectedStep = step;
          }
        });
      });
      isfilled = selectedStep.filled;
      return isfilled;
    } catch (e) {
      return isfilled;
    }
  };

  const validateField = (input, value, selectedItems) => {
    const pageTitle = queryString.parse(props.history.location.search).title;

    let error = false;
    let helperText = "";
    if (pageTitle === "career-interested_industries") {
      if (
        (Array.isArray(value) && value.length === 0) ||
        (Array.isArray(selectedItems) && selectedItems.length === 0)
      ) {
        error = true;
        helperText = "This field is required";
      }
    } else {
      if (input.required && !value) {
        error = true;
        helperText = "This field is required";
      }
    }

    return { error, helperText };
  };

  const nextHandler = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    let is_valid = true;
    let data;
    if (
      props.inputData !== "" &&
      (props.currentInputData === null || props.currentInputData === undefined)
    ) {
      data = props.inputData;
    } else if (
      props.inputData === "" &&
      (props.currentInputData !== null && props.currentInputData !== undefined)
    ) {
      data = props.currentInputData;
    } else if (
      props.inputData !== "" &&
      (props.currentInputData !== null && props.currentInputData !== undefined)
    ) {
      data = props.currentInputData;
    } else {
      is_valid = false;
    }

    if (data && Object.keys(data).length > 0) {
      data.forEach((d) => {
        const { error, helperText } = validateField(
          d,
          d.value,
          d.selectedItems
        );
        d.error = error;
        d.helperText = helperText;
        if (error && is_valid) {
          is_valid = false;
        }
      });
    } else if (!checkStepFilled(pageTitle)) {
      is_valid = false;
    } else {
      if (pageTitle === "introduction-video") {
        is_valid = true;
      } else {
        is_valid = false;
      }
    }
    if (pageTitle === "personal_info-basic") {
      const dateInput = document.querySelector('input[type="date"]');
      const selectedDate = new Date(dateInput.value);
      const minDate = new Date("1950-01-01");
      const maxDate = new Date();
      if (selectedDate < minDate || selectedDate > maxDate) {
        displayErrorMessage();
        return false;
      } else {
        removeErrorMessage();
      }
    }
    if (pageTitle !== constants.NON_DOD_IC_APPLICATION_PROCESS_LAST_PAGE) {
      props.currentInputData &&
      props.saveNonDodIcApplication(props.currentInputData, props.history);
      props.history.push(`non-dod-ic?title=${getNextPage()}`);

      if (!is_valid) {
        props.history.push(`non-dod-ic?title=${getPreviousPage()}`);
        const appSectionTitle = document.querySelector(".appSectionTitle");

        // Add Error Message
        removeErrorMessage();
        const errorMessage =
          "Please fill all the required (non-optional) fields before proceeding.";
        const errorElement = document.createElement("div");
        errorElement.textContent = errorMessage;
        errorElement.style.fontSize = "16px";
        errorElement.style.textAlign = "center";
        errorElement.classList.add("text-danger");
        const parentElement = appSectionTitle.parentElement;
        parentElement.appendChild(errorElement);
      } else {
        removeErrorMessage();
      }
    } else {
      submitApplication();
    }
  };

  const previousHandler = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    if (pageTitle !== constants.NON_DOD_IC_APPLICATION_PROCESS_FIRST_PAGE) {
      props.currentInputData &&
        props.saveNonDodIcApplication(props.currentInputData, props.history);
    }
    const navigateAndReload = (url) => {
      window.location.href = url;
    };

    navigateAndReload(`non-dod-ic?title=${getPreviousPage()}`);
  };

  const saveAndExitHandler = () => {
    const pageTitle = queryString.parse(props.history.location.search).title;
    if (pageTitle === "personal_info-basic") {
      const dateInput = document.querySelector('input[type="date"]');
      const selectedDate = new Date(dateInput.value);
      const minDate = new Date("1950-01-01");
      const maxDate = new Date();
      if (selectedDate < minDate || selectedDate > maxDate) {
        displayErrorMessage();
        return false;
      } else {
        removeErrorMessage();
      }
    }
    if (props.currentInputData) {
      props.saveNonDodIcApplication(props.currentInputData, props.history);
      if (props.user.has_submitted_application) {
        props.history.push("/home");
      } else {
        props.logout();
        props.history.push("/account/login");
      }
    } else if (props.user.has_submitted_application) {
      props.history.push("/home");
    } else {
      props.logout();
      props.history.push("/account/login");
    }
    props.closeAllDrawers();
  };

  const finalizeAndSubmitNonDodIcApplication = () => {
    props.finalizeAndSubmitNonDodIcApplication(
      props.history,
      props.currentInputData
    );
    props.closeAllDrawers();
  };

  const submitApplication = () => {
    if (allFormsFilled(props.application.applicationMetadata)) {
      if (props.user.has_submitted_application) {
        props.saveNonDodIcApplication(props.currentInputData, props.history);
        props.history.push("/home");
      } else {
        props.toggleModal({
          modal_mode: true,
          modal_usi: "applicationConfirmation",
        });
      }
    } else {
      // Add Error Message
      const appSectionTitle = document.querySelector(".appSectionTitle");
      removeErrorMessage();
      const errorMessage =
        "Please fill all the required (non-optional) fields before proceeding.";
      const errorElement = document.createElement("div");
      errorElement.textContent = errorMessage;
      errorElement.style.fontSize = "16px";
      errorElement.style.textAlign = "center";
      errorElement.classList.add("text-danger");
      const parentElement = appSectionTitle.parentElement;
      parentElement.appendChild(errorElement);
      props.saveNonDodIcApplication(props.currentInputData, props.history);
      props.toggleNonDodIcUnfilled();
      props.openDrawerAndPreventTertiary();
    }
  };

  const pageTitle = queryString.parse(props.history.location.search).title;

  return (
    <>
      <UserFieldEditorFooter
        nextHandler={nextHandler}
        previousHandler={previousHandler}
        renderPreviousButton={
          queryString.parse(props.history.location.search).title !==
          constants.NON_DOD_IC_APPLICATION_PROCESS_FIRST_PAGE
        }
        saveAndExitHandler={saveAndExitHandler}
        toRenderSaveAndExitButton={!props.user.has_submitted_application}
        nextText={
          pageTitle === constants.NON_DOD_IC_APPLICATION_PROCESS_LAST_PAGE
            ? props.user.has_submitted_application
              ? "Finish Editing"
              : "Submit"
            : "Next"
        }
      />
      <ApplicationConfirmationModal
        submitHandler={finalizeAndSubmitNonDodIcApplication}
      />
    </>
  );
};

// TODO: Migrate this component to utilize hook
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

const mapStateToProps = (state, props) => {
  try {
    const { applicationMetadata, currentInputData } =
      state.nonDodIcApplication;

    const pageTitle = queryString.parse(props.history.location.search).title;
    const { stepId } = NonDodIcApplicationSteps.find(
      (elem) => elem.stepId === pageTitle
    );

    let selectedStep;

    applicationMetadata.find((input) => {
      return input.steps.find((step) => {
        if (step.stepId === stepId) {
          selectedStep = step;
        }
        return step.stepId === stepId;
      });
    });

    return {
      ...props,
      currentInputData,
      application: state.nonDodIcApplication,
      selectedStep,
      applicationInitialized: state.nonDodIcApplication.initialized,
      willLocate:
        state.nonDodIcApplication.address &&
        state.nonDodIcApplication.userWillReLocate,
      user: state.user.user,
    };
  } catch (e) {
    if (
      e.toString() === "TypeError: Cannot read property 'stepId' of undefined"
    ) {
      props.history.push("/account/login");
    }
    throw e;
  }
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WithDrawerConsumer(NonDodIcApplicationFooter))
);
