import React, { Component } from "react"
import colors from "../../Constants/colors"
import "./FocusedVideoContainer.css"
import queryString from "query-string"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import VideoMetaContent from "../VideoMetaContent/VideoMetaContent"
import DigitalLibraryVideoPlayer from "../DigitalLibraryVideoPlayer/DigitalLibraryVideoPlayer"
import FocusedVideoSidebar from "../FocusedVideoSidebar/FocusedVideoSidebar"
import DigitalLibraryInfiniteScroll from "../DigitalLibraryInfiniteScroll/DigitalLibraryInfiniteScroll"

class FocusedVideoContainer extends Component {
  constructor() {
    super()
    this.state = {
      scrollReady: false,
      scrolledToTop: false
    }
    this.finishLoading = this.finishLoading.bind(this)
  }

  finishLoading() {
    this.setState({
      scrollReady: true
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      // desktop
      document.getElementById("focused-video-container").scrollTop = 0
      // mobile
      document.getElementById("DL-container").scrollTop = 0
      this.props.fetchOneVideo(queryString.parse(this.props.location.search))
      const { videoId } = queryString.parse(this.props.location.search)
      this.props.addToRecentlyWatched(videoId)
    }
  }

  componentDidMount() {
    document.getElementById("focused-video-container").scrollTop = 0
    document.getElementById("DL-container").scrollTop = 0
    this.props.fetchOneVideo(queryString.parse(this.props.location.search))
    const { videoId } = queryString.parse(this.props.location.search)
    this.props.addToRecentlyWatched(videoId)
  }

  componentWillUnmount() {
    this.props.clearReduxVideos()
  }

  render() {
    return (
      <div
        style={styles.componentContainer}
        id="focused-video-container"
        className="focusedVideoComponentContainer"
      >
        <section className="videoContentContainer">
          <DigitalLibraryVideoPlayer />
          <VideoMetaContent />
        </section>
        <DigitalLibraryInfiniteScroll
          scrollReady={this.state.scrollReady}
          // This will cap the video length at 30. TODO: obviously this doesn't read well (i.e. why is it 20 and not 30?).
          // TODO: can't get the infinite scroll to work - we just show 10 videos for now. Have a feeling it's the parent container not triggering the scroll properly
          hasMore={false}
          loadMore={this.props.loadMoreVideos}
        >
          <section className="thumbnailContainer" style={styles.sidebar}>
            <FocusedVideoSidebar finishLoading={this.finishLoading} />
          </section>
        </DigitalLibraryInfiniteScroll>
      </div>
    )
  }
}

const styles = {
  componentContainer: {
    backgroundColor: colors.background,
    overflow: "auto",
    width: "100%"
  },
  sidebar: {
    marginTop: "2vw",
    borderLeft: "solid 1px #e1e0e0",
    display: "flex",
    flexDirection: "column",
    width: "100%"
  }
}

function mapDispatchToProps(dispatch) {
  const {
    loadMoreVideos,
    fetchOneVideo,
    addToRecentlyWatched,
    clearReduxVideos
  } = Actions

  return bindActionCreators(
    {
      fetchOneVideo,
      addToRecentlyWatched,
      loadMoreVideos,
      clearReduxVideos
    },
    dispatch
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    fetchingVideos: state.digitalLibrary.fetchingVideos,
    videosLength: state.digitalLibrary.videos.allIds.length
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FocusedVideoContainer)
)
