/* These are used a lot in conjunction with react-router */

export const underscorify = (str) => {
  return str.replace(/ /g, "_")
}

export const spacify = (str) => {
  return str.replace(/_/g, " ").trim()
}

export const dedashify = (str) => {
  return str.replace(/-/g, "_")
}

export const uppercasify = (str) => {
  const frags = str.split("_")
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(" ")
}

export const dashify = (str) => {
  return str.replace(/\s+/g, "-").toLowerCase()
}

export const despacify = (str) => {
  return str.replace(/ /g, "")
}
