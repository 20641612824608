import React from "react"
import colors from "../../Constants/colors"

export const MyPlaylistCount = ({ children, style }) => {
  return (
    <div style={{ ...styles.componentContainer, ...style }}>{children}</div>
  )
}

const styles = {
  componentContainer: {
    cursor: "pointer",
    width: "26px",
    height: "26px",
    borderRadius: "13px",
    color: colors.white,
    backgroundColor: colors.fadedRed,
    fontSize: "12px",
    fontFamily: "Lato-Semibold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}

export default MyPlaylistCount
