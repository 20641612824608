import React from "react"
import DialogActions from "@material-ui/core/DialogActions"
import { useSelector, useDispatch } from "react-redux"
import colors from "../../Constants/colors"
import CommitButtonWithoutClickState from "../CommitButtonWithoutClickState/CommitButtonWithoutClickState"
import CoachesNotesMap from "./CoachesNotesMap"
import ModalDialogHandler from "../ModalDialogHandler/ModalDialogHandler"
import Actions from "../../Redux/Actions"

const CoachesNotesModal = (props) => {
  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(
      Actions.toggleModal({
        modal_mode: false,
        modal_usi: "coachesNotes"
      })
    )
  }

  const stateData = useSelector((state) => {
    const currentPart = state.pyp.parts.byId[state.pyp.navigation.currentPartId]
    return {
      currentPart,
      modalIsOpen: state.modal.modal.coachesNotes
    }
  })

  const NotesComponent = CoachesNotesMap[stateData.currentPart.name]

  return (
    <div
      style={{
        ...props.style,
        color: colors.midnight
      }}
    >
      <ModalDialogHandler
        modal_usi="coachesNotes"
        footer={
          <DialogActions>
            <CommitButtonWithoutClickState
              clickHandler={closeModal}
              id="return to module"
              type="red"
              style={{
                width: "150px"
              }}
            >
              Return to Module{" "}
            </CommitButtonWithoutClickState>
          </DialogActions>
        }
      >
        <div style={{ padding: "50px", backgroundColor: colors.background }}>
          {NotesComponent ? <NotesComponent /> : ""}
          {/* <NotesComponent /> */}
        </div>
      </ModalDialogHandler>
    </div>
  )
}

export default CoachesNotesModal
