import React from "react"
import { Droppable } from "react-beautiful-dnd"
import RoadmapperCalenderEvent from "../RoadmapperCalenderEvent/RoadmapperCalenderEvent"

export default function RoadmapperCalenderContainer({
  eventIds,
  timeEvents,
  constraintEnd,
  constraintStart,
  isEditing,
  updateParentState,
  renderMoment,
  isBeingDragged,
  isDragDisabled,
  eventIdBeingEdited,
  updateEditEvent,
  deleteTimeEvent,
  skeletonLoader
}) {
  return (
    <div>
      <Droppable droppableId="TIME_EVENTS">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={styles.container}
          >
            {timeEvents[0] && timeEvents[0].title && eventIds[0] ? (
              eventIds.map((id, index) => {
                return (
                  <RoadmapperCalenderEvent
                    skeletonLoader={skeletonLoader}
                    {...provided.droppableProps}
                    constraintStart={constraintStart}
                    constraintEnd={constraintEnd}
                    isEditing={isEditing}
                    updateParentState={updateParentState}
                    timeEvent={timeEvents.filter((event) => event.id === id)[0]}
                    key={
                      timeEvents.filter((event) => event.id === id)[0] &&
                      timeEvents.filter((event) => event.id === id)[0].id
                    }
                    index={index}
                    isDragDisabled // isDragDisabled}
                    renderMoment={renderMoment}
                    isBeingDragged={isBeingDragged}
                    updateEditEvent={updateEditEvent}
                    deleteTimeEvent={deleteTimeEvent}
                    editingComponent={
                      eventIdBeingEdited ===
                      timeEvents.filter((event) => event.id === id)[0].id
                    }
                  />
                )
              })
            ) : (
              <></>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

const styles = {
  container: {
    maxWidth: "699px"
  }
}
