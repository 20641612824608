import React from "react"

export const Map = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="288"
      height="235"
      viewBox="0 0 288 235"
    >
      <defs>
        <linearGradient id="a" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="53.514%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <path
          id="b"
          d="M232.795 207.15H175.55l-87.325 8.441-88-8.441V.334l87.635 6.331L177.387.334l87.582 11.959V174.79z"
        />
        <linearGradient id="d" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopOpacity=".12" />
          <stop offset="55.135%" stopOpacity=".09" />
          <stop offset="100%" stopOpacity=".02" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#a)"
          fillRule="nonzero"
          d="M245.935 225.67h-55.57l-94.65 9.197L.33 225.67V.315l94.988 6.899L192.358.315l94.93 13.031-2.438 173.057z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M244.124 222.537h-54.355l-92.58 8.95-93.297-8.95V3.274l92.91 6.712 94.916-6.712 92.853 12.678-2.384 168.38z"
        />
        <path
          fill="#292E4C"
          fillRule="nonzero"
          d="M244.43 216.567h-57.246l-87.325 8.442-88-8.442V9.751l87.636 6.331 89.528-6.33 87.581 11.958V184.21z"
        />
        <g transform="translate(34.087 12.174)">
          <mask
            id="c"
            fill="#fff"
            dangerouslySetInnerHTML={{ __html: '<use xlink:href="#b" />' }}
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            d="M320.712 266.485L119.683 155.159l51.062-169.652-17.378-6.891-24.745 82.216-197.155-109.18-7.989 19.007L122.577 80.916 77.122 231.94l17.377 6.891 19.14-63.588 199.084 110.249z"
            mask="url(#c)"
          />
        </g>
        <path
          fill="url(#d)"
          fillRule="nonzero"
          d="M240.377 222.195l.673-42.69 41.137 1.66z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M11.86 99.128l126.173 55.865 8.385 17.381-8.348 8.696-126.21-59.856zM244.124 222.195l.613-39.393 37.45 1.53zM183.287 189.07l52.647-172.913 20.942 2.86-58.98 179.096z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M99.495 225.009l87.635-8.442V9.751l-87.635 6.331"
          opacity=".1"
        />
        <path
          fill="#E1373A"
          fillRule="nonzero"
          d="M179.756 85.021c0 16.706-30.249 64.015-30.249 64.015S119.26 101.727 119.26 85.02a30.249 30.249 0 1 1 60.497 0z"
        />
        <circle
          cx="149.507"
          cy="82.911"
          r="9.145"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M230.494 99.128l46.11 11.832v22.085l-56.447-20.149zM54.76 141.56l-11.647 80.553 13.218 2.783 17.412-74.334-.02-10.536zM54.29 36.398l-13.068 75.731 10.119 5.463 24.12-81.218z"
        />
        <circle
          cx="79.287"
          cy="21.418"
          r="2.783"
          fill="#E1373A"
          fillRule="nonzero"
        />
        <circle
          cx="45.896"
          cy="165.418"
          r="2.783"
          fill="#8792A5"
          fillRule="nonzero"
        />
        <circle
          cx="43.113"
          cy="185.939"
          r="2.783"
          fill="#E1373A"
          fillRule="nonzero"
        />
        <circle
          cx="72.679"
          cy="181.07"
          r="2.783"
          fill="#E1373A"
          fillRule="nonzero"
        />
        <circle
          cx="25.026"
          cy="97.591"
          r="2.783"
          fill="#E1373A"
          fillRule="nonzero"
        />
        <circle
          cx="244.157"
          cy="94.809"
          r="2.783"
          fill="#8792A5"
          fillRule="nonzero"
        />
        <circle
          cx="220.157"
          cy="191.505"
          r="2.783"
          fill="#E1373A"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}
