import React from "react"

export const DownArrow = (props) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "7"}
        height={props.height || "5"}
        viewBox="0 0 7 5"
      >
        <path
          fill="none"
          fillRule="evenodd"
          stroke={props.stroke || "#1F2024"}
          d="M7 1L3.5 4 0 1"
        />
      </svg>
    </div>
  )
}
