import React from "react"
// import PropTypes from 'prop-types'
import colors from "../../Constants/colors.js"

export const VideoEditorHeader = (props) => {
  return (
    <div style={{ ...styles.componentContainer, ...props.style }}>
      {props.children}
    </div>
  )
}

const styles = {
  componentContainer: {
    width: "100%",
    border: 0
  },
  title: {
    textAlign: "center",
    color: colors.midnight,
    fontSize: "30px",
    lineHeight: "42px",
    fontFamily: "Lato-Semibold"
  },
  subTitle: {
    fontSize: "15px",
    lineHeight: "42px",
    textAlign: "center",
    color: colors.gray,
    fontFamily: "Lato-Medium"
  }
}

export default VideoEditorHeader
