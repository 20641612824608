import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { withStyles } from "@material-ui/core/styles"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import MyPlaylistCount from "../MyPlaylistCount/MyPlaylistCount"
import { RightArrow } from "../../Icons/RightArrow"
import WithHistory from "../WithHistory/WithHistory"
import WithAdminAccount from "../WithAdminAccount/WithAdminAccount"
import colors from "../../Constants/colors"
import Actions from "../../Redux/Actions"
import { gtmClickHandler } from "../../Utils/gtm"
import { uppercasify } from "../../Utils/urlParsingUtilities"

const styleSheet = {
  root: {
    width: "16.5%",
    backgroundColor: colors.midnight,
    marginTop: "60px"
  },
  listItem: {
    display: "flex",
    color: colors.white,
    height: "86px",
    width: "100%",
    fontFamily: "Lato-Regular",
    fontSize: "14px",
    lineHeight: "42px",
    letterSpacing: "0.8px",
    borderBottom: "solid 1px",
    borderTop: "solid 1px",
    padding: "0px",
    borderBottomColor: colors.mediumBlue,
    borderTopColor: colors.mediumBlue,
    "&:hover": {
      backgroundColor: "#292e4c"
    }
  },
  list: {
    padding: "0px"
  }
}

const typeMap = {
  "Full Interviews": "full_interviews",
  Insights: "insights",
  Industry: "industry",
  Home: "home",
  Journey: "journey"
}

export const DigitalLibraryNavMenu = (props) => {
  const navOptions = [
    {
      id: 0,
      name: "Home"
    },
    {
      id: 1,
      name: "Journey"
    }
  ].concat(props.types)

  const goToPlaylist = () => {
    if (props.match.params.action !== "my_videos" && !props.fetchingVideos) {
      gtmClickHandler({
        name: "DigitalLibrarySideMenuClickedEvent",
        data: {
          action: "Digital Library Side Menu Clicked",
          category: "Video Engagement",
          label: "My Videos"
        }
      })
      props.clearReduxVideos()
      props.history.push("/digitalLibrary/my_videos")
    }
  }

  const navClickHandler = (option) => (e) => {
    if (
      props.match.params.action !== typeMap[option.name] &&
      !props.fetchingVideos
    ) {
      props.clearReduxVideos()
      gtmClickHandler({
        name: "DigitalLibrarySideMenuClickedEvent",
        data: {
          action: "Digital Library Side Menu Clicked",
          category: "Video Engagement",
          label: uppercasify(typeMap[option.name])
        }
      })

      if (option.name === "Home") {
        props.history.push(`/digitalLibrary/home`)
      } else if (option.name === "Journey") {
        props.history.push(`/digitalLibrary/transition`)
      } else {
        props.history.push(`/digitalLibrary/${typeMap[option.name]}/all`)
      }
    }
  }

  const checkToHighlightType = (option) => {
    if (props.match.params.action === typeMap[option]) {
      return true
    }
    if (props.match.params.action === "recommended" && option === "Home") {
      return true
    }
    if (
      props.currentVideo &&
      props.currentVideo.type.name.toLowerCase() === typeMap[option]
    ) {
      return true
    }
  }

  const checkToHighlightMyVideos = () => {
    return (
      props.match.params.action === "my_videos" ||
      props.match.params.action === "recently_watched" ||
      props.match.params.action === "my_playlist"
    )
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        width: "200px",
        backgroundColor: colors.midnight
      }}
    >
      <List classes={{ root: props.classes.list }}>
        {navOptions.map((option, i) => (
          <WithHistory
            key={i}
            renderComponent={({ history, location, match }) => {
              return (
                <ListItem
                  style={
                    checkToHighlightType(option.name)
                      ? { backgroundColor: "#292e4c" }
                      : null
                  }
                  className="drawerNavItem"
                  onClick={navClickHandler(option)}
                  key={i}
                  button
                  aria-label={`see ${option.name} videos  `}
                  classes={{
                    root: props.classes.listItem
                  }}
                >
                  <div className="drawerNavItem" style={styles.navItem}>
                    {option.name.toUpperCase()}
                    <RightArrow />
                  </div>
                </ListItem>
              )
            }}
          />
        ))}
      </List>
      <WithHistory
        renderComponent={({ history }) => (
          <WithAdminAccount
            user={props.user}
            renderNonAdminComponent={() => (
              <ListItem
                onClick={goToPlaylist}
                button
                aria-label="go to my videos"
                classes={{
                  root: props.classes.listItem
                }}
                style={{
                  fontFamily: "Lato-Semibold",
                  fontSize: "12px",
                  paddingLeft: "20px",
                  backgroundColor: checkToHighlightMyVideos() && "#292e4c"
                }}
              >
                <MyPlaylistCount style={{ marginRight: "10px" }}>
                  {props.user && props.user.playlist
                    ? props.user.playlist.length
                    : 0}
                </MyPlaylistCount>
                MY VIDEOS
              </ListItem>
            )}
            renderAdminComponent={() => (
              <ListItem
                onClick={() => {
                  history.push(`/digitalLibraryAdmin`)
                }}
                button
                classes={{
                  root: props.classes.listItem
                }}
                style={{
                  fontFamily: "Lato-Semibold",
                  fontSize: "12px",
                  paddingLeft: "20px"
                }}
              >
                UPLOAD VIDEO
              </ListItem>
            )}
          />
        )}
      />
    </div>
  )
}

const styles = {
  navOptions: {
    display: "flex",
    height: "100%"
  },
  navItem: {
    height: "100%",
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    lineHeight: "42px",
    fontSize: "14px",
    letterSpacing: "0px"
  },
  navContentContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props,
    user: state.user.user,
    allCategories: state.digitalLibrary.allCategories,
    fetchingVideos: state.digitalLibrary.fetchingVideos,
    currentPlaylist: state.digitalLibrary.currentPlaylist,
    recommendedVideos: state.digitalLibrary.recommendedVideos,
    currentVideo: state.digitalLibrary.currentVideo,
    types: state.digitalLibrary.navigationItems
  }
}

export default withRouter(
  withStyles(styleSheet)(
    connect(mapStateToProps, mapDispatchToProps)(DigitalLibraryNavMenu)
  )
)
