import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import InputGroup from "../InputGroup/InputGroup"
import "./MilitaryOne.css"

const initialInputData = [
  {
    id: 0,
    userField: "military.branch",
    className: "branch",
    inputType: "select",
    options: [
      { id: 0, value: "Air Force" },
      { id: 1, value: "Army" },
      { id: 5, value: "Coast Guard" },
      { id: 2, value: "Marines" },
      { id: 4, value: "National Guard" },
      { id: 3, value: "Navy" },
      { id: 7, value: "Space Force" },
      { id: 6, value: "Civilian" }
    ],
    placeHolder: "Branch",
    title: "What is your branch of service?",
    required: true
  },
  {
    id: 1,
    userField: "military.lastRank",
    placeHolder: "Final Rank",
    className: "rank",
    inputType: "select",
    options: [
      { id: 0, value: "E-1" },
      { id: 1, value: "E-2" },
      { id: 2, value: "E-3" },
      { id: 3, value: "E-4" },
      { id: 4, value: "E-5" },
      { id: 5, value: "E-6" },
      { id: 6, value: "E-7" },
      { id: 7, value: "E-8" },
      { id: 8, value: "E-9" },
      { id: 10, value: "O-1" },
      { id: 12, value: "O-2" },
      { id: 13, value: "O-3" },
      { id: 14, value: "O-4" },
      { id: 16, value: "O-5" },
      { id: 17, value: "O-6" },
      { id: 18, value: "O-7" },
      { id: 19, value: "O-8" },
      { id: 20, value: "O-9" },
      { id: 21, value: "O-10" },
      { id: 10, value: "W-1" },
      { id: 11, value: "W-2" },
      { id: 12, value: "W-3" },
      { id: 13, value: "W-4" },
      { id: 14, value: "W-5" }
    ],
    title: "What was your final rank?",
    required: true
  },
  {
    id: 2,
    userField: "military.status",
    className: "status",
    inputType: "select",
    placeHolder: "Status",
    options: [
      { id: 0, value: "Active Duty" },
      { id: 1, value: "National Guard" },
      { id: 2, value: "Active Guard/ Reserve (>180 days)" },
      { id: 3, value: "Reserve" },
      { id: 4, value: "Retired" },
      { id: 5, value: "Separated (Voluntary)" },
      {
        id: 6,
        value: "Medical Separation or Medical Retirement (Pending or Complete)"
      },
      { id: 7, value: "Other Than Honorable Discharge" },
      { id: 8, value: "Dishonorable Discharge" },
      { id: 8, value: "Bad Conduct Discharge" },
      { id: 8, value: "Dismissed" }
    ],
    title: "What is your military status?",
    required: true
  },
  {
    id: 3,
    userField: "military.years_of_service",
    className: "yearsOfService",
    inputType: "numberInput",
    placeHolder: "Years of Service (Including years until separation date)",
    title: "Years of Service",
    required: true
  },
  {
    id: 4,
    userField: "military.retirementDate",
    className: "sepDate",
    shrink: true,
    helperText:
      "Please include a projected date if you are still on active duty",
    placeHolder: "What is your separation or retirement date? (MM/DD/YYYY)",
    type: "date",
    inputType: "date",
    title:
      "What is your separation or retirement date? Please include a projected date if you are still active duty.",
    required: true
  },
  {
    id: 5,
    userField: "military.specialty",
    className: "specialty",
    inputType: "text",
    placeHolder: "Military Occupation (e.g. Helicopter Pilot)",
    title: "Military Specialty",
    required: true
  },
  {
    id: 6,
    userField: "military.lastDutyPosition",
    inputType: "text",
    className: "duty",
    placeHolder: "Last Duty Position",
    title: "What was the last duty position you held?",
    required: true
  },
  {
    id: 7,
    userField: "military.sof",
    className: "sofSelect",
    inputType: "select",
    placeHolder:
      "Was the last duty position a unit within the USSOCOM enterprise?",
    title: "Was the last duty position a unit within the USSOCOM enterprise?",
    options: [
      { id: 0, value: "No" },
      { id: 1, value: "Yes" }
    ],
    required: true
  },
  {
    id: 8,
    className: "sofUnit invisible",
    userField: "military.sofUnit",
    inputType: "select",
    placeHolder: "SOF Unit",
    title: "SOF Unit",
    options: [],
  }
]

const branchesWithDropdown = ["Navy", "Army", "Marines", "Air Force"]

const MilitaryOne = (props) => {
    const application = window.location.href.includes('spouse') ?  props.spouseApplication : props.application
    const inputsByField = initialInputData.reduce(
        (inputs, currentInput) => ({
        ...inputs,
        [currentInput.userField]: {
            ...currentInput,
            value: Object.byString(application, currentInput.userField) || ""
        }
        }),
        {}
    )

  const [inputs, setInputs] = useState(inputsByField)

  const getSofUnitOptions = (branchOfService) => {
    switch (branchOfService) {
      case "Navy":
        return [
          { id: 0, value: "JSOC" },
          { id: 1, value: "MARSOC" },
          { id: 2, value: "NSW Group 1" },
          { id: 3, value: "NSW Group 2" },
          { id: 4, value: "NSW Group 3" },
          { id: 5, value: "NSWDG" },
          { id: 6, value: "NSW Group 4" },
          { id: 7, value: "NSW Group 10" },
          { id: 8, value: "NSWC" },
          { id: 9, value: "NECC Group 1" },
          { id: 10, value: "NECC Group 2" }
        ]
      case "Army":
        return [
          { id: 0, value: "JSOC" },
          { id: 1, value: "75th RR" },
          { id: 2, value: "160th SOAR" },
          { id: 3, value: "NEXUS" },
          { id: 4, value: "1SFG" },
          { id: 5, value: "3SFG" },
          { id: 6, value: "5SFG" },
          { id: 7, value: "7SFG" },
          { id: 8, value: "10SFG" },
          { id: 9, value: "CAG" },
          { id: 10, value: "EOD" }
        ]
      case "Marines":
        return [{ id: 0, value: "MARSOC" }]
      case "Air Force":
        return [
          { id: 0, value: "AFSOC" },
          { id: 1, value: "EOD" }
        ]
      default:
        return []
    }
  }

  const onInputChange = (input, value) => {
    const newInputsData = {
      ...inputs,
      [input.userField]: {
        ...input,
        value
      }
    }

    const sofValue = newInputsData["military.sof"].value
    const branchOfServiceValue = newInputsData["military.branch"].value

    setInputs({
      ...newInputsData,
      "military.sofUnit": {
        ...newInputsData["military.sofUnit"],
        className:
          sofValue === "Yes" &&
          branchesWithDropdown.includes(branchOfServiceValue)
            ? "sofUnit"
            : "sofUnit invisible",
        options: getSofUnitOptions(branchOfServiceValue)
      }
    })
  }

  useEffect(() => {
    /* Handle initial visibility of dynamic sof unit input */
    const sofValue = inputs["military.sof"].value
    const branchOfServiceValue = inputs["military.branch"].value

    setInputs({
      ...inputs,
      "military.sofUnit": {
        ...inputs["military.sofUnit"],
        className: sofValue === "Yes" && branchesWithDropdown.includes(branchOfServiceValue) ? "sofUnit" : "sofUnit invisible",
        options: getSofUnitOptions(branchOfServiceValue)
      }
    })
  }, [])

  return (
    <InputGroup
      {...props}
      containerClass="militaryOneContainer"
      inputData={Object.values(inputs)}
      onInputChange={onInputChange}
      renderComponent={({ inputComponent, inputData, checkSubmittability }) =>
        props.renderComponent({
          ...props,
          inputData,
          inputComponent,
          checkSubmittability
        })
      }
    />
  )
}

const mapStateToProps = (state) => ({
  application: state.application.application,
  spouseApplication: state.spouseApplication.application
})

export default connect(mapStateToProps)(MilitaryOne)
