import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"
import colors from "../../Constants/colors.js"
import FormHeader from "../FormHeader/FormHeader"
import FormFooter from "../FormFooter/FormFooter"
import AccountFormContainer from "../AccountFormContainer/AccountFormContainer"
import { Key } from "../../Icons/Key"

import Actions from "../../Redux/Actions"

const ForgotPassword = (props) => {
  const navBackToLogin = () => {
    props.history.push("/account/login")
  }

  return (
    <div style={styles.componentContainer}>
      <AccountFormContainer>
        <div style={styles.headerContainer}>
          <Key style={{ marginBottom: "20px" }} />
          <FormHeader
            title="Thanks"
            subTitle="You should be receiving an email to reset your password shortly."
          />
        </div>
        <FormFooter>
          <CommitButtonWithClickState
            style={styles.createButton}
            hoverStyle={styles.createButtonHover}
            clickHandler={navBackToLogin}
          >
            Back to login
          </CommitButtonWithClickState>
        </FormFooter>
      </AccountFormContainer>
    </div>
  )
}

const styles = {
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
  createAccountButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  componentContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "30px",
    color: colors.text,
    lineHeight: "30px",
    letterSpacing: 0
  },
  loginButton: {
    borderRadius: "19px",
    border: "solid 1px #e1373a",
    color: colors.fadedRed,
    backgroundColor: "#ffffff"
  },
  footerText: {
    fontSize: "13px"
  },
  createButtonHover: {
    backgroundColor: "#ffffff",
    color: "#E1373A",
    borderColor: "#E1373A"
  },
  createButton: {
    borderRadius: "3px",
    transition: "all 0.3s ease-in-out",
    backgroundColor: colors.fadedRed,
    color: colors.white,
    marginTop: "20px"
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

const mapStateToProps = (state, props) => {
  return {
    ...props
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
)
