import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import "./ProgramApprovalContainer.css"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import Actions from "../../Redux/Actions"
import CommitButtonWithClickState from "../CommitButtonWithClickState/CommitButtonWithClickState"

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}
const mapStateToProps = (state, props) => {
  return { ...props }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class ProgramApprovalContainer extends Component {
      constructor(props) {
        super(props)
        this.boundCompleteGuide = this.boundCompleteGuide.bind(this)
      }

      boundCompleteGuide = () => {
        this.props.completeGuide(
          this.props.data.GUIDE_USI,
          this.props.history,
          this.props.data.route
        )
      }

      render() {
        return (
          <div className="programApprovalContainer" style={this.props.style}>
            <div className="programApprovalImageContainer">
              <img
                className="programApprovalImage"
                src={this.props.pageImage}
                alt="page for approval"
              />
            </div>
            <span className="programApprovalTextContainer">
              <div className="programApprovalTopContent">
                <h3 className="programApprovalHeader">
                  {this.props.data.approvalHeader || "You've Been Approved!"}
                </h3>
                <p
                  className="programApprovalMessage"
                  style={{ WebkitBoxOrient: "vertical" }}
                >
                  {this.props.data.approvalMessage ||
                    "Once Upon a midnight dreary"}
                </p>
              </div>
              <div className="programApprovalButton">
                <CommitButtonWithClickState
                  type="red"
                  clickHandler={this.boundCompleteGuide}
                  id={`${this.props.data.GUIDE_USI}_guide_redirect`}
                  style={{ width: "153px", height: "40px" }}
                >
                  {this.props.data.redirectButtonText || "Merrily Forward"}
                </CommitButtonWithClickState>
              </div>
            </span>
          </div>
        )
      }
    }
  )
)
